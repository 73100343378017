/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

Copyright 2000 NAB, All Rights Reserved.

NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
and Non-disclosure agreements explicitly covering such access.

The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
MAY DESCRIBE, IN WHOLE OR IN PART.

*/

import React from 'react';
import CustomRating from '../../shared/Rating';
import Loading from './LoadingCard';

const AverageCard = ({t, type, rating, ratingsNumber, isLoading, icon }) => {


    return (
      <span className='card reputationCard'>
        <div className='list'>
            <div className='content'>
              {isLoading ? <Loading/>  :  (<><img src={icon} alt={type} />
              <p className='ratingNumber'>{ratingsNumber == 0 ? t('ReputationScreen.NoRatings') : `${ratingsNumber} ${t('ReputationScreen.Ratings')}`}</p>
              <div className='ratingGroup'>
                <CustomRating className='themedIcon' readOnly size='small' value={Number(rating)}/>
                <p>{t('ReputationScreen.Average')}</p>
              </div></>)}
            </div>
        </div>
      </span>
    );

};

export default AverageCard;
