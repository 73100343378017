
const styles = (theme) => ({

  '.payanywhere': {
    fontFamily: theme.typography.primaryFontFamily,
    'header': {
      '.navSelected': {
        color: 'labelColor.main',
      },
      'nav': {
        'a:hover': {
          color: 'labelColor.main',
        },
      },
    },
    '.closedAccountContainer': {
      '.closedAccountIndicator': {
        '.closedAccountIcon': {
          'svg': {
            fill: 'labelColor.main !important',
          },
        },
      },
    },
    '.linkButton:hover': {
      color: 'labelColor.main !important',
      'svg': {
        fill: 'labelColor.main !important',
      },
    },
    '.linkBtn': {
      ...theme.sharedStyles.secondaryFontFamilySemiBold,
      textTransform: 'uppercase',
      marginTop: '10px',
      fontSize: '13.5px',
      padding: '0 6px !important',
    },
    '.inventoryItems': {
      '.activeTab': {
        color: 'labelColor.main !important',
      },
      '.inactiveTab': {
        color: theme.palette.common.regularColor + ' !important',
      },
    },
    'div.stock .stockAlert svg': {
      fill: 'labelColor.main !important',
    },
    '.addMerchantAccount': {
      color: 'labelColor.main !important',
    },
    '.addAccountDialog': {
      '.activeField label': {
        color: 'labelColor.main !important',
      },
      '.errorField.activeField label': {
        color: theme.palette.error.main + ' !important',
      },
      'hr:first-of-type, .activeField hr:first-of-type': {
        borderBottom: '1px solid labelColor.main !important',
      },
      'hr + hr, .activeField hr + hr': {
        borderBottom: '2px solid labelColor.main !important',
      },
      '.errorField hr:first-of-type': {
        borderBottom: `1px solid ${theme.palette.error.main} !important`,
      },
      '.errorField hr + hr': {
        borderBottom: `2px solid ${theme.palette.error.main} !important`,
      },
    },
    '.cornerCheckMark': {
      '.st0': {
        fill: 'labelColor.main !important',
      },
    },
    '.mainMenuItem': {
      '.st0': {
        fill: theme.palette.common.loginHeadingColor + ' !important',
      },
      '&.selected': {
        '.st0': {
          fill: 'labelColor.main !important',
        },
      },
    },
    '.integrationsPage': {
      '.returnToIntegrations': {
        'svg': {
          fill: 'labelColor.main !important',
        },
      },
      '.imageGallery': {
        '.image-gallery-left-nav:hover::before, .image-gallery-right-nav:hover::before': {
          color: 'labelColor.main !important',
        },
      },
    },
    '& .today': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& .today .action a': {
      color: 'labelColor.main !important',
    },
    '& .today .trends .trendsRow .statisticsContainer .stars svg': {
      fill: 'labelColor.main !important',
    },
    '& .openBatchButton.selected svg': {
      fill: 'labelColor.main !important',
    },
    '& ._elevio_launcher button': {
      backgroundColor: 'labelColor.main !important',
    },
    '& .payAnywhereLogin': {
      display: 'flex',
      height: '100vh',
      flexDirection: 'row',
      '& .formContainer': {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        boxShadow: '10px 0 0 0 rgba(0, 0, 0, 0.1)',
        zIndex: 1,
        alignItems: 'center',
        width: '35%',
        [theme.breakpoints.down(theme.breakpoints.values.sizeTabletMax)]: {
          width: '100%',
          padding: '0 20vw',
          boxShadow: 'none',
        },
        [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)]: {
          padding: 0,
          boxShadow: 'none',
        },
        '& .loginButton': {
          background: `linear-gradient(to top right, ${theme.palette.common.paGradientOne}, ${theme.palette.common.paGradientTwo}) !important`,
          boxShadow: '2px 2px 10px 0 rgba(163, 163, 166, 1)',
          textAlign: 'left !important',
          paddingLeft: '30px !important',
          '& div': {
            position: 'relative !important',
            '& span': {
              textTransform: 'capitalize !important',
            },
            '& svg': {
              position: 'absolute',
              right: '47px',
              top: '15px',
              width: '12px',
              fill: 'common.backgroundColor !important',
              height: '12px',
            },
          },
        },
        '& .logo': {
          width: '284px',
          height: '85px',
          marginTop: '45px',
        },
        '& .toggleContainer': {
          height: '50px',
          width: '80%',
          textAlign: 'center',
          marginTop: '55px',
          backgroundColor: '#E9E9EC',
          borderRadius: '30px',
          '& .toggleButton': {
            ...theme.typography.secondaryFontFamilySemiBold,
            borderRadius: '30px',
            width: '50%',
            height: '100%',
            display: 'inline-block',
            lineHeight: '50px',
            [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)]: {
              fontSize: '10px',
            },
          },
          '& .sales': {
            backgroundColor: 'common.backgroundColor2',
            color: 'common.payAnywhereAlternativeColor',
            textDecoration: 'none',
          },
          '& .owner': {
            backgroundColor: 'labelColor.main',
            color: 'common.backgroundColor',
          },
        },
        '& .loginContainer': {
          margin: '70px 0',
          width: '80%',
          '& .payanywhereForm button': {
            height: '65px !important',
            marginTop: '35px !important',
          },
          '& .signUpLink': {
            width: 'auto',
            textAlign: 'center',
            marginTop: '50px',
          },
        },
        '& .forgotPasswordContainer': {
          textAlign: 'center',
          marginTop: '55px',
          textTransform: 'capitalize !important',
          ...theme.typography.secondaryFontFamilySemiBold,
          '& a': {
            textDecoration: 'none',
            color: 'labelColor.main !important',
          },
          '& .arrowIcon svg': {
            width: '10px',
            height: '10px',
            fill: 'labelColor.main',
            marginLeft: '10px',
          },
        },
      },
      '& .imgContainer': {
        backgroundImage: 'url("/images/payanywhere/login.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        width: '65%',
        justifyContent: 'center',
        '& .heading': {
          fontFamily: theme.typography.secondaryFontFamily,
          color: 'common.loginHeadingColor',
          fontWeight: 'bold',
          paddingTop: '60px',
          textAlign: 'center',
        },
        [theme.breakpoints.down(theme.breakpoints.values.sizeTabletMax)]: {
          display: 'none',
        },
      },
    },
    '& .contactFooter p': {
      ...theme.typography.contentLabel,
      textAlign: 'center',
      color: 'labelColor.main',
      '& a': {
        textDecoration: 'none',
        color: 'labelColor.main',
      },
    },
    '& .contactFooter .divider': {
      color: 'labelColor.main !important',
    },
    '& .failedPaymentInfo .failureButtonContainer .failureResendButton': {
      color: 'labelColor.main !important',
    },
    '& .w9 a': {
      color: 'labelColor.main !important',
    },
    '& .assistanceDialog .formCopyLink .textWithLink .copyOfForm a, & .assistanceDialog .formCopyLink .textWithLink .correctionOfForm a': {
      textDecoration: 'none',
      color: 'labelColor.main !important',
    },
  },
  '.mobileDevice, .customerPaymentBody': {
    '& ._elevio_launcher': {
      '& div': {
        display: 'none !important', // hides elevio needs help button
        '& #_elev_io ._1g6cj': {
          [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)]: {
            zIndex: 1499,
          },
        },
      },
    },
  },
});


export default styles;
