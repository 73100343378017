/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, {Component} from 'react';
import TitlePanel from './../TitlePanel';
import moment from 'moment';
import BarChart from '../shared/charts/BarChart';
import {setSelectedChartItems} from '../../actions/userExperienceActions';
import NoData from './../NoData';
import ItemsGraphSelector from '../activity/ItemsGraphSelector';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import withStyles from '@mui/styles/withStyles';
import {primaryColorCheckBox} from '../../jss/inlineStyles';
import numeral from 'numeral';
import _ from 'lodash';
import SalesDataUtil from '../util/SalesDataUtil';
import classNames from 'classnames';

let itemsData = [];

export const CustomCheckbox = withStyles(primaryColorCheckBox)
((props) => <Checkbox color='default' {...props} />);

export default class Customers extends Component {

  constructor(props) {
    super(props);

    this.handleRowSelection = this.handleRowSelection.bind(this);
    this.handleItemsGraphSelection = this.handleItemsGraphSelection.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);

    this.state = {
      itemsTableCheckBox: false,
      itemsyAxesType: '$',
      itemsGraphSelection: 'total'
    }
  }

  handleRowSelection(rowIds) {
    let itemsMapped = {};
    _.each(itemsData.itemsTableData, item => {
      itemsMapped[item['index']] = item;
    });
    this.props.dispatch(setSelectedChartItems(rowIds.map(index => {
      return itemsMapped[index];
    }),rowIds));
  }

  handleItemsGraphSelection(value) {
    this.setState({
      itemsGraphSelection: value,
      itemsyAxesType: value === 'sold' ? '#' : '$',
      itemsTableCheckBox: value === 'custom',
    });

  };

  handleRowClick(i) {
    return () => {
      const selected = [...(this.props.userExperience.selectedRows ?? [])];
      const newSelected = selected?.includes(i) ?
        selected?.filter((rowIndex) => rowIndex !== i) : [...selected, i];

      this.handleRowSelection(newSelected);
    }
  }

  render() {

    const {reports, userExperience, dispatch, t} = this.props;

    itemsData = SalesDataUtil.formatItemsData(reports.data);
    let salesData = SalesDataUtil.processData(reports.data);
    let itemsChartValues = SalesDataUtil.itemsChart(itemsData.itemsTableData, this.state.itemsGraphSelection);
    let itemsChartCustomValues = SalesDataUtil.itemsChartCustom(userExperience.selectedItems);

    let itemsChart =
      <div className='chart'>
      <BarChart
        chartLabels={itemsChartValues.itemsChartLabels}
        currentData={itemsChartValues.itemsChartValues}
        width={600}
        height={300}
        {...this.props}
        yAxesType={this.state.itemsyAxesType}
        name='itemsChart'
        t={t}
      />
      </div>;

    let itemsChartCustom = (
      <div className='chartCustom'>
      <BarChart
        chartLabels={itemsChartCustomValues.itemsChartLabels}
        currentData={itemsChartCustomValues.itemsChartValues}
        width={600}
        height={300}
        {...this.props}
        yAxesType={'$'}
        name='itemsCustomChart'
        t={t}
      />
      </div>
    );

    let selectedItemsChart = this.state.itemsTableCheckBox ? itemsChartCustom : itemsChart;

    let itemsChartTable = reports.data.categorySales.length ?
      <div>
        <ItemsGraphSelector
          dispatch={dispatch}
          userExperience={userExperience}
          handleSelection={this.handleItemsGraphSelection}
          t={t}
        />
        <Table className='mobileTable'>
          <TableHead>
            <TableRow>
              {this.state.itemsTableCheckBox && (
                <TableCell component='th' className='mobileTHead'/>
              )}
              <TableCell component='th' colSpan={2} className={classNames('mobileTHead', {'noPaddingLeft': this.state.itemsTableCheckBox})}>NAME</TableCell>
              <TableCell component='th' className='noPaddingLeft mobileTHead'>SOLD</TableCell>
              <TableCell component='th' className='noPaddingLeft mobileTHead'>NET SALES</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              itemsData.itemsTableData.map((item, i) => {
                return (
                  <TableRow
                      className={itemsData.itemsTableData.length === i + 1 ? 'doubleBottomBorder' : ''}
                      key={i}
                      onClick={this.state.itemsTableCheckBox ? this.handleRowClick(i) : undefined}
                  >
                    {this.state.itemsTableCheckBox && (
                      <TableCell padding='checkbox'>
                        <CustomCheckbox
                            checked={_.includes(this.props.userExperience.selectedRows, i)}
                            component={'div'}
                            InputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${i}` }}
                        />
                      </TableCell>
                    )}
                    <TableCell colSpan={2} className={(this.state.itemsTableCheckBox) ? 'noPaddingLeft textMobile' : 'textMobile'}>
                      {item.name}
                    </TableCell>
                    <TableCell className='noPaddingLeft textMobile'> {item.sold} </TableCell>
                    <TableCell className='noPaddingLeft textMobile'>{numeral(item.total).format('$0,0.00')}</TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
          <TableFooter>
            <TableRow className='noBottomBorder'>
              <TableCell colSpan={2} className={(this.state.itemsTableCheckBox) ? 'noPaddingLeft textMobile' : 'textMobile'}>
                {'Total to Account For'}
              </TableCell>
              <TableCell className='noPaddingLeft textMobile'>
                {salesData.categoryTotalSold}
              </TableCell>
              <TableCell className='noPaddingLeft textMobile'>{numeral(salesData.categoryTotalSales).format('$0,0.00')}</TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </div> : null;

    return (
      <div className='items'>
        <TitlePanel
          title='Items'
          subText={`${moment(this.props.dateRange.startDate).format('MM/DD/YYYY h:mm:ss a')} - ${moment(this.props.dateRange.endDate).format('MM/DD/YYYY h:mm:ss a')}`}/>
        {reports.data.categorySales.length ? selectedItemsChart : <NoData text='No Items Data'/>}
        {itemsChartTable}
      </div>
    )

  }

}

Customers.propTypes = {
  dateRange: PropTypes.object,
  chartData: PropTypes.object,
};
