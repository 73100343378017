/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */
import _ from 'lodash';
import numeral from 'numeral';
import commonUtil from './CommonUtil';

const SalesDataUtil = {

  processData: function (data = {}) {
    let salesData = {};

    salesData.discountsTotalSold = _.reduce(_.map(data?.discounts, 'sold'), (sum, n) => {
      return sum + n;
    }, 0);

    salesData.discountsTotalSales = _.reduce(_.map(data?.discounts, 'total'), (sum, n) => {
      return sum + n;
    }, 0);

    salesData.discountsTotalSales = numeral(salesData.discountsTotalSales).format('$0,0.00');

    salesData.unpaidAmount = data?.unpaidAmount?.total ? data.unpaidAmount.total : 0;

    salesData.categoryTotalSold = _.reduce(_.map(data?.categorySales, 'sold'), (sum, n) => {
      return sum + n;
    }, 0);

    salesData.categoryTotalSales = _.reduce(_.map(data?.categorySales, 'total'), (sum, n) => {
      return sum + n;
    }, 0);
    return salesData;
  },

  categoriesChart: (categories) => {
    let chartData = {};
    chartData.categoriesChartLabels = _.map(categories, 'name');
    chartData.categoriesChartCount = _.map(categories, function (value) {
      return value.total ? numeral(value.sold).format('0.0') : 0;
    });
    chartData.categoriesSalesTotalChartCount = _.map(categories, function (value) {
      return value.total ? numeral(value.total).format('0.0') : 0;
    });
    return chartData;
  },

  employeeChart: (data) => {

    let employeeData = {};
    let currentTransactions = data.currentActivities.data.transactions;
    let previousTransactions = data.previousActivities.data.transactions;

    let currentArray = _.chain(currentTransactions).filter('sold_by', 'sold_by_id').map(el => {
      return _.pick(el, 'sold_by', 'sold_by_id');
    }).value();

    currentArray = _.map(currentArray, o => _.extend({
      total: 0,
      tips: 0,
      difference: 0
    }, o));
    currentArray = commonUtil.mergeByFieldsOneToMany(currentArray, currentTransactions, 'sold_by_id', 'sold_by_id', 'transactions');
    currentArray.forEach(employee => {
      employee.transactions.forEach(transaction => {
        const amountToAdd = transaction.ccs_authorized_amt ? numeral(transaction.ccs_authorized_amt).value() : numeral(transaction.amount).value();
        employee.total = numeral(employee.total).value() + amountToAdd;
        employee.tips = numeral(employee.tips).value() + numeral(transaction.tip_amount).value();
      });
      employee.totalTransactions = employee.transactions.length;
    });

    let previousArray = _.chain(previousTransactions).filter('sold_by', 'sold_by_id').map(el => {
      return _.pick(el, 'sold_by', 'sold_by_id');
    }).value();

    previousArray = _.map(previousArray, o => _.extend({total: 0, tips: 0}, o));
    previousArray = commonUtil.mergeByFieldsOneToMany(previousArray, previousTransactions, 'sold_by_id', 'sold_by_id', 'transactions');
    previousArray.forEach(employee => {
      employee.transactions.forEach(transaction => {
        const amountToAdd = transaction.ccs_authorized_amt ? numeral(transaction.ccs_authorized_amt).value() : numeral(transaction.amount).value();
        employee.total = numeral(employee.total).value() + amountToAdd;
        employee.tips = numeral(employee.tips).value() + numeral(transaction.tip_amount).value();
      });
    });

    currentArray.forEach(employee => {
      previousArray.forEach(employeePrevious => {
        /* istanbul ignore else */
        if (employee.sold_by === employeePrevious.sold_by) {
          employee.difference = commonUtil.difference(employee.total, employeePrevious.total);
        }
      });
    });

    employeeData.currentTransactions = currentArray;
    employeeData.previousTransactions = previousArray;

    return employeeData;
  },
  volumeData: (data ) => {
    let volumeData = {};

    const transactions = data?.creditCards?.length ? _.map(data.creditCards, 'ccSalesAmount') : [];
    transactions.push(data?.cash?.amount || 0);
    volumeData.grossSalesTotalQuantityOfTransactions = _.reduce(transactions, (sum, n) => {
      return sum + n;
    }, 0);

    volumeData.grossSalesTotal = _.reduce(_.map(data?.categorySales, (sale) => sale.total_with_modifier || sale.total), (sum, n) => {
      return sum + n;
    }, 0);

    volumeData.discountsTotalQuantity = _.reduce(_.map(data?.discounts, 'sold'), (sum, n) => {
      return sum + n;
    }, 0);
    volumeData.discountsTotal = _.reduce(_.map(data?.discounts, 'total'), (sum, n) => {
      return sum + n;
    }, 0);
    volumeData.refundsTotalQuantity = data?.refunds?.amount || 0;
    volumeData.refundsTotal = data?.refunds?.total || 0;
    volumeData.voidsTotalQuantity = data?.voids?.amount || 0;
    volumeData.voidsTotal = data?.voids?.total || 0;
    volumeData.tipsTotalQuantity = data?.tips?.amount || 0;
    volumeData.tipsTotal = data?.tips?.total || 0;
    volumeData.unpaidAmount = data?.unpaidAmount?.total || 0;
    volumeData.tax =  data?.tax || 0;
    volumeData.totalNetSales = volumeData.grossSalesTotal + volumeData.discountsTotal + volumeData.refundsTotal + volumeData.voidsTotal + volumeData.unpaidAmount;
    volumeData.totalAccount = volumeData.totalNetSales + volumeData.tipsTotal + volumeData.tax;
    return volumeData;
  },

  getVolumeChange: (pastData, currentData) => {
    let change = {
      grossSales: 0,
      discounts: 0,
      refunds: 0,
      void: 0,
      unpaid: 0,
      netSales: 0,
      tips: 0,
      tax: 0,
    };
    /* istanbul ignore else */
    if (pastData) {
      pastData = SalesDataUtil.volumeData(pastData);
      currentData = SalesDataUtil.volumeData(currentData);

      change = {
        grossSales: commonUtil.differencePercentage(currentData.grossSalesTotal,
          pastData.grossSalesTotal),
        discounts: commonUtil.differencePercentage(currentData.discountsTotal,
          pastData.discountsTotal),
        refunds: commonUtil.differencePercentage(currentData.refundsTotal,
          pastData.refundsTotal),
        void: commonUtil.differencePercentage(currentData.voidsTotal,
          pastData.voidsTotal),
        unpaid: commonUtil.differencePercentage(currentData.unpaidAmount,
          pastData.unpaidAmount),
        netSales: commonUtil.differencePercentage(currentData.totalNetSales,
          pastData.totalNetSales),
        tips: commonUtil.differencePercentage(currentData.tipsTotal,
          pastData.tipsTotal),
        tax: commonUtil.differencePercentage(currentData.tax,
          pastData.tax),
      };
    }
    return change;
  },
  formatItemsData: (data) => {
    let itemsData = {};
    itemsData.itemsTableData = _.flatMap(data?.categorySales, item =>
      _(item.transactions)
        .map(value => ({
          name: value.name,
          sold: value.sold,
          total: value.total
        }))
        .value()
    );
    _.forEach(itemsData.itemsTableData, (value, key) => {
      _.extend(itemsData.itemsTableData[key], {index: key});
    });

    itemsData.topByValue = _(itemsData.itemsTableData)
      .orderBy('total', 'desc')
      .take(3)
      .value();

    itemsData.topByQuantity = _(itemsData.itemsTableData)
      .orderBy('sold', 'desc')
      .take(3)
      .value();
    return itemsData;
  },
  itemsChart: (data, key = 'total') => {
    let chartData = {};
    chartData.itemsChartLabels = _(data).orderBy(key, 'desc').map('name').take(3).value();
    chartData.itemsChartValues = _(data).orderBy(key, 'desc').map(key).take(3).value();
    return chartData;
  },
  itemsChartCustom: (data) => {
    let chartData = {};
    chartData.itemsChartLabels = _(data).map('name').value();
    chartData.itemsChartValues = _(data).map('total').value();
    return chartData;
  },
  methodVoidRefunds: (data) => {
    let transactions = data.currentActivities.data.transactions;
    let totals = {};

    totals.keyedRefunds = _.sumBy(transactions, (transaction) => {
      return transaction.type === 'Credit Refund' && transaction.cc_type === 'Keyed' && transaction.network !== 'Gift Card' ? parseFloat(transaction.amount) : 0;
    });
    totals.swipedRefunds = _.sumBy(transactions, (transaction) => {
      return transaction.type === 'Credit Refund' && transaction.cc_type === 'Swiped' && transaction.network !== 'Gift Card' ? parseFloat(transaction.amount) : 0;
    });
    totals.contactlessRefunds = _.sumBy(transactions, (transaction) => {
      if (transaction.type === 'Credit Refund'
        && (transaction.cc_type === 'Contactless' || transaction.cc_type === 'Contactless EMV')
      ) {
        return parseFloat(transaction.amount);
      };
      return 0;
    });
    totals.chipRefunds = _.sumBy(transactions, (transaction) => {
      return transaction.type === 'Credit Refund' && transaction.cc_type === 'Chip Read' ? parseFloat(transaction.amount) : 0;
    });
    totals.cashRefunds = _.sumBy(transactions, (transaction) => {
      return transaction.type === 'Cash Refund' ? parseFloat(transaction.amount) : 0;
    });
    totals.giftCardRefunds = _.sumBy(transactions, (transaction) => {
      return transaction.type === 'Credit Refund' && transaction.network === 'Gift Card' ? parseFloat(transaction.amount) : 0;
    });
    totals.achRefunds = _.sumBy(transactions, (transaction) => {
      return transaction.type === 'ACH Refund' ? parseFloat(transaction.amount) : 0;
    });

    totals.keyedVoids = _.sumBy(transactions, (transaction) => {
      return transaction.type === 'Void' && transaction.cc_type === 'Keyed' && transaction.network !== 'Gift Card' ? parseFloat(transaction.amount) : 0;
    });
    totals.swipedVoids = _.sumBy(transactions, (transaction) => {
      return transaction.type === 'Void' && transaction.cc_type === 'Swiped' && transaction.network !== 'Gift Card' ? parseFloat(transaction.amount) : 0;
    });
    totals.contactlessVoids = _.sumBy(transactions, (transaction) => {
      if (transaction.type === 'Void'
        && (transaction.cc_type === 'Contactless' || transaction.cc_type === 'Contactless EMV')
      ) {
        return parseFloat(transaction.amount);
      };
      return 0;
    });
    totals.chipVoids = _.sumBy(transactions, (transaction) => {
      return transaction.type === 'Void' && transaction.cc_type === 'Chip Read' ? parseFloat(transaction.amount) : 0;
    });
    totals.cashVoids = _.sumBy(transactions, (transaction) => {
      return transaction.type === 'Void' && transaction.cc_type === null ? parseFloat(transaction.amount) : 0;
    });
    totals.giftCardVoids = _.sumBy(transactions, (transaction) => {
      return transaction.type === 'Void' && transaction.network === 'Gift Card' ? parseFloat(transaction.amount) : 0;
    });
    totals.achVoids = _.sumBy(transactions, (transaction) => {
      return transaction.type === 'ACH Void' ? parseFloat(transaction.amount) : 0;
    });

    totals.netKeyed = data.keyedSales - Math.abs(totals.keyedRefunds + totals.keyedVoids);
    totals.netSwiped = data.swipedSales - Math.abs(totals.swipedRefunds + totals.swipedVoids);
    totals.netContactless = data.contactlessSales - Math.abs(totals.contactlessRefunds + totals.contactlessVoids);
    totals.netChip = data.chipSales - Math.abs(totals.chipRefunds + totals.chipVoids);
    totals.netCash = data.cashSales - Math.abs(totals.cashRefunds + totals.cashVoids);
    totals.netGiftCard = data.giftCardSales - Math.abs(totals.giftCardRefunds + totals.giftCardVoids);
    totals.netAch = data.achSales - Math.abs(totals.achRefunds + totals.achVoids);

    return totals;
  },
};

export default SalesDataUtil;
