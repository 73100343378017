import React, { Component } from 'react';
import IconButton from './IconButton';
import TextField from './TextField';
import IconUtil from '../util/IconUtil';

class PasswordField extends Component {
    constructor(props) {
      super(props);

      this.state = {
        isPasswordVisible: false
      }
    }

    onChange = () => {
      this.setState({ isPasswordVisible: !this.state.isPasswordVisible })
    }

    render() {
      const { isPasswordVisible } = this.state;
      const color = '#888C8D';
      const props = {
        ...this.props,
        type: isPasswordVisible ? 'string' : 'password',
        adornment: <IconButton
          iconStyle={{ color }}
          onClick={this.onChange}
          style={{ ...this.props.passwordstyle }}
          isPasswordField={true}
          size='large'>
          {IconUtil.getIcon(isPasswordVisible ? 'Visibility' : 'VisibilityOff', color)}
        </IconButton>
      }

      return <TextField { ...props } />
    }
}

export default PasswordField;
