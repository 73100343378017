/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';
import MidListItem from './MidListItem';
import MessageDialog from '../shared/MessageDialog';
import {getApiAccess, putApiAccess} from '../../actions/apiAccessActions';
import ApiAccessUtil from '../util/ApiAccessUtil';
import CredentialsType from '../../constants/credentialsType';
import _ from 'lodash';

class MidList extends React.Component {

  constructor(props) {
    super(props);
    this.state = { hoverMid: null, currentMid: null };
  }

  handleOnClick = (ev) => {
    const { user, hasAccess } = this.props;

    if (!hasAccess) return;

    let target = ev.target;

    while (target && target.nodeName !== 'LI') {
      target = target.parentNode; // If the clicked element isn't a direct child
      if (!target) { return; } // If element doesn't exist
    }

    const { midId, credentialType } = target.dataset;

    const merchantAccounts = user?.data?.merchantAccounts || [];
    const midName = merchantAccounts.find(account => `${account.mid}` === midId)?.dba_name;

    this.setState({ removeMidModal: true, midName, currentMid: midId, credentialType });
  }

  handleOnMouseOver = (ev) => {
    const { hasAccess } = this.props;
    const { midId } = ev.target.dataset;
    midId && hasAccess && this.setState({ hoverMid: midId });
  }

  handleOnMouseLeave = () => {
    this.setState({ hoverMid:  null});
  }

  buildRemovePayload = () => {
    const { currentMid, credentialType: credentialToRemove } = this.state;
    const { apiAccess: { accessData } } = this.props;

    const credentialsSettings = accessData.find(setting => setting.mid === currentMid);

    const keyMapping =  {
      [CredentialsType.iFrameAPI]: ['accessJSSDK', 'jsSDKDomain', 'jsSDKToken'],
      [CredentialsType.semiIntegratedAPI]: ['semiIntegration'],
      [CredentialsType.invoicingAPI]: ['accessPayNow'],
      [CredentialsType.webhookDomain]: ['accessWebhook', 'webhookDomain']
    }

    const keysToRemove = keyMapping[credentialToRemove];

    const credentialsSettingsToSend = _.omit(credentialsSettings, keysToRemove);

    const payload = {
      mid: currentMid,
      type: 'update_mid',
      ...credentialsSettingsToSend
    };

    return payload;
  }

  revokeMID = async () => {
    const { revokeAccess } = this.props;
    const payload = this.buildRemovePayload();
    await revokeAccess(payload);
  }

  closeDialog = () => {
    this.setState({ removeMidModal: null })
  }

  getMids = () => {
    const { user, apiAccess: { accessData }, credentialType } = this.props;
    let merchantAccounts = user?.data?.merchantAccounts?.filter(account => account.is_isv) || [];

    const currentMid = ApiAccessUtil.getCurrentMid(user);

    const currentMidFeatureSettings = accessData?.find(
      (settings) => `${settings.mid}` === `${currentMid}`
    );

    const areCredentialsPristine = accessData.every(setting => _.isEmpty(setting.proxy));
    const isFeatureEnabled = currentMidFeatureSettings?.[credentialType];

    if (!areCredentialsPristine && isFeatureEnabled) {
      const midsToDisplay = accessData.filter(settings => settings[credentialType]).map(settings => settings.mid);
      merchantAccounts = merchantAccounts.filter(merchantAccount => midsToDisplay.some(mid => `${mid}` === `${merchantAccount.mid}`))
    }

    return merchantAccounts;
  }

  renderMidList = () =>  {
    const { hasAccess, credentialType } = this.props;
    const { hoverMid } = this.state;

    const merchantAccounts = this.getMids();
    const removeLabel = <span className='remove'>Remove</span>;

    return merchantAccounts
      .map(merchantAccount =>
        <MidListItem
          credentialType={credentialType}
          key={merchantAccount.mea_id}
          merchantAccount={merchantAccount}
          label={ (merchantAccount.mid === hoverMid && hasAccess)? removeLabel : merchantAccount.mid}/>
      );
  }

  render = () => {
    const { user, t, hasAccess } = this.props;
    const { removeMidModal, midName, currentMid } = this.state;

    const merchantAccounts = user?.data?.merchantAccounts || [];
    const mids = this.getMids();

    if (merchantAccounts.length <= 1 || mids.length <= 1) return null;

    return (
      <>
        { hasAccess && <p className='title'>{t('ApiAccess.HoverRemove')}</p>}
        <ul className='midListPane'
          onClick={this.handleOnClick}
          onMouseLeave={this.handleOnMouseLeave}
          onMouseOver={this.handleOnMouseOver}>
          {this.renderMidList()}
        </ul>
        <MessageDialog
          titleText='Remove MID'
          confirmText={t('Ok')}
          cancelText={t('Cancel')}
          onRequestClose={this.closeDialog}
          onConfirm={this.revokeMID}
          open={Boolean(removeMidModal)}
          isChoiceRequired={false}
          dialogBodyClassName='removeMIDContent'
          externalClassName='removeMIDDialog'
          bodyText={
            <div className='removeMID'>
              <p> <span className='importantText'>{t('ApiAccess.Important')} </span>
                {t('ApiAccess.RemoveWarning')}
              </p>
              <div className='credential'>
                <div className='data end'>
                  <span className='dataKey'>{midName}&nbsp;</span>
                  <span className='dataValue end'>{currentMid}</span>
                </div>
              </div>
            </div>
          }/>
      </>
    );
  }
}

export default MidList;
