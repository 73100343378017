
import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import DetailPanel from '../../../shared/detailPanel/DetailPanel';
import CustomerReviewDetails from './CustomerReviewDetails';
import { submit } from 'redux-form';
import { addReviewReply, getReputationSettings } from '../../../../actions/reputationActions';

const styles = {dialogRoot: {width: '523px'}};

export const SyledDetailPanel = withStyles(styles)((props) => <DetailPanel {...props}/>);

class CustomerReviewDetailsPanel extends Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.onSaveClick = this.onSaveClick.bind(this);

      this.state = {
        displayReviewDetails: false,
      }

    }

    onSaveClick() {
      this.props.dispatch(submit('reviewResponseForm'));
    }


    async handleSubmit(values) {
      const reviewId = this.props.selectedReview?.reviewId;
      if (reviewId) {
        await this.props.dispatch(addReviewReply(this.props.user, reviewId, values.reply));
        this.props.hideReviewDetails();
        this.props.dispatch(getReputationSettings(this.props.user, this.props.userExperience.selectedDate.dateRange));
      }

    }


    render() {
        const {t, displayReviewDetails, selectedReview, hideReviewDetails, setEditMode, isEditing, reputationManagement: {isFetching, googleAccount}  } = this.props;
        const reviewDetails = displayReviewDetails ? (
            <SyledDetailPanel
              open={displayReviewDetails}
              shouldDisplay
              isNew={!selectedReview.reply || isEditing}
              confirmationText={t('Send')}
              onClick={this.onSaveClick}
              onCloseClick={hideReviewDetails}
            >
              <CustomerReviewDetails
                quickReplies={googleAccount?.replies}
                selectedReview={selectedReview}
                setEditMode={setEditMode}
                onSubmit={this.handleSubmit}
                isEditing={isEditing}
                t={t}
              />
            </SyledDetailPanel>
          ) : null;

        return reviewDetails;
    }
}


export default CustomerReviewDetailsPanel;
