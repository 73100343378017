/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import TextUtil from './util/FormatTextUtil';
import IconUtils from './util/IconUtil';
import RecurringUtil from './util/RecurringUtil';
import messages from './../constants/messages';
import { Link } from 'react-router-dom';
import routes from './../constants/routes';
import moment from 'moment';
import DeleteButton from './../components/DeleteButton';
import LabelUtil from './util/LabelUtil';

export const RecurringInvoiceInfoType = {
  CUSTOMER: 'CUSTOMER',
  INVOICE: 'INVOICE',
  TRANSACTION: 'TRANSACTION',
};

export class RecurringInvoiceInfo extends Component {

  constructor(props) {
    super(props);

    this.parseData = this.parseData.bind(this);
  }

  parseData(props, displayType) {

    let returnValue = [];

    if (displayType === RecurringInvoiceInfoType.CUSTOMER) {
      returnValue = RecurringUtil.dataForCustomer(props.data);
    } else if (displayType === RecurringInvoiceInfoType.INVOICE) {
      returnValue = RecurringUtil.dataForInvoice(props.invoices.selectedInvoice);
    } else {
      // AutopayInfoType.TRANSACTION
      returnValue = RecurringUtil.dataForTransaction(props.transactions.selectedReceipt);
    }

    return returnValue;
  }

  removeButton(invoiceName) {
    const {t} = this.props;

    return (
      <div className='autopayButton'>
        <DeleteButton
          type={'reverse'}
          className='removeAutopayButton'
          buttonLabel='Unenroll From Autopay'
          message={t(messages.autopay.confirmRemoveSeriesAutopay, {invoiceName})}
          {...this.props}
          handleDelete={this.props.onRemoveAutopay}
        />
      </div>
    )
  }

  endsDisplayText(endDate) {

    const { t } = this.props;

    if (endDate === null) {
      return t('NeverEnds');
    }

    let formattedEndDate = moment(endDate).format('MM/DD/YYYY');
    return moment(endDate).isSameOrAfter(moment(), 'day') ? `${t('Ends')} ${formattedEndDate}` : `${t('Ended')} ${formattedEndDate}`;
  }

  render() {

    const {displayType, t} = this.props;

    const recurringInvoices = this.parseData(this.props, displayType);

    const labelColor = LabelUtil.getLabelColor();

    const recurrenceIcon = IconUtils.getIcon('RecurrenceIcon', labelColor);
    const appRoutePrefix = globalApplicationLabel.path;

    const displayRecurringInfo = [RecurringInvoiceInfoType.CUSTOMER, RecurringInvoiceInfoType.TRANSACTION].includes(displayType);
    const displayRemoveButton = displayType === RecurringInvoiceInfoType.INVOICE;
    const displaySeriesName = displayType === RecurringInvoiceInfoType.CUSTOMER;



    return (
      <div className='detailDescriptionText detailDescriptionRow'>
        {recurringInvoices.map((invoice, i) => {

          const maskedCardNumber = TextUtil.formatMaskedCardNumber(invoice.firstSix, invoice.lastFour, invoice.card);

          const recurringPayment = (
            <div className='autopayHolder'>
              <div className='autopayIcon'>{recurrenceIcon}</div>
              <div className='autopayInfo'>
                <div className='autopayText'>{t('AutopayText')}</div>
                {displaySeriesName && <div className='autopayText'>{invoice.seriesId}</div>}
                {invoice.card && <div className='autopayText'>{invoice.card} {maskedCardNumber}</div>}
                {displayRemoveButton && this.removeButton(invoice.name)}
              </div>
            </div>);

          const viewLink = `${appRoutePrefix}${routes.business.root}${routes.business.invoices}?seriesId=${invoice.seriesId}`;
          const dayCount = parseInt(invoice.frequency.split(' days')[0]);

          return (
            <div className='recurringInvoice' key={i}>
              {displayRecurringInfo &&
              <div key={i} className='recurringDetail'>
                <div className='detailDescriptionText detailDescriptionRow'>
                  <Trans
                      count={dayCount}
                      i18nKey={'InvoiceFrequency'}
                  >
                    Every {{dayCount}} day
                  </Trans>
                </div>
                <div className='detailDescriptionText detailDescriptionRow'>
                  {this.endsDisplayText(invoice.endDate)}
                </div>
                <div className='detailDescriptionText detailDescriptionRow'>
                  <div className='viewRecurringButton'>
                    <Link
                        className='linkAsButton'
                        to={viewLink}
                    >
                      {t('ViewInvoice')}
                    </Link>
                  </div>
                </div>
              </div>}
              {recurringPayment}
            </div>);
        })}
      </div>
    );
  }

}

RecurringInvoiceInfo.propTypes = {
  displayType: PropTypes.oneOf([
    RecurringInvoiceInfoType.CUSTOMER,
    RecurringInvoiceInfoType.INVOICE,
    RecurringInvoiceInfoType.TRANSACTION
  ]).isRequired,
  t: PropTypes.func.isRequired
};

