/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Avatar from '@mui/material/Avatar';
import IconUtils from './util/IconUtil';
import IconButton from './shared/IconButton';
import MobileScrollNote from './shared/MobileScrollNote';


export default class MasterList extends React.Component {

  constructor(props) {
    super(props);
    this.onTableClick = this.onTableClick.bind(this);
  }

  onTableClick({target, currentTarget}) {
    const parent = target.parentElement;
    if(target !== currentTarget && target.matches('TD') && parent.matches('TR')) {
      this.props.onItemClick(parent.rowIndex);
    }
  }

  render() {

    const {searchBar, t, paginationComponent} = this.props;

    const mobileScrollNote = <MobileScrollNote t={t} />
    const avatarStyles = {width: 10, height: 10};
    const hasPagination = !!paginationComponent;

    if (this.props.tableData) {

      return (
        <div id='masterList' className={`masterListContainer ${hasPagination ? 'masterListContainerPagination' : ''}`}>
          <div className='scrollableArea'>

            <div className='searchContainer hideTablet'>{searchBar}</div>

            {this.props.header ? this.props.header : null}

            {this.props.hasMobileScrollNote && mobileScrollNote}

            <div className='masterTableContainer'>
              <Table
                onClick={this.onTableClick}
                style={{tableLayout: 'auto'}}
              >

                {this.props.tableHeader ? this.props.tableHeader : null}

                {this.props.tableRowLayout ? this.props.tableRowLayout :

                  <TableBody style={{width: '100%'}}>

                    {this.props.tableData.map((row, i) => (
                      <TableRow key={i} style={{height: this.props.listRowHeight}}>


                        {row.tid ?
                          <TableCell className='masterListColumn'
                                     style={{width: '50px'}}>
                            {row.tid}
                          </TableCell> : null}

                        {row.colorDot ?
                          <TableCell className='masterListColumn'
                                     style={{width: '10px'}}>
                            <IconButton
                              iconStyle={{backgroundColor: row.colorDot}}
                              disableTouchRipple={true}
                              size='large'>
                              <Avatar style={avatarStyles}>{''}</Avatar>
                            </IconButton>
                          </TableCell> : null}

                        {row.thumb ?
                          <TableCell style={{width: '10px'}}>
                            <Avatar style={avatarStyles}>{row.thumb}</Avatar>
                          </TableCell> : null}

                        {row.type ?
                          <TableCell style={{width: '10px'}}>
                            {IconUtils.getIcon(row.type,
                              row.type === 'deposit' ? '#63c21e' : '#ec251b'
                            )}
                          </TableCell> : null}

                        <TableCell>
                          <h3>{row.colOne}</h3>
                          {row.colOneSub ? <span
                            className='columnSubHead'>{row.colOneSub}</span> : null}
                        </TableCell>

                        <TableCell style={{width: '140px'}}>
                          <h3>{row.colTwo}</h3>
                          {row.colTwoSub ? <span
                            className='columnSubHead'>{row.colTwoSub}</span> : null}
                        </TableCell>
                      </TableRow>
                    ))}

                  </TableBody>
                }
              </Table>
            </div>

          </div>

          {hasPagination && (
            <div className='paginationHolder'>
              {paginationComponent}
            </div>
          )}
        </div>
      );

    } else {
      return (
        <div className='noData'>
          <div className='searchContainer hideTablet'>{searchBar}</div>
          {'No Data'}
        </div>
      );
    }

  }
}
