/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, {Component} from 'react';
import { submit } from 'redux-form';
import moment from 'moment';
import {
  setSelectedDateRange,
  setSelectedEmployeeFilter
} from '../actions/userExperienceActions';
import { IconButton, Menu, MenuItem } from '@mui/material';
import IconUtils from './util/IconUtil';
import DateUtils from './util/DateUtil';
import CustomDateFilter from './CustomDateFilter';
import CustomTimeFilter from './CustomTimeFilter';
import UpdateSpinner from './UpdateSpinner';
import PropTypes from 'prop-types';
import {
  updateCustomizedReportSettings,
  getMerchantSettings
} from '../actions/merchantSettingsActions';
import Modal from './shared/Modal';
import UserUtil from './util/UserUtil';
import _ from 'lodash';

class DateFilter extends Component {

  constructor(props) {
    super(props);

    this.handleCustomRange = this.handleCustomRange.bind(this);
    this.handleCustomTime = this.handleCustomTime.bind(this);
    this.handleCustomRangeClose = this.handleCustomRangeClose.bind(this);
    this.handleCustomTimeClose = this.handleCustomTimeClose.bind(this);
    this.onCustomDateRangeSubmit = this.onCustomDateRangeSubmit.bind(this);
    this.onCustomTimeRangeSubmit = this.onCustomTimeRangeSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleCustomRangeOpen = this.handleCustomRangeOpen.bind(this);
    this.handleCustomTimeOpen = this.handleCustomTimeOpen.bind(this);
    this.handleMenuOpen = this.handleMenuOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      selectedItem: null,
      open: false,
      openTime: false,
      loading: false,
      displayCustomRangeError: false,
      anchorEl: null,
    };

  }

  handleCustomRangeOpen() {
    this.setState({open: true});
    this.setState({ selectedItem: 'Custom Range' });
  }

  handleCustomRangeClose() {
    this.setState({open: false});
  }

  handleCustomTimeOpen() {
    this.setState({openTime: true});
  }

  handleCustomTimeClose() {
    this.setState({openTime: false});
  }

  onCustomDateRangeSubmit() {
    this.props.dispatch(submit('dateFilterForm'));
  }

  onCustomTimeRangeSubmit() {
    this.props.dispatch(submit('timeFilterForm'));
  }

  handleCustomTime(values) {

    const that = this;

    this.setState({
      openTime: false,
      loading: true
    });
    const from = moment(values.fromTime, 'HH:mm');
    const fromHours = from.hours().toString().length === 1 ? `0${from.hours()}` : `${from.hours()}`;
    const fromMinutes = from.minutes().toString().length === 1 ? `0${from.minutes()}` : `${from.minutes()}`;
    const fromSeconds = from.seconds().toString().length === 1 ? `0${from.seconds()}` : `${from.seconds()}`;

    const to = from.clone().add({ hours:23, minutes: 59, seconds: 59 });
    const toHours = to.hours().toString().length === 1 ? `0${to.hours()}` : `${to.hours()}`;
    const toMinutes = to.minutes().toString().length === 1 ? `0${to.minutes()}` : `${to.minutes()}`;
    const toSeconds = to.seconds().toString().length === 1 ? `0${to.seconds()}` : `${to.seconds()}`;

    const fromTime = `${fromHours}:${fromMinutes}:${fromSeconds}`;
    const toTime = `${toHours}:${toMinutes}:${toSeconds}`;

    this.props.dispatch(updateCustomizedReportSettings(this.props.user, {fromTime, toTime})).then(() => {
      that.setState({loading: false});
      that.props.dispatch(getMerchantSettings(that.props.user)).then((res) => {
        const startTime = (res.response && res.response.merchant_settings && res.response.merchant_settings.report_start) ? res.response.merchant_settings.report_start : '00:00:00';
        const dateRange = DateUtils.getPersonalizedDateRange('Today', startTime);
        that.props.dispatch(setSelectedDateRange('Today', dateRange));
        that.props.dispatch(setSelectedEmployeeFilter([]));
        that.props.handleSelection('Today', dateRange);
      });
    });

  }

  handleCustomRange(values) {

    // We allow 18 months for custom queries
    const customDateDifferenceDaysAllowed = 548;

    this.setState({open: false});

    const startDateTime = values.fromDate ? new Date(values.fromDate).toDateString() + ' ' + new Date(values.fromDate).toTimeString() : null;

    const endDateTime = values.toDate ? moment(values.toDate).startOf('day') > moment(values.fromDate).startOf('day') && new Date(values.toDate).toTimeString().startsWith('23:59') ? new Date(moment(values.toDate).subtract(1,'day')).toDateString() + ' ' + DateUtils.endOfDayTime() : new Date(values.toDate).toDateString() + ' ' + new Date(values.toDate).toTimeString() : null;

    const previousStartDateTime = values.fromDatePrevious ? new Date(values.fromDatePrevious).toDateString() + ' ' + new Date(values.fromDatePrevious).toTimeString() : null;

    const previousEndDateTime = values.toDatePrevious ? moment(values.toDatePrevious).startOf('day') > moment(values.fromDatePrevious).startOf('day') && new Date(values.toDatePrevious).toTimeString().startsWith('23:59') ? new Date(moment(values.toDatePrevious).subtract(1,'day')).toDateString() + ' ' + DateUtils.endOfDayTime() : new Date(values.toDatePrevious).toDateString() + ' ' + new Date(values.toDatePrevious).toTimeString() : null;

    const dateRange = DateUtils.getCustomDateRange(startDateTime, endDateTime, previousStartDateTime, previousEndDateTime);
    const dateDifference = dateRange.endDate.diff(dateRange.startDate, 'days');

    if (dateDifference > customDateDifferenceDaysAllowed) {

      this.setState({displayCustomRangeError: true});

    } else {

      this.setState({
        open: false,
        displayCustomRangeError: false
      });

      this.props.dispatch(setSelectedDateRange('Custom Range', dateRange));
      this.props.handleSelection('Custom Range', dateRange);
    }
  }

  handleClick(event) {
    const value = event.currentTarget.getAttribute('value');
    this.setState({selectedItem: value});

    const dateRange = DateUtils.getPersonalizedDateRange(value, this.props.merchantSettings.customReportStartTime);

    if (!value.includes('Custom')) {
      this.props.dispatch(setSelectedDateRange(value, dateRange));
      this.props.dispatch(setSelectedEmployeeFilter([]));
      this.props.handleSelection(value, dateRange);
    }

  }

  handleMenuOpen(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  render() {

    const {
      location,
      merchantSettings,
      showAllTime,
      showOpenBatch,
      t,
      user,
      userExperience,
    } = this.props;

    const dateIcon = IconUtils.getIcon('DateIcon', '#888C8D');
    const editIcon = IconUtils.getIcon('EditIcon', '#888C8D');
    const isLargeProcessor = UserUtil.isLargeProcessor(user);
    const styles = {
      iconButtonStyle: { backgroundColor: 'transparent', border: '0' },
      editIconStyle: { marginLeft: '30px' },
      menuItemHeaderStyle: {
        fontFamily: 'Raleway, sans-serif',
        fontSize: '13.5px',
        display: 'flex',
        justifyContent: 'space-between',
      },
    };

    const CustomDateDialog = (
      <Modal
        title={t('CustomDateRangeTitle')}
        confirmText={t('Ok')}
        cancelText={t('Cancel')}
        onClose={this.handleCustomRangeClose}
        onConfirm={this.onCustomDateRangeSubmit}
        open={this.state.open}
      >
        <CustomDateFilter {...this.props} displayCustomRangeError={this.state.displayCustomRangeError} onSubmit={this.handleCustomRange}/>
      </Modal>
    );

    const CustomTimeDialog = (
      <Modal
        title={t('CustomTimeRangeTitle')}
        contentClassName='customDateSelectorDialog'
        open={this.state.openTime}
        confirmText={t('Ok')}
        cancelText={t('Cancel')}
        onClose={this.handleCustomTimeClose}
        onConfirm={this.onCustomTimeRangeSubmit}
      >
        <CustomTimeFilter {...this.props} onSubmit={this.handleCustomTime}/>
      </Modal>
    );
    const labelTime = merchantSettings.customReportStartTime && DateUtils.getFormattedDates(merchantSettings.customReportStartTime);
    const customStartTime = labelTime && labelTime.fromTime.format('hh:mm:ss a');
    const customEndTime = labelTime && labelTime.toTime.format('hh:mm:ss a');
    const loading = this.state.loading ? <UpdateSpinner/> : null;
    const shouldItShowCustomOption = location.pathname.includes('test-drive');

    const menuValues = [
      'All Time',
      'Open Batch',
      'Today',
      'Yesterday',
      'This Week',
      'This Month',
      'This Year',
      'Last Week',
      'Last Month',
      'Last Year',
      'Custom Range',
    ];

    const menuItems = menuValues.map((value, i) => {
      let handleCustomRangeOpen;

      if (value === 'Today') {

        return (
          <MenuItem
            key={`item${i}`}
            onClick={this.handleClick}
            value={value}
            className={_.isEmpty(userExperience.selectedDate) || userExperience.selectedDate?.dateRange?.text === 'Today' ? 'active' : ''}
          >
            {value}
          </MenuItem>
        );
      }

      if (value === 'All Time' && !showAllTime) return null;
      if (value === 'Open Batch' && !showOpenBatch) return null;
      if (value === 'This Year' && isLargeProcessor) return null;
      if (value === 'Last Year' && isLargeProcessor) return null;
      if (value === 'Custom Range' && this.props.hideCustomDate) return null;

      if (value === 'Custom Range') {
        handleCustomRangeOpen = this.handleCustomRangeOpen;
      }

      return (
        <MenuItem
          key={`item${i}`}
          onClick={handleCustomRangeOpen || this.handleClick}
          value={value}
          className={userExperience.selectedDate?.dateRange?.text === value ? 'active' : ''}
        >
          {value}
        </MenuItem>
      );
    });

    return (
      <div className='dateFilter'>
        <IconButton onClick={this.handleMenuOpen} style={styles.iconButtonStyle} size='large'>
          {dateIcon}
        </IconButton>
        <Menu
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem style={styles.menuItemHeaderStyle} disabled={true}>
            <div className='uppercase'>{t('FilterByDate')}</div>
            <div>{dateIcon}</div>
          </MenuItem>
          {!shouldItShowCustomOption ?
            <MenuItem
              value='Custom Time'
              onClick={this.handleCustomTimeOpen}
            >
              <span>{customStartTime} - {customEndTime}</span>
              <span style={styles.editIconStyle}>{editIcon}</span>
            </MenuItem> : null}
          {menuItems}
        </Menu>
        {CustomDateDialog}
        {CustomTimeDialog}
        {loading}
      </div>
    );
  }
}

DateFilter.propTypes = {
  handleSelection: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  showAllTime: PropTypes.bool,
  showOpenBatch: PropTypes.bool,
  showPreviousCustomSelection: PropTypes.bool,
  alwaysUseDefaultValue: PropTypes.bool,
};

export default DateFilter;
