/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

*/

import React, {Component} from 'react';
import _ from 'lodash';
import IconUtils from '../../util/IconUtil';
import Validator from '../../util/Validator';
import {deleteReputationsReply, postReputationsReply} from '../../../actions/reputationActions';
import Modal from '../../shared/Modal';
import TextField from '../../shared/TextField';
import Select from '../../shared/Select';
import { MenuItem } from '@mui/material';
import { connect } from 'react-redux';
import QuickRepliesCategory from './QuickRepliesCategory';
import { Field, reduxForm, getFormValues } from 'redux-form';

export const NEUTRAL_CATEGORY = 'Neutral';
export const NEGATIVE_CATEGORY = 'Negative';
export const POSITIVE_CATEGORY = 'Positive';



export class QuickReplies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDeleteModal: false,
      openCreateModal: false,
      selectedReplyId: null,
    }

    this.categories = {
      [POSITIVE_CATEGORY]: <QuickRepliesCategory category={props.t('ReputationScreen.QuickRepliesPositive')}  icon='PositiveFaceIcon' />,
      [NEUTRAL_CATEGORY]:<QuickRepliesCategory category={props.t('ReputationScreen.QuickRepliesNeutral')}  icon='NeutralFaceIcon' />,
      [NEGATIVE_CATEGORY]: <QuickRepliesCategory category={props.t('ReputationScreen.QuickRepliesNegative')}  icon='NegativeFaceIcon' />
    };

    this.categoriesEntries = Object.entries(this.categories);
    this.categoriesKeys = Object.keys(this.categories);
  }

  resetState = () => {
    this.setState({selectedReplyId: null, openDeleteModal: false, openCreateModal: false});
    this.props.reset();
  }

  deleteReply = () => {
    this.props.dispatch(deleteReputationsReply(this.props.user, this.state.selectedReplyId));
    this.resetState();
  }

  createReply = (reply) => {
    this.props.dispatch(postReputationsReply(this.props.user, reply));
    this.resetState();
  }

  handleOpenDeleteModal = (id) => () => {
    this.setState({selectedReplyId: id, openDeleteModal: true});
  }

  handleOpenCreateModal = () => {
    this.setState({openCreateModal: true});
  }

  renderReply = ({reply, id}) => (
    <div className='reply' key={id}>
      {reply}
      <div className='replyDeleteIcon' onClick={this.handleOpenDeleteModal(id)}>
        {IconUtils.getIcon('DeleteIcon', '#231F20', 16)}
      </div>
    </div>
  );

  renderReplies = () => {
    const splittedRepliesByCategory = {};

    const {replies = []} = this.props;

    replies.forEach(({category, ...rest}) => {
      splittedRepliesByCategory[category]
      ? splittedRepliesByCategory[category].push(this.renderReply(rest))
      : splittedRepliesByCategory[category] = [this.renderReply(rest)];
    });

    return this.categoriesKeys
      .map(category => splittedRepliesByCategory[category] &&
        <div key={category}>
          {this.categories[category]}
          {splittedRepliesByCategory[category]}
        </div>
      );
    };

  render() {
    const {openDeleteModal, openCreateModal} = this.state;
    const {t, handleSubmit} = this.props;

    return (
      <section className='quickReplies'>
        <h1>
          {t('ReputationScreen.QuickReplies')}
        </h1>
        <h4>{t('ReputationScreen.QuickRepliesDescription')}</h4>
        <div className='addReply' onClick={this.handleOpenCreateModal}>
          <div className='plusIcon'>
            {IconUtils.getIcon('AddIcon', '#231F20', 10)}
          </div>
          <h4>{t('ReputationScreen.QuickReplyCreate')}</h4>
        </div>
        {this.renderReplies()}
        <Modal
          title={t('ReputationScreen.QuickRepliesRemoveTitle')}
          confirmText={t('ReputationScreen.QuickRepliesRemove')}
          cancelText={t('ReputationScreen.QuickRepliesCancel')}
          open={openDeleteModal}
          onClose={this.resetState}
          onConfirm={this.deleteReply}
        >
          {t('ReputationScreen.QuickRepliesRemoveDescription')}
        </Modal>
        <Modal
          title={t('ReputationScreen.QuickRepliesCreateTitle')}
          confirmText={t('Save')}
          cancelText={t('Cancel')}
          open={openCreateModal}
          onClose={this.resetState}
          onConfirm={handleSubmit(this.createReply)}
          fullWidth
        >
          <>
            <Field
              component={Select}
              t={t}
              label={t('ReputationScreen.QuickRepliesCategory')}
              name='category'
              className='quickRepliesCategory'
            >
              {this.categoriesEntries.map(([value, label]) => (
                <MenuItem key={value} value={value} className='quickRepliesCategoryItem'>
                  {label}
                </MenuItem>))
              }
            </Field>
            <Field
              component={TextField}
              name='text'
              label={t('ReputationScreen.QuickRepliesMessage')}
              hintText={t('ReputationScreen.QuickRepliesMessageLimit', {limit: 350})}
              maxLength={350}
              multiline
              rows={6}
            />
          </>
        </Modal>
      </section>
    );
  }
}

const QUICK_REPLIES_FORM_ID = 'quickRepliesForm';

export const validate = (values) => {
  return (!_.isEmpty(values)) ? Validator.validateQuickReplyForm(values) : {};
};

let QuickRepliesFormContainer = reduxForm({
  validate,
  fields: [
    'category',
    'text'
  ],
  form: QUICK_REPLIES_FORM_ID,
  enableReinitialize: true,
  shouldError: () => true,
})(QuickReplies);

function mapStateToProps(state) {
  const currentValues = getFormValues(QUICK_REPLIES_FORM_ID)(state) || {};

  return {
    currentValues,
    isProcessing: false,
    initialValues: {
        category: '',
        text: '',
    },
  };
}

export default connect(mapStateToProps)(QuickRepliesFormContainer);
