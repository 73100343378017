import actionTypes from '../constants/actionTypes';
import localStorageUtil from '../components/util/LocalStorageUtil';
import applicationConstants from '../constants/applicationConstants';
import labels from '../constants/labels';

const authReducer = (state = {
  isFetching: false,
  isSpr: localStorageUtil.getRole(localStorage?.getItem('pa-ma'), 'spr'),
  isReport: localStorageUtil.getRole(localStorage?.getItem('pa-ma'), 'role') === 'report',
  isOwner: localStorageUtil.getRole(localStorage?.getItem('pa-ma'), 'role') === 'owner',
  isAdmin: localStorageUtil.getRole(localStorage?.getItem('pa-ma'), 'role') === 'admin',
  isManager: localStorageUtil.getRole(localStorage?.getItem('pa-ma'), 'role') === 'manager',
  isManagerVT: localStorageUtil.getRole(localStorage?.getItem('pa-ma'), 'manager_online_payments_access_enabled'),
  isManagerActivity: localStorageUtil.getRole(localStorage?.getItem('pa-ma'), 'manager_activity_access_enabled'),
  isManagerInvoice: localStorageUtil.getRole(localStorage?.getItem('pa-ma'), 'manager_can_access_invoices'),
  isManagerReputation: localStorageUtil.getRole(localStorage?.getItem('pa-ma'), 'manager_can_access_reputation_mgmt'),
  isManagerPaymentLinks: localStorageUtil.getRole(localStorage?.getItem('pa-ma'), 'manager_can_access_payment_links'),
  hasAcceptedTermsAndConditions: localStorageUtil.getRole(localStorage?.getItem('pa-ma'), 'accepted_terms_conditions'),
  termsAndConditionsID: localStorageUtil.getRole(localStorage?.getItem('pa-ma'), 'tc_id'),
  termsAndConditionsEnabled: localStorageUtil.getRole(localStorage?.getItem('pa-ma'), 'tc_enabled'),
  role: localStorageUtil.getRole(localStorage?.getItem('pa-ma'), 'role'),
  needsInfo: localStorageUtil.getRole(localStorage?.getItem('pa-ma'), 'needs_info'),
  loginError: null,
  forgotPasswordSent: null,
  forgotPasswordTokenValidated: null,
  forgotPasswordError: null,
  resetPasswordError: null,
  setPasswordSent: null,
  resetPasswordData: null,
  setPasswordData: null,
  setPasswordTokenValidated: null,
  setPasswordError: null,
  addAccountsData: null,
  addAccountsError: null,
  newToken: null,
  tokenAutoRenew: false,
  savingAgreement: false,
  forceResetPassword: false,
  twoFactorEnabled: false,
  twoFactorSetupEnabled: false,
  twoFactorFormPhoneNumber: null,
  twoFactorFormFlag: null,
  twoFactorCodeStatus: applicationConstants.codeStatus.default,
  twoFactorPhoneStatus: applicationConstants.phoneVerificationStatus.default,
  twoFactorError: null,
  twoFactorMessage: null,
  actionRequiredToken: null,
  isAuthenticated: localStorage?.getItem('pa-token') ? true : false,
  showExpirePasswordMessage: false,
  menuActive: false,
  desktopToken: null,
  debtRepayment: localStorageUtil.getRole(localStorage?.getItem('pa-ma'), 'dp_allowed'),
  processingLevel: localStorageUtil.getRole(localStorage?.getItem('pa-ma'), 'processing_level'),
  leadSignupUser: null,
  leadSignupError: null,
  leadSignupSuccess: false
}, action) => {

  switch (action.type) {
    case actionTypes.loginRequest:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false,
        loginError: null,
        forgotPasswordTokenValidated: null,
        resetPasswordError: null,
        forgotPasswordSent: null,
        setPasswordTokenValidated: null,
        setPasswordError: null,
        setPasswordSent: null,
        resetPasswordData: null,
        setPasswordData: null,
        addAccountsData: null,
        addAccountsError: null,
        twoFactorEnabled: false,
        twoFactorSetupEnabled: false,
        twoFactorFormPhoneNumber: null,
        twoFactorFormFlag: null,
        twoFactorCodeStatus: applicationConstants.codeStatus.default,
        twoFactorPhoneStatus: applicationConstants.phoneVerificationStatus.default,
        twoFactorError: null,
        twoFactorMessage: null,
        actionRequiredToken: null,
        newToken: null,
        isSpr: null,
        isReport: null,
        isOwner: null,
        isAdmin: null,
        isManager: null,
        isManagerVT: null,
        isManagerActivity: null,
        isManagerReputation: null,
        isManagerInvoice: null,
        isManagerPaymentLinks: null,
        hasAcceptedTermsAndConditions: null,
        termsAndConditionsEnabled: false,
        role: null,
        desktopToken: null
      });
    case actionTypes.loginSuccess:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: true,
        loginError: null
      });
    case actionTypes.loginFailure:
      let error = action.message;
      if (error === 'Server.Error.UnsuccessfulSignInPA' &&
        [labels.ph.abbreviation, labels.nh.abbreviation].includes(globalApplicationLabel.abbreviation) ) {
        error = 'Server.Error.UnsuccessfulSignInPH';
      }
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        loginError: error,
        twoFactorCodeStatus: applicationConstants.codeStatus.error,
      });
    case actionTypes.loginFormError:
      return{
        ...state,
        loginError: action.message
      }
    case actionTypes.setActionRequiredSession:
      return{
        ...state,
        actionRequiredToken: action.token
      }
    case actionTypes.cleanExpiredPasswordSession:
      return{
        ...state,
        actionRequiredToken: null
      }
    case actionTypes.forceResetPasswordLoginRequest:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false,
        loginError: null,
        forgotPasswordTokenValidated: null,
        resetPasswordError: null,
        forgotPasswordSent: null,
        setPasswordTokenValidated: null,
        setPasswordError: null,
        setPasswordSent: null,
        resetPasswordData: null,
        setPasswordData: null,
        addAccountsData: null,
        addAccountsError: null,
        twoFactorEnabled: false,
        twoFactorSetupEnabled: false,
        twoFactorCodeStatus: applicationConstants.codeStatus.default,
        twoFactorError: null,
        twoFactorMessage: null,
        newToken: null,
        isSpr: null,
        isReport: null,
        isOwner: null,
        isAdmin: null,
        isManager: null,
        isManagerVT: null,
        isManagerActivity: null,
        isManagerReputation: null,
        isManagerInvoice: null,
        isManagerPaymentLinks: null,
        hasAcceptedTermsAndConditions: null,
        termsAndConditionsEnabled: false,
        role: null,
        desktopToken: null
      });
    case actionTypes.accountSuccess:
      return Object.assign({}, state, {
        isReport: action.role === 'report',
        isOwner: action.role === 'owner',
        isAdmin: action.role === 'admin',
        isManager: action.role === 'manager',
        isManagerVT: action.managerVt,
        isManagerReputation: action.managerReputation,
        isManagerActivity: action.managerActivity,
        isManagerInvoice: action.managerInvoice,
        isManagerPaymentLinks: action.managerPaymentLinks,
        hasAcceptedTermsAndConditions: action.acceptedTermsAndConditions,
        termsAndConditionsID: action.termsAndConditionsID,
        termsAndConditionsEnabled: (action.user.merchantAccounts?.[0].is_demo ? false : action.termsAndConditionsEnabled),
        savingAgreement: false,
        role: action.role,
        needsInfo: action.needsInfo,
        debtRepayment: action.debtRepayment,
        processingLevel: action.processingLevel
      });
    case actionTypes.changeSelectedMerchantAccount:
      return Object.assign({}, state, {
        isReport: localStorageUtil.getRole(action.selectedMerchantAccount, 'role') === 'report',
        isOwner: localStorageUtil.getRole(action.selectedMerchantAccount, 'role') === 'owner',
        isAdmin: localStorageUtil.getRole(action.selectedMerchantAccount, 'role') === 'admin',
        isManager: localStorageUtil.getRole(action.selectedMerchantAccount, 'role') === 'manager',
        isManagerVT: localStorageUtil.getRole(action.selectedMerchantAccount, 'manager_online_payments_access_enabled'),
        isManagerReputation: localStorageUtil.getRole(action.selectedMerchantAccount, 'manager_can_access_reputation_mgmt'),
        isManagerActivity: localStorageUtil.getRole(action.selectedMerchantAccount, 'manager_activity_access_enabled'),
        isManagerInvoice: localStorageUtil.getRole(action.selectedMerchantAccount, 'manager_can_access_invoices'),
        isManagerPaymentLinks: localStorageUtil.getRole(action.selectedMerchantAccount, 'manager_can_access_payment_links'),
        hasAcceptedTermsAndConditions: localStorageUtil.getRole(action.selectedMerchantAccount, 'accepted_terms_conditions'),
        termsAndConditionsID: localStorageUtil.getRole(action.selectedMerchantAccount, 'tc_id'),
        termsAndConditionsEnabled: localStorageUtil.getRole(action.selectedMerchantAccount, 'tc_enabled'),
        role: localStorageUtil.getRole(action.selectedMerchantAccount, 'role'),
        needsInfo: localStorageUtil.getRole(action.selectedMerchantAccount, 'needs_info'),
        debtRepayment: localStorageUtil.getRole(action.selectedMerchantAccount, 'dp_allowed'),
        processingLevel: localStorageUtil.getRole(action.selectedMerchantAccount, 'processing_level')
      });
    case actionTypes.validateResetPasswordTokenRequest:
      return Object.assign({}, state, {
        isFetching: true,
        forgotPasswordTokenValidated: null,
        resetPasswordError: null,
        resetPasswordData: null
      });
    case actionTypes.validateResetPasswordTokenSuccess:
      return Object.assign({}, state, {
        isFetching: false,
        forgotPasswordTokenValidated: true,
        resetPasswordError: null,
        resetPasswordData: action.response
      });
    case actionTypes.validateResetPasswordTokenFailure:
      return Object.assign({}, state, {
        isFetching: false,
        forgotPasswordTokenValidated: false,
        resetPasswordError: action.response || 'Error Token Invalid or Expired',
        resetPasswordData: null
      });
    case actionTypes.validateSetPasswordTokenRequest:
      return Object.assign({}, state, {
        isFetching: true,
        setPasswordTokenValidated: null,
        setPasswordError: null,
        setPasswordSent: null,
        setPasswordData: null
      });
    case actionTypes.validateSetPasswordTokenSuccess:
      return Object.assign({}, state, {
        isFetching: false,
        setPasswordTokenValidated: true,
        setPasswordError: null,
        setPasswordSent: null,
        setPasswordData: action.response
      });
    case actionTypes.validateSetPasswordTokenFailure:
      return Object.assign({}, state, {
        isFetching: false,
        setPasswordTokenValidated: false,
        setPasswordError: action.message || 'Error Token Invalid or Expired',
        setPasswordSent: null,
        setPasswordData: null
      });
    case actionTypes.userForgotPasswordRequest:
      return Object.assign({}, state, {
        isFetching: true,
        forgotPasswordSent: null,
        forgotPasswordError: null
      });
    case actionTypes.userForgotPasswordSuccess:
      return Object.assign({}, state, {
        isFetching: false,
        forgotPasswordSent: true,
        forgotPasswordError: action.response && action.response.message ? action.response.message : null
      });
    case actionTypes.userForgotPasswordFailure:
      return Object.assign({}, state, {
        isFetching: false,
        forgotPasswordSent: false,
        forgotPasswordError: action.response && action.response.message ? action.response.message : null
      });
    case actionTypes.clearForgotPasswordData:
      return Object.assign({}, state, {
        isFetching: false,
        forgotPasswordSent: null,
        forgotPasswordError: null
      });
    case actionTypes.userResetPasswordRequest:
      return Object.assign({}, state, {
        isFetching: true,
        forgotPasswordSent: null,
        resetPasswordError: null
      });
    case actionTypes.userResetPasswordSuccess:
      return Object.assign({}, state, {
        isFetching: false,
        forgotPasswordSent: true
      });
    case actionTypes.userResetPasswordFailure:
      return Object.assign({}, state, {
        isFetching: false,
        forgotPasswordSent: false,
        resetPasswordError: action.error || 'Error resetting password'
      });
    case actionTypes.userSetPasswordRequest:
      return Object.assign({}, state, {
        isFetching: true,
        setPasswordSent: null,
        setPasswordError: null
      });
    case actionTypes.userSetPasswordSuccess:
      return Object.assign({}, state, {
        isFetching: false,
        setPasswordSent: true
      });
    case actionTypes.userSetPasswordFailure:
      return Object.assign({}, state, {
        isFetching: false,
        setPasswordSent: false,
        setPasswordError: 'Error setting password'
      });
    case actionTypes.addAccountsRequest:
      return Object.assign({}, state, {
        isFetching: true,
        addAccountsData: null,
        addAccountsError: null
      });
    case actionTypes.addAccountsSuccess:
      return Object.assign({}, state, {
        isFetching: false,
        addAccountsData: action.response,
        addAccountsError: null
      });
    case actionTypes.addAccountsFailure:
      return Object.assign({}, state, {
        isFetching: false,
        addAccountsData: null,
        addAccountsError: action.error
      });
    case actionTypes.clearMidMessagesForUI:
      return Object.assign({}, state, {
        isFetching: false,
        addAccountsData: null,
        addAccountsError: null
      });
    case actionTypes.clearForgotPasswordSent:
      return Object.assign({}, state, {
        isFetching: false,
        forgotPasswordSent: null,
      });
    case actionTypes.sendNotificationEmailRequest:
      return Object.assign({}, state, {
        isFetching: true,
        sentEmail: false
      });
    case actionTypes.sendNotificationEmailSuccess:
      return Object.assign({}, state, {
        isFetching: false,
        sentEmail: true
      });
    case actionTypes.sendNotificationEmailFailure:
      return Object.assign({}, state, {
        isFetching: false,
        sentEmail: false
      });
    case actionTypes.validateCSRequest:
      return Object.assign({}, state, {
        isFetching: true,
        isSpr: null,
      });
    case actionTypes.validateCSSuccess:
      return Object.assign({}, state, {
        isFetching: false,
        isSpr: true,
        role: 'owner'
      });
    case actionTypes.validateCSFailure:
      return Object.assign({}, state, {
        isFetching: false,
        isSpr: false,
      });
    case actionTypes.forceResetPasswordRequired:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        forceResetPassword: action.forceResetPassword,
        twoFactorEnabled: false,
        twoFactorSetupEnabled: false,
        twoFactorCodeStatus: applicationConstants.codeStatus.default,
        twoFactorPhoneStatus: applicationConstants.phoneVerificationStatus.default,
      });
    case actionTypes.force2FASetupRequired:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        twoFactorEnabled: false,
        twoFactorCodeStatus: applicationConstants.codeStatus.default,
        twoFactorPhoneStatus: applicationConstants.phoneVerificationStatus.default,
      });
    case actionTypes.twoFactorTokenRequired:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        twoFactorEnabled: action.authyEnabled,
        twoFactorSetupEnabled: action.setup2FA,
        twoFactorCodeStatus: applicationConstants.codeStatus.sent
      });
    case actionTypes.twoFactorSendTokenRequest:
      return {
        ...state,
        loginError:null,
        twoFactorCodeStatus: applicationConstants.codeStatus.sending
      };
    case actionTypes.twoFactorSendTokenSuccess:
      return {
        ...state,
        twoFactorCodeStatus: applicationConstants.codeStatus.sent,
        twoFactorError: null,
        twoFactorMessage: action.response
      };
    case actionTypes.twoFactorSendTokenFailure:
      return {
        ...state,
        twoFactorCodeStatus: applicationConstants.codeStatus.default,
        twoFactorError: action.error,
        twoFactorMessage: null
      };
    case actionTypes.twoFactorVerifyTokenRequest:
    case actionTypes.twoFactorVerifyRegistrationTokenRequest:
      return {
        ...state,
        twoFactorCodeStatus: applicationConstants.codeStatus.validating
      };
    case actionTypes.twoFactorVerifyTokenSuccess:
      return {
        ...state,
        isAuthenticated: true,
        twoFactorCodeStatus: applicationConstants.codeStatus.verified
      };
    case actionTypes.twoFactorVerifyRegistrationTokenFailure:
    case actionTypes.twoFactorVerifyRegistrationTokenSuccess:
      return {
        ...state,
        twoFactorCodeStatus: applicationConstants.codeStatus.default
      };
    case actionTypes.setup2faVerifyTokenSuccess:
      return {
        ...state,
        twoFactorCodeStatus: applicationConstants.codeStatus.verified
      };
    case actionTypes.twoFactorVerifyTokenFailure:
      return {
        ...state,
        isFetching: false,
        twoFactorCodeStatus: applicationConstants.codeStatus.error,
        loginError: 'Unable to verify code. Please try again.'
      };
    case actionTypes.twoFactorTokenValidating:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
        twoFactorCodeStatus: applicationConstants.codeStatus.validating,
        loginError: null
      };
    case actionTypes.refreshTokenRequest:
      return Object.assign({}, state, {
        isFetching: true,
        newToken: null
      });
    case actionTypes.refreshTokenSuccess:
      return Object.assign({}, state, {
        isFetching: false,
        newToken: action.response.token
      });
    case actionTypes.refreshTokenFailure:
      return Object.assign({}, state, {
        isFetching: false,
        newToken: null
      });
    case actionTypes.desktopTokenRequest:
      return Object.assign({}, state, {
        isFetching: true,
        desktopToken: null
      });
    case actionTypes.desktopTokenSuccess:
      return Object.assign({}, state, {
        isFetching: false,
        desktopToken: action.response.token
      });
    case actionTypes.desktopTokenFailure:
      return Object.assign({}, state, {
        isFetching: false,
        desktopToken: null
      });
    case actionTypes.agreementSaveFailure:
      return Object.assign({}, state, {
        savingAgreement: false
      });
    case actionTypes.agreementSaveSuccess:
      return Object.assign({}, state, {
        savingAgreement: true
      });
    case actionTypes.agreementSaveRequest:
      return Object.assign({}, state, {
        savingAgreement: true,
      });
    case actionTypes.setTokenAutoRenew:
      return Object.assign({}, state, {
        tokenAutoRenew: action.tokenAutoRenew
      });
    case actionTypes.setResponsiveMenu:
      return Object.assign({}, state, {
        menuActive: action.isActive
      });
    case actionTypes.twoFactorPhoneCodeRequest:
      return {
        ...state,
        twoFactorCodeStatus: applicationConstants.codeStatus.sending,
        twoFactorPhoneStatus: applicationConstants.phoneVerificationStatus.verifying
      };
    case actionTypes.twoFactorPhoneCodeSuccess:
      return {
        ...state,
        twoFactorCodeStatus: applicationConstants.codeStatus.sent,
        twoFactorPhoneStatus: applicationConstants.phoneVerificationStatus.verified
      };
    case actionTypes.twoFactorPhoneCodeFailure:
      return {
        ...state,
        twoFactorCodeStatus: applicationConstants.codeStatus.error,
        twoFactorPhoneStatus: applicationConstants.phoneVerificationStatus.error
      };
    case actionTypes.twoFactorPhoneVerifyRequest:
      return {
        ...state,
        twoFactorCodeStatus: applicationConstants.codeStatus.validating
      };
    case actionTypes.twoFactorPhoneVerifySuccess:
      return {
        ...state,
        isAuthenticated: true,
        twoFactorCodeStatus: applicationConstants.codeStatus.verified
      };
    case actionTypes.twoFactorPhoneVerifyFailure:
      return {
        ...state,
        twoFactorCodeStatus: applicationConstants.codeStatus.error
      };
    case actionTypes.setOpenExpirePasswordMessage:
      return {
        ...state,
        showExpirePasswordMessage: true
      }
    case actionTypes.setCloseExpirePasswordMessage:
      return {
        ...state,
        showExpirePasswordMessage: false
      }
    case actionTypes.logoutSuccess:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        loginError: null,
        forgotPasswordError: null,
        forgotPasswordTokenValidated: null,
        resetPasswordError: null,
        forgotPasswordSent: null,
        setPasswordTokenValidated: null,
        setPasswordError: null,
        setPasswordSent: null,
        resetPasswordData: null,
        setPasswordData: null,
        addAccountsData: null,
        addAccountsError: null,
        isSpr: null,
        isReport: null,
        isOwner: null,
        isAdmin: null,
        isManager: null,
        isManagerVT: null,
        isManagerReputation: null,
        isManagerActivity: null,
        isManagerInvoice: null,
        isManagerPaymentLinks: null,
        hasAcceptedTermsAndConditions: null,
        termsAndConditionsID: null,
        termsAndConditionsEnabled: false,
        role: null,
        needsInfo: null,
        newToken: null,
        tokenTermsRenew: null,
        savingAgreement: false,
        tokenAutoRenew: false,
        forceResetPassword: false,
        twoFactorEnabled: false,
        twoFactorSetupEnabled: false,
        twoFactorFormPhoneNumber: null,
        twoFactorFormFlag: null,
        twoFactorCodeStatus: applicationConstants.codeStatus.default,
        twoFactorPhoneStatus: applicationConstants.phoneVerificationStatus.default,
        twoFactorError: null,
        twoFactorMessage: null,
        actionRequiredToken: null,
        menuActive: false,
        debtRepayment: null,
        processingLevel: null,
        desktopToken: null
      });
    case actionTypes.update2FAForm:
      return { ...state,
        twoFactorFormPhoneNumber: action.phoneNumber,
        twoFactorFormFlag: action.twoFactorEnabled
      };
    case actionTypes.reset2FACodeStatus:
      return { ...state,
        twoFactorCodeStatus: applicationConstants.codeStatus.default,
      };
    case actionTypes.reset2FAPhoneStatus:
      return {
        ...state,
        twoFactorPhoneStatus: applicationConstants.phoneVerificationStatus.default
      };
    case actionTypes.postApiAccessRequest:
      return  {
        ...state,
        twoFactorCodeStatus: applicationConstants.codeStatus.validating
      };
    case actionTypes.postApiAccessSuccess:
      return  {
        ...state,
        twoFactorCodeStatus: applicationConstants.codeStatus.verified
      };
    case actionTypes.postApiAccessFailure:
      return  {
        ...state,
        twoFactorCodeStatus: applicationConstants.codeStatus.default
      };
    case actionTypes.putApiAccessRequest:
      return  {
        ...state,
        twoFactorCodeStatus: applicationConstants.codeStatus.validating
      };
    case actionTypes.putApiAccessSuccess:
      return  {
        ...state,
        twoFactorCodeStatus: applicationConstants.codeStatus.verified
      };
    case actionTypes.putApiAccessFailure:
      return  {
        ...state,
        twoFactorCodeStatus: applicationConstants.codeStatus.default
      };
    case actionTypes.toggleSavingAgreement:
      return  {
        ...state,
        savingAgreement: false
      };
    case actionTypes.twoFactorTokenValid:
      return {
        ...state,
        isAuthenticated: true,
        twoFactorCodeStatus: applicationConstants.codeStatus.default,
        twoFactorPhoneStatus: applicationConstants.phoneVerificationStatus.default,
      };

    case actionTypes.leadSignupRequest:
      return {
        ...state,
        isFetching: true,
        leadSignupError: null
      };
    case actionTypes.leadSignupFailure:
      let errorMessage = null;
      try {
        errorMessage = JSON.parse(action.error);
      } catch {
        if (action.error && _.isString(action.error)) {
          errorMessage = {
            reason: action.error
          };
        } else {
          errorMessage = {};
        }
      }

      return {
        ...state,
        isFetching: false,
        leadSignupError: errorMessage.reason ?? 'Could not not create account'
      };
    case actionTypes.leadSignupSuccess:
      return {
        ...state,
        isFetching: false,
        leadSignupUser: action.response
      };
    case actionTypes.clearLeadSignup:
      return {
        ...state,
        isFetching: false,
        leadSignupError: action.error,
        leadSignupUser: null
      };
    case actionTypes.setLeadError:
      return {
        ...state,
        leadSignupError: action.error
      };
    default:
      return state;
  }
};

export default authReducer;


/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

