/**
North American Bancard ("NAB") CONFIDENTIAL MATERIAL

Copyright 2000 NAB, All Rights Reserved.

NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
and Non-disclosure agreements explicitly covering such access.

The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
MAY DESCRIBE, IN WHOLE OR IN PART.

*/

import React from 'react';
import {Field, reduxForm, getFormValues, reset} from 'redux-form'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CustomToggle from '../shared/Toggle';
import Select from '../shared/Select';
import TextField from '../shared/TextField';
import messages from '../../constants/messages';

import Validator from '../util/Validator';
import ScheduleReportUtil, {ReportFrequency} from '../util/ScheduleReportUtil';
import MobileUtil from '../util/MobileUtil';
import MenuItem from '../shared/MenuItem';

export const validate = values => {
  return Validator.validateScheduleReport(values);
};

export class ScheduleReportForm extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.toggleReport && this.props.toggleReport) {
      this.props.dispatch(reset('scheduleReportForm'));
    }
  }

  render() {
    const {toggleReport, toggleReportAction, reportName, currentValues, handleSubmit, t} = this.props;
    const isMobile = MobileUtil.isMobileDevice();
    const isNotDailyFrequency  = currentValues && currentValues.frequency !== ReportFrequency.DAILY;

    return (
      <form onSubmit={handleSubmit}>
        <div className='scheduleReportForm'>
          {t(messages.scheduleReportForm.text)}
          <div className='reportToggle'>
            <CustomToggle
              label={`${t('ScheduledReports.ScheduleReport')} "${t(reportName)}" ${t('ScheduledReports.Report')}`}
              input={{
                value: toggleReport,
                onChange: toggleReportAction
              }}
            />
          </div>
          {
            toggleReport &&
              <div className='activeReport'>
                <Field
                  label={t('ScheduledReports.SendTo')}
                  name='email'
                  ref='email'
                  component={TextField}/>
                <Field
                  hintText={t('ScheduledReports.AdditionalEmailHint')}
                  label={t('ScheduledReports.AdditionalEmail')}
                  component={TextField}
                  name='additionalEmail'
                  ref='additionalEmail'/>
                <div className={isNotDailyFrequency && !isMobile ? 'frequencyFieldsContainer' : ''}>
                  <div className='frequencyField'>
                    <Field
                      label={t('ScheduledReports.Frequency')}
                      component={Select}
                      name='frequency'
                      ref='frequency'
                    >
                      <MenuItem value={ReportFrequency.DAILY} primaryText={t('ScheduledReports.DailyReport')}>{t('ScheduledReports.DailyReport')}</MenuItem>
                      <MenuItem value={ReportFrequency.WEEKLY} primaryText={t('ScheduledReports.WeeklyReport')}>{t('ScheduledReports.WeeklyReport')}</MenuItem>
                      <MenuItem value={ReportFrequency.MONTHLY} primaryText={t('ScheduledReports.MonthlyReport')}>{t('ScheduledReports.MonthlyReport')}</MenuItem>
                    </Field>
                  </div>

                  {
                    isNotDailyFrequency &&
                      <div className='frequencyField'>
                        {
                          this.props.currentValues.frequency === ReportFrequency.MONTHLY ?
                            <Field
                              label={t('ScheduledReports.SendOnThe')}
                              component={Select}
                              name='dayOfTheMonth'
                              ref='dayOfTheMonth'>
                                {ScheduleReportUtil.getMonthlyReportOptions().map(({value, text}) => {
                                  return <MenuItem value={value} key={value} primaryText={t(text)}>{t(text)}</MenuItem>;
                                })}
                            </Field>
                          :
                            <Field
                              label={t('ScheduledReports.SendOn')}
                              component={Select}
                              name='dayOfTheWeek'
                              ref='dayOfTheWeek'>
                                {ScheduleReportUtil.getWeeklyReportOptions().map(({value, text}) => {
                                  return <MenuItem value={value} key={value} primaryText={t(text)}>{t(text)}</MenuItem>;
                                })}
                            </Field>
                        }
                      </div>
                  }
                </div>
            </div>
          }
        </div>
      </form>
    );
  }
};

ScheduleReportForm.propTypes = {
  reportName: PropTypes.string.isRequired,
  merchantEmail: PropTypes.string.isRequired,
  toggleReport: PropTypes.bool.isRequired,
  toggleReportAction: PropTypes.func.isRequired
};

let ScheduleReportContainer = reduxForm({
  form: 'scheduleReportForm',
  validate,
  fields: ['email', 'additionalEmail', 'frequency', 'dayOfTheWeek', 'dayOfTheMonth'],
  enableReinitialize: true,
})(ScheduleReportForm);

function mapStateToProps(state, ownProps) {
  const currentValues = getFormValues('scheduleReportForm')(state) || {};

  const scheduledInfo = ownProps.scheduledInfo;

  const firstEmail = scheduledInfo?.email_to[0];
  const additionalEmail = scheduledInfo?.email_to[1];

  return {
    initialValues: {
      email: firstEmail ? firstEmail : ownProps.merchantEmail,
      additionalEmail: additionalEmail ? additionalEmail : '',
      frequency: scheduledInfo ? ReportFrequency[scheduledInfo.frequency] : ReportFrequency.DAILY,
      dayOfTheMonth: scheduledInfo && ReportFrequency[scheduledInfo.frequency] === ReportFrequency.MONTHLY ? +scheduledInfo.send_on : 1,
      dayOfTheWeek: scheduledInfo && ReportFrequency[scheduledInfo.frequency] === ReportFrequency.WEEKLY ? scheduledInfo.send_on : 'MONDAY',
      type: ownProps.type
    },
    currentValues
  }
}

export default connect(mapStateToProps)(ScheduleReportContainer);
