/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import moment from 'moment';
import numeral from 'numeral';
import MasterDetailLayout from '../../MasterDetailLayout';
import DetailPanel from '../../shared/detailPanel/DetailPanel';
import DetailPanelOptions from '../../shared/detailPanel/DetailPanelOptions';
import BatchDetails from './BatchDetails';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import NoData from '../../NoData';
import BatchesSummaryHeader from './BatchesSummaryHeader';
import DateFilterAccessory from '../../appBar/accessories/DateFilterAccessory';
import DateUtils from '../../util/DateUtil';
import {
  getBatches,
  getBatchDetail
} from '../../../actions/batchesActions';
import { setBatch } from '../../../actions/batchesActions';
import IconUtil from '../../util/IconUtil';
import CsvUtil from '../../util/CsvUtil';
import CsvExporter from '../../util/CsvExporter';
import LabelUtil from '../../util/LabelUtil';
import ReportUtil from '../../util/ReportUtil';
import TableHeader from '../../shared/TableHeader';
import FilterPanel from '../../shared/FilterPanel';
import Button from '../../shared/Button';
import SearchBarAccessory from '../../appBar/accessories/SearchBarAccessory';
import UserUtil from '../../util/UserUtil';
import SettingsUtil from '../../util/SettingsUtil';
import dateFilterTypes from '../../../constants/dateFilterTypes';
import routes from '../../../constants/routes';
import Page from '../../shared/Page';
import ExportImportAccessory from '../../appBar/accessories/ExportImportAccessory';

const defaultDateSelectionPeriod = dateFilterTypes.THIS_WEEK;
const defaultTimeSelection = '00:00:00';

const OPEN_BATCH_DATES = [
  dateFilterTypes.ALL_TIME,
  dateFilterTypes.TODAY,
  dateFilterTypes.THIS_WEEK,
  dateFilterTypes.THIS_MONTH,
  dateFilterTypes.THIS_YEAR
];

export default class Batches extends Component {

  constructor(props) {
    super(props);

    const { userExperience } = props;
    const { selectedDate } = userExperience;

    this.handleDateSelection = this.handleDateSelection.bind(this);
    this.showDetailForRow = this.showDetailForRow.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.handleDetailView = this.handleDetailView.bind(this);
    this.handleDetailExport = this.handleDetailExport.bind(this);
    this.handleDetailPrint = this.handleDetailPrint.bind(this);
    this.loadData = this.loadData.bind(this);
    this.isTodayIncluded = this.isTodayIncluded.bind(this);
    this.redirectToTransactions = this.redirectToTransactions.bind(this);

    const dateRange = selectedDate.dateRange && selectedDate.value !== dateFilterTypes.ALL_TIME ?
      selectedDate.dateRange : DateUtils.getPersonalizedDateRange(defaultDateSelectionPeriod, defaultTimeSelection);

    const showOpenBatches = OPEN_BATCH_DATES.includes(dateRange?.text) || this.isTodayIncluded(dateRange);

    this.state = {
      dateRange: dateRange,
      order: null,
      showOpenBatches
    };

  }

  UNSAFE_componentWillMount() {
    const { batches, user, userExperience, dispatch } = this.props;
    const { historicHardwareMode } = userExperience;

    const selectedIndex = batches.selectedBatch?.selectedIndex;
    const selectedBatchDetailTransactions = batches.selectedBatch?.detail?.transactions;
    const isBatchSelected = selectedIndex > 0 || selectedIndex === 0;

    if (!batches.filteredData || !isBatchSelected) {
      this.loadData(this.props);
    }

    if (isBatchSelected && !selectedBatchDetailTransactions?.rows?.length) {
      dispatch(getBatchDetail(user, batches.selectedBatch, historicHardwareMode, 1));
    }
  }

  isTodayIncluded(dateRange) {
    const today = DateUtils.getPersonalizedToday();
    return today.startDate.isSameOrAfter(dateRange?.startDate) &&
      today.endDate.isSameOrBefore(dateRange?.endDate);
  }
  loadData(props) {

    const { userExperience, dispatch, user } = props;
    const { selectedDate, historicHardwareMode } = userExperience;

    SettingsUtil.getMerchantSettings(props).then((res) => {

      const startTime = (res.response && res.response.merchant_settings && res.response.merchant_settings.report_start) ? res.response.merchant_settings.report_start : '00:00:00';
      const dateRange = selectedDate.dateRange && selectedDate.value !== dateFilterTypes.ALL_TIME ? selectedDate.dateRange :
        DateUtils.getPersonalizedDateRange(defaultDateSelectionPeriod, startTime);
      dispatch(getBatches(user, dateRange, historicHardwareMode));

    });
  }

  showDetailForRow(row) {

    const { user, userExperience, dispatch, batches } = this.props;
    const { historicHardwareMode } = userExperience;

    if (row > 0) {
      const selectedIndex = row - 1;
      const selectedBatch = batches.filteredData[selectedIndex];
      dispatch(setBatch({...selectedBatch, selectedIndex}));
      dispatch(getBatchDetail(user, selectedBatch, historicHardwareMode, 1));
    } else {
      dispatch(setBatch(null));
    }

  }

  handleDateSelection(value, dateRange) {
    const { user, userExperience, dispatch} = this.props;
    const { historicHardwareMode } = userExperience;

    const showOpenBatches = OPEN_BATCH_DATES.includes(dateRange?.text) || this.isTodayIncluded(dateRange);

    this.setState({ dateRange, showOpenBatches } , () => {
      dispatch(getBatches(user, dateRange, historicHardwareMode));
    });
  }

  handleExport() {
    const batchesJson = CsvUtil.createBatchesSummaryCsv(this.props.batches.filteredData);
    const fileName = CsvUtil.buildFilename('Batches Summary', this.props);
    CsvExporter.download(batchesJson, fileName);
  }

  handleDetailView() {
    const { history, batches } = this.props;
    const batchDetailsTableRoute = `${globalApplicationLabel.path}${routes.activity.root}${routes.activity.batches}/${batches.selectedBatch?.uniq_id}`;
    history.push(batchDetailsTableRoute);
  }

  handleDetailExport() {
    const batchDetailJson = CsvUtil.createsBatchDetailCsv(this.props.batches.selectedBatch);
    CsvExporter.download(batchDetailJson, `Batch_Details_ID_${this.props.batches.selectedBatch.batch_id}_${moment().format('MMDDYYYY')}.csv`);
  }

  handleDetailPrint() {
    const batchDetailJson = CsvUtil.createsBatchDetailCsv(this.props.batches.selectedBatch);
    const reportWindow = window.open('about:blank');
    const html = ReportUtil.createHtmlFromCsvReport('Batch Details', this.props, batchDetailJson);
    if (!!reportWindow?.document) {
      reportWindow.document.write(html);
      reportWindow.document.title = 'Batch Details Report';
      ReportUtil.windowBindListeners(reportWindow);
    }
  }

  redirectToTransactions() {
    const { history } = this.props;
    const transactionsRoute = `${globalApplicationLabel.path}${routes.activity.root}${routes.activity.transactions}`;
    history.push(`${transactionsRoute}?openBatch=true`);
  }

  render() {

    const { merchantSettings, batches, user, t } = this.props;
    const { showOpenBatches } = this.state;
    const selectedIndex = batches.selectedBatch?.selectedIndex;

    const userType = UserUtil.userType(user);
    const shouldDisplayBatchLink = UserUtil.isEPX(user) && userType !== 'PA';
    const chevronRightIcon = IconUtil.getIcon('ChevronRightIcon');

    const viewOpenBatchButton = shouldDisplayBatchLink && showOpenBatches && (
      <Button
        label={t('ViewOpenBatch')}
        icon={chevronRightIcon}
        onClick={this.redirectToTransactions}
      />
    );

    const isEmpty = merchantSettings.customReportStartTime === null || batches.data === null || batches.isFetching;

    const pageLoading = isEmpty;

    if(pageLoading) {
      return <Page title={t('Batches')} loading/>
    }

      const titlePanel =  (<BatchesSummaryHeader {...this.props} subTitle={viewOpenBatchButton}/>);

      const listHeaders =  ((batches.filteredData && batches.filteredData.length) &&
        <TableRow className={'tableRow'}>
          <TableHeader
            attribute={'batch_date'}
            className='hideMobile'
            clickable
            headerId={'batchHeaderOne'}
            name={t('BatchesTable.Date')}
            type='table'
            {...this.props}
          />
          <TableHeader
            attribute={'batch_id'}
            clickable
            headerId={'batchHeaderTwo'}
            name={t('BatchesTable.BatchNumber')}
            type='table'
            {...this.props}
          />
          <TableHeader
            attribute={'total_purch_trans'}
            className='hideMobile'
            clickable
            headerId={'batchHeaderThree'}
            name={t('Sales')}
            type='table'
            {...this.props}
          />
          <TableHeader
            attribute={'total_purch_amt'}
            className='hideMobile'
            clickable
            headerId={'batchHeaderFour'}
            name={t('BatchesTable.SalesAmount')}
            type='table'
            {...this.props}
          />
          <TableHeader
            attribute={'total_return_trans'}
            className='hideMobile'
            clickable
            headerId={'batchHeaderFive'}
            name={t('BatchesTable.Refunds')}
            type='table'
            {...this.props}
          />
          <TableHeader
            attribute={'total_return_amt'}
            className='hideMobile'
            clickable
            headerId={'batchHeaderSix'}
            name={t('BatchesTable.RefundsAmount')}
            type='table'
            {...this.props}
          />
          <TableHeader
            attribute={'total_trans'}
            className='hideMobile'
            clickable
            headerId={'batchHeaderSeven'}
            name={t('BatchesTable.TotalTransactions')}
            type='table'
            {...this.props}
          />
          <TableHeader
            attribute={'total_gross_amt'}
            className='hideMobile'
            clickable
            headerId={'batchHeaderEight'}
            name={t('BatchesTable.GrossAmount')}
            type='table'
            {...this.props}
          />
          <TableHeader
            attribute={'total_volume'}
            clickable
            headerId={'batchHeaderNine'}
            name={t('BatchesTable.NetAmount')}
            type='table'
            {...this.props}
          />
        </TableRow>
      ) || null;

      const batchesRows =  (batches.filteredData && batches.filteredData.length ?
          batches.filteredData.map((batch, index) => {
            const tableRowClassName = (selectedIndex === index) ? 'tableRow selectedListRow' : 'tableRow';
            return (<TableRow className={tableRowClassName}
                key={index}
                    >
              <TableCell className='hideMobile'>{moment(batch.batch_date).format('MMMM D, YYYY')}</TableCell>
              <TableCell>{batch.batch_id}</TableCell>
              <TableCell className='hideMobile'>{batch.total_purch_trans}</TableCell>
              <TableCell className='hideMobile'>{numeral(batch.total_purch_amt).format('$0,0.00')}</TableCell>
              <TableCell className='hideMobile'>{batch.total_return_trans}</TableCell>
              <TableCell className='hideMobile'>{`-${numeral(batch.total_return_amt).format('$0,0.00')}`}</TableCell>
              <TableCell className='hideMobile'>{batch.total_trans}</TableCell>
              <TableCell className='hideMobile'>{numeral(batch.total_gross_amt).format('$0,0.00')}</TableCell>
              <TableCell>{numeral(batch.total_volume).format('$0,0.00')}</TableCell>
            </TableRow>)})
          : (<NoData text={t('NoBatches')}
                  type='table'
             />)
      );

      const batchesList =  (
        <TableBody>
          {listHeaders}
          {batchesRows}
        </TableBody>
      );

      const searchBar =  (
        <SearchBarAccessory {...this.props} />
      );

      const detailDate = batches.selectedBatch && moment(batches.selectedBatch.batch_date).format('MMMM D, YYYY') + ' ' + moment(batches.selectedBatch.batch_date).format('h:mm a');
      const detailId = batches.selectedBatch && batches.selectedBatch.batch_id;
      const viewIcon = IconUtil.getIcon('Visibility', LabelUtil.getLabelColor());
      const printIcon = IconUtil.getIcon('BatchesPrintIcon', LabelUtil.getLabelColor());
      const exportIcon = IconUtil.getIcon('ExportIconBatch', LabelUtil.getLabelColor());

      const detailOptions =  batches.selectedBatch && [
        {
          onClick: this.handleDetailView,
          text: t('BatchDetailsSummary.ViewBatchDetails'),
          icon: viewIcon
        },
        {
          onClick: this.handleDetailExport,
          text: t('BatchDetailsSummary.ExportBatchDetails'),
          icon: exportIcon
        },
        {
          onClick: this.handleDetailPrint,
          text: t('BatchDetailsSummary.PrintBatchDetails'),
          icon: printIcon
        }
      ];

      const selectedBatchDetailTransactions = batches.selectedBatch?.detail?.transactions;
      const hideDetailOptions =  batches.isFetchingDetails || !selectedBatchDetailTransactions?.rows?.length;
      const detailPanelOptions =  hideDetailOptions ? null : (<DetailPanelOptions options={detailOptions}/>);

      const isLargeBatchDetailsList = selectedBatchDetailTransactions?.rowCount > selectedBatchDetailTransactions?.pageSize;
      const batchDetailsPanel = (selectedIndex > 0 || selectedIndex === 0 || !!batches.selectedBatch?.uniq_id) && (
        <DetailPanel
          heading={detailDate}
          subheading={`${t('BatchesTable.BatchNumber')}${detailId}`}
        >
          {detailPanelOptions}
          <BatchDetails
            batches={batches}
            isLargeBatchDetailsList={isLargeBatchDetailsList}
            t={t}
          />
        </DetailPanel>
      );

      const filterPanelMobileOnly =  (
        <FilterPanel
            mobileOnly
            {...this.props}
        />
      );

      const pageAccessories = [
        {
          name: 'search',
          accessory: searchBar,
          showInMobile: true,
          hide: false
        },
        {
          name: 'dateFilter',
          accessory: (
            <DateFilterAccessory
              {...this.props}
              dataName={'Batches data'}
              defaultValue={this.state.dateRange.text}
              disableCompactVersion
              handleSelection={this.handleDateSelection}
              showAllTime={false}
              showPreviousCustomSelection={false}
            />
          ),
          showInMobile: true,
          hide: false
        },
        {
          name: 'exportButton',
          accessory: (
            <ExportImportAccessory
              exportTitle='Export Batches'
              handleSelection={this.handleExport}
            />
          ),
          showInMobile: true,
          hide: false
        },
      ];

      return (
        <section className='batches'>
          {filterPanelMobileOnly}
          <MasterDetailLayout
              {...this.props}
              pageTitle={t('Batches')}
              pageLoading={pageLoading}
              pageAccessories={pageAccessories}
              detailDisplayCallback={this.showDetailForRow}
              forceOpenDetail={selectedIndex > 0 || selectedIndex === 0 || !!batches.selectedBatch?.uniq_id}
              header={titlePanel}
              showLogo
              tableData={batches.filteredData}
              tableRowLayout={batchesList}
          >
            {batchDetailsPanel}
          </MasterDetailLayout>
        </section>
      );

  }

}
