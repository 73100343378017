/**
 North American Bancard ('NAB') CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

 import LabelUtil from '../../util/LabelUtil';
 import React from 'react';
 export const TrendsInfo = ({alt}) => {
   return (
    <svg className='trendsInfo' alt={alt} xmlns='http://www.w3.org/2000/svg' width='284.565' height='252.165' viewBox='0 0 284.565 252.165'>
      <defs>
        <clipPath id='clipPath'>
          <rect id='Rectangle_953' data-name='Rectangle 953' width='230.229' height='235.106' transform='translate(0 0)' fill='none'/>
        </clipPath>
        <clipPath id='clipPath-2'>
          <rect id='Rectangle_882' data-name='Rectangle 882' width='13.552' height='13.552' transform='translate(0)' fill='#00c4b3' stroke='#707070' strokeWidth='1'/>
        </clipPath>
        <clipPath id='clipPath-6'>
          <rect id='Rectangle_886' data-name='Rectangle 886' width='13.552' height='13.552' transform='translate(0)' fill={LabelUtil.getLabel().primaryColor} stroke='#707070' strokeWidth='1'/>
        </clipPath>
      </defs>
      <g id='Group_1656' data-name='Group 1656' transform='translate(-914.079 -77.5)'>
        <path id='Path_2273' data-name='Path 2273' d='M6038.418,2248.1h0s187.377,67.887,213-10.808c3.753-2.977-4.723,1.881-4.723,1.881l-20.749,11.785s-37.521-17.149-77.719-5.538c-36.869,12.373-39.041,14.226-64.239,0C6067.282,2232.32,6038.418,2248.1,6038.418,2248.1Z' transform='translate(-5066.467 -1945.64)' fill='#fff'/>
        <g id='Group_1556' data-name='Group 1556' transform='translate(966.087 77.5)' clipPath='url(#clipPath)'>
          <path id='Path_2228' data-name='Path 2228' d='M1175.792,580.911s19.692-41.493,14.67-73.539c-.961-6.132-1.035-12.282-3.995-20.977-4.814-14.14-13.534-28.7-21.9-39.444q-2.039-2.62-4.194-5.148-1.343-1.575-2.731-3.11a132.717,132.717,0,0,0-24.265-21.208l-3.187-2.223a3.439,3.439,0,0,0-3.747-.466,6.927,6.927,0,0,0-2.85,2.74c-2.366,3.735-2.9,8.335-2.925,12.756A65.053,65.053,0,0,0,1123,447.825c1.446,5.263,3.3,11.644,7.339,15.511,2.048,1.96,4.465,3.488,6.657,5.285,7.337,6.013,11.929,14.922,13.969,24.186s.409,19.013-.844,28.415c0,0-23.4,42.838-20.85,51.087,10.894,35.246,46.522,8.6,46.522,8.6' transform='translate(-961.47 -355.577)' fill='#fff'/>
          <path id='Path_2229' data-name='Path 2229' d='M1171.332,577.637a.711.711,0,0,1-.651-.992c6.165-14.253,10.167-26.328,12.6-38a118.993,118.993,0,0,0,1.809-39.719,97.632,97.632,0,0,0-8.905-29.445,142.7,142.7,0,0,0-16.461-26.362c-1.353-1.739-2.758-3.462-4.174-5.123-.9-1.05-1.81-2.091-2.717-3.095a131.992,131.992,0,0,0-24.134-21.095l-3.2-2.23a.722.722,0,0,1-.09-.075,2.719,2.719,0,0,0-2.959-.326,6.157,6.157,0,0,0-2.542,2.473c-2.487,3.924-2.8,8.944-2.815,12.379a64.232,64.232,0,0,0,2.3,17.34c1.639,5.969,3.419,11.621,7.146,15.187a42.145,42.145,0,0,0,4.015,3.249c.861.64,1.752,1.3,2.6,2,6.924,5.674,11.972,14.4,14.213,24.582,1.9,8.609.671,17.6-.513,26.289q-.162,1.19-.321,2.373a.712.712,0,0,1-.072.23c-.022.042-2.2,4.291-5.1,10.272a.71.71,0,1,1-1.278-.62c2.651-5.464,4.7-9.488,5.06-10.191q.15-1.125.305-2.256c1.167-8.568,2.375-17.429.533-25.792-2.173-9.868-7.047-18.316-13.725-23.789-.824-.675-1.7-1.327-2.549-1.958a43.17,43.17,0,0,1-4.15-3.363c-4.319-4.132-6.253-11.176-7.533-15.837a65.661,65.661,0,0,1-2.354-17.723c.039-7.538,1.751-11.105,3.036-13.133a7.5,7.5,0,0,1,3.159-3.008,4.174,4.174,0,0,1,4.49.562l3.142,2.192a133.361,133.361,0,0,1,24.385,21.314c.917,1.014,1.84,2.066,2.745,3.126,1.431,1.677,2.848,3.417,4.214,5.172a144.11,144.11,0,0,1,16.624,26.627,99.046,99.046,0,0,1,9.031,29.876,120.392,120.392,0,0,1-1.828,40.187c-2.449,11.769-6.479,23.931-12.683,38.275a.71.71,0,0,1-.652.428' transform='translate(-957.18 -351.307)' fill='#1a1e22'/>
          <path id='Path_2230' data-name='Path 2230' d='M899.969,1009.07c-1.637-16.614-.72-22.115,1.114-41.827.93-10-.462-22.176-2.093-32.05a30.477,30.477,0,0,0-14.622-21.337,29.258,29.258,0,0,0-23.9-2.036c-25.294,8.6-25.62,39.6-28.994,61.212a123.059,123.059,0,0,1-3.915,17.757c-1.056,3.38-2.73,11.144-6.337,12.775.119-.054,33.352-15.042,78.748,5.505' transform='translate(-704.56 -780.883)' fill='#fff'/>
          <path id='Path_2231' data-name='Path 2231' d='M895.677,1005.542a.708.708,0,0,1-.293-.063A121.389,121.389,0,0,0,864.132,996a103.845,103.845,0,0,0-24.742-.937c-10.994.93-19.3,3.617-22.163,4.91l-.005,0a.71.71,0,1,1-.585-1.294l0,0c2.776-1.261,4.44-7.1,5.435-10.587.183-.641.355-1.247.513-1.75a120.04,120.04,0,0,0,3.891-17.655c.447-2.86.835-5.849,1.246-9.012,1.308-10.068,2.79-21.48,6.687-31.126,4.512-11.168,11.556-18.245,21.534-21.637a29.335,29.335,0,0,1,34.4,11.342A31.828,31.828,0,0,1,895.4,930.84c2.164,13.1,2.87,23.945,2.1,32.231-.2,2.138-.387,4.108-.562,5.944-1.458,15.284-2,21-.552,35.747a.71.71,0,0,1-.707.78M847.76,993.29a107.58,107.58,0,0,1,16.588,1.3,122.366,122.366,0,0,1,30.505,9.092c-1.316-14.014-.719-20.269.668-34.806.175-1.835.363-3.8.562-5.94.76-8.165.058-18.887-2.086-31.869a30.393,30.393,0,0,0-4.828-12.022A27.914,27.914,0,0,0,856.4,908.255c-9.564,3.251-16.326,10.062-20.674,20.824-3.829,9.477-5.3,20.793-6.6,30.777-.412,3.173-.8,6.17-1.251,9.049a121.407,121.407,0,0,1-3.938,17.859c-.152.486-.322,1.084-.5,1.716-.779,2.732-1.832,6.426-3.515,8.961a84.27,84.27,0,0,1,19.337-3.794q4.206-.356,8.5-.356' transform='translate(-700.268 -776.646)' fill='#1a1e22'/>
          <path id='Path_2232' data-name='Path 2232' d='M973.791,853.559c1.811,9.8-2.354,18.438-3.388,28.413-1.3-8.036-6.36-16.263-13.721-20.085-3.711-1.927-7.9-2.846-11.524-4.925s-6.786-5.908-6.2-10.048c-2.96,3.923-5.019,8.179-10.154,9.15a10.074,10.074,0,0,1-7.143-1.03c-.927-.522-4.833-3.31-4.475-4.629a6.417,6.417,0,0,1-6.454,4.753c-2.749-.206-5.234-2.278-6.661-4.987a18.254,18.254,0,0,1-1.764-9.141,25.808,25.808,0,0,1,4.841-14.155c2.523-3.394,6.62-7.135,10.337-8.538a9.518,9.518,0,0,0,.783,9.587c4.767,6.3,13.228-.126,18.987-.682,15.2-1.466,30.479,7.907,35.51,22.448a26.542,26.542,0,0,1,1.023,3.87' transform='translate(-774.112 -702.085)' fill='#1a1e22'/>
          <path id='Path_2233' data-name='Path 2233' d='M966.113,878.394a.71.71,0,0,1-.7-.6c-1.359-8.384-6.6-16.064-13.347-19.568a46.851,46.851,0,0,0-5.288-2.212,41.455,41.455,0,0,1-6.262-2.728,14.984,14.984,0,0,1-4.916-4.47,9.767,9.767,0,0,1-1.623-4.024l-.211.3c-2.362,3.354-4.594,6.523-9.117,7.378a10.7,10.7,0,0,1-7.623-1.109,18.258,18.258,0,0,1-4.119-3.414,7.026,7.026,0,0,1-6.515,3.628c-2.833-.212-5.606-2.268-7.236-5.365a18.9,18.9,0,0,1-1.845-9.5,26.668,26.668,0,0,1,4.981-14.551c2.447-3.291,6.691-7.282,10.656-8.779a.71.71,0,0,1,.884.986,8.758,8.758,0,0,0,.717,8.837c3.019,3.99,7.8,2.3,12.425.667a26.635,26.635,0,0,1,5.927-1.627,35.346,35.346,0,0,1,22.012,5.326,34.158,34.158,0,0,1,14.238,17.6,27.458,27.458,0,0,1,1.05,3.973c1.195,6.467-.122,12.3-1.517,18.471a86.3,86.3,0,0,0-1.864,10.144.71.71,0,0,1-.686.637h-.021M934.67,841.915a.71.71,0,0,1,.7.81c-.362,2.55.952,6.525,5.849,9.332a40.38,40.38,0,0,0,6.05,2.628,48,48,0,0,1,5.448,2.283c6.1,3.168,11.031,9.542,13.224,16.833.387-2.222.876-4.385,1.354-6.5,1.361-6.027,2.647-11.72,1.505-17.9a26.041,26.041,0,0,0-1-3.766,32.734,32.734,0,0,0-13.649-16.857,33.918,33.918,0,0,0-21.121-5.116,25.738,25.738,0,0,0-5.591,1.552c-4.868,1.72-10.385,3.669-14.031-1.149a10.263,10.263,0,0,1-1.5-8.65,28.271,28.271,0,0,0-8.484,7.6,25.213,25.213,0,0,0-4.7,13.759,17.494,17.494,0,0,0,1.683,8.782c1.4,2.668,3.736,4.434,6.085,4.61a5.734,5.734,0,0,0,5.714-4.227v0h0a.71.71,0,0,1,1.371.372c-.011.136.256.775,1.626,1.97a19.085,19.085,0,0,0,2.512,1.853,9.358,9.358,0,0,0,6.663.951c3.957-.748,5.932-3.553,8.219-6.8.48-.682.977-1.387,1.5-2.079a.71.71,0,0,1,.567-.282' transform='translate(-769.823 -697.796)' fill='#1a1e22'/>
          <path id='Path_2234' data-name='Path 2234' d='M1303.939,1188.727a6.909,6.909,0,0,1,.3-1,4.6,4.6,0,0,1,6.453-2.167c2.537,1.5,3.905,4.858,4.4,8.068a17.1,17.1,0,0,1-1.252,10.1c-1.486,2.952-4.264,5.061-7.281,5-4.043-.084-8.137-4.627-8.483-8.116-.221-2.223.108-8.477,1.363-10.333.9-1.327,4.339-.823,4.506-1.55' transform='translate(-1113.615 -1016.625)' fill='#fff'/>
          <path id='Path_2235' data-name='Path 2235' d='M1321.057,1205.246h-.142a7.947,7.947,0,0,1-6.007-3.265.71.71,0,1,1,1.131-.86,6.608,6.608,0,0,0,4.906,2.7c2.589.058,5.2-1.754,6.633-4.608a16.327,16.327,0,0,0,1.184-9.676c-.551-3.611-2.029-6.368-4.054-7.563a3.984,3.984,0,0,0-3.812-.007,3.179,3.179,0,0,0-1.772,2.631.71.71,0,0,1-1.418-.089,4.58,4.58,0,0,1,2.525-3.8,5.432,5.432,0,0,1,5.2.038c2.394,1.413,4.121,4.537,4.736,8.572a17.742,17.742,0,0,1-1.319,10.529c-1.656,3.289-4.7,5.39-7.789,5.39' transform='translate(-1127.991 -1012.434)' fill='#1a1e22'/>
          <path id='Path_2236' data-name='Path 2236' d='M1343.91,1251.229a3.331,3.331,0,0,1-1.407-.307.71.71,0,1,1,.6-1.288,2.161,2.161,0,0,0,1.925-.116,2.438,2.438,0,0,0,1.3-1.716,2.648,2.648,0,0,0-.7-2.151,2.268,2.268,0,0,0-1.767-.831.71.71,0,0,1-.075-1.419,3.674,3.674,0,0,1,2.9,1.3,4.076,4.076,0,0,1,1.039,3.33,3.865,3.865,0,0,1-2.035,2.74,3.812,3.812,0,0,1-1.78.456' transform='translate(-1151.437 -1066.761)' fill='#1a1e22'/>
          <path id='Path_2237' data-name='Path 2237' d='M1012.528,1296.47c-.948,3.4-4.669,7.2-10.195,4.016-3.667-2.113-3.253-6.676-1.748-9.236l9.983,1.62a4.039,4.039,0,0,1,1.581.48,1.542,1.542,0,0,1,.615,1.339,5.963,5.963,0,0,1-.236,1.781' transform='translate(-857.502 -1107.817)' fill='#1a1e22'/>
          <path id='Path_2238' data-name='Path 2238' d='M962.791,1178.951a4.656,4.656,0,0,1-1.049-.118,5.005,5.005,0,0,1-3.627-3.528.71.71,0,1,1,1.375-.359,3.468,3.468,0,0,0,5.984,1.386.71.71,0,1,1,1.077.927,5.024,5.024,0,0,1-3.76,1.693' transform='translate(-821.988 -1007.579)' fill='#1a1e22'/>
          <path id='Path_2239' data-name='Path 2239' d='M1128.377,1203.886a4.676,4.676,0,0,1-1.05-.118,5,5,0,0,1-3.626-3.528.71.71,0,0,1,1.375-.358,3.467,3.467,0,0,0,5.984,1.386.71.71,0,0,1,1.077.927,5.021,5.021,0,0,1-3.759,1.693' transform='translate(-964.051 -1028.972)' fill='#1a1e22'/>
          <path id='Path_2240' data-name='Path 2240' d='M44.838,742.994c-6.638-1.741-16.453,1.757-16.974-1.851-4.028-27.891-3.536-55.525,5.225-81.684,5.685-16.975,12.316-33.912,25.184-46.725a41.172,41.172,0,0,1,9.613-7.324,18.333,18.333,0,0,1,11.682-2.266,15.93,15.93,0,0,1,11.112,8.9,22.552,22.552,0,0,1,1.356,14.445,39.144,39.144,0,0,1-6.072,13.375,39.838,39.838,0,0,0-3.52,6.232,33.463,33.463,0,0,1-8.032,15.158c-4.178,4.518-7.016,7.724-8.7,13.9a93.179,93.179,0,0,0-2.256,38.17c1.3,8.7,3.991,17.482,9.755,24.12a35.339,35.339,0,0,0,18.061,10.721s-25.283-1.8-45.169-7.02' transform='translate(-21.822 -517.27)' fill='#fff'/>
          <path id='Path_2241' data-name='Path 2241' d='M98.916,741.556A45.955,45.955,0,0,1,85.8,739.67a36.158,36.158,0,0,1-17.1-10.68c-5.09-5.862-8.336-13.869-9.921-24.481a94.292,94.292,0,0,1,2.273-38.462c1.7-6.222,4.475-9.449,8.866-14.2a32.618,32.618,0,0,0,7.862-14.834.7.7,0,0,1,.031-.1,42.088,42.088,0,0,1,3.592-6.368,38.08,38.08,0,0,0,5.967-13.129,21.834,21.834,0,0,0-1.3-13.987,15.227,15.227,0,0,0-10.6-8.505c-4.366-.793-8.512.756-11.222,2.194a40.117,40.117,0,0,0-9.445,7.2,80.986,80.986,0,0,0-15.152,21.616,193.568,193.568,0,0,0-9.86,24.831,158.315,158.315,0,0,0-7.771,40.98,202.108,202.108,0,0,0,2.214,42.358.71.71,0,1,1-1.4.216A203.527,203.527,0,0,1,20.6,691.664a159.742,159.742,0,0,1,7.842-41.348A194.886,194.886,0,0,1,38.374,625.3a82.372,82.372,0,0,1,15.42-21.988,41.5,41.5,0,0,1,9.781-7.448c2.908-1.544,7.375-3.2,12.142-2.337a16.668,16.668,0,0,1,11.625,9.288,23.255,23.255,0,0,1,1.41,14.9,39.461,39.461,0,0,1-6.177,13.62,39.5,39.5,0,0,0-3.429,6.047,34.044,34.044,0,0,1-8.191,15.428c-4.244,4.591-6.928,7.7-8.539,13.607A92.863,92.863,0,0,0,60.177,704.3c1.544,10.336,4.681,18.107,9.589,23.759A34.742,34.742,0,0,0,86.2,738.309a45.364,45.364,0,0,0,19.814,1.279,93.829,93.829,0,0,0,27.731-8.566.71.71,0,1,1,.639,1.268,95.258,95.258,0,0,1-28.152,8.7,47.783,47.783,0,0,1-7.319.564' transform='translate(-17.406 -509.008)' fill='#1a1e22'/>
          <path id='Path_2242' data-name='Path 2242' d='M289.675,45.451c9.548,30.374,17.1,61.9,25.1,92.72-24.693,7.888-109.366,28.51-127.336,32.053-11.383-29.882-19.983-61.451-27.953-92.418,43.392-12.872,86.419-23.755,130.189-32.355' transform='translate(-136.83 -38.994)' fill='#fff'/>
          <path id='Path_2243' data-name='Path 2243' d='M499.64,27.416a.711.711,0,0,1-.69-.881c1.709-6.907,3.734-13.824,6.019-20.559.841-2.478,1.942-4.885,4.287-5.712,3.171-1.118,6.189,1.484,8.183,3.539,5.039,5.191,10.123,10.435,15.112,15.585a.71.71,0,0,1-1.02.988c-4.989-5.15-10.073-10.393-15.111-15.584-3.6-3.7-5.542-3.594-6.691-3.188-1.774.625-2.689,2.691-3.415,4.829-2.272,6.7-4.286,13.576-5.985,20.444a.711.711,0,0,1-.689.54' transform='translate(-428.053 0.005)' fill='#1a1e22'/>
          <path id='Path_2244' data-name='Path 2244' d='M289.675,45.451c-43.77,8.6-86.8,19.484-130.189,32.355,7.97,30.967,16.57,62.536,27.953,92.418,17.97-3.543,102.643-24.165,127.336-32.053-8-30.819-15.552-62.346-25.1-92.72M175.543,86.195c34.59-10.261,68.889-18.936,103.78-25.792,7.611,24.213,12.314,43.758,18.689,68.325-19.684,6.288-87.181,22.727-101.506,25.552-9.074-23.821-14.609-43.4-20.963-68.084' transform='translate(-136.83 -38.994)' fill='#fff'/>
          <path id='Path_2245' data-name='Path 2245' d='M183.148,166.644a.71.71,0,0,1-.663-.458c-11.866-31.148-20.663-64.076-27.977-92.494a.71.71,0,0,1,.486-.858c45.54-13.509,88.146-24.1,130.254-32.371a.711.711,0,0,1,.815.484c7.508,23.885,13.862,48.787,20.007,72.87,1.667,6.531,3.39,13.284,5.1,19.885a.71.71,0,0,1-.471.855c-10.853,3.467-34.995,9.907-66.234,17.669-26.456,6.573-52.187,12.631-61.181,14.4a.7.7,0,0,1-.138.014M156.054,74c7.227,28.058,15.905,60.445,27.545,91.118,19.357-3.9,101.515-23.99,126.029-31.713-1.658-6.4-3.323-12.922-4.935-19.239-6.088-23.86-12.382-48.525-19.8-72.188C243.24,50.187,201.078,60.665,156.054,74m36.161,76.7a.711.711,0,0,1-.663-.457c-8.881-23.314-14.421-42.646-20.987-68.16a.71.71,0,0,1,.486-.858c36.307-10.77,70.275-19.212,103.846-25.808a.71.71,0,0,1,.814.484c6.223,19.8,10.446,36.253,15.336,55.308q1.619,6.307,3.362,13.051a.71.71,0,0,1-.471.855c-19.627,6.27-86.521,22.6-101.585,25.572a.714.714,0,0,1-.138.013m-20.1-68.308c6.427,24.932,11.9,43.984,20.555,66.783,15.608-3.157,80.554-19.042,100.2-25.211q-1.66-6.4-3.194-12.4c-4.835-18.84-9.018-35.138-15.125-54.629C241.426,63.461,207.9,71.793,172.111,82.39' transform='translate(-132.539 -34.703)' fill='#1a1e22'/>
          <path id='Path_2246' data-name='Path 2246' d='M859.187,307.552a.71.71,0,0,1-.464-1.248l3.832-3.3a.71.71,0,1,1,.927,1.076l-3.831,3.3a.708.708,0,0,1-.463.172' transform='translate(-736.524 -259.81)' fill='#1a1e22'/>
          <path id='Path_2247' data-name='Path 2247' d='M470.047,607.687a.71.71,0,0,1-.224-1.384c.491-.192,4.609-2.709,7.217-4.445a.71.71,0,1,1,.787,1.182c-2.073,1.38-6.878,4.385-7.555,4.61a.712.712,0,0,1-.225.037' transform='translate(-402.664 -516.256)' fill='#1a1e22'/>
          <path id='Path_2248' data-name='Path 2248' d='M465.08,549.93a.71.71,0,0,1-.01-1.42l5.825-.088h.011a.71.71,0,0,1,.01,1.421l-5.825.088h-.011' transform='translate(-398.403 -470.514)' fill='#1a1e22'/>
          <path id='Path_2249' data-name='Path 2249' d='M818.648,263.446a.711.711,0,0,1-.7-.819,49.682,49.682,0,0,1,2.359-9.241.71.71,0,1,1,1.336.481,48.272,48.272,0,0,0-2.291,8.976.71.71,0,0,1-.7.6' transform='translate(-701.742 -216.988)' fill='#1a1e22'/>
          <path id='Path_2250' data-name='Path 2250' d='M517.477,335.011c-.626.594-1.482,1.223-2.272.875-.831-.367-.9-1.5-.839-2.407.391-5.875,1.577-13.02,1.968-18.9-4.553-1.138-9.646-2.215-13.5-4.888a4.292,4.292,0,0,1-2.169-2.779,3.034,3.034,0,0,1,1.414-2.649,8.94,8.94,0,0,1,2.915-1.146c3.858-1.013,10.3-2.77,14.159-3.783,1.14-5.179,2.985-19.818,4.254-20.6,2.708-1.668,10.687,12.821,13.419,15.846,5.723-1.4,21.782-3.029,21.565-1.895a7.519,7.519,0,0,1-1.611,3.03,60.62,60.62,0,0,1-10.762,10.9c-.18.141,14.557,14.4,11.713,17.443-2.236,2.394-9.506.218-12.105-.683-4.4-1.527-8.362-4.088-12.273-6.625.181.155-10.417,13.084-15.874,18.26' transform='translate(-429.53 -239.026)' fill={LabelUtil.getLabel().primaryColor}/>
          <path id='Path_2251' data-name='Path 2251' d='M511.4,332.361a1.88,1.88,0,0,1-.765-.163c-.946-.417-1.37-1.461-1.261-3.1.2-2.957.6-6.276.988-9.485.361-2.978.732-6.049.938-8.818l-.7-.172c-4.351-1.071-8.851-2.178-12.457-4.677a4.879,4.879,0,0,1-2.471-3.292,3.7,3.7,0,0,1,1.714-3.3,9.58,9.58,0,0,1,3.141-1.251c1.928-.507,4.548-1.211,7.081-1.892,2.351-.632,4.776-1.284,6.655-1.78.359-1.725.783-4.3,1.231-7.016,1.733-10.507,2.342-13.051,3.253-13.613,2.3-1.416,5.749,3.405,10.75,10.924,1.332,2,2.5,3.76,3.3,4.737a113.055,113.055,0,0,1,12.145-1.763c8.687-.847,9.332-.235,9.607.027a.837.837,0,0,1,.252.766,8.124,8.124,0,0,1-1.744,3.328,61.293,61.293,0,0,1-10.312,10.567c.218.236.5.534.786.84,6.851,7.288,12.856,14.39,10.432,16.986-2.523,2.7-9.832.552-12.857-.5a55.709,55.709,0,0,1-11.83-6.314c-.277.376-.706.923-1.369,1.749-1.162,1.448-2.654,3.248-4.2,5.07-2.395,2.819-6.881,7.994-10.024,10.974a3.65,3.65,0,0,1-2.282,1.172m8.127-57.369c-.7.966-1.908,8.264-2.631,12.648-.489,2.966-.951,5.767-1.336,7.514a.71.71,0,0,1-.513.534c-1.926.506-4.541,1.209-7.07,1.889s-5.157,1.387-7.089,1.894a8.379,8.379,0,0,0-2.688,1.041,2.329,2.329,0,0,0-1.115,2c.093.921,1,1.663,1.867,2.267,3.391,2.35,7.762,3.426,11.988,4.466q.647.159,1.283.317a.71.71,0,0,1,.536.736c-.2,2.957-.6,6.277-.988,9.487-.386,3.191-.786,6.491-.98,9.409-.063.956.085,1.563.417,1.71.4.178,1.036-.3,1.5-.74a202.141,202.141,0,0,0,15.69-17.973.71.71,0,0,1,1.059-.367c3.821,2.479,7.772,5.042,12.119,6.55,4.239,1.47,9.756,2.579,11.353.869.664-.711-.519-3.25-3.088-6.625-2.437-3.2-5.627-6.6-7.34-8.418-1.5-1.6-1.5-1.6-1.476-1.965a.719.719,0,0,1,.272-.509,59.869,59.869,0,0,0,10.636-10.769,8.455,8.455,0,0,0,1.34-2.223c-2.631-.328-15.072.865-20.561,2.209a.711.711,0,0,1-.7-.214,56.85,56.85,0,0,1-3.7-5.217c-1.724-2.593-3.679-5.532-5.437-7.68-2.358-2.882-3.183-2.881-3.352-2.835m-6.334,55.682h0Zm15.26-17.9a.7.7,0,0,0,.152.182.7.7,0,0,1-.152-.182' transform='translate(-425.241 -234.689)' fill='#1a1e22'/>
          <path id='Path_2252' data-name='Path 2252' d='M372.277,76.891a.71.71,0,0,1-.132-1.408l8.661-1.642a.71.71,0,0,1,.265,1.4l-8.661,1.642a.722.722,0,0,1-.133.012' transform='translate(-318.784 -63.339)' fill='#1a1e22'/>
          <path id='Path_2253' data-name='Path 2253' d='M428.749,54.911c-.033-1.825-1.24-4-3.434-3.261-3.515,1.178-3.453,8.92,1.063,7.537,1.674-.513,2.4-2.525,2.371-4.276' transform='translate(-362.755 -44.186)' fill='#1a1e22'/>
          <path id='Path_2254' data-name='Path 2254' d='M421.069,55.763a3.084,3.084,0,0,1-3-2.373c-.776-2.42.245-5.879,2.725-6.71a2.889,2.889,0,0,1,2.695.333,4.556,4.556,0,0,1,1.674,3.589c.037,2.023-.837,4.344-2.872,4.968a4.185,4.185,0,0,1-1.223.194m.8-7.847a1.95,1.95,0,0,0-.62.111c-1.656.555-2.387,3.174-1.824,4.929a1.741,1.741,0,0,0,2.453,1.255c1.321-.4,1.895-2.141,1.868-3.584a3.158,3.158,0,0,0-1.073-2.454,1.36,1.36,0,0,0-.8-.257' transform='translate(-358.461 -39.889)' fill='#1a1e22'/>
          <path id='Path_2255' data-name='Path 2255' d='M.71,338.137a.71.71,0,0,1-.072-1.417l14.611-1.5a.71.71,0,0,1,.146,1.413l-14.611,1.5a.726.726,0,0,1-.073,0' transform='translate(0 -287.592)' fill='#1a1e22'/>
          <path id='Path_2256' data-name='Path 2256' d='M43.7,246.8a.708.708,0,0,1-.23-.039l-12.082-4.136a.71.71,0,0,1,.46-1.344l12.082,4.136a.71.71,0,0,1-.23,1.383' transform='translate(-26.516 -206.972)' fill='#1a1e22'/>
          <path id='Path_2257' data-name='Path 2257' d='M140.689,163.482a.71.71,0,0,1-.654-.432,54.711,54.711,0,0,1-3.746-13.167.71.71,0,0,1,1.4-.215,53.28,53.28,0,0,0,3.648,12.825.711.711,0,0,1-.653.989' transform='translate(-116.921 -127.888)' fill='#1a1e22'/>
          <path id='Path_2258' data-name='Path 2258' d='M295.4,748.117a4.911,4.911,0,0,0,5.713.113,10.54,10.54,0,0,0,3.686-4.784,13.986,13.986,0,0,1,2.2-4.006c1.046-1.119,2.813-1.753,4.138-.984,5.023,2.918.632,13.889-1.033,17.65A38.12,38.12,0,0,1,297.7,771.553Z' transform='translate(-253.44 -633.261)' fill='#fff'/>
          <path id='Path_2259' data-name='Path 2259' d='M284.692,767.95a.71.71,0,0,1-.416-1.286A37.242,37.242,0,0,0,296.454,751.5c1.447-3.269,5.82-14.137,1.326-16.748-1.036-.6-2.454-.009-3.262.855a10.42,10.42,0,0,0-1.75,3.078c-.106.246-.212.491-.321.734a10.967,10.967,0,0,1-3.955,5.094c-1.84,1.161-4.685,1.471-6.561-.181a5.769,5.769,0,0,1-1.551-5.466,11.6,11.6,0,0,1,1.306-3.373.71.71,0,0,1,1.248.679,10.274,10.274,0,0,0-1.16,2.967,4.419,4.419,0,0,0,1.1,4.128c1.338,1.178,3.505.9,4.865.045a9.651,9.651,0,0,0,3.417-4.474c.106-.236.209-.476.313-.715a11.629,11.629,0,0,1,2.017-3.485c1.213-1.3,3.324-2.095,5.013-1.113,2.442,1.419,3.227,4.533,2.332,9.255a45.076,45.076,0,0,1-3.073,9.3,38.665,38.665,0,0,1-12.645,15.736.707.707,0,0,1-.415.134' transform='translate(-240.435 -628.948)' fill='#1a1e22'/>
          <path id='Path_2260' data-name='Path 2260' d='M1138.509,559.374a51.357,51.357,0,0,1-14.887-10.946,56.01,56.01,0,0,1-5.746-6.349,27.165,27.165,0,0,1-3.844-7.973c-.541-1.9-1.246-7.078,2.153-7.024a4.429,4.429,0,0,1,2.938,1.621c1.852,1.928,3.294,4.435,5.47,6.115,1.758,1.357,5.1,2.7,6.618.25Z' transform='translate(-955.385 -452.205)' fill='#fff'/>
          <path id='Path_2261' data-name='Path 2261' d='M1134.208,545.455a.706.706,0,0,1-.31-.072,51.746,51.746,0,0,1-15.093-11.1c-.538-.571-1.1-1.135-1.637-1.68a35.619,35.619,0,0,1-4.176-4.753,27.822,27.822,0,0,1-3.943-8.182c-.054-.187-1.285-4.616.4-6.812a2.883,2.883,0,0,1,2.446-1.116,4.986,4.986,0,0,1,3.439,1.839c.737.767,1.413,1.625,2.067,2.455a20.241,20.241,0,0,0,3.325,3.59,6.041,6.041,0,0,0,4.172,1.419,2.009,2.009,0,0,0,1.408-.981,4.555,4.555,0,0,0,.174-3.044c-.511-2.336-2.79-4.741-4.107-5.708a.71.71,0,0,1,.841-1.145,12.646,12.646,0,0,1,4.654,6.549,5.736,5.736,0,0,1-.354,4.1,3.446,3.446,0,0,1-2.362,1.632c-1.853.337-4.01-.7-5.294-1.693a21.315,21.315,0,0,1-3.573-3.835c-.662-.84-1.287-1.633-1.975-2.35a3.7,3.7,0,0,0-2.437-1.4,1.479,1.479,0,0,0-1.3.561c-.926,1.205-.591,4.049-.162,5.559a26.386,26.386,0,0,0,3.745,7.763,34.037,34.037,0,0,0,4.016,4.56c.547.551,1.112,1.121,1.662,1.706a50.329,50.329,0,0,0,14.68,10.795.71.71,0,0,1-.311,1.349' transform='translate(-951.084 -437.576)' fill='#1a1e22'/>
        </g>
        <ellipse id='Ellipse_109' data-name='Ellipse 109' cx='2.23' cy='2.23' rx='2.23' ry='2.23' transform='translate(1194.185 171.055)' fill='none'/>
        <g id='Rectangle_905' data-name='Rectangle 905' transform='translate(914.079 226.646)' fill='#fff' stroke='#1a1e22' strokeWidth='1'>
          <rect width='145.543' height='43.064' rx='12' stroke='none'/>
          <rect x='0.5' y='0.5' width='144.543' height='42.064' rx='11.5' fill='none'/>
        </g>
        <g id='XMLID_28_' transform='translate(932.687 238.843)'>
          <path id='XMLID_30_' d='M81.265,71.917a9.4,9.4,0,0,0-5.29,4.631,9.116,9.116,0,0,0-.81,2.347A9.34,9.34,0,0,0,81.9,89.757a9.98,9.98,0,0,0,4.778.052,8.235,8.235,0,0,0,6.184-5.86,10.954,10.954,0,0,0,.235-4.955H84.312v3.643H89.4a4.4,4.4,0,0,1-1.871,2.885,5.308,5.308,0,0,1-2.054.81,6.132,6.132,0,0,1-2.227-.005,5.436,5.436,0,0,1-2.08-.9,5.784,5.784,0,0,1-2.138-2.865,5.651,5.651,0,0,1,0-3.633,5.754,5.754,0,0,1,1.354-2.2,5.488,5.488,0,0,1,5.525-1.437,5.068,5.068,0,0,1,2.012,1.181c.575-.57,1.145-1.145,1.72-1.715.3-.308.617-.6.91-.92a9.006,9.006,0,0,0-3.006-1.861A9.345,9.345,0,0,0,81.265,71.917Z' transform='translate(-74.981 -71.404)' fill='#fff'/>
          <path id='XMLID_31_' d='M99.385,71.906a9.407,9.407,0,0,1,6.288.058,9.058,9.058,0,0,1,3.006,1.861c-.293.314-.606.612-.91.92-.575.57-1.145,1.145-1.72,1.715a5.007,5.007,0,0,0-2.012-1.181,5.488,5.488,0,0,0-5.525,1.437,5.754,5.754,0,0,0-1.354,2.2c-1.019-.789-2.039-1.584-3.058-2.373A9.364,9.364,0,0,1,99.385,71.906Z' transform='translate(-93.101 -71.393)' fill='#e94435'/>
          <path id='XMLID_32_' d='M75.165,172.147a9.318,9.318,0,0,1,.81-2.347c1.019.789,2.039,1.584,3.058,2.373a5.651,5.651,0,0,0,0,3.633c-1.019.789-2.039,1.584-3.058,2.373A9.323,9.323,0,0,1,75.165,172.147Z' transform='translate(-74.981 -164.656)' fill='#f8bb15'/>
          <path id='XMLID_33_' d='M253.585,216.5h8.787a10.953,10.953,0,0,1-.235,4.955,8.482,8.482,0,0,1-2.363,3.873c-.988-.768-1.981-1.537-2.964-2.305a4.385,4.385,0,0,0,1.871-2.885H253.6C253.58,218.931,253.585,217.718,253.585,216.5Z' transform='translate(-244.248 -208.915)' fill='#547dbe'/>
          <path id='XMLID_34_' d='M94,287.173c1.019-.789,2.039-1.584,3.058-2.373a5.761,5.761,0,0,0,2.138,2.865,5.518,5.518,0,0,0,2.08.9,6.133,6.133,0,0,0,2.227.005,5.308,5.308,0,0,0,2.054-.81c.988.768,1.981,1.537,2.964,2.305a8.378,8.378,0,0,1-3.821,1.986A9.981,9.981,0,0,1,99.922,292a9.325,9.325,0,0,1-3.392-1.709A9.489,9.489,0,0,1,94,287.173Z' transform='translate(-93.006 -273.645)' fill='#34a751'/>
        </g>
        <g id='Group_1649' data-name='Group 1649' transform='translate(21.146 91.208)'>
          <g id='Mask_Group_666' data-name='Mask Group 666' transform='translate(943.448 149.448)' clipPath='url(#clipPath-2)'>
            <path id='rating-solid' d='M7.526,2.734a.732.732,0,0,1,1.31,0l1.7,3.443,3.8.551a.732.732,0,0,1,.407,1.247l-2.747,2.679.651,3.782a.732.732,0,0,1-1.062.773L8.182,13.4l-3.4,1.807a.732.732,0,0,1-1.062-.773l.651-3.782L1.625,7.975a.732.732,0,0,1,.407-1.247l3.8-.551Z' transform='translate(-1.406 -2.035)' fill={LabelUtil.getLabel().primaryColor}/>
          </g>
          <g id='Mask_Group_667' data-name='Mask Group 667' transform='translate(959.448 149.448)' clipPath='url(#clipPath-2)'>
            <path id='rating-solid-2' data-name='rating-solid' d='M7.526,2.734a.732.732,0,0,1,1.31,0l1.7,3.443,3.8.551a.732.732,0,0,1,.407,1.247l-2.747,2.679.651,3.782a.732.732,0,0,1-1.062.773L8.182,13.4l-3.4,1.807a.732.732,0,0,1-1.062-.773l.651-3.782L1.625,7.975a.732.732,0,0,1,.407-1.247l3.8-.551Z' transform='translate(-1.406 -2.035)' fill={LabelUtil.getLabel().primaryColor}/>
          </g>
          <g id='Mask_Group_668' data-name='Mask Group 668' transform='translate(975.448 149.448)' clipPath='url(#clipPath-2)'>
            <path id='rating-solid-3' data-name='rating-solid' d='M7.526,2.734a.732.732,0,0,1,1.31,0l1.7,3.443,3.8.551a.732.732,0,0,1,.407,1.247l-2.747,2.679.651,3.782a.732.732,0,0,1-1.062.773L8.182,13.4l-3.4,1.807a.732.732,0,0,1-1.062-.773l.651-3.782L1.625,7.975a.732.732,0,0,1,.407-1.247l3.8-.551Z' transform='translate(-1.406 -2.035)' fill={LabelUtil.getLabel().primaryColor}/>
          </g>
          <g id='Mask_Group_669' data-name='Mask Group 669' transform='translate(991.448 149.448)' clipPath='url(#clipPath-2)'>
            <path id='rating-solid-4' data-name='rating-solid' d='M7.526,2.734a.732.732,0,0,1,1.31,0l1.7,3.443,3.8.551a.732.732,0,0,1,.407,1.247l-2.747,2.679.651,3.782a.732.732,0,0,1-1.062.773L8.182,13.4l-3.4,1.807a.732.732,0,0,1-1.062-.773l.651-3.782L1.625,7.975a.732.732,0,0,1,.407-1.247l3.8-.551Z' transform='translate(-1.406 -2.035)' fill={LabelUtil.getLabel().primaryColor}/>
          </g>
          <g id='Mask_Group_670' data-name='Mask Group 670' transform='translate(1007.448 149.448)' clipPath='url(#clipPath-6)'>
            <path id='rating-solid-5' data-name='rating-solid' d='M7.526,2.734a.732.732,0,0,1,1.31,0l1.7,3.443,3.8.551a.732.732,0,0,1,.407,1.247l-2.747,2.679.651,3.782a.732.732,0,0,1-1.062.773L8.182,13.4l-3.4,1.807a.732.732,0,0,1-1.062-.773l.651-3.782L1.625,7.975a.732.732,0,0,1,.407-1.247l3.8-.551Z' transform='translate(-1.406 -2.035)' fill={LabelUtil.getLabel().primaryColor}/>
          </g>
        </g>
      </g>
    </svg>
   )
 }