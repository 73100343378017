/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import _ from 'lodash';
import {
  getPhoneVerificationCode,
  resetTwoFactorAuthCodeStatus,
  resetTwoFactorAuthPhoneStatus,
  updatedTwoFactorForm
} from '../../actions/authActions';
import { block2FA } from '../../actions/verificationActions';
import TextUtil from '../util/FormatTextUtil';
import LabelUtil from '../util/LabelUtil';
import Validator from '../util/Validator';
import twoFactorCodeStatusUtil from '../util/twoFactorCodeStatusUtil';
import messages from '../../constants/messages';
import TextField from '../shared/TextField';
import Toggle from '../shared/Toggle';
import actionTypes from '../../constants/actionTypes';
import {requestCodeStyles} from '../../jss/twoFactorStyles';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import {toastr} from 'react-redux-toastr';

const validate = values => Validator.validateTwoFactorForm(values);

export class TwoFactorForm extends Component {

  constructor(props) {
    super(props);

    this.requestCode = this.requestCode.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleEnterPressed = this.handleEnterPressed.bind(this);
  }

  requestCode() {
    const {cellPhoneSelector, person, dispatch, t, blocked} = this.props;
    if (!blocked) {
      return dispatch(getPhoneVerificationCode(person, cellPhoneSelector)).then(result => {
        if (result.type === actionTypes.twoFactorPhoneCodeFailure && result.error === t('Errors.TwoFactor.RateLimit')) {
          dispatch(block2FA());
        }
        return result;
      });
    } else {
      toastr.error(t('Toastr.Error.Title'), t('Errors.TwoFactor.RateLimit'), { progressBar: false });
    }
  }

  componentWillUnmount() {
    this.handlePhoneChange();
  }

  handlePhoneChange(event) {
    const { dispatch, codeStatus, checkingNewPhone } = this.props;
    const codeInProcess = twoFactorCodeStatusUtil.isSendingCode(codeStatus) || twoFactorCodeStatusUtil.isCodeSent(codeStatus) || twoFactorCodeStatusUtil.isValidatingCode(codeStatus);
    const codeError = twoFactorCodeStatusUtil.codeError(codeStatus);

    if ((checkingNewPhone && codeInProcess && event) || codeError) {
      dispatch(updatedTwoFactorForm(null, null));
      dispatch(resetTwoFactorAuthCodeStatus());
      dispatch(resetTwoFactorAuthPhoneStatus());
    }
  }

  handleEnterPressed(event) {
    if (event.key == 'Enter' || event.keyCode == 13) {
      this.props.handleSubmit();
    }
  }

  render() {
    const {handleSubmit, showLoginTwoFAToggle, codeStatus, t, twoFactorCellPhone, checkingNewPhone, blocked} = this.props;
    const codeInProcess = twoFactorCodeStatusUtil.isSendingCode(codeStatus) || twoFactorCodeStatusUtil.isCodeSent(codeStatus) || twoFactorCodeStatusUtil.isValidatingCode(codeStatus);
    const codeError = twoFactorCodeStatusUtil.codeError(codeStatus);

    const twoFactorLoginToggle = (
      <Field
        component={Toggle}
        label={t('TwoFactorForm.EnableForLogin')}
        name='hasTwoFactorEnabled'
        customClass='twoFactorToggle'
        disabled={codeInProcess || blocked}
      />
    );

    const label = LabelUtil.getLabel();

    const twoFactorCode = (
      <div className='paddingTop20'>
        <Field
          label={t('TwoFactorForm.4DigitCode')}
          hintText={t('TwoFactorForm.Enter4DigitCode')}
          component={TextField}
          className='fullScreen'
          fullWidth={false}
          onKeyDown={this.handleEnterPressed}
          name='validationCode'
          id='validationCode'
          autoComplete='off'
          disabled={blocked}
        />
        <Box component={'span'} sx={requestCodeStyles}>
          <span className={classNames({ disabled: blocked })} onClick={this.requestCode}>
            {t('TwoFactorForm.RequestNewCode')}
          </span>
        </Box>
      </div>
    );

    return (
      <form onSubmit={handleSubmit}>
        <div className='twoFactorSettings'>
          <div className='formItems'>
            <div className='headerBody'>
              {t(messages.accountSection.twoFactor.headerText)}
            </div>

            <div className='paddingTop20'>
              <Field
                label={t('TwoFactorForm.MobileNumber')}
                hintText={!twoFactorCellPhone ? t('TwoFactorForm.EnterMobileNumber') : `(XXX) XXX-${twoFactorCellPhone}`}
                component={TextField}
                className='fullScreen'
                disabled={(codeInProcess && !codeError && !checkingNewPhone) || blocked}
                fullWidth={false}
                normalize={TextUtil.formatPhoneNumber}
                onChange={this.handlePhoneChange}
                name='cellPhone'
                id='cellPhone'
              />
            </div>

            {!!showLoginTwoFAToggle && twoFactorLoginToggle}

            {!!showLoginTwoFAToggle && (<div className='headerBody' id='accountMessage'>
              {t(messages.accountSection.twoFactor.description)}
            </div>)}

            {twoFactorCodeStatusUtil.isCodeSent(codeStatus) && twoFactorCode}
          </div>
        </div>
      </form>
    );
  }
}

let TwoFactorFormContainer = reduxForm({
  form: 'twoFactorForm',
  validate,
  fields: [
    'cellPhone',
    'hasTwoFactorEnabled',
  ],
  enableReinitialize: false,
})(TwoFactorForm);

export function mapStateToProps(state) {
  const initialValues = {};
  const cellPhoneSelector = selector(state, 'cellPhone');
  const twoFactorFormFlag = state.auth?.twoFactorFormFlag;
  const twoFactorCellPhone = state.merchantSettings?.twoFactor?.mobile_number?.slice(-4);
  const checkingNewPhone = !!state.auth?.twoFactorFormPhoneNumber;

  initialValues.hasTwoFactorEnabled = _.isNil(twoFactorFormFlag) ? state.user?.data?.is_ecp : twoFactorFormFlag;
  initialValues.cellPhone = !!state.auth?.twoFactorFormPhoneNumber ? state.auth.twoFactorFormPhoneNumber : '';

  return {
    initialValues,
    person: state.user,
    cellPhoneSelector,
    twoFactorCellPhone,
    checkingNewPhone
  };
}

const selector = formValueSelector('twoFactorForm');

export default connect(mapStateToProps)(TwoFactorFormContainer);;
