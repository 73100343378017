/**
 North American Bancard ('NAB') CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

 import LabelUtil from '../../util/LabelUtil';
 import React from 'react';
 export const CompetitionCompare = ({alt}) => {
   return (
    <svg className='competitionCompare' alt={alt} xmlns='http://www.w3.org/2000/svg' width='134.978' height='110.181' viewBox='0 0 134.978 110.181'>
      <defs>
        <clipPath id='clipPath'>
          <rect id='Rectangle_1038' data-name='Rectangle 1038' width='134.978' height='110.181' transform='translate(0 0)' fill='none' stroke='#1a1e22' strokeWidth='1'/>
        </clipPath>
      </defs>
      <g id='Group_1648' data-name='Group 1648' transform='translate(-1034.043 -499.231)'>
        <path id='Path_2399' data-name='Path 2399' d='M1064.273,112.035c.33-.173,4.48-2.816,6.438-4.24a32.771,32.771,0,0,0-8.466-1.159c-.789-2.179-2.605-5.5-3.395-7.678-1.2,2.277-2.264,5.418-3.44,7.71-2.557.312-5.39.836-8.192,1.236l6.344,3.555a44.908,44.908,0,0,0-3.382,7.76s5.853-2.173,8.78-4.244c2.208,1.238,5.273,2.908,7.481,4.146-.733-2.023-1.435-5.065-2.168-7.088' transform='translate(4.81 462.878)' fill={LabelUtil.getLabel().primaryColor} />
        <path id='Path_2396' data-name='Path 2396' d='M931.733,156.542c.407-.213,5.517-3.468,7.927-5.221a40.352,40.352,0,0,0-10.425-1.427c-.972-2.683-3.208-6.771-4.181-9.455-1.482,2.8-2.789,6.672-4.236,9.494-3.149.385-6.637,1.029-10.088,1.522l7.812,4.378a55.313,55.313,0,0,0-4.165,9.556s7.208-2.676,10.813-5.226c2.719,1.524,6.493,3.581,9.212,5.106-.9-2.491-1.766-6.237-2.669-8.728' transform='translate(125.24 429.054)' fill={LabelUtil.getLabel().primaryColor} />
        <path id='Path_2398' data-name='Path 2398' d='M1066.512,113.752c.374-.2,5.069-3.186,7.283-4.8a37.07,37.07,0,0,0-9.578-1.311c-.893-2.465-2.947-6.221-3.84-8.686-1.361,2.576-2.562,6.129-3.891,8.722-2.893.353-6.1.946-9.267,1.4l7.176,4.022a50.786,50.786,0,0,0-3.826,8.779s6.622-2.458,9.933-4.8c2.5,1.4,5.965,3.29,8.463,4.69-.83-2.288-1.623-5.73-2.452-8.018' transform='translate(86.564 448.228)' fill={LabelUtil.getLabel().primaryColor} />
        <g id='Group_1647' data-name='Group 1647' transform='translate(1034.043 499.231)'>
          <g id='Group_1646' data-name='Group 1646' transform='translate(0 0)' clipPath='url(#clipPath)'>
            <path id='Path_2381' data-name='Path 2381' d='M846.859,292.065h0a.316.316,0,0,1-.315-.318l.441-93.863a.316.316,0,0,1,.316-.315h0a.316.316,0,0,1,.315.318l-.441,93.863a.316.316,0,0,1-.316.315' transform='translate(-779.643 -181.957)' fill='#1a1e22' stroke='#1a1e22' strokeWidth='1'/>
            <path id='Path_2382' data-name='Path 2382' d='M501.817,1385.238a.316.316,0,0,1,0-.632l55.082-.139h0a.316.316,0,0,1,0,.632l-55.082.139Z' transform='translate(-461.869 -1275.057)' fill='#1a1e22' stroke='#1a1e22' strokeWidth='1'/>
            <path id='Path_2383' data-name='Path 2383' d='M255.474,59.962a.316.316,0,0,1-.067-.625l94.408-20.609a.316.316,0,0,1,.135.618L255.542,59.955a.316.316,0,0,1-.068.007' transform='translate(-234.994 -35.661)' fill='#1a1e22' stroke='#1a1e22' strokeWidth='1'/>
            <path id='Path_2384' data-name='Path 2384' d='M798.749,126a4.224,4.224,0,0,0,1.1-1.632c1.249-3.391-2.264-6.7-5.578-6.248-4.757.652-6.053,7.3-1.3,9.083a5.848,5.848,0,0,0,5.78-1.2' transform='translate(-727.556 -108.746)' fill='#fff' stroke='#1a1e22' strokeWidth='1'/>
            <path id='Path_2385' data-name='Path 2385' d='M791.115,124.156a5.515,5.515,0,0,1-1.932-.338,4.665,4.665,0,0,1-3.156-5.04,5.386,5.386,0,0,1,1.362-3.011,5.169,5.169,0,0,1,3.161-1.642,5.707,5.707,0,0,1,5.084,2.135,4.59,4.59,0,0,1,.833,4.536,4.525,4.525,0,0,1-1.177,1.754,6.2,6.2,0,0,1-4.175,1.606m.1-9.443a4.264,4.264,0,0,0-.577.039,4.593,4.593,0,0,0-3.981,4.1,4.055,4.055,0,0,0,2.749,4.377,5.549,5.549,0,0,0,5.454-1.138,3.9,3.9,0,0,0,1.015-1.51,3.972,3.972,0,0,0-.732-3.921,5.156,5.156,0,0,0-3.929-1.944' transform='translate(-723.881 -105.066)' fill='#1a1e22' stroke='#1a1e22' strokeWidth='1'/>
            <path id='Path_2386' data-name='Path 2386' d='M1147.554,67.083a.316.316,0,0,1-.3-.422c3.177-8.977,6.409-18.707,9.533-28.118s6.358-19.147,9.537-28.129a3.678,3.678,0,0,1,1.555-2.244,2.88,2.88,0,0,1,3.2.674,6.951,6.951,0,0,1,1.486,2.731c3.089,8.628,6.339,17.965,9.482,26.994s6.391,18.363,9.479,26.988a.316.316,0,0,1-.6.213c-3.089-8.627-6.339-17.964-9.481-26.994s-6.391-18.364-9.48-26.989a6.467,6.467,0,0,0-1.335-2.494,2.27,2.27,0,0,0-2.469-.563,3.134,3.134,0,0,0-1.251,1.894c-3.177,8.977-6.409,18.707-9.533,28.117s-6.358,19.147-9.537,28.13a.316.316,0,0,1-.3.211' transform='translate(-1056.575 -7.297)' fill='#1a1e22' stroke='#1a1e22' strokeWidth='1'/>
            <path id='Path_2387' data-name='Path 2387' d='M1194.922,738.579a36.435,36.435,0,0,1-43.683,1.1c.507,7.581,4.083,14.921,10.745,18.574a23.165,23.165,0,0,0,21.85.006c6.664-3.649,10.577-12.1,11.088-19.682' transform='translate(-1060.26 -680.212)' fill='#fff' stroke='#1a1e22' strokeWidth='1'/>
            <path id='Path_2388' data-name='Path 2388' d='M1169.231,757.629h-.007a23.094,23.094,0,0,1-11.076-2.78c-6.272-3.439-10.35-10.478-10.908-18.83a.316.316,0,0,1,.5-.279,36.119,36.119,0,0,0,43.3-1.093.316.316,0,0,1,.511.269,29,29,0,0,1-3.273,11.443,20.446,20.446,0,0,1-7.979,8.5,23.094,23.094,0,0,1-11.071,2.774m-21.308-20.99c.709,7.843,4.614,14.412,10.529,17.655a22.46,22.46,0,0,0,10.772,2.7h.007A22.457,22.457,0,0,0,1180,754.3c4.847-2.654,10.013-9.437,10.868-18.718a36.749,36.749,0,0,1-42.944,1.057' transform='translate(-1056.577 -676.528)' fill='#1a1e22' stroke='#1a1e22' strokeWidth='1'/>
            <path id='Path_2389' data-name='Path 2389' d='M1385.881,9.613a2.994,2.994,0,0,0,.777-1.157c.885-2.4-1.6-4.751-3.954-4.43-3.372.462-4.291,5.176-.921,6.439a4.146,4.146,0,0,0,4.1-.853' transform='translate(-1270.638 -3.681)' fill='#fff' stroke='#1a1e22' strokeWidth='1'/>
            <path id='Path_2390' data-name='Path 2390' d='M1379.395,7.327a4,4,0,0,1-1.4-.245,3.4,3.4,0,0,1-2.3-3.669,3.831,3.831,0,0,1,3.285-3.379,4.14,4.14,0,0,1,3.688,1.547,3.344,3.344,0,0,1,.605,3.3,3.3,3.3,0,0,1-.858,1.279,4.493,4.493,0,0,1-3.023,1.163m.071-6.694a2.937,2.937,0,0,0-.4.027,3.165,3.165,0,0,0-2.743,2.823,2.786,2.786,0,0,0,1.89,3.007,3.836,3.836,0,0,0,3.771-.788,2.672,2.672,0,0,0,.7-1.035,2.726,2.726,0,0,0-.5-2.69,3.561,3.561,0,0,0-2.713-1.343' transform='translate(-1266.96 -0.001)' fill='#1a1e22' stroke='#1a1e22' strokeWidth='1'/>
            <path id='Path_2391' data-name='Path 2391' d='M.316,311.909a.316.316,0,0,1-.3-.422C3.2,302.51,6.427,292.78,9.552,283.369s6.358-19.147,9.538-28.129A3.679,3.679,0,0,1,20.645,253a2.88,2.88,0,0,1,3.2.674,6.948,6.948,0,0,1,1.486,2.731c3.089,8.628,6.339,17.965,9.482,26.995s6.391,18.363,9.479,26.988a.316.316,0,0,1-.6.213c-3.089-8.627-6.339-17.964-9.481-26.993s-6.392-18.364-9.48-26.99a6.466,6.466,0,0,0-1.335-2.494,2.27,2.27,0,0,0-2.469-.563,3.135,3.135,0,0,0-1.251,1.894c-3.177,8.977-6.409,18.707-9.533,28.118S3.794,302.715.614,311.7a.316.316,0,0,1-.3.211' transform='translate(0 -232.775)' fill='#1a1e22' stroke='#1a1e22' strokeWidth='1'/>
            <path id='Path_2392' data-name='Path 2392' d='M47.684,983.4A36.435,36.435,0,0,1,4,984.506c.507,7.581,4.083,14.921,10.745,18.574a23.164,23.164,0,0,0,21.85.006c6.664-3.649,10.577-12.1,11.088-19.682' transform='translate(-3.685 -905.688)' fill='#fff' stroke='#1a1e22' strokeWidth='1'/>
            <path id='Path_2393' data-name='Path 2393' d='M21.993,1002.453h-.007a23.092,23.092,0,0,1-11.076-2.78C4.638,996.234.56,989.195,0,980.843a.316.316,0,0,1,.5-.279,36.118,36.118,0,0,0,43.3-1.093.316.316,0,0,1,.511.269,29,29,0,0,1-3.273,11.443,20.446,20.446,0,0,1-7.978,8.5,23.1,23.1,0,0,1-11.071,2.774M.685,981.463c.709,7.844,4.614,14.411,10.529,17.655a22.46,22.46,0,0,0,10.772,2.7h.007a22.458,22.458,0,0,0,10.767-2.7c4.847-2.654,10.013-9.437,10.868-18.718A36.749,36.749,0,0,1,.685,981.463' transform='translate(-0.001 -902.004)' fill='#1a1e22' stroke='#1a1e22' strokeWidth='1'/>
            <path id='Path_2394' data-name='Path 2394' d='M230.91,252.116a3,3,0,0,0-.456,1.317c-.241,2.55,2.766,4.183,4.955,3.272,3.142-1.309,2.826-6.1-.756-6.461a4.146,4.146,0,0,0-3.744,1.872' transform='translate(-212.23 -230.453)' fill='#fff' stroke='#1a1e22' strokeWidth='1'/>
            <path id='Path_2395' data-name='Path 2395' d='M230.412,253.591a4.287,4.287,0,0,1-2.522-.832,3.344,3.344,0,0,1-1.43-3.04,3.3,3.3,0,0,1,.5-1.456,4.439,4.439,0,0,1,4.043-2.018,3.4,3.4,0,0,1,3.158,2.96,3.831,3.831,0,0,1-2.312,4.107,3.743,3.743,0,0,1-1.439.279M227.5,248.6a2.672,2.672,0,0,0-.408,1.179,2.725,2.725,0,0,0,1.176,2.472,3.5,3.5,0,0,0,3.343.478,3.165,3.165,0,0,0,1.93-3.431,2.786,2.786,0,0,0-2.6-2.424A3.838,3.838,0,0,0,227.5,248.6' transform='translate(-208.549 -226.768)' fill='#1a1e22' stroke='#1a1e22' strokeWidth='1'/>
          </g>
        </g>
      </g>
    </svg>
   )
 }