import React from 'react';
import {features, text} from '../../../constants/planInformation';
import planTypes from '../../../constants/planTypes';
import LabelUtil from '../../util/LabelUtil';

const PlanDetails = ({plan, t}) => {
  
  const planText = text[plan];
  const {left, right} = features(plan);
  const label = LabelUtil.getLabel();
  
  return (
    <>
      <div className='cardPlanDetails'>
        <div className='detailsHeader'>
          <h3 className='title'>{t(planText.label)}</h3>
          <div className='generalInfo'>
            <p className='price'>{t(planText.price)}</p>
            <p className='description'>{t(planText.subHeading)}</p>
          </div>
        </div>
        <div className='detailsBody'>
          <ul className='leftList'>
            {left.map((feature, index) =>
              <li className='planFeature' key={`right${index}`}>
                <span>{`${t(feature.name)}${feature.asterisk ? '*' : ''}`}</span>
                {(feature.plans.includes(plan) && !feature.addOn) && <span className='check'>{String.fromCharCode(10003)}</span>}
              </li>
            )}
          </ul>
          <ul className='rightList'>
            {right.map((feature, index) =>
              <li className='planFeature' key={`right${index}`}>
                <span>{`${t(feature.name)}${feature.asterisk ? '*' : ''}`}</span>
                {(feature.plans.includes(plan) && !feature.addOn) && <span className='check'>{String.fromCharCode(10003)}</span>}
                {feature.addOn && <span className='addOn'>Add-On**</span>}
              </li>
            )}
          </ul>
        </div>
      </div>
      <p className='cardPlanDisclaimer'>{t('PlanDisclaimer.Free', {phone: label.phoneNumber})}</p>
      {plan === planTypes.premiumPlus && <p className='cardPlanDisclaimer'>{t('PlanDisclaimer.PremiumPlus')}</p>}
    </>
  );
  
}

export default PlanDetails;