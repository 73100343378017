import moment from 'moment-timezone';
import FormatTextUtil from './FormatTextUtil';
import emailValidator from 'email-validator';
import IconUtil from './IconUtil';
import PaymentUtil from './PaymentUtil';
import CommonUtil from './CommonUtil';
import {saveCustomer, updateCustomer} from '../../actions/customerActions';
import _ from 'lodash';
import UserUtil from './UserUtil';

const CustomerUtil = {

  cleanCustomers: (customers) => {
    if (customers && customers.filteredData && Array.isArray(customers.filteredData)) {
      customers.filteredData = customers.filteredData.filter(
        (customer) => {
          const firstName =  Boolean(customer?.first_name?.trim());
          const lastName = Boolean(customer?.last_name?.trim());
          const emailAddress = Boolean(customer?.email_addresses?.[0]);
          const phoneNumber = Boolean(customer?.phone_number?.trim());
          return ((firstName || lastName) && (phoneNumber || emailAddress))
        }
      );
    }
    return customers;
  },

  checkCustomer(values, props) {

    const {customers, user, dispatch} = props;
    const selectedCustomer = customers?.selectedCustomer ?? {};

    const paymentValues = {payment_methods: []};

    if (selectedCustomer?.id && selectedCustomer?.payment_methods?.length) {
      paymentValues.payment_methods = selectedCustomer?.payment_methods;
    }

    const addressFields = (
      values?.customer_street_address_1 ||
      values?.customer_street_address_2 ||
      values?.customer_city ||
      values?.customer_state ||
      values?.customer_zip
    ) && {
      street_address_1: values?.customer_street_address_1 ?? '',
      street_address_2: values?.customer_street_address_2 ?? '',
      city: values?.customer_city ?? '',
      state: values?.customer_state ?? '',
      zip: values?.customer_zip ?? ''
    };

    const contactValues = {
      return_customer_on_conflict: true,
      first_name: values?.customer_first ?? '',
      last_name: values?.customer_last ?? '',
      email_addresses: values?.customer_email_addresses ?? [],
      phone_number: values?.customer_phone ? FormatTextUtil.formatWholeNumber(values.customer_phone) : '',
      ...(paymentValues)
    };

    return new Promise((resolve, reject) => {

      const saveOperation = async (customerId) => {
        const customerApiCall = customerId ? updateCustomer : saveCustomer;
        const customer = customerId ? selectedCustomer : 'new';

        try {
          const parsedContactValues = {
            ...(customerId ? { address: addressFields } : addressFields),
            ...contactValues
          };
          const saveCustomerResponse = await dispatch(customerApiCall(props.user, customer, parsedContactValues));

          let customerData;

          if (saveCustomerResponse?.response?.id) { // saveCustomer
            customerData = { paCustomerId: saveCustomerResponse.response.id };
          } else { // updateCustomer
            customerData = customers.selectedCustomer.is_pa_customer ?
              { paCustomerId: customers.selectedCustomer.id } :
              { customerId: customers.selectedCustomer.id }
          }
          resolve(customerData);

        } catch (saveCustomerError) {
          reject(saveCustomerError)
        }

      };

      const isCustomerDirty =
        customers?.selectedCustomer?.first_name !== contactValues.first_name ||
        customers?.selectedCustomer?.last_name !== contactValues.last_name ||
        _.head(customers?.selectedCustomer?.email_addresses) !== _.head(contactValues.email_addresses) ||
        ((customers?.selectedCustomer?.phone_number !== null && contactValues.phone_number !== '') &&
          customers?.selectedCustomer?.phone_number !== contactValues.phone_number
        ) || Boolean(addressFields);

      if (isCustomerDirty) {
        if (UserUtil.isPayanywhere(user)) {
          return saveOperation(customers?.selectedCustomer?.id);
        } else {
          resolve({
            customer: {
              first_name: contactValues.first_name,
              last_name: contactValues.last_name,
              email: contactValues.email_addresses[0],
              phone: contactValues.phone_number
            }
          });
        }
      } else {
        resolve({
          ...(customers.selectedCustomer.is_pa_customer
              ? { paCustomerId: customers.selectedCustomer.id }
              : { customerId: customers.selectedCustomer.id }
          )
        });
      }
    });

  },

  formatPaymentMethod(paymentMethod = {}) {

    if (!paymentMethod?.cdigits && !paymentMethod?.accountNumber) {
      return paymentMethod;
    }
    const result = {};
    if (paymentMethod?.cdigits) {
      const zipCode = paymentMethod.zipPlus4 ? paymentMethod.cardZip + paymentMethod.zipPlus4 : paymentMethod.cardZip;
      const expYear = moment(paymentMethod.edate, 'MM/YY').year();
      const expMonth = moment(paymentMethod.edate, 'MM/YY').month() + 1;
      result.type = 'card'
      zipCode && (result.zip = zipCode);
      expMonth && (result.exp_month = expMonth);
      expYear && (result.exp_year = expYear);
      paymentMethod.cvv && (result.cvv = paymentMethod.cvv);
      paymentMethod.streetNumber && (result.address = paymentMethod.streetNumber);
      paymentMethod.cardHolderName && (result.name_on_card = paymentMethod.cardHolderName);
      paymentMethod.cdigits && (result.number = FormatTextUtil.formatWholeNumber(paymentMethod.cdigits));
      paymentMethod.is_default && (result.is_default = paymentMethod.is_default);
    } else {
      const [firstName, lastName] = paymentMethod.accountName.split(' ');
      result.type = 'ach';
      paymentMethod.accountNumber && (result.account_nbr = paymentMethod.accountNumber);
      paymentMethod.routingNumber && (result.routing_nbr = paymentMethod.routingNumber);
      firstName && (result.first_name = firstName);
      lastName && (result.last_name = lastName);
      paymentMethod.accountType = (result.account_type = paymentMethod.accountType);
      paymentMethod.is_default && (result.is_default = paymentMethod.is_default);
    }
    return result;
  },

  formatPaymentMethodsBody(values) {
    let formattedPaymentMethods = values?.payment_methods?.map(this.formatPaymentMethod) || [];

    if (formattedPaymentMethods.every(paymentMethod => !paymentMethod.is_default)) {
      const [firstPaymentMethod] = formattedPaymentMethods;
      if (firstPaymentMethod) {
        firstPaymentMethod.is_default = true;
      }
    }
    return formattedPaymentMethods;
  },

  hasValidEmail: (emailArray) => {
    let validEmail = false;
    emailArray?.forEach((email) => {
      if (emailValidator.validate(email)) {
        validEmail = true;
      }
    });
    return validEmail;
  },

  hasValidPhoneNumber: (phoneNumber) => {
    const phoneNumberRegEx = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
    return phoneNumberRegEx.test(phoneNumber);
  },

  renderPaymentMethod: (paymentMethod) => {
    const paymentMethodInfo = {};
    if (paymentMethod?.type === 'ach') {
      paymentMethodInfo.img = PaymentUtil.getCardImage('ach').cardImgUrl;
      paymentMethodInfo.accountType = CommonUtil.capitalizeFirstLetter(paymentMethod?.ach_type);
      paymentMethodInfo.paddedNumber = `**** **** **** ${paymentMethod?.account_num_last4}`;
      paymentMethodInfo.routingNumber = paymentMethod?.routing_num_last4;
    } else {
      paymentMethodInfo.img = PaymentUtil.getCardImage(paymentMethod?.network).cardImgUrl;
      paymentMethodInfo.expMonth = paymentMethod?.exp_month;
      paymentMethodInfo.expYear = paymentMethod?.exp_year;
      paymentMethodInfo.paddedNumber = `**** **** **** ${paymentMethod?.last4}`;
    }
    paymentMethodInfo.type = paymentMethod?.type ?? 'card';
    paymentMethodInfo.deleteIcon = IconUtil.getIcon('DeleteIcon');
    paymentMethodInfo.id = paymentMethod?.id;
    return paymentMethodInfo;
  }

};

export default CustomerUtil;
