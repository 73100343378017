/**
 NAB Holdings, LLC (“NAB”) CONFIDENTIAL MATERIAL

Unpublished Copyright © 2000-2017 NAB, All Rights Reserved.

NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
and Non-disclosure agreements explicitly covering such access.

The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
MAY DESCRIBE, IN WHOLE OR IN PART.

*/

import React, { Component } from 'react';
import FilterPanel from '../shared/FilterPanel';
import StoreSettingsForm from './StoreSettingsForm';
import LabelUtil from '../util/LabelUtil';
import routes from '../../constants/routes';
import Button from '../shared/Button';
import { submit } from 'redux-form';
import messages from '../../constants/messages';
import Modal from '../shared/Modal';
import { getMerchantSettings, partialUpdateSettings } from '../../actions/merchantSettingsActions';
import StorePreview from './StorePreview';
import {getItems, resetSalesItemImage} from '../../actions/itemsActions';
import Page from '../shared/Page';

export default class StoreSettings extends Component {

  constructor(props) {
    super(props);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      showPreview: false,
    }
  }

  async componentDidMount() {
    await this.loadData(this.props)
  }

  async loadData(props){
    await Promise.all([
      props.dispatch(getMerchantSettings(props.user)),
      props.dispatch(resetSalesItemImage()),
      props.dispatch(getItems(props.user))
    ]);
  }


  onSaveClick() {
    this.props.dispatch(submit('storeSettingsForm'));
  }

  async handleSubmit(values) {
    const { props } = this;
    const payload = {
      merchant_settings: {
        ...values
      }
    };
    await props.dispatch(partialUpdateSettings(payload, props.user));
    await this.loadData(this.props);
  }

  openPreview = () => {
    this.setState({showPreview: true});
  };

  closePreview = () => {
    this.setState({showPreview: false});
  };

  render() {
    const {t, formReducer, merchantSettings, items} = this.props;

    const isLoading = merchantSettings.isProcessing || items.isFetching;

    const labelColor = LabelUtil.getLabelColor();

    const filterPanel = (
      <FilterPanel
        {...this.props}
        filterData={this.props.settingsMenuItems}
        selectFilterCallback={this.props.handleMenuSelection}
      />
    );

    const storeModal = (<Modal
      title={t('StoreSettings.StorePreview.Title')}
      cancelText={t('Close')}
      maxWidth='lg'
      open={this.state.showPreview}
      onClose={this.closePreview}
      hideConfirmButton>
        <StorePreview {...this.props} />
      </Modal>);

    const bottomButton = (
      <div className='detailPanelHolderButtons saveContainer'>
        {formReducer && formReducer.storeSettingsForm && formReducer.storeSettingsForm.syncErrors &&
        <span className='settingsErrorBottom'>{t(messages.storeSettings.formErrorsLabel)}</span>}
        <Button
          label={t('SaveSettingsBtn')}
          onClick={this.onSaveClick}
        />
      </div>
    );

    return(
      <section className='settings'>
        <div className='settings pageWrap'>
          <Page
            loading={isLoading}
            title={t('Business Settings')}
          >
            <div className='flexContainer flexContainerResponsiveLayout'>
              {filterPanel}
              <div
                className='businessSettingsSubsection settingsFormContainer appSettings appSettingsWrapper'>
                <div className='title'>
                  <span
                    className='heading'>{t(messages.storeSettings.formTitle)}</span>
                  <span className='subText'>
                    {t(messages.storeSettings.formSubtitlePartOne)}
                    <a
                      href={`${globalApplicationLabel.path}${routes.account.root}${routes.account.salesSettings}`}
                      target='_blank'
                      style={{
                        color: labelColor,
                        textDecoration: 'none'
                      }}
                    >
                      {t(messages.storeSettings.formSubtitleLink)}
                    </a>{t(messages.storeSettings.formSubtitlePartTwo)}
                  </span>
                </div>
                <StoreSettingsForm
                  onSubmit={this.handleSubmit}
                  openPreview={this.openPreview}
                  {...this.props}
                />
                {bottomButton}
              </div>
            </div>
          </Page>
        </div>
        {storeModal}
      </section>
    );
  }
}
