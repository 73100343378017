/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';
import SettingsUtil from '../util/SettingsUtil';
import IconUtil from '../util/IconUtil';
import Facebook from '../icons/FacebookBlackIcon';
import Instagram from '../icons/InstagramBlackIcon';
import Twitter from '../icons/TwitterBlackIcon';
import Yelp from '../icons/YelpBlackIcon';
import DateUtils from '../util/DateUtil';
import Image from '../shared/Image';
import { isNorth } from '../util/WhiteLabelUtil';

const LoyaltyEmailPreview = ({formReducer, merchantSettings, t}) => {

  const merchant = merchantSettings?.merchantSettings;
  const message = formReducer?.loyaltyFormVpc?.values?.rewardMessage || t('LoyaltyPrograms.RewardCustomMsg');
  const rewardAmountType = formReducer?.loyaltyFormVpc?.values?.rewardAmountType || 'percent';
  const rewardAmount = formReducer?.loyaltyFormVpc?.values?.rewardAmount || '20';
  const isAddressComplete = merchant?.street_address?.address1 && merchant?.street_address?.city;
  const socialEnabled = merchant?.social_media_enabled;
  const logo = merchant?.logo;
  const logoExt = logo ? SettingsUtil.getImgExt(logo) : null;

  const expirationDates = {
    '3 months': DateUtils.formatDate(DateUtils.addTimeToToday(3, 'M'), 'MM/DD/YY'),
    '6 months': DateUtils.formatDate(DateUtils.addTimeToToday(6, 'M'), 'MM/DD/YY'),
    '9 months': DateUtils.formatDate(DateUtils.addTimeToToday(9, 'M'), 'MM/DD/YY'),
    '12 months': DateUtils.formatDate(DateUtils.addTimeToToday(12, 'M'), 'MM/DD/YY'),
    'notexpire': false
  }

  const activeExpiration = expirationDates[formReducer?.loyaltyFormVpc?.values?.rewardExpiration] || false;

  const isNhLabel = isNorth();

  const emailImage = isNhLabel ? '/images/loyalty/nh/upgradeRightTop.svg' : '/images/loyalty/ph/emailImg.svg';
  const bottomLogo = isNhLabel ? '/images/north/logo.svg' : '/images/paymenthubs/logo_rebrand.svg';
  const logoAlt = isNhLabel ? 'North Hub' : 'Payments Hub';

  return (
    <div className='loyaltyEmail'>
      {(merchant?.display_company_logo && logo) &&
        <img src={`data:image/${logoExt};base64,${logo}`} className='logoImg'/>}
      <header>
        <Image 
          alt={'Loyalty program'}
          src={emailImage}
          sx={{wdith: '122px'}}
        />
        <h1>{t('LoyaltyPrograms.RewardEmailHeader')}</h1>
        <p>{message}</p>
      </header>
      <section className='grayContainer'>
        <div className='icon'>
          {IconUtil.getIcon('LoyaltyProgramTag')}
        </div>
        {rewardAmountType === 'percent' ?
          <h2>{`${rewardAmount}% ${t('LoyaltyPrograms.RewardDiscountText')}`}</h2>
        :
          <h2>{`$${rewardAmount} ${t('LoyaltyPrograms.RewardDiscountText')}`}</h2>
        }
        <p className='qrText'>{t('LoyaltyPrograms.RewardQrText')}</p>
        <div className='qr' />
        <p className='discountCode'>4K928JMELLOL1776</p>
      </section>
      {
        activeExpiration ?
          <section className='expiration'>
            <p className='expText'>{`${t('LoyaltyPrograms.RewardExpires')} ${activeExpiration}`}</p>
          </section>
        :
          null
      }
      {(merchant?.merchant_name || isAddressComplete) && <div className='merchant'>
        {merchant?.merchant_name &&
          <p className='name'>{merchant?.merchant_name}</p>}
        {isAddressComplete && <p className='card address'>{`${merchant?.street_address?.address1}`}</p>}
        {isAddressComplete && <p className='card city'>{`${merchant?.street_address?.city}, ${merchant?.street_address?.state} ${merchant?.street_address?.zip ? merchant.street_address.zip : ''}`}</p>}
        {merchant?.website &&
          <p className='card website'>{merchant.website}</p>}
      </div>}
      {socialEnabled && <div className='social'>
        <ul>
          {merchant?.facebook_link && <li>
            <Facebook />
          </li>}
          {merchant?.instagram_link && <li>
            <Instagram />
          </li>}
          {merchant?.twitter_link && <li>
            <Twitter />
          </li>}
          {merchant?.yelp_link && <li>
            <Yelp />
          </li>}
        </ul>
      </div>}
      <div className='brand'>
        <Image 
          alt={logoAlt}
          src={bottomLogo}
        />
      </div>
    </div>
  );

}

export default LoyaltyEmailPreview;
