/**
North American Bancard ("NAB") CONFIDENTIAL MATERIAL

Copyright 2000 NAB, All Rights Reserved.

NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
and Non-disclosure agreements explicitly covering such access.

The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
MAY DESCRIBE, IN WHOLE OR IN PART.

*/

import React, { Component } from 'react';
import {Modal as MuiModal} from '@mui/material';
import PaymentLinkForm from '../../paymentLink/PaymentLinkForm';
import paymentLinks from '../../../constants/paymentLinks';
import IconUtils from '../../util/IconUtil';
import MerchantLogo from '../../MerchantLogo';
import { getMerchantSettings } from '../../../actions/merchantSettingsActions';

export default class PreviewPaymentLink extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    if(!this.props.merchantSettings?.merchantSettings) {
      await this.props.dispatch(getMerchantSettings(this.props.user));
    }
  }

  render () {
    const {
      open,
      handleClose,
      formReducer,
      merchantSettings: { merchantSettings },
      t
    } = this.props;

    const {
      acceptTips,
      paymentLinkTitle,
      paymentLinkAmountType,
      paymentLinkDescription,
      addCustomField,
      image,
      paymentAmount1,
      paymentAmount2,
      paymentAmount3,
      allowCustomAmount,
      cashDiscounting,
      enableTax
    } = formReducer?.createPaymentLinksForm?.values || {};

    const defaultFixedAmount = '10.0';
    const defaultMultipleAmount = ['10.0', '15.0', '20.0'];
    let amount = [];

    if (paymentLinkAmountType === paymentLinks.amountTypes.fixedAmount) {
      amount = paymentAmount1;
    } else if (paymentLinkAmountType === paymentLinks.amountTypes.multipleAmount) {
      if (paymentAmount1) amount.push(paymentAmount1);
      if (paymentAmount2) amount.push(paymentAmount2);
      if (paymentAmount3) amount.push(paymentAmount3);
      if (!amount.length) amount = defaultMultipleAmount;
    }


    const settings = {
      tips_enabled: acceptTips,
      title: paymentLinkTitle || t('PaymentLinks.CreateForm.SampleTitle'),
      type: paymentLinkAmountType,
      amount: amount || defaultFixedAmount,
      include_other: allowCustomAmount,
      custom_field: addCustomField ? {name: addCustomField} : false,
      subtitle: paymentLinkDescription || t('PaymentLinks.CreateForm.DescriptionHint'),
      image,
      cash_discount_enabled: cashDiscounting,
      tax_enabled: enableTax
    };

    const closeIcon = IconUtils.getIcon('CancelIcon');

    let headerTitle = null;
    if (merchantSettings) {
      if (merchantSettings?.logo) {
        headerTitle = (
          <MerchantLogo
            altText={merchantSettings?.merchant_name}
            logoBase64={merchantSettings.logo}
            size={100}
          />
        );
      } else {
        headerTitle = merchantSettings?.merchant_name;
      }
    } else {
      headerTitle = (
        <img className='merchantLogoImage' alt={'Payments Hub'} src={`${serverDomainUrl}images/paymenthubs/logo.svg`}/>
      );
    }

    let merchantUrl = null;
    const website = merchantSettings?.website;
    if (website?.startsWith('http')) {
      merchantUrl = website
    } else if (website) {
      merchantUrl = `https://${website}`;
    }

    const header = (
      <div className='paymentLinkHeader'>
        {merchantUrl ? (
          <a href={merchantUrl} target='_blank' alt={merchantSettings?.merchant_name}>{headerTitle}</a>
        ) : headerTitle}
      </div>
    );

    const merchantSettingsCopy = _.cloneDeep(merchantSettings);
    const parsedMerchantSettings = {
      cd_enabled: merchantSettings?.cash_discount_enabled,
      cd_fixed_amount: merchantSettings?.cash_discount_fixed_amount,
      cd_percent_amount: merchantSettings?.cash_discount_amount_percent,
      ...merchantSettingsCopy
    }

    return (
      <MuiModal open={open} onClose={handleClose}>
        <div className='paymentLinkPreview'>
          <div className='closeIcon' onClick={handleClose}>{closeIcon}</div>
          {header}
          <PaymentLinkForm
            {...this.props}
            merchantSettings={parsedMerchantSettings}
            settings={settings}
          />
        </div>
      </MuiModal>
    );
  }
}
