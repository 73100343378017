import i18n from 'i18next';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { TRANSLATIONS_ES } from './es_US/translations';
import { TRANSLATIONS_EN } from './en_US/translations';

export const i18nLanguages = { en_US: 'en-US', es_US: 'es-US' };
export const momentLanguages = { en_US: 'en', es_US: 'es' };

export function setLanguageKey(langKey) {
  switch (langKey) {
    case 'en_US':
    case 'en-US':
      return i18nLanguages.en_US
    case 'es_US':
    case 'es-US':
      return i18nLanguages.es_US
    default:
      return i18nLanguages.en_US
  }
}

export function setMomentLanguage(langKey) {
  let key;
  switch (langKey) {
    case 'en-US':
      key = momentLanguages.en_US
      break;
    case 'es-US':
      key = momentLanguages.es_US
      break;
    default:
      key = momentLanguages.en_US
  }
  moment.locale(key);
}

export function setInitialLanguage() {
  const i18nLanguage =  setLanguageKey(localStorage.getItem('i18nLanguage'));
  const i18nextLng =  setLanguageKey(localStorage.getItem('i18nextLng'));

  if (i18nextLng && (i18nextLng !== i18nLanguage)) {
    i18n.changeLanguage(i18nLanguage);
  }

  setMomentLanguage(i18nLanguage);
}

export function checkIsSpanish() {
  return i18n.language === i18nLanguages.es_US;
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    resources: {
      [i18nLanguages.en_US]: {
        translation: TRANSLATIONS_EN
      },
      [i18nLanguages.es_US]: {
        translation: TRANSLATIONS_ES
      }
    },
    lng: i18nLanguages.en_US,
    fallbackLng: i18nLanguages.es_US,
    interpolation: {
      escapeValue: false
    },
    useSuspense: false
  });

export default i18n;
