/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { submit, reset } from 'redux-form';
import {
  setSelectedItemTab,
  setSubmitButtonEnabled,
  setVisibilityFilter
} from '../../actions/userExperienceActions';
import { setTokenAutoRenew } from '../../actions/authActions';
import {
  addToImportLog,
  cleanImportLog,
  deleteBatchItems,
  deleteItem,
  getItems,
  saveItem,
  resetSalesItemImage,
  attachCategoryToBatchItems,
  onDropFileFailed
} from '../../actions/itemsActions';
import MasterDetailLayout from '../MasterDetailLayout';
import DetailPanel from '../shared/detailPanel/DetailPanel';
import ImportItemsForm from './ImportItemsForm';
import FilterPanel from '../shared/FilterPanel';
import actionTypes from '../../constants/actionTypes';
import MenuItem from '@mui/material/MenuItem';
import Select from '../shared/Select';
import MessageDialog from '../shared/MessageDialog';
import IconUtils from './../util/IconUtil';
import { isJSON } from './../util/CommonUtil';
import converter from 'papaparse'
import _ from 'lodash';
import ItemsDetailForm from './ItemsDetail';
import CsvExporter from './../util/CsvExporter';
import moment from 'moment';
import LabelUtil from './../util/LabelUtil';
import CsvUtil from './../util/CsvUtil';
import itemsUtil from './../util/ItemsUtil';
import TaxUtil from '../../components/util/TaxUtil';
import UserUtil from '../util/UserUtil';
import UpdateSpinner from '../UpdateSpinner';
import MobileUtil from './../util/MobileUtil';
import SearchBarAccessory from '../appBar/accessories/SearchBarAccessory';
import BottomBar from '../shared/BottomBar';
import MenuButton from '../shared/MenuButton';
import Modal from '../shared/Modal';
import Pagination from '../shared/PaginationMui';
import NoData from '../NoData';
import inventoryOptions from '../../constants/inventoryOptions';
import Page from '../shared/Page';
import ExportImportAccessory from '../appBar/accessories/ExportImportAccessory';
import ItemsFilterAccessory from '../appBar/accessories/ItemsFilterAccessory';
import CreateButtonAccessory from '../appBar/accessories/CreateButtonAccessory';

export default class Items extends Component {

  constructor(props) {
    super(props);

    this.showDetailForRow = this.showDetailForRow.bind(this);
    this.loadData = this.loadData.bind(this);
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.onCloseDetail = this.onCloseDetail.bind(this);
    this.displayUploadDialog = this.displayUploadDialog.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onUploadClick = this.onUploadClick.bind(this);
    this.processUploadedCsvFile = this.processUploadedCsvFile.bind(this);
    this.exportItems = this.exportItems.bind(this);
    this.setCroppedImage = this.setCroppedImage.bind(this);
    this.handleImportComplete = this.handleImportComplete.bind(this);
    this.onFileDrop = this.onFileDrop.bind(this);
    this.addToLog = this.addToLog.bind(this);
    this.handleUploadCompletion = this.handleUploadCompletion.bind(this);
    this.setImportProgressTotal = this.setImportProgressTotal.bind(this);
    this.increaseImportProgressPercentage = this.increaseImportProgressPercentage.bind(this);
    this.selectItems = this.selectItems.bind(this);
    this.checkRowSelected = this.checkRowSelected.bind(this);
    this.cleanSelectedItems = this.cleanSelectedItems.bind(this);
    this.deleteMultipleItems = this.deleteMultipleItems.bind(this);
    this.onDeleteConfirmation = this.onDeleteConfirmation.bind(this);
    this.selectAllItems = this.selectAllItems.bind(this);
    this.displayDeleteDialogList = this.displayDeleteDialogList.bind(this);
    this.displayDeleteDialogDetail = this.displayDeleteDialogDetail.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
    this.filterByCategoriesData = this.filterByCategoriesData.bind(this);
    this.addToCategory = this.addToCategory.bind(this);
    this.onChangePage = this.onChangePage.bind(this);

    this.handleFilterSelection = this.props.handleFilterSelection.bind(this);
    this.handleTopBarFilterSelection = this.props.handleTopBarFilterSelection.bind(this);
    this.handleCreateMenuClose = this.props.handleCreateMenuClose.bind(this);
    this.handleCreateNew = this.props.handleCreateNew;
    this.renderCreateMenu = this.props.renderCreateMenu.bind(this);
    this.handleCreateClick = this.props.handleCreateClick.bind(this);

    this.categoriesWithNoId = {
      allItems: JSON.stringify({name: 'All Items'}),
      favorite: JSON.stringify({name: 'Favorite'}),
    }

    this.state = {
      loading: false,
      pageNumber: 1,
      needToSearch: false,
      selectedIndex: 0,
      selectedCategory: {
        color: '000000',
        name: 'None'
      },
      selectedFilter: this.categoriesWithNoId.allItems,
      open: false,
      openDetail: false,
      createMenuOpen: false,
      openDeleteDialogType: 0,
      detailSubmitted: false,
      image_data: null,
      loadedImage: null,
      importCompleted: false,
      importInProgress: false,
      importTotal: 0,
      importCount: 0,
      importDetails: {
        fileStatus: '',
        fileName: ''
      },
      selectedItems: [],
      allSelected: false
    };
  }

  componentDidMount() {
    this.loadData();

    this.props.dispatch(setVisibilityFilter({
      property: 'type',
      value: this.categoriesWithNoId.allItems,
      categoryId: undefined
    }, 'filter'));
    this.props.dispatch(setVisibilityFilter('', ''));

    if (this.props.taxes.taxRate === 0) {
      TaxUtil.getGeoLocation(this);
    }

    if(this.props.location?.state?.openCreateNewFromTheStart) {
      this.showDetailForRow('new');
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const searchChanged = prevProps.visibilityFilter.search !== this.props.visibilityFilter.search;
    const categoryChanged = !!prevProps.visibilityFilter.filter?.value && prevProps.visibilityFilter.filter?.value !== this.props.visibilityFilter?.filter?.value;

    if (categoryChanged) {
      this.setPageNumberAndLoadData(1)
    }

    if (searchChanged) {
      this.setState({needToSearch: true});
      this.debouncedLoadDataHandleSearch();
    }

  }

  componentWillUnmount() {
    this.props.dispatch(setVisibilityFilter(null, 'filter'));
    this.props.dispatch(setVisibilityFilter('', ''));
  }

  loadData() {

    const { dispatch, user, visibilityFilter } = this.props;
    const { pageNumber } = this.state;
    const categoryId = visibilityFilter.filter?.categoryId || '';

    let searchValue = visibilityFilter.search;

    const priceRegex = /\$\d+(\.\d+)?/g;
    const decimalRegex = /(?<!\d)(\.\d{2})(?!\d)/g;


    if (Boolean(searchValue?.match(priceRegex))) {
      searchValue = searchValue.replace('$', '');
    }

    if (searchValue?.match(decimalRegex)) {
      searchValue = `0${searchValue}`
    }

    dispatch(resetSalesItemImage());
    dispatch(getItems(user, 'active', pageNumber, searchValue, categoryId));
  }

  debouncedLoadDataHandleSearch = _.debounce(() => {
    this.setPageNumberAndLoadData(1);
  }, inventoryOptions.items.debounceTime);

  onChangePage(ev, pageNumber) {
    if (this.state.pageNumber !== pageNumber) {
      this.setPageNumberAndLoadData(pageNumber);
    }
  }

  setPageNumberAndLoadData = (pageNumber) => {
    this.setState({ pageNumber, needToSearch: false }, () => this.loadData());
  };

  onCloseDetail() {
    this.setState({ selectedIndex: -1, openDetail: false, detailSubmitted: false});
  }

  onCellClick(selectedIndex) {
    this.setState({ selectedIndex, openDetail: true, detailSubmitted: false });
  }

  showDetailForRow(selectedIndex) {
    this.setState({ image_data: null, loadedImage: null });
    this.props.dispatch(setSelectedItemTab('details'));

    this.onCellClick(selectedIndex);
  }

  filterByCategoriesData() {
    const categories = this.props.items.categories;

    const filterCategoriesData = categories ? _.cloneDeep(categories) : [];
    filterCategoriesData?.unshift(
      {
        name: this.categoriesWithNoId.allItems
      },
      {
        name: this.categoriesWithNoId.favorite,
        icon: IconUtils.getIcon('Favorite', LabelUtil.getLabelColor())
      });

    return filterCategoriesData;
  }

  displayUploadDialog() {
    this.props.dispatch(setSubmitButtonEnabled(false));
    this.setState({open: true});
  }

  displayDeleteDialogList() {
    this.setState({openDeleteDialogType: 1});
  }

  displayDeleteDialogDetail() {
    this.setState({openDeleteDialogType: 2});
  }

  closeDeleteDialog() {
    if (this.state.openDeleteDialogType === 1) {
      this.cleanSelectedItems();
    }
    this.setState({openDeleteDialogType: 0});
  }

  handleClose() {
    if (this.state.importCompleted) {
      this.loadData();
    }

    this.setState({
      open: false,
      importCompleted: false,
      importInProgress: false,
      importTotal: 0,
      importCount: 0,
      importDetails: {
        fileStatus: '',
        fileName: ''
      }
    });
    this.props.dispatch(cleanImportLog());
    this.props.dispatch(setTokenAutoRenew(false));
  }

  handleImportComplete() {
    this.setState({
      selectedIndex: 0,
      open: false,
      importCompleted: false,
      importInProgress: false,
      importTotal: 0,
      importCount: 0,
      importDetails: {
        fileStatus: '',
        fileName: ''
      }
    });
    this.props.dispatch(cleanImportLog());
    this.props.dispatch(setTokenAutoRenew(false));
    this.loadData();
  }

  setCroppedImage(image_data, image) {
    this.setState({
      image_data: image_data,
      loadedImage: image
    });
  }

  onFileDrop(files) {
    const { dispatch, t } = this.props;
    dispatch(cleanImportLog());
    if (files[0]?.name) {
      this.setState({
        importDetails: {
          fileStatus: t('ImportedFileUploaded', { fileName: files[0].name }),
          fileName: files[0].name
        }
      });
    } else {
      dispatch(onDropFileFailed(true, t('OnDropFile.ImportFailed')));
    }
  }

  addToLog(line, type) {
    this.props.dispatch(addToImportLog(line, type));
  }

  newItem() {
    return {
      option_set_ids: [],
      name: '',
      is_taxable: false,
      is_favorite: false,
      is_trackable: true,
      allow_outof_stock: false,
      categories: [],
      details: {
        prices: [],
        modifierSets: null
      }
    }
  }

  save(values) {
    let that = this;
    if (!_.isNull(this.state.image_data)) {
      values.image_data = this.state.image_data;
    }
    values.is_one_time_use = false;
    this.setState({loading: true});
    return this.props.dispatch(saveItem(this.props.user, this.state.selectedIndex, values)).then((res) => {
      const nextState = {loading: false, openDetail: false, loadedImage: null, image_data: null, detailSubmitted: false };
      if (res.type !== actionTypes.itemSaveFailure) {
        nextState.selectedIndex = 0;
        that.loadData();
      }
      that.setState(nextState);
    });
  }

  onUploadClick() {
    this.props.dispatch(submit('importItemsForm'));
  }

  setImportProgressTotal(total) {
    this.setState({
      importTotal: total
    });

  }

  increaseImportProgressPercentage() {
    this.setState({
      importCount: (this.state.importCount + 1),
    });
  }

  cleanImportedData(data) {
    return _.filter(data, (line) => {
      return line[0]
    }); // Filter Out Empty CSV Lines
  }

  //Too hard to test FileReader
  /* istanbul ignore next */
  processUploadedCsvFile(values) {
    let that = this;
    that.props.dispatch(setTokenAutoRenew(true));
    let reader = new FileReader();
    that.setState({
      importInProgress: true,
      importDetails: {
        fileStatus: that.props.t('ImportInProgress', {fileName: that.state.importDetails.fileName}),
      }
    });

    reader.onload = function (e) {
      that.props.dispatch(cleanImportLog());
      let importedCsvContent = converter.parse(e.target.result);
      importedCsvContent.data = that.cleanImportedData(importedCsvContent.data);

      if (importedCsvContent.data.length > 3001) {
        that.props.dispatch(reset('importItemsForm'));
        that.props.dispatch(setSubmitButtonEnabled(false));
        that.setState({
          importInProgress: false,
          importDetails: {
            fileStatus: that.props.t('ImportSmallerFile', {fileName: values.items_library[0].name}),
          }
        });
      } else {
        CsvUtil.getItemsFromCsv(importedCsvContent, that.addToLog)
          .then(itemsValues => {
            itemsUtil.createCategories(itemsValues, that.props.items.categories, that).then(result => {
              itemsUtil.createItemsWithCategories(itemsValues, result, that).then(that.handleUploadCompletion);
            }).catch(reason => {
              that.setState({
                importInProgress: false,
                importDetails: {
                  fileStatus: reason
                }
              });
              that.addToLog('error: ' + reason);
            });
          }).catch(result => {
          that.setState({
            importInProgress: false,
            importDetails: {
              fileStatus: that.props.t('ImportInvalidItem', {item: result?.completeItem?.name ? result?.completeItem?.name : 'item'}),
            }
          });
          that.addToLog(result.reason, 'error');
        });

      }
    };
    reader.readAsText(values.items_library[0]);
  }

  handleUploadCompletion() {
    this.props.dispatch(setTokenAutoRenew(false));
    this.addToLog(this.props.t('ImportFinished'), 'info');
    this.setState({
      importCompleted: true,
      importDetails: {
        fileStatus: this.props.t('ImportFinished'),
      }
    });
  }

  delete() {
    let that = this;
    this.setState({openDeleteDialogType: 0, loading: true});
    this.props.dispatch(deleteItem(this.props.user, this.props.items.filteredItems[this.state.selectedIndex])).then(function () {
      that.setState({ selectedIndex: 0, loading: false, openDetail: false, detailSubmitted: false});
      that.loadData();
    });
  }

  selectItems(selectedId) {
    const { selectedItems } = this.state;
    const foundItem = selectedItems.some(id => id === selectedId);

    const newSelectedItems = foundItem ?
      selectedItems.filter(id => id !== selectedId) :
      [...selectedItems, selectedId];

    this.setState({
      selectedItems: newSelectedItems,
      allSelected: newSelectedItems.length === this.props.items.filteredItems.length
    });
  }

  selectAllItems() {
    if (this.state.allSelected) {
      this.setState({allSelected: false});
      this.cleanSelectedItems();
    } else {
      const selectedItems = this.props.items.filteredItems.map((item) => item.id);
      this.setState({ allSelected: true, selectedItems });
    }
  }

  checkRowSelected(id) {
    return this.state.selectedItems.includes(id);
  }

  cleanSelectedItems() {
    this.setState({selectedItems: [], allSelected: false});
  }

  deleteMultipleItems() {
    const that = this;
    this.setState({openDeleteDialogType: 0, loading: true});
    this.props.dispatch(deleteBatchItems(this.props.user, this.state.selectedItems)).then(() => {
      that.setState({loading: false});
      that.cleanSelectedItems();
      that.loadData();
    });
  }

  onDeleteConfirmation() {
    if (this.state.openDeleteDialogType === 1) {
      this.deleteMultipleItems();
    } else if (this.state.openDeleteDialogType === 2) {
      this.delete();
    }
  }

  onSaveClick() {
    this.setState({ detailSubmitted: true });
    this.props.dispatch(submit('itemDetailForm'));
  }

  exportItems() {

    const itemsJson = CsvUtil.constructExportItem(this.props.items.filteredItems, this.props.user);
    const itemsHeaders = CsvUtil.constructItemExportHeaders(itemsJson);
    const labelName = LabelUtil.getLabel().text;

    CsvExporter.download(itemsJson, labelName + '_Items_' + moment().format('MMDDYYYY') + '.csv', itemsHeaders)
  }

  addToCategory(categoryId) {
    const that = this;

    this.setState({ loading: true });

    return this.props.dispatch(attachCategoryToBatchItems(this.props.user, parseInt(categoryId), this.state.selectedItems)).then(() => {
      that.setState({ loading: false });
      that.cleanSelectedItems();
      that.loadData();
    });
  }

  getArchiveItemsCount() {
    const { selectedItems } = this.state;
    return selectedItems.length > 1 ? selectedItems.length : 1;
  }

  render() {

    const { items, user, auth, userExperience, t, isItemDetailFormInvalid  } = this.props;

    const isFetching = user.isFetching || items.isFetching;

    const isEmpty = items.salesItems === null;

    const pageLoading = isEmpty && isFetching;

    const isCsUser = UserUtil.isUserCS(user, auth);

    const isReadOnly = !UserUtil.isActive(user);

    const labelColor = LabelUtil.getLabelColor();
    const amountOfItemsInView = items && items.filteredItems && items.filteredItems.length ? items.filteredItems.length : 0;
    const totalAmountOfItems = items?.salesItemsPagination?.rowCount || 0;

    const isNewItem = this.state.selectedIndex === 'new';

    const isMobile = MobileUtil.isMobileDevice();

    const salesItems =  (items.filteredItems || items.salesItems);
    const selectedItem = !isNewItem && !!salesItems && salesItems[this.state.selectedIndex] || this.newItem();

    const submitButtonDisabled = !userExperience.enableFormSubmitButton;
    const showSubmitErrorMessage = isItemDetailFormInvalid && this.state.detailSubmitted;

    const itemsDetailPanel = (
      <DetailPanel
        isNew
        shouldDisplay
        open={this.state.openDetail}
        confirmationText={t('Save')}
        disabled={isCsUser}
        heading={isNewItem ? t('ItemsDetail.NewItem') : selectedItem.name}
        onClick={this.onSaveClick}
        onCloseClick={this.onCloseDetail}
        isSubmitButtonVisible={!isReadOnly}
        showSubmitErrorMessage={showSubmitErrorMessage}
      >
        <ItemsDetailForm
          selectedItem={selectedItem}
          onSubmit={this.save}
          loadedImage={this.state.loadedImage}
          onDelete={this.displayDeleteDialogDetail}
          setCroppedImage={this.setCroppedImage}
          isNewItem={isNewItem} {...this.props}
          submitButtonDisabled={submitButtonDisabled}
          t={t}
        />
      </DetailPanel>
    );

      const archiveItemsCount = this.getArchiveItemsCount();
      const deleteDialogText = this.state.selectedItems.length > 1 ?
        t('ItemsDetail.DeleteDialogText_plural', { selectedItems : archiveItemsCount }) :
        t('ItemsDetail.DeleteDialogText_one', { selectedItems : archiveItemsCount });

    const deleteConfirmationDialog = (
      <MessageDialog
        cancelText={t('No')}
        confirmText={t('Yes')}
        externalClassName='deleteConfirmationDialog'
        isChoiceRequired={false}
        onConfirm={this.onDeleteConfirmation}
        onRequestClose={this.closeDeleteDialog}
        open={!!this.state.openDeleteDialogType}
        scrollable={true}
        titleText={t('ItemsDetail.DeleteDialogTitle')}
        bodyText={
          <p className='confirmationText'>{deleteDialogText}</p>
        }/>
    );

    const uploadCsvModal = (
      <Modal
        title={t('Import')}
        open={this.state.open}
        maxWidth='md'
        confirmText={t('Import')}
        cancelText={t(!this.state.importCompleted ? 'Cancel' : 'Done')}
        onClose={this.handleClose}
        onConfirm={this.onUploadClick}
        isConfirmDisabled={submitButtonDisabled}
        isCancelDisabled={this.state.importInProgress && !this.state.importCompleted}
        hideConfirmButton={this.state.importInProgress}>
        <ImportItemsForm
          onSubmit={this.processUploadedCsvFile}
          onFileDrop={this.onFileDrop}
          log={items.log} {...this.state}
          t={t}
        />
      </Modal>
    )

    const importIcon = (
      <div className='exportIconWrapper'>
        {IconUtils.getIcon('CustomImportIcon', labelColor)}
      </div>
    );
    const csvExportIcon = IconUtils.getIcon('CsvExport', labelColor);
    const selectedItemsLength = this.state.selectedItems.length;
    const filteredCategoriesData = this.filterByCategoriesData();

    const searchBar = (
      <SearchBarAccessory avoidCleaning searchPlaceholder={t('ItemsDetail.SearchItems')} {...this.props} />
    );

    const isBottomBarOpen = selectedItemsLength && !isMobile;

    const createNewButton = <CreateButtonAccessory
      onClick={this.handleCreateClick}
      disabled={isReadOnly}
      isMobileOnly
    />

    const filterPanel = (
      <FilterPanel
        buttonOnClick={this.handleCreateClick}
        buttonText={'Create'}
        buttonWithCreateIcon
        {...this.props}
        filterData={UserUtil.getInventoryOptions('Items', totalAmountOfItems, auth, user, this.props.t)}
        selectFilterCallback={this.handleFilterSelection}
        disabled={isReadOnly}
        buttonBar={isBottomBarOpen}
      />
    );

    const pageAccessories = [
      {
        name: 'createNew',
        accessory: createNewButton,
        showInMobile: true,
      },
      {
        name: 'search',
        accessory: searchBar,
        showInMobile: true,
        hide: false
      },
      {
        name: 'filterDropdown',
        accessory: (
         <ItemsFilterAccessory
           onChange={(event) => this.handleTopBarFilterSelection(event.target.value)}
           value={this.state.selectedFilter}
           data={filteredCategoriesData}
         />
        ),
        showInMobile: true,
        hide: false
      },
      {
        name: 'importButton',
        accessory: (
          <ExportImportAccessory
            handleSelection={this.displayUploadDialog}
            exportTitle={t('ImportInventory')}
            disabled={isCsUser || isReadOnly}
            isImportAccessory
          />
        ),
        showInMobile: true,
        hide: false
      },
      {
        name: 'exportButton',
        accessory: (
          <ExportImportAccessory
            handleSelection={this.exportItems}
            exportTitle='Export Inventory'
            disabled={isCsUser}
          />
        ),
        showInMobile: true,
        hide: false
      },
    ];

    const createCategoryOption = ({ id, name, color }) => ({
      name,
      value: id.toString(),
      icon: IconUtils.getIcon('DotIcon', `#${color ? color : 'd3d3d3'}`)
    });

    const buttonIconColor = isReadOnly ? 'rgba(0, 0, 0, 0.38)' : LabelUtil.getLabelColor();

    const bottomBar = isBottomBarOpen ? (
      <BottomBar
        className='inventoryBottomBar'
        leftContent={`${selectedItemsLength} item${selectedItemsLength > 1 ? 's' : ''} selected`}
        rightContent={
          <MenuButton
            name={t('ItemsDetail.AddToCategory')}
            icon={IconUtils.getIcon('PlusIcon', buttonIconColor)}
            options={this.props.items.categories.map(category => createCategoryOption(category))}
            onChange={this.addToCategory}
            disabled={isCsUser || isReadOnly}
          />
        }
        rightActions={[
          {
            key: 'archiveItem',
            label: t('Archive'),
            disabled: isCsUser || isReadOnly,
            icon: IconUtils.getIcon('DeleteIcon'),
            onClick: this.displayDeleteDialogList
          },
          {
            key: 'closeBottomBar',
            label: t('Cancel'),
            disabled: isCsUser,
            onClick: this.cleanSelectedItems
          }
        ]}
      />
    ) : null;


    const isLargeInventory = totalAmountOfItems > items.salesItemsPagination?.pageSize;
    const paginationComponent = isLargeInventory ? (
      <Pagination
        rowCount={totalAmountOfItems}
        onChangePage={this.onChangePage}
        pageSize={items.salesItemsPagination.pageSize}
        page={this.state.pageNumber}
      />
    ) : null;

    const onlyOneRow = amountOfItemsInView === 1;
    const tableData = onlyOneRow ?
      [...items.filteredItems, {}] : items.filteredItems;

    return (

      <section className='inventoryItems'>
        <MasterDetailLayout
          {...this.props}
          pageTitle={t('Inventory')}
          pageInitialSubpage={t('Items')}
          pageForceInitialSubpage={true}
          pageLoading={pageLoading}
          pageAccessories={pageAccessories}
          filterPanel={filterPanel}
          tableData={!this.state.needToSearch ? tableData : []}
          forceOpenDetail={isNewItem}
          containerClass={isBottomBarOpen ? 'bottomBarActivated' : ''}
          paginationComponent={paginationComponent}
          dynamicLoading='items'
          onRowClick={this.onCellClick}
          rowSelectionProps={{
            allSelected: this.state.allSelected,
            checkRowSelected: this.checkRowSelected,
            selectRow: this.selectItems,
            toggleSelectAllRows: this.selectAllItems
          }}
          onlyOneRow={onlyOneRow}
          noDataComponent={this.state.needToSearch && (<NoData text={t('Searching')}/>)}
        />
        {itemsDetailPanel}
        {uploadCsvModal}
        {this.state.loading && <UpdateSpinner/>}
        {deleteConfirmationDialog}
        {this.renderCreateMenu()}
        {bottomBar}
      </section>

    )
  }
}
