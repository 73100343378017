/**
  North American Bancard ("NAB") CONFIDENTIAL MATERIAL
 
  Copyright 2000 NAB, All Rights Reserved.
 
  NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
  contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
  protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
  strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
  is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
  and Non-disclosure agreements explicitly covering such access.
 
  The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
  which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
  ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
  CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
  INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
  IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
  MAY DESCRIBE, IN WHOLE OR IN PART.
 
  */

import React from 'react';

const HowItWorks = ({ style }) => (

  <svg id='question' xmlns='http://www.w3.org/2000/svg' xmlns='http://www.w3.org/1999/xlink' style={style}>
    <defs>
      <clipPath id='clip-path'>
        <rect id='Rectangle_519' data-name='Rectangle 519' width='32' height='32' transform='translate(0)' strokeWidth='1' />
      </clipPath>
    </defs>
    <g id='Mask_Group_254' data-name='Mask Group 254' transform='translate(0)' clipPath='url(#clip-path)'>
      <path id='help' d='M10.4,1.4a9,9,0,1,0,9,9A9,9,0,0,0,10.4,1.4Zm-.24,14.4a1.314,1.314,0,1,1,.935-.385A1.314,1.314,0,0,1,10.16,15.8Zm1.152-4.38a1.128,1.128,0,0,1-2.256,0,3.336,3.336,0,0,1,1.56-2.712c1.8-1.452-.63-1.8-1.674-1.164a1.086,1.086,0,0,1-1.7-.894,1.1,1.1,0,0,1,.6-.966,4.332,4.332,0,0,1,2.4-.666c1.974,0,3.3,1.152,3.3,2.868.012,2.172-2.232,2.472-2.232,3.522Z' transform='translate(-1.4 -1.4)'/>
    </g>
  </svg>

);

export default HowItWorks;