/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import Radio from '../shared/Radio';
import { InputAdornment, MenuItem, Box, Typography } from '@mui/material';
import { connect } from 'react-redux';
import Validator from '../util/Validator';
import TextUtil from '../util/FormatTextUtil';
import IconUtils from '../util/IconUtil';
import {
  change,
  Field,
  formValueSelector, getFormValues,
  isPristine,
  isValid,
  reduxForm,
  touch
} from 'redux-form';
import UserUtil from './../util/UserUtil';
import moment from 'moment';
import _ from 'lodash';
import messages from '../../constants/messages';
import CustomTextField from '../shared/TextField';
import Select from '../shared/Select';
import CustomToggle from '../shared/Toggle';
import FormModal from '../shared/FormModal';
import CustomDatePicker from '../shared/DatePicker';
import LabelUtil from '../util/LabelUtil';
import { Link } from 'react-router-dom';
import routes from '../../constants/routes';
import { Trans } from 'react-i18next';
import DateUtils from '../util/DateUtil';
import Tooltip from '../shared/Tooltip';
import Button from '../shared/Button';
import { Grid } from '@mui/material';
import {settingsFormStyles} from '../../jss/settingsFormStyles';
import {achSettingContainer, descriptionText, secondaryTitle} from '../../jss/settingsFormStyles';
import CheckboxGroup from '../shared/CheckboxGroup';

export const validate = (values, props) => {
  const isPA = UserUtil.userType(props.user) === 'PA';
  const achEnabled = props.merchantSettings.merchantSettings.ach_enabled;
  if (!_.isEmpty(values)) {
    return Validator.validateSettingsForm(values, isPA, achEnabled);
  } else {
    return {};
  }
};

export class SettingsFormComponent extends Component {

  constructor(props) {
    super(props);

    this.closeDialog = this.closeDialog.bind(this);
    this.clearTaxRate = this.clearTaxRate.bind(this);
    this.goToIntegrations = this.goToIntegrations.bind(this);
  }

  componentDidMount() {

    const {dispatch} = this.props;

    dispatch(touch('settingsForm', 'tip_one_type'));
    dispatch(touch('settingsForm', 'tip_two_type'));
    dispatch(touch('settingsForm', 'tip_three_type'));
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const {
      dispatch,
      system_invoice_enabled,
      custom_invoice_numbering_enabled,
      invoice_number_required
    } = this.props;

    if (system_invoice_enabled !== undefined && system_invoice_enabled !== nextProps.system_invoice_enabled && nextProps.system_invoice_enabled) {
      dispatch(change('settingsForm', 'custom_invoice_numbering_enabled', false));
      dispatch(change('settingsForm', 'invoice_number_required', false));
    }

    if (custom_invoice_numbering_enabled !== undefined && custom_invoice_numbering_enabled !== nextProps.custom_invoice_numbering_enabled) {
      if (nextProps.custom_invoice_numbering_enabled) {
        dispatch(change('settingsForm', 'system_invoice_enabled', false));
        dispatch(change('settingsForm', 'invoice_number_required', false));
      } else {
        this.props.onHideResetInvoiceNumbering();
        dispatch(change('settingsForm', 'custom_invoice_starting_number', 1));
      }
    }

    if (invoice_number_required !== undefined && invoice_number_required !== nextProps.invoice_number_required && nextProps.invoice_number_required) {
      dispatch(change('settingsForm', 'system_invoice_enabled', false));
      dispatch(change('settingsForm', 'custom_invoice_numbering_enabled', false));
    }
  }

  closeDialog() {
    this.props.dispatch(change('settingsForm', 'is_preferred_edge', !this.props.isEdge));
    this.props.handleEdgeDialogClose();
  }


  clearTaxRate() {
    if (this.props.formReducer && !this.props.formReducer?.settingsForm?.values?.is_geotax_enabled) {
      this.props.dispatch(change('settingsForm', 'tax_rate', '0.000%'));
    }
  }

  handleManualCaptureTimeFormat = (time) => {
    return DateUtils.getEastToLocalTime(time, true)
  }


  normalizePercentage = (percentageValue) => TextUtil.formatPercentageWithoutSymbol(percentageValue);

  goToIntegrations() {
    const appRoutePrefix = globalApplicationLabel.path;
    const integrationsRoute = appRoutePrefix + routes.account.root + routes.account.integrations + '?davo=true';
    this.props.history.push(integrationsRoute);
  }

  render() {

    const {
      handleSubmit,
      items,
      nonPA,
      isHbt,
      isSalido,
      bottomButton,
      handleEdgeDialogSubmit,
      edgeDialog,
      edgeUpgrade,
      handleEdgeUpgrade,
      merchantSettings,
      custom_invoice_numbering_enabled,
      user,
      auth,
      userExperience,
      formReducer,
      isTerminal,
      gcType,
      t,
      autoCaptureTime,
      cash_discount_enabled,
      accountProfile
    } = this.props;

    const isRetailAndPA = !nonPA && isTerminal !== '1';

    const isSpr = auth.isSpr;

    const edgeDialogTextOptIn = (
      <div>
        <p>{t(messages.appSettings.edgeDialogFirst)}</p>
        <p>{t(messages.appSettings.edgeDialogSecond)}</p>
        <p style={{fontSize: '11px'}}>{t(messages.appSettings.edgeTerms)}</p>
      </div>
    );

    const edgeDialogTextOptOut = (
      <div>
        <p>{t(messages.appSettings.edgeDialogFirstOptOut)}</p>
        <p style={{fontSize: '11px'}}>{t(messages.appSettings.edgeTermsOptOut)}</p>
      </div>
    );

    const isCashDiscount = cash_discount_enabled === true;
    const alreadyEdge = merchantSettings?.edgeStatus?.is_preferred_edge;
    const edgePending = merchantSettings?.edgeStatus?.has_pending_pricing_change;
    const canActivateEdge = merchantSettings?.edgeStatus?.can_switch_to_preferred_edge;
    const canDeactivateEdge = merchantSettings?.edgeStatus?.can_switch_pricing_back;
    const edgeTermsAccepted = userExperience.uxStorage.edgeTermsAccepted;
    const isDavoEnrolled = accountProfile?.davo?.is_integrated;
    const isDavoEnabled = merchantSettings.merchantSettings?.davo_enabled;
    const isAchEnabled = merchantSettings.merchantSettings?.ach_enabled;

    const edgeDialogText = (alreadyEdge || edgePending) ? edgeDialogTextOptOut : edgeDialogTextOptIn;

    const label = LabelUtil.getLabel();
    const infoIcon = IconUtils.getIcon('InfoIcon', label.primaryColor);
    const warningIcon = IconUtils.getIcon('Warning', 'red');
    const externalLinkIcon = IconUtils.getIcon('OpenInNewIcon', label.primaryColor);
    const checkIcon = IconUtils.getIcon('CheckIcon', label.white, 'medium');

    const wantsEdge = (!!(!alreadyEdge && this.props.isEdge && !edgeTermsAccepted));
    const wantsEdgeUpgrade = (!!(!edgeTermsAccepted && edgeUpgrade));
    const wantsEdgeOptout = (!!(!edgeTermsAccepted && alreadyEdge && !this.props.isEdge));
    const autoDetectTax = formReducer?.settingsForm?.values?.is_geotax_enabled;

    const isOwner = auth.role === 'owner';
    const isAuto = !this.props.manualCapture;
    const isManual = this.props.manualCapture;
    const selectedMid = UserUtil.getActiveAccount(user)?.mid;
    const hasMultipleMids = isSpr ? UserUtil.hasRelatedMids(user) : UserUtil.hasMultipleMids(user);

    const showInvoiceNumbering = isOwner;
    const showInvoiceNumberingBatchRestart = showInvoiceNumbering && isManual;
    const showInvoiceNumberingApplyAccounts = [showInvoiceNumbering, isAuto, hasMultipleMids, !showInvoiceNumberingBatchRestart].every((flag) => Boolean(flag) === true);
    const giftCardPaymentEnabled = (!!gcType && !(merchantSettings?.merchantSettings?.gc_type?.indexOf('PENDING') >= 0));
    const setClosingTimer = this.props.formReducer?.settingsForm?.values?.set_closing_timer;
    const manualCaptureTimeDisabled = auth.isManager || isSpr;

    const hasEdgeProgramOrCashDiscount =  isCashDiscount || alreadyEdge || edgePending;

    const showDavoSettings = !auth.isManager && !nonPA;

    const taxSettings = (
      <>
        <div className='appCustomToggle'>
          <Field
            name='is_geotax_enabled'
            component={CustomToggle}
            onClick={this.clearTaxRate}
          />
          <div className='twoLines'>
            <p className='label'>{t('AutoDetectTax')}</p>
          </div>
        </div>

        {!autoDetectTax && <Field
          component={CustomTextField}
          name='tax_rate'
          label={t('TaxRate')}
          maxLength='7'
          hintText='00.000'
          className={'textField customTaxRate fullScreen'}
          normalize={this.normalizePercentage}
          disabled={autoDetectTax}
          InputProps={{ endAdornment: <InputAdornment position='end'>%</InputAdornment> }}
        />}

        {isRetailAndPA &&
          <div className='appCustomToggle'>
            <Field
              name='express_item_taxable'
              component={CustomToggle}
            />
            <div className='twoLines'>
              <p className='label'>{t('ExpressItemTaxableLabel')}</p>
            </div>
          </div>}
        { !showDavoSettings && <hr className='appSpacer' /> }
        {/*Pin Enable - timeout goes here PA-11897*/}
      </>
    );

    const ACH_NOTIFICATION_CHANNEL_PREFERENCES = [
      { label: t('AchAuthorization.Mail'), value: 'mail' },
      { label: t('AchAuthorization.Email'), value: 'email' },
      { label: t('AchAuthorization.Telephone'), value: 'telephone' }
    ];

    const ACH_NOTIFICATION_CHANNEL_LENGTH = [
      {label: t('AchAuthorization.NotificationLength5'), value: 5},
      {label: t('AchAuthorization.NotificationLength10'), value: 10},
      {label: t('AchAuthorization.NotificationLength30'), value: 30}
    ];

    return (
      <Box className='businessSettingsSubsection settingsFormContainer appSettings' sx={settingsFormStyles}>
        <div className='title'>
          <span className='heading'>{t(messages.appSettings.formTitle)}</span>
          <span className='subText'>{t(messages.appSettings.formSubtitle)}</span>
        </div>

        <form onSubmit={handleSubmit} className='appSettings'>
          {!nonPA &&
            <div id='closingSettingsWrapper'>
              <div className='secondaryTitle'>
                <span className='subText'>{t('ClosingSettingSecondaryTitle')}</span>
              </div>
              <div className='closingSettingsWrapper'>
                <p className='label'>{this.props.manualCapture ? t('ManualClose') : t('AutoClose')}</p>
                <p className='subText'>{this.props.manualCapture ? t(messages.appSettings.closing.manual) :t(messages.appSettings.closing.auto)}</p>
                {
                  (isOwner || auth.isAdmin || auth.isManager) ?
                    <div className='appCustomToggle'>
                      <Field
                        name='is_auto_pax_print_closing_reports'
                        component={CustomToggle}
                        labelPosition={'end'}
                        customClass={'printReportToggle'}
                        label={t('IsOwnerLabel')}
                        disabled={auth.isManager}
                      />
                      <div id='autoPrintDescription'>
                        <p>{this.props.manualCapture ? t(messages.appSettings.closing.manualPrintReport) : t(messages.appSettings.closing.autoPrintReport)}</p>
                      </div>
                    </div>
                  : null
                }
                {
                  isManual &&
                    <div className='appCustomToggle'>
                      <div id='setClosingTimer'>
                        <Field
                          name='set_closing_timer'
                          className={'setClosingTimer'}
                          customClass={'printReportToggle'}
                          component={CustomToggle}
                          labelPosition='end'
                          label={t('BusinessForm.SetClosingTimer')}
                          disabled={manualCaptureTimeDisabled}
                        />
                        <p>{t(messages.appSettings.closing.manualCaptureTime)}</p>
                      </div>
                    </div>
                }
                {
                  isManual && setClosingTimer &&
                  <Field
                    name='manual_capture_time'
                    className={'manualCaptureTime'}
                    component={CustomDatePicker}
                    dateFormat='hh:mm a'
                    format={this.handleManualCaptureTimeFormat}
                    label={t('BusinessForm.SelectTime')}
                    disabled={manualCaptureTimeDisabled}
                    styles={{minHeight: '0px'}}
                    useTimePicker
                    input={{
                      value: moment(formReducer?.settingsForm?.values?.manual_capture_time, 'hh:mm'),
                      onChange: (date) => this.props.dispatch(change('settingsForm', 'manual_capture_time', moment(date,'hh:mm').format('hh:mm'))),
                    }}
                    t={t}
                  />
                }
                {
                  this.props?.autoClosePrintTime && (
                    <div className='autoClosePrintTime'>
                      <p className='label'>{t('AutoClosePrintTime')}</p>
                      <p>{this.props.autoClosePrintTime}</p>
                    </div>
                  )
                }
              </div>
            </div>}

          <div className='appSettingsWrapper'>

            {!nonPA &&
              <div style={{ maxHeight: 300 }}>
                <Field
                  label={t('Theme')}
                  component={Select}
                  className='fullScreen alignBottom fullScreenRes'
                  name='theme'
                  ref='theme'>
                  <MenuItem value='0'>{t('Retail')}</MenuItem>
                  <MenuItem value='1'>{t('Terminal')}</MenuItem>
                </Field>
              </div>}

            {isRetailAndPA && <div style={{maxHeight: 300}}>
              <Field
                label={t('DefaultSaleViewLabel')}
                component={Select}
                className='fullScreen alignBottom fullScreenRes'
                name='express_view_enabled'
                ref='express_view_enabled'>
                <MenuItem value={false}>{t('ItemLibrary')}</MenuItem>
                <MenuItem value={true}>{t('ExpressSale')}</MenuItem>
              </Field>
            </div>}

            {isRetailAndPA &&
              <div className='appCustomToggle'>
                <Field
                  name='express_category_enabled'
                  component={CustomToggle}
                />
                <div className='twoLines'>
                  <p
                    className='label'>{t('ExpressCategoryEnabledLabel')}</p>
                  <p className='subText'>
                    {t('ExpressCategoryEnabledSubtext')}
                  </p>
                </div>
              </div>
            }

            {showInvoiceNumbering && (
              <div>
                <hr className='appSpacer'/>
                <div className='title'>
                  <span className='secondaryTitle'>{t('ShowInvoiceNumberingTitle')}</span>
                </div>
                <div className='appCustomToggle'>
                  <Field
                    buttons={[{label: '', value: true}]}
                    component={Radio}
                    disabled={isSpr}
                    isBoolean
                    name='system_invoice_enabled'
                  />

                  <div className='twoLines'>
                    <p className='label'>{t('ShowInvoiceNumberingOpt1Label')}</p>
                    <p className='subText'>
                      {t('ShowInvoiceNumberingOpt1Subtext')}
                    </p>
                  </div>
                </div>

                <div className='appCustomToggle'>
                  <Field
                    buttons={[{label: '', value: true}]}
                    component={Radio}
                    disabled={isSpr}
                    isBoolean
                    name='custom_invoice_numbering_enabled'
                  />

                  <div className='twoLines'>
                    <p className='label'>{t('ShowInvoiceNumberingOpt2Label')}</p>
                    <p className='subText'>
                      {t('ShowInvoiceNumberingOpt2Subtext')}
                    </p>

                    {custom_invoice_numbering_enabled && (
                      <div className='automaticInvoiceNumberingSubFields'>
                        {this.props.showResetInvoiceNumbering ? (
                          <div className='invoiceNumberingStartField'>
                            <span className='showField' style={{ color: LabelUtil.getLabelColor() }} onClick={this.props.onShowResetInvoiceNumbering}>{t('ResetInvoiceNumbering')}</span>
                          </div>) : (
                          (
                            <div className='invoiceNumberingStartField'>
                              <Field
                                disabled={isSpr}
                                name='custom_invoice_starting_number'
                                component={CustomTextField}
                                hintText='1'
                                label={t('InvoiceNumberingStartField')}
                                className={'smallerLabel'}
                                normalize={TextUtil.formatWholeNumber}
                              />
                              <div className='twoLines'>
                                <p className='subText subField'>
                                  {t('InvoiceNumberingStartFieldSubtext')}
                                </p>
                              </div>
                            </div>
                          )
                        )}
                        { showInvoiceNumberingBatchRestart && (
                          <div className='invoiceNumberingResetToggle'>
                            <Field
                              name='custom_invoice_restart_batches'
                              component={CustomToggle}
                              disabled={isSpr}
                            />
                            <div className='twoLines'>
                              <p className='label'>{t('InvoiceNumberingResetToggleLabel')}</p>
                              <p className='subText'>
                                {t('InvoiceNumberingResetToggleSubtext')}
                              </p>
                            </div>
                          </div>
                        )}
                        { showInvoiceNumberingApplyAccounts && (
                          <div className='invoiceNumberingApplyAccounts'>
                            <Field
                              name='custom_invoice_apply_to_portfolio'
                              component={CustomToggle}
                              disabled={isSpr}
                            />
                            <div className='twoLines'>
                              <p className='label'>{t('InvoiceNumberingApplyAccountsLabel')}</p>
                              <p className='subText'>
                                <Trans i18nKey={'InvoiceNumberingApplyAccountsSubtext'}>
                                  Use your defined invoice number sequence across all accounts (MIDs) you own. When disabled, the sequence applies only to this account ({{selectedMid}})
                                </Trans>
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                  </div>
                </div>

                  <div className='appCustomToggle'>
                    <Field
                      buttons={[{label: '', value: true}]}
                      component={Radio}
                      name='invoice_number_required'
                      disabled={isSpr}
                      isBoolean
                    />
                    <div className='twoLines'>
                      <p className='label'>{t('ShowInvoiceNumberingOpt3Label')}</p>
                      <p className='subText'>
                        {t('ShowInvoiceNumberingOpt3Subtext')}
                      </p>
                    </div>
                  </div>

              </div>
            )}

            {!nonPA && <hr className='appSpacer' />}

            {showDavoSettings ? (
                <>
                  <div className='title'>
                    <span className='secondaryTitle'>{t('ShowTaxSettingsTitle')}</span>
                  </div>
                  {isDavoEnabled && (!isDavoEnrolled ? (
                    <div className='davoSettingsWrapper'>
                      <img src='/images/davo/DAVO-Logo.svg' alt='DAVO logo' className='davoLogo' />
                      <div className='twoLines'>
                        <p className='label'>{t('EnableDavoTitle')}<sup>{'*'}</sup></p>
                        <p className='subText'>
                          {t('EnableDavoSubText')}
                        </p>
                      </div>
                      <div className='ctaButton'>
                        <Button
                          className='davoGetFreeMonthButton'
                          label={t('DavoGetFreeMonthButton')}
                          onClick={this.goToIntegrations}
                        />
                        <p className='disclaimer'>*Only available with a paid plan.</p>
                      </div>
                    </div>
                  ) : (
                    <div className='davoSettingsWrapper davoEnrolled'>
                      <Grid container spacing={1}>
                        <Grid item xs={6} display='flex' alignItems='center'>
                          <img src='/images/davo/logo.png' alt='DAVO logo' className='davoLogo' />
                        </Grid>
                        <Grid item xs={3} display='flex' alignItems='center'>
                          <a href='https://portal.davosalestax.com/' target='_blank' className='davoPortalLink'>{t('DavoPortalLink')} {externalLinkIcon}</a>
                        </Grid>
                        <Grid item xs={3} display='flex' alignItems='center'>
                          <div className='davoConnectedLabel'>
                            {checkIcon} {t('DavoConnectLabel')}
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                  <div className='taxSettingsWrapper'>
                    {taxSettings}
                  </div>
                  <hr className='appSpacer'/>
                </>
              ) : (
                <>
                  <hr className='appSpacer'/>
                  {taxSettings}
                </>
            )}

            {!nonPA && <div>
              <div className='appCustomToggle'>
                <Field
                  name='store_forward_enabled'
                  component={CustomToggle}
                />
                <div className='twoLines'>
                  <p className='label'>{t('OfflineTransactionsLabel')}</p>
                  <p className='subText'>
                    {t('OfflineTransactionsSubtext')}
                  </p>
                  <div className='subText'>
                    <div>{`${t('Note')}: `}</div>
                    <div>&#8208;{` ${t('OfflineTransactionsNote1')}`}</div>
                    <div>&#8208;{` ${t('OfflineTransactionsNote2')}`}</div>
                    <div>&#8208;{` ${t('OfflineTransactionsNote3')}`}</div>
                  </div>
                </div>
              </div>
              <hr className='appSpacer' />

              <div className='appCustomToggle'>
                <Field
                  name='tickets_enabled'
                  component={CustomToggle}
                />
                <div className='twoLines'>
                  <p className='label'>{t('EnableTicketsLabel')}</p>
                  <p className='subText'>
                    {t('EnableTicketsSubtext')}
                  </p>
                </div>
              </div>

              {!!(this.props.ticketsEnabled && items.identifiers && items.identifiers.length) &&
                <div className='identifiers'>
                  <div className='twoLines'>
                    <p className='identifiersTitle'>{t('IdentifiersTitle')}</p>
                    <p
                      className='subText'>{t('IdentifiersSubtext')}</p>
                  </div>
                  {items.identifiers.map((identifier, index) =>
                    <div className='identifiersHolder' key={index}>
                      <p className='identifiersName'>{identifier.name}</p>
                      <p className='identifiersItemHolder'>
                        {identifier.identifiers.map((i, k) =>
                          <span className='identifiersItem'
                            key={k}>{`${i.name}, `}</span>)
                        }
                      </p>
                    </div>)
                  }
                </div>}

              <hr className='appSpacer' />

              <div className='appCustomToggle enablePinDebit'>
                <Field
                  name='allow_pin_debit'
                  disabled={true}
                  component={CustomToggle}
                />
                <div className='twoLines'>
                  <p className='label'>{t('AllowPinDebitLabel')}</p>
                  <p className='subText'>
                    {t('AllowPinDebitSubtext')}
                  </p>
                </div>
              </div>

              {merchantSettings?.merchantSettings?.allow_pin_debit ? (
                  <div className='appCustomToggle forcePinDebit'>
                    <Field
                      name='pin_debit_enabled'
                      disabled={!(isOwner || auth.isAdmin)}
                      component={CustomToggle}
                    />
                    <div className='twoLines'>
                      <p className='label'>{t('PinDebitEnabledTitle')}</p>
                      <p className='subText'>
                        {t('PinDebitEnabledSubtext')}
                      </p>
                    </div>
                  </div>
              ) : null}

              <hr className='appSpacer' />

              <div className='appCustomToggle ebt_enabled'>
                <Field
                  name='ebt_enabled'
                  component={CustomToggle}
                  disabled
                />
                <div className='twoLines'>
                  <p className='label'>{t('EBTEnabledLabel')}</p>
                  <p className='subText'>
                    {t('EBTEnabledSubtext')}
                  </p>
                </div>
              </div>

              <hr className='appSpacer' />
              <div className='appCustomToggle accept_cash_payments'>
                <Field
                  name='accept_cash_payments'
                  component={CustomToggle}
                />
                <div className='twoLines'>
                  <p className='label'>{t('Cash')}</p>
                  <p className='subText'>{t('CashFieldSubText')}</p>
                </div>
              </div>

              <hr className='appSpacer' />

              <div className='appCustomToggle attp_enabled'>
                <Field
                  name='attp_enabled'
                  component={CustomToggle}
                  disabled
                />
                <div className='twoLines'>
                  <p className='label'>{t('TapToPayLabel')}</p>
                  <p className='subText'>{t('TapToPaySubtext')}</p>
                </div>
              </div>

              <hr className='appSpacer' />

              <div className='appCustomToggle gift_card_enabled'>
                <Field
                  name='gift_card_enabled'
                  component={CustomToggle}
                  disabled
                />
                <div className='twoLines'>
                  <p className='label'>{t('GiftCardEnabledTitle')}</p>
                  <p className='subText'>
                    {t('GiftCardEnabledSubtext')}
                  </p>
                  {isRetailAndPA && giftCardPaymentEnabled &&
                    <div className='gift_card_enabledPresetContainer'>
                      <div className='gift_card_enabledPresetStock'>
                        <div className='gift_card_enabledPresetStockText'>
                          <p className='label'>{t('GiftCardStockTitle')}</p>
                          <p className='subText'>{t('GiftCardStockSubtext')}</p>
                        </div>
                        <Field
                          component={CustomTextField}
                          name='gc_quantity'
                          hintText='00'
                          className={'textField'}
                          format={TextUtil.formatWholeNumber}
                          normalize={TextUtil.formatWholeNumber}
                        />
                      </div>
                      <div className='gift_card_enabledPresetPresets'>
                        {Array(4).fill().map((field, i) => (
                          <Field
                            key={`gift_card_preset_${i + 1}`}
                            component={CustomTextField}
                            name={`gift_card_preset_${i + 1}`}
                            label={t('GiftCardPreset', { presetNumber: i + 1 })}
                            className={'textField'}
                            format={TextUtil.formatCurrencyWithMaxDigit}
                            normalize={TextUtil.formatCurrencyWithMaxDigit}
                            placeholder={'$0.00'}
                          />
                        ))}
                      </div>
                    </div>
                  }
                  {(!isRetailAndPA || !giftCardPaymentEnabled) &&
                    <Link to={`${globalApplicationLabel.path}${routes.account.root}${routes.account.integrations}`}
                          className='learnMoreLink'>
                      <span>{t('LearnMoreLinkIntegration')}</span>
                    </Link>
                  }
                </div>
              </div>
              {isRetailAndPA && giftCardPaymentEnabled &&
              <div className='appCustomToggle gift_card_enabled'>
                <Field
                  name='keep_gc_on_top'
                  component={CustomToggle}
                  disabled={!giftCardPaymentEnabled}
                />
                <div className='twoLines'>
                  <p className='label'>{t('GiftCardOnTopLabel')}</p>
                  <p className='subText'>
                    {t('GiftCardOnTopSubtext')}
                  </p>
                </div>
              </div>
              }
              {isRetailAndPA && giftCardPaymentEnabled &&
              <div className='appCustomToggle gift_card_enabled'>
                <Field
                  name='add_gc_to_favorites'
                  component={CustomToggle}
                  disabled={!giftCardPaymentEnabled}
                />
                <div className='twoLines'>
                  <p className='label'>{t('GiftCardFavoritesLabel')}</p>
                  <p className='subText'>
                    {t('GiftCardFavoritesSubtext')}
                  </p>
                </div>
              </div>
              }

              <hr className='appSpacer' />

            </div>}

            <div>

              <div className='appCustomToggle ignore_avs_failure'>
                <Field
                  name='ignore_avs_failure'
                  component={CustomToggle}
                />
                <div className='twoLines'>
                  <p className='label'>{t('IgnoreAvsFailureLabel')}</p>
                  <p className='subText'>
                    {t('IgnoreAvsFailureSubtext')}
                  </p>
                </div>
              </div>

            </div>

            {!nonPA && <div>

              <div className='appCustomToggle'>
                <Field
                  name='barcode_search_enabled'
                  component={CustomToggle}
                />
                <div className='twoLines'>
                  <p className='label'>{t('BarcodeSearchEnabledLabel')}</p>
                </div>
              </div>
              <hr className='appSpacer'/>

              <div className='appCustomToggle'>
                <Field
                  name='is_signature_required'
                  component={CustomToggle}
                />
                <div className='twoLines'>
                  <p className='label'>{t('IsSignatureRequiredLabel')}</p>
                  <p className='subText'>
                    {t('IsSignatureRequiredSubtext')}
                  </p>
                </div>
              </div>

              <div className='appCustomToggle'>
                <Field
                  name='paper_signature_enabled'
                  component={CustomToggle}
                />
                <div className='twoLines'>
                  <p className='label'>{t('PaperSignatureEnabledLabel')}</p>
                  <p className='subText'>
                    {t('PaperSignatureEnabledSubtext')}
                  </p>
                </div>
              </div>
              <hr className='appSpacer'/>

              <div className='appCustomToggle'>
                <Field
                  name='is_tip_enabled'
                  component={CustomToggle}
                />
                <div className='twoLines'>
                  <p className='label'>{t('IsTipEnabledLabel')}</p>
                </div>
              </div>
              {this.props.tipEnabled ?
                <div>
                  <div className='tipsWrapper'>
                    <div className='tipContainer'>
                      <p className='label'>{t('TipEnabledPresetTip', {presetNumber: 1})}</p>
                      <div className='textFields presetTips'>
                        <Field
                          component={CustomTextField}
                          name='tip_one_amount'
                          hintText={this.props.isPercentageOne ? '00%' : '$00.00'}
                          className={'textField customTaxRate halfScreen'}
                          normalize={TextUtil.formatDecimals}
                        />
                        <Field
                          component={Select}
                          className='fullScreen alignBottom halfScreen'
                          name='tip_one_type'
                          ref='tip_one_type'>
                          <MenuItem disabled value={null}>{t('Select')}</MenuItem>
                          <MenuItem value='0'>$</MenuItem>
                          <MenuItem value='1'>%</MenuItem>
                        </Field>
                      </div>
                    </div>
                    <div className='tipContainer'>
                      <p className='label'>{t('TipEnabledPresetTip', {presetNumber: 2})}</p>
                      <div className='textFields presetTips'>
                        <Field
                          component={CustomTextField}
                          name='tip_two_amount'
                          hintText={this.props.isPercentageTwo ? '00%' : '$00.00'}
                          className={'textField customTaxRate halfScreen'}
                          normalize={TextUtil.formatDecimals}
                        />
                        <Field
                          component={Select}
                          className='halfScreen alignBottom fullScreenRes'
                          name='tip_two_type'
                          ref='tip_two_type'>
                          <MenuItem disabled value={null}>{t('Select')}</MenuItem>
                          <MenuItem value='0'>$</MenuItem>
                          <MenuItem value='1'>%</MenuItem>
                        </Field>
                      </div>
                    </div>
                    <div className='tipContainer'>
                      <p className='label'>{t('TipEnabledPresetTip', {presetNumber: 3})}</p>
                      <div className='textFields presetTips'>
                        <Field
                          component={CustomTextField}
                          name='tip_three_amount'
                          hintText={this.props.isPercentageThree ? '00%' : '$00.00'}
                          className={'textField customTaxRate halfScreen'}
                          normalize={TextUtil.formatDecimals}
                        />
                        <Field
                          component={Select}
                          className='halfScreen alignBottom fullScreenRes'
                          name='tip_three_type'
                          ref='tip_three_type'>
                          <MenuItem disabled value={null}>{t('Select')}</MenuItem>
                          <MenuItem value='0'>$</MenuItem>
                          <MenuItem value='1'>%</MenuItem>
                        </Field>
                      </div>
                    </div>
                  </div>

                  {isRetailAndPA && <div className='appCustomToggle' id='combineTipsSignature'>
                    <Field
                      name='combine_tips_signature_enabled'
                      component={CustomToggle}
                    />
                    <div className='twoLines'>
                      <p
                        className='label'>{t('CombineTipsLabel')}</p>
                    </div>
                  </div>}
                  <hr className='appSpacer'/>
                </div> : <hr className='appSpacer'/>
              }

              {(!isSalido && !isHbt && !nonPA) ? <div className='edgeSetting'>
                {edgePending ?
                  <p className='edgeProgramPending'><span>{warningIcon}</span>{t('EdgeProgramPendingWarning')}
                  </p> : null}
                {!edgePending ? <div className='appCustomToggle'>
                  <Field
                    name='is_preferred_edge'
                    component={CustomToggle}
                    disabled={(alreadyEdge && !canDeactivateEdge && !canActivateEdge) ||
                      (alreadyEdge && !canDeactivateEdge) ||
                      (!alreadyEdge && !canActivateEdge) || (!alreadyEdge && canActivateEdge)}
                  />
                  <div className='twoLines'>
                    <p className='label'>{t('EdgeProgramLabel')}</p>
                    <p className='subText'>
                      {t(messages.appSettings.edgeProgramDescription)}
                    </p>
                  </div>
                  {!alreadyEdge && !canActivateEdge ? (
                    <Tooltip
                      placement='left'
                      title={t(messages.appSettings.cantDoEdge)}
                    >
                      <p>{infoIcon}</p>
                    </Tooltip>
                  ) : null}
                  {(alreadyEdge && !canDeactivateEdge) ? (
                    <Tooltip
                      placement='left'
                      title={t(messages.appSettings.cantDisableEdge)}
                    >
                      <p>{infoIcon}</p>
                    </Tooltip>
                  ) : null}
                </div> : null}
                <hr className='appSpacer'/>
              </div> : null}

              <div className='appCustomToggle cashDiscountToggle'>
                <Field
                  name='cash_discount_enabled'
                  component={CustomToggle}
                  disabled
                />
                <div className='twoLines'>
                  <p className='label'>{t('CashDiscountingLabel')}</p>
                  <p className='subText'>{t(messages.appSettings.cashDiscountDescription)}</p>
                </div>
              </div>

              <div className='appCustomToggle cashDiscountToggle'>
                <Field
                  name='cash_discount_per_transaction_enabled'
                  component={CustomToggle}
                  disabled={!this.props.cashDiscountEnabled}
                />
                <div className='twoLines'>
                  <p className='label'>{t('EnablePerTransactionLabel')}</p>
                  <p className='subText'>
                    {t('EnablePerTransactionSubtext')}
                  </p>
                </div>
              </div>
              <div className='flexRow'>
                <Field
                  component={CustomTextField}
                  name='cash_discount_fixed_amount'
                  label={t('CashFixedAmountLabel')}
                  subText={t('CashFixedAmountSubtext')}
                  hintText='$0.00'
                  className={'textField customTaxRate halfScreen'}
                  normalize={TextUtil.formatDecimals}
                  disabled
                />
                <Field
                  component={CustomTextField}
                  name='cash_discount_amount_percent'
                  label={t('CashDiscountAmountLabel')}
                  subText={t('CashDiscountAmountSubtext')}
                  hintText='0%'
                  className={'textField customTaxRate halfScreen'}
                  normalize={TextUtil.formatDecimals}
                  disabled
                />
              </div>
              {(!isSalido && !isHbt && !nonPA && canActivateEdge) && !alreadyEdge && !edgePending && !edgeUpgrade ?
                <div className='edgeNotice'>
                  <p className='title'>{t('EdgeNoticeTitle')}</p>
                  <p className='text'>{t(messages.appSettings.edgeProgramDescription)}</p>
                  <Button
                    label={t('EdgeNoticeReverseBtn')}
                    onClick={handleEdgeUpgrade}
                    variant='text'
                  />
                </div> : null}
              <p className='settingsDisclaimer'>
                {t('settingsDisclaimer')}
              </p>
              <div className='appCustomToggle displayCashPriceToggle'>
                <Field
                  component={CustomToggle}
                  disabled={!isOwner || !hasEdgeProgramOrCashDiscount}
                  name='cash_discount_display_cash_price'
                />
                <div className='twoLines'>
                  <p className='label'>{t('DisplayCashPriceLabel')}</p>
                  <p className='subText'>
                    {t('DisplayCashPriceSubText')}
                  </p>
                </div>
              </div>
              <hr className='appSpacer'/>
              {isAchEnabled && <Box
                component='div'
                data-test-id='settings-form-ach'
              >
                <Typography
                  sx={secondaryTitle}
                >
                  {t('AchAuthorization.Title')}
                </Typography>
                <Box
                  component='div'
                  sx={achSettingContainer}
                >
                  <Typography
                    component='p'
                    sx={descriptionText}
                  >
                    {t('AchAuthorization.ChannelDescription')}
                  </Typography>
                  <CheckboxGroup
                    name='ach_notification_revoke_type'
                    options={ACH_NOTIFICATION_CHANNEL_PREFERENCES}
                    allowMultiple={true}
                    horizontal={true}
                    t={t}
                  />
                </Box>
                <Box
                  component='div'
                  sx={achSettingContainer}
                >
                  <Typography
                    component='p'
                    sx={descriptionText}
                  >
                    {t('AchAuthorization.LengthDescription')}
                  </Typography>
                  <CheckboxGroup
                    name='ach_notification_revoke_days'
                    options={ACH_NOTIFICATION_CHANNEL_LENGTH}
                    horizontal={true}
                    allowMultiple={false}
                    t={t}
                  />
                </Box>
              </Box>}
              <hr className='appSpacer'/>
            </div>
            }
          </div>

          {bottomButton(this.props.submissionOk)}

        </form>
        <FormModal
          open={(wantsEdge || wantsEdgeUpgrade || wantsEdgeOptout) && edgeDialog}
          title={t('SubmitEdgeProgramTermsTitle')}
          content={edgeDialogText}
          buttonAction={handleEdgeDialogSubmit}
          closeAction={this.closeDialog}
          buttonLabel={t('Submit')}/>
      </Box>
    );
  }

}

let SettingsForm = reduxForm({
  form: 'settingsForm',
  validate,
  fields: [
    'allow_pin_debit',
    'pin_enabled',
    'is_geotax_enabled',
    'is_auto_pax_print_closing_reports',
    'express_item_taxable',
    'express_category_enabled',
    'barcode_search_enabled',
    'pin_debit_enabled',
    'is_tip_enabled',
    'tip_one_type',
    'tip_one_amount',
    'tip_two_type',
    'tip_two_amount',
    'tip_three_type',
    'tip_three_amount',
    'combine_tips_signature_enabled',
    'merchant_name',
    'manual_capture',
    'auto_capture_time',
    'should_bcc',
    'display_sold_by',
    'esn_enabled',
    'customer_rating_enabled',
    'social_media_enabled',
    'cash_discount_enabled',
    'cash_discount_per_transaction_enabled',
    'cash_discount_display_cash_price',
    'custom_search_enabled',
    'theme',
    'tax_rate',
    'system_invoice_enabled',
    'custom_invoice_numbering_enabled',
    'custom_invoice_starting_number',
    'custom_invoice_restart_batches',
    'custom_invoice_apply_to_portfolio',
    'invoice_number_required',
    'tickets_enabled',
    'gift_card_enabled',
    'gc_quantity',
    'gift_card_preset_1',
    'gift_card_preset_2',
    'gift_card_preset_3',
    'gift_card_preset_4',
    'keep_gc_on_top',
    'add_gc_to_favorites',
    'manual_capture_time',
    'set_closing_timer',
    'accept_cash_payments'
  ],
  enableReinitialize: true
})(SettingsFormComponent);

const selector = formValueSelector('settingsForm');

function parsePresetAmounts(input) {
  return input
      ?.match(/-?\d+(\.\d+)?|NaN/g)
      ?.map(value => {
        const num = parseFloat(value);
        return isNaN(num) ? '0.00' : num.toFixed(2);
      })
    ?? [];
}

export function mapStateToProps(state, ownProps) {

  const initialValues = state?.merchantSettings?.merchantSettings && state?.merchantSettings?.userSettings && state?.merchantSettings?.edgeStatus ? {...state?.merchantSettings?.merchantSettings, ...state?.merchantSettings?.userSettings, ...state?.merchantSettings?.edgeStatus} : {};

  initialValues.theme = String(initialValues.theme);

  initialValues.system_invoice_enabled = !initialValues.custom_invoice_numbering_enabled && !initialValues.invoice_number_required;
  initialValues.custom_invoice_numbering_enabled = initialValues.custom_invoice_numbering_enabled && !initialValues.invoice_number_required;
  initialValues.invoice_number_required = initialValues.invoice_number_required && !initialValues.custom_invoice_numbering_enabled;

  initialValues.custom_invoice_starting_number = initialValues.custom_invoice_starting_number;
  initialValues.custom_invoice_restart_batches = initialValues.custom_invoice_restart_batches;
  initialValues.custom_invoice_apply_to_portfolio = initialValues.custom_invoice_apply_to_portfolio;

  initialValues.tip_one_amount = initialValues.tip_default_values && initialValues.tip_default_values.length && initialValues.tip_default_values[0] && initialValues.tip_default_values[0].value;

  initialValues.tip_two_amount = initialValues.tip_default_values && initialValues.tip_default_values.length && initialValues.tip_default_values[1] && initialValues.tip_default_values[1].value;

  initialValues.tip_three_amount = initialValues.tip_default_values && initialValues.tip_default_values.length && initialValues.tip_default_values[2] && initialValues.tip_default_values[2].value;

  initialValues.tip_one_type = initialValues.tip_default_values && initialValues.tip_default_values.length && initialValues.tip_default_values[0] && initialValues.tip_default_values[0].type !== null ? initialValues.tip_default_values[0].type.toString() : '';

  initialValues.tip_two_type = initialValues.tip_default_values && initialValues.tip_default_values.length && initialValues.tip_default_values[1] && initialValues.tip_default_values[1].type !== null ? initialValues.tip_default_values[1].type.toString() : '';

  initialValues.tip_three_type = initialValues.tip_default_values && initialValues.tip_default_values.length && initialValues.tip_default_values[2] && initialValues.tip_default_values[2].type !== null ? initialValues.tip_default_values[2].type.toString() : '';

  initialValues.cash_discount_fixed_amount = (initialValues.cash_discount_fixed_amount && Number(initialValues.cash_discount_fixed_amount) !== 0) ? TextUtil.formatDecimals(Number(initialValues.cash_discount_fixed_amount).toFixed(2)) : null;

  initialValues.cash_discount_amount_percent = (initialValues.cash_discount_amount_percent && Number(initialValues.cash_discount_amount_percent) !== 0) ? TextUtil.formatDecimals(Number(initialValues.cash_discount_amount_percent).toFixed(2)) : null;

  initialValues.tax_rate = initialValues.tax_rate && TextUtil.formatPercentageWithoutSymbol(Number(initialValues.tax_rate).toFixed(3));

  initialValues.ignore_avs_failure = !initialValues.ignore_avs_failure;

  initialValues.is_preferred_edge = (initialValues.is_preferred_edge || ownProps.edgeUpgrade);

  const gcType = !!state?.merchantSettings?.merchantSettings?.gc_type;
  initialValues.gift_card_enabled = gcType && state.merchantSettings.merchantSettings.gc_type.length > 1;

  const [gift_card_preset_1 = '0.00', gift_card_preset_2 = '0.00', gift_card_preset_3 = '0.00', gift_card_preset_4 = '0.00'] = parsePresetAmounts(ownProps.merchantSettings?.merchantSettings?.gc_preset_amounts);

  initialValues.gift_card_preset_1 = gift_card_preset_1;
  initialValues.gift_card_preset_2 = gift_card_preset_2;
  initialValues.gift_card_preset_3 = gift_card_preset_3;
  initialValues.gift_card_preset_4 = gift_card_preset_4;
  initialValues.keep_gc_on_top = !!initialValues.keep_gc_on_top;
  initialValues.add_gc_to_favorites = !!initialValues.add_gc_to_favorites;
  initialValues.manual_capture_time = !!ownProps?.merchantSettings?.merchantSettings?.manual_capture_time ? ownProps?.merchantSettings?.merchantSettings?.manual_capture_time : null;
  initialValues.set_closing_timer = !!ownProps?.merchantSettings?.merchantSettings?.manual_capture_time;
  initialValues.auto_close_print_time = ownProps?.merchantSettings?.merchantSettings?.auto_close_print_time;

  const revoke_days = ownProps?.merchantSettings?.merchantSettings?.ach_notification_revoke_days
  initialValues.ach_notification_revoke_days = revoke_days ? [parseInt(revoke_days)] : [10];

  const revoke_type = ownProps?.merchantSettings?.merchantSettings?.ach_notification_revoke_type;
  initialValues.ach_notification_revoke_type = revoke_type ? Object.keys(revoke_type).filter(key => revoke_type[key]) : ['mail'];

  const autoClosePrintTime = initialValues?.auto_close_print_time && moment(initialValues.auto_close_print_time, 'HH:mm').format('LT');
  //selectors
  const allowPinDebit = selector(state, 'allow_pin_debit');
  const pinDebitEnabled = selector(state, 'pin_debit_enabled');
  const pinEnabled = selector(state, 'pin_enabled');
  const tipEnabled = selector(state, 'is_tip_enabled');
  const system_invoice_enabled = selector(state, 'system_invoice_enabled');
  const custom_invoice_numbering_enabled = selector(state, 'custom_invoice_numbering_enabled');
  const custom_invoice_starting_number = selector(state, 'custom_invoice_starting_number');
  const custom_invoice_restart_batches = selector(state, 'custom_invoice_restart_batches');
  const custom_invoice_apply_to_portfolio = selector(state, 'custom_invoice_apply_to_portfolio');
  const invoice_number_required = selector(state, 'invoice_number_required');
  const socialMediaEnabled = selector(state, 'social_media_enabled');
  const bccEmailEnabled = selector(state, 'should_bcc');
  const ticketsEnabled = selector(state, 'tickets_enabled');
  const isTerminal = selector(state, 'theme');
  const cashDiscountEnabled = selector(state, 'cash_discount_enabled');
  const displayCashPriceEnabled = selector(state, 'cash_discount_display_cash_price');
  const valid = isValid('settingsForm')(state);
  const pristine = isPristine('settingsForm')(state);
  const percentageOne = selector(state, 'tip_one_type');
  const percentageTwo = selector(state, 'tip_two_type');
  const percentageThree = selector(state, 'tip_three_type');
  const manualCapture = selector(state, 'manual_capture');
  const autoCaptureDate = selector(state, 'auto_capture_time');
  const autoCaptureTime = autoCaptureDate ? moment(autoCaptureDate, 'HH:mm').format('LT') : '10:00 PM';

  const gCQuantity = selector(state, 'gc_quantity');
  const gCSelectorOne = selector(state, 'gift_card_preset_1');
  const gCSelectorTwo = selector(state, 'gift_card_preset_2');
  const gCSelectorThree = selector(state, 'gift_card_preset_3');
  const gCSelectorFour = selector(state, 'gift_card_preset_4');

  const gCTopSelector = selector(state, 'keep_gc_on_top');
  const gCFavoritesSelector = selector(state, 'add_gc_to_favorites');

  const isPercentageOne = percentageOne === '1';
  const isPercentageTwo = percentageTwo === '1';
  const isPercentageThree = percentageThree === '1';

  const submissionOk = !valid && !pristine;

  const isEdge = selector(state, 'is_preferred_edge');

  const manualCaptureTime = selector(state, 'manual_capture_time');
  const setClosingTime = selector(state, 'set_closing_timer');

  const acceptCashPayment = selector(state, 'accept_cash_payments');

  return {
    allowPinDebit,
    autoClosePrintTime,
    pinDebitEnabled,
    initialValues,
    pinEnabled,
    tipEnabled,
    socialMediaEnabled,
    bccEmailEnabled,
    ticketsEnabled,
    isTerminal,
    cashDiscountEnabled,
    displayCashPriceEnabled,
    submissionOk,
    isPercentageOne,
    isPercentageTwo,
    isPercentageThree,
    manualCapture,
    autoCaptureTime,
    system_invoice_enabled,
    custom_invoice_numbering_enabled,
    custom_invoice_starting_number,
    custom_invoice_restart_batches,
    custom_invoice_apply_to_portfolio,
    invoice_number_required,
    isEdge,
    gCQuantity,
    gCSelectorOne,
    gCSelectorTwo,
    gCSelectorThree,
    gCSelectorFour,
    gCTopSelector,
    gCFavoritesSelector,
    gcType,
    manualCaptureTime,
    setClosingTime,
    acceptCashPayment
  };

}

SettingsForm = connect(mapStateToProps)(SettingsForm);

export default SettingsForm;
