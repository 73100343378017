/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import _ from 'lodash';
import numeral from 'numeral';
import moment from 'moment';
import TextUtil from './FormatTextUtil';
import InvoiceUtil, { InvoiceFilterTypes } from './InvoiceUtil';
import actionTypes from '../../constants/actionTypes';
import DateUtils from './DateUtil';
import commonUtil from './CommonUtil';
import reputationStatusOptions from '../../constants/reputationStatusOptions';
import inventoryOptions from '../../constants/inventoryOptions';
import paginationUtil from './PaginationUtil';
import customerOptions from '../../constants/customerOptions';
import generalOptions from '../../constants/generalOptions'
import { PaymentLinksFilterTypes } from './PaymentLinksUtil';
import { getTransactionType } from './TransactionsUtil';
import { isJSON } from './CommonUtil';

const FilterUtil = {

  filterItems: (items, visibilityFilter, filterArchiveItems = false, paginateData = false) => {
    const searchFilter = _.toUpper(visibilityFilter.search);
    const searchDropdownFilter = _.toUpper(visibilityFilter.secondaryFilter);
    const { maximumPerPage } = inventoryOptions.items;
    const page = visibilityFilter.pagination || 1;

    const priceRegex = /\$\d+(\.\d+)?/g;

    items.filteredItems = (searchFilter || searchDropdownFilter) ? _.filter(items.salesItems, (item) => {
      let localSearchFilter =
        (searchFilter && !searchDropdownFilter && searchFilter) ||
        (!searchFilter && searchDropdownFilter && searchDropdownFilter);


      const isSearchPriceWithDollarSign = localSearchFilter.match(priceRegex);

      if (isSearchPriceWithDollarSign) {
        localSearchFilter = localSearchFilter.replace('$', '');
      }

      const hasVisibilityFilter = item.name && _.toUpper(item.name.toString()).includes(localSearchFilter) ||
        item.description && _.toUpper(item.description.toString()).includes(localSearchFilter) ||
        item.details.prices.some(price => (
            price?.barcode?.includes(`${parseFloat(localSearchFilter)}`) ||
            `${parseFloat(price.price || 0)}`.includes(`${parseFloat(localSearchFilter)}`) )
        );

      if (hasVisibilityFilter && item.is_active) {
        return item;
      }

    }) : items.salesItems?.filter((item) => item.is_active);

    items.filteredCategories = searchFilter ? _.filter(items.categories, (category) => {

      const hasVisibilityFilter = category.name && _.toUpper(category.name.toString()).includes(searchFilter);

      if (hasVisibilityFilter === true) {
        return category;
      }

    }) : items.categories;

    items.filteredDiscounts = (searchFilter || searchDropdownFilter) ? _.filter(items.discounts, (discount) => {
      const localSearchFilter = searchFilter || searchDropdownFilter;

      const hasVisibilityFilter = discount.name && _.toUpper(discount.name.toString()).includes(localSearchFilter);

      if (hasVisibilityFilter) {
        return {
          ...discount,
          value: discount.type === 'amount' ? TextUtil.formatCurrencyOff(discount.amount) : TextUtil.formatPercentOff(discount.percentage)
        };
      }

    }) : items.discounts;

    items.filteredModifiers = searchFilter ? _.filter(items.modifierSets, (modifierSet) => {

      const hasVisibilityFilter = modifierSet.name && _.toUpper(modifierSet.name.toString()).includes(searchFilter);

      if (hasVisibilityFilter === true) {
        return modifierSet;
      }

    }) : items.modifierSets;

    if (filterArchiveItems) {
      items.filteredArchivedItems = visibilityFilter.filter ? _.filter(items.salesItems,  (item) => {
        const filterName = isJSON(visibilityFilter.filter.value) ? (JSON.parse(visibilityFilter.filter.value))?.name : visibilityFilter.filter.value;
        const categories = item?.categories?.length && item.categories.map((category) => category.name) || [];

        return !item.is_active && (filterName === 'All Items' || (filterName === 'Favorite' && item.is_favorite) || categories.includes(filterName))
      }) : items.filteredArchivedItems;

      const isSearchPriceWithDollarSign = searchFilter?.match(priceRegex);

      let newSearchFilter = searchFilter;

      if (isSearchPriceWithDollarSign) {
        newSearchFilter = searchFilter.replace('$', '');
      }

      items.filteredArchivedItems = newSearchFilter ? _.filter(items.filteredArchivedItems,  (item) =>
        item.name && _.toUpper(item.name.toString()).includes(newSearchFilter) ||
        item.description && _.toUpper(item.description.toString()).includes(newSearchFilter) ||
        item.details.prices.some(price => (
            price?.barcode?.includes(`${parseFloat(newSearchFilter)}`) ||
            `${parseFloat(price?.price || 0)}`.includes(`${parseFloat(newSearchFilter)}`) )
        )
      ) : items.filteredArchivedItems;
    } else {
      items.filteredArchivedItems = [];

      items.filteredItems = visibilityFilter.filter ? _.filter(items.filteredItems, (item) => {

        const filterName = isJSON(visibilityFilter.filter.value) ? (JSON.parse(visibilityFilter.filter.value))?.name : visibilityFilter.filter.value;

        if (filterName === 'Favorite' && item.is_favorite && item.is_active) {
          return item;
        } else if (
          (
            filterName === 'All Items'
            || filterName === 'All Reports'
            || filterName === 'Itemized sale'
            || filterName === 'Create an invoice'
            || filterName === 'itemized'
            || filterName === 'Inventory Reports'
            || Object.values(InvoiceFilterTypes).includes(filterName)
          )
          && item.is_active
        ) {
          return item;
        } else if (item?.categories?.length && item.categories.map((category) => category.name).includes(filterName) && item.is_active) {
          return item;
        } else {
          return;
        }

      }) : items.filteredItems;
    }

    if (visibilityFilter.order && visibilityFilter.order.value && visibilityFilter.order.dir) {

      const valueToMatch = (visibilityFilter.order.value === 'details.prices[0].price')
        ? (value) => parseFloat(value.details.prices[0].price, 10) || value[visibilityFilter.order.value]
        : visibilityFilter.order.value;

      items.filteredItems = _.orderBy(items.filteredItems, [valueToMatch], [visibilityFilter.order.dir]);
      items.filteredCategories = _.orderBy(items.filteredCategories, [visibilityFilter.order.value], [visibilityFilter.order.dir]);
      items.filteredDiscounts = _.orderBy(items.filteredDiscounts, [visibilityFilter.order.value], [visibilityFilter.order.dir]);
      items.filteredModifiers = _.orderBy(items.filteredModifiers, [visibilityFilter.order.value], [visibilityFilter.order.dir]);
      items.filteredArchivedItems = _.orderBy(items.filteredArchivedItems, [visibilityFilter.order.value], [visibilityFilter.order.dir]);
    }

    if (paginateData) {

      items.filteredItemsTotal = items.filteredItems?.length || 0;
      const isLargeItemsInventory = items.filteredItemsTotal > maximumPerPage;
      if (isLargeItemsInventory && !filterArchiveItems) {
        items.filteredItems = paginationUtil.paginate(items.filteredItems, maximumPerPage, page);
      }

      items.filteredArchivedItemsTotal = items.filteredArchivedItems?.length || 0;
      const isLargeArchivedItemsInventory = items.filteredArchivedItemsTotal > maximumPerPage;
      if (isLargeArchivedItemsInventory && filterArchiveItems) {
        items.filteredArchivedItems = paginationUtil.paginate(items.filteredArchivedItems, maximumPerPage, page);
      }
    }

    return items;
  },

  filterCustomers: (customers, visibilityFilter, paginateData = false) => {
    const { maximumPerPage } = customerOptions.customers;
    const page = visibilityFilter.pagination || 1;
    const action = _.get(visibilityFilter, 'action', {});
    const filteredModally = action.type === actionTypes.setModalVisibilitySearchFilter;
    const searchFilter = filteredModally ? _.toUpper(visibilityFilter.action.filterValue) : _.toUpper(visibilityFilter.search);
    const filteredProperty = filteredModally ? 'filteredModally' : 'filteredData';
    customers[filteredProperty] = searchFilter ? _.filter(customers.data, (customer) => {

      customer.email_addresses = customer?.email_addresses?.filter(email => !!email?.replace(/\s/g, '')); //Removes any empty emails

      const hasVisibilityFilter = customer.first_name && _.toUpper(customer.first_name.toString()).includes(searchFilter) ||
        customer.last_name && _.toUpper(customer.last_name.toString()).includes(searchFilter) ||
        customer.phone_number && _.toUpper(customer.phone_number.toString()).includes(searchFilter) ||
        customer.email_addresses && _.toUpper(customer.email_addresses.toString()).includes(searchFilter) ||
        customer.account_number && _.toUpper(customer.account_number.toString()).includes(searchFilter) ||
        _.toUpper(TextUtil.formatName(customer.first_name, customer.last_name, 'Unnamed Customer')).includes(searchFilter);

      if (hasVisibilityFilter === true) {
        return customer;
      }

    }) : customers.data;

    if (visibilityFilter.order && visibilityFilter.order.value && visibilityFilter.order.dir && customers.filteredData && customers.filteredData.length) {
      const firstCustomer = customers.filteredData[0];

      const valueToMatch = (visibilityFilter.order.value === 'loyalty_vpc_status.reward_is_eligible')
        ? (customer) => !customer.loyalty_vpc_status?.reward_is_eligible
        : (visibilityFilter.order.value === 'transaction_count')
        ? (value) => !value[visibilityFilter.order.value] ? 0 : parseFloat(value[visibilityFilter.order.value], 10) || value[visibilityFilter.order.value]
        : (visibilityFilter.order.orthographicException && firstCustomer.hasOwnProperty(visibilityFilter.order.value))
          ? (customer) => TextUtil.removeDiacritics(customer[visibilityFilter.order.value]).toLowerCase()
          : visibilityFilter.order.value;

      customers.filteredData = _.orderBy(customers.filteredData, [valueToMatch], [visibilityFilter.order.dir]);
    }

    if (visibilityFilter.filter?.value?.includes('With Active Reward')) {
      customers.filteredData = customers?.filteredData?.length ? customers.filteredData.filter((customer) => customer.loyalty_vpc_status?.reward_is_eligible) : [];
    }


    if (paginateData) {
      customers.filteredDataRaw = customers.filteredData;
      customers.filteredDataTotal = customers.filteredDataRaw?.length || 0;
      const isLargeCustomerList = customers.filteredDataTotal > maximumPerPage;
      if (isLargeCustomerList) {
        customers.filteredData = paginationUtil.paginate(customers.filteredDataRaw, maximumPerPage, page);
      }
    }

    return customers;

  },

  filterPaymentLinks: (paymentLinks, visibilityFilter) => {
    const isActiveLinksSelected = _.get(visibilityFilter, 'filter.value') === PaymentLinksFilterTypes.ACTIVE;
    const isInactiveLinksSelected = _.get(visibilityFilter, 'filter.value') === PaymentLinksFilterTypes.INACTIVE;
    const searchTerm = (isActiveLinksSelected ||isInactiveLinksSelected) ? _.toUpper(visibilityFilter.search) : null;
    const order = visibilityFilter.order?.value;
    const direction = visibilityFilter.order?.dir;

    let filteredData = paymentLinks.internalPaymentLinks;

    if (searchTerm) {
      filteredData = filteredData.filter((link) =>
        _.toUpper(link.settings?.title?.toString()).includes(searchTerm) ||
        _.toUpper(link.settings?.type?.toString()).includes(searchTerm)
      );
    }

    if (order && filteredData.length) {
      filteredData = _.orderBy(filteredData, order, direction);
    }

    paymentLinks.filteredData = filteredData;

    return paymentLinks;
  },

  filterInvoices: (invoices, visibilityFilter) => {
    const isSeriesSelected = _.get(visibilityFilter, 'filter.value') === InvoiceFilterTypes.SERIES;
    const isCanceledSelected = _.get(visibilityFilter, 'filter.value') === InvoiceFilterTypes.CANCELED;
    const isDraftSelected = _.get(visibilityFilter, 'filter.value') === InvoiceFilterTypes.DRAFT;

    let data = [];

    if (isSeriesSelected) {
      data = _.get(invoices, 'data.series')
    } else {
      data = _.get(invoices, 'data.invoices')
    }

    if(isCanceledSelected || isDraftSelected){
      data = data.concat(_.get(invoices, 'data.series'));
    }

    let invoicesByFilter = _(data);

    if (isCanceledSelected) {
      invoicesByFilter = invoicesByFilter.orderBy(['created_date'], ['desc']);
    } else if (isDraftSelected) {
      invoicesByFilter = invoicesByFilter.orderBy(['created_date'], ['asc']);
    }

    invoicesByFilter = invoicesByFilter?.value();

    const searchFilter = Boolean(visibilityFilter.search) && _.toUpper(visibilityFilter.search);

    let filteredData = searchFilter ? _.filter(invoicesByFilter, (invoice) => {

      const amount = numeral(invoice.total_amt).format('$0,0.00');

      let hasVisibilityFilter;

      if (visibilityFilter.filter?.isSeries) {
        hasVisibilityFilter = invoice.series_details_id === visibilityFilter?.search || (invoice.id === visibilityFilter?.search && invoice.statusList.isSeries);
      } else {
        const searchTerm = _.toUpper(searchFilter);
        const stringIncludesSearchTerm = (field) =>
          field?.toString()?.toUpperCase().includes(searchTerm);
        const fullName = `${invoice.first_name} ${invoice.last_name}`;
        const seriesFullName = `${invoice.customer_first_name} ${invoice.customer_last_name}`;
        hasVisibilityFilter = [
            fullName,
            seriesFullName,
            invoice.phone_number,
            invoice.invoice,
            invoice.receipt_id,
            invoice.name,
            invoice.series_details_id,
            invoice.id,
          ].some(stringIncludesSearchTerm) ||
          invoice.email_addresses?.some((email) => stringIncludesSearchTerm(email)) ||
          (invoice.total_amt && amount.includes(searchFilter)) ||
          stringIncludesSearchTerm(invoice.status);
      }

      if (hasVisibilityFilter === true) {
        return invoice;
      }

    }) : invoicesByFilter;

    filteredData = _.uniqBy(filteredData, 'id');

    invoices.filteredData = visibilityFilter.filter ? _.filter(filteredData, (invoice) => {
      const {
        isPaid, isUnpaid, isOverdue, isScheduled,
        isDraft, isFailed, isCanceled
      } = invoice.statusList;

      switch (visibilityFilter.filter.value) {
        case InvoiceFilterTypes.CANCELED:
          if (isCanceled) return invoice;
          break;
        case InvoiceFilterTypes.DRAFT:
          if (isDraft) return invoice;
          break;
        case InvoiceFilterTypes.PAID:
          if (isPaid) return invoice;
          break;
        case InvoiceFilterTypes.UNPAID:
          if (isUnpaid) return invoice;
          break;
        case InvoiceFilterTypes.OVERDUE:
          if (isOverdue) return invoice;
          break;
        case InvoiceFilterTypes.SCHEDULED:
          if (isScheduled) return invoice;
          break;
        case InvoiceFilterTypes.FAILED:
          if (isFailed) return invoice;
          break;
        case InvoiceFilterTypes.SERIES:
          if (InvoiceUtil.filterIsSeries(invoice)) return invoice;
          break;
        case InvoiceFilterTypes.INVOICES:
        default:
          if (InvoiceUtil.filterIsAllInvoices(invoice)) return invoice;
          break;
      }
    }) : filteredData;

    const matchingFunction = (invoice) => {

      const totalAfterDiscount = invoice?.total_amt
        ? (invoice.total_amt - (!invoice.paid_date && !invoice.receipt_updated_date && invoice.receipt_discount_amt && invoice.receipt_discount_amt[0] ? invoice.receipt_discount_amt[0] : 0))
        : 0;

      return parseFloat(totalAfterDiscount, 10);
    }

    if (visibilityFilter.order && visibilityFilter.order.value && visibilityFilter.order.dir) {

      const valueToMatch = (visibilityFilter.order.value === 'total_amt')
        ? matchingFunction
        : visibilityFilter.order.value;

      invoices.filteredData = _.orderBy(invoices.filteredData, [valueToMatch], [visibilityFilter.order.dir]);

    }

    return invoices;

  },

  filterDeposits: (deposits, visibilityFilter) => {

    const searchFilter = _.toUpper(visibilityFilter.search);

    const searchFilteredData = searchFilter ? _.filter(deposits.data, (deposit) => {

      const depositDate = moment(deposit.deposit_date).format('MM/DD/YYYY') + ' ' + moment(deposit.deposit_date).format(' h:mm a');
      const amountToClear = numeral(deposit.amount_to_clear).format('$0,0.00');

      const hasVisibilityFilter = deposit.account && _.toUpper(deposit.account.toString()).includes(searchFilter) ||
        deposit.type && _.toUpper(deposit.type.toString()).includes(searchFilter) ||
        deposit.amount_to_clear && amountToClear.includes(searchFilter) ||
        deposit.clearing_method && _.toUpper(deposit.clearing_method.toString()).includes(searchFilter) ||
        deposit.deposit_date && _.toUpper(depositDate).includes(searchFilter);

      if (hasVisibilityFilter === true) {
        return deposit;
      }

    }) : deposits.data;

    deposits.filteredData = visibilityFilter.filter ? _.filter(searchFilteredData, (deposit) => {
      switch (visibilityFilter.filter.value) {
        case 'All':
          if ((deposit.type === 'Deposit') ||
            (deposit.type === 'Withdrawal') ||
            (deposit.type.includes('Resolved')) ||
            (deposit.type.includes('Held'))) {
            return deposit;
          }
          break;
        case 'Deposits':
          if (deposit.type === 'Deposit') {
            return deposit;
          }
          break;
        case 'Withdrawals':
          if (deposit.type === 'Withdrawal') {
            return deposit;
          }
          break;
        case 'Resolved transactions':
          if (deposit.type.includes('Resolved')) {
            return deposit;
          }
          break;
        case 'Held funds':
          if (deposit.type.includes('Held')) {
            return deposit;
          }
          break;
        default:
          return deposit;
      }
    }) : searchFilteredData;

    if (visibilityFilter.order && visibilityFilter.order.value && visibilityFilter.order.dir) {

      const valueToMatch = (visibilityFilter.order.value === 'amount_to_clear')
        ? (value) => parseFloat(value[visibilityFilter.order.value], 10) || value[visibilityFilter.order.value]
        : visibilityFilter.order.value;

      deposits.filteredData = _.orderBy(deposits.filteredData, [valueToMatch], [visibilityFilter.order.dir]);
    }

    return deposits;

  },

  filterEmployees: (employees, visibilityFilter) => {

    const action = _.get(visibilityFilter, 'action', {});
    const filteredModally = action.type === actionTypes.setModalVisibilitySearchFilter;
    const searchFilter = filteredModally ? _.toUpper(visibilityFilter.action.filterValue) : _.toUpper(visibilityFilter.search);

    const filteredProperty = filteredModally ? 'filteredModally' : 'filteredData';

    const filteredData = searchFilter ? _.filter(employees.data, (employee) => {

      let hasVisibilityFilter = employee.first_name && _.toUpper(employee.first_name.toString()).includes(searchFilter) ||
        employee.last_name && _.toUpper(employee.last_name.toString()).includes(searchFilter) ||
        employee.first_name && employee.last_name && _.toUpper(`${employee.first_name.toString()} ${employee.last_name.toString()}`).includes(searchFilter) ||
        employee.phone_number && _.toUpper(employee.phone_number.toString()).includes(searchFilter) ||
        employee.email_address && _.toUpper(employee.email_address.toString()).includes(searchFilter);

      if (hasVisibilityFilter === true) {
        return employee;
      }

    }) : employees.data;

    employees[filteredProperty] = visibilityFilter.filter ? _.filter(filteredData, (employee) => {

      switch (visibilityFilter.filter.value) {
        case 'Administrator':
          if ((employee[visibilityFilter.filter.property] === 'admin' ||
            employee[visibilityFilter.filter.property] === 'owner') && employee.is_active) {
            return employee;
          }
          break;
        case 'Manager':
          if (employee[visibilityFilter.filter.property] === 'manager' && employee.is_active) {
            return employee;
          }
          break;
        case 'Cashier':
          if (employee[visibilityFilter.filter.property] === 'cashier' && employee.is_active) {
            return employee;
          }
          break;
        case 'Reporter':
          if (employee[visibilityFilter.filter.property] === 'report' && employee.is_active) {
            return employee;
          }
          break;
        case 'Staff':
          if (employee[visibilityFilter.filter.property] === 'staff' && employee.is_active) {
            return employee;
          }
          break;
        case 'Archive':
          if (!employee.is_active) {
            return employee;
          }
          break;
        case 'All Employees':
          if (employee.is_active) {
            return employee;
          }
          break;
        default:
          return employee;
      }
    }) : filteredData;

    if (visibilityFilter.order && visibilityFilter.order.value && visibilityFilter.order.dir) {
      employees.filteredData = _.orderBy(employees.filteredData, [visibilityFilter.order.value], [visibilityFilter.order.dir]);
    }

    return employees;

  },

  filterShoppingCart: (shoppingCart, visibilityFilter) => {
    const orderHistorySearchFilter = visibilityFilter?.filter?.value === 'Order History' ? _.toUpper(visibilityFilter.search) : '';
    const data = _.get(shoppingCart, 'orders.data');

    shoppingCart.orders.filteredData = orderHistorySearchFilter ? _.filter(data, (order) => {
      let hasVisibilityFilter = order.id && _.toUpper(order.id.toString()).includes(orderHistorySearchFilter) ||
        _.isNumber(order.merchant_amount) && numeral(order.merchant_amount).format('$0.00').includes(orderHistorySearchFilter) ||
        order.order_date && _.toUpper(moment(order.order_date).format('MMMM')).includes(orderHistorySearchFilter);

      if (hasVisibilityFilter === true) {
        return order;
      }
    }) : data;

    if (visibilityFilter.order && visibilityFilter.order.tableId === 'orders' && visibilityFilter.order.value && visibilityFilter.order.dir) {
      shoppingCart.orders.filteredData = _.orderBy(data, [visibilityFilter.order.value], [visibilityFilter.order.dir]);
    }

    shoppingCart.allotments.filteredData = shoppingCart.allotments.data ? shoppingCart.allotments.data.map((allotment) => {
      const remaining = allotment.supply_limit - allotment.supplies_ordered;
      return {
        supply_name: allotment.supply_name,
        supply_limit: allotment.supply_limit,
        supplies_ordered: allotment.supplies_ordered,
        remaining: remaining < 0 ? 0 : remaining
      };
    }) : null;

    if (visibilityFilter.order && visibilityFilter.order.tableId === 'allotments' && visibilityFilter.order.value && visibilityFilter.order.dir) {
      shoppingCart.allotments.filteredData = _.orderBy(shoppingCart.allotments.filteredData, [visibilityFilter.order.value], [visibilityFilter.order.dir]);
    }

    shoppingCart.terminals.filteredData = shoppingCart.terminals.data ? shoppingCart.terminals.data.filter(terminal => terminal.is_active) : [];

    const returnLabelsSearchFilter = visibilityFilter?.filter?.value === 'Electronic Return Labels' ? visibilityFilter.search : '';
    const labelsData = _.get(shoppingCart, 'labels.data');
    if (labelsData) {
      shoppingCart.labels.filteredData = returnLabelsSearchFilter ?
        labelsData.filter(({rmaId}) => rmaId.includes(returnLabelsSearchFilter)) :
        labelsData;
    }

    return shoppingCart;

  },

  filterTransactions: function (transactions, visibilityFilter) {

    const showDeclinedTransactions = visibilityFilter?.filter?.value?.includes('Declined Transactions');
    let that = this;

    let transactionReceipts = transactions.receipts;

    if (visibilityFilter?.filter?.value?.includes('Declined Transactions')) {
      transactionReceipts = transactions.declinedReceipts;
      transactions.end = transactions.declinedEnd;
      transactions.page = transactions.declinedPage;
      transactions.pageSize = transactions.declinedPageSize;
      transactions.rowCount = transactions.declinedRowCount;
    }

    if (visibilityFilter?.filter?.value?.includes('Open Transactions')) {
      const openReceiptsEmployees = [];
      transactionReceipts = transactions.openReceipts || [];

      transactionReceipts = transactionReceipts.map((transaction) => {
        const {sold_by_id} = transaction;
        const employeeAdded = openReceiptsEmployees.find((employee) => employee.id === sold_by_id);
        const firstName = transaction?.employee_firstname ? transaction.employee_firstname : '';
        const lastName = transaction?.employee_lastname ? transaction.employee_lastname : '';
        const fullName =  (firstName && lastName) ? (firstName + ' ' + lastName) : (firstName + lastName);

        if (!employeeAdded) {
          openReceiptsEmployees.push({ id: sold_by_id, employee_firstname: firstName, employee_lastname: lastName });
        }

        return { ...transaction, sold_by: fullName, first_name: transaction.customer_firstname};
      });

      if (visibilityFilter.advancedSearch > 0) {
        transactionReceipts = transactionReceipts.filter((transaction) => transaction.sold_by_id === visibilityFilter.advancedSearch);
      }

      transactions.openReceiptsEmployees = openReceiptsEmployees;
      transactions.end = null;
      transactions.page = null;
      transactions.pageSize = null;
      transactions.rowCount = null;
    }

    let searchFilteredData;

    const advancedSearchFilter = _.isObject(visibilityFilter.search);

    if (advancedSearchFilter) {
      const searchFilter = {};

      _.forOwn(visibilityFilter.search, function(value, key) {
        searchFilter[key] = _.toUpper(value);
      });

      searchFilteredData = !_.isEmpty(searchFilter) ? _.filter(transactionReceipts, (receipt) => {

        const transactionDate = moment(receipt.datetime);
        const transactionType = getTransactionType(receipt, showDeclinedTransactions);
        const name = `${receipt.first_name} ${receipt.last_name}`;
        const soldBy = `${receipt.employee_firstname} ${receipt.employee_lastname}`;

        let hasVisibilityFilter = (
          _.toUpper(name).includes(searchFilter.name || '') &&
          _.toUpper(receipt.type).includes(searchFilter.type || '') &&
          _.toUpper(receipt.network).includes(searchFilter.paymentMethod || '') &&
          _.toUpper(receipt.mid).includes(searchFilter.mid || '') &&
          _.toUpper(receipt.cc_last4).includes(searchFilter.cc_last4 || '') &&
          _.toUpper(receipt.uniq_id).includes(searchFilter.confirmationNumber || '') &&
          _.toUpper(receipt.cc_auth_code).includes(searchFilter.auth || '') &&
          (_.toUpper(receipt.sold_by).includes(searchFilter.soldByEmployee) ||
            _.toUpper(soldBy).includes(searchFilter.soldByEmployee || ''))
        );

        if (searchFilter.bric && !receipt.bric?.includes(searchFilter.bric)) {
          hasVisibilityFilter = false;
        }

        if (hasVisibilityFilter && transactionType) {
          hasVisibilityFilter = _.toUpper(transactionType.toString()).includes(searchFilter.transactionType || '');
        }

        if (hasVisibilityFilter && transactionDate && searchFilter.dateRangeFrom && searchFilter.dateRangeTo) {
          const dateRangeFrom = moment(searchFilter.dateRangeFrom);
          const dateRangeTo = moment(searchFilter.dateRangeTo).endOf('day');
          hasVisibilityFilter = transactionDate.isBetween(dateRangeFrom, dateRangeTo);
        }

        const amountFrom = searchFilter.amountFrom && numeral(searchFilter.amountFrom).value();
        const amountTo = searchFilter.amountTo && numeral(searchFilter.amountTo).value();
        if (hasVisibilityFilter && (amountFrom || amountFrom === 0) && (amountTo || amountTo === 0)) {
          hasVisibilityFilter = (amountFrom <= receipt.amount) && (amountTo >= receipt.amount);
        }

        const authAmountFrom = searchFilter.authAmountFrom && numeral(searchFilter.authAmountFrom).value();
        const authAmountTo = searchFilter.authAmountTo && numeral(searchFilter.authAmountTo).value();
        if (hasVisibilityFilter && (authAmountFrom || authAmountFrom === 0) && (authAmountTo || authAmountTo === 0)) {
          hasVisibilityFilter = (authAmountFrom <= receipt.ccs_authorized_amt) && (authAmountTo >= receipt.ccs_authorized_amt);
        }

        const tipAmountFrom = searchFilter.tipAmountFrom && numeral(searchFilter.tipAmountFrom).value();
        const tipAmountTo = searchFilter.tipAmountTo && numeral(searchFilter.tipAmountTo).value();
        if (hasVisibilityFilter && (tipAmountFrom || tipAmountFrom === 0) && (tipAmountTo || tipAmountTo === 0)) {
          hasVisibilityFilter = (tipAmountFrom <= receipt.tip_amount) && (tipAmountTo >= receipt.tip_amount);
        }

        if (hasVisibilityFilter === true) {
          return receipt;
        }

      }) : transactionReceipts;

    } else {
      const searchFilter = _.toUpper(visibilityFilter.search);

      searchFilteredData = searchFilter ? _.filter(transactionReceipts, (receipt) => {

      const transactionDate = moment(receipt.datetime).format('MM/DD/YYYY') + ' ' + moment(receipt.datetime).format(' h:mm a');
      const amount = numeral(receipt.amount).format('$0,0.00');
      const tipAmount = numeral(receipt.tip_amount).format('$0,0.00');
      const transactionType = getTransactionType(receipt, showDeclinedTransactions);
      receipt.txn_source = that.getTransactionSource(receipt);
      const customerPhoneNumber = receipt.phone_number ? TextUtil.formatPhoneNumber(receipt.phone_number) : null;

      const itemsNames = receipt.item_names ? _.flatten(receipt.item_names) : '';

      const hasVisibilityFilter = receipt.first_name && _.toUpper(receipt.first_name.toString()).includes(searchFilter) ||
        receipt.id && _.toUpper(receipt.id.toString()).includes(searchFilter) || receipt.receipt_id && _.toUpper(receipt.receipt_id.toString()).includes(searchFilter) ||
        receipt.last_name && _.toUpper(receipt.last_name.toString()).includes(searchFilter) ||
        receipt.cc_first6 && _.toUpper(receipt.cc_first6.toString()).includes(searchFilter) ||
        receipt.cc_last4 && _.toUpper(receipt.cc_last4.toString()).includes(searchFilter) ||
        receipt.cc_auth_code && _.toUpper(receipt.cc_auth_code.toString()).includes(searchFilter) ||
        receipt.invoice && _.toUpper(receipt.invoice.toString()).includes(searchFilter) ||
        transactionType && _.toUpper(transactionType.toString()).includes(searchFilter) ||
        receipt.amount && amount.includes(searchFilter) ||
        receipt.tip_amount && tipAmount.includes(searchFilter) ||
        receipt.cc_name_on_card && _.toUpper(receipt.cc_name_on_card.toString()).includes(searchFilter) ||
        receipt.sold_by && _.toUpper(receipt.sold_by.toString()).includes(searchFilter) ||
        receipt.datetime && _.toUpper(transactionDate).includes(searchFilter) ||
        _.toUpper(itemsNames).includes(searchFilter) ||
        receipt.customer_id && _.toUpper(receipt.customer_id.toString()).includes(searchFilter) ||
        !receipt.is_complete && searchFilter === 'INCOMPLETE TRANSACTIONS' ||
        receipt.txn_source && _.toUpper(receipt.txn_source.toString()).includes(searchFilter) ||
        receipt.customer_account_number && _.toUpper(receipt.customer_account_number.toString()).includes(searchFilter) ||
        receipt?.status_message && _.toUpper(receipt?.status_message.toString()).includes(searchFilter) ||
        receipt.network && _.toUpper(receipt.network.toString()).includes(searchFilter) ||
        customerPhoneNumber && _.toUpper(customerPhoneNumber.toString()).includes(searchFilter) ||
        receipt.sold_by_name && _.toUpper(receipt.sold_by_name.toString()).includes(searchFilter) ||
        receipt.bric?.includes(searchFilter);

        if (hasVisibilityFilter === true) {
          return receipt;
        }

      }) : transactionReceipts;
    }

    const filteredData = visibilityFilter.filter ? _.filter(searchFilteredData, (receipt) => {
      switch (visibilityFilter.filter.value) {
        case 'Invoices':
          if (!_.isEmpty(receipt.inv_paid_date) && !['Credit Refund', 'Void'].includes(receipt.type)) {
            return receipt;
          }
          break;
        case 'Voids':
          if (receipt[visibilityFilter.filter.property] === 'Void' ||
            receipt[visibilityFilter.filter.property] === 'ACH Void') {
            return receipt;
          }
          break;
        case 'Refunds':
          if (receipt[visibilityFilter.filter.property] === 'Cash Refund' ||
            receipt[visibilityFilter.filter.property] === 'Credit Refund' ||
            receipt[visibilityFilter.filter.property] === 'Gift Card Refund' ||
            receipt[visibilityFilter.filter.property] === 'ACH Refund') {
            return receipt;
          }
          break;
        case 'All Sales':
          if (receipt[visibilityFilter.filter.property] === 'Cash Sale' ||
            receipt[visibilityFilter.filter.property] === 'Credit Sale' ||
            receipt[visibilityFilter.filter.property] === 'ACH Sale' ||
            receipt[visibilityFilter.filter.property] === 'Cash Sale - Split Payment' ||
            receipt[visibilityFilter.filter.property] === 'Credit Sale - Split Payment' ||
            receipt[visibilityFilter.filter.property] === 'Gift Card Sale' ||
            receipt[visibilityFilter.filter.property] === 'Gift Card Sale - Split Payment') {
            return receipt;
          }
          break;
        case 'Gift Card Sales':
          if ((receipt[visibilityFilter.filter.property] === 'Credit Sale' && receipt.network === 'Gift Card') ||
            (receipt[visibilityFilter.filter.property] === 'Credit Sale - Split Payment' && receipt.network === 'Gift Card') ||
            (receipt[visibilityFilter.filter.property] === 'Credit Refund' && receipt.network === 'Gift Card') ||
            (receipt[visibilityFilter.filter.property] == 'Void' && receipt.network === 'Gift Card')) {
            return receipt;
          }
          break;
        case 'Credit Sales':
          if ((receipt[visibilityFilter.filter.property] === 'Credit Sale' && receipt.network !== 'Gift Card') ||
            (receipt[visibilityFilter.filter.property] === 'Credit Sale - Split Payment' && receipt.network !== 'Gift Card')) {
            return receipt;
          }
          break;
        case 'Cash Sales':
          if (receipt[visibilityFilter.filter.property] === 'Cash Sale' ||
            receipt[visibilityFilter.filter.property] === 'Cash Sale - Split Payment') {
            return receipt;
          }
          break;
        case 'Bank Account Sales':
          if (receipt[visibilityFilter.filter.property] === 'ACH Sale') {
            return receipt;
          }
          break;
        case 'Pre-Auths':
          if (receipt[visibilityFilter.filter.property] === 'Pre Auth') {
            return receipt;
          }
          break;
        case 'Virtual Terminal':
          if (receipt.txn_source && receipt.txn_source.toUpperCase().endsWith('PORTAL') &&
            !['Credit Refund', 'Cash Refund', 'Void'].includes(receipt.type) &&
            _.isEmpty(receipt.inv_paid_date)
          ) {
            return receipt;
          }
          break;
        case 'Debit Sales':
          if ((receipt[visibilityFilter.filter.property] === 'Credit Sale' ||
            receipt[visibilityFilter.filter.property] === 'Credit Sale - Split Payment') && receipt.is_debit) {
            return receipt;
          }
          break;
        case 'EBT Sales':
          if (receipt.network && receipt.network.toString().toLowerCase() === 'ebt') {
            return receipt;
          }
          break;
        case '1099K':
          if ((receipt[visibilityFilter.filter.property] === 'Credit Sale' && receipt.network !== 'Gift Card') ||
            (receipt[visibilityFilter.filter.property] === 'Credit Sale - Split Payment' && receipt.network !== 'Gift Card') ||
            receipt[visibilityFilter.filter.property] === 'Void') {
            return receipt;
          }
          break;
        case 'Payment Links':
          if (receipt.payment_link_id) {
            return receipt;
          }
          break;
        default:
          return receipt;
      }
    }) : searchFilteredData;

    transactions.filteredData = visibilityFilter.advancedSearch && visibilityFilter.advancedSearch.length > 0 ?
      _.filter(filteredData, (receipt) => {
        if (_.includes(visibilityFilter.advancedSearch, receipt.sold_by_id)) {
          return receipt;
        }
      }) : filteredData;

    if (visibilityFilter.order && visibilityFilter.order.value && visibilityFilter.order.dir) {

      let valueToMatch;

      if (visibilityFilter.order.value === 'amount') {
        valueToMatch = value => parseFloat(value[visibilityFilter.order.value], 10) || value[visibilityFilter.order.value];
      } else if (visibilityFilter.order.value === 'type') {
        valueToMatch  = value => getTransactionType(value, showDeclinedTransactions);
      } else {
        valueToMatch = visibilityFilter.order.value;
      }

      transactions.filteredData = _.orderBy(transactions.filteredData, [valueToMatch], [visibilityFilter.order.dir]);
    }

    return transactions;

  },

  filterReports: (reports, visibilityFilter, t) => {

    const searchFilter = _.toUpper(visibilityFilter.search);

    const filteredData = searchFilter ? _.filter(reports.reportTypes, (report) => {

      const hasVisibilityFilter = report.name &&_.toUpper(t(report.name))?.includes(searchFilter) ||
      report.summary && _.toUpper(t(report.summary))?.includes(searchFilter);

      if (hasVisibilityFilter === true) {
        return report;
      }

    }) : reports.reportTypes;

    reports.filteredReportTypes = visibilityFilter.filter ? _.filter(filteredData, (report) => {

      switch (visibilityFilter.filter.value) {
        case 'Sales Reports':
          if (_.includes(report[visibilityFilter.filter.property], 'Sales')) {
            return report;
          }
          break;
        case 'Customer Reports':
          if (_.includes(report[visibilityFilter.filter.property], 'Customer')) {
            return report;
          }
          break;
        case 'Employee Reports':
          if (_.includes(report[visibilityFilter.filter.property], 'Employee')) {
            return report;
          }
          break;
        case 'Inventory Reports':
          if (_.includes(report[visibilityFilter.filter.property], 'Inventory')) {
            return report;
          }
          break;
        case 'Custom Reports':
          if (_.includes(report[visibilityFilter.filter.property], 'Custom')) {
            return report;
          }
          break;
        default:
          return report;
      }
    }) : filteredData;

    return reports;

  },

  filterDisputes: (disputes, visibilityFilter) => {

    const searchFilter = _.toUpper(visibilityFilter.search);

    disputes.filteredData = searchFilter ? _.filter(disputes.data, dispute => {

      const hasVisibilityFilter = dispute.arn && _.toUpper(dispute.arn.toString()).includes(searchFilter)
        || dispute.card_brand && _.toUpper(dispute.card_brand.toString()).includes(searchFilter)
        || dispute.card_number && _.toUpper(dispute.card_number.toString()).includes(searchFilter)
        || dispute.chargeback_guid && _.toUpper(dispute.chargeback_guid.toString()).includes(searchFilter)
        || dispute.type && _.toUpper(dispute.type.toString()).includes(searchFilter)
        || dispute.invoice_number && _.toUpper(dispute.invoice_number.toString()).includes(searchFilter)
        || dispute.original_transaction_amount && dispute.original_transaction_amount &&  dispute.original_transaction_amount.toString().includes(searchFilter)
        || dispute.chargeback_amount && dispute.chargeback_amount.toString().includes(searchFilter)
        || dispute.chargeback_amount && numeral(dispute.chargeback_amount).format('$0,0.00').includes(searchFilter)
        || dispute.reason_code && _.toUpper(dispute.reason_code.toString()).includes(searchFilter)
        || _.toUpper(moment(dispute.original_transaction_date).format('MMMM DD YYYY')).includes(searchFilter)
        || dispute.original_transaction_date && _.toUpper(moment(dispute.original_transaction_date).format('YYYY')).includes(searchFilter)
        || dispute.original_transaction_date && _.toUpper(moment(dispute.original_transaction_date).format('MM/DD/YYYY')).includes(searchFilter)
        || dispute.original_transaction_date && _.toUpper(moment(dispute.original_transaction_date).format('DD')).includes(searchFilter)
        || _.toUpper(moment(dispute.due_date).format('MMMM DD YYYY')).includes(searchFilter)
        || dispute.due_date && _.toUpper(moment(dispute.due_date).format('YYYY')).includes(searchFilter)
        || dispute.due_date && _.toUpper(moment(dispute.due_date).format('MM/DD/YYYY')).includes(searchFilter)
        || dispute.mid && _.toUpper(dispute.mid.toString()).includes(searchFilter)
        || dispute.due_date && _.toUpper(moment(dispute.due_date).format('DD')).includes(searchFilter);

      if (hasVisibilityFilter === true) {
        return dispute;
      }

    }) : disputes.data;

    disputes.filteredData = visibilityFilter.filter ? _.filter(disputes.filteredData, d => {

      const filterName = visibilityFilter.filter.value;

      if (filterName === 'All Disputes') {
        return d;
      } else if (filterName === 'Action Required' && d.actionRequired) {
        return d;
      } else if (filterName === 'Under Review' && d.isPending) {
        return d;
      } else if (filterName === 'Closed' && d.isClosed) {
        return d;
      }
    }) : disputes.filteredData;

    disputes.filteredData = visibilityFilter.secondaryFilter ? _.filter(disputes.filteredData, d => {

      const filterName = visibilityFilter.secondaryFilter.value;

      if (filterName === 'Retrieval' && d.type === filterName) {
        return d;
      } else if (filterName === 'Chargeback' && d.type === filterName) {
        return d;
      } else if (filterName === 'Pre-Arbitration' && d.type === filterName) {
        return d;
      } else if (filterName === 'Arbitration' && d.type === filterName) {
        return d;
      }
    }) : disputes.filteredData;

    if (visibilityFilter.order && visibilityFilter.order.value && visibilityFilter.order.dir) {

      const valueToMatch = (visibilityFilter.order.value === 'chargeback_amount')
        ? (value) => !value[visibilityFilter.order.value] ? 0 : parseFloat(value[visibilityFilter.order.value], 10) || value[visibilityFilter.order.value]
        : visibilityFilter.order.value;

      disputes.filteredData = _.orderBy(disputes.filteredData, [valueToMatch], [visibilityFilter.order.dir]);
    }

    return disputes;

  },

  filterStatements: (statements, visibilityFilter) => {

    const searchFilter = _.toUpper(visibilityFilter.search);

    statements.filteredData = searchFilter ? _.filter(statements.data, (statement) => {

      const hasVisibilityFilter = statement.date && _.toUpper(moment(statement.date).format('MMMM')).includes(searchFilter) ||
        statement.date && _.toUpper(moment(statement.date).format('YYYY')).includes(searchFilter) ||
        statement.backends && _.toUpper(statement.backends[0]).includes(searchFilter);

      if (hasVisibilityFilter === true) {
        return statement;
      }

    }) : statements.data;

    return statements;

  },

  filterBatches: (batches, visibilityFilter) => {

    const searchFilter = _.toUpper(visibilityFilter.search);
    batches.filteredData = searchFilter ? _.filter(batches.data, (batch) => {

      const hasVisibilityFilter = batch.batch_date && _.toUpper(moment(batch.batch_date).format('MMMM')).includes(searchFilter) ||
        batch.batch_date && _.toUpper(moment(batch.batch_date).format('YYYY')).includes(searchFilter) ||
        batch.batch_date && _.toUpper(moment(batch.batch_date).format('DD')).includes(searchFilter) ||
        batch.batch_id && _.toUpper(batch.batch_id).includes(searchFilter) || batch.total_purch_amt && batch.total_purch_amt.includes(searchFilter);


      if (hasVisibilityFilter === true) {
        return batch;
      }

    }) : batches.data;

    if (visibilityFilter.order && visibilityFilter.order.value && visibilityFilter.order.dir) {

      const numberColumns = ['total_trans', 'total_volume', 'amount', 'total_purch_trans', 'total_purch_amt', 'total_return_trans', 'total_return_amt', 'total_gross_amt'];
      const valueToMatch = numberColumns.includes(visibilityFilter.order.value) 
        ? (value) => parseFloat(value[visibilityFilter.order.value], 10) || value[visibilityFilter.order.value]
        : visibilityFilter.order.value;

      batches.filteredData = _.orderBy(batches.filteredData, [valueToMatch], [visibilityFilter.order.dir]);

      if (batches.selectedBatch?.detail?.transactions?.rows?.length > 0) {
        batches.selectedBatch.detail.transactions.rows = _.orderBy(batches.selectedBatch.detail.transactions.rows, [valueToMatch], [visibilityFilter.order.dir]);
      }
    }

    if (batches.selectedBatch?.detail?.transactions) {
      const { maximumPerPage } = generalOptions;
      const pageSize = batches.selectedBatch.detail.transactions.pageSize || maximumPerPage;
      const numberOfLoadedPages = Math.ceil(pageSize / maximumPerPage);

      let page = visibilityFilter.pagination ? visibilityFilter.pagination % numberOfLoadedPages : 1;
      if (page === 0) {
        page = numberOfLoadedPages;
      }

      const rows = batches.selectedBatch.detail.transactions.rows || [];

      batches.selectedBatch.detail.paginatedTransactions = paginationUtil.paginate(rows, maximumPerPage, page);
    }

    return batches;
  },

  getTransactionSource: (receipt) => {

    let transactionSource;

    switch (receipt.txn_source) {
      case 'PayAnywhere Android Storefront':
        transactionSource = 'PayAnywhere Storefront';
        break;
      default:
        transactionSource = receipt.txn_source;
        break;
    }

    return transactionSource;
  },

  provisionalFilter: (data, visibilityFilter) => {

    if (visibilityFilter.order && visibilityFilter.order.value && visibilityFilter.order.dir) {
      data = _.orderBy(data, [visibilityFilter.order.value], [visibilityFilter.order.dir]);
    }

    return data;
  },

  filterDevices: (devices, visibilityFilter) => {
    const searchFilter = _.toUpper(visibilityFilter.search);
    devices['filteredData'] = searchFilter ? _.filter(devices.data, (device) => {
      const hasVisibilityFilter = device && device.deviceId && _.toUpper(device.deviceId.toString()).includes(searchFilter) ||
        device.paVersion && _.toUpper(device.paVersion.toString()).includes(searchFilter) ||
        device.operationSystem && _.toUpper(device.operationSystem.toString()).includes(searchFilter) ||
        device.hbt_info && device.hbt_info?.device_model && _.toUpper(device.hbt_info?.device_model.toString()).includes(searchFilter);
      if (hasVisibilityFilter === true) {
        return device;
      }
    }) : devices.data;

    if (visibilityFilter.order && visibilityFilter.order.value && visibilityFilter.order.dir) {
      devices.filteredData = _.orderBy(devices.filteredData, [visibilityFilter.order.value], [visibilityFilter.order.dir]);
    }
    return devices;
  },

  filterActiveItems: (items) => {
    return items && items.salesItems && items.salesItems.filter(({ is_active }) => is_active);
  },

  filterInvoicesTransactions: (transactions, visibilityFilter, invoices) => {

    const { secondaryFilter, search } = visibilityFilter;

    const mergedPayloads = transactions?.invoicesTransactions ? transactions?.invoicesTransactions?.map((transaction) => {
      const transactionInvoice =  invoices && invoices?.data && invoices?.data?.length && Array.isArray(invoices?.data) && invoices?.data?.find((invoice) => invoice?.receipt_id === transaction?.receipt_id);
      return {
        ...transaction,
        name: transactionInvoice?.name
      }
    }) : [];

    const searchFilter = _.toUpper(search);
    transactions.filteredData = searchFilter ? _.filter(mergedPayloads, (transaction) => {

      const hasVisibilityFilter = transaction.inv_paid_date && _.toUpper(moment(transaction.inv_paid_date).format('MMMM')).includes(searchFilter) ||
        transaction.inv_paid_date && _.toUpper(moment(transaction.inv_paid_date).format('YYYY')).includes(searchFilter) ||
        transaction.inv_paid_date && _.toUpper(moment(transaction.inv_paid_date).format('DD')).includes(transaction.inv_paid_date) ||
        transaction.total_amt && numeral(transaction.total_amt).format('$0,0.00').includes(searchFilter) ||
        transaction.network && _.toUpper(transaction.network).includes(searchFilter) ||
        transaction.cc_first6 && transaction.cc_first6.includes(searchFilter) ||
        transaction.cc_last4 && transaction.cc_last4.includes(searchFilter) ||
        transaction.name && _.toUpper(transaction.name).includes(searchFilter);

      if (hasVisibilityFilter === true) {
        return transaction;
      }

    }) : transactions?.invoicesTransactions;

    if (transactions?.invoicesTransactions?.length && !search && secondaryFilter?.value && secondaryFilter?.value !== 'All') {
      transactions.filteredData = transactions.invoicesTransactions.filter((t) =>
        moment(t.inv_paid_date).format('MMM') === secondaryFilter?.value); //change to year
    }
    if (transactions?.invoicesTransactions?.length && !search && (secondaryFilter?.value === 'All' || !secondaryFilter?.value)) {
      transactions.filteredData = transactions.invoicesTransactions;
    }

    return transactions;

  },

  filterCompetitorsReviews: (competitors, visibilityFilter) => {
    const searchTerm = visibilityFilter.filter?.value === 'Competitor Performance' ? _.toUpper(visibilityFilter.search) : null;
    const order = visibilityFilter.order?.value || 'reviewDate';
    const direction = visibilityFilter.order?.dir || 'desc';

    const activeCompetitors = competitors ?
      competitors.filter((competitor) => competitor.status !== reputationStatusOptions.COMPETITOR_NOT_FOUND && competitor.status !== reputationStatusOptions.COMPETITOR_REQUEST_DENIED)
    : [];

    const reviews = activeCompetitors.length ? _.orderBy(
      _.reduce(activeCompetitors, (reviews, competitor) => {
        if(!competitor.data?.reviews) return reviews;

        const competitorReviews = _.reduce(competitor.data?.reviews ,(filteredReviews,{rating, text, time}) => {

          const current = {
            competitor: competitor.data.name,
            rating,
            comments: text,
            reviewDate: DateUtils.formatDate(moment.unix(time), 'MM/DD/YYYY')
          };

          if (searchTerm) {
            if ( text && _.toUpper(text.toString()).includes(searchTerm) || competitor.data.name && _.toUpper(competitor.data.name.toString()).includes(searchTerm)) {

              return [...filteredReviews, current];
            } else {
              return filteredReviews;
            }
          }
          return [...filteredReviews, current];
        }, []);


        return [...reviews,...competitorReviews];
      }, []), order, direction) : [];


    return reviews;
  },

  filterReviews: (googleAccount, visibilityFilter) => {
    if(!googleAccount) return [];
    const {reviews, pa_reviews} = googleAccount;
    const searchTerm = visibilityFilter.filter?.value === 'Customer Reviews' ? _.toUpper(visibilityFilter.search) : null;
    const order = visibilityFilter.order?.value || 'reviewDate';
    const direction = visibilityFilter.order?.dir || 'desc';
    const ratings = {'FIVE': 5, 'FOUR': 4, 'THREE': 3, 'TWO': 2, 'ONE': 1};

    const googleReviews = reviews && reviews.length ?
      _.reduce(reviews, (filteredReviews, review) => {
        const {reviewId, reviewer, comment, updateTime, starRating, reviewReply} = review;

        const customer = reviewer?.displayName;
        const splitName = customer ? customer.split(' ') : null;
        const firstName = splitName && splitName[0] ? splitName[0] : '';
        const lastName = splitName && splitName[1] ? splitName[1] : '';

        const current = {
          reviewId,
          customer,
          firstName,
          lastName,
          comments: comment,
          reviewDate: updateTime,
          rating: ratings[starRating],
          reply: reviewReply?.comment,
          replyDate: reviewReply?.updateTime,
          platform: 'Google'
        }

        if (searchTerm) {
          if (customer && _.toUpper(customer.toString()).includes(searchTerm) || comment && _.toUpper(comment.toString()).includes(searchTerm)) {
            return [...filteredReviews, current]
          } else {
            return filteredReviews
          }
        }
        return [...filteredReviews, current];
      }, [])
    :
      [];


    const paReviews = pa_reviews && pa_reviews.length ?
      _.reduce(pa_reviews, (filteredPaReviews, paReview) => {
        const {rating, feedback, id, firstName = '', lastName = '', createdDate, customerId} = paReview;

        const current = {
          reviewId: id,
          customer: `${firstName} ${lastName}`,
          comments: feedback,
          reviewDate: createdDate,
          rating,
          platform: 'PA',
          customerId
        }

        return [...filteredPaReviews, current];
      }, [])
    :
      [];

    const result = _.orderBy(([...googleReviews, ...paReviews]), order, direction);

    return result;
  },

  getCompetitors: (competitors, locations) => {
    const activeLocation = locations?.find(({is_active}) => is_active);
    const activeLocationDetails = activeLocation?.details?.latlng;
    const list = _.reduce(competitors, (competitorsList, competitor) => {

      const address = competitor.data?.formatted_address ? competitor.data?.formatted_address.split(',') : '';

      const firstLine = address.length ? address[0] : '';
      const secondLine = address.length ? `${address[1]}, ${address[2]}` : '';

      const latitude = competitor.data?.geometry?.location?.lat;
      const longitude = competitor.data?.geometry?.location?.lng;

      const current = {
        placeId: competitor.placeId,
        name: competitor.data?.name,
        firstLine,
        secondLine,
        active: competitor.status !== reputationStatusOptions.COMPETITOR_NOT_FOUND,
        latitude,
        longitude,
        distance: activeLocationDetails ? commonUtil.calculateDistanceBetweenPoints(activeLocationDetails.latitude, activeLocationDetails.longitude, latitude, longitude) : -1,
      }
      return [...competitorsList, current];
    }, []);

    return list;
  },

  getCompetitorsSearch: (competitorsSearch, locations) => {
    const activeLocation = locations?.find(({is_active}) => is_active)?.details?.latlng;

    const result = competitorsSearch && competitorsSearch.length ?
      _.reduce(competitorsSearch, (list, competitor) => {

        const address = competitor.formatted_address ? competitor.formatted_address.split(',') : '';

        const firstLine = address.length ? address[0] : '';
        const secondLine = address.length ? `${address[1]}, ${address[2]}` : '';
        const latitude = competitor.geometry?.location?.lat;
        const longitude = competitor.geometry?.location?.lng;

        const current = {
          placeId: competitor.place_id,
          name: competitor.name,
          firstLine,
          secondLine,
          distance: activeLocation ? commonUtil.calculateDistanceBetweenPoints(activeLocation.latitude, activeLocation.longitude, latitude, longitude) : -1,
        }
        return [...list, current];

      }, [])
    :
      [];

    return result;

  },

  filterCustomerPaymentInvoices: (invoices) => {
    return invoices.filter(({statusList, receipt_discount_name}) =>
      ![
        statusList.isPaid && !statusList.isZeroAmountInvoice,
        statusList.isPaid && statusList.isZeroAmountInvoice && receipt_discount_name?.includes('Loyalty Reward'),
        statusList.isCanceled
      ].some((flag) => flag));
  },

  filterCustomerPaidInvoiceTransactions: (invoices) => {

    return invoices ? invoices.filter((transaction) => transaction.inv_paid_date) : [];
  },

  filterPaymentMethodsAch: (paymentMethods, achEnabled) => {
    return achEnabled ? paymentMethods : paymentMethods?.filter(({type}) => type !== 'ach');
  },

  filterMerchantCategories: (merchantCategories = {}, visibilityFilter) => {

    const searchTerm = _.toUpper(visibilityFilter.search);
    let filteredMerchantCategories = merchantCategories.merchantCategories;

    if (searchTerm) {
      filteredMerchantCategories = filteredMerchantCategories?.filter(category =>
        _.toUpper(category?.description?.toString()).includes(searchTerm) ||
        _.toUpper(category?.mcc?.toString()).includes(searchTerm)
      );
    }

    merchantCategories.filteredMerchantCategories = filteredMerchantCategories;

    return merchantCategories;
  },

};

export default FilterUtil;
