/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

'use strict';

import React, { Component } from 'react';
import { setSubmitButtonEnabled } from '../../actions/userExperienceActions';
import { MenuItem } from '@mui/material';
import { connect } from 'react-redux';
import LabelUtil from '../util/LabelUtil';
import IconUtil from '../util/IconUtil';
import TextUtil from '../util/FormatTextUtil';
import Validator from '../util/Validator';
import { change, Field, getFormValues, reduxForm } from 'redux-form';
import FormUtil from './../util/FormUtil';
import _ from 'lodash';
import moment from 'moment';
import states from '../../constants/states';
import TextField from '../shared/TextField';
import Select from '../shared/Select';
import Tooltip from '../shared/Tooltip';
import CustomDatePicker from '../shared/DatePicker';

const label = LabelUtil.getLabel();

export const validate = values => {
  if (!_.isEmpty(values, label.abbreviation)) {
    return Validator.validateProfileForm(values);
  } else {
    return {};
  }
};

export class ProfileForm extends Component {

  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const enableSubmit = FormUtil.shouldEnableSubmit(nextProps);
    if (this.props?.userExperience?.enableFormSubmitButton !== enableSubmit) {
      this.props.dispatch(setSubmitButtonEnabled(enableSubmit));
    }
  }

  render() {
    const { handleSubmit, forms, initialValues, merchantSettings, disableFields, auth, t } = this.props;

    const maskPersonalInfo = !(!!((forms.data || {}).show_personal_info) || initialValues.show_personal_info || merchantSettings.showPersonalInfo || false);

    const label = LabelUtil.getLabel();
    const infoIcon = IconUtil.getIcon('InfoIcon', label.primaryColor);
    const todayMomentObject = moment();

    return (
      <form onSubmit={handleSubmit}>
        <div className='personalInformation'>
          <div className='formItems'>
            <Field
              hintText={t('ProfileForm.NameHint')}
              label={t('ProfileForm.NameLabel')}
              component={TextField}
              disabled={disableFields}
              className='halfScreen fullScreenRes'
              fullWidth={false}
              name='principal'
            />
            {disableFields ? <Field
                hintText={t('ProfileForm.DateOfBirthHint')}
                label={t('ProfileForm.DateOfBirthLabel')}
                component={TextField}
                disabled={disableFields}
                className='sixthScreen halfScreenRes'
                fullWidth={false}
                normalize={value => value && maskPersonalInfo ? 'xx/xx/xxxx' : value}
                format={value => value && maskPersonalInfo ? 'xx/xx/xxxx' : value}
                name='principal_dob'
              /> :
              <Field
                className='sixthScreen halfScreenRes'
                component={CustomDatePicker}
                disabled={disableFields}
                fullWidth={false}
                label={t('ProfileForm.DateOfBirthLabel')}
                name='principal_dob'
                maxDate={todayMomentObject}
                input={{
                  value: moment(initialValues.principal_dob),
                  onChange: (date) => this.props.dispatch(change('profileForm', 'principal_dob', moment(date))),
                }}
                t={t}
              />
            }
            <Field
              hintText={t('SsnHint')}
              label={t('Ssn')}
              component={TextField}
              disabled
              className='sixthScreen halfScreenRes'
              fullWidth={false}
              name='principal_ssn'
            />
            <Field
              label={t('ProfileForm.HideSensitiveDataLabel')}
              component={Select}
              disabled={disableFields || !auth.isOwner}
              className='sixthScreen fullScreenRes'
              name='show_personal_info'
              props={{
                style: { height: '48px' }
              }}
            >
              {[
                <MenuItem value={true} key={1}>{t('No')}</MenuItem>,
                <MenuItem value={false} key={2}>{t('Yes')}</MenuItem>,
              ]}
            </Field>
            <br/>
            <Field
              hintText={t('ProfileForm.AddressLabel')}
              label={t('ProfileForm.AddressHint')}
              component={TextField}
              disabled={disableFields}
              className='halfScreen fullScreenRes'
              fullWidth={false}
              name='principal_address'
            />
            <Field
              hintText={t('ProfileForm.SuiteAptHint')}
              label={t('ProfileForm.SuiteAptLabel')}
              component={TextField}
              disabled={disableFields}
              className='halfScreen fullScreenRes'
              fullWidth={false}
              name='principal_address_2'
            />
            <br/>
            <Field
              hintText={t('ProfileForm.CityHint')}
              label={t('ProfileForm.CityLabel')}
              component={TextField}
              disabled={disableFields}
              className='cityInput halfScreenRes halfScreenMediumDesktopRes'
              fullWidth={false}
              name='principal_city'
            />
            <Field
              label={t('ProfileForm.StateLabel')}
              component={Select}
              disabled={disableFields}
              className='stateInput halfScreenRes halfScreenMediumDesktopRes'
              name='principal_state'
              props={{
                style: { height: '48px' }
              }}
            >
              {states.content.map((state, i) => {
                return <MenuItem value={state} key={i}>{state}</MenuItem>
              })}
            </Field>
            <Field
              hintText={t('ProfileForm.ZipHint')}
              label={t('ProfileForm.ZipLabel')}
              component={TextField}
              disabled={disableFields}
              className='quarterScreen halfScreenRes halfScreenMediumDesktopRes'
              fullWidth={false}
              normalize={TextUtil.formatZip}
              name='principal_zip'
            />
            <div className='phoneContainer quarterScreen halfScreenRes halfScreenMediumDesktopRes'>
              <Field
                hintText={t('PhoneNumber')}
                label={t('PhoneNumber')}
                component={TextField}
                disabled={disableFields}
                className='hiddenData'
                fullWidth={false}
                normalize={TextUtil.formatPhoneNumber}
                name='principal_phone'
              />
              <div className='tooltipHolder'>
                <Tooltip
                  placement='left'
                  title={t('Tooltips.PhoneNumber')}
                >
                  {infoIcon}
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        <br/>
        <br/>
        <br/>
      </form>
    )
  }
}

export const Profile = reduxForm({
  form: 'profileForm',
  validate,
  fields: [
    'principal',
    'principal_address',
    'principal_address_2',
    'principal_city',
    'principal_phone',
    'principal_state',
    'principal_zip',
    'principal_phone',
    'principal_dob',
    'principal_ssn',
    'show_personal_info'
  ],
  enableReinitialize: true,
})(ProfileForm);

function mapStateToProps(state) {

  const currentValues = getFormValues('profileForm')(state) || {};
  const mask = '••• •• ••••'
  const regex = /••••/gi;
  const ssn = state.accountProfile?.data && state.accountProfile.data.principal_ssn ? mask.replace(regex, state.accountProfile.data.principal_ssn) : mask;

  const initialValues = {
    principal: state.accountProfile?.data && state.accountProfile.data.principal,
    principal_dob: '',
    principal_address: 'XXXXXXXXX',
    principal_address_2: 'XXXXXXXXX',
    principal_city: 'XXXXXXX',
    principal_state: '',
    principal_zip: 'XXXXX',
    principal_phone: '(XXX) XXX-XXXX',
    principal_ssn: mask
  };

  initialValues.show_personal_info = !!(state.merchantSettings?.showPersonalInfo || false);

  if (state.verification?.verified && state.accountProfile.data) {
    initialValues.principal_phone = TextUtil.formatPhoneNumber(state.accountProfile.data.principal_phone);
    initialValues.principal_ssn = ssn;
    initialValues.principal_dob = state.verification.fullInfo && moment(state.verification.fullInfo.principal_dob, 'MM/DD/YYYY').toDate();
    initialValues.principal_address = state.accountProfile.data.principal_address;
    initialValues.principal_address_2 = state.accountProfile.data.principal_address_2;
    initialValues.principal_city = state.accountProfile.data.principal_city;
    initialValues.principal_state = state.accountProfile.data.principal_state;
    initialValues.principal_zip = state.accountProfile.data.principal_zip;
  }

  return {
    initialValues,
    currentValues,
    user: state.user
  }

}

export default connect(mapStateToProps)(Profile);
