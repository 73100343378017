/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */
export default {
  visa: [
    { code: '10.1', description: 'EMV Liability Shift Counterfeit Fraud' },
    { code: '10.2', description: 'EMV Liability Shift Non-Counterfeit Fraud' },
    { code: '10.3', description: 'Other Fraud-Card Present Environment' },
    { code: '10.4', description: 'Other Fraud-Card Absent Environment' },
    { code: '10.5', description: 'Visa Fraud Monitoring Program' },
    { code: '11.1', description: 'Card Recovery Bulletin' },
    { code: '11.2', description: 'Declined Authorization' },
    { code: '11.3', description: 'No Authorization' },
    { code: '12.1', description: 'Late Presentment' },
    { code: '12.2', description: 'Incorrect Transaction Code' },
    { code: '12.3', description: 'Incorrect Currency' },
    { code: '12.4', description: 'Incorrect Account Number' },
    { code: '12.5', description: 'Incorrect Amount' },
    { code: '12.6.1', description: 'Duplicate Processing' },
    { code: '12.6.2', description: 'Paid by Other Means' },
    { code: '13.1', description: 'Merchandise/Services Not Received' },
    { code: '13.2', description: 'Cancelled Recurring' },
    {
      code: '13.3',
      description: 'Not as Described or Defective Merchandise/Services'
    },
    { code: '13.4', description: 'Counterfeit Merchandise' },
    { code: '13.5', description: 'Misrepresentation' },
    { code: '13.6', description: 'Credit Not Processed' },
    { code: '13.7', description: 'Cancelled Merchandise/Services' },
    { code: '13.8', description: 'Original Credit Transaction Not Accepted' },
    {
      code: '13.9',
      description: 'Non-Receipt of Cash or Load Transaction Value'
    },
    { code: 'M10.4', description: 'RDR-Other Fraud-Card Absent Environment' },
    { code: 'M11.3', description: 'RDR-No Authorization' },
    { code: 'M12.1', description: 'RDR-Late Presentment' },
    { code: 'M12.6', description: 'RDR-Processing Error' },
    { code: 'M13.1', description: 'RDR-Merchandise/Services Not Received' },
    { code: 'M13.2', description: 'RDR-Cancelled Recurring' },
    { code: 'M13.3', description: 'RDR-Not as Described or Defective' },
    { code: 'M13.5', description: 'RDR-Misrepresentation' },
    { code: 'M13.6', description: 'RDR-Credit Not Processed' },
    { code: 'M13.7', description: 'RDR-Cancelled Merchandise/Services' }
  ],
  mastercard: [
    { code: '4534', description: 'Duplicate processing (chrgbk) - cr' },
    { code: '4541', description: 'Automatic Payment -cr' },
    { code: '4553', description: 'C/M disputes mdse/srvcs rndrd (chrgbk)' },
    { code: '4752', description: 'UNRECOGNIZED TRAN CHRGBK -SALE' },
    { code: '4753', description: 'Invalid cardmember accnt - rtn chrgbk' },
    { code: '4755', description: 'Non-receipt of goods (cchrgbk) - cr' },
    { code: '4807', description: 'Warning Bulletin' },
    {
      code: '4808',
      description: 'Requested/Required Authorization Not Obtained'
    },
    { code: '4812', description: 'Account Number Was Not on File' },
    { code: '4831', description: 'Transaction Amount Differs' },
    { code: '4834', description: 'Duplicate Processing' },
    { code: '4837', description: 'No Cardholder Authorization' },
    { code: '4841', description: 'Cancelled Recurring Transaction' },
    { code: '4849', description: 'Questionable Merchant Activity' },
    {
      code: '4853',
      description: 'Cardholder Dispute, Defective/Not as Described'
    },
    { code: '4855', description: 'Non-Receipt of Merchandise' },
    { code: '4860', description: 'Credit Not Processed' },
    { code: '4863', description: 'AUTH NON-COMPLIANCE CHRGBK -SALE' },
    { code: '4865', description: 'C/M dspte mdse/srvcs rendered (chrgbk)' },
    { code: '4866', description: 'Unauthorized purchase - cr' },
    { code: '4870', description: 'Chip Liability Shift' },
    { code: '4871', description: 'Chip/Pin Liability Shift' }
  ],
  amex: [
    { code: 'A01', description: 'Charge Amount Exceeds Authorization Amount' },
    { code: 'A02', description: 'No Valid Authorization' },
    { code: 'A08', description: 'Authorization Approval Expired' },
    { code: 'C02', description: 'Credit Not Processed' },
    { code: 'C04', description: 'Goods/Services Returned or Refused' },
    { code: 'C05', description: 'Goods/Services Cancelled' },
    { code: 'C08', description: 'Goods/Services Not or Partially Received' },
    { code: 'C14', description: 'Paid by Other Means' },
    { code: 'C18', description: '\'No Show\' or CARDeposit Canceled' },
    { code: 'C28', description: 'Cancelled Recurring Billing' },
    { code: 'C31', description: 'Goods/Services Not As Described' },
    { code: 'C32', description: 'Goods/Services Damaged or Defective' },
    { code: 'M10', description: 'Vehicle Rental - Capital Damages' },
    { code: 'M49', description: 'Vehicle Rental - Theft or Loss of Use' },
    { code: 'FR2', description: 'Fraud Full Recourse Program' },
    { code: 'FR4', description: 'Immediate Chargeback Program' },
    { code: 'FR6', description: 'Partial Immediate Chargeback Program' },
    { code: 'F10', description: 'CH Claims Unauthorized - Not Swiped/Dipped' },
    { code: 'F14', description: 'Missing Signature' },
    { code: 'F24', description: 'No Card Member Authorization' },
    { code: 'F29', description: 'Card Not Present' },
    { code: 'F30', description: 'EMV - Counterfeit' },
    { code: 'F31', description: 'EMV List/Stolen/Non-Received' }
  ],
  discover: [
    { code: '4534', description: 'Duplicate Processing' },
    { code: '4541', description: 'Cancelled Recurring Transaction' },
    { code: '4542', description: 'Late Presentation' },
    { code: '4550', description: 'Credit/Debit Posted Incorrectly' },
    {
      code: '4553',
      description: 'Cardholder Disputes Quality of Goods or Services'
    },
    { code: '4586', description: 'Altered Amount' },
    { code: '4752', description: 'Does Not Recognize' },
    { code: '4753', description: 'Invalid Cardholder Number' },
    { code: '4755', description: 'Non-Receipt of Goods or Services' },
    { code: '4762', description: 'Good Faith Investigation' },
    { code: '4863', description: 'Authorization Non-Compliance' },
    { code: '4864', description: 'Non-Receipt of Cash from ATM' },
    { code: '4865', description: 'Paid by other means' },
    { code: '4866', description: 'Fraud - Chip Card Counterfeit Transaction' },
    { code: '4867', description: 'Fraud - Chip Card and PIN Transaction' },
    { code: '7010', description: 'Fraud - Card Present Transaction' },
    { code: '7030', description: 'Fraud - Card Not Present Transaction' },
    { code: '8002', description: 'Credit Not Processed' },
    { code: 'AA', description: 'Cardholder Does Not Recognize' },
    { code: 'AP', description: 'Canceled Recurring Transaction' },
    { code: 'AW', description: 'Altered Amount' },
    { code: 'CD', description: 'Credit Posted as Card Sale' },
    { code: 'DP', description: 'Duplicate Processing' },
    { code: 'IC', description: 'Illegible Sales Data' },
    { code: 'NF', description: 'Non-Receipt of Cash From ATM' },
    { code: 'PM', description: 'Paid by Other Means' },
    { code: 'RG', description: 'Non-Receipt of Goods or Services' },
    { code: 'RM', description: 'Quality Discrepancy' },
    { code: 'RN2', description: 'Credit Not Received' },
    { code: 'AT', description: 'Authorization Non-Compliance' },
    { code: 'DA', description: 'Declined Authorization' },
    { code: 'EX', description: 'Expired Card' },
    { code: 'NA', description: 'No Authorization Request' },
    { code: 'IN', description: 'Invalid Card Number' },
    { code: 'LP', description: 'Late Presentment' },
    { code: 'NC', description: 'Not Classified' },
    { code: 'UA01', description: 'Fraud/Card Present Environment' },
    { code: 'UA02', description: 'Fraud/Card-Not-Present Environment' },
    { code: 'UA05', description: 'Fraud/Counterfeit Chip Transaction' },
    { code: 'UA06', description: 'Fraud/Chip-and-PIN Transaction' },
    { code: 'UA10', description: 'Request Transaction Receipt (swiped card)' },
    {
      code: 'UA11',
      description: 'Cardholder Claims Fraud (swiped, no signature)'
    },
  ]
};
