/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, {Component} from 'react';

export default class MasterCardPaymentMethod extends Component {

  render() {
    return (
      <svg id='MasterCardPaymentMethod' xmlns='http://www.w3.org/2000/svg' width='100' height='60' viewBox='0 0 100 60' className='masterCardPaymentMethod'>
        <defs>
          <clipPath id='clipPath'>
            <path id='Path_12713' data-name='Path 12713' d='M0-55.993A4.008,4.008,0,0,1,4-60H96a4,4,0,0,1,4,4.007V-4.007A4.008,4.008,0,0,1,96,0H4A4,4,0,0,1,0-4.007Z' clipRule='evenodd'/>
          </clipPath>
          <clipPath id='clipPath-2'>
            <path id='Path_12712' data-name='Path 12712' d='M-290,1226H710V-224H-290Z'/>
          </clipPath>
        </defs>
        <g id='Group_20266' data-name='Group 20266' transform='translate(-644 -738)'>
          <g id='Group_20261' data-name='Group 20261'>
            <g id='Group_20259' data-name='Group 20259' transform='translate(644 798)'>
              <g id='Group_20218' data-name='Group 20218' clipPath='url(#clipPath)'>
                <g id='Group_20217' data-name='Group 20217' clipPath='url(#clipPath-2)'>
                  <path id='Path_12711' data-name='Path 12711' d='M-5-65H105V5H-5Z'/>
                </g>
              </g>
            </g>
            <g id='Group_20260' data-name='Group 20260' transform='translate(-114 52)'>
              <rect id='Rectangle_3258' data-name='Rectangle 3258' width='37' height='37' rx='18.5' transform='translate(778 698)' fill='#eb041c'/>
              <path id='Exclusion_1' data-name='Exclusion 1' d='M13200.5,5182a18.423,18.423,0,0,1-11.5-4.008,18.676,18.676,0,0,0,5.1-6.316,18.538,18.538,0,0,0,0-16.352,4.29,4.29,0,1,0-7.6-3.919,18.5,18.5,0,1,1,14,30.6Z' transform='translate(-12381 -4447)' fill='#f79f1a'/>
              <path id='Intersection_2' data-name='Intersection 2' d='M13205,5163.5a8.937,8.937,0,1,1,7,14.493A18.463,18.463,0,0,1,13205,5163.5Z' transform='translate(-12404 -4447)' fill='#fe5f04'/>
            </g>
          </g>
        </g>
      </svg>
    );
  }
};
