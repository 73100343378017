/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, FieldArray, Field } from 'redux-form';
import FileInput from './FileInput';
import CustomTextField from './shared/TextField';
import UploadedFiles from './shared/UploadedFiles';
import IconUtil from './util/IconUtil';
import LabelUtil from './util/LabelUtil';
import Validator from './util/Validator';
import PropTypes from 'prop-types';
import withTranslate from '../hoc/withTranslate';

export const AttachFilesFormType = {
  DISPUTE: 'DISPUTE',
  GIACT: 'GIACT',
  LOGO: 'LOGO',
  OTHER: 'OTHER'
};

const validate = (values, props) => {
  if (props.displayType === AttachFilesFormType.GIACT) {
    return Validator.validateBankVerificationImages(values, props);
  }
  if (props.displayType === AttachFilesFormType.DISPUTE) {
    return Validator.validateDisputeFile(values);
  }
};


export class AttachFilesFormComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      errorMessage: false
    };

    this.onDropRejected = this.onDropRejected.bind(this);
    this.onDropAccepted = this.onDropAccepted.bind(this);
  }

  onDropRejected() {
    this.setState({errorMessage: true});
  }

  onDropAccepted() {
    this.setState({errorMessage: false});
  }

  render() {
    const {handleSubmit, dropzoneOptions, label, filesCopy, insideForm, clearingType, secondaryText, t} = this.props;
    const isGiactFiles = this.props.displayType === AttachFilesFormType.GIACT;
    const isDisputeFiles = this.props.displayType === AttachFilesFormType.DISPUTE;
    const isLogoFile = this.props.displayType === AttachFilesFormType.LOGO;

    const color = LabelUtil.getLabelColor();
    const icon = IconUtil.getIcon('ImgUploadIcon', '#404143');

    const errorMessageText = isGiactFiles
      ? t('AttachFilesFormError.GiactExceedsLimit')
      : t('AttachFilesFormError.ExceedsLimit');

    const renderFiles = ({ fields }) => {

      const allFields = fields.getAll();

      const attachedFilesGiact = allFields ? allFields.map((f, i) => {
        return (
          <div className='attachments' key={i}>
            <div className='attachmentIcon'>
              <span> {IconUtil.getIcon('AttachmentIcon', '#888C8D', null)}</span>
            </div>
            <div className='title'>
              <span>{f.name}</span>
              <span className='removeIcon'
                    onClick={() => fields.remove(i)}> {IconUtil.getIcon('CancelIcon', '#888C8D', '12px')}</span>
            </div>
          </div>
        );
      }) : null;

      const attachedFilesNonGiact = !isLogoFile
      ? <div>
          <p className='fileInputLabel'>{t('AttachFilesFormInputLabel')}</p>
          <UploadedFiles items={fields} attachForm={true}/>
        </div>
      : null;

      return (
        <div>
          <FileInput
            name='dropzone'
            label={label}
            classNameLabel='fileInputLabel'
            className='fileInput'
            onDropRejected={this.onDropRejected}
            onDropAccepted={this.onDropAccepted}
            onDropFunction={(acceptedFiles, rejectedFiles, e) => {
              acceptedFiles.forEach(f => {
                fields.push({
                  name: isDisputeFiles ? Validator.validatesFileName(f.name) : f.name,
                  file: f,
                  description: '',
                  clearing: isGiactFiles ? clearingType : ''
                })
              });
              if(isLogoFile) {
                this.props.logoSetState(acceptedFiles);
              }
            }}
            dropzoneOptions={dropzoneOptions}
          >
            <div className='dropZoneArea'>
              <div className='iconHolder'>
                <span>{icon}</span>
                <p className='iconHolderText'>{t('IconHolderText')}</p>
              </div>
              <p className='browse' style={{color: color}}>{filesCopy || t('Browse')}</p>
              {secondaryText && <p className='secondaryText'>{secondaryText}</p>}
            </div>
          </FileInput>

          {isDisputeFiles &&
          <p className='acceptedFiles'>{t('AcceptedFiles')}.</p>}

          {this.state.errorMessage &&
          <p className='errorMsg'>{errorMessageText}</p>}

          {allFields
            ? isGiactFiles
              ? attachedFilesGiact
              : attachedFilesNonGiact
            : null
          }
        </div>
      );
    };

    const fieldItem = <div>
      <Field component={({meta}) =>
        <div>{meta.error && meta.submitFailed &&
        <span className='errorText'>{t(meta.error)}<br/><br/></span>}</div>
      } name='verificationCount' className={isGiactFiles ? '' : 'ErrorMessageField'}/>

      <FieldArray name='files' component={renderFiles}/>

      {isDisputeFiles ?
        <div>
          <p className='fileInputLabel'>{t('DisputesFilesInputLabel')}</p>
          <Field
            component={CustomTextField}
            fullWidth={true}
            maxLength='1000'
            name='comment'
          />
        </div> : null}
    </div>;

    return (insideForm && !isGiactFiles)
      ? <div className='attachFilesForm'>
          {fieldItem}
        </div>
      : <form onSubmit={handleSubmit} className='attachFilesForm'>
          {fieldItem}
        </form>;
  }

}

export const AttachFilesFormContainer = reduxForm({
  form: 'attachFilesForm',
  validate,
  fields: ['files', 'comment']
})(AttachFilesFormComponent);

export function mapStateToProps(state, ownProps) {
  return {}
}

export default connect(mapStateToProps)(withTranslate(AttachFilesFormContainer));

AttachFilesFormComponent.propTypes = {
  displayType: PropTypes.oneOf([
    AttachFilesFormType.DISPUTE,
    AttachFilesFormType.GIACT,
    AttachFilesFormType.LOGO,
    AttachFilesFormType.OTHER,
  ]).isRequired
};
