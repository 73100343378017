import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import numeral from 'numeral';
import _ from 'lodash';
import Trend from './Trend';

export default class MobileTable extends Component {

  constructor(props) {
    super(props);
  }

  render() {

    const table = this.props.rows.map(row => _.zipObject(this.props.headers, row));
    return (
      <Table className='mobileTable'>
        <TableBody>
          {
            table.map(cell => Object.keys(cell).map((c, i) =>
              (c === 'CHANGE' ?
                  <TableRow key={`${c.name}_${i}`} className={(this.props.compared) ? '' : 'hideComponent'}>
                    <TableCell>
                      <span className='tableHeader'>{c}</span>
                      <span className='tableData'><Trend difference={cell[c]} /></span>
                    </TableCell>
                  </TableRow> :
                  <TableRow key={`${c.name}_${i}`}>
                    <TableCell>
                  <span
                    className={(i % this.props.headers.length === 0 ? 'tableHeader tableFirst' : 'tableHeader')}>{c}</span>
                      <span
                        className='tableData'>{(c === 'TRANSACTIONS' || c === 'NAME' || c === 'QUANTITY') ? cell[c] : numeral(cell[c]).format('$0,0.00')}</span>
                    </TableCell>
                  </TableRow>
              )))
          }
        </TableBody>
      </Table>)

  }

}

MobileTable.propTypes = {
  headers: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  compared: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
};
