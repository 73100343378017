/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

'use strict';

import _ from 'lodash';

const base64regex = new RegExp('^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$');
const maxFileSize = 2000000; //2MB
// This could be tested by using Cavas: npm install Canvas and sending a Canvas image from the mocha test
// however the canvas module installation it is complicated and has a lot of errors
// the steps to fix theses issues are specific to the OS, so it will add a lot of complexity to the dev env, and the CI cycle.


const getImage = (imageUrl) => {
  return new Promise(
    (resolve, reject) => {
      let response = {
        data: null,
        message: ''
      };
      if (imageUrl && !_.isUndefined(imageUrl) && !_.isNull(imageUrl) && _.isString(imageUrl)) {
        getBase64(imageUrl).then(res => {
          if (base64regex.test(res.data)) {
            if (res.data.length > maxFileSize) {
              response.message = 'image is larger than limit';
              resolve(response);
            } else {
              response.data = res.data;
              response.message = 'image downloaded succesfully';
              resolve(response);
            }
          } else {
            response.message = 'has an invalid image';
            resolve(response);
          }
        })
          .catch(err => {
            response.message = err.message;
            resolve(response);
          });
      } else {
        if (!!imageUrl){ //Will not add error message if imageUrl was left empty
          response.message = 'has an invalid image url';
        }
        resolve(response);
      }
    }
  );
};

/* istanbul ignore next */
const crop = (image, crop) => {
  let imageWidth = image.naturalWidth;
  let imageHeight = image.naturalHeight;
  let cropX = (crop.x / 100) * imageWidth;
  let cropY = (crop.y / 100) * imageHeight;
  let cropWidth = (crop.width / 100) * imageWidth;
  let cropHeight = (crop.height / 100) * imageHeight;
  let canvas = document.createElement('canvas');
  canvas.width = cropWidth;
  canvas.height = cropHeight;
  let ctx = canvas.getContext('2d');
  ctx.drawImage(image, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
  let data = canvas.toDataURL('image/jpeg');
  return data;
};

/* istanbul ignore next */
const getBase64 = (url) => {
  return new Promise(
    (resolve, reject) => {

      let response = {
        data: null,
        message: ''
      };

      let reader = new FileReader();
      let xhr = new XMLHttpRequest();

      xhr.onload = () => {

        reader.onloadend = () => {
          response.data = reader.result.split(',')[1];
          response.message = 'Got base64 image succesfully';
          resolve(response);
        };

        reader.onerror = () => {
          response.message = 'Error reading image';
          reject(response);
        };

        reader.readAsDataURL(xhr.response);

      };

      xhr.onerror = () => {
        response.message = 'Error downloading image';
        reject(response);
      };

      if ('withCredentials' in xhr) {
        xhr.open('GET', url, true);
      } else if (typeof XDomainRequest != 'undefined') {
        xhr = new XDomainRequest();
        xhr.open('GET', url);
      } else {
        response.message = 'Cors not supported';
        reject(response);
      }

      xhr.responseType = 'blob';
      xhr.send();

    });
};

const ImageUtil = {
  getImage: getImage,
  crop: crop,
  getBase64: getBase64
};

export default ImageUtil;