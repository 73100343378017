/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';

export default function FeatureIcon({style}) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg'
         viewBox='0 -960 960 960'
         style={style}>
      <path
        className='FeatureIcon'
        d='m137.233-185.386 130.998-364.227q4.616-11.846 13.962-18.077 9.346-6.231 20.192-6.231 7.231 0 13.461 2.423 6.231 2.423 11.847 8.039l234.612 234.613q5.616 5.615 8.039 11.846 2.423 6.23 2.423 13.461 0 10.846-6.231 20.192t-18.077 13.962L184.233-138.387q-10.847 4.231-20.885 1.308-10.038-2.923-17.269-10.154-7.231-7.231-10.154-17.269-2.923-10.038 1.308-20.884ZM893.69-668.999q-7.076 7.461-16.576 7.461t-16.577-7.077l-2.615-2.615q-16.308-16.308-39.616-16.308T778.69-671.23L576.075-468.615q-7.077 7.077-16.577 7.269-9.499.193-16.961-7.269-7.461-7.461-7.461-16.769 0-9.307 7.461-16.768l202.615-202.616q30.077-30.077 72.769-30.077 42.693 0 72.769 30.077l3 3q7.077 7.077 7.077 16.384 0 9.308-7.077 16.385Zm-498.536-115q7.461-7.461 16.768-7.461 9.308 0 16.769 7.461l8.462 8.462q32 32 32 76.769 0 44.769-32 76.769l-7.231 7.232q-7.077 7.076-16.576 7.269-9.5.192-16.962-7.269-7.461-7.462-7.461-16.769 0-9.308 7.461-16.769l7.231-7.231q17.847-17.847 17.847-43.232 0-25.385-17.847-43.231l-8.461-8.462q-7.077-7.077-7.27-16.577-.192-9.499 7.27-16.961Zm154.307-69.23q7.461-7.461 16.769-7.461 9.307 0 16.769 7.461l42.615 42.615q30.461 30.462 30.461 73.154 0 42.692-30.461 73.154L502.999-541.691q-7.077 7.077-16.577 7.269-9.5.192-16.961-7.269-7.462-7.461-7.462-16.769 0-9.307 7.462-16.769l122.615-122.615q16.308-16.308 16.308-39.616t-16.308-39.616l-42.615-42.615q-7.077-7.077-7.269-16.577-.193-9.499 7.269-16.961Zm284.612 457.691q-7.461 7.461-16.768 7.461-9.308 0-16.769-7.461l-31.076-31.076q-18.231-18.231-44.616-18.231-26.386 0-44.617 18.231l-31.076 31.076q-7.076 7.077-16.576 7.269-9.5.192-16.961-7.269-7.462-7.462-7.462-16.769 0-9.308 7.462-16.769l31.076-31.076q32.384-32.385 78.154-32.385 45.769 0 78.153 32.385l31.076 31.076q7.077 7.077 7.27 16.577.192 9.499-7.27 16.961Z'/>
    </svg>
  );
};
