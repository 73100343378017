/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PaymentMethodSummary from '../shared/enhancedInvoices/PaymentMethodSummary';
import Modal from '../shared/Modal';
import IconUtil from '../util/IconUtil';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import FilterUtil from '../util/FilterUtil';

class PaymentMethodSelect extends Component {
  
  state = {
    isPaymentMethodsModalOpen: false
  };

  getFormattedPaymentMethodList = () => {
    const { customer, achEnabled } = this.props;
    const paymentMethods = FilterUtil.filterPaymentMethodsAch(customer?.payment_methods, achEnabled);

    if (paymentMethods?.length > 0) {
      const defaultPaymentMethodIndex = paymentMethods.map(paymentMethod => paymentMethod.is_default).indexOf(true);
      if (defaultPaymentMethodIndex !== -1 && defaultPaymentMethodIndex > 0) {
        const defaultPaymentMethod = paymentMethods[defaultPaymentMethodIndex];
        paymentMethods.splice(defaultPaymentMethodIndex, 1);
        paymentMethods.unshift(defaultPaymentMethod);
      }
    }

    return paymentMethods;
  };

  setPaymentMethod = (value) => {
    const { customer, setPaymentMethod } = this.props;
    const paymentMethods = customer?.payment_methods;

    const selectedPaymentMethod = paymentMethods.find(paymentMethod => paymentMethod.id === value);
    setPaymentMethod(selectedPaymentMethod);
    this.closePaymentMethodsModal();
  };

  openPaymentMethodsModal = () => {
    this.setState({ isPaymentMethodsModalOpen: true });
  };

  closePaymentMethodsModal = () => {
    this.setState({ isPaymentMethodsModalOpen: false });
  };

  enterCardManually = () => {
    const { enterCardManually } = this.props;
    enterCardManually();
    this.closePaymentMethodsModal();
  };

  sendToCustomer = () => {
    const { sendToCustomer } = this.props;

    sendToCustomer();
    this.closePaymentMethodsModal();
  };
  
  useBankingAccount = () => {
    const { useBankingAccount } = this.props;
    useBankingAccount();
    this.closePaymentMethodsModal();
  }

  useCardReader = () => {
    const { toggleCardPresent, isUsingCardReader} = this.props;
    if (!isUsingCardReader) {
      toggleCardPresent();
    }
    this.closePaymentMethodsModal();
  };

  render() {
    const {
      selectedPaymentMethod,
      isEnteringCardManually,
      isUsingCardReader,
      isBankingAccount,
      isIssueCredit,
      isSendToCustomer,
      sendToCustomer,
      toggleCardPresent,
      isPremiumPlusAccount,
      error,
      achEnabled,
      t
    } = this.props;
    
    const {
      isPaymentMethodsModalOpen
    } = this.state;
    
    const paymentMethods = this.getFormattedPaymentMethodList();
    const hasPaymentMethods = paymentMethods?.length > 0;
    
    let selectedPaymentMethodInputText = null;
    
    if (isUsingCardReader) {
      selectedPaymentMethodInputText = t('UseCardReader');
    } else if (isEnteringCardManually) {
      selectedPaymentMethodInputText = t('EnterCardManually');
    } else if (isSendToCustomer) {
      selectedPaymentMethodInputText = t('SendToCustomer');
    } else if (isBankingAccount) {
      selectedPaymentMethodInputText = t('AddBankingAccount');
    }
    
    let selectedPaymentMethodInput = (
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        width='100%'>
        <Typography>
          {selectedPaymentMethodInputText || t('NoPaymentMethodsAdded')}
        </Typography>
        <IconButton>
          {IconUtil.getIcon('ArrowDropdownToggle', '#808080')}
        </IconButton>
      </Stack>
    );
    
    if (!selectedPaymentMethodInputText && hasPaymentMethods && !!selectedPaymentMethod) {
      selectedPaymentMethodInput = (
        <PaymentMethodSummary
          paymentMethod={selectedPaymentMethod}
          backgroundColor='#FFF'
          showEditButton={false}
          showDefault={!!selectedPaymentMethod?.is_default}
          onIconButtonClick={this.openPaymentMethodsModal}
          t={t}
        />
      );
    }
    
    const rowStyle = {cursor: 'pointer'};
    
    return (
      <Box
        id='paymentMethodSelect'
        marginBottom='28px'>
        <InputLabel
          sx={{
            margin: '0 0 7px 15px',
            fontSize: '13px',
            fontFamily: 'interSemiBold'
          }}>
          {t('PaymentMethod')}
        </InputLabel>
        <Box
          id='optionHolder'
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '0 6px 0 15px',
            borderWidth: '1px',
            borderColor: error ? 'red' : 'common.lineColor3',
            borderRadius: '4px',
            borderStyle: 'solid',
            minHeight: '48px',
            cursor: 'pointer'
          }}
          onClick={this.openPaymentMethodsModal}>
          {selectedPaymentMethodInput}
        </Box>
        {!!error &&
          <Typography
            id='errorMsg'
            color='red'
            fontSize='12px'
            margin='5px 0 0 15px'
            fontFamily='interSemiBold'>
            {t(error)}
          </Typography>}
        <Modal
          cancelText={t('Cancel')}
          onClose={this.closePaymentMethodsModal}
          open={isPaymentMethodsModalOpen}
          title={t('SelectPaymentMethod')}
          hideConfirmButton={true}>
          <TableContainer>
            <Table>
              <TableBody>
                {sendToCustomer &&
                  <TableRow
                    sx={rowStyle}
                    selected={isSendToCustomer}
                    onClick={this.sendToCustomer}>
                    <TableCell>
                      {t('SendToCustomer')}
                    </TableCell>
                  </TableRow>}
                <TableRow
                  sx={rowStyle}
                  selected={isEnteringCardManually}
                  onClick={this.enterCardManually}>
                  <TableCell>
                    {t('EnterCardManually')}
                  </TableCell>
                </TableRow>
                {achEnabled && <TableRow
                  sx={rowStyle}
                  selected={isBankingAccount}
                  onClick={this.useBankingAccount}>
                  <TableCell>
                    {t('AddBankingAccount')}
                  </TableCell>
                </TableRow>}
                {toggleCardPresent &&
                  <TableRow
                    sx={rowStyle}
                    selected={isUsingCardReader}
                    onClick={this.useCardReader}>
                    <TableCell>
                      {t('UseCardReader')}
                    </TableCell>
                  </TableRow>}
                {isPremiumPlusAccount && hasPaymentMethods && !isIssueCredit && (
                  paymentMethods.map(paymentMethod =>
                    <TableRow
                      key={paymentMethod.id}
                      sx={rowStyle}
                      selected={selectedPaymentMethod?.id === paymentMethod.id}
                      onClick={() => this.setPaymentMethod(paymentMethod.id)}>
                      <TableCell>
                        <PaymentMethodSummary
                          paymentMethod={paymentMethod}
                          showEditButton={false}
                          showDefault={!!paymentMethod?.is_default}
                          onIconButtonClick={() => this.setPaymentMethod(paymentMethod.id)}
                          achEnabled={achEnabled}
                          table={true}
                          t={t}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Modal>
      </Box>
    );
  }
}

export const mapStateToProps = (state) => ({
  user: state.user,
  customer: state.customers?.selectedCustomer,
});

export {PaymentMethodSelect as ComponentUnderTest};
export default connect(mapStateToProps)(PaymentMethodSelect);
