import { CALL_API } from '../middleware/api';
import actionTypes from '../constants/actionTypes';
import moment from 'moment-timezone';
import LabelUtil from '../components/util/LabelUtil';
import _ from 'lodash';
import UserUtil from '../components/util/UserUtil';
import ShoppingCartUtil from '../components/util/ShoppingCartUtil';
import numeral from 'numeral';
import FormatTextUtil from '../components/util/FormatTextUtil';
import InvoiceUtil, {PaymentMethod} from '../components/util/InvoiceUtil';
import { roundToTwoDecimals, handleNegative, uuidUtil } from '../components/util/CommonUtil';
import emailValidator from 'email-validator';
import Validator from '../components/util/Validator';
import applicationConstants from '../constants/applicationConstants';

export function getTransactionReceipts(user, dateRange, allAccounts, type, historicHardwareMode, pageNumber, count, largeCsvExport, declined = false, open = false, openAutoSigned = false, invoiceId = null, seriesId = null, paCustomerId = null, customerId = null, paymentLinkId = null, isGiftCardActivationsFilter = false, bric = null, lastTransactionDate = null) {

  let query = '';
  const mode = historicHardwareMode ? 'h' : '';

  if (type === 'batch') {
    query = '?type=ob';
  } else {
    query = `?verbose=${type === 'verbose'}`;

    if (type === 'void' && !!historicHardwareMode) {
      query = '?type=void';
    } else if(type === 'raw') {
      query = '?type=raw';
    }

    if (!invoiceId && !seriesId && !paymentLinkId) {
      const hasDateRange = dateRange && dateRange.value !== 'all';
      const formattedStartDate = lastTransactionDate ? moment(dateRange?.startDate).utc().format() : moment(dateRange?.startDate).tz('America/New_York').format();
      const formattedEndDate = lastTransactionDate ? moment(dateRange?.endDate).utc().format() : moment(dateRange?.endDate).tz('America/New_York').format();
      query = hasDateRange ?
        `${query}&start=${formattedStartDate}&end=${formattedEndDate}&allAccounts=${allAccounts}` :
        `${query}&allAccounts=${allAccounts}`;
    }
  }

  query = query + '&declinedonly=' + declined;

  if (open) {
    query = query + '&openonly=' + open;
  } else if (openAutoSigned) {
    query = query + '&filterBy=portalMissingTips';
  } else if (isGiftCardActivationsFilter) {
    query = query + '&filterBy=giftCardActivity';
  }

  if (paCustomerId) {
    query = query + '&paCustomerId=' + paCustomerId;
  } else if (customerId){
    query = query + '&customerId=' + customerId;
  } else if (paymentLinkId){
    query = query + '&paymentLinkId=' + paymentLinkId;
  }

  if (invoiceId) {
    query = `${query}&invoiceId=${invoiceId}`;
  } else if (seriesId) {
    query = `${query}&seriesId=${seriesId}`;
  } else if (bric) {
    query = `${query}&bric=${bric}`;
  }

  query = query + '&mode=' + mode;
  query = pageNumber ? query + '&page=' + pageNumber : query;
  query = count ? query + '&count=' + count : query;

  const endpoint = 'users/accounts/' + user.selectedMerchantAccount + '/transactions' + query;

  const actionTypesForRequest = type === 'comparison' ? [actionTypes.comparedReceiptsRequest, actionTypes.comparedReceiptsSuccess, actionTypes.comparedReceiptsFailure] :
    type === 'verbose' ? [actionTypes.transactionVerboseRequest, actionTypes.transactionVerboseSuccess, actionTypes.transactionVerboseFailure] :
      largeCsvExport ? [actionTypes.transactionCsvExportRequest, actionTypes.transactionCsvExportSuccess, actionTypes.transactionCsvExportFailure] :
        declined ? [actionTypes.transactionDeclinedReceiptsRequest, actionTypes.transactionDeclinedReceiptsSuccess, actionTypes.transactionDeclinedReceiptsFailure] :
          (open || openAutoSigned) ? [actionTypes.transactionOpenReceiptsRequest, actionTypes.transactionOpenReceiptsSuccess, actionTypes.transactionOpenReceiptsFailure] :
            [actionTypes.transactionReceiptsRequest, actionTypes.transactionReceiptsSuccess, actionTypes.transactionReceiptsFailure];

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: actionTypesForRequest
    }
  };

}

export function updateCustomerTransaction(customer, transaction, user) {

  const endpoint = `merchant/${user.selectedMerchantAccount}/transactions-receipt/update/${transaction.id}`;

  const body = {
    'merchant_customer_id': customer.is_pa_customer ? null : customer.id,
    'pa_customer_id': customer.is_pa_customer ? customer.id : null,
    'type': transaction.type
  };

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'PATCH',
      authenticated: true,
      types: [actionTypes.transactionUpdateRequest, actionTypes.transactionUpdateSuccess, actionTypes.transactionUpdateFailure],
      body
    }
  };
}

export function updateTipTransaction(transactions, user) {
  const payload = transactions.map((transaction) => {
    const unFormattedTip = numeral(transaction.tip_amount).value();
    const tipAmount = unFormattedTip && unFormattedTip > 0 ? parseFloat(unFormattedTip) : 0;
    const requestId = user && user.data && generateRequestId(tipAmount, user, 'invoice');

    return {
      request_id: requestId,
      sale_id: transaction.id,
      amount: tipAmount,
      uniq_id: transaction.uniq_id,
      transaction_source: LabelUtil.getLabel().transactionSource,
      transaction_data: {
        ignore_duplicate_transactions: true
      },
    };
  });

  const endpoint = `merchant/${user.selectedMerchantAccount}/transactions-receipt/update-tip-batch`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'PUT',
      authenticated: true,
      types: [actionTypes.transactionUpdateRequest, actionTypes.transactionUpdateSuccess, actionTypes.transactionUpdateFailure],
      body: payload
    }
  };
}

export function setTransactionReceipt(selectedReceipt, receipts) {

  if (selectedReceipt) {

    let subTransactions;

    if (receipts) {

      switch (selectedReceipt.type) {
        case 'Void': {
          subTransactions = _.filter(receipts, {void_uniq_id: selectedReceipt.uniq_id});
          break;
        }
        case 'Credit Refund': {
          subTransactions = _.filter(receipts, {uniq_id: selectedReceipt.parent_uniq_id});
          break;
        }
        case 'Credit Sale':
          subTransactions = _.filter(receipts, {parent_uniq_id: selectedReceipt.uniq_id});
          break;
        case 'Cash Sale':
          subTransactions = _.filter(receipts, {parent_uniq_id: selectedReceipt.uniq_id});
          break;
        case 'Cash Refund':
          subTransactions = _.filter(receipts, {uniq_id: selectedReceipt.parent_uniq_id});
          break;
        default: {

          let splitTransactionDetails = _.filter(receipts, {receipt_id: selectedReceipt.receipt_id});
          if (splitTransactionDetails && splitTransactionDetails.length > 1 && selectedReceipt.type.includes('- Split Payment')) {
            subTransactions = _.filter(receipts, { parent_uniq_id: selectedReceipt.uniq_id });
            selectedReceipt.isSplitPayment = true;
          }
          break;
        }
      }
    }
    selectedReceipt.subTransactions = subTransactions || [];
  }

  return {
    type: actionTypes.receiptSelected,
    selectedReceipt: selectedReceipt
  };
}

export function sendReceipt(selectedReceipt, emails = [], phoneNumber = null, label, user, isEmail) {

  let body = {};
  body.brand = label;
  body.isEmail = isEmail;
  body.phoneNumber = phoneNumber;
  body.emailAddresses = emails;
  body.type = selectedReceipt.type;
  body.transactionId = selectedReceipt.id;

  const endpoint = `merchant/${user.selectedMerchantAccount}/transactions-receipts/send-receipt`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      types: [actionTypes.sendReceiptRequest, actionTypes.sendReceiptSuccess, actionTypes.sendReceiptFailure],
      body
    }
  };
}

export function printReceipt(selectedReceipt, label, user) {

  let body = {};
  body.brand = label;
  body.type = selectedReceipt.type;
  body.transactionId = selectedReceipt.id;
  body.allowPrintReceipt = (selectedReceipt.device !== null && selectedReceipt.type === 'Credit Sale' && ((selectedReceipt.txn_source === 'Payments Hub') || (selectedReceipt.txn_source === 'PaymentsHub Portal'))) || selectedReceipt.device === true;

  const endpoint = `merchant/${user.selectedMerchantAccount}/transactions-receipts/print`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      types: [actionTypes.printReceiptRequest, actionTypes.printReceiptSuccess, actionTypes.printReceiptFailure],
      body
    }
  };
}

export function processCreditCardVoid(receipt, user) {

  const merchantId = user ? user.selectedMerchantAccount : undefined;
  const username = receipt.sold_by;
  const endpoint = `users/accounts/${merchantId}/transactions`;

  let restock = [];

  _.range(0, _.size(receipt.item_line_item_ids)).forEach((itemIdIndex) => {
    restock.push({
      line_item_id: receipt.item_line_item_ids[itemIdIndex],
      quantity: receipt.item_quantity[itemIdIndex]
    });
  });

  const type = receipt.type === applicationConstants.achSale ? 'ach_void' : 'void';

  const body = {
    type,
    username,
    transaction_id: receipt.id,
    transaction_source: LabelUtil.getLabel().transactionSource,
    restock: restock
  };

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      encrypted: true,
      types: [actionTypes.creditCardVoidRequest, actionTypes.creditCardVoidSuccess, actionTypes.creditCardVoidFailure],
      body
    }
  };
}

export function processCreditCardVoidWithToken(payload, token) {

  const username = payload.sold_by;

  const query = `?token=${encodeURIComponent(token)}`;

  const endpoint = `invoice/void${query}`;

  let restock = [];

  _.range(0, _.size(payload.item_ids)).forEach((itemId) => {
    restock.push({
      line_item_id: payload.item_ids[itemId],
      quantity: payload.item_quantity[itemId]
    });
  });

  const body = {
    type: 'void',
    username,
    transaction_id: payload.id,
    transaction_source: LabelUtil.getLabel().transactionSource,
    restock,
  };

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: false,
      encrypted: true,
      types: [actionTypes.creditCardVoidRequest, actionTypes.creditCardVoidSuccess, actionTypes.creditCardVoidFailure],
      body
    }
  };
}

export function processMerchantCreditCardVoid(receipt, user) {

  const merchantId = user ? user.selectedMerchantAccount : undefined;
  const username = user?.data?.username;
  const endpoint = `users/accounts/${merchantId}/transactions`;

  const bric = receipt?.bric;

  const body = {
    type: 'void',
    username,
    transaction_source: LabelUtil.getLabel().transactionSource,
    processor_reference_token: bric
  };

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      encrypted: true,
      types: [actionTypes.creditCardMerchantVoidRequest, actionTypes.creditCardMerchantVoidSuccess, actionTypes.creditCardMerchantVoidFailure],
      body
    }
  };
}

export function processCashRefund(receipt, user, formValues, refundTotals) {

  const merchantId = user ? user.selectedMerchantAccount : undefined;
  const endpoint = `sales/refund-cash/${merchantId}/${receipt.id}`;

  const restock = [];

  formValues && formValues.lineItems && formValues.lineItems.forEach((lineItem, index) => {
    const restockQuantity = parseInt(lineItem.restockQuantity);
    if (restockQuantity > 0) {
      restock.push({
        line_item_id: receipt.item_line_item_ids[index],
        quantity: restockQuantity
      });
    };
  });

  const refundComment = (formValues && formValues.note) || null;
  let totalAmount;

  if (formValues && formValues.isItemized) {
    totalAmount = _.round(parseFloat(refundTotals && refundTotals.total), 2) || 0; //Includes tip if available
  } else {
    totalAmount = _.round(parseFloat(formValues && numeral(formValues.customRefundAmount).value()), 2) || 0;
  }

  const body = {
    transaction_source: LabelUtil.getLabel().transactionSource,
    restock: restock,
    refund_amount: totalAmount,
    comment: refundComment
  };

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      encrypted: true,
      types: [actionTypes.cashRefundRequest, actionTypes.cashRefundSuccess, actionTypes.cashRefundFailure],
      body
    }
  };
}

export function processCreditRefund(receipt, user, formValues, refundTotals) {

  const merchantId = user ? user.selectedMerchantAccount : undefined;
  const username = receipt.sold_by;
  const endpoint = `users/accounts/${merchantId}/transactions`;

  const restock = [];

  formValues && formValues.lineItems && formValues.lineItems.forEach((lineItem, index) => {
    const restockQuantity = parseInt(lineItem.restockQuantity);
    if (restockQuantity > 0) {
      restock.push({
        line_item_id: receipt.item_line_item_ids[index],
        quantity: restockQuantity
      });
    }
  });

  const refundComment = (formValues && formValues.note) || null;
  let tipAmount, totalAmount;

  if (formValues && formValues.isItemized) {
    tipAmount = _.round(parseFloat(formValues && numeral(formValues.refundTip).value()), 2) || 0;
    totalAmount = _.round(parseFloat(refundTotals && refundTotals.total), 2) || 0; //Includes tip if available
  } else {
    tipAmount = 0;
    totalAmount = _.round(parseFloat(formValues && numeral(formValues.customRefundAmount).value()), 2) || 0;
  }

  const type = receipt.type === applicationConstants.achSale ? 'ach_refund' : 'refund';

  let body = {
    type,
    comment: refundComment,
    amount: totalAmount,
    transaction_source: LabelUtil.getLabel().transactionSource,
    restock: restock,
    transaction_id: receipt.id,
    tip_amount: tipAmount,
    username
  };

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      encrypted: true,
      types: [actionTypes.creditRefundRequest, actionTypes.creditRefundSuccess, actionTypes.creditRefundFailure],
      body
    }
  };
}

export function processMerchantCreditRefund(receipt, user, formValues, refundTotals, mec_customer_id = undefined, pa_customer_id = undefined) {

  const merchantId = user ? user.selectedMerchantAccount : undefined;
  const username = user?.data?.username;
  const endpoint = `users/accounts/${merchantId}/transactions`;
  const bric = receipt?.bric;

  const refundComment = (formValues && formValues.note) || null;

  const totalAmount = _.round(parseFloat(refundTotals && refundTotals.total), 2) || 0;

  let body = {
    type: 'refund',
    username,
    transaction_source: LabelUtil.getLabel().transactionSource,
    amount: totalAmount,
    comment: refundComment,
    processor_reference_token: bric
  };

  if (!!formValues?.cdigits) {
    const expirationDate = moment(formValues?.edate, 'MM-YY').format('YYMM');
    const zipCode = formValues?.zipPlus4 ? formValues?.zip + formValues.zipPlus4 : formValues?.zip;

    if (formValues?.customer) {
      body.customer = formValues.customer;
    }

    if (mec_customer_id) {
      body.mec_customer_id = mec_customer_id
    }

    if (pa_customer_id) {
      body.pa_customer_id = pa_customer_id
    }

    body.card = {
      input_type: 'keyed',
      number: FormatTextUtil.parseCardNumberDigits(formValues.cdigits),
      expiration_date: expirationDate,
      security_code: formValues?.cvv,
      address: formValues?.street_number,
      zip_code: zipCode,
      credit_card_network: FormatTextUtil.parseCardType(formValues.cdigits)
    };
  }

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      encrypted: true,
      types: [actionTypes.creditMerchantRefundRequest, actionTypes.creditMerchantRefundSuccess, actionTypes.creditMerchantRefundFailure],
      body
    }
  };
}

export function processCreditCardPayment(taxValues, formValues = {}, user, txnTotal, categories = [], invoiceValues = {}, itemizedCart, isInvoiceCreation = false, customFields) {

  const isCardPresent = !!(formValues.tlv || null);
  const isEMV = !!(isCardPresent && formValues.tlv.inputType === 'EMV');
  const isContactlessEMV = !!(isCardPresent && formValues.tlv.inputType === 'CONTACTLESS_EMV');
  const isItemizedTransaction = !!(itemizedCart?.item_ids?.length > 0);
  const hasIccData = isEMV || isContactlessEMV;

  const merchantId = user ? user.selectedMerchantAccount : undefined;

  let endpoint = isInvoiceCreation ? `users/accounts/${merchantId}/invoices` : `sales/${merchantId}/sales-transaction-receipts`;

  const { brand, abbreviation } = LabelUtil.getLabel();

  const expirationMonth = moment(formValues.edate, 'MM-YY').format('MM');
  const expirationYear = moment(formValues.edate, 'MM-YY').format('YYYY');

  const zipCode = formValues.zipPlus4 ? formValues.zip + formValues.zipPlus4 : formValues.zip;

  const isRepayment = formValues.paymentType === 'debtRepayment';

  let r = { //receipt
    str: { //sales_transaction_receipt
      in: formValues.invoice_number || invoiceValues.invoiceNumber, //invoice_number
      sfa: 0, //service_fee_amount
      ta: taxValues.taxAmount, //tax_amount
      oda: 0, //order_discount_amount
      odr: 0, //order_discount_rate
      cda: !!formValues.cd_activated //cd_activated
    },
    b: brand, //brand
    iv: invoiceValues.isInvoice || false, // is_invoice
    inn: invoiceValues.invoiceName || null, // invoice_name
    id: invoiceValues.invoiceDescription || null,
    dd: invoiceValues.dueDate || null, // due_date
    ci: invoiceValues.customerId || null, // customer_id
    pci: invoiceValues.paCustomerId || null, // pa_customer_id
    si: invoiceValues.sendInvoice || null, // send_invoice
    sd: invoiceValues.sendDate || null, // send_date
    at: invoiceValues.allowTip || null, // allowTip
    ir: invoiceValues.isSeries || null, // isSeries
    iat: invoiceValues.invoiceAllowTip || false // inv_allow_tip
  };

  if (formValues.rewardCode && formValues.rewardCodeInformation) {
    r.ed = [{
      t: 'loyalty',
      c: formValues.rewardCode,
      a: formValues.rewardDiscount,
      n: 'Loyalty Reward'
    }];
  }

  let cashDiscount = 0;
  if (formValues.cashDiscountAmount > 0) {
    r.str.sfa = cashDiscount = roundToTwoDecimals(formValues.cashDiscountAmount).toFixed(2); //service_fee_amount
  }

  const roundedSubTotalAmount = handleNegative(roundToTwoDecimals(parseFloat(txnTotal)));
  r.str.sta = roundedSubTotalAmount; //sub_total_amount
  r.str.a = formValues.total; //total_amount
  const roundedAmount = formValues.total?.toFixed(2);

  if (!isItemizedTransaction) {
    const totalAmountWithoutLoyalty = handleNegative(roundToTwoDecimals(formValues.subTotalWithoutLoyalty + taxValues.taxAmount));

    r.eli = [{ // express_line_items
      iotu: true, //is_one_time_use
      itax: !!taxValues.taxRate || false, //is_taxable
      ifa: false, //is_favorite
      it: false, //is_trackable
      ia: false, //is_active
      ps: [{ //prices
        n: 'Base Price', //name
        p: numeral(formValues.amount).value(), //price
        q: 1, //quantity
      }],
      pta: formValues.subTotalWithoutLoyalty, //price_total_amt
      sta: formValues.subTotalWithoutLoyalty, //sub_total_amt
      sfa: cashDiscount, //service_fee_amt
      ta: taxValues.taxAmount, //tax_amt
      a: totalAmountWithoutLoyalty, //total_amt
      sc: formValues.selectedCategory, //selectedCategory
      c: categories || [], //categories
    }];

    // looks in SalesReceiptLineItemDto looks like taxRate is there.. can we not utilize that?
    if (taxValues.taxRate > 0.001) {
      r.eli[0].tr = taxValues.taxRate; //tax_rate
    }

  } else {

    r.eli = []; //express_line_items
    r.li = []; //line_items
    r.di = []; //cart discounts

    itemizedCart.item_ids.map((itemId, itemIndex) => {

      let expressOptions = [];
      if (itemizedCart.express_options[itemIndex]?.length > 0) {
        itemizedCart.express_options[itemIndex].map((modifier, modifierIndex) => {
          const modifierCashDiscountAmount = roundToTwoDecimals(
            itemizedCart.cd_item_express_modifier_prices[itemIndex][modifierIndex] - (numeral(modifier.price).value() * itemizedCart.item_quantity[itemIndex])
          );

          expressOptions.push({
            n: modifier.name, //name
            p: numeral(modifier.price).value().toFixed(2), //price
            toa: itemizedCart.cd_item_express_modifier_prices[itemIndex][modifierIndex], //total_amt
            sfa: modifierCashDiscountAmount //service_fee_amt
          });
        });
      }

      if (itemId === 'express') {
        r.eli.push({
          iotu: true, //is_one_time_use
          itax: !!itemizedCart.item_tax_rate[itemIndex], //is_taxable
          n: itemizedCart.item_names[itemIndex] || 'Express Item', //name
          ifa: false, //is_favorite
          it: false, //is_trackable
          ia: false, //is_active
          ps: [{ //prices
            n: 'Base Price', //name
            p: itemizedCart.item_unit_price[itemIndex], //price
            q: itemizedCart.item_quantity[itemIndex], //quantity
          }],
          pta: itemizedCart.cd_item_price[itemIndex], //price_total_amt
          sta: itemizedCart.item_subtotal[itemIndex], //sub_total_amt
          ...(itemizedCart.item_service_fee_amt[itemIndex] && { sfa: itemizedCart.item_service_fee_amt[itemIndex] }), //service_fee_amt
          ta: itemizedCart.item_tax_amt[itemIndex], //tax_amt
          a: itemizedCart.item_total[itemIndex], //total_amt
          sc: itemizedCart.item_category[itemIndex], //selectedCategory
          c: (itemizedCart.item_category[itemIndex] > 0) ? [itemizedCart.item_category[itemIndex]] : [], //categories
          tr: (itemizedCart.item_tax_rate[itemIndex] > 0) ?  itemizedCart.item_tax_rate[itemIndex] : undefined, //tax_rate
          eo: expressOptions
        });

      } else {

        const lineItem = {
          pi: itemizedCart.item_price_id[itemIndex], //price_id
          pt: itemizedCart.item_unit_price[itemIndex], //price_at_time
          q: itemizedCart.item_quantity[itemIndex], //quantity
          ii: itemizedCart.item_ids[itemIndex], //itemId
          ci: (itemizedCart.item_category[itemIndex] > 0) ? itemizedCart.item_category[itemIndex] : null, //categoryId
          a: itemizedCart.item_unit_price[itemIndex], //amount
          pta: itemizedCart.cd_item_price[itemIndex], //price_total_amt
          sta: itemizedCart.item_subtotal[itemIndex], //sub_total_amt
          ta: itemizedCart.item_tax_amt[itemIndex], //tax_amt
          toa: itemizedCart.item_total[itemIndex], //total_amt
          tr: (itemizedCart.item_tax_rate[itemIndex] > 0) ?  itemizedCart.item_tax_rate[itemIndex] : undefined, //tax_rate
          eo: expressOptions
        };

        if (itemizedCart.item_discount_amt[itemIndex]) {
          lineItem.da = itemizedCart.item_discount_amt[itemIndex]; //discount_amount
          lineItem.dta = itemizedCart.cd_item_discount_amt[itemIndex]; //discount_total_amt
        }

        if (itemizedCart.item_discount_rate[itemIndex]) {
          lineItem.dr = itemizedCart.item_discount_rate[itemIndex]; //discount_rate
          lineItem.dta = itemizedCart.cd_item_discount_amt[itemIndex]; //discount_total_amt
        }

        if (itemizedCart.item_service_fee_amt[itemIndex]) {
          lineItem.sfa = itemizedCart.item_service_fee_amt[itemIndex]; //service_fee_amt
        }

        if (itemizedCart.modifier_ids[itemIndex]?.length > 0) {
          lineItem.om = []; //option_members (modifiers)
          itemizedCart.modifier_ids[itemIndex].map((modifier, modifierIndex) => {
            const modifierCashDiscountAmount = roundToTwoDecimals(
              itemizedCart.cd_item_modifier_prices[itemIndex][modifierIndex] - (numeral(modifier.price).value() * itemizedCart.item_quantity[itemIndex])
            );

            lineItem.om.push({
              id: modifier.modifierId, //id
              a: modifier.price, //amount
              toa: itemizedCart.cd_item_modifier_prices[itemIndex][modifierIndex], //total_amt
              sfa: modifierCashDiscountAmount //service_fee_amt
            });
          });
        }

        r.li.push(lineItem);

      }

    });

    itemizedCart.receipt_discount_id.map((discountId, discountIndex)=> {
      r.di.push({
        id: discountId,
        a: itemizedCart.receipt_discount_amt[discountIndex]
      });
    });

  }

  let businessData = formValues.processingDataSelection && formValues.processingDataSelection !== 'none' ? ({
    data_type: 'G'
  }) : null;

  if (businessData && (formValues.processingDataSelection === 'levelThree' || formValues.processingDataSelection === 'levelTwo')) {
    businessData.l2 = {
      tag001: formValues.customerCode,
      tag002: numeral(formValues.taxAmount).value(),
      tag003: formValues.taxId
    };

    r.str.ta = numeral(formValues.taxAmount).value();
  }

  if (businessData && formValues.processingDataSelection === 'levelThree') {
    businessData.l3 = {
      tag004: formValues.itemDescription,
      tag005: formValues.itemQuantity,
      tag006: formValues.measureUnit,
      tag007: formValues.unitPrice,
      tag008: formValues.productCode,
      tag009: formValues.orderDate,
      tag014: formValues.commodityCode,
      tag015: formValues.requestorName,
      tag016: formValues.companyName,
      tag017: formValues.shipmentId,
      tag018: formValues.shipmentCountry,
      tag019: formValues.shipmentState,
      tag020: formValues.shipmentZipCode,
      tag021: formValues.destinationCountry,
      tag022: formValues.destinationState,
      tag023: formValues.destinationZipCode,
      tag024: formValues.discountAmount,
      tag025: formValues.dutyAmount,
      tag026: formValues.freightAmount,
    }
  }

  const latitude = taxValues.geoLocation ? taxValues.geoLocation.latitude : null;
  const longitude = taxValues.geoLocation ? taxValues.geoLocation.longitude : null;

  if (latitude && longitude) {
    r.str.la = latitude;
    r.str.lo = longitude;
  }

  if (invoiceValues && invoiceValues.isInvoice) {
    r.t = 'invoice';
    r.sta = roundedSubTotalAmount;
    r.a = formValues.total;
    r.la = taxValues.geoLocation ? taxValues.geoLocation.latitude : null;
    r.lo = taxValues.geoLocation ? taxValues.geoLocation.longitude : null;
    r.ta = taxValues.taxAmount;
    r.tr = taxValues.taxRate;
    r.b = abbreviation;
    r.iid = formValues.isDraft;
    r.mp = invoiceValues.markAsPaid;
    r.ts = LabelUtil.getLabel().transactionSource;

    if (invoiceValues.isSeries) {
      r.t = 'series';
      r.ri = invoiceValues.recurringInvoice;
      r.rf = invoiceValues.recurringInvoice.period;
      r.rl = invoiceValues.recurringInvoice.length;
      r.rsd = invoiceValues.recurringInvoice.start_date;
      r.red = invoiceValues.recurringInvoice.end_date;
      r.reap = invoiceValues.recurringInvoice.end_amount_payments;
      r.ap = false;
    }

    if (invoiceValues.customer) {
      r.c = {
        fn: invoiceValues.customer.first_name,
        ln: invoiceValues.customer.last_name,
        e: invoiceValues.customer.email,
        p: invoiceValues.customer.phone
      };
    }
  }

  const encryptedSetting = !isInvoiceCreation;

  endpoint = hasIccData ? endpoint + '/emv' : endpoint;

  const td = {
    ts: LabelUtil.getLabel().transactionSource, //transaction_source
    a: roundedAmount, //amount
    av: applicationVersion, //app_version
    idt: 1, //ignore_duplicate_transactions
    la: taxValues.geoLocation ? taxValues.geoLocation.latitude : null, //latitude
    lo: taxValues.geoLocation ? taxValues.geoLocation.longitude : null, //longitude
    ta: 0, //tip_amount
    c: formValues.description, //comment
    sn: formValues.street_number, //street_numeral
    zc: zipCode, //zip_code
    ir: isRepayment, //is_repayment
    ien: true // isExternalNotification
  };

  if (formValues.custom_field_1 && customFields?.vt_fields?.length > 0) {
    td.custom_fields = { custom_field_1: { name: customFields.vt_fields[0].custom_field_1.label, value: formValues.custom_field_1} };
  }

  let body = {};
  const addCard = formValues.paymentMethod === PaymentMethod.CREDIT_CARD || (formValues.selectedPaymentMethod?.hasOwnProperty('id') && formValues.selectedPaymentMethod.hasOwnProperty('network'));
  const addBankingAccount = formValues.paymentMethod === PaymentMethod.BANKING_ACCOUNT || (formValues.selectedPaymentMethod?.hasOwnProperty('id') && formValues.selectedPaymentMethod.hasOwnProperty('ach_type'));
  const customerId = formValues?.selectedCustomer?.id;

  if (invoiceValues && invoiceValues.isInvoice) {

    if (addCard && invoiceValues.isSeries) {
      r.ap = true;
    }

    body = {
      r,
      ...(addCard && {
        td,
        c: { //card
          bd: businessData,
          s: formValues.saveCreditCard, //savePaymentMethod
          ...(!formValues.selectedPaymentMethod && { n: FormatTextUtil.parseCardType(formValues.cdigits) }), //network
        }
      }),
      ...(addBankingAccount && {
        td,
        ba: { //bank_account
          s: formValues.saveCreditCard, //savePaymentMethod
          bd: businessData,
        }
      })
    };
  } else if (isCardPresent) {
    body = {
      t: 'ccs', //type
      r, //receipt
      c: { //card
        cc: { //credit_card
        },
        td,
        bd: businessData, //business_data
        n: FormatTextUtil.parseCardType(formValues.cdigits) //network
      }
    };
  } else {
    body = {
      t: addBankingAccount ? 'ach' : 'ccs', //type
      r, //receipt
      td,
      ...(addCard && {
        c: { //card
          bd: businessData, //business_data
          ...(!formValues.selectedPaymentMethod && {n: FormatTextUtil.parseCardType(formValues.cdigits)}) //network
      }
    }),
    ...(addBankingAccount && {
      ba: { //bank_account
        bd: businessData, //business_data
      }
    })
    };
  }

  if (isCardPresent) {
    body.c.cc = {
      it: formValues.tlv.inputType || 'SWIPED',
      noc: formValues.tlv.cardholderName ? formValues.tlv.cardholderName.trim() : null, //name_on_card
      d: formValues.tlv.deviceType || 'CHIPPER2X' //device
    };

    if (formValues.tlv.chipConditionCode) {
      body.c.cc.ccc = formValues.tlv.chipConditionCode; //chip_condition_code
    }

    /* istanbul ignore else */
    if (body.c.cc.it === 'SWIPED') {
      body.c.cc['msd'] = formValues.tlv.encTrack2; //magnetic_stripe_data
    } else if (isEMV || isContactlessEMV) {
      body.c.cc['id'] = formValues.tlv.iccData; //icc_data
      body.c.td['ibs'] = false; //is_batch_message
      body.c.cc['ic'] = formValues.tlv.inputChannel || '' // input_channel
    }

    if (formValues.tlv.epb || formValues.tlv.onlinePinKsn) {
      body.c.cc.epb = formValues.tlv.epb || '';
      body.c.cc.pk = formValues.tlv.onlinePinKsn || ''; //pin_key
    }
    if (formValues.tlv.expiryData) {
      body.c.cc.ed = moment(formValues.tlv.expiryData, 'YYMM').format('YYYY-MM-DDT00:00:00UTC');
    }

    body.c.td.ssn = formValues.tlv.ksn; //swiper_serial_number
    body.c.td.rsn = formValues.tlv.serialNumber; //reader_serial_number
    body.c.td.sdfi = formValues.tlv.formatID; //swipe_data_format_id
    body.c.td.cpv = formValues.cpVersion || ''; // card_present_version
    body.c.td.msn = formValues.machineSerialNumber;
  }

  if (body.c) {
    const customerId = formValues.selectedCustomer?.id;

    body.c = {
      ...body.c,
      ...(!!formValues.selectedCustomer?.address?.id && {aid: formValues.selectedCustomer.address.id }),
      ...(!!formValues.customer_street_address_1 && {sa1: formValues.customer_street_address_1}),
      ...(!!formValues.customer_street_address_2 && {sa2: formValues.customer_street_address_2}),
      ...(!!formValues.customer_state && {st: formValues.customer_state}),
      ...(!!formValues.customer_city && {cy: formValues.customer_city}),
      ...(!!formValues.customer_zip && {zip: formValues.customer_zip}),
    };

    if (!!formValues.selectedPaymentMethod) {
      body.c = {
        ...body.c,
        ci:  null,
        pci: customerId,
        pm: formValues.selectedPaymentMethod.id, //payment_method
        pmt: 'card'
      };
    } else {
      body.c = {
        ...body.c,
        ...(formValues.selectedCustomer?.id && {
          ci: formValues.selectedCustomer?.is_pa_customer ? null : customerId,
          pci: formValues.selectedCustomer?.is_pa_customer ? customerId : null,
        }),
        em: expirationMonth,
        ey: expirationYear,
        it: 'KEYED',
        num: FormatTextUtil.parseCardNumberDigits(formValues.cdigits),
        spm: !!formValues.saveCreditCard, //save_payment_method
        cvv: formValues.cvv,
        a: formValues.street_number,
        zc: zipCode
      };
      if (!formValues.selectedCustomer?.id) {

        const validEmails = _.filter(formValues.customer_email_addresses, contact => emailValidator.validate(contact));
        const validMobileNumber = Validator.isPhoneNumber(formValues.customer_phone) ? formValues.customer_phone : '';

        body.c = {
          ...body.c,
          cf: formValues.customer_first,
          cl: formValues.customer_last,
          ce: validEmails[0],
          cp: validMobileNumber
        };

      }
    }
  } else if (body.ba) {
    
    body.ba = {
      ...body.ba,
      ...(!!formValues.customer_street_address_1 && {sa1: formValues.customer_street_address_1}),
      ...(!!formValues.customer_street_address_2 && {sa2: formValues.customer_street_address_2}),
      ...(!!formValues.customer_state && {st: formValues.customer_state}),
      ...(!!formValues.customer_city && {cy: formValues.customer_city}),
      ...(!!formValues.customer_zip && {zip: formValues.customer_zip}),
    }
    
    if (!!formValues.selectedPaymentMethod) {
      body.ba = {
        ...body.ba,
        it: 'BANK',
        ci:  null,
        pci: customerId,
        pm: formValues.selectedPaymentMethod.id, //payment_method
        pmt: 'ach'
      };
    } else {
      const [firstName, lastName] = formValues?.accountName.split(' ');
      body.ba = {
        ...body.ba,
        ...(formValues.selectedCustomer?.id && {
          ci: formValues.selectedCustomer?.is_pa_customer ? null : customerId,
          pci: formValues.selectedCustomer?.is_pa_customer ? customerId : null,
        }),
        cf: firstName,
        cl: lastName,
        an: formValues.accountNumber,
        rn: formValues.routingNumber,
        at: formValues.accountType,
        spm: !!formValues.saveCreditCard, //save_payment_method
      };
      
      if(!formValues.selectedCustomer?.id) {
        
        const validEmails = _.filter(formValues.customer_email_addresses, contact => emailValidator.validate(contact));
        const validMobileNumber = Validator.isPhoneNumber(formValues.customer_phone) ? formValues.customer_phone : '';
        
        body.ba = {
          ...body.ba,
          bacf: formValues.customer_first,
          bacl: formValues.customer_last,
          ce: validEmails[0],
          cp: validMobileNumber
        };
        
      }
      
    }

  }
  if (user && user.data) {
    const requestId = generateRequestId(roundedAmount, user, body.c?.it || body.c?.cc?.it || body.ba?.it || 'invoice');
    if (body.r.iv) {
      body.ri = requestId;
    } else {
      if (body.c) {
        body.c.ri = requestId;
      } else {
        body.ba.ri = requestId;
      }
    }
  }

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      encrypted: encryptedSetting,
      types: [actionTypes.creditCardPaymentRequest, actionTypes.creditCardPaymentSuccess, actionTypes.creditCardPaymentFailure],
      body
    }
  };
}

export function generateRequestId(amount, user, type, token = null) {

  const timestamp = Math.round(new Date() / 1000);
  const randomNumber = Math.floor(Math.random() * 8999) + 1000;

  if (type === 'paynow') {
    return uuidUtil.uuid() + '+' + amount + '+' + timestamp + '+' + type + '+' + randomNumber;
  } else if (token) {
    return uuidUtil.uuid() + '+' + token + '+' + amount + '+' + timestamp + '+' + type + '+' + randomNumber;
  } else {
    const merchantId = UserUtil.getActiveAccount(user).mea_id;
    return uuidUtil.uuid() + '+' + merchantId + '+' + user.data.user_id + '+' + amount + '+' + timestamp + '+' + type + '+' + randomNumber;
  }
}

export function markInvoiceAsPaid(values, user) {
  const merchantId = user?.selectedMerchantAccount;
  const endpoint = `users/accounts/${merchantId}/transactions`;

  const amount = roundToTwoDecimals(values.total_amt);
  const cashAmountProps = values.cashAmountSelected && {
    update_receipt: true,
    invoice: {
      items: InvoiceUtil.getLineItems(values.itemizedCart),
      discounts: InvoiceUtil.getDiscounts(values.itemizedCart),
      ...(values?.rewardCode ? { express_discounts: [values.rewardCode] } : {}),
      express_line_items: InvoiceUtil.getExpressLineItems(values.itemizedCart, values.description, values.itemizedCart.sub_total_amt, null, values.itemizedCart.service_fee_amt, true),
      service_fee_amount: 0.0,
      amount,
      sub_total_amount: values.itemizedCart.sub_total_amt,
      tax_rate: values.tax_rate,
      tax_amount: values.tax_amt,
      is_draft: false,
      transaction_source: LabelUtil.getLabel().transactionSource,
    }
  };

  const brand = LabelUtil.getLabel().brand;

  const body = {
    type: 'cash_payment',
    receipt_id: values.receipt_id,
    due_amount: handleNegative(amount),
    tendered_amount: handleNegative(amount),
    comment: values.comment,
    tax_info: {
      state: 0,
      city: 0,
      county: 0,
      custom: values.tax_amt
    },
    ...cashAmountProps,
    transaction_source: LabelUtil.getLabel().transactionSource,
    transaction_data: {
      app_name: brand,
      app_version: applicationVersion,
      ignore_duplicate: 0,
      ignore_duplicate_transactions: 0,
      latitude: values.latitude,
      longitude: values.longitude,
      tip_amount: 0,
      is_external_notification: true
    },
    service_fee_amount: !values.cashAmountSelected ? values.service_fee_amt : 0
  };

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      encrypted: true,
      types: [actionTypes.invoiceMarkAsPaidRequest, actionTypes.invoiceMarkAsPaidSuccess, actionTypes.invoiceMarkAsPaidFailure],
      body
    }
  };
}

export function processCashPayment(values, user) {

  const merchantId = user ? user.selectedMerchantAccount : undefined;
  const endpoint = `sales/cash-sale/${merchantId}/${values.receipt_id}`;

  const brand = LabelUtil.getLabel().brand;

  const receiptDiscountsTotalAmount = values.receipt_discount_amt && values.receipt_discount_amt.length > 0
    ? values.receipt_discount_amt.reduce((total, currentDiscount) => (total + _.round(currentDiscount, 2)), 0)
    : null;

  const discountAmountToSubtract = !values.receipt_updated_date && receiptDiscountsTotalAmount ? receiptDiscountsTotalAmount : 0;

  const amount = values.cashAmountSelected
    ? parseFloat(roundToTwoDecimals(values.total_amt) - roundToTwoDecimals(values.service_fee_amt)).toFixed(2)
    : parseFloat(roundToTwoDecimals(values.total_amt) - roundToTwoDecimals(discountAmountToSubtract)).toFixed(2);

  const body = {
    due_amount: amount,
    tendered_amount: amount,
    comment: values.comment,
    tax_info: {
      state: 0,
      city: 0,
      county: 0,
      custom: values.tax_amt
    },
    transaction_source: LabelUtil.getLabel().transactionSource,
    transaction_data: {
      app_name: brand,
      app_version: applicationVersion,
      ignore_duplicate: 0,
      ignore_duplicate_transactions: 0,
      latitude: values.latitude,
      longitude: values.longitude,
      tip_amount: 0,
      is_external_notification: true
    },
    service_fee_amount: !values.cashAmountSelected ? values.service_fee_amt : 0
  };

  if (user && user.data) {
    const requestId = generateRequestId(amount, user, 'cash');
    body.request_id = requestId;
  }

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      encrypted: true,
      types: [actionTypes.cashSalePaymentRequest, actionTypes.cashSalePaymentSuccess, actionTypes.cashSalePaymentFailure],
      body
    }
  };

}

export function processInvoicePayment(values, token, type) {

  const isCardPresent = !!(values.tlv || null);
  const isEMV = !!(isCardPresent && values.tlv.inputType === 'EMV');

  const isContactlessEMV = !!(isCardPresent && values.tlv.inputType === 'CONTACTLESS_EMV');
  const encryptedSetting = !isEMV && !isContactlessEMV;

  const encodedToken = encodeURIComponent(token);

  const query = `?token=${encodedToken}`;
  let endpoint = `sales-invoice/${values.receipt_id}`;
  endpoint = type === 'paynow' ? endpoint + '/pay-now' : endpoint;
  endpoint = !encryptedSetting ? endpoint + '/emv' : endpoint;
  endpoint += query;

  const brand = LabelUtil.getLabel().brand;
  const expirationDate = moment(values.edate, 'MM-YY').format('YYYY-MM-DDT00:00:00UTC');

  const receiptDiscountsTotalAmount = values.receipt_discount_amt && values.receipt_discount_amt.length > 0
    ? values.receipt_discount_amt.reduce((total, currentDiscount) => (total + _.round(currentDiscount, 2)), 0)
    : null;

  const unformattedTip = numeral(values.tip_amount).value();
  const tipAmount = unformattedTip && unformattedTip > 0 ? parseFloat(unformattedTip).toFixed(2) : 0;

  const discountAmountToSubtract = !values.receipt_updated_date && receiptDiscountsTotalAmount ? receiptDiscountsTotalAmount : 0;
  const amount = parseFloat(roundToTwoDecimals(values.total_amt) - roundToTwoDecimals(discountAmountToSubtract)).toFixed(2);

  const zipCode = values.zipPlus4 ? values.zip + values.zipPlus4 : values.zip;

  let body = {};

  if (isCardPresent) {
    body.transaction_data = {};
    body.credit_card = {
      input_type: values.tlv.inputType || 'SWIPED',
      name_on_card: values.tlv.cardholderName ? values.tlv.cardholderName.trim() : null, //name_on_card
      device: values.tlv.deviceType || 'CHIPPER2X' //device
    };

    if (values.tlv.chipConditionCode) {
      body.credit_card.chip_condition_code = values.tlv.chipConditionCode; //chip_condition_code
    }

    /* istanbul ignore else */
    if (body.credit_card.input_type === 'SWIPED') {
      body.credit_card.magnetic_stripe_data = values.tlv.encTrack2; //magnetic_stripe_data
    } else if (isEMV || isContactlessEMV) {
      body.credit_card.icc_data = values.tlv.iccData; //icc_data
      body.transaction_data.is_batch_message = false; //is_batch_message
      body.credit_card.channel = values.tlv.inputChannel || '' // channel
    }

    if (values.tlv.epb || values.tlv.onlinePinKsn) {
      body.credit_card.epb = values.tlv.epb || '';
      body.credit_card.pin_key = values.tlv.onlinePinKsn || ''; //pin_key
    }
    if (values.tlv.expiryData) {
      body.credit_card.expiration_date = moment(values.tlv.expiryData, 'YYMM').format('YYYY-MM-DDT00:00:00UTC');
    }

    body.transaction_data.swiper_serial_number = values.tlv.ksn; //swiper_serial_number
    body.transaction_data.reader_serial_number = values.tlv.serialNumber; //reader_serial_number
    body.transaction_data.swipe_data_format_id = values.tlv.formatID; //swipe_data_format_id
    body.transaction_data.transaction_source = LabelUtil.getLabel().transactionSource;
    body.transaction_data.amount = amount;
    body.transaction_data.app_name = brand;
    body.transaction_data.app_version = applicationVersion;
    body.transaction_data.ignore_duplicate_transactions = 1;
    body.transaction_data.latitude = values.latitude;
    body.transaction_data.longitude = values.longitude;
    body.transaction_data.tip_amount = tipAmount;
    body.transaction_data.comment = null;
    body.transaction_data.street_numeral = values.street_number;
    body.transaction_data.zip_code = zipCode;
    body.transaction_data.is_recurring = false;
    body.transaction_data.is_external_notification = true;
    body.transaction_data.card_present_version = values.cpVersion || '';
  } else {
    body = {
      transaction_data: {
        transaction_source: LabelUtil.getLabel().transactionSource,
        amount: amount,
        app_name: brand,
        app_version: applicationVersion,
        ignore_duplicate_transactions: 1,
        latitude: values.latitude,
        longitude: values.longitude,
        tip_amount: tipAmount,
        comment: null,
        street_numeral: values.street_number,
        zip_code: zipCode,
        is_recurring: false,
        is_external_notification: true
      }
    };

    if (!!values.selectedPaymentMethod) {
      body.payment_method = values.selectedPaymentMethod.id;
    } else {
      body.credit_card = {
        expiration_date: expirationDate,
        input_type: 'KEYED',
        number: values.cdigits,
        security_code: values.cvv
      };
    }
  }

  if (values.is_pre_auth) {
    body.is_pre_auth = true;
  }

  if (values.enrolledAutoPayment) {

    const recurringPeriod = values.frequency.split(' ');
    body.transaction_data.is_recurring = true;

    body.recurring_payment = {
      length: recurringPeriod[0],
      period: recurringPeriod[1],
      start: moment(values.start_date).format('YYYY-MM-DD'),
    };

    if (values.end_date) {
      body.recurring_payment.end = moment(values.end_date).format('YYYY-MM-DD');
    }

  }

  if (values.customer_id) {
    body.customer_id = values.customer_id;
  }

  if (values.extra && !_.isEmpty(values.extra)) {
    body.transaction_data.extra = values.extra;
  }

  type = type ? type : 'invoice';

  const requestId = generateRequestId(amount, null, type, token);
  body.request_id = requestId;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: false,
      encrypted: encryptedSetting,
      types: [actionTypes.creditCardPaymentRequest, actionTypes.creditCardPaymentSuccess, actionTypes.creditCardPaymentFailure],
      body
    }
  };
}

export function processNABPayment(user, values, shoppingCart) {

  /////////////////////////////////////////////////////////////////////
  // Process a NAB account payment by credit card
  // Note: No Tax
  /////////////////////////////////////////////////////////////////////

  const merchantId = user.selectedMerchantAccount;
  const endpoint = `sales-invoice/nab/${merchantId}`;
  const brand = LabelUtil.getLabel().brand;
  const expirationDate = moment(values.edate, 'MM-YY').format('YYYY-MM-DDT00:00:00UTC');
  const activeAccount = UserUtil.getActiveAccount(user);

  let totalOrder = 0;


  const zipCode = values.zipPlus4 ? values.zip + values.zipPlus4 : values.zip;

  const cart = shoppingCart.cart;
  const lineItems = cart.productIds.map((productId) => {

    const cartProduct = cart.productHashes[productId];

    const productTotal = cartProduct.product.details.prices[0].price * cartProduct.quantity;

    let lineItem = {};

    lineItem.pi = cartProduct.product.details.prices[0].id; //price_id
    lineItem.q = cartProduct.quantity; // quantity
    lineItem.tr = 0.00; //tax_rate
    lineItem.dr = 0.00; //discount_rate
    lineItem.da = 0.00; //discount_amount
    lineItem.om = []; //option_members

    totalOrder = Math.round((productTotal + totalOrder) * 100) / 100;

    return lineItem;
  });

  let body = {};
  body.p = {
    o: { //order
      li: lineItems, //line_items
      to: parseFloat(Math.round(totalOrder * 100) / 100).toFixed(2),  //total_amount
      ta: 0.00 //tax_amount
    },
    ts: LabelUtil.getLabel().transactionSource, //transaction_source
    cc: { //credit_card
      ed: expirationDate,  //expiration_date
      it: 'KEYED', //input_type
      n: values.cdigits, //number
      sc: values.cvv,  //security_code
      sn: values.street_number, //street_numeral
      zc: zipCode //zip_code
    },
    ta: user.data.username, //to_address
    b: brand,  //brand
    s: activeAccount.mea_code //status
  };

  body.so = ShoppingCartUtil.buildOrderBody(user, shoppingCart, 'EQUIPMENT'); //shipping Order

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      encrypted: true,
      types: [actionTypes.creditCardPaymentRequest, actionTypes.creditCardPaymentSuccess, actionTypes.creditCardPaymentFailure],
      body
    }
  };
}

export function completeCreditCardPayment(receiptValues, geoLocation, user) {

  /////////////////////////////////////////////////////////////////////
  // Complete a credit card payment (pre-auth)
  /////////////////////////////////////////////////////////////////////

  const merchantId = user ? user.selectedMerchantAccount : undefined;
  const endpoint = `sales/pre-auth/${merchantId}/${receiptValues.id}`;

  const body = {
    type: 'preauth',
    transaction_source: LabelUtil.getLabel().transactionSource,
    transaction_id: receiptValues.id,
    comment: receiptValues?.comment ?? '',
    latitude: receiptValues?.latitude ?? '',
    longitude: receiptValues?.longitude ?? ''
  };

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      encrypted: true,
      types: [actionTypes.creditCardCompleteRequest, actionTypes.creditCardCompleteSuccess, actionTypes.creditCardCompleteFailure],
      body
    }
  };
}

export function cancelPreAuthPayment(receiptValues, geoLocation, user) {

  /////////////////////////////////////////////////////////////////////
  // Cancel a credit card pre-auth payment
  /////////////////////////////////////////////////////////////////////

  const merchantId = user ? user.selectedMerchantAccount : undefined;
  const endpoint = `sales/pre-auth/${merchantId}/${receiptValues.id}/cancel`;

  const body = {
    transaction_source: LabelUtil.getLabel().transactionSource,
    amount: parseFloat(receiptValues.amount).toFixed(2),
    latitude: geoLocation ? geoLocation.latitude : null,
    longitude: geoLocation ? geoLocation.longitude : null
  };

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      encrypted: true,
      types: [actionTypes.creditCardCancelRequest, actionTypes.creditCardCancelSuccess, actionTypes.creditCardCancelFailure],
      body
    }
  };
}

export function getCustomerInvoicesTransactions(token) {

  const query=`?token=${encodeURIComponent(token)}`;

  const endpoint = `customer/invoices-transactions${query}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: false,
      types: [actionTypes.customerInvoicesTransactionsGetRequest, actionTypes.customerInvoicesTransactionsGetSuccess, actionTypes.customerInvoicesTransactionsGetFailure]
    }
  };
}

/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */
