import actionTypes from '../constants/actionTypes';
import {i18nLanguages} from '../locales/i18n';

const languageReducer = (
  state = {
    i18nLanguage: localStorage.getItem('i18nLanguage') ? localStorage.getItem('i18nLanguage') : i18nLanguages.en_US
  },
  action
) => {
  switch (action.type) {
    case actionTypes.languageSelected:
      return Object.assign({}, state, {
        i18nLanguage: action.i18nLanguage
      });

    default:
      return state;
  }
}

export default languageReducer;
