/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import _ from 'lodash';
import { change } from 'redux-form';
import FormatTextUtil from './FormatTextUtil';
import DateUtil from './DateUtil';
import InvoiceUtil, {FormType} from './InvoiceUtil';

const normalizeCashDiscount = (cashDiscountFixedAmount, cashDiscountPercentAmount, amount) => FormatTextUtil.formatCashDiscount(cashDiscountFixedAmount, cashDiscountPercentAmount, amount);

const FormUtil = {

  shouldEnableSubmit: function (theProps) {

    let serverError = theProps.updateError ? true : false;

    // submitSucceeded is true when form is submitting
    let enableSubmit = !theProps.submitSucceeded || theProps.pristine || serverError;
    return enableSubmit;

  },

  virtualTerminalForm: function (props, nextProps) {

    if (nextProps.itemizedCart && nextProps.itemizedCart.sub_total_amt !== props.itemizedCart.sub_total_amt) {
      props.dispatch(change('virtualTerminalForm', 'amount', nextProps.itemizedCart.sub_total_amt));
    }

    if (nextProps.itemizedCart &&
      (
        nextProps.itemizedCart.sub_total_pre_discounts !== props.itemizedCart.sub_total_pre_discounts
        || nextProps.itemizedCart.sub_total_amt !== props.itemizedCart.sub_total_amt
      )
    ) {
      props.dispatch(change('virtualTerminalForm', 'subtotalPreDiscounts', nextProps.itemizedCart.sub_total_pre_discounts));
    } else if (nextProps.selectedPaymentType === 'oneTimePayment' && nextProps.currentValues.amount !== props.currentValues.amount) {
      const subtotalPreDiscounts = InvoiceUtil.recalculateCartCustomAmount(nextProps.currentValues, nextProps.loyaltyVpc?.rewardCodeInfo).subTotalWithoutLoyalty;
      props.dispatch(change('virtualTerminalForm', 'subtotalPreDiscounts', subtotalPreDiscounts));
    }

    //This updates the redux form's value when the payment type is changed using the FilterPanel
    if (nextProps.selectedPaymentType !== props.selectedPaymentType) {
      props.dispatch(change('virtualTerminalForm', 'paymentType', nextProps.selectedPaymentType));

      if (!!nextProps.merchantSettings.geoTaxEnabled) {
        props.dispatch(change('virtualTerminalForm', 'taxRate', FormatTextUtil.formatNumberToThreeDecimals(nextProps.taxes.taxRate)));
      }
    }

    if (nextProps.merchantSettings.geoTaxEnabled != props.merchantSettings.geoTaxEnabled && !!nextProps.merchantSettings.geoTaxEnabled) {
      props.dispatch(change('virtualTerminalForm', 'taxRate', FormatTextUtil.formatNumberToThreeDecimals(nextProps.taxes.taxRate)));
    }

    if (
      nextProps.currentValues.processingDataSelection === 'levelTwo' ||
      nextProps.currentValues.processingDataSelection === 'levelThree'
    ) {

      let taxAmount = 0;

      if (nextProps.selectedPaymentType === 'itemizedSale') {
        taxAmount = InvoiceUtil.recalculateCart(nextProps.itemizedCart, nextProps.currentValues, nextProps.loyaltyVpc.rewardCodeInfo).tax_amt;
      } else if (nextProps.selectedPaymentType === 'oneTimePayment') {
        taxAmount = InvoiceUtil.recalculateCartCustomAmount(nextProps.currentValues, nextProps.loyaltyVpc.rewardCodeInfo).taxAmount;
      }

      const formattedTaxAmount = FormatTextUtil.formatCurrencyDoubleDecimals(`${taxAmount}`);

      if (nextProps.currentValues.taxAmount !== formattedTaxAmount) {
        props.dispatch(change('virtualTerminalForm', 'taxAmount', formattedTaxAmount));
      }
    }

    if (nextProps.customers.selectedCustomer != props.customers.selectedCustomer) {
      props.dispatch(change('virtualTerminalForm', 'customer_first', nextProps.customers.selectedCustomer.first_name));
      props.dispatch(change('virtualTerminalForm', 'customer_last', nextProps.customers.selectedCustomer.last_name));

      if (nextProps.customers.selectedCustomer.email_addresses && nextProps.customers.selectedCustomer.email_addresses[0] !== null) {
        const emails = nextProps.customers.selectedCustomer.email_addresses.filter(item => !!item.replace(/\s/g, '')); //Removes any empty items
        props.dispatch(change('virtualTerminalForm', 'customer_email_addresses', emails));
      }

      if (!_.isEmpty(nextProps.customers.selectedCustomer.phone_number)) {
        props.dispatch(change('virtualTerminalForm', 'customer_phone', FormatTextUtil.formatPhoneNumber(nextProps.customers.selectedCustomer.phone_number)));
      }

      if (!!nextProps.customers.selectedCustomer.address?.id) {
        const { address } = nextProps.customers.selectedCustomer;
        props.dispatch(change('virtualTerminalForm', 'customer_street_address_1', address.street_address_1));
        props.dispatch(change('virtualTerminalForm', 'customer_street_address_2', address.street_address_2));
        props.dispatch(change('virtualTerminalForm', 'customer_city', address.city));
        props.dispatch(change('virtualTerminalForm', 'customer_state', address.state));
        props.dispatch(change('virtualTerminalForm', 'customer_zip', address.zip));
      } else {
        props.dispatch(change('virtualTerminalForm', 'customer_street_address_1', ''));
        props.dispatch(change('virtualTerminalForm', 'customer_street_address_2', ''));
        props.dispatch(change('virtualTerminalForm', 'customer_city', ''));
        props.dispatch(change('virtualTerminalForm', 'customer_state', ''));
        props.dispatch(change('virtualTerminalForm', 'customer_zip', ''));
      }
    }

    this.cashDiscountingAmount(props, nextProps);

    if (
      ( props.merchantSettings.merchantSettings === null &&
        nextProps.merchantSettings.merchantSettings &&
        nextProps.merchantSettings.merchantSettings.cash_discount_enabled
      ) ||
      ( props.merchantSettings.merchantSettings &&
        nextProps.merchantSettings.merchantSettings &&
        props.merchantSettings.merchantSettings.cash_discount_enabled !== nextProps.merchantSettings.merchantSettings.cash_discount_enabled
      )
    ) {
      props.dispatch(change('virtualTerminalForm', 'cashDiscounting', nextProps.merchantSettings.merchantSettings.cash_discount_enabled));
    }

    return;

  },

  getCashDiscountingAmount: function(props, newCart) {
    const {
      merchantSettings: { merchantSettings }
    } = props;
    if (merchantSettings?.cash_discount_enabled) {

      const cashDiscountFixedAmount = merchantSettings?.cash_discount_fixed_amount || 0.00;
      const cashDiscountPercentAmount = merchantSettings?.cash_discount_amount_percent || 0.00;
      const amount = InvoiceUtil.calculateSubTotalWithoutCashDiscounting(newCart) || 0.00;
      const cashDiscountingAmount = normalizeCashDiscount(cashDiscountFixedAmount, cashDiscountPercentAmount, amount);

      return cashDiscountingAmount;
    }
  },

  cashDiscountingAmount: function(props, nextProps) {
    const {
      dispatch, form, currentValues, itemizedCart,
      merchantSettings: { merchantSettings }
    } = props;

    const { currentValues: {
      type: nextType, amount: nextAmount,
      cashDiscountingAmount: nextCashDiscountingAmount,
      cashDiscounting: nextCashDiscounting
    },
      itemizedCart: nextCart,
      selectedPaymentType
    } = nextProps;

    const isCustomAmount = nextType === FormType.CUSTOM_AMOUNT || selectedPaymentType === 'oneTimePayment';
    const isItemizedInvoice = nextType === FormType.ITEMIZED || selectedPaymentType === 'itemizedSale';

    const amountHasChanged =
      (isCustomAmount && nextAmount !== currentValues.amount) ||
      (isItemizedInvoice && nextCart.sub_total_amt !== itemizedCart.sub_total_amt);

    const cashDiscountHasChanged =
      (nextCashDiscountingAmount !== currentValues.cashDiscountingAmount) ||
      (nextCashDiscounting !== currentValues.cashDiscounting);

    if (merchantSettings?.cash_discount_enabled &&
      (cashDiscountHasChanged || amountHasChanged)
    ) {

      const cashDiscountFixedAmount = merchantSettings?.cash_discount_fixed_amount || 0.00;
      const cashDiscountPercentAmount = merchantSettings?.cash_discount_amount_percent || 0.00;
      const amount = (isItemizedInvoice ? InvoiceUtil.calculateSubTotalWithoutCashDiscounting(nextCart) : nextAmount) || 0.00;
      const cashDiscountingAmount = normalizeCashDiscount(cashDiscountFixedAmount, cashDiscountPercentAmount, amount);

      dispatch(change(form, 'cashDiscountingAmount', cashDiscountingAmount));
    }
  },

  getTrimmedFormValues(formValues = {}) {
    let trimmedFormValues = {};

    for (const [key, value] of Object.entries(formValues)) {
      let formValue = value;

      if (typeof formValue === 'string') {
        formValue = formValue.trim();
      }

      if (formValue instanceof Array && formValue.every(value => typeof value === 'string')) {
        formValue = formValue.map(value => value.trim());
      }

      trimmedFormValues = { ...trimmedFormValues, [key]: formValue };
    }
    return trimmedFormValues;
  },

  handleManualCaptureTime: function(time) {
    if (typeof time === 'string') {
      const localTime = DateUtil.getEastToLocalTime(time, true);
      return DateUtil.localToEastTime(localTime);
    }
    return DateUtil.localToEastTime(time);
  }

};

export default FormUtil;
