/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from './shared/IconButton';
import _ from 'lodash';
import numeral from 'numeral';
import { Field, change } from 'redux-form';
import IconUtil from './util/IconUtil';
import InvoiceUtil from './util/InvoiceUtil';
import ItemThumbnail from './ItemThumbnail';
import FormatTextUtil from './util/FormatTextUtil';
import TextField from './shared/TextField';


/**
 * Default Functionality: Creates a list of items included in an order, for each displaying name, image, quantity, price, and any modifiers
 * Optional Functionality:
 * - For refunds: Can display buttons to increase/decrease the refund and restock quantities for each item.
 *      In this case needs props: fields, currentFormValues, hideInputFields
 * - For itemized VT transactions: Can display buttons to increase/decrease the quantity for an item, or delete it from order.
 *      Can also display cart discounts as another item in the list, with a button to delete it from order
 *      In this case needs props: itemCartHandlers, showCartDiscounts
 */
export default class LineItemList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mappedModifiers: []
    };
    this.changeItemQuantity = this.changeItemQuantity.bind(this);

  }

  changeItemQuantity(itemIndex, type, isSubtracting) {

    const currentValue = parseInt(this.props.currentFormValues?.lineItems?.[itemIndex]?.[type]) || 0;
    let newValue;

    if (isSubtracting) {
      newValue = (currentValue > 0) ? currentValue - 1 : 0;
    } else {
      newValue = currentValue === parseInt(this.props.currentFormValues?.lineItems?.[itemIndex]?.originalQuantity) ? currentValue : currentValue + 1;
    }

    this.props.dispatch(change('paymentDialogForm', `lineItems[${itemIndex}][${type}]`, newValue));

  }

  render() {

    const { selectedReceipt, isTransactionDetails, fields, currentFormValues, hideInputFields, itemCartHandlers, showCartDiscounts, t } = this.props;

    if (selectedReceipt) {

      const modifiersObject = selectedReceipt.modifier_sets || selectedReceipt.item_modifiers;
      const expressModifiers = selectedReceipt.express_options;

      const hasItemCartHandlers = !_.isEmpty(itemCartHandlers);
      const hasItems = !!(selectedReceipt.item_ids && selectedReceipt.item_ids.length && !_.isEmpty(selectedReceipt.item_ids.filter(item => !!item)));
      const hasDiscountRate = !!(selectedReceipt.item_discount_rate && selectedReceipt.item_discount_rate.length && !_.isEmpty(selectedReceipt.item_discount_rate.filter(item => !!item)));
      const hasDiscountAmount = !!(selectedReceipt.item_discount_amt && selectedReceipt.item_discount_amt.length && !_.isEmpty(selectedReceipt.item_discount_amt.filter(item => !!item)));
      const hasModifiers = !!(modifiersObject && modifiersObject.length && !_.isEmpty(modifiersObject.filter(item => !!item)));
      const hasExpressModifiers = expressModifiers?.length > 0 && !_.isEmpty(expressModifiers.filter(item => !!item));
      let mappedModifiers = []; //Will be an array of arrays. One array of modifiers for each item
      let expressModifierTotals = [];
      const numberOfItems = selectedReceipt.item_ids?.length;

      if (hasItems && hasModifiers) {
        const isModifiersSimpleArray = _.isEmpty(modifiersObject.filter(modifier => Array.isArray(modifier))); //Simple array without nested arrays

        mappedModifiers = !isModifiersSimpleArray
          ? modifiersObject
          : selectedReceipt.item_ids.map(itemId => modifiersObject.filter(modifier => modifier && modifier.includes(itemId)));

        mappedModifiers =  !!isTransactionDetails ? mappedModifiers.map(InvoiceUtil.mapModifiers) : mappedModifiers.map(InvoiceUtil.mapItemModifiers);
      }

      if (hasItems && hasExpressModifiers) {
        expressModifierTotals = expressModifiers.map(modifiers => modifiers.reduce((partialSum, modifier) => partialSum + numeral(modifier?.price || 0).value(), 0));
      }

      const cdItemPrices = selectedReceipt.cd_item_price?.length > 0 ? selectedReceipt.cd_item_price : selectedReceipt.price_total_amount;
      const cdItemModifierPrices = selectedReceipt.cd_item_modifier_prices?.length > 0 && selectedReceipt.cd_item_modifier_prices;
      const cdItemExpressModifierPrices = selectedReceipt.cd_item_express_modifier_prices?.length > 0 && selectedReceipt.cd_item_express_modifier_prices;
      const cdItemDiscountAmounts = selectedReceipt.cd_item_discount_amt?.length > 0 ? selectedReceipt.cd_item_discount_amt : selectedReceipt.item_discount_total_amt;

      const plusIcon = IconUtil.getIcon('AddIcon');
      const minusIcon = IconUtil.getIcon('SubtractIcon');
      const deleteIcon = IconUtil.getIcon('CustomDeleteIcon');

      const shouldRenderItemRefundOptions = fields && fields.length && currentFormValues && currentFormValues.isItemized && !hideInputFields;

      return (
        <ul className={`detailList ${hasItemCartHandlers ? 'withItemCartHandlers' : ''}`}>
          {selectedReceipt.item_names?.length > 0 && selectedReceipt.item_names.map((item, itemIndex) => {

            const quantityInUse = currentFormValues && currentFormValues.lineItems && currentFormValues.lineItems[itemIndex]
              ? currentFormValues.lineItems[itemIndex]['refundQuantity']
              : selectedReceipt.item_quantity[itemIndex];

            const totalAmount = cdItemPrices?.[itemIndex] > 0
              ? cdItemPrices[itemIndex]
              : selectedReceipt.item_unit_price[itemIndex] * quantityInUse;

            const currentRefundQuantity = currentFormValues && currentFormValues.lineItems && parseInt(currentFormValues.lineItems[itemIndex]['refundQuantity']);
            const availableRestockQuantity = currentFormValues && currentFormValues.lineItems && parseInt(currentFormValues.lineItems[itemIndex]['availableRestockQuantity']);

            const currentRestockQuantity = currentFormValues && currentFormValues.lineItems && parseInt(currentFormValues.lineItems[itemIndex]['restockQuantity'])
            const originalQuantity = currentFormValues && currentFormValues.lineItems && parseInt(currentFormValues.lineItems[itemIndex].originalQuantity);

            const parsedItem = FormatTextUtil.formatItemName(item, selectedReceipt?.item_price_names?.[itemIndex]);

            return (
              <li className='detailItem regularItem' key={itemIndex}>

                <div className='itemMainContainer' onClick={itemCartHandlers?.editItem ? () => itemCartHandlers.editItem(itemIndex) : undefined}>
                  <div className='itemThumbnailContainer'>
                    <ItemThumbnail {...this.props} item={{
                      name: item,
                      id: selectedReceipt.item_ids[itemIndex],
                      image_id: selectedReceipt.item_image_id[itemIndex]
                    }}/>
                    <span className='itemQuantity'>{`${selectedReceipt.item_quantity[itemIndex]}`}</span>
                  </div>

                  <span className={`itemInfo ${currentFormValues && (currentFormValues.refundAll && currentFormValues.isItemized) ? 'disabled' : ''}`}>

                    <p className='name'>
                      <span className='text'>{parsedItem}</span>
                      <span className='number'>{numeral(totalAmount).format('$0,0.00')}</span>

                      {selectedReceipt.item_tax_rate[itemIndex] > 0 &&
                        <span className='taxed'>{t('Taxed')}</span>
                      }
                    </p>

                    {hasModifiers && mappedModifiers[itemIndex] && Object.keys(mappedModifiers[itemIndex]).length > 0 ?
                      mappedModifiers[itemIndex].map((modifier, modifierIndex) => (
                        <p className='specifics modifier' key={modifierIndex}>
                          <span className='text'>{modifier?.name}</span>
                          <span className='number'>
                            {
                              numeral(!!cdItemModifierPrices
                                ? cdItemModifierPrices[itemIndex][modifierIndex]
                                : Number(modifier?.total_amt) > 0
                                  ? modifier?.total_amt
                                  : Number(modifier?.price) * quantityInUse
                              ).format('$0,0.00')
                            }
                          </span>
                        </p>
                      )) : null}

                    {hasExpressModifiers && !!expressModifierTotals[itemIndex] &&
                      <p className='specifics modifier'>
                        <span className='text'>{t('ExpressModifiers')}</span>
                        <span className='number'>
                          {
                            numeral(!!cdItemExpressModifierPrices
                              ? cdItemExpressModifierPrices[itemIndex].reduce((partialSum, modifier) => partialSum + modifier, 0)
                              : expressModifierTotals[itemIndex] * quantityInUse
                            ).format('$0,0.00')
                          }
                        </span>
                      </p>
                    }

                    {hasDiscountRate && selectedReceipt.item_discount_rate[itemIndex] ?
                      (
                        <p className='specifics'>
                          <span className='text'>{t('Discount')}</span>
                          <span className='number'>{`-${selectedReceipt.item_discount_rate[itemIndex]}%`}</span>
                        </p>
                      ) : hasDiscountAmount && selectedReceipt.item_discount_amt[itemIndex] ?
                        (
                          <p className='specifics'>
                            <span className='text'>{t('Discount')}</span>
                            <span className='number'>
                              {
                                '-' + numeral(
                                  cdItemDiscountAmounts?.[itemIndex] > 0 ? cdItemDiscountAmounts[itemIndex] : selectedReceipt.item_discount_amt[itemIndex]
                                ).format('$0,0.00')
                              }
                            </span>
                          </p>
                        ) : null
                    }

                </span>

                </div>

                {hasItemCartHandlers && (
                  <div className='itemModifyOptions'>
                    <IconButton
                      onClick={() => itemCartHandlers.changeItemQuantity(itemIndex, false)}
                      className='itemModifyButton'
                      size='large'>
                      {plusIcon}
                    </IconButton>
                    <IconButton
                      onClick={() => itemCartHandlers.changeItemQuantity(itemIndex, true)}
                      className='itemModifyButton'
                      disabled={selectedReceipt.item_quantity[itemIndex] === 1}
                      size='large'>
                      {minusIcon}
                    </IconButton>
                    <IconButton
                      onClick={() => itemCartHandlers.deleteItem(itemIndex)}
                      className='itemModifyButton'
                      size='large'>
                      {deleteIcon}
                    </IconButton>
                  </div>
                )}

                {shouldRenderItemRefundOptions  ? (
                  <div className='itemRefundOptions'>

                    {currentFormValues && currentFormValues.isItemized && !hideInputFields && (
                      <div className='quantitySelector refundQuantitySelector'>
                        <IconButton
                          onClick={() => this.changeItemQuantity(itemIndex, 'refundQuantity', true)}
                          className='quantityButton'
                          disabled={currentFormValues && (!currentFormValues.isItemized || currentFormValues.refundAll || currentRefundQuantity === 0)}
                          size='large'>
                          {minusIcon}
                        </IconButton>
                        <Field
                          component={TextField}
                          name={`lineItems[${itemIndex}][refundQuantity]`}
                          label={t('Refund')}
                          className='lineItemRefundQuantity'
                          disabled={currentFormValues && (!currentFormValues.isItemized || currentFormValues.refundAll)}
                          normalize={FormatTextUtil.formatWholeNumber}
                        />
                        <IconButton
                          onClick={() => this.changeItemQuantity(itemIndex, 'refundQuantity', false)}
                          className='quantityButton'
                          disabled={currentFormValues && (!currentFormValues.isItemized || currentFormValues.refundAll || currentRefundQuantity === originalQuantity)}
                          size='large'>
                          {plusIcon}
                        </IconButton>
                      </div>
                    )}

                    {!hideInputFields && (
                      <div className='quantitySelector restockQuantitySelector'>
                        <IconButton
                          onClick={() => this.changeItemQuantity(itemIndex, 'restockQuantity', true)}
                          className='quantityButton'
                          disabled={currentFormValues && currentRestockQuantity === 0}
                          size='large'>
                          {minusIcon}
                        </IconButton>
                        <Field
                          component={TextField}
                          name={`lineItems[${itemIndex}][restockQuantity]`}
                          label={t('ReStock')}
                          className='lineItemRestockQuantity'
                          normalize={FormatTextUtil.formatWholeNumber}
                        />
                        <IconButton
                          onClick={() => this.changeItemQuantity(itemIndex, 'restockQuantity', false)}
                          className='quantityButton'
                          disabled={currentFormValues && currentRestockQuantity === availableRestockQuantity}
                          size='large'>
                          {plusIcon}
                        </IconButton>
                      </div>
                    )}

                  </div>
                ) : null}

              </li>
            );})}

          {showCartDiscounts && !!selectedReceipt.receipt_discount_name?.length && selectedReceipt.receipt_discount_name.map((discountName, discountIndex) => {

            const formattedColor = selectedReceipt.receipt_discount_color && selectedReceipt.receipt_discount_color[discountIndex]
              ? '#' + selectedReceipt.receipt_discount_color[discountIndex]
              : '#888C8D';

            const discountValue = selectedReceipt.receipt_discount_type[discountIndex] === 'percent'
              ? `${selectedReceipt.receipt_discount_percentage[discountIndex]}% ${t('Off')}`
              : `${numeral(selectedReceipt.receipt_discount_amt[discountIndex]).format('$0,0.00')} ${t('Off')}`

            const discountTotal = `-${numeral(selectedReceipt.receipt_discount_amt[discountIndex]).format('$0,0.00')}`;

            return (
              <li className='detailItem cartDiscount' key={discountIndex + numberOfItems}>
                <div className='itemMainContainer'>
                  <div className='itemThumbnailContainer'>
                    <IconButton disableTouchRipple className='discountIcon' size='large'>
                      <Avatar className='discountIconAvatar' style={{ width:50, height: 50, backgroundColor: formattedColor }}>
                        {IconUtil.getIcon('DiscountIcon', '#FFFFFF')}
                      </Avatar>
                    </IconButton>
                  </div>

                  <span className={'itemInfo'}>
                    <p className='name'>
                      <span className='text'>{discountName}</span>
                      <span className='number'>{discountTotal}</span>
                    </p>

                    <p className='specifics'>
                      <span className='text'>{discountValue}</span>
                      <span className='number'></span>
                    </p>

                  </span>

                </div>

                {hasItemCartHandlers && (
                  <div className='itemModifyOptions'>
                    <IconButton
                      onClick={() => itemCartHandlers.deleteDiscount(discountIndex)}
                      className='itemModifyButton'
                      size='large'>
                      {deleteIcon}
                    </IconButton>
                  </div>
                )}

              </li>
            );
          })}

        </ul>
      );

    } else {
      return null;
    }

  }
}
