/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

Copyright 2000 NAB, All Rights Reserved.

NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
and Non-disclosure agreements explicitly covering such access.

The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
MAY DESCRIBE, IN WHOLE OR IN PART.

*/

import React, {Component} from 'react';

export default class LoyaltyTerms extends Component {

  render() {

    return (
      <div className='nonAuthTerms'>
        <h2>Loyalty Program Terms and Conditions (“Loyalty Terms”)</h2>
        <p>
          Subject your compliance with these Loyalty Terms and the Merchant Credit Card Processing Agreement (“Merchant Agreement”) Terms and Conditions (“Merchant Terms”) between the Merchant (“you” or “your”) and North American Bancard, LLC (“NAB”, “we”, or “our”), NAB permits you to offer your customers (“Customers”) the ability to earn loyalty rewards (“Rewards”) and to manage your business’ rewards program (“Rewards Program”) through the Payments Hub loyalty feature (the “Loyalty Program”). These Loyalty Terms are intended to supplement, not replace, the Merchant Terms.
        </p>
        <h3>1. Loyalty Program.</h3>
        <p>
          You will have access to the Loyalty Program through Payments Hub, where you can track Rewards and modify the various features of your Rewards Program. You agree to: (i) operate your Rewards Program in a fair and non-abusive manner that complies with applicable Laws (defined below), (ii) maintain the confidentiality of your Payments Hub login information, (iii) implement technical and administrative safeguards to secure all devices with access to Payments Hub, and (iv) establish terms and conditions for your Rewards Program that comply with applicable Law(s) and are made available to participating Customers. You agree that while NAB grants you access to its Loyalty Program, the responsibility for managing the Loyalty Program lies solely with you. You acknowledge and agree that the purpose of this Loyalty Program is to award Customers Rewards for eligible purchases at the rate of and levels of accumulation you set forth in relation to dollars spent, items purchased or number of visits during which a purchase was made by the Customer. If provided the option, you may also award Rewards for other legal activities you designate. You agree that Rewards will have no monetary value and may not be redeemed for cash or any prohibited item.
        </p>
        <h3>2. Compliance With Laws.</h3>
        <p>
          By enabling and using the Loyalty Program, you agree to comply with all applicable federal and state laws and regulations, including but not limited to all consumer privacy laws that may apply to your use of the Loyalty Program (collectively, the “Laws”). Applicable consumer privacy Laws may include the California Consumer Privacy Act, the  Telephone Consumer Protection Act and its implementing regulations such as the Telemarketing Sales Rule, the CAN-SPAM Act, and any state law counterparts. You are solely responsible for your compliance with all Laws, these Loyalty Terms, and the Merchant Agreement; failure to comply may result in termination of your access to the Loyalty Program, at NAB’s sole discretion. In the event these Loyalty Terms or the Merchant Terms conflict with any Law, the applicable Law(s) shall control with respect to your use of the Loyalty Program. By using the Loyalty Program you represent that you have reviewed the Federal Communications Commission’s and Federal Trade Commission’s regulations and guidelines relating to telephone and electronic marketing, and that you have obtained independent legal advice as appropriate to ensure your compliance with the Laws and any restrictions related to your use of the Loyalty Program. You acknowledge and understand that NAB is not responsible for your compliance with any Laws, and that your adherence to these Loyalty Terms does not ensure compliance with Laws.
        </p>
        <h3>3. Customer Data.</h3>
        <p>
          We may provide you with certain specified data regarding Customers, including the Customer’s name, email address, number of visits and purchases, visits details, and certain other data regarding the Customer’s activities at your business (“Consumer Data”) during a Customer’s participation in the Loyalty Program. You agree that you will not use or disclose any Customer Data in any way or to any person that is inconsistent with Laws or the Loyalty Terms. You acknowledge that your Customers must agree to participate in your Rewards Program in the manner or method set forth within Payments Hub. You may not enroll Customers without their express consent. You shall fully indemnify NAB (and its officers, directors, employees and consultants), as set forth herein, for all breaches of your obligations under this provision.
        </p>
        <h3>4. Term.</h3>
        <p>
          Unless earlier terminated (i) by NAB in its sole discretion, or (ii) by you, at your option, provided that you provide no less than seven (7) days’ notice, you shall have access to the Loyalty Program for as long as you are current in paying all applicable fees associated with the Loyalty Program. Upon termination of your subscription that includes this feature, your access to the Loyalty Program will terminate and all Rewards tracking will cease. You will not receive a refund of any fees associated with any paid feature that includes the Loyalty Program if, (i) we terminate your access to the Loyalty Program or paid feature that include the Loyalty Program for any violation, or (ii) if you terminate your participation in a paid feature that includes the Loyalty Program with any partial period remaining. You are responsible for (i) ensuring that your Customers are notified of the termination of your Rewards Program in accordance with applicable Laws, and (ii) providing any required compensation for your Customers’ outstanding Rewards at the time of termination. You are liable for any damages related to such termination, whether terminated at your option or in NAB’s discretion.
        </p>
        <h3>5. Cancellation or Updates.</h3>
        <p>
          Notwithstanding anything in these Loyalty Terms to the contrary, we may terminate the provision of the Loyalty Program to you (i) immediately, if you are no longer eligible for the Loyalty Program, including by failure to pay the required fees, and (ii) at any time, in our discretion, upon no less than seven (7) days’ prior notice to you. If cancellation occurs for any reason, (i) you will no longer be able to offer Rewards through the Loyalty Program, and (ii) you will continue to be responsible to redeem any outstanding Rewards earned by your Customers for the period set forth in your Rewards Program. In addition to cancellation, we retain the right to update the Loyalty Program and any related fees. We will provide adequate notice in the event of any updates affecting pricing or in the event of termination of the Loyalty Program.
        </p>
        <h3>6. Representations and Warranties.</h3>
        <p>You represent and warrant that:</p>
        <ol type='a'>
          <li>
            You will not require Customers already participating in your Rewards Program to provide additional personal information in order to redeem Rewards.
          </li>
          <li>You will honor all Rewards earned by Customers.</li>
          <li>You will manage your Rewards Program in good faith.</li>
          <li>
            You will resolve Customer disputes related to your Rewards Program fairly and within a reasonable time.
          </li>
        </ol>
        <h3>7. Indemnification.</h3>
        <p>
          Notwithstanding, but in addition to, your indemnification obligations set forth in the Merchant Agreement, you agree to defend, indemnify and hold harmless NAB from and against any and all suits, proceedings, assertions, damages, costs, liabilities or expenses (including court costs and reasonable attorneys’ legal fees) which NAB incurs in connection with any actual claim, demand, action or other proceeding by any third party arising from or relating to (i) a claim that you have violated any Customer rights, (ii) your use of any Consumer Data not in compliance with applicable Laws, rules or regulations, or our privacy policy, (iii) any fraud or misrepresentation related to the Rewards you offer to Customers, or (iv) any other breach of these Loyalty Terms, including the representations and warranties set forth in Section 6 above.
        </p>
        <h3>8. Limitation of Liability.</h3>
        <p>
          IN NO EVENT SHALL WE, OUR DIRECTORS, OFFICERS, EMPLOYEES, AGENTS OR SHAREHOLDERS, BE LIABLE TO MERCHANT, ANY CUSTOMER OR ANY OTHER THIRD PARTY FOR ANY DAMAGES, INCLUDING BUT NOT LIMITED TO GENERAL, INCIDENTAL, CONSEQUENTIAL, INDIRECT, DIRECT, SPECIAL OR PUNITIVE DAMAGES, ARISING OUT OF OR RELATING TO YOUR USE OF THE LOYALTY PROGRAM. NOTWITHSTANDING THE LIABILITY CAP SET FORTH IN THE MERCHANT AGREEMENT, OUR ENTIRE LIABILITY UNDER ANY PROVISION OF THESE LOYALTY TERMS OR THE LOYALTY PROGRAM SHALL NOT EXCEED $1,000.
        </p>
        <br/>
        <h3>092023</h3>
      </div>
    )
  }
}
