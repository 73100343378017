/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, {Component} from 'react';
import IconUtils from '../../util/IconUtil';
import { setOpenDepositTransactionsDialog, setSelectedDepositDetail } from '../../../actions/depositsActions';
import Loading from '../../Loading';
import NoData from '../../NoData';
import DepositTransactionsReport from './DepositTransactionsReport';
import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';
import Modal from '../../shared/Modal';

export default class BatchReport extends Component {

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.dispatch(setOpenDepositTransactionsDialog(false));
  }

  setReportToView(depositDetail, props) {
    props.dispatch(setOpenDepositTransactionsDialog(true));
    props.dispatch(setSelectedDepositDetail(depositDetail))
  }

  render() {

    const {isFetching, deposits, t} = this.props;

    const isEmpty = deposits.data === null;

    if (isEmpty && isFetching) {
      return (
        <Loading/>
      )
    } else {

      const depositsWithTransactions = _.filter(deposits.data,  (deposit) => !!(deposit && deposit.type));

      const depositId = deposits.selectedDeposit ? deposits.selectedDeposit.uniq_id : null;

      const depositDate = deposits.selectedDeposit ? moment(deposits.selectedDeposit.effective_date).format('MM/DD/YYYY') + ' ' +
        moment(deposits.selectedDeposit.effective_date).format('h:mm a') : null;

      const depositAmount = deposits.selectedDeposit ? numeral(deposits.selectedDeposit.amount_to_clear).format('$0,0.00') : null;

      const depositType = deposits.selectedDeposit ? deposits.selectedDeposit.type : '';
      const dialogHeader = depositType + ' Transaction for ' + depositAmount + ' on ' + depositDate;

      const depositTransactionsReportDialog = depositId ? (
        <Modal
          externalClassName='depositTransactionsReportDialog'
          maxWidth='lg'
          title={dialogHeader}
          open={this.props.userExperience.openDepositTransactionsDialog}
          onClose={this.handleClose}
          hideConfirmButton
        >
          <DepositTransactionsReport {...this.props} depositId={depositId}/>
        </Modal>
      ) : null;

      const depositsList = depositsWithTransactions.length > 0 ? (
        depositsWithTransactions.map((deposit, i) => {

          const iconToShow = IconUtils.getDepositIcon(deposit.type);

          return (
            <div key={i} className='flexRowItem'>
              <div className='depositType'><span>{iconToShow}</span><span>{deposit.type}</span></div>
              <div>{moment(deposit.deposit_date).format('MM/DD/YYYY') + ' ' + moment(deposit.deposit_date).format('h:mm a')}</div>
              <div>{numeral(deposit.amount_to_clear).format('$0,0.00')}</div>
              {(deposit && deposit.ndf && deposit.ndf === 1) && <div className='depositTransactionLink' onClick={this.setReportToView.bind(null, deposit, this.props)}>{t('View').toUpperCase()}</div>}
            </div>)
        })) : (<NoData text={t('NoDataBatchReport')} />);


      return (
        <div className='depositTransactionsDetail'>
          {depositsList}
          {depositTransactionsReportDialog}
        </div>
      )
    }

  }
}

BatchReport.defaultProps = {
  isFetching: true,
};
