/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL
 
 Copyright 2000 NAB, All Rights Reserved.
 
 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.
 
 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.
 
 */

export const customerHeadlineStyle = {
 display: { xs: 'block', sm: 'block', md: 'block', lg: 'flex', xl: 'flex' },
 justifyContent: 'space-between',
 paddingBottom: '5px',
 borderBottom: '1px solid',
 borderColor: 'common.lineColor',
 marginBottom: {xs: '15px', sm: '15px', md: '15px', lg: '60px', xl: '60px'}
};

export const customerHeadlineTextStyle = {
 fontFamily: 'primaryFontFamilyRegular',
 fontSize: '14px',
 color: 'common.paymentDetailText',
 fontWeight: 'normal'
};

export const paymentPageMainTitle = {
 fontFamily: 'centuryGothicBold',
 fontSize: '30px',
 margin: 0
};

export const paymentPageDetailTitle = {
 ...paymentPageMainTitle,
 margin: {xs: '10px 0 0 0', sm: '10px 0 0 0', md: '10px 0 0 0', lg: '110px 0 0 0', xl: '110px 0 0 0'},
 textAlign: {xs: 'center', sm: 'center', md: 'center', lg: 'left', xl: 'left'}
};

export const paymentPageDetailSummaryTitle = {
 fontFamily: 'interBold',
 fontSize: '18px',
 textAlign: 'center',
 marginBottom: '15px'
};

export const paymentPageDetailSummarySubTitle = {
 fontFamily: 'interRegular',
 fontSize: '14px',
 textAlign: 'center',
 color: 'common.paymentDetailText',
 margin: '0 0 5px 0',
 overflowWrap: 'break-word'
};

export const paymentPageDetailSummaryMain = {
 fontFamily: 'interSemiBold',
 fontSize: '14px',
 marginBottom: '5px',
 '&:last-of-type': {
  marginBottom: 0
 }
};

export const paymentPageDetailSummarySub = {
 fontFamily: 'interRegular',
 color: 'common.paymentDetailText',
 fontSize: '14px',
 marginBottom: '5px',
 '&:last-of-type': {
  marginBottom: 0
 }
};

export const paymentPageDetailSummaryPrice = {
 fontFamily: 'interSemiBold',
 margin: '0 0 5px auto',
 fontSize: '14px'
};

export const paymentPageDetailSummaryReward = {
 fontFamily: 'interRegular',
 color: 'common.paymentDetailText',
 fontSize: '14px',
 marginBottom: '45px'
};

export const PaymentErrorModalText = {
 margin: 0
}

export const paymentPagePaidAchNotice = {
 margin: '20px 0'
}

export const paymentPagePayDialogActions = {
 margin: '30px 0'
};

//invoiceDetailPaid
export const invoiceDetailPaidContainer = {
 display: 'flex',
 flexDirection: 'column',
 alignItems: 'center'
}

export const invoiceDetailPaidTitle = {
 fontFamily: 'centuryGothicBold',
 color: 'common.customerPaymentPrimaryColor',
 fontSize: '30px',
 marginTop: '15px',
 marginBottom: '35px'
}

export const invoiceDetailPaidText = {
 fontFamily: 'centuryGothicRegular',
 fontSize: '17px'
}

export const invoiceDetailPaidTextTop = {
 ...invoiceDetailPaidText,
 marginTop: '15px',
 'span': {
  fontWeight: 'bolder'
 }
};

export const invoiceDetailPaidTextBold = {
 ...invoiceDetailPaidText,
 fontWeight: 'bold'
}

export const invoiceDetailPaidSummary = {
 boxSizing:' border-box',
 width: '100%',
 padding: '32px',
 backgroundColor: 'common.paymentPageGrey',
 marginTop: '25px',
 marginBottom: '50px'
}

export const invoiceDetailPaidSummaryRow = {
 display: 'flex',
 flexDirection: {xs: 'column', lg: 'row'},
 justifyContent: 'space-between'
}
//invoiceDetailPaid

//loyalty status
export const loyaltyStatusContainer = {
 boxSizing: 'border-box',
 width: '100%',
 display: 'flex',
 padding: '32px',
 backgroundColor: 'common.paymentPageGrey',
 marginBottom: '50px',
 alignItems: 'center'
}

export const loyaltyStatusContainerText = {
 marginLeft: '10px'
}
//loyalty status

//loyalty Enroll
export const enrollInLoyaltyBox = {
 paddingBottom: '35px'
}

export const enrollInLoyaltyBoxHeader = {
 textAlign: 'center'
}

export const enrollInLoyaltyCustomerInfo = {
 fontSize: '19px',
 fontWeight: 'bold'
}

export const enrollInLoyaltyProgramDetails = {
 marginTop: '5px'
}

export const enrollInLoyaltyToggle = {
 marginTop: '15px',
 backgroundColor: 'common.paymentPageGrey',
 padding: '32px'
}

export const enrollInLoyaltyToggleControl = {
 display: 'flex',
 justifyContent: 'space-between'
}

export const enrollInLoyaltyToggleText = {
 margin: '0',
  fontSize: '14px'
}

export const enrollInLoyaltyFormContainer = {
 display: 'flex',
 flexDirection: 'column',
 alignItems: 'center',
 '& button': {
  maxWidth: '150px'
 }
}
//loyalty Enroll
