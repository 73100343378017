/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues, reset} from 'redux-form';
import TextField from '../TextField';
import Select from '../Select';
import VisibilityToggle from '../../visibilityToggle';
import FormatTextUtil from '../../util/FormatTextUtil';
import IconUtil from '../../util/IconUtil';
import Validator from '../../util/Validator';
import withTranslate from '../../../hoc/withTranslate';
import {MenuItem} from '@mui/material';
import {PaymentMethod} from '../../util/InvoiceUtil';
import BankingForm from '../ach/BankingForm';

export const validate = (values, props) => {
  if (!_.isEmpty(values)) {
    return Validator.validateCardOnFileForm(values, props);
  }
  return {};
};

export class CardOnFileForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardVisibility: false
    };
    this.toggleCardNumberVisibility = this.toggleCardNumberVisibility.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }

  toggleCardNumberVisibility () {
    this.setState(prevState => ({ cardVisibility: !prevState.cardVisibility }));
  }

  resetForm () {
    this.props.dispatch(reset('cardOnFileForm'));
  }

  render () {
    const { handleSubmit, submitting, cardOnFileFormValues, showAvsNotice, showAcknowledgement, t, ignoreAvsFailure, achEnabled, isClp, isRecurringInvoice, merchant, notificationType, notificationTime, isManagingPaymentMethods = false } = this.props;
    const { cdigits } = cardOnFileFormValues;
    const cardType = cdigits && FormatTextUtil.parseCardType(cdigits);
    const isAmex = cardType === 'amex';
    const cardImgUrl = cardType ?
      `${serverDomainUrl}images/cards/${cardType}.png` :
      `${serverDomainUrl}images/cards/unknown_card.png`;
    const { cardVisibility } = this.state;

    return (
      <form onSubmit={handleSubmit}>
        <div className='cardOnFileForm'>
          <Field
            component={Select}
            label={t('PayAndSaveForm.PaymentMethodField')}
            name='methodType'
            className='methodTypeSelect'
            onChange={this.resetForm}
          >
            <MenuItem value={'creditCard'}>{t('PayAndSaveForm.PayWithCreditCardOption')}</MenuItem>
            {achEnabled && <MenuItem value={'bankingAccount'}>{t('PayAndSaveForm.PayWithBankAccountOption')}</MenuItem>}
          </Field>
          {cardOnFileFormValues.methodType === PaymentMethod.CREDIT_CARD && <>
            <Field
              component={TextField}
              label={t('CardOnFile.Fields.CardHolderName')}
              hintText={t('CardOnFile.Fields.CardHolderName')}
              disabled={submitting}
              name='cardHolderName'
            />
            <div className='cardNumberContainer'>
              {/*The label text is in css to stop safari from saving the card number*/}
              <Field
                className={`cardNumber textField ${cardVisibility ? 'visible' : ''}`}
                component={TextField}
                label={t('CardOnFile.Fields.CDigits')}
                maxLength='25'
                hintText='0000-0000-0000-0000'
                name='cdigits'
                normalize={FormatTextUtil.formatCardNumber}
                disabled={submitting}
              />
              <span className='cardImage'>
               <img src={cardImgUrl} width='50' />
             </span>
              <VisibilityToggle
                visibility={cardVisibility}
                onMouseDown={this.toggleCardNumberVisibility}
                onClick={this.toggleCardNumberVisibility}
              />
            </div>
            <div className='flex'>
              <Field
                className='textField shrinkSpacing halfRow'
                component={TextField}
                disabled={submitting}
                hintText='MM/YY'
                label={t('CardOnFile.Fields.EDate')}
                maxLength='5'
                name='edate'
                normalize={FormatTextUtil.formatCreditCardExpiration}
              />
              <Field
                className='textField halfRow'
                disabled={submitting}
                component={TextField}
                hintText={isAmex ? 'CID' : 'CVV'}
                label={isAmex ? 'CID' : 'CVV'}
                maxLength={'10'}
                name='cvv'
                normalize={FormatTextUtil.formatWholeNumber}
              />
            </div>
            <div className='flex'>
              <Field
                className='textField halfRow'
                disabled={submitting}
                component={TextField}
                hintText={ignoreAvsFailure ? t('CardOnFile.Fields.Optional') : t('CardOnFile.Fields.StreetNumber')}
                label={t('CardOnFile.Fields.StreetNumber')}
                maxLength='50'
                name='streetNumber'
                normalize={FormatTextUtil.formatStreetNumber}
              />
              <Field
                className='textField quarterRow'
                disabled={submitting}
                component={TextField}
                hintText={ignoreAvsFailure ? t('CardOnFile.Fields.Optional') : t('CardOnFile.Fields.CardZip')}
                label={t('CardOnFile.Fields.CardZip')}
                maxLength='5'
                name='cardZip'
                normalize={FormatTextUtil.formatWholeNumber}
              />
              <Field
                className='textField quarterRow'
                disabled={submitting}
                component={TextField}
                hintText={t('CardOnFile.Fields.Optional')}
                label={t('CardOnFile.Fields.ZipPlus4')}
                maxLength='4'
                name='zipPlus4'
                normalize={FormatTextUtil.formatWholeNumber}
              />
            </div>
            {showAvsNotice && <div className='vtAvsNotice'>
              {t('AvsNotice')}
            </div>}
            {showAcknowledgement &&
              <div className='cardAcknowledgement'>
              <span className='cardAcknowledgementIcon'>
                {IconUtil.getIcon('LightBulbIcon', 'inherited', 15)}
              </span>
                <span>
                {t('CardOnFile.Acknowledgement')}
              </span>
              </div>
            }
            </>}
          {cardOnFileFormValues.methodType === PaymentMethod.BANKING_ACCOUNT &&
            <BankingForm
              submitting={submitting}
              t={t}
              isClp={isClp}
              isRecurringInvoice={isRecurringInvoice}
              merchant={merchant}
              notificationType={notificationType}
              notificationTime={notificationTime}
              isManagingPaymentMethods={isManagingPaymentMethods}
            />
          }
        </div>
      </form>);
  }
}


function mapStateToProps(state, ownProps) {
  const { methodType, cardHolderName, cdigits, cvv, edate, streetNumber, cardZip, zipPlus4, accountType, routingNumber, nameOnAccount, accountNumber } = ownProps?.valuesToEdit || {};
  const cardOnFileFormValues = getFormValues('cardOnFileForm')(state) || {};
  return {
    initialValues: {
      cvv: cvv ?? '',
      edate: edate ?? '',
      cdigits: cdigits ?? '',
      cardZip: cardZip ?? '',
      zipPlus4: zipPlus4 ?? '',
      cardHolderName: cardHolderName ?? '',
      streetNumber: streetNumber ?? '',
      methodType: methodType ?? 'creditCard',
      accountType: accountType ?? 'personal checking',
      routingNumber: routingNumber ?? '',
      nameOnAccount: nameOnAccount ?? '',
      accountNumber: accountNumber ?? '',
      achTerms: false
    },
    cardOnFileFormValues
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'cardOnFileForm',
    validate,
    fields: [
      'methodType',
      'accountType',
      'accountName',
      'accountNumber',
      'routingNumber',
      'cvv',
      'edate',
      'cdigits',
      'cardZip',
      'zipPlus4',
      'cardHolderName',
      'streetNumber',
      'achTerms'
    ],
    enableReinitialize: true
  })(withTranslate(CardOnFileForm)));
