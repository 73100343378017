/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, {Component} from 'react';
import LabelUtil from '../util/LabelUtil';

export default class UserAgreementGlobal extends Component {

  render() {

    const label = LabelUtil.getLabel();

    const styles = {
      link: {
        color: label.primaryColor,
        textDecoration: 'none'
      }
    };

    return (
      <div className='userAgreementGlobal'>

        <h2>TERMS AND CONDITIONS OF MERCHANT SERVICE AGREEMENT</h2>

        <p><strong>1. INTRODUCTION AND DEFINITIONS. </strong></p>
        <p>This document, "Terms and Conditions of Merchant Service Agreement" ("Terms and Conditions") accompanies the
          document "Global Payments Merchant Application" ("Merchant Application"). The Merchant Service Agreement
          ("Agreement" or "Card Services Agreement") includes these Terms and Conditions and the terms and conditions of
          the Merchant Application. The Member bank identified in such Merchant Application ("Member") is a member of
          Visa USA, Inc. ("Visa") and Mastercard International, Inc. ("Mastercard"). Global Payments Direct, Inc.
          ("Global") is a registered independent sales organization of Visa, a member service provider of Mastercard and
          a registered acquirer for Discover Financial Services LLC ("Discover"), and a registered Program Participant
          of American Express Travel Related Services Company, Inc. ("American Express"). Merchant and Global Direct
          agree that the rights and obligations contained in these Terms and Conditions of the Merchant Service
          Agreement do not apply to the Member with respect to Discover and PayPal transactions and Switched
          Transactions (as defined below). To the extent Merchant accepts Discover cards, the provisions in this
          Agreement with respect to Discover apply if Merchant does not have a separate agreement with Discover. To the
          extent Merchant accepts Discover cards and has a separate agreement with Discover, Discover and PayPal card
          transactions shall be processed as Switched Transactions (as defined below). To the extent Merchant accepts
          American Express cards, the provisions in this Agreement with respect to American Express apply if Merchant
          does not have a separate agreement with American Express. Any references to the Debit Sponsor shall refer to
          the debit sponsor identified below. The Agreement is between Global Direct, the Member, and the merchant
          identified in the Merchant Application ("Merchant"). Under the terms of the Agreement, Merchant will be
          furnished with the services and products, including any software, described herein and in the Merchant
          Application, and selected by Merchant therein (collectively and individually, as applicable, the "Services").
          Global Direct will be the sole and exclusive provider of the Services to Merchant during the term of this
          Agreement. Any Merchant accepted by Global Direct for card processing services agrees to be bound by the
          Agreement, including the terms of the Merchant Application and these Terms And Conditions as may be modified
          or amended in the future. A Merchant's submission of a transaction to Global Direct shall be deemed to signify
          Merchant's acceptance of the Merchant Services Agreement, including the Terms and Conditions herein. Except as
          expressly stated in the first three paragraphs of Section 13, all terms and conditions of this Merchant
          Services Agreement shall survive termination to the extent necessary to protect Global Direct, Members and
          anyone acting on Global Direct or Member's behalf's rights herein. </p>
        <p><strong>2. SERVICE DESCRIPTIONS. </strong></p>
        <p>Credit Card processing services: Global Direct's actions to the appropriate card networks and/or issuers
          (e.g., Visa, Mastercard, Diners, Discover, American Express); settlement; dispute resolution with cardholders'
          banks; and transaction-related reporting, statements and products. Debit/ATM Processing Services: Global
          Direct has connected to the following debit card networks ("Debit Networks"): Accel, AFFN, Alaska Option,
          CU24, Interlink, Maestro, NYCE, Pulse, Shazam, Star, and Tyme. Global Direct will provide Merchant with the
          ability to access the Debit Networks that Global Direct has connected to for the purpose of authorizing debit
          card transactions at the point of sale from cards issued by the members of the respective Debit Networks.
          Global Direct will provide connection to such Debit Networks, terminal applications, settlement and reporting
          activities. EBT Transaction Processing Services: Global Direct offers electronic interfaces to Electronic
          Benefits Transfer("EBT") networks for the processing of cash payments or credits to or for the benefit of
          benefit recipients ("Recipients"). Global Direct will provide settlement and switching services for various
          Point of Sale transactions initiated through Merchant for the authorization of the issuance of the United
          States Department of Agriculture, Food and Nutrition Services ("FNS") food stamp benefits ("FS Benefits")
          and/or government delivered cash assistance benefits ("Cash Benefits, with FS Benefits, "Benefits") to
          Recipients through the use of a state-issued card ("EBT Card"). With respect to Visa and Mastercard products,
          Merchant agrees to pay and Merchant's account(s) will be charged pursuant to Section 5 of this Agreement for
          any additional fees incurred as a result of Merchant's subsequent acceptance of transactions with any Visa or
          Mastercard product that it has not elected to accept. </p>
        <p><strong>3. PROCEDURES. </strong></p>
        <p>Merchant will permit holders of valid cards bearing the symbols of the cards authorized to be accepted by
          Merchant hereunder to charge purchases or leases of goods and services and the debt resulting there from shall
          be purchased hereunder on a provisional basis in accordance with Section 5 below, provided that the
          transaction complies with the terms of this Agreement. All indebtedness submitted by Merchant for purchase
          will be evidenced by an approved sales slip. Merchant will not present for purchase any indebtedness that does
          not arise out of a transaction between a cardholder and Merchant. Merchant agrees to follow the Card
          Acceptance Guide which is incorporated into and made part of this Agreement, and to be bound by the operating
          regulations, requirements, and rules of Visa, Mastercard, Discover, American Express, PayPal and any other
          card network organization covered by this Agreement, as any of the above referenced documents may be modified
          and amended from time to time. Merchant acknowledges that the Card Acceptance Guide is located at <a href='http://images.gonab.com/pdf/cag_gen.pdf' style={styles.link}>http://images.gonab.com/pdf/cag_gen.pdf</a>. Without limiting
          the generality of the foregoing, Merchant agrees to comply with and be bound by, and to cause any third-party
          who provides Merchant with services related to payment processing or facilitates Merchant's ability to accept
          credit and debit cards and who is not a party to this Card Services Agreement to comply with and be bound by
          the rules and regulations of Visa, Mastercard, Discover, PayPal and any other card network organization
          related to cardholders and transaction information security, including without limitation, Payment Card
          Industry Data Security Standards ("PCI DSS"), Visa's Cardholder Information Security Program and Mastercard's
          Site Data Protection Program. Furthermore, Merchant is required to annually validate PCI DSS compliance, with
          the initial validation required to be completed within ninety (90) days of the effective date of the
          Agreement. Merchant will be charged an annual compliance fee ("Compliance Fee"), which is subject to change at
          agent's sole discretion, to offset the costs associated with maintaining compliance with PCI DSS and other
          governmental and regulatory requirements. Merchant also agrees to cooperate at its sole expense with any
          request for an audit or investigation by Global Direct, Member, a card network organization in connection with
          cardholder and transaction information security. Without limiting the generality of the foregoing, Merchant
          agrees that it will use information obtained from a cardholder in connection with a card transaction solely
          for the purpose of processing a transaction with that cardholder or attempting to re-present a chargeback with
          respect to such transaction. Merchant will indemnify and hold Global Direct, Member and anyone acting on
          Global Direct or Member's behalf harmless from any fines and penalties issued by Visa, Mastercard, Discover,
          PayPal or any card network organization and any other fees and costs arising out of or relating to the
          processing of transactions by Global Direct and Member at Merchant's location(s) and will reimburse Global
          Direct for any losses incurred by Global Direct with respect to any such fines, penalties, fees and costs.
          Global Direct may, from time to time, issue written directions (via mail or Internet) regarding procedures to
          follow and forms to use to carry out this Agreement. These directions and the terms of the forms are binding
          as soon as they are issued and shall form part of these Terms and Conditions of the Agreement. Such operating
          regulations and rules may be reviewed upon appointment at Global Direct's designated premises and Merchant
          acknowledges that it has had the opportunity to request a review and/or review such operating regulations and
          rules in connection with its execution of this Card Services Agreement. </p>
        <p><strong>4. MARKETING. </strong></p>
        <p>Merchant shall adequately display the card issuer service marks and promotional materials supplied by Global
          Direct. Merchant shall cease to use or display such service marks immediately upon notice from Global Direct
          or upon termination of this Agreement.</p>

        <p><strong>5. PAYMENT, CHARGES AND FEES.</strong></p>
        <p>Global Direct may contract with third parties to furnish certain of the services. These third parties may
          impose their own fees or charges, markup existing fees or charges, or receive a portion of fees or charges,
          charged by Global Direct. Merchant shall at all times maintain one or more commercial checking accounts with
          Member or with another financial institution of Merchant's choice acceptable to Member and Global Direct that
          belongs to the Automated Clearing House ("ACH") network and which can accept ACH transactions. Merchant will
          be paid for indebtedness purchased under this Agreement by credit to Merchant's account(s). Each purchase of
          indebtedness shall be funded on a provisional basis until all chargebacks, fees, fines, penalties, and other
          adjustments are identified and implemented in respect of such purchase under this Card Services Agreement
          and/or the rules and regulations of a card network organization. Accordingly, any such provisional funding is
          subject to reversal and recoupment with regard to any such chargebacks, fees, fines, penalties, and other
          adjustments. Merchant's account(s) will be credited for the gross amount of the indebtedness deposited less
          the amount of any credit vouchers deposited. Merchant shall not be entitled to credit for any indebtedness
          that arises out of a transaction not processed in accordance with the terms of this Card Services Agreement or
          the rules and regulations of a card network organization. Availability of any such funds shall be subject to
          the procedures of the applicable financial institution. Chargebacks and adjustments will be charged to
          Merchant's account(s) on a daily basis. Merchant agrees to pay and Merchant's account(s) will be charged for
          the discount, fees, product service costs, chargebacks, and other fees and charges described in this
          Agreement. Merchant also agrees to pay and Merchant's account(s) will be debited for all fees, arbitration
          fees, fines, penalties, etc. charged or assessed by the card network organizations on account of or related to
          Merchant's processing hereunder, including without limitation with regards to any third-party who provides
          Merchant with services related to payment processing or facilitates Merchant's ability to accept credit and
          debit cards and who is not a party to this Card Services Agreement. If any type of overpayment to Merchant or
          other error occurs, Merchant's account(s) may be debited or credited, without notice, and if Merchant's
          account(s) do not contain sufficient funds, Merchant agrees to remit the amount owed directly to Global
          Direct. Merchant agrees not to, directly or indirectly, prevent, block or otherwise preclude any debit by
          Global Direct or Member to Merchant's account which is permitted hereunder. Merchant represents and warrants
          that no one other than Merchant has any claim against such indebtedness except as authorized in writing by
          Member and Global Direct, and further agrees that Merchant shall have no right, title or interest in any such
          funds, including any such funds held in a Reserve Account (as defined below). Merchant hereby assigns to
          Member and Global Direct all of its right, title, and interest in and to all indebtedness submitted hereunder,
          agrees that Member and Global Direct have the sole right to receive payment on any indebtedness purchased
          hereunder. Merchant acknowledges and agrees, notwithstanding anything set forth in this Card Services
          Agreement to the contrary, that each purchase of indebtedness under this Card Services Agreement is of such a
          nature that the price to be paid by the Member and Global Direct cannot be equitably determined until the
          respective periods permitted for chargebacks, fees, fines, penalties, and other adjustments to be assessed or
          implemented have all expired under this Card Services Agreement and/or the rules and regulations of the
          applicable card network organization. These chargebacks, fees, fines, penalties, and other adjustments are an
          integral part of the purchase transaction, and the final price that is to be paid for such indebtedness by the
          Member and Global. To the extent that any chargeback, fee, penalty, fine or other adjustment is assessed or
          implemented, the Member and Global Direct may exercise their right of recoupment with regard to the price
          provisionally paid for the respective indebtedness that was purchased. In the absence of this remedy, the
          Merchant acknowledges that it would be overpaid for the indebtedness purchased, and such overpayment shall be
          held in trust by the Merchant as the legal and equitable property of the Member and Global Direct. </p>

        <table width='80%' cellPadding='5' cellSpacing='0'>
          <tbody>
          <tr>
            <td width='25%;' style={{borderStyle: 'solid', borderWidth: 1, borderColor: '#000000'}}>Fee Description</td>
            <td width='25%;' style={{borderStyle: 'solid', borderWidth: 1, borderColor: '#000000'}}>Frequency</td>
            <td width='25%;' style={{borderStyle: 'solid', borderWidth: 1, borderColor: '#000000'}}>Fee Description</td>
            <td width='25%;' style={{borderStyle: 'solid', borderWidth: 1, borderColor: '#000000'}}>Frequency</td>
          </tr>
          <tr>
            <td width='25%;' style={{borderStyle: 'solid', borderWidth: 1, borderColor: '#000000'}}>Address Verification</td>
            <td width='25%;' style={{borderStyle: 'solid', borderWidth: 1, borderColor: '#000000'}}>per occurrence</td>
            <td width='25%;' style={{borderStyle: 'solid', borderWidth: 1, borderColor: '#000000'}}>Touchtone Transactions</td>
            <td width='25%;' style={{borderStyle: 'solid', borderWidth: 1, borderColor: '#000000'}}>per occurrence</td>
          </tr>
          <tr>
            <td width='25%;' style={{borderStyle: 'solid', borderWidth: 1, borderColor: '#000000'}}>Voice Authorization Fee</td>
            <td width='25%;' style={{borderStyle: 'solid', borderWidth: 1, borderColor: '#000000'}}>per occurrence</td>
            <td width='25%;' style={{borderStyle: 'solid', borderWidth: 1, borderColor: '#000000'}}>Batch Settlement</td>
            <td width='25%;' style={{borderStyle: 'solid', borderWidth: 1, borderColor: '#000000'}}>per occurrence</td>
          </tr>
          <tr>
            <td width='25%;' style={{borderStyle: 'solid', borderWidth: 1, borderColor: '#000000'}}>ACH Reject/NSF Fee</td>
            <td width='25%;' style={{borderStyle: 'solid', borderWidth: 1, borderColor: '#000000'}}>per occurrence</td>
            <td width='25%;' style={{borderStyle: 'solid', borderWidth: 1, borderColor: '#000000'}}>Retrieval</td>
            <td width='25%;' style={{borderStyle: 'solid', borderWidth: 1, borderColor: '#000000'}}>per occurrence</td>
          </tr>
          <tr>
            <td width='25%;' style={{borderStyle: 'solid', borderWidth: 1, borderColor: '#000000'}}>PCI Program Fee</td>
            <td width='25%;' style={{borderStyle: 'solid', borderWidth: 1, borderColor: '#000000'}}>annually</td>
            <td width='25%;' style={{borderStyle: 'solid', borderWidth: 1, borderColor: '#000000'}}>Chargeback</td>
            <td width='25%;' style={{borderStyle: 'solid', borderWidth: 1, borderColor: '#000000'}}>per occurrence</td>
          </tr>
          <tr>
            <td width='25%;' style={{borderStyle: 'solid', borderWidth: 1, borderColor: '#000000'}}>Dial Pay Transaction Fee</td>
            <td width='25%;' style={{borderStyle: 'solid', borderWidth: 1, borderColor: '#000000'}}>per occurrence</td>
            <td width='25%;' style={{borderStyle: 'solid', borderWidth: 1, borderColor: '#000000'}}>Statement Mailing Fee</td>
            <td width='25%;' style={{borderStyle: 'solid', borderWidth: 1, borderColor: '#000000'}}>monthly</td>
          </tr>
          </tbody>
        </table>

        <p>If no per transaction fee is specified, AMEX transactions will be subject to the same communications fee as
          VISA/Mastercard/Discover, specified on the Merchant Application. Furthermore, additional fees, descriptions
          and/or terms for programs and services provided hereunder are set forth in the following applicable sections
          of these Terms and Conditions. All fees payable by Merchant under this Merchant Service Agreement, including
          those detailed in the Merchant Application and any separate schedule of fees may be changed or modified at any
          time by providing notice to Merchant of changes to such fees as provided for herein. </p>
        <p>The following Network Fees that are set forth on the Merchant Application will be charged for each
          transaction on the applicable network: Visa Assessments, Visa Network Access Fee, Master Card Assessments,
          Mastercard Network Access Fee, Discover Assessments, Discover Network Access Fee, American Express Network
          Fee, and the American Express Non-Swiped Transaction Fee. Additionally, the following network fees shall apply
          for each cross-border transaction on the applicable network. </p>
        <p>Different discount rates and other fees may apply to different types of Card transactions. For example, a
          "Qualified Discount Rate" will be charged on certain types of Card transactions, including without limitation,
          swiped cards on which the full magnetic stripe has been read; a higher "Mid-Qualified Discount Rate" will be
          charged on other types of Card transactions, including without limitation, keyed cards at retail locations or
          keyed cards with valid AVS response and order number for "card not present" transactions; and a higher
          "Non-Qualified Discount Rate" will be charged on other types of Card transactions, including without
          limitation, Business, Corporate, Purchase, International, or Government cards, keyed cards where AVS is not
          present or missing any of the required data elements, batches not closed within one calendar day of
          transaction, all rewards cards as defined by the card issuers, and any Pre-Authorized sale that is not
          processed/captured within 7 business days. Merchant is encouraged to review certain of these additional rates
          and fees in Section 34. </p>
        <p>Additional charges that may occur from time to time include chargeback fees, re-presentment fees, and
          retrieval fees. A monthly minimum fee will be deducted unless Merchant has met its minimum processing volume.
          The standard delivery method for Monthly Statements is in an electronic, online version that will be generated
          each month and located at <a href='https://www.paymentshub.com' style={styles.link}>http://www.paymentshub.com</a>.</p>

        <p><strong>6. EQUIPMENT AND SUPPLIES/THIRD-PARTY SERVICES. </strong></p>
        <p>Merchant agrees that it will not acquire any title, copyrights, or any other proprietary right to any
          advertising material, leased equipment including imprinters, authorization terminals, card reader hardware or
          printers, software, credit card authenticators, unused forms (online or paper), and Merchant deposit plastic
          cards provided by Global Direct in connection with this Card Services Agreement. Merchant will protect all
          such items from loss, theft, damage, or any legal encumbrance and will allow Global Direct and its designated
          representatives reasonable access to Merchant's premises for their repair, removal, modification, installation
          and relocation. Merchant acknowledges that any equipment or software provided under this Agreement is embedded
          with proprietary technology ("Software"). Merchant shall not obtain title, copyrights, or any other
          proprietary right to any Software. At all time, Global Direct or its suppliers retain all rights to such
          Software, including but not limited to updates, enhancements, and additions. Merchant shall not disclose such
          Software to any party, convey, copy, license, sublicense, modify, translate, reverse engineer, decompile,
          disassemble, tamper with, or create any derivative work based on such Software, or transmit any data that
          contains software viruses, time bombs, worms, Trojan horses, spyware, disabling devices, or any other
          malicious or unauthorized code. Merchant's use of such Software shall be limited to that expressly authorized
          by Global Direct. Global Direct's suppliers are intended third-party beneficiaries of this Agreement to the
          extent of any terms herein pertaining to such suppliers' ownership rights; such suppliers have the right to
          rely on and directly enforce such terms against Merchant. The operating instructions or user guides will
          instruct Merchant in the proper use of the terminals, other hardware or payment application(s), and Merchant
          shall use and operate the terminals, hardware, or payment application(s) only in such manner. If Merchant has
          purchased the maintenance/help desk service hereunder for its terminals, Merchant will promptly notify Global
          Direct of any equipment malfunction, failure, or other incident resulting in the loss of use of the equipment
          or software or need for repair or maintenance, whereupon Global Direct will make the necessary arrangements to
          obtain required maintenance or replacement software or hardware. Merchant is responsible for shipping costs.
          Merchant shall cooperate with Global Direct in its attempt to diagnose any problem with the terminal,
          hardware, or payment application(s). In the event the Merchant's terminal requires additional Software,
          Merchant is obligated to cooperate and participate in a dial in down line load procedure. With respect to any
          item of equipment or software leased to Merchant by Global Direct, Merchant will not be liable for normal wear
          and tear, provided, however that Merchant will be liable to Global Direct in the event that any leased item of
          equipment is lost, destroyed, stolen, rendered inoperative. Merchant will indemnify Global Direct against any
          loss arising out of damage to or destruction of any item of equipment provided hereunder for any cause
          whatsoever. Merchant also agrees to hold harmless and indemnify Global Direct for any costs, expenses, and
          judgments Global may suffer, including reasonable attorney's fees, as a result of Merchant's use of the
          equipment or software provided hereunder. Any unused equipment in its original packaging purchased from Global
          hereunder may be returned to Global Direct at Merchant's expense within 60 days of receipt. Merchant shall
          receive a refund of any money paid in connection therewith subject to a re-stocking fee of an amount equal to
          20 percent of the total purchase price for the returned equipment. No refunds shall be issued for any
          equipment returned after 60 days. Merchant acknowledges that some of the services and applications to be
          provided by Global Direct and Member hereunder may be provided by third parties. Merchant agrees that, except
          for its right to utilize such services in connection with this Card Services Agreement, it acquires no right,
          title or interest in any such services. Merchant further agrees that it has no contractual relationship with
          any third-party providing services under this Card Services Agreement and that Merchant is not a third-party
          beneficiary of any agreement between Global Direct or Member, as applicable, and such third-party. Merchant
          may not resell the services of any third-party providing services under this Card Services Agreement to any
          other party. </p>

        <p><strong>7. FINANCIAL INFORMATION. </strong></p>
        <p>Merchant agrees to furnish Global Direct and Member such financial statements and information concerning
          Merchant, its owners, principals, partners, proprietors, or its affiliates as Global Direct or Member may from
          time to time request. Global Direct and Member, or its duly authorized representatives, may examine the books
          and records of Merchant, including records of all indebtedness previously purchased or presented for purchase.
          Merchant agrees to retain copies of all paper and electronic sales slips and credit slips submitted to Global
          Direct for a period of two years from submission, or such longer period of time as may be required by the
          operating rules or regulations of the card network organizations, by law, or by Global Direct as specifically
          requested in writing in individual cases. Merchant agrees that Global Direct, Member, and anyone acting on
          Global Direct or Member's behalf may seek injunctive relief with respect to Merchant's failure to furnish
          financial or other information upon request. </p>

        <p><strong>8. CHANGE IN BUSINESS. </strong></p>
        <p>Merchant agrees to provide Global Direct and Member 60 days prior written notice of its intent to (a)
          transfer or sell any substantial part (10% or more) of its total stock, assets, and/or to liquidate; or (b)
          change the basic nature of its business, or (c) convert all or part of the business to mail order sales,
          telephone order sales, or to other sales where the card is not present and swiped through Merchant's terminal
          or other card reader. Upon the occurrence of any such event, the terms of this Agreement may be modified to
          address issues arising there from, including but not limited to requirements of applicable card network
          organizations. </p>

        <p><strong>9. TRANSFERABILITY. </strong></p>
        <p>This Agreement is not transferable by Merchant without the written consent of Global Direct and Member. Any
          attempt by Merchant to assign its rights or to delegate its obligations in violation of this paragraph shall
          be void. Merchant agrees that the rights and obligations of Global Direct hereunder may be transferred by
          Global Direct without notice to Merchant. Merchant agrees that the rights and obligations of Member hereunder
          may be transferred to any other member without notice to Merchant. Merchant acknowledges that the transferable
          rights of Global Direct and Member hereunder shall include, but shall not be limited to, the authority and
          right to debit the Merchant's account(s) as described herein. </p>

        <p><strong>10. WARRANTIES AND REPRESENTATIONS. </strong></p>
        <p>Merchant warrants and represents to Global Direct and Member: (a) that each sales transaction delivered
          hereunder will represent a bona fide sale to a card holder by Merchant for the amount shown on the sales slip
          as the total sale and constitutes the binding obligation of the card holder, free from any claim, demand,
          defense, setoff, or other adverse claim whatsoever; (b) that each sales slip or other evidence of indebtedness
          will accurately describe the goods and services which have been sold and delivered to the card holder or in
          accordance with his instructions; (c) that Merchant will comply fully with all federal, state, and local laws,
          rules and regulations applicable to its business; (d) that Merchant will fulfill completely all of its
          obligations to the cardholder and will resolve any customer dispute or complaint directly with the card
          holder; (e) that the signature on the sales slip will be genuine and authorized by cardholder and not forged
          or unauthorized; (f) that the sales transaction shall have been consummated and the sales slip prepared in
          full compliance with the provisions of the Card Acceptance Guide and the operating rules and regulations of
          the applicable card network organization, as amended from time to time; (g) that none of the sales
          transactions submitted hereunder represent sales by telephone, or mail, or Internet, or where the card is not
          physically present at the Merchant's location and swiped through Merchant's terminal, unless Merchant is
          specifically authorized in writing by Global Direct to submit such sales slips for purchase,(h)that none of
          the sales transactions submitted here under for purchase represent sales to any principal, partner,
          proprietor, or owner of Merchant, (i) that, without limiting the generality of the foregoing, each sales
          transaction submitted hereunder and the handling, retention, and storage of information related thereto, will
          comply with the rules and regulations of Visa, Mastercard, Discover, American Express, PayPal and any other
          card network organization related to card holder and transaction information security, including without
          limitation, Payment Card Industry (PCI) Data Security Standards, Visa's Cardholder Information Security
          Program and Mastercard's Site Data Protection Program, and (j) that all of the information contained in this
          Merchant Application and Service Agreement is true and correct. In the event that any of the foregoing
          warranties or representations a r e breached, the affected sales slips or other indebtedness may be refused,
          or prior acceptance revoked and charged back to the Merchant. Furthermore, if Merchant submits for purchase
          hereunder a sales transaction that is not the result of a sale of Merchant's goods or services offered to the
          general public or if Merchant submits any sales transactions for purchase hereunder which represents a sale to
          any principal, partner, proprietor, or owner of Merchant, such sales transaction may be refused or charged
          back. Merchant must notify Global Direct if Merchant elects to use the terminal service of American Express,
          Novus, or any other third-party provider. If Merchant elects to use a third- party terminal provider, that
          provider becomes Merchant's agent for the delivery of card transactions to Global via the applicable card
          processing network. Global Direct and Member shall have no responsibility for or liability in connection with
          any hardware, software, or services Merchant receives from a third-party agent, even if Global Direct collects
          monies with respect to such hardware, software, or services. Neither Global Direct nor Member makes any
          representation or warranty with respect to such agent's access to or ability to integrate with the products,
          services, and systems of Global Direct and any such access or ability may terminate at any time and Global
          Direct shall have no obligation to advise Merchant of such termination. Merchant agrees to assume full
          responsibility and liability for any failure of such agent to comply with the operating regulations and rules
          of the applicable card network organization, including, without limitation, any violation which results in a
          chargeback to the Merchant. Global Direct and Member have no responsibility for any card transactions until it
          receives data for the card transaction in the format required by Global Direct. Merchant also agrees that the
          obligation hereunder to reimburse the Merchant for the value of the card transactions captured by an agent is
          limited to the value of the transactions (less applicable fees) received by the card processing network from
          the agent. NEITHER MEMBER, NOR GLOBAL DIRECT, NOR ANY SUPPLIER MAKES ANY REPRESENTATIONS OR WARRANTIES,
          EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR
          PURPOSE WITH RESPECT TO ANY TERMINAL, EQUIPMENT, SOFTWARE, OR SERVICES LEASED, SOLD, OR OTHERWISE FURNISHED IN
          CONNECTION THEREWITH, OR ANY OF THE SERVICES FURNISHED HEREUNDER. </p>

        <p><strong>Internal Revenue Service ("IRS") Reporting </strong></p>
        <p>Merchant herby warrants and certifies that: (i) the taxpayer identification number ("TIN") provided on the
          Merchant Application is Merchant's true and correct TIN; (ii) Merchant is not subject to backup withholding
          because (a) Merchant is exempt from backup withholding, (b) Merchant has not been notified by the IRS that it
          is subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS
          has notified Merchant that it is no longer subject to backup withholding, and (iii) Merchant is a citizen of
          the United States of America ("U.S.") or other U.S. person. (For federal tax purposes, Merchant is considered
          a U.S. person if Merchant is: an individual who is a U.S. citizen, or U.S. resident alien, partnership,
          corporation, company or association created or organized in the United States or under the laws of the United
          States, and estate (other than a foreign estate), or a domestic trust (as defined in the Internal Revenue Code
          section 301.7701-7.)) </p>

        <p><strong>11. INDEMNITY. </strong></p>
        <p>Merchant agrees to satisfy directly with the card holder any claim or complaint arising in connection with
          the card sale. Merchant agrees to indemnify, defend, and hold Global Direct, Member and their respective
          parent companies, subsidiaries and affiliates (including, without limitation, the respective officers,
          directors, employees, attorneys, shareholders, representatives, and agents of all of the foregoing) harmless
          from and against any and all liabilities, judgments, arbitration awards, settlements, actions, suits, claims,
          demands, losses, damages, costs (including, but not limited to, court costs and out of pocket costs and
          expenses), expenses of any and every type, litigation expenses, and attorneys' fees, including, but not
          limited to, attorneys' fees incurred in any and every type of suit, proceeding, or action, including, but not
          limited to, bankruptcy proceedings, in connection with, by virtue of, or arising from, either directly or
          indirectly: (a) any card transaction that does not conform to the requirements of this Card Services
          Agreement, the rules and regulations of any card network, or applicable laws; (b) any card transaction or any
          act or omission of Merchant in connection with a cardholder; (c) Merchant's breach or default, or an alleged
          breach or default of or under any term, covenant, condition, representation, warranty, obligation,
          undertaking, promise, or agreement contained in this Card Services Agreement or in any agreement (whether oral
          or written) with any cardholder, any agreement with any card network, or in any other agreement with Member or
          Global Direct, any breach or threatened breach by Merchant of the card network rules and regulations or any
          violation by Merchant of laws, rules and regulations applicable to Merchant; (d) the rescission, cancellation,
          or avoidance of any card transaction, by operation of law, adjudication or otherwise; (e) any claim,
          counterclaim, complaint, dispute, or defense, including, without limitation, claims brought by Merchant,
          whether or not well founded, with respect to this Card Services Agreement or a card transaction; (f) damages,
          including, without limitation, those for death or injury caused by the good or service purchased with the
          card; or (g) for all web based, Internet or electronic commerce transactions including Merchant's insecure
          transmission of card transaction data and/or storage of cardholder information. For purposes of this
          Agreement, including the foregoing indemnities, Merchant is responsible and liable for the acts and omissions
          of its employees, agents, and representatives (whether or not acting within the scope of their duties). </p>

        <p><strong>12. LIMITATION OF LIABILITY. </strong></p>
        <p>Neither Member nor Global Direct shall be liable for failure to provide the Services if such failure is due
          to any cause or condition beyond such party's reasonable control. Such causes or conditions shall include, but
          shall not be limited to, acts of God or of the public enemy, acts of the Government in either its sovereign or
          contractual capacity, fires, floods, epidemics, quarantine restrictions, strikes, shortages of labor or
          materials, freight embargoes, unusually severe weather, breakdowns, operational failures, electrical power
          failures, communication failures, unavoidable delays, or other similar causes beyond such party's control. The
          liability of Global Direct and Member for any loss hereunder, including, but not limited to, damages arising
          out of any malfunction of the equipment or the failure of the equipment to operate, the unavailability or
          malfunction of the Services, personal injury, or property damage, shall, in the aggregate, be limited to
          actual, direct, and general money damages in an amount not to exceed one (1) month's average charge paid by
          Merchant hereunder (exclusive of interchange fees, assessments, and any other fees or costs that are imposed
          by a third-party in connection with Merchant's payment processing) for Services during the previous twelve
          (12) months or such lesser number of months as shall have elapsed subsequent to the effective date of this
          Agreement. This shall be the extent of Global Direct's and Member's liability in the event of any alleged
          default by Global Direct or Member arising out of or relating in any way to this Agreement, including alleged
          acts of negligence, breach of contract, or otherwise and regardless of the form in which any legal or
          equitable action may be brought against Global Direct or Member, whether contract, tort, or otherwise, and the
          foregoing shall constitute Merchant's exclusive remedy. Under no circumstances shall Global Direct or Member
          be liable for any lost profits, lost interest, or for special, consequential, punitive, or exemplary damages,
          including, but not limited to, damages arising out of placement of a Merchant's name on any terminated
          merchant list for any reason, even if Global Direct or Member has been advised of the possibility of such
          damages. Member shall not be responsible or liable to Merchant for any action taken by Member (or the results
          thereof) that is authorized by this Agreement. It is agreed that in no event will Global Direct or Member be
          liable for any claim, loss, billing error, damage, or expense caused by Global Direct's or Member's
          performance or failure to perform hereunder which is not reported in writing to Global Direct by Merchant
          within 60 days of such failure to perform or, in the event of a billing error, within 90 days of the date of
          the invoice or applicable statement. Merchant expressly waives any such claim that is not brought within the
          time periods stated herein. </p>
        <p><strong>13. TERM AND TERMINATION. </strong></p>
        <p>This Agreement shall remain in full force and effect for an initial term of three (3) years. This Agreement
          shall be automatically extended for successive one (1) year periods on the same terms and conditions expressed
          herein, or as may be amended, unless Merchant gives written notice of termination as to the entire Agreement
          or a portion thereof at least 60 days prior to the expiration of the initial term or any extension or renewals
          thereof, in which case this Agreement will terminate at the end of the then-current term. Notwithstanding
          anything to the contrary set forth herein, in the event Merchant terminates the Agreement in breach of this
          Section 13, all monthly fees assessed to Merchant under the Agreement and due to Global Direct for the
          remainder of the then existing term of the Agreement, including all minimum monthly fee commitments, shall be
          immediately due and payable to Global Direct, and Merchant hereby authorizes Global Direct to deduct the
          greater of (a) $295 and (b) all remaining monthly fees (provided in no event shall either such amount exceed
          the maximum amount permitted by applicable state law) from Merchant's account referenced in Section 5, or to
          otherwise withhold the total amount from amounts due to Merchant from Global Direct, immediately on or after
          the effective date of termination. If the Merchant's account does not contain sufficient funds for the debit
          or the amount cannot be withheld by Global Direct from amounts due to Merchant, Merchant shall pay Global
          Direct the amount due within ten (10) days of the date of Global Direct's invoice for same. The payment of
          accelerated monthly fees as described here is not a penalty, but rather is hereby agreed by the parties to be
          a reasonable amount of liquidated damages to compensate Global Direct for its termination expenses and all
          other damages under the circumstances in which such amounts would be payable. Such amounts shall not be in
          lieu of but in addition to any payment obligations for Services already provided hereunder (or that Global
          Direct may continue to provide), which shall be an additional cost, and any and all other damages to which
          Global Direct may be entitled hereunder. Notwithstanding the foregoing, Global Direct may terminate this
          Agreement or any portion thereof upon written notice to Merchant. Furthermore, Global Direct may terminate
          this Agreement at any time without notice upon Merchant's default in performing under any provision of this
          Agreement, upon an unauthorized conversion of all or any part of Merchant's activity to mail order, telephone
          order, or to any activity where the card is not physically present and swiped through the Merchant's terminal,
          upon any failure to follow the Card Acceptance Guide or any operating regulation or rule of a card network
          organization, upon any misrepresentation by Merchant, upon commencement of bankruptcy or insolvency
          proceedings by or against the Merchant, or in the event Global Direct reasonably deems itself insecure in
          continuing this Agreement. In the event that Global Direct and Member breach the terms and conditions hereof,
          the Merchant may, at its option, give written notice to Global Direct and Member of its intention to terminate
          this Agreement unless such breach is remedied within 30 days of such notice. Failure to remedy such a breach
          shall make this Agreement terminable, at the option of the Merchant, at the end of such 30 day period unless
          notification is withdrawn. Any Merchant deposit of sales or credit slips that is accepted by Global Direct and
          Member or by a designated depository after the effective date of termination will be returned to Merchant and
          will not be credited (or debited) to its account(s). If the deposit has already been posted to Merchant's
          account(s), said posting will be reversed and the deposit returned to Merchant. Termination of this Agreement
          shall not affect Merchant's obligations which have accrued prior to termination or which relate to any
          indebtedness purchased hereunder prior to termination, including but not limited to charge backs even if such
          charge backs come in after termination. In the event of termination, all equipment leased from, and software
          provided by, Global Direct (but not from any other leasing agent), including but not limited to imprinters,
          terminals, and printers, all supplies, Card Acceptance Guides, and operating instructions must be returned
          immediately to Global at Merchant's expense. Notwithstanding the foregoing, if within forty-five (45) days of
          Merchant's execution of this Agreement Merchant both provides Member or Global with written notice that it
          wishes to terminate this Agreement immediately and, if applicable, returns to Global, or anyone acting on
          Global Direct or Member's behalf, at Merchant's expense any free terminals Merchant received in connection
          with this Agreement, Merchant shall not be responsible for the payment of the above-referenced accelerated
          monthly fees, but shall be responsible for compliance with all other terms and conditions set forth in this
          Agreement, including, but not limited to, payment for all fees incurred prior to the termination of this
          Agreement. </p>
        <p><strong>14. RETURNED ITEMS/CHARGEBACKS. </strong></p>
        <p>If a cardholder disputes any transaction, if a transaction is charged back for any reason by the card issuing
          institution, or if Global Direct or Member has any reason to believe an indebtedness previously purchased is
          questionable, not genuine, or is otherwise unacceptable, the amount of such indebtedness may be charged back
          and deducted from any payment due to Merchant or may be charged against any of Merchant's accounts or the
          Reserve Account (as defined below). Merchant acknowledges and agrees that it is bound by the rules of the card
          network organizations with respect to any chargeback. Merchant further acknowledges that it is solely
          responsible for providing Global Direct and Member with any available information to re- present a chargeback
          and that, regardless of any information it provides or does not provide Global Direct and Member in connection
          with a chargeback, or any other reason, Merchant shall be solely responsible for the liability related to such
          chargeback. A list of some common reasons for chargebacks is contained in the Card Acceptance Guide provided,
          however, that such list is not exclusive and does not limit the generality of the foregoing. If any such
          amount is uncollectible through withholding from any payments due hereunder or through charging Merchant's
          accounts or the Reserve Account, Merchant shall, upon demand by Global Direct, pay Global Direct the full
          amount of the charge back. Merchant understands that obtaining an authorization for any sale shall not
          constitute a guarantee of payment, and such sales slips can be returned or charged back to Merchant like any
          other item hereunder. </p>

        <p><strong>15. RESERVE ACCOUNT. </strong></p>
        <p>At any time, Global Direct and Member may, at their option, establish a reserve account to secure the
          performance of Merchant's obligations under this Agreement to such party ("Reserve Account"). The Reserve
          Account may be funded, at Global Direct's (or anyone acting on Global Direct's behalf's) sole discretion,
          through any or all of the following: (a) Direct payment by Merchant - At the request of Global Direct or
          Member, Merchant will deposit funds in the Reserve Account; (b) The proceeds of indebtedness presented for
          purchase; or (c) The transfer by Global Direct and Member into the Reserve Account of funds withdrawn from any
          of the accounts referred to in Section 5 or any other accounts, including certificates of deposit, maintained
          by Merchant or Merchant's guarantor, if any, with any designated depository or other financial institution.
          Merchant and Merchant's guarantor hereby grant Member a security interest in all accounts referenced in
          Section 5 or any other accounts, including certificates of deposits, maintained by Merchant or Merchant's
          guarantor, if any, with any designated depository or other financial institution and authorize Global Direct
          (to the extent authorized by Member) or Member to make such withdrawals at such times and in such amounts as
          it may deem necessary hereunder. Merchant and Merchant's guarantor hereby instruct said financial institutions
          to honor any requests made by Global Direct and Member under the terms of this provision. Merchant and
          Merchant'sguarantor will hold harmless the financial institutions and indemnify them for any claims or losses
          they may suffer as a result of honoring withdrawal requests from Global Direct and Member. Merchant hereby
          agrees that Global Direct and Member may deduct from this Reserve Account any amount owed to such party in
          accordance with this Agreement. Any funds in the Reserve Account may be held until the later of (a) the
          expiration of any potentially applicable chargeback rights in respect of purchased indebtedness under the
          rules and regulations of the card network organizations and (b) the period necessary to secure the performance
          of Merchant's obligations under this Card Services Agreement, which holding period may extend beyond
          termination of this Agreement. The Merchant will not receive any interest on funds being held in a Reserve
          Account and merchant has no right to access the funds being held in the Reserve Account or otherwise transfer,
          pledge, or use these funds for its own purpose. Without limiting the generality of the foregoing, Merchant
          shall, upon termination of this Card Services Agreement, maintain the sum of at least five percent (5%) of
          gross sales for the 90 day period prior to termination to be held in a Reserve Account in accordance with the
          terms of this Card Services Agreement. Global Direct may, at its discretion upon termination of this
          Agreement, require that the Merchant maintain more than five percent (5%) of gross sales for the 90 day period
          prior to termination in a Reserve Account. </p>

        <p><strong>16. DEFAULT/SECURITY INTEREST. </strong></p>
        <p>Upon failure by Merchant to meet any of its obligations under this Agreement (including funding the Reserve
          Account), any of the accounts referred to in Section 5 or any other accounts belonging to Merchant, Merchant's
          affiliated entities, or Merchant's guarantor held by any designated depository (or by any other financial
          institution) may be debited without notice to Merchant, and Merchant (on behalf of itself and its affiliated
          entities) and Merchant's guarantor gives Member and Global Direct a security interest in all such accounts for
          these purposes, and hereby grants to Member, Global Direct a lien and security interest in all of Merchant's
          right, title and interest in or to any of the following assets or properties: (a) all of the accounts
          referenced in the preceding sentence, (b) the Reserve Account, (c) any rights to receive credits or payments
          under this Card Services Agreement and (d) all deposits and other property of Merchant that Member or its
          affiliates possess or maintain (including all proceeds of the foregoing). Merchant shall execute, acknowledge,
          or deliver any documents or take any actions Member, Global Direct may from time to time request to better
          assure, preserve, protect, perfect, maintain or enforce this security interest. To the extent permitted by
          law, Merchant irrevocably authorizes Member, Global Direct to file any financing statements (at Merchant's
          expense) in any relevant jurisdiction or any other documents or instruments related to this security interest.
          Merchant represents and warrants that (a) Merchant has good and valid rights and title to the property
          described herein, (b) Merchant has full power and authority to grant to Member the security interest pursuant
          hereto and to execute, deliver, and perform its obligations in accordance with the terms of this Card Services
          Agreement, without the consent or approval of any other person or entity, (c) no other person or entity has a
          security interest or lien in any of the property described herein and (d) this security interest is a first
          lien security interest and secures Merchant's obligations to Member under this Card Services Agreement. Member
          shall have all rights of a secured party and Merchant must obtain the prior written consent of Member before
          granting any subsequent security interest or lien in the property described herein. Merchant agrees that it is
          Merchant's intent that these accounts and secured property shall to the extent allowed by applicable law not
          be subject to any preference, claim, or stay by reason of any bankruptcy or insolvency law. Merchant agrees to
          act consistently with the understanding that said accounts and secured property under this Card Services
          Agreement are free of all such preferences, claims, or stays by reason of and as allowed by any such law. The
          scope of the security interest, and Merchant's (on behalf of itself and its affiliated entities) and
          Merchant's guarantor's instructions to its financial institutions to accept withdrawal requests from Global
          Direct and Member, and Merchant's agreement to hold such institutions harmless and to indemnify them are
          described above in Section 15. Merchant also agrees that, in the event of a default by Merchant, and in
          furtherance of its rights under Section 5 above and otherwise, Member has a right of setoff and may apply any
          of Merchant's deposit balances or any other monies due Merchant from Member towards the payment of amounts due
          from Merchant under the terms of this Agreement. The rights stated herein are in addition to any other rights
          Global Direct and Member may have under applicable law. </p>

        <p><strong>17. AMENDMENTS. </strong></p>
        <p>This Agreement may be amended only in writing signed by Global Direct, Member, and Merchant, except that (a)
          the Card Acceptance Guide and any and all fees, charges, and/or discounts (including, without limitations,
          non-qualified surcharge rates) may be changed immediately, or (b) Global Direct may mail Merchant either (i) a
          notice describing amendments to this Agreement or new services to be provided or fees to be charged to
          Merchant or (ii) an entirely new agreement, which notice, amendments, or new agreement will be binding upon
          Merchant if it deposits sales or credit slips after the effective date of such amendment or new agreement set
          forth in Global Direct's notice; provided, however, that a minimum of thirty (30) days advance notice will be
          provided to Merchant in the case of any fee increase or introduction of a new fee related to Mastercard and
          Maestro, and in such event Merchant will have the right to terminate Mastercard or Maestro acceptance under
          this Agreement within ninety (90) days of receiving notice of such fee increase or introduction of a new fee.
          Notwithstanding the foregoing, this termination right as to Mastercard acceptance will not apply to any fee
          increase made in accordance with a pre- determined fee schedule included in this Agreement. </p>

        <p><strong>18. WAIVER. </strong></p>
        <p>No provision of this Agreement shall be deemed waived by any party unless such waiver is in writing and
          signed by the party against whom enforcement is sought. No failure to exercise, and no delay in exercising on
          the part of any party hereto, any right, power or privilege under this Card Services Agreement shall operate
          as a waiver thereof; nor shall any single or partial exercise of any right, power or privilege under this Card
          Services Agreement preclude any other or further exercise thereof or the exercise of any right, power, or
          privilege. </p>

        <p><strong>19. EXCHANGE OF INFORMATION. </strong></p>
        <p>Merchant authorizes Global Direct to order a credit report on Merchant or any owner, officer, shareholder,
          partner, proprietor, managing agent, or guarantor of Merchant. Merchant hereby authorizes Member or any
          depository institution to release any financial information concerning Merchant or its accounts to Global
          Direct. Subsequent credit reports may be ordered in connection with updating, renewing, or continuing this
          Agreement. Upon the written request of any individual who is the subject of a consumer credit report, Global
          Direct will provide the name and address of the consumer credit reporting agency furnishing such report, if
          any. Global Direct may exchange information about Merchant, Merchant's owners, principals, partners,
          proprietors, officers, shareholders, managing agents, and guarantors with Member, other financial institutions
          and credit card networks, network organizations and any other party. Merchant hereby authorizes Global Direct
          to disclose information concerning Merchant's activity to any card network, network organizations, or any of
          their member financial institutions, or any other party without any liability whatsoever to Merchant. </p>

        <p><strong>20. ONLINE STATEMENTS, PAYMENTS HUB AND INSIGHTS. </strong></p>
        <p>If Merchant elects to receive monthly statements by accessing them on-line (an "E-statement"), Merchant will
          need to visit and register at <a href='https://www.paymentshub.com' style={styles.link}>www.paymentshub.com</a>, agent's secure
          website, to obtain a user ID and password. Merchant will be required to provide their merchant ID and other
          additional information to verify their identification. After completing the registration process, Merchant
          will be able to access their E-statement(s) by visiting <a href='https://www.paymentshub.com' style={styles.link}>www.paymentshub.com/</a>
          and logging in with their user ID and password, which user ID and password can be reset at any time by
          Merchant. Merchant will typically have access to the E-statement by the third business day of the following
          month. Merchant acknowledges and agrees that if they elect to receive E- Statements, they will not receive a
          monthly paper account statement by mail. However, Merchant is entitled to also receive a paper copy of monthly
          statements through the U.S. mail by visiting <a href='https://www.paymentshub.com' style={styles.link}>www.paymentshub.com</a>, and
          following the opt-out procedures provided through that link, or by contacting their agent or the customer
          service number provided on their statements and in the welcome letter; provided, however, exercising the
          option to opt out does not constitute a withdrawal of consent to receive monthly E-statements. If Merchant
          chooses to receive paper statements, Merchant will be charged a monthly fee of $1.00 for each statement. In
          order to register to receive and access to E- statements, Merchant will need (i) internet access, (ii) a
          currently supported version of one the following internet browsers: Internet Explorer, Firefox, or Safari, and
          (iii) a currently supported PDF viewer (such as Adobe Reader which is available for free download at <a href='http://get.adobe.com/reader/' style={styles.link}>http://get.adobe.com/reader/</a>). These requirements, browsers, and/or
          PDF readers may change from time to time, and Merchant will be provided notice of any such change. Neither
          Member, Global Direct nor its agents are responsible for ensuring that Merchant's E-statements are accessible
          through outdated vendor products. In the event Merchant is unable to access any of the information that has
          been made available on the E-statement, it is Merchant's obligation to notify its agent immediately. Without
          limiting any of the rights under this Agreement, neither Member, Global Direct nor its agents are responsible
          for: (i) consequential or incidental damages caused by services performed by Member, Global Direct nor its
          agents, any agents of Member, Global Direct or its agents, or Merchant's internet services provider; (ii)
          damages arising from unauthorized access to E-statements; or (iii) any costs associated with updating,
          modifying, or terminating Merchant's software or hardware. Member, Global Direct or its agents may change,
          suspend, or terminate all or any aspect of the E-statement upon notice to Merchant. Furthermore, through the
          premium features of Payments Hub, Member offers certain programs under which it provides replacement of credit
          card processing terminals that fail to operate, shipment of replacement terminal receipt paper-rolls/supplies,
          and web-based access to the merchant's Card transaction data 24 hours per day, 7 days per week.</p>
        <p><strong>21. GENERAL. </strong></p>
        <p>If any provision of this Agreement or portion thereof is held to be unenforceable, such a determination will
          not affect the remainder of this Agreement. Paragraph headings are included for convenience only and are not
          to be used in interpreting this Agreement.</p>

        <p><strong>22. NOTICES. </strong></p>
        <p>All notices required by this Agreement shall be in writing and shall be sent by telefax, overnight carrier,
          or by regular or certified mail. All notices sent to Global Direct or Member shall be effective upon actual
          receipt by the Corporate Secretary of Global Payments Direct, Inc. 10 Glenlake Parkway North Tower, Atlanta,
          Georgia 30328. Any notices sent to Merchant shall be effective upon the earlier of actual receipt or upon
          sending such notice to the address provided by Merchant in the Merchant Application. The parties hereto may
          change the name and address of the person to whom all notices or other documents required under this Agreement
          must be sent at any time by giving written notice to the other party. </p>

        <p><strong>23. TELEPHONE MONITORING AND CONTACTING THE MERCHANT. </strong></p>
        <p>From time to time Global and/or its agents may monitor and record telephone calls regarding Merchant's
          account to assure the quality of their service(s). Merchant agrees, in order for Global and/or its agents to
          service the account or to collect any amounts Merchant may owe, that Global and/or its agents may from time to
          time make calls and/or send e-mails and/or text messages to the Merchant, using prerecorded/artificial voice
          messages and/or through the use of an automatic dialing device, at any telephone number associated with
          Merchant's account, including wireless telephone numbers that could result in charges to Merchant, or at any
          email address Merchant provide to Global and/or its agents. </p>

        <p><strong>24. MERGER. </strong></p>
        <p>The Agreement, including these Terms and Conditions and the Merchant Application, constitutes the entire
          Agreement between Merchant, Global Direct, and Member and supersedes all prior memoranda or agreements
          relating thereto, whether oral or in writing. The Merchant, Global Direct and Member agree that, in order for
          the rights and obligations of the parties to be fairly and equitable administered, the various purchases of
          indebtedness contemplated under these Terms and Conditions and the Merchant Application shall constitute a
          single, integrated transaction, and not a series of separate or discrete transactions. </p>

        <p><strong>25. CHOICE OF LAW/ATTORNEY'S FEES/VENUE/JURY TRIAL WAIVER.</strong></p>

        <p><strong>Choice of Law</strong> <br/>
          This Agreement and any controversies or claims arising out of or relating to (a) this Agreement, (b) the
          relationships which result from this Agreement, or (c) the validity, scope, interpretation or enforceability
          of this Agreement, shall be governed by, and construed in accordance with, the laws of the State of Georgia,
          without giving effect to the conflict of law principles thereof, except that Section 25 concerning arbitration
          shall be governed by the Federal Arbitration Act, 9 U.S.C. § 1, et seq. <br/>

          <strong>Arbitration</strong> <br/>
          The parties agree that any controversies or claims arising out of or relating to (a) this Agreement, (b) the
          relationships which result from this Agreement, or (c) the validity, scope, interpretation or enforceability
          of this Agreement, except where the amount in controversy is $25,000 or less, shall be settled by binding
          arbitration in accordance with the Commercial Arbitration Rules of the American Arbitration Association. The
          parties further agree that the arbitrator in any such arbitration shall not be authorized to award any
          consequential, incidental, exemplary or punitive damages. The decision of the arbitrator in any such
          arbitration shall be final and binding upon the parties, and judgment upon the award may be entered in any
          court having jurisdiction thereof. The arbitration shall take place in Atlanta, Georgia. Each party shall bear
          its own costs and expenses, including attorney's fees, but the expenses of the arbitrator and administrative
          fees shall be borne by the losing party. The arbitration shall be conducted before a single arbitrator
          mutually agreed to by the parties. If the parties are unable to agree on the arbitrator, the arbitrator shall
          be selected pursuant to the AAA rules. The arbitrability of disputes shall be determined by the arbitrator.
          <br/>

          <strong>Waiver of Class Actions</strong> <br/>
          The parties further expressly waive the right to bring a class action claim. All parties to the arbitration or
          lawsuit must be individually named. There shall be no right or authority for any claims to be arbitrated or
          litigated on a class action or consolidated basis or on bases involving claims brought in a representative
          capacity on behalf of the general public (such as a private attorney general), other recipients of Services,
          or other persons similarly situated.

          <strong>Waiver of Jury Trial </strong> <br/>
          For controversies or claims arising out of or relating to this Agreement that are excepted from arbitration
          under Section 25 because they involve an amount in controversy of $25,000 or less, each party hereby
          irrevocably waives its rights to trial by jury.</p>

        <p><strong>26. EFFECTIVE DATE.</strong></p>
        <p>This Card Services Agreement shall become effective only upon acceptance by Global Direct and Member, or upon
          delivery of indebtedness at such locations as designated by Global Direct for purchase, whichever event shall
          first occur. </p>

        <p><strong>27. DESIGNATION OF DEPOSITORY. </strong></p>
        <p>The financial institution set forth in the Merchant Application is designated by Merchant as a depository
          institution ("Depository") for its credit card indebtedness. Such financial institution must be a member of an
          Automated Clearing House Association. Merchant authorizes payment for indebtedness purchased hereunder to be
          made by paying Depository therefore with instructions to credit Merchant's accounts. Depository, Member,
          and/or Global Direct may charge any of Merchant's accounts at Depository for any amount due under this
          Agreement. Global Direct must approve in writing any proposed changes to the account numbers or to the
          Depository. Merchant hereby authorizes Depository to release any and all account information to Global Direct
          or anyone acting on Global Direct's behalf as Global Direct may request without any further authorization,
          approval, or notice from or to Merchant. </p>

        <p><strong>28. FINANCIAL ACCOMMODATION. </strong></p>
        <p>The acquisition and processing of sales slips hereunder is a financial accommodation and, as such, in the
          event Merchant becomes a debtor in bankruptcy, this Agreement cannot be assumed or enforced, and Global Direct
          and Member shall be excused from performance hereunder.</p>

        <p><strong>29. DEBIT/ ATM PROCESSING SERVICES: ADDITIONAL TERMS AND CONDITIONS.</strong></p>
        <p>Debit Sponsor shall act as Merchant's sponsor with respect to the participation of point-of-sale terminals
          owned, controlled, and/or operated by Merchant (the "Covered Terminals") in each of the following debit card
          networks ("Debit Networks"): Accel, AFFN, Alaska Option, CU24, Interlink, Maestro, NYCE, Pulse, Shazam, Star,
          and Tyme, which Debit Networks may be changed from time-to-time by Debit Sponsor or Global Direct without
          notice. Merchant may also have access to other debit networks that do not require a sponsor. Global Direct
          will provide Merchant with the ability to access the Debit Networks at the Covered Terminals for the purpose
          of authorizing debit card transactions from cards issued by the members of the respective Debit Networks.
          Global Direct will provide connection to such Debit Networks, terminal applications, settlement, and reporting
          activities. Merchant will comply with all federal, state, and local laws, rules, regulations, and ordinances
          ("Applicable Laws") and with all by-laws, regulations, rules, and operating guidelines of the Debit Networks
          ("Network Rules"). Merchant will execute and deliver any application, participation, or membership agreement
          or other document necessary to enable Debit Sponsor to act as sponsor for Merchant in each Network. Merchant
          agrees to utilize the debit card Services in accordance with the Card Services Agreement, its exhibits or
          attachments, and Global Direct's instructions and specifications (including but not limited to the Card
          Acceptance Guide which is incorporated into and made a part of this Card Services Agreement), and to provide
          Global Direct with the necessary data in the proper format to enable Global Direct to properly furnish the
          Services. Copies of the relevant agreements or operating regulations shall be made available to Merchant upon
          request. Merchant shall not in any way indicate that Debit Sponsor endorses Merchant's activities, products,
          or services. Debit Sponsor and Merchant are and shall remain independent contractors of one another, and
          neither they, nor their respective individual employees, shall have or hold themselves out as having any power
          to bind the other to any third-party. Nothing contained in this Section shall be construed to create or
          constitute a partnership, joint venture, employer- employee, or agency relationship between Debit Sponsor and
          Merchant. In the event that Debit Sponsor's sponsorship of Merchant in any Network is terminated prior to the
          termination of the Card Services Agreement, Global Direct may assign Debit Sponsor's rights and obligations
          hereunder to a third-party. All provisions in this Section necessary to enforce the rights and obligations of
          the parties contained in this Section shall survive the termination of Debit Sponsor's debit sponsorship of
          Merchant under the Card Services Agreement. Debit Sponsor may assign this Agreement to any parent, subsidiary,
          affiliate, or successor- in-interest. DEBIT CARD MERCHANT. EACH DEBIT CARD TRANSACTION WILL BE ASSESSED THE
          NETWORK'S ACQUIRER FEE IN ADDITION TO THE DEBIT CARD PER ITEM FEE QUOTED IN THE CARD SERVICES FEE SCHEDULE OF
          THE MERCHANT APPLICATION </p>

        <p><strong>30. MERCHANTACCEPTANCE OF EBT TRANSACTIONS: ADDITIONAL TERMS AND CONDITIONS. </strong></p>
        <p>Merchant agrees to issue Benefits to Recipients in accordance with procedures specified herein, and in all
          documentation and user guides provided to Merchant by Global Direct, as amended from time-to-time (including,
          but not limited to, the Card Acceptance Guide which is incorporated into and made a part of this Card Services
          Agreement); and pursuant to the Quest Operating Rules (the "Rules"), as amended from time-to-time, issued by
          the National Automated Clearing House Association as approved by the Financial Management Service of the U.S.
          Treasury Department. Unless otherwise defined herein, all capitalized terms shall have the meanings ascribed
          them in the Rules. Merchant will provide each recipient a receipt of each Benefit issuance. Merchant will be
          solely responsible for Merchant's issuance of Benefits other than in accordance with authorizations. Merchant
          agrees to comply with all the requirements, laws, rules and regulations pertaining to the delivery of services
          to Benefit Recipients and Benefit Recipient confidentiality. If Merchant issues FS Benefits under this Card
          Services Agreement, Merchant represents and warrants to Global Direct that Merchant is and FNS-authorized
          "Merchant" (as such term is defined in the Rules) and is not currently suspended or disqualified by FNS.
          Merchant agrees to secure and maintain at its own expense all necessary licenses, permit, franchises, or other
          authorities required to lawfully effect the issuance and distribution of Benefits under this Card Services
          Agreement, including, without limitation, any applicable franchise tax certificate and non-governmental
          contractor's certificate, and covenants that Merchant will not issue Benefits at any time during which
          Merchant is not in compliance with the requirements of any applicable law. Merchant agrees to hold Global
          Direct harmless from any costs of compliance or failure to comply with any such obligation by Merchant. Global
          Direct may terminate or modify the provision of Services to Merchant if any of Global Direct's agreements with
          government EBT agencies are terminated for any reason or if any party threatens to terminate services to
          Global Direct due to some action or inaction on the part of Merchant. If any of these Card Services
          Terms &amp; Conditions are found to conflict with Federal or State law, regulation or policy of the Rules,
          these Card Services Terms &amp; Conditions are subject to reasonable amendment by Global Direct, the State or
          its EBT Service Provider to address such conflict upon ninety (90) days written notice to Merchant, provided
          that Merchant may, upon written notice, terminate the Card Services Agreement upon receipt of notice of such
          amendment. Nothing contained herein shall preclude the State from commencing appropriate administrative or
          legal action against Merchant or for making any referral for such action to any appropriate Federal, State, or
          local agency. Any references to "State" herein shall mean the State in which Merchant issues Benefits pursuant
          hereto. If merchant issues Benefits in more than one State pursuant hereto, then the reference shall mean each
          such State severally, not jointly. </p>

        <p><strong>31. DISCOVER PROGRAM MARKS. </strong></p>
        <p>Merchant is hereby granted a limited non-exclusive, non-transferable license to use Discover brands, emblems,
          trademarks, and/or logos that identify Discover cards ("Discover Program Marks"). Merchant is prohibited from
          using the Discover Program Marks other than is expressly authorized in writing by Global Direct. Merchant
          shall not use the Discover Program Marks other than to display decals, signage, advertising and other forms
          depicting the Discover Program Marks that are provided to Merchant by Global Direct pursuant to this Card
          Services Agreement or otherwise approved in advance in writing by Global Direct. Merchant may use the Discover
          Program Marks only to promote the services covered by the Discover Program Marks by using them on decals,
          indoor and outdoor signs, advertising materials and marketing materials; provided that all such uses by
          Merchant must be approved in advance by Global Direct in writing. Merchant shall not use the Discover Program
          Marks in such a way that customers could believe that the products or services offered by Merchant are
          sponsored or guaranteed by the owners of the Discover Program Marks. Merchant recognizes that it has no
          ownership rights in the Discover Program Marks and shall not assign to any third-party any of the rights to
          use the Discover Program Marks. </p>

        <p><strong>32. PAYPAL MARKS. </strong></p>
        <p>PayPal Marks means the brands, emblems, trademarks, and/or logos that identify PayPal Acceptance. Merchant
          shall not use the PayPal Marks other than to display decals, signage, advertising, and other forms depicting
          the PayPal Marks that are provided to Merchant by Global Direct pursuant to the Merchant Program or otherwise
          approved in advance in writing by Acquirer. Merchant may use the PayPal Marks only to promote the services
          covered by the PayPal Marks by using them on decals, indoor and outdoor signs, advertising materials and
          marketing materials; provided that all such uses by Merchant must be approved in advance by Global Direct in
          writing. Merchant shall not use the PayPal Marks in such a way that customers could believe that the products
          or services offered by Merchant are sponsored or guaranteed by the owners of the PayPal Marks. Merchant
          recognizes that it has no ownership rights in the PayPal Marks. Merchant shall not assign to any third-party
          any of the rights to use the PayPal Marks. Merchant is prohibited from using the PayPal Marks, not permitted
          above, unless expressly authorized in writing by PayPal. </p>

        <p><strong>33. AMERICAN EXPRESS CARD ACCEPTANCE. </strong></p>
        <p>Merchant hereby acknowledges and agrees that for purposes of acceptance of American Express, the American
          Express Guide is hereby incorporated by reference into this Card Services Agreement. In addition, Merchant
          agrees to comply with the terms of all other security and operational guides published by American Express
          from time to time, including the American Express Data Security Requirements. Merchant hereby authorizes
          Global Direct to submit American Express transactions to, and receive settlement from, American Express on
          behalf of Merchant. Merchant must accept the American Express card as payment for goods and services (other
          than those goods and services prohibited under the American Express Guide sold, or (if applicable) for
          charitable contributions made, at all of its establishments, except as expressly permitted by state statute.
          Merchant is jointly and severally liable for the obligations of Merchant's establishments under the Card
          Services Agreement. For the avoidance of doubt, "cardholder" as used in this Card Services Agreement shall
          include Card Members as defined in the American Express Guide. Merchant hereby acknowledges and agrees that
          (i) Global Direct may disclose American Express Transaction Data (which for purposes of this Section 33 shall
          have the same definition as "Transaction Data" in the American Express Guide), Merchant Data (as defined
          below), and other information about Merchant to American Express, (ii) American Express may use such
          information to perform its responsibilities in connection with the American Express Program, promote the
          American Express Network, perform analytics and create reports, and for any other lawful business purpose,
          including marketing purposes, and (iii) American Express may use the information obtained in this application
          at the time of setup to screen and/or monitor Merchant in connection with American Express Card marketing and
          administrative purposes. If Merchant has provided a wireless phone number in connection with this Card
          Services Agreement, Merchant hereby agrees that it may be contacted at that number and the communications sent
          may include autodialed text messages or automated prerecorded calls. If Merchant has provided a fax number,
          Merchant hereby agrees that it may be sent fax communications. To opt out of American Express-related
          marketing communications, Merchant may contact Global Direct customer service as described in this Card
          Services Agreement. For purposes of this Section 33, "Merchant Data" means names, postal and email addresses,
          tax ID numbers, names and social security numbers of the authorized signer of Merchant and similar identifying
          information about Merchant. For clarification, Merchant Data does not include American Express Transaction
          Data. Merchant hereby agrees that, in the event that Merchant becomes a High Charge Volume Merchant (as
          defined below), Merchant will be converted to from the American Express Program to a direct American Express
          Card acceptance agreement relationship with American Express, and upon such conversion, (i) Merchant will be
          bound by American Express' then-current card acceptance agreement, and (ii) American Express will set pricing
          and other fees payable by Merchant for American Express Card acceptance. "High Charge Volume Merchant" for
          purposes of this Section 33 means an American Express Program Merchant with either (i) greater than $1,000,000
          in American Express charge volume in a rolling twelve (12) month period or (ii) greater than $100,000 in
          American Express charge volume in any three (3) consecutive months. For clarification, if Merchant has
          multiple establishments, the American Express charge volume from all establishments shall be summed together
          when determining whether Merchant has exceeded the thresholds above. Merchant shall not assign to any
          third-party any American Express-related payments due to it under this Card Services Agreement, and all
          indebtedness arising from American Express Charges (as defined below) will be for bona fide sales of goods and
          services (or both) at its establishments (as defined below) and free of liens, claims, and encumbrances other
          than ordinary sales taxes; provided, however, that Merchant may sell and assign future American Express
          transaction receivables to Global Direct, its affiliated entities and/or any other cash advance funding source
          that partners with Global Direct or its affiliated entities, without consent of American Express. Merchant
          hereby agrees that American Express shall have third-party beneficiary rights, but not obligations, to enforce
          the Card Services Agreement against Merchant to the extent applicable to American Express processing.
          Merchant's termination of American Express card acceptance shall have no direct or indirect effect on the
          Merchant's rights to accept other card brands. To terminate American Express acceptance, Merchant may contact
          Global Direct customer services as described in this Card Services Agreement. Without limiting any other
          rights provided herein, Global Direct shall have the right to immediately terminate Merchant's acceptance of
          American Express cards upon request of American Express. Merchant may not bill or collect from any American
          Express Card Member for any purchase or payment on the American Express card unless a chargeback has been
          exercised, Merchant has fully paid for such charge, and it otherwise has the right to do so. Merchant shall
          use the American Express brand and marks in accordance with the requirements set forth in the American Express
          Guide. In connection with Merchants acceptance of American Express, Merchant agrees to comply with and be
          bound by the rules and regulations imposed by the Payment Card Industry (PCI) Security Standards Council
          (including, without limitation, the PCI Data Security Standard). Merchant hereby agrees to report all actual
          or suspected Data Incidents (as such term is defined in the American Express Data Security Requirements)
          immediately to Global Direct and American Express immediately upon discovery thereof. </p>

        <p><strong>34. MID AND NON-QUALIFIED SURCHARGES/CROSS-BORDER FEES </strong></p>
        <p>Merchant pricing appears in the Pricing Schedule of the Merchant Application. T&amp;E merchants (airline, car
          rental, cruise line, fast food, lodging, restaurant, travel agent, transportation) may have separate rates
          quoted for consumer and commercial (business) transactions. Transactions that do not clear as priced are
          subject to MID or non-qualified surcharges (MID/NQS) that are billed back to you on your monthly statement.
          The most predominate market sectors and applicable MID or non-qualified surcharge rates appear below, however,
          such sectors and transaction types are not comprehensive and are subject to change. Most MID or non-qualified
          surcharges can be avoided by using products that support authorization and market data requirements
          established by the card networks and that are subject to change from time to time. Some MID or non-qualified
          surcharges occur on specific types of cards (including, without limitation, Visa Rewards Card, Visa Signature
          Card, Visa Signature Preferred Card, Visa Infinite Card, Mastercard Rewards Card. Mastercard World Card,
          Mastercard World Elite Card, Discover Rewards Card, Discover Premium Card, Discover Premium Plus Card, and
          "foreign" cards issued outside the United States), if the merchant accepts credit cards. <strong>Unless your
            Pricing Schedule specifically addresses commercial cards (i.e., Business Cards, Corporate Cards, Fleet
            Cards, GSA Cards, Purchase Cards), you will be billed back for the higher cost of acceptance of commercial
            cards; this is also unavoidable based on the card network requirement to accept all types of credit cards,
            if the merchant accepts credit cards, unless you are primarily a business-to-business supplier with
            corresponding pricing based on acceptance of commercial cards. </strong>The card networks require that
          information from the original authorization, including a life cycle identifier, be retained and returned with
          subsequent authorizations and/or the settled transaction data. The card networks validate this information as
          part of the clearing and settlement process. If authorization data is not retained and returned at settlement,
          then the transaction will not clear as priced and will incur MID/NQS. The items listed in this Section 34 are
          not and are not intended to be a comprehensive list of all instances in which non-qualified surcharges may
          apply. Non-qualified surcharges may apply in additional situations. All non-qualified surcharges include
          additional fees assessed by the applicable card networks and Global Direct. Merchant will also be assessed (a)
          Cross-Border fees and a U.S. Acquirer Support fee for international Mastercard and Maestro transactions. (b)
          an International Service Assessment fee and International Acquirer fee for international Visa transactions,
          and (c) an International Processing fee and International Service 9 fee for international Discover
          transactions. These fees, which are applicable to transactions between the merchant and a Mastercard or
          Maestro or Visa card holder as applicable, outside the United States, which are in addition to any fees stated
          on the Merchant Application and will be displayed as a separate item on Merchant's monthly statement. All
          references to non-qualified surcharges in this Section 34 shall include both mid-qualified and non- qualified
          surcharges. Merchant will also be assessed per transaction access fees and assessment rates for Visa,
          Mastercard, and Discover transactions, which will be displayed as a separate item on Merchant's monthly
          statement and may include fees by both the applicable card networks and Member or Global Direct. Merchant will
          also be assessed the following fees on or related to Visa transactions: the Visa Misuse of Authorization
          System fee, which will be assessed on authorizations that are approved but never settled with the Merchant's
          daily batch, the Visa Zero Floor Limit Fee, which will be assessed on settled transactions that were not
          authorized, the Visa Zero Dollar Verification fee, which will be assessed on transactions where Merchant
          requested an address verification response without an authorization, the Visa Transaction Integrity fee, which
          will be assessed on Visa signature debit and prepaid transactions that fail to meet processing and transaction
          standards defined by Visa, and a monthly fee based on the number of card present Merchant locations by
          Merchant taxpayer identification number and/or all Visa volume processed by a Merchant's taxpayer
          identification number Merchant will also be assessed the Mastercard Misuse of Authorization System fee, which
          will be assessed on authorizations that are approved but never settled with the Merchant's daily batch or not
          properly reversed within 120 days, and an acceptance and licensing fee that will be applied to the Merchant's
          total U.S. Mastercard sales volume. These fees will be displayed as separate items on Merchant's monthly
          statement, provided that the acceptance and licensing fee may be included with Merchant's Mastercard
          assessment fees and may include fees assessed by both the applicable card networks and Member or Global
          Direct. </p>

        <p><strong>Association Fees and Assessments </strong></p>
        <table width='100%' style={{borderStyle: 'solid', borderWidth: 1, borderColor: '#000000'}} cellSpacing='0' cellPadding='0'>
          <tbody>
          <tr>
            <td width='100%' colSpan='4' style={{backgroundColor: 'silver', fontSize: 18}} cellSpacing='0' cellPadding='0'>
              <strong>Association Fees and Assessments</strong>
              (Per occurrence fees marked with a *)
            </td>
          </tr>
          <tr>
            <td width='8%' style={{padding: 5}}>0.4000%</td>
            <td width='48%' style={{padding: 5}}>GP Free-Amex Inbound*</td>
            <td width='8%' style={{padding: 5}}>$0.0195</td>
            <td width='48%' style={{padding: 5}}>GP Fee MasterCard NABU*</td>
          </tr>
          <tr>
            <td width='8%' style={{padding: 5}}>0.1500%</td>
            <td width='48%' style={{padding: 5}}>GP Free-Amex Network*</td>
            <td width='8%' style={{padding: 5}}>0.0100%</td>
            <td width='48%' style={{padding: 5}}>GP Fee MasterCard Digital Enablement*</td>
          </tr>
          <tr>
            <td width='8%' style={{padding: 5}}>0.3000%</td>
            <td width='48%' style={{padding: 5}}>GP Free-Amex CNP*</td>
            <td width='8%' style={{padding: 5}}>$2.9700</td>
            <td width='48%' style={{padding: 5}}>GP Fee- MasterCard per Location (Monthly)</td>
          </tr>
          <tr>
            <td width='8%' style={{padding: 5}}>0.1000%</td>
            <td width='48%' style={{padding: 5}}>GP Free-Amex Acess*</td>
            <td width='8%' style={{padding: 5}}>$0.0195</td>
            <td width='48%' style={{padding: 5}}>GP Fee PayPal Participation*</td>
          </tr>
          <tr>
            <td width='8%' style={{padding: 5}}>0.1300%</td>
            <td width='48%' style={{padding: 5}}>GP Fee-Discover Assessments*</td>
            <td width='8%' style={{padding: 5}}>0.1300%</td>
            <td width='48%' style={{padding: 5}}>GP Fee-PayPal Assessments*</td>
          </tr>
          <tr>
            <td width='8%' style={{padding: 5}}>0.5000%</td>
            <td width='48%' style={{padding: 5}}>GP Fee-Discover International Processing *</td>
            <td width='8%' style={{padding: 5}}>$0.0195</td>
            <td width='48%' style={{padding: 5}}>GP Fee-Visa Acquirer Processing – Credit*</td>
          </tr>
          <tr>
            <td width='8%' style={{padding: 5}}>0.8000%</td>
            <td width='48%' style={{padding: 5}}>GP Fee-Discover International Service *</td>
            <td width='8%' style={{padding: 5}}>$0.0155</td>
            <td width='48%' style={{padding: 5}}>GP Fee-Visa Acquirer Processing – Debit*</td>
          </tr>
          <tr>
            <td width='8%' style={{padding: 5}}>$0.0195</td>
            <td width='48%' style={{padding: 5}}>GP Fee- Discover Data Usage*</td>
            <td width='8%' style={{padding: 5}}>0.1300%</td>
            <td width='48%' style={{padding: 5}}>GP Fee-Visa Assessments-Credit*</td>
          </tr>
          <tr>
            <td width='8%' style={{padding: 5}}>$0.0025</td>
            <td width='48%' style={{padding: 5}}>GP Fee-Discover Network Authorization *</td>
            <td width='8%' style={{padding: 5}}>0.1300%</td>
            <td width='48%' style={{padding: 5}}>GP Fee-Visa Assessments-Debit*</td>
          </tr>
          <tr>
            <td width='8%' style={{padding: 5}}>0.0100%</td>
            <td width='48%' style={{padding: 5}}>GP Fee-MasterCard Accept & License*</td>
            <td width='8%' style={{padding: 5}}>$0.0250</td>
            <td width='48%' style={{padding: 5}}>GP Fee Visa Account Inquiry*</td>
          </tr>
          <tr>
            <td width='8%' style={{padding: 5}}>0.8500%</td>
            <td width='48%' style={{padding: 5}}>GP Fee- MasterCard Acquirer PGM Support *</td>
            <td width='8%' style={{padding: 5}}>0.4500%</td>
            <td width='48%' style={{padding: 5}}>GP Fee-Visa International Acquirer *</td>
          </tr>
          <tr>
            <td width='8%' style={{padding: 5}}>0.1200%</td>
            <td width='48%' style={{padding: 5}}>GP Fee- MasterCard Assessments*</td>
            <td width='8%' style={{padding: 5}}>1.2000%</td>
            <td width='48%' style={{padding: 5}}>GP Fee-Visa International Service Assessment-Enhanced*</td>
          </tr>
          <tr>
            <td width='8%' style={{padding: 5}}>0.1400%</td>
            <td width='48%' style={{padding: 5}}>GP Fee-MasterCard Assessments-Large Ticket*</td>
            <td width='8%' style={{padding: 5}}>0.8000%</td>
            <td width='48%' style={{padding: 5}}>GP Fee-Visa International Service Assessment-Base *</td>
          </tr>
          <tr>
            <td width='8%' style={{padding: 5}}>0.6000%</td>
            <td width='48%' style={{padding: 5}}>GP Fee-MasterCard Cross-Border Domestic*</td>
            <td width='8%' style={{padding: 5}}>$0.0018</td>
            <td width='48%' style={{padding: 5}}>GP Fee Visa File Transmission Fee*</td>
          </tr>
          <tr>
            <td width='8%' style={{padding: 5}}>1.0000%</td>
            <td width='48%' style={{padding: 5}}>GP Fee- MasterCard Cross-Border Foreign*</td>
            <td width='8%' style={{padding: 5}}>$0.0900</td>
            <td width='48%' style={{padding: 5}}>GP Fee-Visa Misuse of Authorization*</td>
          </tr>
          <tr>
            <td width='8%' style={{padding: 5}}>$0.0025</td>
            <td width='48%' style={{padding: 5}}>GP Fee-MasterCard CVC2*</td>
            <td width='8%' style={{padding: 5}}>$0.1000</td>
            <td width='48%' style={{padding: 5}}>GP Fee-Visa Transaction Integrity *</td>
          </tr>
          <tr>
            <td width='8%' style={{padding: 5}}>0.2500%</td>
            <td width='48%' style={{padding: 5}}>GP Fee MC Integrity – Final Auth (Max)*</td>
            <td width='8%' style={{padding: 5}}>$0.2000</td>
            <td width='48%' style={{padding: 5}}>GP Fee- Visa Zero Floor Limit*</td>
          </tr>
          <tr>
            <td width='8%' style={{padding: 5}}>$0.0400</td>
            <td width='48%' style={{padding: 5}}>GP Fee MC Integrity – Final Auth (Min) per Auth*</td>
            <td width='8%' style={{padding: 5}}>$0.0195</td>
            <td width='48%' style={{padding: 5}}>GP Fee Credit Voucher Processing (Credit)*</td>
          </tr>
          <tr>
            <td width='8%' style={{padding: 5}}>$0.0450</td>
            <td width='48%' style={{padding: 5}}>GP Fee MC Integrity – PreAuth/Undefined per Auth*</td>
            <td width='8%' style={{padding: 5}}>$0.0155</td>
            <td width='48%' style={{padding: 5}}>GP Fee Credit Voucher Processing (Debit)*</td>
          </tr>
          </tbody>
        </table>

        <p>* GP Fee stands for ‘Global Payments Fee' and indicates fees charged to you in connection with the Services
          provided by Global Direct or third-party hereunder. </p>

        <p><strong>MID AND NON-QUALIFIED SURCHARGES FOR PREDOMINANT MARKET SECTORS</strong><strong>Retail/Restaurant
          Electronic Merchant</strong> If you are a Retail Merchant or a Restaurant Merchant with retail-only pricing
          (no Business Card Rate) and utilize a certified terminal product or electronic system or the payment
          application provided by Global Direct or its partner, which is designed for authorization and settlement
          through Global Direct, each consumer card transaction you submit which meets all of the following requirements
          will be priced at the rate quoted. Each transaction not processed as outlined, including, without limitation,
          retail commercial card transactions in addition to transactions using Visa Rewards Card, Visa Signature Card,
          Visa Signature Preferred Card, Visa Infinite Card, Mastercard Rewards Card, Mastercard World Card, Mastercard
          World Elite Card, and all Commercial Cards, will be priced at the non-qualified rate quoted in the Merchant
          Application</p>
          <p>&#8226; Obtain a single electronic authorization with magnetic strip read or contactless data capture
            (electronic imprint) at the time of sale.</p>
          <p>&#8226; Obtain a single electronic authorization and settle for authorized amounts.</p>
          <p>&#8226; Obtain a cardholder signature (unless transaction is eligible for No Signature Required [NSR]
            program).</p>
          <p>&#8226; Settle and transmit batches same day via your terminal/electronic system.</p>
          <p>&#8226; The electronic authorization amount must be equal to the transaction amount on all Visa debit card
            transactions unless a Restaurant (MCC 5812), Fast Food (MCC 5814), Service Station (MCC 5541) or, Bar/Tavern
            (MCC 5513), Beauty/Barber Shop (MCC 7230), or Taxi/limousines (MCC 4121).</p>
          <p>&#8226; The electronic authorization amount must be equal to the transaction amount on Discover retail
            transactions except that Taxi limousines (MCC 4121) and Beauty/Barber Shop (MCC 7230) merchant transactions
            may vary up to 20%. Restaurant (MCC 5812), Fast Food (MCC 5814), Service Station (MCC 5541) or Bar/Tavern
            (MCC 5513) transactions may vary by more than 20% from the electronic authorization without incurring
            NQS.</p>

          <p><strong>Restaurant Electronic Merchant</strong></p>
          <p>If you are a Restaurant Merchant (MCC 5812) or Fast Food Merchant (MCC 5814) and utilize a certified
            terminal product or electronic system for authorization and settlement through Global Direct, each consumer
            card transaction you submit which meets all of the following requirements will be priced at the rate quoted.
            Each transaction not processed as outlined, in addition to transactions using Visa Rewards Card, Visa
            Signature Card, Visa Signature Preferred Card, Visa Infinite Card, Mastercard Rewards Card, Mastercard World
            Card, and Mastercard World Elite Card will be priced at the non- qualified rate quoted in the Merchant
            Application. Commercial Card transactions that meet these requirements will be subject to the Business Card
            rate quoted in the Fee Schedule. Commercial Card transactions not processed in accordance with these
            requirements will be subject to the non- qualified rate quoted in the Merchant Application.</p>
          <p>&#8226; Obtain a single electronic authorization with magnetic strip read or contactless data capture
            (electronic imprint) at the time of sale.</p>
          <p>&#8226; Obtain a cardholder signature (unless transaction is eligible for NSR program).</p>
          <p>&#8226; Settle and transmit batches same day via your terminal/electronicsystem.</p>

          <p><strong>Supermarket Electronic Merchant</strong></p>
          <p>If you are an approved (certified) supermarket merchant and utilize a terminal or electronic system for
            authorization and settlement through Global Direct, each transaction you submit which meets all of the
            following requirements will be priced at the rate(s) quoted for Supermarket Credit Card and Supermarket
            Check Card. Each transaction not processed as outlined, in addition to transactions using Visa Rewards Card,
            Visa Signature Card, Visa Signature Preferred Card, Visa Infinite Card, Mastercard Rewards Card, Mastercard
            World Card, Mastercard World Elite, and commercial cards, will be priced at the nonqualified rate quoted in
            the Merchant Application.</p>
          <p>&#8226; Obtain a magnetic strip read (card swipe/contactless data capture/electronic imprint) at the time
            of sale.</p>
          <p>&#8226; Obtain a single electronic authorization and settle for authorized amounts. </p>
          <p>&#8226; Obtain a cardholder signature (unless transaction is eligible for NSR program).</p>
          <p>&#8226; Settle and transmit batches same day via your terminal/electronic system.</p>
          <p>&#8226; The electronic authorization amount must be equal to the transaction amount on all Visa debit card
            transactions.</p>

          <p><strong>Emerging Market Electronic Merchant</strong></p>
          <p>If you qualify as an Emerging Market Merchant (as defined by Network guidelines from time-to-time) and
            utilize a terminal or electronic system for authorization and settlement through Global Direct, each
            transaction you submit which meets all the following requirements will be priced at the rates quoted. Any
            other transaction, including commercial card transactions, Visa Rewards Card, Visa Signature Card, Visa
            Signature Preferred Card, Visa Infinite Card, Mastercard Rewards Card, Mastercard World Card, and Mastercard
            World Elite Card, and non-magnetic stripe read foreign transactions will be priced at the non-qualified rate
            quoted in the Merchant Application. In addition, each Visa transaction not processed as outlined, but
            transmitted same day or next day via your terminal/electronic system, will be priced at the non-qualified
            rate quoted in the Merchant Application.</p>
          <p>&#8226; Obtain a single electronic authorization.</p>
          <p>&#8226; Settle and transmit batches same day via your terminal/electronicsystem.</p>
          <p>&#8226; Provide market data as required:</p>
          <p>Note: If card is not present and a magnetic stripe read does not occur, then Merchant may be required to
            comply with "Direct Marketer" market data requirements including AVS request on cardholder billing address
            at time of authorization. If card is present and cardholder signature is obtained, however the magnetic
            stripe is damaged, then Merchant may be required to obtain AVS match on cardholder billing address zip
            code.</p>

          <p><strong>MOTO Electronic Merchant</strong></p>
          <p>If you are a MOTO Merchant (non-magnetic swipe read transactions), and utilize a certified terminal product
            or electronic system for authorization and settlement through Global Direct, each transaction you submit
            which meets all of the following requirements will be priced at the rate quoted. Any other transaction,
            including all foreign transactions and commercial card transactions in addition to transactions using Visa
            Rewards Card, Visa Signature Card, Visa Signature Preferred Card, Visa Infinite Card, Mastercard Rewards
            Card, Mastercard World Card, and Mastercard World Elite Card will be priced at the non-qualified rate quoted
            in the Merchant Application.</p>
            <p>&#8226; Obtain an electronic authorization and settle for authorized amounts (one reversal permitted on
              Visa transactions to make authorization amount equal to settle amount).</p>
            <p>&#8226; Address Verification Request in authorization on cardholder billing address. For Discover
              transactions, Merchant must obtain full address verification request on street number and/or 9 digit
              postal code.</p>
            <p>&#8226; CID verification for Discover merchants on non-recurring transactions.</p>
              <p>&#8226; Purchase date (settled date) is ship date.</p>
              <p>&#8226; Send order number with each transaction.</p>
              <p>&#8226; Settle and transmit batches same day via your terminal/electronic system.</p>
              <p>&#8226; Send level 3 data (line item detail, sales tax, customer code) with every eligible commercial
                card transaction.</p>

              <p>NOTE: Card Not Present transactions involving one-time, recurring, or installment bill payment
                transactions are subject to additional card network requirements which must be complied with to avoid
                NQS. Electronic commerce transaction requirements are also subject to additional card network
                requirements which must be complied with to avoid NQS. Please refer to Card Acceptance Guide for
                additional requirements.</p>

              <p>NOTE: Transactions which utilize our TouchTone Capture system for authorizations and settlement, settle
                beyond 48 hours, or are not transmitted via the TouchTone Capture system, will be priced at the rate
                quoted plus the applicable surcharge rate quoted in the Merchant Application. </p>

              <p><strong>Public Sector Electronic Merchant </strong></p>
              <p>If you are an approved (certified) public sector merchant and utilize a terminal or electronic system
                for authorization and settlement through Global Direct, each transaction you submit which meets all of
                the following requirements will be priced at the rate(s) quoted for Public Sector. Each transaction not
                processed as outlined, in addition to transactions using Visa Rewards Card, Visa Signature Card, Visa
                Signature Preferred Card, Visa Infinite Card, Mastercard Rewards Card, Mastercard World Card, Mastercard
                World Elite, Discover Rewards Card, Discover Premium Card, Discover Premium Plus Card and commercial
                cards, will be priced at the rate quoted plus the applicable surcharge rate quoted in the Merchant
                Application. </p>
              <p>&#8226; Obtain a magnetic strip read (card swipe/contactless data capture/electronic imprint) at the
                time of sale. &#8226; Obtain a single electronic authorization and settle for authorized amounts.&#8226;
                Obtain a cardholder signature (unless transaction is eligible for NSR program).&#8226; Settle and
                transmit batches same day via your terminal/electronic system. </p>
              <p>&#8226; The electronic authorization amount must be equal to the transaction amount on all Visa debit
                card transactions. </p>

              <p><strong>Purchase Card Electronic Merchant </strong></p>
              <p>If you are a Purchase Card Merchant (non-magnetic swipe read transactions) and utilize a certified
                terminal product or electronic system for authorization and settlement through Global Direct, each
                transaction you submit which meets the following requirements will be priced at the rate quoted. Each
                Visa transaction not processed as outlined, but transmitted same day or next day via your
                terminal/electronic system, will be priced at the non-qualified rate quoted in the Merchant Application.
                Each Visa business and commercial card transaction will be priced at the rate quoted plus 1.09%. Any
                other transaction that does not meet the following requirements, including without limitation foreign
                transactions, tax-exempt Visa Commercial transactions, Visa Rewards Card, Visa Signature Card, Visa
                Signature Preferred Card, Visa Infinite Card, Mastercard Rewards Card, Mastercard World Card, and
                Mastercard World Elite Card, will be priced at the non-qualified rate quoted in the Merchant
                Application. </p>
              <p>&#8226; Obtain an electronic authorization and settle for authorized amounts (one reversal permitted on
                Visa transactions to make authorization amount equal to settled amount).</p>
              <p>&#8226; Address Verification Request in authorization on cardholder billing address.</p>
              <p>&#8226; Purchase date (settled date) is ship date. </p>
              <p>&#8226; Send order number (customer code) with each transaction.</p>
              <p>&#8226; Send tax amount with every transaction.&#8226; Send Level 3 data (line item detail) with every
                eligible commercial card transaction. Sales tax exempt transactions will not be considered to meet these
                requirements unless they include Level 3 data (line item detail).</p>
              <p>&#8226; Settle and transmit batches same day via your terminal/electronic system.</p>

              <p><strong>Lodging/Auto Rental Electronic Merchant</strong></p>
              <p>If you are a Lodging or Auto Rental Merchant utilizing a terminal or electronic system for
                authorization and settlement through Global Direct, each consumer card transaction you submit which
                meets the following requirements will be priced at the rate quoted. Each transaction not processed as
                outlined, including, without limitation, non-magnetic stripe read foreign transactions, and transactions
                using Visa Rewards Card, Visa Signature Card, Visa Signature Preferred Card, Visa Infinite Card,
                Mastercard Rewards Card, Mastercard World Card, and Mastercard World Elite Card will be priced at the
                rate plus 1.48%. Commercial Card transactions that meet these requirements will be subject to the
                Business Card rate quoted in the Fee Schedule. Commercial Card transactions not processed in accordance
                with these requirements will be subject to the non-qualified rate quoted in the Merchant
                Application.</p>
              <p>&#8226; Obtain a magnetic swipe read (card swipe/electronic imprint) at the time of check-in.</p>
              <p>&#8226; Obtain additional electronic authorizations or send partial reversals to bring total authorized
                amount within 15% of settled amount. Authorizations must meet card network requirements.</p>
              <p>&#8226; Obtain a cardholder signature for final transaction amount.</p>
              <p>&#8226; Purchase Date is hotel check-out date/auto return date.</p>
              <p>&#8226; Length of guest stay/rental in initial authorization. </p>
              <p>&#8226; Hotel Folio/Rental Agreement Number and check-in date/check-out date transmitted with each
                transaction.</p>
              <p>&#8226; Additional market data may be required for commercial card transactions to avoid NQS. Lodging
                merchants who (1) accept credit cards for advance payment; (2) guarantee reservations using a credit
                card; or (3) provide express check-out services to guests, must comply with additional card network
                requirements for these services in addition to additional authorization and settlement market data
                requirements. Lodging merchants who subject charges to final audit and bill for ancillary/additional
                charges must comply with additional bank card network requirements for these services in addition to
                additional authorization and settlement market data requirements to avoid NQS. These transactions may
                also be subject to the rate quoted plus 1.48%. Please see Card Acceptance Guide for requirements and
                best practices for these transactions.</p>

              <p><strong>TouchTone Capture Merchant</strong></p>
              <p>Transactions which utilize our TouchTone Capture system for authorizations and settlement, settle
                beyond 48 hours, or are not transmitted via the TouchTone Capture system, will be priced at the
                non-qualified rate quoted in the Merchant Application.</p>

              <p><strong>Paper Deposit Merchant</strong></p>
              <p>Non-terminal/electronic paper deposit transactions will be priced at the rate quoted in the Card
                Services Fee Schedule of the Merchant Application.</p>

              <p><strong>Debit Card Merchant</strong></p>
              <p>Each debit card transaction will be assessed the network's acquirer fee in addition to the debit card
                per item fee quoted in the Card Services Fee Schedule of the Merchant Application.</p>

              <p><strong>Card Present: / Mag Stripe Failure:</strong></p>
              <p>A magnetic stripe read is also referred to as an electronic imprint. If the magnetic stripe is damaged,
                then other validation means may be required to protect against counterfeit cards and merchant must
                obtain a manual imprint. Most products, including the payment application, will prompt for cardholder
                billing zip code and perform an AVS check for a zip code match. CID verification is recommended for
                Discover key-entered transactions. Key-entered retail transactions are subject to higher interchange and
                NQS. <br/>
                The foregoing information regarding NQS is not comprehensive and is subject to change by the card
                networks. Additional or different rates or fees may apply based on the details of a subject transaction.
              </p>
              <p>For Member Contact: <br/>
                Wells Fargo Bank N.A., 1200 Montego, Walnut Creek, CA 94598. Debit sponsorship provided by Wells Fargo
                Bank N.A. </p>

      </div>
  );
  }
  }
