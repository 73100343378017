/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';

const NoPaymentMethod = (props) => {

  const color = props.color || '#00c4b3';

  return (

    <svg className={props.className} style={props.style} xmlns='http://www.w3.org/2000/svg' width='323.143' height='333.29'
         viewBox='0 0 323.143 333.29'>
      <defs>
        <style>{`.a{fill:#fff;}.b{fill:#1a1e22;}.c{fill:#212121;}.d{fill:${color};}`}</style>
      </defs>
      <g transform='translate(-228.599 -200)'>
        <g transform='translate(229.971 200)'>
          <g transform='translate(111.906 7.365)'>
            <path className='a'
                  d='M787.606,269.9c-.358,4.486-.061,9.181-.005,13.334.12,8.957,2,18.054,3.268,26.9,1.058,7.386,4.243,21.83-.221,28.807-2.745,4.29-12.243,6.149-13.571,8.7-1.421,2.728,7,3.306,9.176,5.48,4.025,4.021,10.867,7,15.859,9.716l.007,0c5.951,3.237,12.834,4.3,19.545,4.123,15.523-.42,33.77-10.756,37.217-27.459-8.586-2.574-11.434-2.936-12.364-12.029-.523-5.759.378-35.772-.519-45.079-1.707-17.713-6.386-39.546-27.284-41.932C805,238.9,794.252,249.756,789.4,261.573A27.8,27.8,0,0,0,787.606,269.9Z'
                  transform='translate(-775.873 -239.273)'/>
            <path className='b'
                  d='M816.259,364.076a38.507,38.507,0,0,1-18.6-4.27l-.008,0h0c-1.078-.587-2.235-1.183-3.461-1.813-4.394-2.26-9.375-4.822-12.637-8.081-.7-.7-2.571-1.31-4.22-1.846-2.5-.814-4.663-1.517-5.267-2.995a2.144,2.144,0,0,1,.123-1.857c.757-1.454,2.981-2.46,5.8-3.734,3.029-1.37,6.463-2.924,7.82-5.047,3.656-5.714,1.806-17.193.582-24.789-.192-1.191-.373-2.316-.515-3.308-.313-2.186-.671-4.433-1.018-6.606A143.522,143.522,0,0,1,782.6,279.3c-.014-1.067-.044-2.154-.075-3.3a94.961,94.961,0,0,1,.084-10.126,28.611,28.611,0,0,1,1.869-8.642,40.114,40.114,0,0,1,11.649-15.991,25.1,25.1,0,0,1,18.748-5.757c9.67,1.1,16.955,6.415,21.652,15.787,4.374,8.725,5.834,19.634,6.551,27.081.513,5.325.444,17.2.384,27.68-.045,7.692-.087,14.958.136,17.4.809,7.914,2.826,8.513,10.33,10.739l1.294.385a1.042,1.042,0,0,1,.722,1.209c-3.541,17.156-22.208,27.857-38.21,28.29Q816.991,364.076,816.259,364.076Zm-17.6-6.1,0,0c5.243,2.852,11.642,4.195,19.019,4,14.9-.4,32.209-10.1,36.015-25.7l-.358-.106c-7.681-2.279-10.86-3.222-11.812-12.538-.233-2.559-.193-9.537-.146-17.618.06-10.429.129-22.249-.374-27.468-.7-7.28-2.122-17.933-6.34-26.346-4.423-8.824-10.974-13.616-20.025-14.65a23.067,23.067,0,0,0-17.211,5.315,38.008,38.008,0,0,0-11.021,15.153,26.6,26.6,0,0,0-1.719,8.016,93.443,93.443,0,0,0-.079,9.9c.032,1.158.062,2.252.076,3.334A141.945,141.945,0,0,0,786.92,299.4c.348,2.181.707,4.437,1.022,6.639.14.974.319,2.09.51,3.272,1.274,7.909,3.2,19.861-.884,26.245-1.691,2.644-5.424,4.333-8.717,5.823-2.055.929-4.383,1.983-4.807,2.8a.356.356,0,0,0-.043.113c.167.553,2.744,1.391,3.982,1.794,2.053.668,3.992,1.3,5.048,2.354,3.032,3.028,7.858,5.511,12.117,7.7,1.236.636,2.4,1.237,3.5,1.836Z'
                  transform='translate(-771.915 -235.323)'/>
          </g>
          <g transform='translate(151.698 59.275)'>
            <path className='b'
                  d='M971.542,492.941c-1.785,0-6.882-.678-8.569-2.943a1.042,1.042,0,0,1,1.672-1.245c.914,1.228,4.877,2.15,6.988,2.1,3.055-.07,7.362-2.21,7.436-5.537a1.043,1.043,0,0,1,1.042-1.02h.023a1.042,1.042,0,0,1,1.019,1.065,7.009,7.009,0,0,1-3.43,5.615,11.811,11.811,0,0,1-6.042,1.96Z'
                  transform='translate(-962.767 -484.299)'/>
          </g>
          <path className='c'
                d='M836.464,200.714c-2.679-1.406-6.454-.712-8.025,1.876-1.258,2.076-1.34,5.106-3.925,6.136-2.245.895-5.563-.206-7.689-.964-12.961-4.615-25.212-8.705-37.814-.041-5.31,3.651-9.52,9.121-10.892,15.417a19.625,19.625,0,0,0,2.409,14.525c1.795,2.8,4.785,9.588,8.342,9.813,2.113.134,4-1.4,5.152-3.18s1.779-3.843,2.831-5.681c2.96-5.17,8.943-2.551,14.076-3.064a79.161,79.161,0,0,0,16.059-2.847c5.387-1.7,9.456-5.113,14.325-7.587C838.658,221.386,846.91,206.2,836.464,200.714Z'
                transform='translate(-656.64 -200)'/>
          <path className='a'
                d='M239.924,819.244C258.8,764.2,324.719,727.375,354.8,720.061c0,0,12.338,23.48,41.032,19.254,32.932-4.85,34.265-23.253,34.265-23.253,40.554,1.485,90.59,29.818,107.739,56.063,0,0,88.866,106.352-131.606,113.545a14.8,14.8,0,0,0-3.425.125C191.7,943.837,239.924,819.244,239.924,819.244Z'
                transform='translate(-235.181 -608.466)'/>
          <g transform='translate(3.701 106.554)'>
            <path className='c'
                  d='M253.973,816.33a1.043,1.043,0,0,1-.986-1.381c10.57-30.815,34.98-53.978,53.6-67.983,20.037-15.075,44.381-27.586,62.018-31.874a1.041,1.041,0,0,1,1.169.528,39.385,39.385,0,0,0,9.551,10.936,37.6,37.6,0,0,0,12.4,6.6,40.663,40.663,0,0,0,18,1.174c9.151-1.348,21.564-4.691,28.732-12.964,4.246-4.9,4.642-9.293,4.645-9.337a1.047,1.047,0,0,1,1.078-.963c19.262.705,41.671,7.407,63.1,18.869,20.382,10.9,36.957,24.631,45.473,37.665a1.043,1.043,0,0,1-1.745,1.141c-8.331-12.75-24.628-26.224-44.711-36.967-20.811-11.132-42.526-17.714-61.268-18.586a21.313,21.313,0,0,1-4.877,9.405,33.774,33.774,0,0,1-10.556,8.019,64.2,64.2,0,0,1-19.566,5.78c-15.194,2.238-25.831-3.257-32.08-8.26a42.189,42.189,0,0,1-9.618-10.823c-13.347,3.408-36.86,13.543-60.5,31.325-18.383,13.83-42.48,36.68-52.878,66.994A1.043,1.043,0,0,1,253.973,816.33Z'
                  transform='translate(-252.93 -711.063)'/>
          </g>
          <g transform='translate(163.358 42.163)'>
            <path className='c'
                  d='M1024.216,409.575a1.308,1.308,0,0,1,.931-2.346c1.858.105,1.651,3.408-.541,2.56A1.6,1.6,0,0,1,1024.216,409.575Z'
                  transform='translate(-1022.65 -406.184)'/>
            <path className='b'
                  d='M1021.321,407.013a2.923,2.923,0,0,1-1.051-.209,2.655,2.655,0,0,1-.642-.355h0a2.35,2.35,0,0,1,1.618-4.219,2.306,2.306,0,0,1,2.072,1.525,2.519,2.519,0,0,1-.5,2.654A2.076,2.076,0,0,1,1021.321,407.013Zm-.438-2.228a.582.582,0,0,0,.139.074.641.641,0,0,0,.337.064.548.548,0,0,0,.011-.425.237.237,0,0,0-.242-.187c-.241-.015-.306.089-.335.168a.255.255,0,0,0,.09.305Z'
                  transform='translate(-1018.689 -402.227)'/>
          </g>
          <g transform='translate(147.082 45.423)'>
            <path className='c'
                  d='M946.151,425.208a1.309,1.309,0,0,1,.931-2.346c1.858.105,1.651,3.408-.541,2.56A1.607,1.607,0,0,1,946.151,425.208Z'
                  transform='translate(-944.585 -421.817)'/>
            <path className='b'
                  d='M943.256,422.647a2.92,2.92,0,0,1-1.051-.209,2.659,2.659,0,0,1-.642-.354h0a2.351,2.351,0,0,1,1.619-4.219,2.306,2.306,0,0,1,2.072,1.525,2.518,2.518,0,0,1-.5,2.653A2.075,2.075,0,0,1,943.256,422.647Zm-.438-2.228a.57.57,0,0,0,.138.074.639.639,0,0,0,.338.065.549.549,0,0,0,.011-.425.237.237,0,0,0-.242-.186c-.24-.014-.306.089-.335.168a.255.255,0,0,0,.09.305Z'
                  transform='translate(-940.625 -417.86)'/>
          </g>
          <path className='a'
                d='M795.437,441.381c-.3-2.431-3.806-3.558-5.979-2.427s-3.245,3.674-3.55,6.1a11.623,11.623,0,0,0,1.5,7.654,7.95,7.95,0,0,0,6.6,3.8,6.079,6.079,0,0,0,5.805-4.534Z'
                transform='translate(-670.998 -388.784)'/>
          <g transform='translate(113.758 48.684)'>
            <path className='b'
                  d='M789.947,453.585a9.051,9.051,0,0,1-7.381-4.281,12.628,12.628,0,0,1-1.652-8.343c.407-3.24,1.9-5.755,4.1-6.9a5.758,5.758,0,0,1,5.183.116,4.142,4.142,0,0,1,2.312,3.108,1.043,1.043,0,0,1-2.069.258,2.13,2.13,0,0,0-1.212-1.52,3.725,3.725,0,0,0-3.252-.112c-2.25,1.17-2.845,4.1-3,5.309a10.55,10.55,0,0,0,1.342,6.965,6.913,6.913,0,0,0,5.705,3.314,5.022,5.022,0,0,0,4.8-3.7,1.042,1.042,0,1,1,2.041.423,7.079,7.079,0,0,1-6.809,5.365Z'
                  transform='translate(-780.797 -433.499)'/>
          </g>
          <path className='a'
                d='M1096.827,380.028c-.54-2.389,2.385-4.567,4.813-4.241s4.3,2.355,5.41,4.538a11.624,11.624,0,0,1,1.189,7.708,7.95,7.95,0,0,1-4.922,5.812,6.36,6.36,0,0,1-5.155-.522Z'
                transform='translate(-917.126 -339.11)'/>
          <g transform='translate(178.592 35.601)'>
            <path className='b'
                  d='M1097.375,391.253a7.38,7.38,0,0,1-3.7-.988,1.042,1.042,0,0,1,1.049-1.8,5.331,5.331,0,0,0,4.293.437,6.909,6.909,0,0,0,4.242-5.053,10.55,10.55,0,0,0-1.1-7.007c-.552-1.084-2.106-3.64-4.62-3.978a3.759,3.759,0,0,0-3.062,1.2,2.051,2.051,0,0,0-.6,1.778,1.042,1.042,0,1,1-2.034.46,4.016,4.016,0,0,1,1.092-3.645,5.8,5.8,0,0,1,4.877-1.859c2.459.33,4.719,2.188,6.2,5.1a12.629,12.629,0,0,1,1.277,8.409,9.03,9.03,0,0,1-5.6,6.57A7.167,7.167,0,0,1,1097.375,391.253Z'
                  transform='translate(-1091.756 -370.753)'/>
          </g>
          <g transform='translate(8.913 133.411)'>
            <path className='b'
                  d='M300.695,850.231a1.038,1.038,0,0,1-.26-.033,104.689,104.689,0,0,1-21.942-8.351,1.042,1.042,0,1,1,.956-1.853,102.6,102.6,0,0,0,21.5,8.185,1.043,1.043,0,0,1-.258,2.053Z'
                  transform='translate(-277.928 -839.877)'/>
          </g>
          <g transform='translate(19.838 115.822)'>
            <path className='b'
                  d='M347.613,775.644a1.038,1.038,0,0,1-.775-.345L330.6,757.253a1.042,1.042,0,1,1,1.55-1.395L348.387,773.9a1.042,1.042,0,0,1-.775,1.74Z'
                  transform='translate(-330.328 -755.513)'/>
          </g>
          <g transform='translate(28.783 105.669)'>
            <path className='b'
                  d='M383.4,723.885a1.043,1.043,0,0,1-.993-.726,36.276,36.276,0,0,0-8.865-14.563,1.042,1.042,0,1,1,1.475-1.474,38.367,38.367,0,0,1,9.377,15.4,1.043,1.043,0,0,1-.993,1.359Z'
                  transform='translate(-373.234 -706.816)'/>
          </g>
          <g transform='translate(275.454 91.195)'>
            <path className='b'
                  d='M1557.379,656.327a1.061,1.061,0,0,1-.161-.012,1.043,1.043,0,0,1-.871-1.19,48.982,48.982,0,0,1,6.147-17.213,1.042,1.042,0,1,1,1.8,1.059,46.877,46.877,0,0,0-5.883,16.473A1.043,1.043,0,0,1,1557.379,656.327Z'
                  transform='translate(-1556.336 -637.398)'/>
          </g>
          <g transform='translate(287.485 98.414)'>
            <path className='b'
                  d='M1615.081,688.543a1.043,1.043,0,0,1-.9-1.568l8.422-14.437a1.042,1.042,0,0,1,1.8,1.051l-8.422,14.437A1.042,1.042,0,0,1,1615.081,688.543Z'
                  transform='translate(-1614.039 -672.02)'/>
          </g>
        </g>
        <path className='d'
              d='M503.317,1081.652c33-3.765,97.3-14.573,126.353-18.956,4.932-.744,9.981-1.527,14.362-3.971,11.893-6.633,7.1-21.595,6.134-32.868-2.812-32.669-8.426-64.981-12.256-97.513-.771-6.548-1.4-17.434-7.126-21.774-4.648-3.52-10.4-3.329-15.82-2.479-16.6,2.6-33.152,4.788-49.8,7.025q-39.312,5.284-78.5,11.478-36.089,5.642-72.113,11.715c-7.6,1.269-19.816,2.116-18.881,9.965,3.385,28.423,7.138,59.473,11.257,87.794,2.007,13.8,3.44,27.957,6.331,41.592,1.8,8.465,4.892,14.6,11.855,16.511'
              transform='translate(-132.196 -556.881)'/>
        <g transform='translate(286.282 390.828)'>
          <path
            d='M514.351,1158.9q.5,2.816.969,5.648c.769,4.687,5.646,6.511,10.3,7.047,10.279,1.183,20.669-.76,30.7-3.109,2.593-.607,5.264-1.281,7.331-2.827,3.807-2.847,2.546-7.916,2-11.824-1.014-7.189-1.542-14.413-2.4-21.615-.677-5.713-2.623-10.921-9.74-11.808a35.037,35.037,0,0,0-7.883.21c-7.38.755-14.82,1.33-22.073,2.628-4.58.82-10.72,1.35-12.777,5.885a9.518,9.518,0,0,0-.368,4.962C511.281,1142.456,512.884,1150.642,514.351,1158.9Z'
            transform='translate(-509.219 -1119.222)'/>
          <path
            d='M528.035,1169.034a56.661,56.661,0,0,1-6.491-.36c-6.515-.75-10.5-3.561-11.211-7.914-.272-1.66-.579-3.45-.967-5.634h0q-.36-2.025-.727-4.045c-1.225-6.787-2.492-13.8-3.224-20.833a10.488,10.488,0,0,1,.455-5.5c2.071-4.566,7.615-5.488,12.07-6.229.5-.084,1-.166,1.473-.251,5.843-1.045,11.882-1.633,17.722-2.2q2.215-.215,4.428-.438a35.875,35.875,0,0,1,8.118-.208,10.85,10.85,0,0,1,8.016,4.539,17.813,17.813,0,0,1,2.631,8.181c.343,2.9.638,5.839.924,8.685.425,4.238.864,8.62,1.469,12.907.055.39.117.791.18,1.2.6,3.862,1.341,8.668-2.583,11.6-2.16,1.615-4.773,2.318-7.717,3.007C544.694,1167.4,536.348,1169.034,528.035,1169.034Zm18.937-51.686a51.377,51.377,0,0,0-5.195.354q-2.217.227-4.438.439c-5.8.564-11.8,1.147-17.557,2.178-.485.087-.986.17-1.5.255-4.172.694-8.9,1.48-10.513,5.034a8.762,8.762,0,0,0-.28,4.424c.723,6.951,1.983,13.929,3.2,20.678q.366,2.024.728,4.052h0c.389,2.193.7,3.992.971,5.661.389,2.371,2.334,5.368,9.392,6.18,10.106,1.162,20.557-.8,30.345-3.089,2.712-.635,5.107-1.273,6.944-2.647,2.921-2.185,2.337-5.963,1.772-9.615-.065-.419-.129-.83-.185-1.229-.611-4.328-1.052-8.732-1.479-12.99-.284-2.837-.579-5.771-.919-8.648-.826-6.969-3.468-10.228-8.834-10.9A19.83,19.83,0,0,0,546.972,1117.348Z'
            transform='translate(-505.261 -1115.262)'/>
        </g>
        <g transform='translate(420.686 372.128)'>
          <path
            d='M1160.917,1061.842c5.134,4.067,12.591,6.007,18.329,6.105,9.036.155,18.88-.9,26.821-5.727,11.469-6.968,11.34-20.789-.274-27.886-6.089-3.721-14.179-4.1-21.053-3.607-11.066.8-32.494,6.534-29.567,22.157A14.943,14.943,0,0,0,1160.917,1061.842Z'
            transform='translate(-1153.859 -1029.515)'/>
          <path
            d='M1176.876,1065.062c-.531,0-1.065,0-1.608-.014-5.171-.089-13.238-1.8-18.958-6.33h0a15.861,15.861,0,0,1-6.12-9.582,14.268,14.268,0,0,1,2.753-11.8c2.718-3.527,7.15-6.4,13.171-8.537a58.082,58.082,0,0,1,14.593-3.057c9.208-.667,16.5.6,21.672,3.757,5.913,3.614,9.3,9.095,9.292,15.038-.008,5.818-3.3,11.151-9.022,14.629C1196.13,1063.129,1187.685,1065.062,1176.876,1065.062Zm-19.271-7.979c5.312,4.208,12.855,5.8,17.7,5.881,11.178.187,19.77-1.633,26.262-5.576,5.089-3.091,8.012-7.775,8.018-12.849.007-5.2-3.016-10.03-8.294-13.256-4.793-2.929-11.668-4.093-20.434-3.456-7.061.511-20.538,3.359-26.262,10.787a12.263,12.263,0,0,0-2.355,10.139,13.841,13.841,0,0,0,5.366,8.332Z'
            transform='translate(-1149.899 -1025.573)'/>
        </g>
        <g transform='translate(308.046 480.784)'>
          <g transform='translate(0 24.577)'>
            <path
              d='M641.423,1670.387a199.3,199.3,0,0,1-25.2,3.715,2.836,2.836,0,0,1-1.492-.112c-.275-.193.149-.708.754-.8,7.662-1.1,15.944-2.44,23.571-3.463a4.782,4.782,0,0,1,2.456.043C641.976,1670.06,642.39,1670.182,641.423,1670.387Z'
              transform='translate(-613.601 -1668.554)'/>
            <path
              d='M611.541,1671.22a2.308,2.308,0,0,1-1.366-.339,1.245,1.245,0,0,1-.492-1.317,1.994,1.994,0,0,1,1.7-1.363c3.05-.438,6.263-.924,9.369-1.394,4.728-.715,9.616-1.454,14.212-2.07a5.05,5.05,0,0,1,3.138.187c.053.032.1.062.154.09a1.325,1.325,0,0,1,.832,1.337c-.13.826-.989,1.007-1.4,1.094a200.079,200.079,0,0,1-25.335,3.735C612.067,1671.2,611.8,1671.22,611.541,1671.22Zm-.285-2.106,26.261-3.775Z'
              transform='translate(-609.646 -1664.593)'/>
          </g>
          <g transform='translate(51.965 16.384)'>
            <path
              d='M890.661,1631.095a199.29,199.29,0,0,1-25.2,3.715,2.835,2.835,0,0,1-1.491-.112c-.275-.193.149-.708.754-.8,7.663-1.1,15.944-2.44,23.571-3.463a4.778,4.778,0,0,1,2.456.043C891.214,1630.768,891.628,1630.89,890.661,1631.095Z'
              transform='translate(-862.839 -1629.263)'/>
            <path
              d='M860.779,1631.928a2.31,2.31,0,0,1-1.366-.339,1.245,1.245,0,0,1-.492-1.317,1.993,1.993,0,0,1,1.7-1.363c3.05-.438,6.263-.924,9.369-1.394,4.727-.715,9.616-1.454,14.212-2.07a5.055,5.055,0,0,1,3.138.187c.053.032.1.062.154.09a1.325,1.325,0,0,1,.833,1.337c-.13.826-.989,1.007-1.4,1.094h0a200.038,200.038,0,0,1-25.335,3.735C861.3,1631.911,861.034,1631.928,860.779,1631.928Zm-.285-2.106'
              transform='translate(-858.884 -1625.302)'/>
          </g>
          <g transform='translate(103.93 8.192)'>
            <path
              d='M1139.9,1591.8a199.293,199.293,0,0,1-25.2,3.715,2.836,2.836,0,0,1-1.492-.112c-.275-.193.149-.708.754-.8,7.663-1.1,15.944-2.441,23.571-3.463a4.776,4.776,0,0,1,2.456.043C1140.452,1591.476,1140.866,1591.6,1139.9,1591.8Z'
              transform='translate(-1112.077 -1589.971)'/>
            <path
              d='M1110.016,1592.636a2.312,2.312,0,0,1-1.367-.339,1.245,1.245,0,0,1-.492-1.317,1.994,1.994,0,0,1,1.7-1.363c3.052-.439,6.266-.925,9.375-1.395,4.725-.715,9.612-1.454,14.206-2.07a5.054,5.054,0,0,1,3.138.187c.053.032.1.062.153.09a1.325,1.325,0,0,1,.833,1.337c-.13.826-.989,1.007-1.4,1.094h0a200.127,200.127,0,0,1-25.335,3.734C1110.542,1592.619,1110.272,1592.636,1110.016,1592.636Zm-.286-2.106'
              transform='translate(-1108.121 -1586.01)'/>
          </g>
          <g transform='translate(155.895)'>
            <path
              d='M1389.136,1552.511a199.279,199.279,0,0,1-25.2,3.715,2.836,2.836,0,0,1-1.492-.112c-.275-.193.149-.708.754-.8,7.663-1.1,15.944-2.441,23.571-3.463a4.78,4.78,0,0,1,2.456.043C1389.689,1552.184,1390.1,1552.306,1389.136,1552.511Z'
              transform='translate(-1361.315 -1550.678)'/>
            <path
              d='M1359.254,1553.344a2.308,2.308,0,0,1-1.366-.339,1.245,1.245,0,0,1-.492-1.317,2,2,0,0,1,1.7-1.363c3.055-.439,6.272-.925,9.383-1.4,4.723-.714,9.606-1.453,14.2-2.068a5.051,5.051,0,0,1,3.138.187c.053.032.105.062.153.09a1.325,1.325,0,0,1,.833,1.337c-.13.826-.988,1.007-1.4,1.094h0a200.178,200.178,0,0,1-25.335,3.735C1359.781,1553.327,1359.51,1553.344,1359.254,1553.344Zm-.285-2.106,26.213-2.689h0Zm.048-1.087Z'
              transform='translate(-1357.36 -1546.717)'/>
          </g>
        </g>
        <g transform='translate(303.399 472.652)'>
          <path
            d='M588.4,1525.034a1.043,1.043,0,0,1-.151-2.074l102.767-15.232a1.042,1.042,0,1,1,.306,2.062l-102.767,15.232A1.062,1.062,0,0,1,588.4,1525.034Z'
            transform='translate(-587.361 -1507.717)'/>
        </g>
        <g transform='translate(305.958 405.874)'>
          <path className='a'
                d='M618.785,1192.43c-3.142.322-12.034-.564-13.994,1.965-.937,1.209,2.3,16.478,2.432,16.462l14-1.713A40.525,40.525,0,0,0,618.785,1192.43Z'
                transform='translate(-603.58 -1191.388)'/>
          <path className='a'
                d='M603.276,1207.943a1.059,1.059,0,0,1-.916-.534c-.3-.524-1.377-6.112-1.756-8.247-1.463-8.231-.935-8.911-.586-9.362,1.627-2.1,6.122-2.275,11.391-2.281,1.368,0,2.55,0,3.32-.082a1.044,1.044,0,0,1,1.084.674,41.733,41.733,0,0,1,2.5,17.146,1.043,1.043,0,0,1-.914.966l-14,1.713A1.007,1.007,0,0,1,603.276,1207.943Zm-1.535-16.954c-.053.331-.144,1.879.942,7.957.51,2.854,1.07,5.526,1.365,6.81l12.233-1.5a39.679,39.679,0,0,0-2.16-14.692c-.763.034-1.686.035-2.71.036C608.257,1189.607,603.021,1189.614,601.741,1190.988Zm.015-.07'
                transform='translate(-599.631 -1187.431)'/>
        </g>
        <g transform='translate(228.599 362.616)'>
          <path className='a'
                d='M273.625,1234.472c-1.976,19.511,13.185,25.458,24.388,26.464a94.988,94.988,0,0,0,42.2-5.667,125.389,125.389,0,0,0,19.511-9.823c4.2-2.5,8.5-4.814,12.756-7.187a9.3,9.3,0,0,1,4.332-1.378,4.393,4.393,0,0,1,3.9,2,4.453,4.453,0,0,1,.167,3.623c-1.211,3.448-5.185,5.093-8.156,6.81-3.645,2.107-9.23,5.644-12.88,7.742,2.889-1.426,7.633-4.428,10.572-5.748,2.52-1.132,4.816-2.8,7.527-3.477,2.688-.673,4.59,1.63,4.1,4.3-.587,3.2-3.373,4.683-5.776,6.452l-12.873,9.478a128.377,128.377,0,0,1,11.206-7.041c2.2-1.144,9.667-3.453,8.762.46-.764,3.3-6.19,8.084-9.713,10.221-32.181,19.524-89.488,32.052-122.753,5.469-19.953-15.945-20.658-41.982-12.252-66.218Z'
                transform='translate(-232.53 -1162.789)'/>
          <path className='a'
                d='M1187.725,1020.8c7.693,18.039-5.012,28.229-15.409,32.522a94.979,94.979,0,0,1-41.979,7.149,125.38,125.38,0,0,1-21.55-3.571c-4.75-1.136-9.545-2.067-14.317-3.065a9.305,9.305,0,0,0-4.546-.026,4.395,4.395,0,0,0-3.125,3.066,4.452,4.452,0,0,0,.919,3.508c2.182,2.931,6.466,3.319,9.813,4.075,4.107.927,10.492,2.641,14.6,3.558-3.182-.5-8.6-1.956-11.8-2.341-2.742-.331-5.43-1.238-8.221-1.08-2.766.157-3.9,2.922-2.635,5.326,1.512,2.879,4.614,3.468,7.434,4.441l15.11,5.217a128.532,128.532,0,0,0-12.794-3.387c-2.442-.437-10.257-.42-8.229,3.047,1.712,2.925,8.315,5.875,12.315,6.868,36.533,9.063,94.971,3.97,118.819-31.307,15-22.192,6.488-48.682-10.294-69.85Z'
                transform='translate(-907.809 -983.911)'/>
          <g transform='translate(0 47.119)'>
            <path className='c'
                  d='M296.246,1289.45c-24.94,0-40.723-8.069-49.93-15.426-9.593-7.666-15.361-17.985-17.142-30.669-1.541-10.975.034-23.667,4.556-36.7a1.043,1.043,0,0,1,1.97.683c-4.414,12.726-5.957,25.082-4.462,35.731,1.705,12.14,7.216,22.008,16.379,29.331,10.367,8.285,29.371,17.5,61.028,14.332,20.817-2.081,43.446-9.512,60.533-19.878,3.413-2.07,8.578-6.711,9.238-9.565.081-.352.062-.578-.057-.67-.947-.733-5.188.319-7.209,1.37a95.872,95.872,0,0,0-8.934,5.532c-.73.49-1.457.977-2.179,1.454a1.042,1.042,0,0,1-1.193-1.709l12.873-9.478c.358-.263.726-.522,1.081-.772,1.986-1.4,3.862-2.714,4.287-5.029a2.863,2.863,0,0,0-.725-2.637,2.158,2.158,0,0,0-2.1-.464,20.5,20.5,0,0,0-4.686,2.059c-.859.464-1.747.944-2.667,1.357-1.592.715-3.77,1.962-5.876,3.167-1.725.987-3.355,1.92-4.662,2.565a1.042,1.042,0,0,1-.981-1.839c1.858-1.068,4.264-2.538,6.59-3.958,2.212-1.352,4.5-2.749,6.288-3.782.48-.277.969-.544,1.488-.827,2.615-1.426,5.318-2.9,6.206-5.426a3.435,3.435,0,0,0-.091-2.764,3.383,3.383,0,0,0-2.976-1.468,8.183,8.183,0,0,0-3.837,1.246q-1.606.894-3.217,1.782c-3.139,1.734-6.385,3.528-9.513,5.39a127.807,127.807,0,0,1-19.677,9.9,95.7,95.7,0,0,1-42.665,5.729c-5.182-.465-14.755-2.31-20.646-9.445-3.857-4.672-5.434-10.783-4.687-18.161a1.042,1.042,0,1,1,2.074.21c-.689,6.8.731,12.4,4.22,16.624,5.409,6.551,14.366,8.26,19.224,8.7a93.626,93.626,0,0,0,41.744-5.6,125.885,125.885,0,0,0,19.346-9.743c3.157-1.879,6.418-3.681,9.571-5.423q1.607-.888,3.211-1.779a10.431,10.431,0,0,1,4.827-1.51h.073a5.025,5.025,0,0,1,4.985,7.009,8.7,8.7,0,0,1-2.826,3.827,4.109,4.109,0,0,1,2.681,1.11,4.93,4.93,0,0,1,1.349,4.534c-.579,3.153-3,4.856-5.139,6.358l-.076.054c2.057-.543,4.417-.811,5.715.2a2.551,2.551,0,0,1,.811,2.788c-.861,3.727-6.588,8.694-10.188,10.878-17.34,10.52-40.3,18.06-61.407,20.17C304.414,1289.246,300.212,1289.45,296.246,1289.45Z'
                  transform='translate(-228.599 -1205.949)'/>
          </g>
          <g transform='translate(177.873 0)'>
            <path className='c'
                  d='M1133.462,1087.107a144.867,144.867,0,0,1-34.361-3.942c-4.085-1.013-11.031-4.051-12.963-7.353a2.551,2.551,0,0,1-.055-2.9c.939-1.347,3.272-1.794,5.4-1.887l-.088-.029c-2.488-.8-5.308-1.7-6.8-4.541a4.929,4.929,0,0,1-.061-4.73,4.107,4.107,0,0,1,2.229-1.858,8.7,8.7,0,0,1-3.836-2.812,5.031,5.031,0,0,1,2.744-8.2,10.434,10.434,0,0,1,5.058,0q1.794.375,3.59.742c3.53.725,7.181,1.475,10.756,2.33a125.911,125.911,0,0,0,21.369,3.545,93.647,93.647,0,0,0,41.52-7.072c4.508-1.862,12.551-6.159,15.765-14.023,2.074-5.073,1.765-10.836-.918-17.127a1.042,1.042,0,0,1,1.918-.818c2.909,6.823,3.222,13.126.93,18.734-3.5,8.565-12.091,13.175-16.9,15.161a95.7,95.7,0,0,1-42.438,7.226,127.809,127.809,0,0,1-21.732-3.6c-3.542-.847-7.176-1.594-10.691-2.316q-1.8-.37-3.6-.743a8.182,8.182,0,0,0-4.034-.048,3.381,3.381,0,0,0-2.4,2.287,3.436,3.436,0,0,0,.736,2.666c1.6,2.147,4.619,2.75,7.54,3.333.579.116,1.126.225,1.666.347,2.016.455,4.617,1.109,7.133,1.741,2.642.664,5.375,1.35,7.466,1.817a1.042,1.042,0,0,1-.389,2.047c-1.439-.227-3.272-.632-5.213-1.061-2.369-.524-4.82-1.066-6.553-1.275-1-.121-1.992-.315-2.95-.5a20.447,20.447,0,0,0-5.086-.572,2.156,2.156,0,0,0-1.864,1.067,2.862,2.862,0,0,0,.093,2.733c1.094,2.084,3.278,2.784,5.589,3.526.414.133.842.27,1.261.415l15.11,5.218a1.042,1.042,0,0,1-.63,1.987c-.83-.24-1.667-.489-2.508-.738a95.969,95.969,0,0,0-10.18-2.624c-2.242-.4-6.6-.145-7.29.837-.087.124-.038.345.144.657,1.48,2.529,7.792,5.422,11.666,6.382,19.4,4.812,43.212,5.173,63.705.965,31.165-6.4,46.567-20.849,54-31.843,6.875-10.17,9.1-21.786,6.613-34.526-2.173-11.133-8-22.922-16.86-34.093a1.043,1.043,0,0,1,1.634-1.3c9.063,11.431,15.035,23.53,17.273,34.989,2.6,13.3.265,25.447-6.932,36.092-7.65,11.316-23.455,26.178-55.307,32.719A152.069,152.069,0,0,1,1133.462,1087.107Z'
                  transform='translate(-1081.725 -979.953)'/>
          </g>
          <g transform='translate(6.483 46.874)'>
            <path className='c'
                  d='M292.458,1221.47a1.039,1.039,0,0,1-.742-.311c-7.986-8.1-20.739-13.984-31.012-14.3a1.043,1.043,0,0,1,.064-2.084c10.765.332,24.1,6.467,32.433,14.921a1.043,1.043,0,0,1-.742,1.774Z'
                  transform='translate(-259.693 -1204.775)'/>
          </g>
          <g transform='translate(278.487 0)'>
            <path className='c'
                  d='M1565.342,1012.541a1.044,1.044,0,0,1-1.005-1.321c1.6-5.774,4.916-11.654,9.861-17.476a81.876,81.876,0,0,1,15.039-13.6,1.043,1.043,0,1,1,1.2,1.7,79.762,79.762,0,0,0-14.652,13.247c-4.747,5.59-7.923,11.2-9.441,16.682A1.043,1.043,0,0,1,1565.342,1012.541Z'
                  transform='translate(-1564.299 -979.953)'/>
          </g>
        </g>
      </g>
    </svg>

  )
};

export default NoPaymentMethod;
