/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

export default {
  types: [
    {
      name: 'Account Summary',
      summary: 'A breakdown of gross sales and total collected by account.',
      type: ['Sales'],
      export: ['html', 'csv']
    },
    {
      name: 'Batch Detail',
      summary: 'The sum of all your authorized credit card transactions for the day at close of business, or by the designated batch closing time.',
      type: ['Sales'],
      export: ['html', 'csv']
    },
    {
      name: 'Batch Summary',
      summary: 'An overview of your batches with totals.',
      type: ['Sales'],
      export: ['html', 'csv']
    },
    {
      name: 'Brand Summary',
      summary: 'View sales totals for all transactions by brand.',
      type: ['Sales'],
      export: ['csv']
    },
    {
      name: 'Card Brand Summary',
      summary: 'View sales totals for credit transactions by card brand.',
      type: ['Sales'],
      export: ['csv']
    },
    {
      name: 'Customer Experience',
      summary: 'A summary of customer feedback by name, date, invoice number, rating, and employee.',
      type: ['Customer'],
      export: ['csv']
    },
    {
      name: 'Customer Export',
      summary: 'Export a listing of your customers and their contact information.',
      type: ['Customer'],
      export: ['csv']
    },
    {
      name: 'Customer Performance',
      summary: 'Track top customers and see their spending habits.',
      type: ['Customer'],
      export: ['csv']
    },
    {
      name: 'Declined Transactions',
      summary: 'Export a listing of all declined transactions in a period.',
      type: ['Sales'],
      export: ['csv']
    },
    {
      name: 'Deposit Detail',
      summary: 'The transactions and related fees within each deposit and withdrawal posted to your bank account.',
      type: ['Sales'],
      export: ['csv']
    },
    {
      name: 'Deposit Summary',
      summary: 'An overview of your deposits with net totals',
      type: ['Sales'],
      export: ['csv']
    },
    {
      name: 'Disputes Report',
      summary: 'View the status of your disputes.',
      type: ['Sales'],
      export: ['html', 'csv']
    },
    {
      name: 'Employee Flash Report',
      summary: 'Totals for net sales, discounts, tax, tips, voids, refunds, and payment methods by single employee.',
      type: ['Employee'],
      export: ['csv']
    },
    {
      name: 'Employee Performance',
      summary: 'Track employees by number of transactions and total collected.',
      type: ['Employee'],
      export: ['csv']
    },
    {
      name: 'Expanded Transaction Report',
      summary: 'Export an expanded listing of all transactions in a period. Includes deposit dates, deposit amounts, and applicable fees.',
      type: ['Sales'],
      export: ['csv']
    },
    {
      name: 'Flash Report',
      summary: 'Totals for net sales, discounts, tax, tips, voids, refunds, and payment methods. Ideal for closing your day.',
      type: ['Sales'],
      export: ['csv']
    },
    {
      name: 'Fraud Monitoring',
      summary: 'The TC40 report from Visa and SAFE report from MasterCard with information about customer fraud claims.',
      type: ['Sales'],
      export: ['csv']
    },
    {
      name: 'Gift Card Summary',
      summary: 'An overview of gift cards activated/sold, redeemed, and refunds to gift cards. Details about payments accepted with gift cards are located in your Flash or Transactions reports.',
      type: ['Sales'],
      export: ['html', 'csv']
    },
    {
      name: 'Inventory Export',
      summary: 'Export your inventory with stock counts.',
      type: ['Inventory'],
      export: ['csv']
    },
    {
      name: 'Item Performance',
      summary: 'Track items by number sold, refunded, voided, and total collected.',
      type: ['Inventory'],
      export: ['csv']
    },
    {
      name: 'Loyalty Rewards Report',
      summary: 'View a listing of your customers who have earned rewards.',
      type: ['Customer'],
      export: ['csv']
    },
    {
      name: 'Modifier Performance',
      summary: 'Track modifiers by modifier set and individual modifier used.',
      type: ['Inventory'],
      export: ['csv']
    },
    {
      name: 'Payment Type Summary',
      summary: 'View sales totals by type of transaction.',
      type: ['Sales'],
      export: ['csv']
    },
    {
      name: 'Product Sales',
      summary: 'Track category performance and items within category by items sold and total sales.',
      type: ['Inventory'],
      export: ['csv']
    },
    {
      name: 'Refund Summary',
      summary: 'View refunded transactions in a period.',
      type: ['Sales'],
      export: ['html', 'csv']
    },
    {
      name: 'Tax',
      summary: 'View tax collected.',
      type: ['Sales'],
      export: ['csv']
    },
    {
      name: 'Transactions',
      summary: 'Export a listing of all transactions in a period.',
      type: ['Sales'],
      export: ['csv']
    },
    {
      name: 'Transactions by Category',
      summary: 'View transactions by category.',
      type: ['Sales'],
      export: ['csv']
    },
    {
      name: 'Transactions by Item',
      summary: 'View transactions by item.',
      type: ['Sales'],
      export: ['csv']
    },
    {
      name: 'Custom Reports',
      summary: 'View the list of custom reports created.',
      type: ['Custom'],
      export: ['csv']
    },
  ]
};

