/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { setSubmitButtonEnabled } from '../../actions/userExperienceActions';
import { reSendInviteEmail } from '../../actions/employeesActions';
import {Box, MenuItem} from '@mui/material';
import { connect } from 'react-redux';
import Validator from '../util/Validator';
import LabelUtil from '../util/LabelUtil';
import TextUtil from '../util/FormatTextUtil';
import IconUtil from '../util/IconUtil';
import moment from 'moment';
import _ from 'lodash';
import { Field, reduxForm } from 'redux-form';
import AlertErrorIcon from '@mui/icons-material/Error';
import Loading from '../Loading';
import FormUtil from './../util/FormUtil';
import messages from '../../constants/messages';
import UserUtil from '../util/UserUtil';
import Select from '../shared/Select';
import Button from '../shared/Button';
import CustomTextField from '../shared/TextField';
import LastLoginActivity from '../shared/LastLoginActivity';
import MapDetail from '../MapDetail';
import CustomToggle from '../shared/Toggle';
import {
  employeeDetailStyles,
  roleWrapperStyles
} from '../../jss/employeeDetailStyles';

const validate = values => {
  if (!_.isEmpty(values)) {
    return Validator.validateEmployeeDetailForm(values);
  } else {
    return {};
  }

};

export class EmployeeDetail extends Component {

  constructor(props) {
    super(props);

    this.resendInvite = this.resendInvite.bind(this);
    this.rateLimitUnlockToggleChange = this.rateLimitUnlockToggleChange.bind(this);
    this.state = {
      enableFormSubmitButton: props?.userExperience?.enableFormSubmitButton,
      rateLimitUnlockToggleValue: false
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const enableSubmit = FormUtil.shouldEnableSubmit(nextProps);

    if (prevState.enableFormSubmitButton !== enableSubmit) {
      return { enableFormSubmitButton: enableSubmit};
    }

    return null;
 }

 componentDidUpdate(prevProps) {
    if (prevProps?.userExperience?.enableFormSubmitButton !== this.state.enableFormSubmitButton) {
      this.props.dispatch(setSubmitButtonEnabled(this.state.enableFormSubmitButton));
   }
 }

  resendInvite() {
    const label = LabelUtil.getLabel();
    this.props.dispatch(reSendInviteEmail(this.props.user, this.props.employeeId, label.abbreviation));
  }

  rateLimitUnlockToggleChange() {
    this.setState({rateLimitUnlockToggleValue: true});
    this.props.unlockRateLimitForEmployee();
  }

  render() {

    const { handleSubmit, isNew, isSending, isLocked, isLockedDueToRateLimit, isReadOnly, isArchived, auth, user, t } = this.props;

    const readOnly = !UserUtil.isActive(user) || auth.needsInfo;
    const isClosedAccount = !UserUtil.isActive(user);
    const isMBP = UserUtil.userType(user) === 'MBP';
    const labelColor = LabelUtil.getLabelColor();
    const shouldShowMap = this.props.latitude && this.props.longitude;

    const shouldShowRateLimitUnlock = isLockedDueToRateLimit && UserUtil.isUserCSWrite(user) && !isArchived;

    const reSendInviteEmailButton = isLocked && !isSending && !isNew && !isReadOnly && !isArchived ? (
      <div className='resendInviteContainer'>
        <div className='resendIconContainer'>
          <AlertErrorIcon/>
        </div>
        <div className='resendMessageContainer'>
          <div className='detailsInfoHeader'>{t('EmployeeDetailResendInvoice.Header')}</div>
          <div
          className='detailDescriptionText'>{t('EmployeeDetailResendInvoice.Description')}</div>
          {!readOnly ?
            <Button
              variant='text'
              className='resendLink'
              onClick={this.resendInvite}
              label={t('EmployeeDetailResendInvoice.Resend')}
            /> :
          null}
        </div>
      </div>) : null;

    const loadingContainer = isSending ? (<Loading/>) : null;

    const map = shouldShowMap ? (
      <div className='lastLoginLocation'>
        <div className='detailsHeader'>{t('EmployeeDetailLastLogin')}</div>
        <MapDetail latitude={this.props.latitude} longitude={this.props.longitude}/>
        <br/>
      </div>) : null

    const newEmployeeNote = isNew ? (
      <div className='newEmployeeNote'>
        <div className='detailsHeader'>{t('Note')}</div>
        <div className='detailsInfoHeader'>{t('EmployeeDetailNewEmployee.InfoHeader')}</div>
        <div className='detailDescriptionText'>
          {t('EmployeeDetailNewEmployee.Description')}
        </div>
        <br/>
        <br/>
      </div>) : null;

    const roleSelect = (isMBP) ?
      <div className='roleSelector'>
        <Field
          name='role'
          component={Select}
          disabled={isLocked || isReadOnly || isClosedAccount}
          label={t('EmployeeDetailForm.Role')}
          className='roleSelectWrap'
        >
          <MenuItem
            disabled
            value='owner'>
            <Box className='roleWrapper' sx={roleWrapperStyles}>
              <span>{IconUtil.getIcon('EmployeeIcon', labelColor)}</span>
              <div className='roleTitle'>{t('EmployeeDetailForm.Roles.Owner')}</div>
            </Box>
          </MenuItem>
          <MenuItem
            disabled={auth.isManager || auth.isAdmin}
            value='admin'>
            <Box className='roleWrapper' sx={roleWrapperStyles}>
              <span>{IconUtil.getIcon('EmployeeIcon', labelColor)}</span>
              <div className='roleTitle'>{t('EmployeeDetailForm.Roles.Admin')}</div>
            </Box>
          </MenuItem>
          <MenuItem
            disabled={auth.isManager}
            value='manager'>
            <Box className='roleWrapper' sx={roleWrapperStyles}>
              <span>{IconUtil.getIcon('EmployeeIcon', '#35C2FD')}</span>
              <div className='roleTitle'>{t('EmployeeDetailForm.Roles.Manager')}</div>
            </Box>
          </MenuItem>
          <MenuItem
            value='report'>
            <Box className='roleWrapper' sx={roleWrapperStyles}>
              <span>{IconUtil.getIcon('EmployeeIcon', '#E762FF')}</span>
              <div className='roleTitle'>{t('EmployeeDetailForm.Roles.Reporter')}</div>
            </Box>
          </MenuItem>
        </Field>
      </div>
      :
      <div className='roleSelector'>
        <Field
          name='role'
          component={Select}
          label={t('EmployeeDetailForm.Role')}
          disabled={isLocked || isReadOnly || isClosedAccount}>
          <MenuItem
            disabled
            value='owner'>
            <Box className='roleWrapper' sx={roleWrapperStyles}>
              <span>{IconUtil.getIcon('EmployeeIcon', labelColor)}</span>
              <div className='roleTitle'>{t('EmployeeDetailForm.Roles.Owner')}</div>
            </Box>
          </MenuItem>
          <MenuItem
            disabled={auth.isManager || auth.isAdmin}
            value='admin'>
            <Box className='roleWrapper' sx={roleWrapperStyles}>
              <span>{IconUtil.getIcon('EmployeeIcon', labelColor)}</span>
              <div className='roleTitle'>{t('EmployeeDetailForm.Roles.Admin')}</div>
            </Box>
          </MenuItem>
          <MenuItem
            disabled={auth.isManager}
            value='manager'>
            <Box className='roleWrapper' sx={roleWrapperStyles}>
              <span>{IconUtil.getIcon('EmployeeIcon', '#35C2FD')}</span>
              <div className='roleTitle'>{t('EmployeeDetailForm.Roles.Manager')}</div>
            </Box>
          </MenuItem>
          <MenuItem
            value='cashier'>
            <Box className='roleWrapper' sx={roleWrapperStyles}>
              <span>{IconUtil.getIcon('EmployeeIcon', '#67E418')}</span>
              <div className='roleTitle'>{t('EmployeeDetailForm.Roles.Cashier')}</div>
            </Box>
          </MenuItem>
          <MenuItem
            value='report'>
            <Box className='roleWrapper' sx={roleWrapperStyles}>
              <span>{IconUtil.getIcon('EmployeeIcon', '#E762FF')}</span>
              <div className='roleTitle'>{t('EmployeeDetailForm.Roles.Reporter')}</div>
            </Box>
          </MenuItem>
        </Field>
      </div>;

    return (
      <Box sx={employeeDetailStyles}>
        <form onSubmit={handleSubmit}>
          <div className='employeeDetail detailsContainer'>
            {reSendInviteEmailButton}
            {loadingContainer}
            {
              shouldShowRateLimitUnlock &&
              <div className='unlockRateLimit'>
                <CustomToggle
                  customClass={'employeeRateLimitUnlockToggle'}
                  labelPosition={'start'}
                  label={t('EmployeeUnlockRateLimit')}
                  input={{
                    value: this.state.rateLimitUnlockToggleValue,
                    onChange: this.rateLimitUnlockToggleChange
                  }}
                />
              </div>
            }
            <div className='employeeFirstName'>
              <Field
                className='textField'
                label={t('EmployeeDetailForm.FirstName')}
                disabled={(isLocked || isReadOnly || isClosedAccount) && !isNew}
                component={CustomTextField}
                maxLength='50'
                name='first_name'
              />
            </div>
            <div className='employeeLastName'>
              <Field
                className='textField'
                label={t('EmployeeDetailForm.LastName')}
                disabled={(isLocked || isReadOnly || isClosedAccount) && !isNew}
                component={CustomTextField}
                maxLength='50'
                name='last_name'
              />
            </div>
            <div>
              <Field
                className='textField'
                label={t('EmailAddress')}
                disabled={(isReadOnly || isClosedAccount) && !isNew}
                component={CustomTextField}
                maxLength='50'
                name='email_address'
              />
            </div>
            <div className='employeePhoneNumber'>
              <Field
                component={CustomTextField}
                description={t('MobileNumberDescription')}
                disabled={(isLocked || isReadOnly || isClosedAccount)}
                hintText={t('MobileNumberHint')}
                label={t('MobileNumberLabel')}
                name='phone_number'
                normalize={TextUtil.formatPhoneNumber}
              />
            </div>

            {roleSelect}

            <div className='detailDescription'>
              <div className='detailsHeader'>{t('EmployeeDetailDetail')}</div>
              <div
                className='detailsInfoHeader roleAccess'>{this.props.t(this.props.accountDetailAccessText)}</div>
              <div className='detailDescriptionText roleDescription'>
                {this.props.t(this.props.accountDetailText)}
              </div>
              {newEmployeeNote}
              <div className='detailDescriptionText'>
                {this.props.addedDateBy}
                <br/>
                {this.props.lastAppLogin}
                <br/>
                {this.props.lastUpdate}
                <br/>
                <span className='isActiveAccount'>{this.props.accountStatus}</span>
                <br/>
                <LastLoginActivity lastLogin={this.props.lastLogin} t={t} />
                <br/>
                {map}
              </div>
            </div>

          </div>
        </form>
      </Box>
    );
  }
}

export const EmployeeDetailContainer = reduxForm({
  form: 'employeeDetailForm',
  validate,
  fields: [
    'id',
    'pid',
    'first_name',
    'last_name',
    'phone_number',
    'email_address',
    'role',
    'last_login',
    'last_app_login',
    'is_locked',
    'updated_date',
    'added_date',
    'added_by',
    'addedDateBy',
    'lastLogin',
    'lastAppLogin',
    'accountLocked',
  ],
  enableReinitialize: true,
})(EmployeeDetail);

function mapStateToProps(state, ownProps) {
  const t = ownProps.t;

  const selectedMerchantAccount = state.user.data && state.user.data.merchantAccounts && state.user.data.merchantAccounts[0] ?
    _.find(state.user.data.merchantAccounts, {mea_id: Number(state.user.selectedMerchantAccount)}) : null;
  const {selectedEmployee} = state.employees;

  const currentUserRole = selectedMerchantAccount ? selectedMerchantAccount.role : undefined;
  const currentUserId = state.user.data.user_id;

  let initialValues = selectedEmployee ? selectedEmployee : {};
  const isLocked = selectedEmployee?.is_locked && !selectedEmployee?.is_rate_limit_locked;
  const isLockedDueToRateLimit = selectedEmployee?.is_rate_limit_locked;
  const accountStatus = isLocked ? t('EmployeeDetailAccountLocked') : t('EmployeeDetailAccountActive');
  const isReadOnly = ownProps.isEmployeeReadOnly(selectedEmployee, currentUserRole, currentUserId);

  const addedBy = selectedEmployee && selectedEmployee.added_by ? t('EmployeeDetailAddedBy', { name: selectedEmployee.added_by }) : '';
  const addedDateBy = selectedEmployee && selectedEmployee.added_date ? `${t('EmployeeDetailAddedDateBy', { date: moment(selectedEmployee.added_date).local().format('MMM  DD, YYYY') })} ${addedBy}` : null;
  const lastLogin = selectedEmployee && selectedEmployee.last_login;
  const lastAppLogin = selectedEmployee && selectedEmployee.last_app_login ? t('EmployeeDetailLastAppLogin', { date: moment(selectedEmployee.last_app_login).local().format('MM/DD/YYYY'), time: moment(selectedEmployee.last_app_login).local().format(' h:mm a') }) : null;
  const lastUpdate = selectedEmployee && selectedEmployee.updated_date ? t('EmployeeDetailLastUpdate', {date: moment(selectedEmployee.updated_date).local().format('MM/DD/YYYY'), time: moment(selectedEmployee.updated_date).local().format(' h:mm a') }) : null;
  initialValues.phone_number = TextUtil.formatPhoneNumber(initialValues.phone_number);
  initialValues.role = selectedEmployee && selectedEmployee.role ? selectedEmployee.role : 'report';
  let accountDetailText = '', accountDetailAccessText = '';

  const employeeRole = state.form?.employeeDetailForm && state.form?.employeeDetailForm.values && state.form?.employeeDetailForm.values.role ?
    state.form?.employeeDetailForm.values.role
    : selectedEmployee ? selectedEmployee.role : null;

  /* istanbul ignore else */
  if (employeeRole) {
    accountDetailAccessText = messages.employee[employeeRole]?.access;
    accountDetailText = UserUtil.userType(state.user) === 'MBP'
      ? messages.employeeMBP[employeeRole]?.description :
        messages.employee[employeeRole]?.description;
  }

  const longitude = selectedEmployee?.last_login_longitude || ownProps.location?.longitude;
  const latitude =  selectedEmployee?.last_login_latitude || ownProps.location?.latitude

  return {
    initialValues: selectedEmployee,
    lastUpdate: lastUpdate,
    addedDateBy: addedDateBy,
    lastLogin: lastLogin,
    lastAppLogin: lastAppLogin,
    accountStatus: accountStatus,
    accountDetailText: accountDetailText,
    accountDetailAccessText: accountDetailAccessText,
    isLocked: isLocked,
    isLockedDueToRateLimit,
    isReadOnly: isReadOnly,
    auth: state.auth,
    user: state.user,
    employeeId: initialValues.id,
    isSending: state.employees.isSending ? state.employees.isSending : false,
    longitude: longitude,
    latitude: latitude
  }
}

export default connect(mapStateToProps)(EmployeeDetailContainer);
