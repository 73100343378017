
const styles = (theme) => ({
  'payNowContainer': {
    '& .loadingIndicator': {
      '& > div:first-of-type': {
        backgroundColor: `${theme.palette.common.genericColor} !important`,
      },
    },
    '& .cardReaderStatusWell .cardReaderStatusAction a': {
      color: `${theme.palette.common.genericColor} !important`,
    },
  },
  'paymentLanding': {
    '& ._elevio_launcher button': {
      display: 'none',
    },
    '& .grecaptcha-badge': {
      display: 'none',
    },
    '& .updateSpinner': {
      '& .spinner': {
        color: `${theme.palette.common.genericColor} !important`,
      },
    },
  },
  '.leadSignup': {
    '& ._elevio_launcher button': {
      display: 'none',
    }
  }
});

export default styles;
