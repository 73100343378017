/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, {Component} from 'react';

export default class DiscoverCardPaymentMethod extends Component {

  render() {
    return (
      <svg id='DiscoverCardPaymentMethod' xmlns='http://www.w3.org/2000/svg' width='100' height='60' viewBox='0 0 100 60' className='discoverPaymentMethod'>
        <defs>
          <clipPath id='clipPath-disc'>
            <path style={{fill: 'none'}} id='Path_12678' data-name='Path 12678' d='M24.115,0H94.371A3.952,3.952,0,0,0,98.28-3.995V-26.3S72.087-8.132,24.115,0Z' transform='translate(0)' clipRule='evenodd'/>
          </clipPath>
          <clipPath id='clipPath-disc-2'>
            <path id='Path_12677' data-name='Path 12677' d='M-610,1126H367.338V-324H-610Z' transform='translate(610 324)' style={{fill: 'none'}}/>
          </clipPath>
          <clipPath id='clipPath-disc-3'>
            <rect id='Rectangle_3257' data-name='Rectangle 3257' width='85.028' height='37' style={{fill: 'none'}}/>
          </clipPath>
          <clipPath id='clipPath-disc-4'>
            <path id='Path_12676' data-name='Path 12676' d='M24,0H98.278V-27H24Z' transform='translate(-24 27)' style={{fill: 'none'}}/>
          </clipPath>
          <clipPath id='clipPath-disc-5'>
            <path id='Path_12681' data-name='Path 12681' d='M18.289-30.178a4.628,4.628,0,0,1-3.235.923H14.42v-7.66h.634a4.534,4.534,0,0,1,3.235.936,3.826,3.826,0,0,1,1.3,2.882,3.893,3.893,0,0,1-1.3,2.92Zm-2.761-8.7h-3.47v11.585h3.448a6.5,6.5,0,0,0,4.321-1.34,5.721,5.721,0,0,0,2.2-4.45c0-3.42-2.67-5.8-6.5-5.8Z' style={{fill: 'none'}} clipRule='evenodd'/>
          </clipPath>
          <clipPath id='clipPath-disc-6'>
            <path id='Path_12680' data-name='Path 12680' d='M-610,1126H390V-324H-610Z' style={{fill: 'none'}}/>
          </clipPath>
          <clipPath id='clipPath-disc-7'>
            <path id='Path_12683' data-name='Path 12683' d='M23.114-27.292h2.353V-38.878H23.114Z' style={{fill: 'none'}}/>
          </clipPath>
          <clipPath id='clipPath-disc-8'>
            <path id='Path_12686' data-name='Path 12686' d='M31.244-34.437c-1.42-.5-1.835-.83-1.835-1.457,0-.728.742-1.28,1.762-1.28a2.487,2.487,0,0,1,1.91.932l1.228-1.542a5.41,5.41,0,0,0-3.554-1.287,3.5,3.5,0,0,0-3.776,3.312c0,1.6.759,2.414,2.975,3.185a9.075,9.075,0,0,1,1.635.661,1.366,1.366,0,0,1,.707,1.194,1.694,1.694,0,0,1-1.835,1.635,2.8,2.8,0,0,1-2.576-1.548L26.364-29.22a4.764,4.764,0,0,0,4.19,2.208c2.451,0,4.178-1.57,4.178-3.807,0-1.843-.8-2.678-3.487-3.618' style={{fill: 'none'}} clipRule='evenodd'/>
          </clipPath>
          <clipPath id='clipPath-disc-10'>
            <path id='Path_12689' data-name='Path 12689' d='M35.473-33.082a6.142,6.142,0,0,0,6.392,6.047,6.75,6.75,0,0,0,2.955-.675v-2.662A3.828,3.828,0,0,1,41.969-29.1,3.863,3.863,0,0,1,37.908-33.1a3.932,3.932,0,0,1,3.958-3.977,4.014,4.014,0,0,1,2.955,1.3v-2.659a6.252,6.252,0,0,0-2.906-.708,6.232,6.232,0,0,0-6.441,6.057' style={{fill: 'none'}} clipRule='evenodd'/>
          </clipPath>
          <clipPath id='clipPath-disc-12'>
            <path id='Path_12692' data-name='Path 12692' d='M63.949-31.1,60.72-38.878H58.146L63.278-27h1.266l5.232-11.878H67.222L63.949-31.1' style={{fill: 'none'}} clipRule='evenodd'/>
          </clipPath>
          <clipPath id='clipPath-disc-14'>
            <path id='Path_12695' data-name='Path 12695' d='M70.847-27.292h6.7v-1.963H73.21v-3.127h4.169v-1.963H73.21v-2.57h4.333v-1.962h-6.7v11.585' style={{fill: 'none'}} clipRule='evenodd'/>
          </clipPath>
          <clipPath id='clipPath-disc-16'>
            <path id='Path_12698' data-name='Path 12698' d='M82.154-33.55h-.685v-3.5h.724c1.475,0,2.265.592,2.265,1.721,0,1.16-.79,1.783-2.3,1.783Zm4.735-1.909c0-2.169-1.555-3.418-4.281-3.418H79.1v11.585h2.367V-31.95h.307l3.262,4.658h2.9l-3.809-4.883a3.115,3.115,0,0,0,2.756-3.284Z' style={{fill: 'none'}} clipRule='evenodd'/>
          </clipPath>
          <clipPath id='clipPath-disc-18'>
            <path id='Path_12701' data-name='Path 12701' d='M58.479-33.055a6.178,6.178,0,0,1-6.311,6.039,6.179,6.179,0,0,1-6.311-6.039,6.179,6.179,0,0,1,6.311-6.04,6.179,6.179,0,0,1,6.311,6.04' style={{fill: 'none'}} clipRule='evenodd'/>
          </clipPath>
        </defs>
        <g id='Group_20264' data-name='Group 20264' transform='translate(-859 -853)'>
          <g id='Rectangle_3264' data-name='Rectangle 3264' transform='translate(859 853)' fill='#fff' stroke='#e6772f' strokeWidth='2'>
            <rect width='100' height='60' rx='4' stroke='none'/>
            <rect x='1' y='1' width='98' height='58' rx='3' style={{fill: 'none'}}/>
          </g>
          <g id='Group_20193' data-name='Group 20193' transform='translate(859.248 913)' clipPath='url(#clipPath-disc)'>
            <g id='Group_20192' data-name='Group 20192' transform='translate(-595.629 -324)' clipPath='url(#clipPath-disc-2)'>
              <g id='Group_20191' data-name='Group 20191' transform='translate(614.745 292)' style={{isolation: 'isolate'}}>
                <g id='Group_20190' data-name='Group 20190' transform='translate(0)' clipPath='url(#clipPath-disc-3)'>
                  <g id='Group_20189' data-name='Group 20189' transform='translate(4.887 5)' clipPath='url(#clipPath-disc-4)'>
                    <path id='Path_12675' data-name='Path 12675' d='M19.115-31.3h83.938V5H19.115Z' transform='translate(-23.889 27)' fill='#e6772f'/>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id='Group_20263' data-name='Group 20263' transform='translate(2 54.069)'>
            <g id='Group_20195' data-name='Group 20195' transform='translate(857 862)' clipPath='url(#clipPath-disc-5)'>
              <g id='Group_20194' data-name='Group 20194' clipPath='url(#clipPath-disc-6)'>
                <path id='Path_12679' data-name='Path 12679' d='M7.058-43.878H27.025v21.585H7.058Z' fill='#1a1918'/>
              </g>
            </g>
            <g id='Group_20196' data-name='Group 20196' transform='translate(857 862)' clipPath='url(#clipPath-disc-7)'>
              <path id='Path_12682' data-name='Path 12682' d='M18.114-43.878H30.467v21.585H18.114Z' fill='#1a1918'/>
            </g>
            <g id='Group_20198' data-name='Group 20198' transform='translate(857 862)' clipPath='url(#clipPath-disc-8)'>
              <g id='Group_20197' data-name='Group 20197' clipPath='url(#clipPath-disc-6)'>
                <path id='Path_12684' data-name='Path 12684' d='M21.364-44.07H39.731v22.058H21.364Z' fill='#1a1918'/>
              </g>
            </g>
            <g id='Group_20200' data-name='Group 20200' transform='translate(857 862)' clipPath='url(#clipPath-disc-10)'>
              <g id='Group_20199' data-name='Group 20199' clipPath='url(#clipPath-disc-6)'>
                <path id='Path_12687' data-name='Path 12687' d='M30.473-44.139H49.82v22.1H30.473Z' fill='#1a1918'/>
              </g>
            </g>
            <g id='Group_20202' data-name='Group 20202' transform='translate(857 862)' clipPath='url(#clipPath-disc-12)'>
              <g id='Group_20201' data-name='Group 20201' clipPath='url(#clipPath-disc-6)'>
                <path id='Path_12690' data-name='Path 12690' d='M53.146-43.878h21.63V-22H53.146Z' fill='#1a1918'/>
              </g>
            </g>
            <g id='Group_20204' data-name='Group 20204' transform='translate(857 862)' clipPath='url(#clipPath-disc-14)'>
              <g id='Group_20203' data-name='Group 20203' clipPath='url(#clipPath-disc-6)'>
                <path id='Path_12693' data-name='Path 12693' d='M65.847-43.878h16.7v21.585h-16.7Z' fill='#1a1918'/>
              </g>
            </g>
            <g id='Group_20206' data-name='Group 20206' transform='translate(857 862)' clipPath='url(#clipPath-disc-16)'>
              <g id='Group_20205' data-name='Group 20205' clipPath='url(#clipPath-disc-6)'>
                <path id='Path_12696' data-name='Path 12696' d='M74.1-43.878H92.942v21.585H74.1Z' fill='#1a1918'/>
              </g>
            </g>
            <g id='Group_20208' data-name='Group 20208' transform='translate(857 862)' clipPath='url(#clipPath-disc-18)'>
              <g id='Group_20207' data-name='Group 20207' clipPath='url(#clipPath-disc-6)'>
                <path id='Path_12699' data-name='Path 12699' d='M40.857-44.095H63.479v22.079H40.857Z' fill='#e6772f'/>
              </g>
            </g>
          </g>
        </g>
      </svg>

    );
  }
};
