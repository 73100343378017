import { CALL_API } from '../middleware/api';
import actionTypes from '../constants/actionTypes';
import moment from 'moment-timezone';
import { ReportFrequency } from '../components/util/ScheduleReportUtil';

export function getReports(user, dateRange, type, allAccounts, employeeId, historicHardwareMode) {

  const accounts = user.accounts || null;
  const mode = historicHardwareMode ? 'h' : '';

  let query = dateRange && dateRange.value !== 'all' ? '?start=' + moment(dateRange.startDate).tz('America/New_York').format() + '&end=' + moment(dateRange.endDate).tz('America/New_York').format() : '';
  const queryStringAppend = query ? '&' : '?';
  query = type ? query + queryStringAppend + 'type=' + type : query;
  query = query ? query + '&allAccounts=' + allAccounts : query + '?allAccounts=' + allAccounts;
  query = employeeId ? query + '&employeeId=' + employeeId : query;
  query = accounts && Array.isArray(accounts) ? query + accounts.map(acc => '&mids[]=' + acc).join('') : query;
  query = query + '&mode=' + mode;

  const account = user.selectedMerchantAccount;
  const endpoint = 'users/accounts/' + account + '/reports' + query;

  let actionTypesForRequest;

  switch (type) {
    case 'comparison':
      actionTypesForRequest = [actionTypes.reportComparisonRequest, actionTypes.reportComparisonSuccess, actionTypes.reportComparisonFailure];
      break;
    case 'advanced':
      actionTypesForRequest = [actionTypes.reportAdvancedRequest, actionTypes.reportAdvancedSuccess, actionTypes.reportAdvancedFailure];
      break;
    default:
      actionTypesForRequest = [actionTypes.reportRequest, actionTypes.reportSuccess, actionTypes.reportFailure];
      break;
  }

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: actionTypesForRequest
    }
  };
}

export function getLoyaltyReport(user) {
  const endpoint = 'users/accounts/' + user.selectedMerchantAccount + '/reports/loyalty';

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.loyaltyReportRequest, actionTypes.loyaltyReportSuccess, actionTypes.loyaltyReportFailure]
    }
  };
}

export function getScheduledReports(user) {

  const endpoint = 'users/accounts/' + user.selectedMerchantAccount + '/reports/scheduled';

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.getScheduledReportsRequest, actionTypes.getScheduledReportsSuccess, actionTypes.getScheduledReportsFailure]
    }
  };
}

export function getFraudReport(user, dateRange) {

  const query = dateRange && dateRange.value !== 'all' ? '?start=' + moment(dateRange.startDate).tz('America/New_York').format() + '&end=' + moment(dateRange.endDate).tz('America/New_York').format() : '';
  const endpoint = `users/accounts/${ user.selectedMerchantAccount}/reports/fraud${query}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.getFraudReportsRequest, actionTypes.getFraudReportsSuccess, actionTypes.getFraudReportsFailure]
    }
  };
}

export function scheduleReports(user, scheduleReportProps, is_active) {
  const {type, additionalEmail, dayOfTheMonth, dayOfTheWeek, email, frequency} = scheduleReportProps;

  const body = {
    report_type: type,
    frequency,
    is_active
  };

  if (frequency == ReportFrequency.WEEKLY) body.send_on = dayOfTheWeek;
  else if (frequency === ReportFrequency.MONTHLY) body.send_on = dayOfTheMonth;

  body.email_to = !is_active ? [''] : additionalEmail ? [email, additionalEmail] : [email];

  const endpoint = 'users/accounts/' + user.selectedMerchantAccount + '/reports';

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'PUT',
      authenticated: true,
      types: [actionTypes.scheduleReportRequest, actionTypes.scheduleReportSuccess, actionTypes.scheduleReportFailure],
      body
    }
  };
}

export function setReportSummaryAll(value, currentAccountId) {
  return {
    type: actionTypes.setReportSummaryAll,
    reportSummaryAll: value,
    currentAccountId: currentAccountId
  };
}

export function setSelectedReport(value) {
  return {
    type: actionTypes.setSelectedReport,
    value
  };
}

export function clearReportError() {
  return {
    type: actionTypes.reportClearError
  };
}

export function toggleNoDataReportModal() {
  return {
    type: actionTypes.toggleNoReportDataModal
  };
}

export function toggleDeleteReportModal() {
  return {
    type: actionTypes.toggleDeleteReportModal,
  };
}

export function setReportFailure(error) {
  return {
    type: actionTypes.reportFailure,
    error,
  };
}

export function setCustomReportSelected(customReportSelected) {
  return {
    type: actionTypes.setCustomReportSelected,
    payload: customReportSelected,
  };
}

export function deleteCustomReportSelected(customReportSelected) {
  return {
    type: actionTypes.deleteCustomReportSelected,
    payload: customReportSelected,
  };
}

export function clearCustomReportSelected() {
  return {
    type: actionTypes.clearCustomReportSelected,
  };
}

/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */
