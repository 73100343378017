import actionTypes from '../constants/actionTypes';
import i18n, {setMomentLanguage, setLanguageKey} from '../locales/i18n';

export function setLanguageSelected(lang) {
  const langKey = setLanguageKey(lang);

  i18n.changeLanguage(langKey);
  localStorage.setItem('i18nLanguage', langKey);
  setMomentLanguage(langKey);

  return {
    type: actionTypes.languageSelected,
    i18nLanguage: langKey,
  };
}
