/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { submit } from 'redux-form';
import moment from 'moment';
import { MenuItem, Select } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';

import {
  updateCustomizedReportSettings,
  getMerchantSettings
} from '../../../actions/merchantSettingsActions';
import {
  setSelectedDateRange,
  setSelectedEmployeeFilter
} from '../../../actions/userExperienceActions';


import CustomDateFilter from '../../CustomDateFilter';
import CustomTimeFilter from '../../CustomTimeFilter';

import Modal from '../../shared/Modal';

import UpdateSpinner from '../../UpdateSpinner';

import UserUtil from '../../util/UserUtil';
import DateUtils from '../../util/DateUtil';

import {
  TODAY,
  ALL_TIME,
  YESTERDAY,
  THIS_WEEK,
  THIS_MONTH,
  THIS_YEAR,
  LAST_WEEK,
  LAST_MONTH,
  LAST_YEAR,
  OPEN_BATCH,
  CUSTOM_RANGE,
  CUSTOM_TIME
} from '../../../constants/dateFilterTypes';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { commonSelectStyles } from '../../../jss/commonSelectStyles';
import { dateFilterAccessory, dateFilterMenuItemStyles } from '../../../jss/appBarStyles';

import Box from '@mui/material/Box';

const MENU_PERIODS = [
  TODAY,
  YESTERDAY,
  THIS_WEEK,
  THIS_MONTH,
  THIS_YEAR,
  LAST_WEEK,
  LAST_MONTH,
  LAST_YEAR,
  CUSTOM_RANGE
];
class DateFilterAccessory extends Component {

  constructor(props) {
    super(props);

    this.state = {
      displayCustomRangeError: false,
      openCustomRangeDialog: false,
      openCustomTimeDialog: false,
      selectedItem: null,
      loading: false,
    };

    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleCustomRange = this.handleCustomRange.bind(this);
    this.handleCustomTime = this.handleCustomTime.bind(this);
    this.handleCustomRangeClose = this.handleCustomRangeClose.bind(this);
    this.onCustomDateRangeSubmit = this.onCustomDateRangeSubmit.bind(this);
    this.onCustomTimeRangeSubmit = this.onCustomTimeRangeSubmit.bind(this);
    this.handleCustomTimeRangeClose = this.handleCustomTimeRangeClose.bind(this);
    this.handleCustomRangeOpen = this.handleCustomRangeOpen.bind(this);
  }

  handleCustomRange(values) {

    const { dispatch, handleSelection } = this.props;

    // We allow 18 months for custom queries
    const customDateDifferenceDaysAllowed = 548;

    const startDateTime = values.fromDate ?
      `${new Date(values.fromDate).toDateString()} ${new Date(values.fromDate).toTimeString()}` : null;
    const endDateTime = values.toDate ?
      `${new Date(values.toDate).toDateString()} ${ new Date(values.toDate).toTimeString()}` : null;

    const previousStartDateTime = values.fromDatePrevious ?
      `${new Date(values.fromDatePrevious).toDateString()} ${new Date(values.fromDatePrevious).toTimeString()}` : null;
    let previousEndDateTime = null;

    if (values.toDatePrevious) {
      if (moment(values.toDatePrevious).startOf('day') >
        moment(values.fromDatePrevious).startOf('day') &&
        new Date(values.toDatePrevious).toTimeString().startsWith('23:59')) {
          previousEndDateTime = `${new Date(moment(values.toDatePrevious).subtract(1, 'day')).toDateString()} ${DateUtils.endOfDayTime()}`;
      } else {
        previousEndDateTime = `${new Date(values.toDatePrevious).toDateString()} ${new Date(values.toDatePrevious).toTimeString()}` ;
      }
    }

    const dateRange = DateUtils.getCustomDateRange(startDateTime, endDateTime, previousStartDateTime, previousEndDateTime);
    const dateDifference = dateRange.endDate?.diff(dateRange.startDate, 'days');

    if (dateDifference > customDateDifferenceDaysAllowed) {
      return this.setState({ displayCustomRangeError: true });
    }

    this.setState({
      openCustomRangeDialog: false,
      displayCustomRangeError: false
    }, () => {
      dispatch(setSelectedDateRange(CUSTOM_RANGE, dateRange));
      handleSelection(CUSTOM_RANGE, dateRange);
    })
  }

  handleCustomTime(values) {
    const { dispatch, user, handleSelection } = this.props;

    this.setState({ openCustomTimeDialog: false, loading: true }, async () => {

      const padTime = (time) => ({
        hours: time.hours().toString().padStart(2, '0'),
        minutes: time.minutes().toString().padStart(2, '0'),
        seconds: time.seconds().toString().padStart(2, '0')
      });

      const from = moment(values.fromTime, 'HH:mm');
      const fromTime = padTime(from);
      const { hours: fromHours, minutes: fromMinutes, seconds: fromSeconds } = fromTime;

      const to = from.clone().add({ hours: 23, minutes: 59, seconds: 59 });
      const toTime = padTime(to);
      const { hours: toHours, minutes: toMinutes , seconds: toSeconds} = toTime;

      const fromTimeText = `${fromHours}:${fromMinutes}:${fromSeconds}`;
      const toTimeText = `${toHours}:${toMinutes}:${toSeconds}`;

      await dispatch(updateCustomizedReportSettings(user, { fromTime: fromTimeText, toTime: toTimeText }));
      this.setState({ loading: false }, async () => {
        const res = await dispatch(getMerchantSettings(user));
        const reportStart = res?.response?.merchant_settings?.report_start;

        const startTime = reportStart ? reportStart : '00:00:00';
        const dateRange = DateUtils.getPersonalizedDateRange(TODAY, startTime);

        dispatch(setSelectedDateRange(TODAY, dateRange));
        dispatch(setSelectedEmployeeFilter([]));

        handleSelection(TODAY, dateRange);
      });
    });

  }

  handleCustomRangeClose() {
   this.setState({
     openCustomRangeDialog: false,
     displayCustomRangeError: false
   });
  }

  onCustomDateRangeSubmit() {
    const { dispatch } = this.props;
    dispatch(submit('dateFilterForm'));
  }

  handleCustomTimeRangeClose() {
    this.setState({ openCustomTimeDialog: false });
  }

  onCustomTimeRangeSubmit() {
    const { dispatch } = this.props;
    dispatch(submit('timeFilterForm'));
  }

  handleCustomRangeOpen() {
    this.setState({
      openCustomRangeDialog: true,
      selectedItem: CUSTOM_RANGE
    });
  }

  handleCustomTimeOpen() {
    this.setState({ openCustomTimeDialog: true });
  }
  handleValueChange(event) {
    const {
      merchantSettings: { customReportStartTime },
      handleSelection,
      dispatch
    } = this.props;

    const selectedItem = event.target.value;

    if (selectedItem === CUSTOM_RANGE) {
      return;
    } else if (selectedItem === CUSTOM_TIME){
      return this.handleCustomTimeOpen();
    }

    this.setState({ selectedItem }, () => {
      const dateRange = DateUtils.getPersonalizedDateRange(selectedItem, customReportStartTime);
      dispatch(setSelectedDateRange(selectedItem, dateRange));
      dispatch(setSelectedEmployeeFilter([]));
      handleSelection(selectedItem, dateRange);
    });
  }

  renderMenuItems() {
    const {
      t,
      user,
      dataName,
      showAllTime,
      showOpenBatch,
      hideCustomDate,
    } = this.props;

    const trimedPeriods = MENU_PERIODS.map(period => period.trim());
    const periodsLabel = trimedPeriods.reduce((acc, period) => ({
      ...acc,
      [period]: <div><span>{t(period)}</span></div>
    }), {})

    const remainingMenuValues =  MENU_PERIODS.map(period => ({
      value: period, text: periodsLabel[period]
    }));

    const menuValues = [
      { value: 'All Time', text: 'All Time' },
      { value: 'Open Batch', text: 'Open Batch' },
      ...remainingMenuValues
    ];

    const isLargeProcessor = UserUtil.isLargeProcessor(user);

    return menuValues.map((item, i) => {
      const hideMenuItem = (item.value === ALL_TIME && !showAllTime) ||
        (item.value === OPEN_BATCH && !showOpenBatch) ||
        ([TODAY, YESTERDAY].includes(item.value) && dataName === 'Batches data') ||
        ([THIS_YEAR, LAST_YEAR].includes(item.value) && isLargeProcessor) ||
        (item.value === CUSTOM_RANGE && hideCustomDate);

      if (hideMenuItem) return null;

      let onClick = null;
      if (item.value === CUSTOM_RANGE) {
        onClick = this.handleCustomRangeOpen;
      }

      return (
        <MenuItem key={`item${i}`} value={item.value} sx={dateFilterMenuItemStyles} onClick={onClick}>
          {item.text}
        </MenuItem>
      );
    });
  }

  renderModals() {
    const { openCustomRangeDialog, openCustomTimeDialog, displayCustomRangeError, loading } = this.state;
    const { userExperience, t } = this.props;
    const submitButtonDisabled = !userExperience.enableFormSubmitButton;
    const loader = loading ? <UpdateSpinner/> : null;

    return (
      <>
        <Modal
          cancelText={t('Cancel')}
          confirmText={t('Ok')}
          isConfirmDisabled={submitButtonDisabled}
          onClose={this.handleCustomRangeClose}
          onConfirm={this.onCustomDateRangeSubmit}
          open={openCustomRangeDialog}
          title={t('CustomDateRangeTitle')}
          disableEnforceFocus
        >
          <CustomDateFilter
            {...this.props}
            displayCustomRangeError={displayCustomRangeError}
            onSubmit={this.handleCustomRange}
          />
        </Modal>
        <Modal
          cancelText={t('Cancel')}
          confirmText={t('Ok')}
          isConfirmDisabled={submitButtonDisabled}
          onClose={this.handleCustomTimeRangeClose}
          onConfirm={this.onCustomTimeRangeSubmit}
          open={openCustomTimeDialog}
          title={t('CustomTimeRangeTitle')}
        >
          <CustomTimeFilter
            {...this.props}
            onSubmit={this.handleCustomTime} />
        </Modal>
        {loader}
      </>);
  }
  renderMenuBody(menuItems, selectedPeriod) {
    const {
      merchantSettings: { customReportStartTime },
      location,
    } = this.props;

    const showCustomOption = location.pathname.includes('test-drive');

    const labelTime = DateUtils.getFormattedDates(customReportStartTime);
    const customStartTime = labelTime?.fromTime?.format('hh:mm:ss a');
    const customEndTime = labelTime?.toTime?.format('hh:mm:ss a');

    return (
      <Box className='dateFilterAccessory' sx={commonSelectStyles} >
        <Select
          IconComponent={KeyboardArrowDownIcon}
          onChange={this.handleValueChange}
          value={selectedPeriod}>
          {!showCustomOption &&
            <MenuItem value={CUSTOM_TIME} sx={dateFilterMenuItemStyles}>
              <span>{`${customStartTime} - ${customEndTime}`}</span>
              {<EditIcon />}
            </MenuItem>
          }
          {menuItems}
        </Select>
      </Box>
    );
  }
  render() {
    const {
      alwaysUseDefaultValue,
      defaultValue,
    } = this.props;

    const { selectedItem } = this.state

    let selectedPeriod = defaultValue;

    if (Boolean(selectedItem) && !alwaysUseDefaultValue) {
      selectedPeriod = selectedItem;
    }

    const menuItems = this.renderMenuItems();
    const menuBody = this.renderMenuBody(menuItems, selectedPeriod);

    const modals = this.renderModals();

    return (
      <Box component='div' className='dateFilterAccessory' sx={dateFilterAccessory}>
        {menuBody}
        {modals}
      </Box>
     )
  }
}

export default DateFilterAccessory;
