import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {features, text} from '../../../constants/planInformation';
import planTypes from '../../../constants/planTypes';
import LabelUtil from '../../util/LabelUtil';

const PlanComparison = ({t}) => {
  
  const freeText = text[planTypes.free];
  const premiumText = text[planTypes.premium];
  const premiumPlusText = text[planTypes.premiumPlus];
  const {left, right} = features(planTypes.premiumPlus);
  const allFeatures = [...left, ...right];
  const label = LabelUtil.getLabel();
  
  return (
    <>
      <div className='detailsHeader'>
        <h3 className='title'>{t('PlanDisclaimer.Compare')}</h3>
      </div>
      <TableContainer>
        <Table sx={{ minWidth: 760 }} className='comparisonTable'>
          <TableHead>
            <TableRow>
              <TableCell/>
              <TableCell align='center' className='tableHeader'>
                <p className='label'>{t(freeText.label)}</p>
                <p className='price'>{t(freeText.price)}</p>
              </TableCell>
              <TableCell align='center' className='tableHeader'>
                <p className='label'>{t(premiumText.label)}</p>
                <p className='price'>{t(premiumText.price)}</p>
              </TableCell>
              <TableCell align='center' className='tableHeader'>
                <p className='label'>{t(premiumPlusText.label)}</p>
                <p className='price'>{t(premiumPlusText.price)}</p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              allFeatures.map((feature, index) =>
                <TableRow
                  key={feature.name}
                >
                  <TableCell align='left' className='feature'>
                    {`${t(feature.name)}${feature.asterisk ? '*' : ''}`}
                  </TableCell>
                  <TableCell align='center' className='feature'>
                    {feature.plans.includes(planTypes.free) && String.fromCharCode(10003)}
                  </TableCell>
                  <TableCell align='center' className='feature'>
                    {feature.plans.includes(planTypes.premium) && String.fromCharCode(10003)}
                  </TableCell>
                  <TableCell align='center' className='feature'>
                    {(feature.plans.includes(planTypes.premiumPlus) && !feature.addOn) && String.fromCharCode(10003)}
                    {feature.addOn && <span className='addOn'>Add-On**</span>}
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
      <p className='cardPlanDisclaimer'>{t('PlanDisclaimer.Free', {phone: label.phoneNumber})}</p>
      <p className='cardPlanDisclaimer'>{t('PlanDisclaimer.PremiumPlus')}</p>
    </>
  );
  
};

export default PlanComparison;