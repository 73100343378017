/**
North American Bancard ("NAB") CONFIDENTIAL MATERIAL

Copyright 2000 NAB, All Rights Reserved.

NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
and Non-disclosure agreements explicitly covering such access.

The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
MAY DESCRIBE, IN WHOLE OR IN PART.

*/

import React from 'react';
import {
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { navIntegrationDialog } from '../../jss/integrations/navIntegrationStyles';
import Image from '../shared/Image';
import IconUtils from '../util/IconUtil';
import LabelUtil from '../util/LabelUtil';
import Button from '../shared/Button';
import { sharedIntegrationDialogStyles } from '../../jss/inlineStyles';

const NavIntegrationDetails = ({t, isConnected, connectAction}) => {
  const label = LabelUtil.getLabel();
  const syncAltIcon = IconUtils.getIcon('SyncAltIcon', label.primaryColor, 'medium');
  const checkIcon = IconUtils.getIcon('CheckIcon', label.white, 'medium');
  const externalLinkIcon = IconUtils.getIcon('OpenInNewIcon', label.primaryColor);
  const externalDocumentIcon = IconUtils.getIcon('DocumentIcon', label.primaryColor, 22);

  return (
    <Box id={'navIntegrationDialog'} sx={sharedIntegrationDialogStyles}>
      <Box sx={sharedIntegrationDialogStyles.integrationHeader}>
        <Typography variant='primaryFontFamilyBold'>
          {t('IntegrationsPage.DialogHeader')}
        </Typography>
        
      </Box>
      <Box sx={sharedIntegrationDialogStyles.borderBox}>
        <Image alt={'Nav logo'}
          src={'/images/nav/navLogo.png'}
          sx={navIntegrationDialog.navLogo}
        />
        <Box sx={sharedIntegrationDialogStyles.brandBox}>
          <Typography
            sx={sharedIntegrationDialogStyles.brandText}
            variant='primaryFontFamilySemiBold'
          >
            Payments Hub
          </Typography>
          <span>{syncAltIcon}</span>
          <Typography
            sx={sharedIntegrationDialogStyles.brandText}
            variant='primaryFontFamilySemiBold'
          >
            Nav
          </Typography>
        </Box>
        <Typography
          sx={navIntegrationDialog.navDescription}
          variant='primaryFontFamilyMedium'
        >
          {t('IntegrationsPage.Nav.Details.Description')}
        </Typography>
        <Chip
          className='tagsContainer'
          label={t('IntegrationsPage.Tags.BusinessFunding')}
          sx={navIntegrationDialog.tagChip}
        />
        {
          !isConnected ? 
            <Box sx={navIntegrationDialog.optionsBox}>
              <Divider sx={{...sharedIntegrationDialogStyles.linkDivider, ...navIntegrationDialog.optionsDivider}}/>
              <Typography variant='primaryFontFamilySemiBold'>
                {t('IntegrationsPage.Nav.Details.Options')}
              </Typography>
            </Box>
          :
            null
        }
      </Box>
      {
        isConnected ?
          <Box id={'navLinkList'} sx={navIntegrationDialog.navLinkList}>
            <a href='https://app.nav.com/login' target='_blank'>
              <Box sx={sharedIntegrationDialogStyles.linkBox}>
                <Typography variant='primaryFontFamilySemiBold'>{t('IntegrationsPage.Nav.Details.Actions.VisitNavPortal')}</Typography>
                {externalLinkIcon}
              </Box>
            </a>
            <Divider sx={sharedIntegrationDialogStyles.linkDivider}/>
            <a href='https://www.nav.com/north/ ' target='_blank'>
              <Box sx={sharedIntegrationDialogStyles.linkBox}>
                <Typography variant='primaryFontFamilySemiBold'>{t('IntegrationsPage.Nav.Details.Actions.VisitNavWebsite')}</Typography>
                {externalLinkIcon}
              </Box>
            </a>
            <Divider sx={sharedIntegrationDialogStyles.linkDivider}/>
            <a href='https://www.nav.com/terms/' target='_blank'>
              <Box sx={sharedIntegrationDialogStyles.linkBox}>
                <Typography variant='primaryFontFamilySemiBold'>{t('IntegrationsPage.Nav.Details.Actions.ViewTermsAndConditions')}</Typography>
                {externalDocumentIcon}
              </Box>
            </a>
            <Divider sx={sharedIntegrationDialogStyles.linkDivider}/>
            <a href='https://www.nav.com/privacy/' target='_blank'>
              <Box sx={sharedIntegrationDialogStyles.linkBox}>
                <Typography variant='primaryFontFamilySemiBold'>{t('IntegrationsPage.Nav.Details.Actions.ViewPrivacyPolicy')}</Typography>
                {externalDocumentIcon}
              </Box>
            </a>
          </Box>
        :
          null
      }
      <Box sx={sharedIntegrationDialogStyles.featureBox}>
        <Typography
          sx={sharedIntegrationDialogStyles.featureText}
          variant='primaryFontFamilySemiBold'
        >
          {t('IntegrationsPage.Nav.Details.DetailedDescription.TopTitle')}
        </Typography>
        <Typography 
          display='inline'
          variant='primaryFontFamilySemiBold'
        >
          {t('IntegrationsPage.Nav.Details.DetailedDescription.Title')}
        </Typography>
        <Typography 
          display='inline'
          variant='primaryFontFamilyMedium'
        >
          {t('IntegrationsPage.Nav.Details.DetailedDescription.Text')}
        </Typography>
      </Box>
      <Box sx={sharedIntegrationDialogStyles.featureBox}>
        <Typography
          sx={{...sharedIntegrationDialogStyles.featureText, ...navIntegrationDialog.detailedDescriptionTitle}}
          variant='primaryFontFamilySemiBold'
        >
          {t('IntegrationsPage.Nav.Details.DetailedDescription.List.Title')}
        </Typography>
        <List>
          <ListItem disableGutters sx={navIntegrationDialog.listItem}>
            <ListItemIcon sx={{...sharedIntegrationDialogStyles.listItemIcon, ...navIntegrationDialog.navListItemIcon}}>
              {checkIcon}
            </ListItemIcon>
            <ListItemText>
              <Box sx={sharedIntegrationDialogStyles.listItemBox}>
                <Typography variant='primaryFontFamilyMedium' component='div'>
                  <Box sx={sharedIntegrationDialogStyles.listItemText} display='inline'><Typography variant='primaryFontFamilyBold'>{t('IntegrationsPage.Nav.Details.DetailedDescription.List.First.Title')}</Typography></Box>
                  {t('IntegrationsPage.Nav.Details.DetailedDescription.List.First.Text')}
                </Typography>
              </Box>
            </ListItemText>
          </ListItem>
          <ListItem disableGutters sx={navIntegrationDialog.listItem}>
            <ListItemIcon sx={{...sharedIntegrationDialogStyles.listItemIcon, ...navIntegrationDialog.navListItemIcon}}>
              {checkIcon}
            </ListItemIcon>
            <ListItemText>
              <Box sx={sharedIntegrationDialogStyles.listItemBox}>
                <Typography variant='primaryFontFamilyMedium' component='div'>
                  <Box sx={sharedIntegrationDialogStyles.listItemText} display='inline'><Typography variant='primaryFontFamilyBold'>{t('IntegrationsPage.Nav.Details.DetailedDescription.List.Second.Title')}</Typography></Box>
                  {t('IntegrationsPage.Nav.Details.DetailedDescription.List.Second.Text')}
                </Typography>
              </Box>
            </ListItemText>
          </ListItem>
          <ListItem disableGutters sx={navIntegrationDialog.listItem}>
            <ListItemIcon sx={{...sharedIntegrationDialogStyles.listItemIcon, ...navIntegrationDialog.navListItemIcon}}>
              {checkIcon}
            </ListItemIcon>
            <ListItemText>
            <Box sx={sharedIntegrationDialogStyles.listItemBox}>
                <Typography variant='primaryFontFamilyMedium' component='div'>
                  <Box sx={sharedIntegrationDialogStyles.listItemText} display='inline'><Typography variant='primaryFontFamilyBold'>{t('IntegrationsPage.Nav.Details.DetailedDescription.List.Third.Title')}</Typography></Box>
                  {t('IntegrationsPage.Nav.Details.DetailedDescription.List.Third.Text')}
                </Typography>
              </Box>
            </ListItemText>
          </ListItem>
        </List>
        <Box sx={navIntegrationDialog.marketingImageContainer}>
          <Image alt={'Nav marketing'}
            src={'/images/nav/navMarketing.png'}
            sx={navIntegrationDialog.marketingImage}
          />
        </Box>
        {
          !isConnected ? 
            <Box sx={navIntegrationDialog.connectContainer}>
              <a href='https://www.nav.com/north/' target='_blank'>
                <Box sx={navIntegrationDialog.navWebsiteLink}>
                  <Typography variant='primaryFontFamilySemiBold'>{t('IntegrationsPage.Nav.Details.Actions.VisitNavWebsite')}</Typography>
                  {externalLinkIcon}
                </Box>
              </a>
              <Button
                label={t('IntegrationsPage.Nav.Details.Actions.Connect')}
                onClick={connectAction}
                sx={navIntegrationDialog.connectButton}
                id={'navButton'}
              />
            </Box>
          :
            null
        }
      </Box>
    </Box>
  )
}

export default NavIntegrationDetails;
