/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import makeStyles from '@mui/styles/makeStyles';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Modal from '../Modal';
import FormatTextUtil from '../../util/FormatTextUtil';
import applicationConstants from '../../../constants/applicationConstants';


const TipSelector = ( {tipAmounts, fullWidth = true, setTip, t}) => {

  const useStyles = makeStyles({
    formControlRoot: {
      marginBottom: '30px'
    },
    radioGroupRoot: {
      backgroundColor: '#F2F2F2',
      borderRadius: '24px',
      justifyContent: 'center'
    },
    labelRoot: {
      fontFamily: '"Inter Semi Bold", sans-serif',
      color: '#000',
      fontWeight: 'bold',
      fontSize: '13px',
      paddingLeft: '15px'
    },
    labelFocused: {
      color: '#000 !important',
    },
    formControlLabelRoot: {
      marginRight: 0,
      padding: '10px 20px 10px 0',
      borderRadius: '24px',
      boxSizing: 'border-box',
      width: '100px',
      justifyContent: 'center',
      '&.bigTip span + span': {
        fontSize: '13px !important',
      }
    },
  });

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(t('NoTip'));
  const [customTip, setCustomTip] = React.useState('');
  const [formattedTips, setFormattedTips] = React.useState([]);

  const bigCustomTipClass = customTip?.length > 7 ? 'bigTip' : '';

  const handleChange = (event) => {
    const value = event?.target?.value;
    switch (value) {
      case t('Other'):
        setCustomTip('');
        setValue(value);
        setOpen(true);
        break;
      case t('NoTip'):
        setCustomTip('');
        setValue(value);
        setTip({value: 0, type: ''});
        break;
      default:
        setCustomTip('');
        setValue(value);
        setTip({value, type: value.replace(/\d|\./g, '')});
    }
  };

  const getSavedTips = () => {
    const dollarType = 0;
    const formattedTips = tipAmounts ? tipAmounts.map((tip) => tip.type === dollarType ? `$${tip.value}` : `${tip.value}%`) : [];
    if (tipAmounts) {
      const defaultIndex = tipAmounts.findIndex(tip => tip.isDefault);
      if (defaultIndex >= 0) {
        setValue(formattedTips[defaultIndex]);
        setTip({value: formattedTips[defaultIndex], type: tipAmounts[defaultIndex].type === dollarType ? '$' : '%'});
      }
    }
    setFormattedTips(formattedTips);
  };

  React.useEffect(() => {
    getSavedTips();
  }, []);

  const closeSetTip = () => {
    setCustomTip('');
    setValue(t('NoTip'));
    setOpen(false);
    setTip({value: 0, type: ''});
  };

  const confirmSetTip = () => {
    setOpen(false);
  };

  const handleCustomTipChange = (event) => {
    const commasDotsAndDollarSignPattern = /[,$.]/g;
    const value = event?.target?.value;
    const parsedValue = value.replace(commasDotsAndDollarSignPattern, '');

    const highTransactionLimitAmount = applicationConstants.highTransactionLimitAmount.toString().replace(commasDotsAndDollarSignPattern, '');
    const maxDigits = highTransactionLimitAmount.length;

    if (parsedValue.toString().length <= maxDigits) {
      const formattedValue = FormatTextUtil.formatCurrencyWithMaxDigit(value, maxDigits);
      setCustomTip(formattedValue);
      setTip({value: formattedValue, type: '$'});
    }
  };

  const setTipModal = (
    <Modal
      title={t('TipModalHeader')}
      maxWidth='sm'
      externalClassName='customTipModal'
      contentClassName=''
      onConfirm={confirmSetTip}
      onClose={closeSetTip}
      open={open}
      cancelText={t('Cancel')}
      confirmText={t('Confirm')}
    >
      <TextField
        value={customTip}
        variant='standard'
        placeholder='$0.00'
        onChange={handleCustomTipChange}
        inputProps={{ maxLength: 11 }}
      />
    </Modal>
  );

  return (
    <div id='tipSelector'>
      <FormControl
        classes={{
          root: classes.formControlRoot,
        }}
      >
        <FormLabel
          classes={{
            root: classes.labelRoot,
            focused: classes.labelFocused
          }}
          id='tip_selector'
        >
          {t('AddTip')}
        </FormLabel>
        <RadioGroup
          classes={{
            root: classes.radioGroupRoot
          }}
          row
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            className={value === t('NoTip') ? 'tipChecked' : ''}
            classes={{
              root: classes.formControlLabelRoot
            }}
            value={t('NoTip')}
            control={
              <Radio
                icon={<span />}
                checkedIcon={<span />}
              />
            }
            label={t('NoTip')}
          />
          {formattedTips?.map((tip, i) =>
            <FormControlLabel
              key={i}
              value={tip}
              control={
                <Radio
                  icon={<span />}
                  checkedIcon={<span />}
                />
              }
              label={tip}
              className={value == tip ? 'tipChecked' : ''}
              classes={{
                root: classes.formControlLabelRoot
              }}
            />
          )}
          <FormControlLabel
            classes={{
              root: classes.formControlLabelRoot
            }}
            className={value === t('Other') ? `tipChecked ${bigCustomTipClass}` : `${bigCustomTipClass}`}
            value={t('Other')}
            control={
              <Radio
                icon={<span />}
                checkedIcon={<span />}
              />
            }
            label={customTip || t('Other')}
          />
        </RadioGroup>
      </FormControl>
      {setTipModal}
    </div>
  );

}

export default TipSelector;
