/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */


import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import _ from 'lodash';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { setSubmitButtonEnabled, setVisibilityFilter } from '../../actions/userExperienceActions';
import { getCustomers, setCustomer } from '../../actions/customerActions';
import { updateCustomerTransaction } from '../../actions/transactionsActions';
import { getPaymentLinkDetail } from '../../actions/paymentLinksActions';

import actionTypes from '../../constants/actionTypes';
import messages from '../../constants/messages';
import routes from '../../constants/routes';

import UserUtil from '../util/UserUtil';
import FilterUtil from '../util/FilterUtil';
import TextUtil from '../util/FormatTextUtil';
import { roundToTwoDecimals, capitalizeFirstLetter } from '../util/CommonUtil';

import Button from '../shared/Button';
import MessageDialog from '../shared/MessageDialog';
import TableHeader from '../shared/TableHeader';

import DeleteButton from '../../components/DeleteButton';
import LineItemList from '../LineItemList';
import MapDetail from '../../components/MapDetail';
import NoData from '../NoData';
import SearchBarField from '../SearchBarField';
import SignatureImage from '../SignatureImage';
import TransactionDetailLevelsData from './TransactionDetailLevelsData';
import UpdateSpinner from '../UpdateSpinner';
import { RecurringInvoiceInfo, RecurringInvoiceInfoType } from '../RecurringInvoiceInfo';


export default class TransactionDetail extends Component {

  constructor(props) {
    super(props);

    this.loadData = this.loadData.bind(this);
    this.displayCustomerDialog = this.displayCustomerDialog.bind(this);
    this.hideCustomerDialog = this.hideCustomerDialog.bind(this);
    this.getCustomer = this.getCustomer.bind(this);
    this.updateTransaction = this.updateTransaction.bind(this);
    this.isActive = this.isActive.bind(this);
    this.relatedTransactions = this.relatedTransactions.bind(this);
    this.handleShowingLevelsData = this.handleShowingLevelsData.bind(this);

    this.state = {
      open: false,
      active: 0,
      updatingCustomer: false,
      visibilityFilterValue: null,
      showLevelsData: false,
      paymentLinkFromTransaction: null
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {

    const { transactions: { selectedReceipt }, dispatch, user } = this.props;
    const paymentLinkId = selectedReceipt?.payment_link_id;

    if (paymentLinkId) {
      dispatch(getPaymentLinkDetail(paymentLinkId, user)).then(result => {

        if (result.type === actionTypes.getPaymentLinkDetailSuccess) {
          this.setState({paymentLinkFromTransaction: result.response})
        }

      });
    }

  }

  componentDidUpdate(prevProps) {
    if (this.state.visibilityFilterValue && !this.state.open && prevProps.visibilityFilter.search !== this.props.visibilityFilter.search) {
      this.props.dispatch(setVisibilityFilter(this.state.visibilityFilterValue));
      this.setState({visibilityFilterValue: null});
    }
  }

  displayCustomerDialog() {
    const { visibilityFilter, customers, user, dispatch } = this.props;
    if (!customers.data) {
      dispatch(getCustomers(user));
    }
    this.setState({visibilityFilterValue: visibilityFilter.search, open: true});
  }

  hideCustomerDialog() {
    this.setState({open: false});
  }

  enableButton() {
    this.setState({enableButton: false});
  }

  getCustomer(index) {
    this.setState({active: index});
    this.props.dispatch(setCustomer(this.props.customers.filteredModally[index]));
    this.props.dispatch(setSubmitButtonEnabled(true));
  }

  updateTransaction() {

    const { transactions, customers, user, dispatch } = this.props;
    const that = this;

    this.setState({updatingCustomer: true});

    let selectedReceipt = transactions.selectedReceipt;
    if (transactions.selectedReceipt.type && transactions.selectedReceipt.type.includes('Void')) {
      selectedReceipt = _.find(transactions.receipts, {'void_uniq_id': transactions.selectedReceipt.uniq_id});
    } else if (transactions.selectedReceipt.type && transactions.selectedReceipt.type.includes('Refund')) {
      selectedReceipt = _.find(transactions.receipts, {'uniq_id': (transactions.selectedReceipt.parent_uniq_id)});
    }

    dispatch(updateCustomerTransaction(customers.selectedCustomer, selectedReceipt, user)).then(() => {
      that.state.updatingCustomer = false;
      that.props.loadData(that.props);
    });
    this.hideCustomerDialog();
  }

  isActive(index) {
    return ((index === this.state.active) ? 'customerActive' : '');
  }

  relatedTransactions(receiptId) {
    const reroute = globalApplicationLabel.path + routes.activity.root + routes.activity.transactions + '?receiptId=' + receiptId;
    this.props.history.push(reroute);
    location.reload();
  }

  handleShowingLevelsData() {
    this.setState({showLevelsData: !this.state.showLevelsData});
  }

  render() {

    const { transactions, customers, user, userExperience, auth, merchantSettings, t, paymentLinks: { isFetchingDetail } } = this.props;
    const { paymentLinkFromTransaction, updatingCustomer, open, showLevelsData } = this.state;

    const { selectedReceipt, receipts } = transactions;
    const { historicHardwareMode } = userExperience;

    const readOnly = !UserUtil.isActive(user);
    const isMBPUser = UserUtil.userType(user) === 'MBP';
    const isPAUser = UserUtil.userType(user) === 'PA';
    const isCsUser = UserUtil.isUserCS(user, auth);

    let parentInScope = true;
    let relatedTransactions = false;

    const spinner = updatingCustomer || isFetchingDetail ? (<UpdateSpinner/>) : null;

    const customerHeaders = (
      <TableRow className={'tableRow'}>
        <TableHeader
          type='table'
          headerId={'customerHeaderListOne'}
          name={t('Name')}
          {...this.props}/>
        <TableHeader
          type='table'
          headerId={'customerHeaderListTwo'}
          name={t('Contact Info')}
          {...this.props}/>
      </TableRow>
    );

    const customerRows = (customers.filteredModally && customers.filteredModally.length > 0) ?
      customers.filteredModally.map((customer, i) => {
          return (
            <TableRow key={i} className={`tableRow ${this.isActive(i)}`} >
              <TableCell>
                <div className='customerCell' onClick={this.getCustomer.bind(this,i)}>
                  {TextUtil.formatName(customer.first_name, customer.last_name, 'Unnamed Customer')}
                </div>
              </TableCell>
              <TableCell>
                <div className='customerCell' onClick={this.getCustomer.bind(this,i)}>
                  {(customer.email_addresses[0]) ? customer.email_addresses[0] : (customer.phone_number) ? TextUtil.formatPhoneNumber(customer.phone_number) : 'No email or phone provided'}
                </div>
              </TableCell>
            </TableRow>
          )
        }
      ) : (
        <NoData
            text={t('NoCustomers')}
            type='table'
        />
      );

    const customerList =  customers.isFetching ? <UpdateSpinner/> : (
      <div className='customerDialogSearch' style={{minHeight: '494px'}}>
        <SearchBarField {...this.props} filterType='modalSearch'  searchPlaceholder={t('SearchBarPlaceholder')}>{null}</SearchBarField>
        <Table>
          <TableBody>
            {customerHeaders}
            {customerRows}
          </TableBody>
        </Table>
      </div>
    );

    const newDialog = (
      <MessageDialog
        className='customerDialog'
        scrollable={true}
        confirmText={t('Add')}
        bodyText={customerList}
        onRequestClose={this.hideCustomerDialog}
        open={open}
        cancelText={t('Cancel')}
        onConfirm={this.updateTransaction.bind(this)}
        titleText={t('SelectCustomer')}
        isChoiceRequired={false}
        disabled={updatingCustomer}
      />
    );
    
    const isAchTransaction = selectedReceipt?.type?.includes('ACH');

    const network = selectedReceipt?.network;
    const first6 = !isAchTransaction && selectedReceipt.cc_first6;
    const maskedCardNumber = TextUtil.formatMaskedCardNumber(first6, selectedReceipt.cc_last4, network);
    const cardInfo = TextUtil.formatJoin(t(network), maskedCardNumber);
    const achAccountType = isAchTransaction && `${selectedReceipt?.cc_type.split(' ').map(word => capitalizeFirstLetter(word)).join(' ')} Account`;
    const achAccountNumber = isAchTransaction && selectedReceipt?.cc_last4;
    const achRoutingNumber = isAchTransaction && selectedReceipt?.routing_nbr;

    const customerName = TextUtil.formatName(selectedReceipt?.first_name && selectedReceipt?.first_name?.trim(), selectedReceipt?.last_name && selectedReceipt?.last_name.trim(), 'Unnamed Customer');
    const emailAddress = selectedReceipt?.email_addresses?.[0] ?? selectedReceipt?.email ?? null;
    const customerPhone = selectedReceipt && selectedReceipt?.phone_number ? TextUtil.formatPhoneNumber(selectedReceipt?.phone_number) : null;
    const showAddButton = !isMBPUser && (!selectedReceipt?.first_name && !selectedReceipt?.last_name && !emailAddress && !customerPhone && !readOnly);

    if (selectedReceipt?.type && selectedReceipt?.type.includes('Refund')) {
      relatedTransactions = parentInScope = !!(_.find(receipts, {'uniq_id': (selectedReceipt?.parent_uniq_id)}));
    } else if (selectedReceipt?.type && selectedReceipt?.type?.includes('Void')) {
      relatedTransactions = (_.find(receipts, {'void_uniq_id': (selectedReceipt?.uniq_id)}));
    }

    let receiptId = relatedTransactions ? selectedReceipt?.parent_uniq_id || relatedTransactions?.uniq_id : null;
    receiptId = receiptId ? receiptId.replace(/\D/g, '') : null;

    const addCustomerButton = parentInScope && !historicHardwareMode && isPAUser && !transactions.selectedReceipt?.type?.includes('Void') && (
      <div className='addCustomerButton'>
        <Button
          label={t('AddCustomer')}
          onClick={this.displayCustomerDialog}
          disabled={isCsUser}
        />
      </div>
    );

    const transactionSource = FilterUtil.getTransactionSource(selectedReceipt);

    const showMapDetail = selectedReceipt?.latitude && selectedReceipt?.longitude;

    const actionsAvailable = selectedReceipt?.rp_is_active && selectedReceipt?.rp_is_autopay;

    const cartDiscounts = selectedReceipt && selectedReceipt?.receipt_discount_amt && selectedReceipt?.receipt_discount_amt?.length ? selectedReceipt?.receipt_discount_amt?.map((receiptDiscount, index) => {
      let percentageText;

      try {
        percentageText = selectedReceipt?.receipt_discount_type[index] === 'Percentage'
          ? ` (${JSON.parse(selectedReceipt?.receipt_discount_info[index])?.percentage}% off)`
          : '';
      } catch (error) {
        percentageText = '';
      }

      return (
        <p className='details detailsCartDiscount' key={`cartDiscount${index}`}>
          <span className='text'>{`Discount${percentageText}:`}</span>
          <span className='number'>{'-' + numeral(receiptDiscount).format('$0,0.00')}</span>
        </p>
      );
    }) : null;

    const subtotal = Math.abs(roundToTwoDecimals(selectedReceipt?.sub_total_amt));
    const tax = Math.abs(roundToTwoDecimals(selectedReceipt?.tax_amt));
    const tip = Math.abs(roundToTwoDecimals(selectedReceipt?.tip_amount));
    const total = subtotal + tax + tip;

    const employeeName = selectedReceipt?.sold_by_name && selectedReceipt?.sold_by_name;
    const employeeEmail = selectedReceipt?.sold_by && selectedReceipt?.sold_by;

    const paymentLinkRawUrl = paymentLinkFromTransaction?.settings?.url || '';
    const paymentLinkToken = paymentLinkRawUrl.slice(paymentLinkRawUrl.indexOf('token='));
    const paymentLinkUrl = `${globalApplicationLabel.path}${routes.paymentLink.root}?${paymentLinkToken}`

    return (

      <div className='transactionDetail'>
        <div className='detailDescription mainDetails'>
          <div className='detailsHeader'>{t('TransactionDetails')}</div>
          {selectedReceipt?.invoice &&
          <div className='detailDescriptionText detailDescriptionRow invoiceNumber'>{`${t('Invoice')} ${selectedReceipt?.invoice}`}</div>}
          <div className='detailDescriptionText detailDescriptionRow mid'>{`MID ${selectedReceipt?.mid}`}</div>
          {selectedReceipt?.txn_source &&
          <div className='detailDescriptionText detailDescriptionRow transactionSource'>{transactionSource}</div>}
          {selectedReceipt?.device && <div className='detailDescriptionText detailDescriptionRow device'>{`${t('Device')} ${selectedReceipt?.device}`}</div>}
          {!isAchTransaction && cardInfo && <div className='detailDescriptionText detailDescriptionRow cInfo'>{cardInfo}</div>}
          {achAccountType && <div className='detailDescriptionText detailDescriptionRow achAccount'>{achAccountType}</div>}
          {achAccountNumber && <div className='detailDescriptionText detailDescriptionRow achNumber'>{t('AccountEnding', {number: achAccountNumber})}</div>}
          {achRoutingNumber && <div className='detailDescriptionText detailDescriptionRow achRouting'>{t('RoutingNumberTemplate', {number: achRoutingNumber})}</div>}
          {selectedReceipt?.cc_auth_code &&
            <div className='detailDescriptionText detailDescriptionRow authNumber'>{`${t('Authorization')} #${selectedReceipt?.cc_auth_code}`}</div>}
          {selectedReceipt?.ccs_authorized_amt && <div className='detailDescriptionText detailDescriptionRow authorizedAmount'>{`${t('AuthorizedAmount')} ${numeral(selectedReceipt?.ccs_authorized_amt).format('$0,0.00')}`}</div>}
          {selectedReceipt?.isSplitPayment &&
          <div className='detailDescriptionText detailDescriptionRow splitPayment'>{t('SplitPayment')}</div>}
          {selectedReceipt?.is_debt_repayment &&
          <div className='detailDescriptionText detailDescriptionRow debtRepayment'>{t('DebtRepayment')}</div>}
          {selectedReceipt?.has_instant_funded &&
          <div className='detailDescriptionText detailDescriptionRow instantFunding'>{t('InstantFunded')}</div>}
          {selectedReceipt?.custom_fields?.custom_field_1 && (
            <div className='detailDescriptionText detailDescriptionRow customField'>{`${selectedReceipt?.custom_fields?.custom_field_1?.name}: ${selectedReceipt?.custom_fields?.custom_field_1?.value}`}</div>
          )}

          {paymentLinkFromTransaction &&
            <div>
              <div className='detailDescriptionText detailDescriptionRow paymentLinkDescriptionTitle'>{`${t('PaymentLinks.PaymentLinkTitle')}: ${paymentLinkFromTransaction?.settings?.title}`}</div>
              <div className='detailDescriptionText detailDescriptionRow paymentLinkURL'>
                <Link className='linkLike'
                  to={paymentLinkUrl}
                  target='_blank' rel='noopener noreferrer'
                >
                  {t('PaymentLinks.PaymentLinkURL')}
                </Link>
              </div>
            </div>
          }
          {selectedReceipt?.custom_fields?.payment_links && (
            <div className='detailDescriptionText detailDescriptionRow paymentLinks'>{`${selectedReceipt?.custom_fields?.payment_links?.name}: ${selectedReceipt?.custom_fields?.payment_links?.value}`}</div>
          )}
        </div>

        {selectedReceipt?.business_data  &&
          <TransactionDetailLevelsData
            showLevelsData={showLevelsData}
            handleShowingLevelsData={this.handleShowingLevelsData}
            levelsData={selectedReceipt?.business_data}
            t={t}
          />
        }

        {actionsAvailable &&
        <div className='detailDescription availableActions'>
          <div className='detailsHeader'>{t('AvailableActions')}</div>
          {selectedReceipt?.rp_is_active && selectedReceipt?.rp_is_autopay &&
          <div className='detailDescriptionText detailDescriptionRow'>
            <DeleteButton
              {...this.props}
              type={'button'}
              message={messages.autopay.confirmRemoveAutopay}
              buttonLabel={t('UnenrollFromAutopay')}
              handleDelete={this.props.removeAutopay}/>
          </div>}
        </div>}

        {selectedReceipt?.inv_is_recurring ? (
          <div className='detailDescription recurringDetails'>
            <div className='detailsHeader'>{t('RecurringInvoice')}</div>
            <RecurringInvoiceInfo
              {...this.props}
              data={selectedReceipt}
              displayType={RecurringInvoiceInfoType.TRANSACTION}/>
          </div>
        ) : null}


        {_.size(selectedReceipt?.subTransactions) ? <div className='detailDescription subtransactionDetails'>
          <div className='detailsHeader'>{t(selectedReceipt?.type)}</div>
          <div className='detailDescriptionText detailDescriptionRow'>
            {`${t(selectedReceipt?.type)} ${t('Amount')}: ${numeral(selectedReceipt?.amount).format('$0,0.00')}`}</div>
        </div> : null}

        <div className='detailDescription employeeDetails'>
          <div className='detailsHeader'>{t('Employee')}</div>
          {employeeName &&
          <div className='detailDescriptionText detailDescriptionRow'>{employeeName}</div>}
          {employeeEmail &&
            <div className='detailDescriptionText detailDescriptionRow'>{employeeEmail}</div>}
        </div>

        <div className='detailDescription customerDetails'>
          <div className='detailsHeader'>{t('Customer')}</div>
          {showAddButton ?
            <div className='detailDescriptionText detailDescriptionRow addCustomer'>
              <span>{t('NoCustomerAssigned')}</span>
              {addCustomerButton}
            </div> :
            <div className='detailDescriptionText detailDescriptionRow customerName'>{customerName}</div>}
          {emailAddress && <div className='detailDescriptionText detailDescriptionRow customerEmail'>{emailAddress}</div>}
          {customerPhone && <div className='detailDescriptionText detailDescriptionRow customerPhone'>{customerPhone}</div>}
          {selectedReceipt?.customer_account_number !== null && <div className='detailDescriptionText detailDescriptionRow customerAccount'>{`Account Number: ${selectedReceipt.customer_account_number}`} </div>}
          {(selectedReceipt?.cc_signature_id && selectedReceipt?.id) && (
            <div className='detailDescriptionText detailDescriptionRow customerSig'>
              <SignatureImage
                {...this.props}
                signatureImageId={_.size(selectedReceipt?.subTransactions) ? _.head(selectedReceipt?.subTransactions).cc_signature_id : selectedReceipt?.cc_signature_id}
                salesId={_.size(selectedReceipt?.subTransactions) ? _.head(selectedReceipt?.subTransactions).id : selectedReceipt?.id}/>
            </div>
          )}
        </div>

        {showMapDetail && <div className='detailDescription locationDetails'>
          <div className='detailsHeader'>{t('Location')}</div>
          <div className='detailDescriptionText detailDescriptionRow'>
            <MapDetail latitude={selectedReceipt?.latitude} longitude={selectedReceipt?.longitude}/>
          </div>
        </div>}

        {selectedReceipt?.comment && <div className='detailDescription commentDetails'>
          <div className='detailsHeader'>{t('Comments')}</div>
          <div className='detailDescriptionText detailDescriptionRow'>
            {selectedReceipt?.comment}
          </div>
        </div>}

        <div className='detailDescription invoiceDetails'>
          <div className='detailsHeader'>{t('Invoice')}</div>

          <LineItemList
            {...this.props}
            selectedReceipt={this.props.transactions?.selectedReceipt}
            isTransactionDetails={true}
          />

          <div className='detailTotals'>

            <div className='cartDiscounts'>
              {cartDiscounts}
            </div>

            <p className='details detailsSubtotal'>
              <span className='text'>{`${t('Subtotal')}:`}</span>
              <span className='number'>{numeral(subtotal).format('$0,0.00')}</span>
            </p>
            <p className='details detailsTax'>
              <span className='text'>{`${t('Tax')}:`}</span>
              <span className='number'>{numeral(tax).format('$0,0.00')}</span>
            </p>
            <p className='details detailsTip'>
              <span className='text'>{`${t('Tip')}:`}</span>
              <span className='number'>{numeral(tip).format('$0,0.00')}</span>
            </p>
            <p className='details detailsTotal'>
              <span className='text'>{`${t('Total')}:`}</span>
              <span className='number'>{numeral(total).format('$0,0.00')}</span>
            </p>
          </div>

        </div>

        {(relatedTransactions) ?
          <div className='detailDescription relatedTransactions'>
            <Button
              label={t('Related Transactions')}
              onClick={() => this.relatedTransactions(receiptId)}/>
          </div> : null}

        {newDialog}
        {spinner}

      </div>

    );

  }
}
