/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';
import CustomToggle from '../shared/Toggle';
import numeral from 'numeral';
import moment from 'moment';
import TextUtil from '../util/FormatTextUtil';

// Levels 2 Tags
// tag001: customerCode,
// tag002: taxAmount,
// tag003: taxId

// Levels 3 Tags
//  tag004: itemDescription,
//  tag005: itemQuantity,
//  tag006: measureUnit,
//  tag007: unitPrice,
//  tag008: productCode,
//  tag009: orderDate,
//  tag014: commodityCode,
//  tag015: requestorName,
//  tag016: companyName,
//  tag017: shipmentId,
//  tag018: shipmentCountry,
//  tag019: shipmentState,
//  tag020: shipmentZipCode,
//  tag021: destinationCountry,
//  tag022: destinationState,
//  tag023: destinationZipCode,
//  tag024: discountAmount,
//  tag025: dutyAmount,
//  tag026: freightAmount,

// Auth.net L2 data
// tax_exempt
// tax_amount


const TransactionDetailLevelsData = ({showLevelsData, handleShowingLevelsData, levelsData, ...props}) => {

    const { t } = props;

    const taxAmount = !levelsData.l2 ? null :
      levelsData.l2.tag002 ? levelsData.l2.tag002 : levelsData.l2.tax_amount ? levelsData.l2.tax_amount : 0;


    return (
      <div className='transactionDetailLevelsInfo'>

        <div className='detailDescription'>
          <div className='detailDescriptionText detailDescriptionRow'>
            <div className={`buttonHolder ${showLevelsData}`}>
              <span className='levelsToggleLabel'>{t('LevelsToggleLabel')}</span>
              <div className='toggleHolder'>
                <CustomToggle
                    input={{
                      value: showLevelsData,
                      onChange: handleShowingLevelsData
                    }}
                    label={t('Show')}
                    labelPosition={'start'}
                />
              </div>
            </div>
            {showLevelsData &&
            <div className='transactionDetailLevelsData'>
              {levelsData.l2 &&
                <div className='level2DataContainer'>
                  {levelsData.l2.tax_exempt &&
                    <div className='levelsRow'>
                      <span className='levelsLabel'>{`${t('TaxExempt')} `}</span>
                      <span className='levelsValue tax_exempt'>{TextUtil.formatYesNo(levelsData.l2.tax_exempt)}</span>
                    </div>
                  }
                  {levelsData.l2.tag001 &&
                    <div className='levelsRow'>
                      <span className='levelsLabel'>{`${t('CustomerCode')} `}</span>
                      <span className='levelsValue tag001'>{levelsData.l2.tag001}</span>
                    </div>
                  }
                  {(taxAmount || taxAmount === 0) && (
                    <div className='levelsRow'>
                      <span className='levelsLabel'>{`${t('TaxAmount')} `}</span>
                      <span className='levelsValue tag002 tax_amount'>{numeral(taxAmount || 0).format('$0,0.00')}</span>
                    </div>
                    )
                  }
                  {levelsData.l2.tag003 &&
                    <div className='levelsRow'>
                      <span className='levelsLabel'>{`${t('TaxId')} `}</span>
                      <span className='levelsValue tag003'>{levelsData.l2.tag003}</span>
                    </div>
                  }
                </div>
              }
              {levelsData.l3 &&
                <div className='level3DataContainer'>
                  <div className='levelsRow'>
                    <span className='levelsLabel'>{`${t('ItemDescription')} `}</span>
                    <span className='levelsValue tag004'>{levelsData.l3.tag004}</span>
                  </div>
                  <div className='levelsRow'>
                    <div className='levelsColumn'>
                      <span className='levelsLabel'>{`${t('ItemQuantity')} `}</span>
                      <span className='levelsValue tag005'>{levelsData.l3.tag005 || 0}</span>
                    </div>
                    <div className='levelsColumn'>
                      <span className='levelsLabel'>{`${t('MeasureUnit')} `}</span>
                      <span className='levelsValue tag006'>{levelsData.l3.tag006}</span>
                    </div>
                  </div>
                  <div className='levelsRow'>
                    <div className='levelsColumn'>
                      <span className='levelsLabel'>{`${t('UnitPrice')} `}</span>
                      <span className='levelsValue tag007'>{numeral(levelsData.l3.tag007 || 0).format('$0,0.00')}</span>
                    </div>
                    <div className='levelsColumn'>
                      <span className='levelsLabel'>{`${t('ProductCode')} `}</span>
                      <span className='levelsValue tag008'>{levelsData.l3.tag008}</span>
                    </div>
                  </div>
                  <div className='levelsRow'>
                    <div className='levelsColumn'>
                      <span className='levelsLabel'>{`${t('OrderDate')} `}</span>
                      <span className='levelsValue tag009'>{moment(levelsData.l3.tag009).format('MMM Do YYYY, h:mm a')}</span>
                    </div>
                    <div className='levelsColumn'>
                      <span className='levelsLabel'>{`${t('CommodityCode')} `}</span>
                      <span className='levelsValue tag014'>{levelsData.l3.tag014}</span>
                    </div>
                  </div>
                  <div className='levelsRow'>
                    <span className='levelsLabel'>{`${t('RequesterName')} `}</span>
                    <span className='levelsValue tag015'>{levelsData.l3.tag015}</span>
                  </div>
                  <div className='levelsRow'>
                    <span className='levelsLabel'>{`${t('CompanyName')} `}</span>
                    <span className='levelsValue tag016'>{levelsData.l3.tag016}</span>
                  </div>
                  <div className='levelsRow'>
                    <div className='levelsColumn'>
                      <span className='levelsLabel'>{`${t('ShipmentId')} `}</span>
                      <span className='levelsValue tag017'>{levelsData.l3.tag017}</span>
                    </div>
                    <div className='levelsColumn'>
                      <span className='levelsLabel'>{`${t('ShipmentCountry')} `}</span>
                      <span className='levelsValue tag018'>{levelsData.l3.tag018}</span>
                    </div>
                  </div>
                  <div className='levelsRow'>
                    <div className='levelsColumn'>
                      <span className='levelsLabel'>{`${t('ShipmentState')} `}</span>
                      <span className='levelsValue tag019'>{levelsData.l3.tag019}</span>
                    </div>
                    <div className='levelsColumn'>
                      <span className='levelsLabel'>{`${t('ShipmentZip')} `}</span>
                      <span className='levelsValue tag020'>{levelsData.l3.tag020}</span>
                    </div>
                  </div>
                  <div className='levelsRow'>
                    <div className='levelsColumn'>
                      <span className='levelsLabel'>{`${t('DestinationCountry')} `}</span>
                      <span className='levelsValue tag021'>{levelsData.l3.tag021}</span>
                    </div>
                    <div className='levelsColumn'>
                      <span className='levelsLabel'>{`${t('DestinationState')} `}</span>
                      <span className='levelsValue tag022'>{levelsData.l3.tag022}</span>
                    </div>
                  </div>
                  <div className='levelsRow'>
                    <div className='levelsColumn'>
                      <span className='levelsLabel'>{`${t('DestinationZip')} `}</span>
                      <span className='levelsValue tag023'>{levelsData.l3.tag023}</span>
                    </div>
                    <div className='levelsColumn'>
                      <span className='levelsLabel'>{`${t('DiscountAmount')} `}</span>
                      <span className='levelsValue tag024'>{numeral(levelsData.l3.tag024 || 0).format('$0,0.00')}</span>
                    </div>
                  </div>
                  <div className='levelsRow'>
                    <div className='levelsColumn'>
                      <span className='levelsLabel'>{`${t('DutyAmount')} `}</span>
                      <span className='levelsValue tag025'>{numeral(levelsData.l3.tag025 || 0).format('$0,0.00')}</span>
                    </div>
                    <div className='levelsColumn'>
                      <span className='levelsLabel'>{`${t('FreightAmount')} `}</span>
                      <span className='levelsValue tag026'>{numeral(levelsData.l3.tag026 || 0).format('$0,0.00')}</span>
                    </div>
                  </div>
                </div>
              }
            </div>}
          </div>
        </div>
      </div>
    )

};

export default TransactionDetailLevelsData;
