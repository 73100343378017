/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';
import {Box} from '@mui/material';
import LabelUtil from '../util/LabelUtil';

import { achTermsStyles } from '../../jss/AchTermsStyles';

import LanguageSelector from '../shared/LanguageSelector';

const AchTerms = (props) => {

  const color = LabelUtil.getLabelColor();
  const linkColor = {color: color};

  return (
    <Box sx={achTermsStyles}>
      <Box className='achTerms'>
        <h1 className='title'>ACH Payment Terms & Conditions</h1>
        <div>
          <p>
            The PayAnywhere Service (“Service”) is provided by North (“North”) (collectively “we,” “our,” or
            “us”), an authorized Payment Service
            Provider for the Merchant (“Merchant”) to which you are submitting payment(s). These Terms and
            Conditions (“Terms and
            Conditions”) apply to all transactions processed by us and apply to and govern all Alternative
            Payment Channels offered by North.
          </p>
          <p>
            As used in these Terms and Conditions, the terms “you,” “your,” and “Receiver” mean a user of this
            Service or any Alternative
            Payment Channel who is authorized to submit payment information to the Merchant for the collection
            of funds. The term “bank
            account” means a checking account, savings account, or money market account established in your name
            or you are a designated
            signor of at a bank or other financial institution. The term “Alternative Payment Channels” includes
            those payments initiated by you
            through telephone, internet, customer service representatives, Integrated Voice Response (IVR), or
            any other mechanism of payment
            acceptance. Depending on the service options selected by the Merchant, a transaction fee
            (“Convenience Fee”) for each payment
            made through the Service may be assessed by the Merchant. If a Convenience Fee is charged, it will
            be disclosed to you prior to
            submitting your payment information, and you will be given the option of not completing the payment
            process if you do not wish to
            pay the Convenience Fee.
          </p>
          <strong>You should retain an electronic or printed copy of these Terms and Conditions
            for your records.</strong>
        </div>
        <div>
          <p>1. Bank Account Payments</p>
          <p>
            By choosing to use a bank account as your payment method, you accept and agree to these Terms and
            Conditions, which cover this
            and any subsequent payment(s) made through this Service to the Merchant. You will be able to pay by
            using any valid Automated
            Clearing House ("ACH") enabled bank account located at a United States-based financial institution.
            Whenever you choose to pay by
            ACH, you are authorizing Merchant (or us) to debit your bank account for the total amount due. Your
            transaction must be payable in
            U.S. dollars. North, in its sole discretion, may refuse this payment option or Service to anyone or
            any user without notice for any
            reason at any time. Transactions that we process using your bank account can be identified by
            Merchant’s name (or similar identifier)
            on the statement issued by your bank or other financial institution holding your account. All
            questions relating to any transactions
            made using your bank account by us should be initially directed to the Merchant, but may also
            require involvement of us and your
            bank.
          </p>

        </div>
        <div>
          <p>2. ACH Authorization</p>
          <p>
            Subject to the additional terms set forth in Section 11, by clicking on the designated button to
            complete a payment, or otherwise authorizing
            a payment, you agree that: (a) you have read, understand and agree to these Terms and Conditions and
            that this constitutes as a "signed
            agreement by you" under any applicable law or regulation, (b) you consent to the electronic delivery
            of the disclosures contained in these
            Terms and Conditions, (c) you authorize Merchant (or us) to make any inquiries necessary to validate
            any dispute involving your payment,
            and (d) you authorize Merchant (or us to initiate one or more ACH debit entries (withdrawals), and
            you authorize the financial institution that
            holds your bank account to deduct such payments, in the amounts and frequency designated in your
            invoice or on your receipt.
          </p>

        </div>
        <div>
          <p>3. Fees and Payment</p>
          <p>
            <i>Timing of Payments:</i> You should authorize your payment at least two (2) Business Days before
            your payment due date. Payments made by
            ACH will be debited from your bank account on the Business Day following your authorization. If we
            receive your authorization on a
            Business Day prior to the scheduled cutoff time, we will process your payment to the Merchant on the
            same Business Day. If we receive
            your authorization on a day which is not a Business Day or after the day’s scheduled cutoff time, we
            will process your payment to the
            Merchant on the next Business Day. For recurring payments, if you schedule a payment on a date which
            is not a Business Day, then we will
            process the payment on the next Business Day thereafter.
          </p>
          <p><i>NSF, Additional Fees or Charges: </i>You agree if there are insufficient funds (“NSF”) in your
            bank account to cover a submitted payment, we
            may attempt to withdrawal the authorized payment one (1) additional time. If your bank rejects a
            debit for any reason, the payment, and any
            future scheduled payments, will be cancelled and the payment will be reversed to the extent
            permitted by law. If this occurs, you may be
            liable to the Merchant for interest, late fees and other charges, to the extent permitted by law.
            You may also be liable for any fees charged
            by the financial institution where your bank account held.</p>

        </div>
        <div>
          <p>4. Revocation or Cancellation</p>
          <p>
            You may not cancel a payment once your bank account has been debited. If you schedule recurring
            payments,
            you may revoke your authorization with the Merchant using the means and timing by which was
            provided to
            you in the invoice or on the receipt.
          </p>
        </div>
        <div>
          <p>5. Refunds</p>
          <p>
            Unless required by law, no refunds of the Convenience Fee or (and subject to your Merchant’s
            refund policy)
            principal are available once your bank account has been debited, unless we processed a payment
            in error.
            Please contact the Merchant if you have questions concerning their products or services, their
            refund policy,
            or the amounts posted to your account with the Merchant.
          </p>
        </div>
        <div>
          <p>6. Transaction Errors</p>
          <p>
            If you believe that any payment transaction initiated by Merchant (or us) with respect to your
            bank account
            is erroneous, or if you need more information about any such transaction, you should contact
            Merchant as soon
            as possible using the contact information in your invoice or on your receipt.
          </p>
        </div>
        <div>
          <p>7. Your Liability for Unauthorized Transactions</p>
          <p>
            Federal law limits your liability for any fraudulent, erroneous or unauthorized transaction from
            your bank
            account based on how quickly you report it to your financial institution. As a general rule, you
            should report
            any fraudulent, erroneous or unauthorized transactions to your bank within 60 days after the
            questionable
            transaction FIRST appeared on your bank account statement. You should contact your bank for more
            information
            about the policies and procedures that apply to your account and any unauthorized transactions,
            including
            any limits on your liability.
          </p>
        </div>
        <div>
          <p>8. Agreement Changes</p>
          <p>
            We may, at our discretion, change these Terms and Conditions at any time without notice to you.
            If any change
            is found to be invalid, void, or for any reason unenforceable, that change is severable and does
            not affect the
            validity and the ability to enforce any other changes or the remainder of these Terms and
            Conditions. Your continued
            use of your bank account as a payment method after any change in these Terms and Conditions
            constitutes your acceptance
            of these changes.
          </p>
        </div>
        <div>
          <p>9. Prohibited Use</p>
          <p>
            North does not accept payments from third-party payers. The Service shall only be used by
            customers of Merchants
            that have contracted with North. Third-party commercial payers and/or payment aggregators are
            expressly prohibited
            from submitting payments through the Service or other Alternative Payment Channels offered by
            North on the Merchant's
            behalf. By using the Service, you represent and warrant you (“Receiver”) are (i) a customer of
            the Merchant; (ii)
            purchasing goods, or paying a bill or invoice on your own behalf; and (iii) not using the
            Service on behalf of a
            Receiver under a claim of agency or other third-party relationship, or for commercial gain. You
            agree that any
            breach of these warranties constitutes a material breach of this Agreement. North reserves the
            right to reverse
            any and all payments made from third-party agencies, and you agree: (i) to indemnify North for
            Merchant fees,
            as well as all other costs and fees, including internal costs to North to research and compile
            charges by you,
            incurred in processing a payment transaction in violation of these terms and conditions, and
            (ii) indemnify North
            for all other damages, costs, fees, expenses, and attorney fees resulting from your breach of
            this agreement.
          </p>
        </div>
        <div>
          <p>10. Confidentiality and Privacy</p>
          <p>
            We will disclose information about the transfers you make or authorize to your Merchant biller.
            We may also
            disclose information to third parties, (i) where it is necessary for completing transfers, (ii)
            In order to
            verify the existence and condition of your account for a third party, such as a credit bureau or
            Merchant,
            (iii) In order to comply with government agency or court orders, (iv) as provided in our <a href='https://www.north.com/privacy' style={linkColor} target='_blank'>Privacy Policy</a>;
            or (v) If you give us your written permission.
          </p>
        </div>
        <div>
          <p>11. Electronic Signature Disclosure, Electronic Delivery of Disclosures, and Consent Notice</p>
          <p>
            By agreeing to these Terms and Conditions, you agree that we may provide you with any and all
            communications
            related to the Service in electronic format, unless you withdraw your consent. You may withdraw
            your consent
            to receive communications in electronic form by (i) writing to North at 250 Stephenson Hwy,
            Troy, MI 48083,
            (ii) emailing us at  <a href='mailto:privacy@north.com' style={linkColor} target='_blank'>privacy@north.com</a>,
            or (iii) if applicable, following the opt out (text messages) or
            unsubscribe instructions (email). However, withdrawal of your consent will be effective after a
            reasonable
            period of time for processing your request, and confirmation of your withdrawal of consent and
            its effective
            date may be communicated in writing (either electronically or in paper form).
          </p>
          <p>
            You agree that your consent to electronic communications is being provided in connection with a
            transaction
            affecting interstate commerce, subject to the federal Electronic Signatures in Global and
            National Commerce
            Act, and intend that the Act apply to the fullest extent possible to validate our ability to
            conduct business
            with you by electronic means. By this Consent, you agree that we may provide all communications
            by email or by
            text message, or, if applicable by making them accessible via websites or applications.
            Communications include,
            but are not limited to, (1) payment authorizations and transaction receipts or confirmations,
            (2) updates to
            these Terms and Conditions, and (3) all current or future communications required under federal
            or state laws.
          </p>
          <p>
            In order to access electronic communications via website (if applicable) or application (if
            applicable), you
            will need (i) internet access, (ii) a currently supported version of one the following internet
            browsers Chrome,
            Firefox or Safari, and (iii) a currently supported PDF viewer (such as Adobe Reader which is
            available for free
            download at http://get.adobe.com/reader/). These requirements, browsers and/or PDF readers may
            change from time
            to time. Neither North nor its agents are responsible for ensuring that communications are
            accessible through
            outdated vendor products. In addition, neither North nor its agents are responsible for (i)
            consequential or
            incidental damages caused by the Services, the Merchant, or your internet services provider, or
            (ii) damages
            arising from unauthorized access to the Services.
          </p>
          <p>
            You have the right to receive Communications in paper form. To request a paper copy of any
            communication at no
            charge, please write directly to your Merchant and the address provided. For the avoidance of
            doubt, requesting
            a paper copy of any communication, in and of itself, will not be treated as withdrawal of
            consent to receive
            electronic communications.
          </p>
        </div>
        <LanguageSelector  {...props} />
      </Box>
    </Box>
  );
}

export default AchTerms;