/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

Copyright 2000 NAB, All Rights Reserved.

NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
and Non-disclosure agreements explicitly covering such access.

The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
MAY DESCRIBE, IN WHOLE OR IN PART.

*/

import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { getReputationSettings, removeCompetitor } from '../../../../actions/reputationActions';
import MapDetail from '../../../MapDetail';
import Modal from '../../../shared/Modal';
import IconUtils from '../../../util/IconUtil';
import LabelUtil from '../../../util/LabelUtil';
import AddCompetitor from './AddCompetitor';
export default class CompetitorsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      removeCompetitorDialogOpen: false,
    }

    this.removeCompetitorDialogClose = this.removeCompetitorDialogClose.bind(this);

  }

  removeCompetitorDialogOpen(competitor) {
    this.setState({removeCompetitorDialogOpen: true, selectedCompetitor: competitor});
  }
  removeCompetitorDialogClose() {
    this.setState({removeCompetitorDialogOpen: false});
  }

  async onRemoveCompetitorSubmit(competitor) {
    this.removeCompetitorDialogClose();
    await this.props.dispatch(removeCompetitor(this.props.user, competitor.placeId));
    this.props.dispatch(getReputationSettings(this.props.user, this.props.userExperience.selectedDate.dateRange));
  }


  render() {
    const {competitors, t, isSettingsList } = this.props;
    const labelColor = LabelUtil.getLabelColor();

    const business = this.state.selectedCompetitor?.name ? this.state.selectedCompetitor?.name : 'this competitor';

    const RemoveCompetitorDialog = (
      <Modal
        title={t('ReputationProject.CompetitorPerformance.RemoveCompetitorTitle')}
        confirmText={t('ReputationProject.CompetitorPerformance.RemoveCompetitorConfirm')}
        cancelText={t('ReputationProject.CompetitorPerformance.RemoveCompetitorCancel')}
        onClose={this.removeCompetitorDialogClose}
        onConfirm={this.onRemoveCompetitorSubmit.bind(this, this.state.selectedCompetitor)}
        open={this.state.removeCompetitorDialogOpen && !!this.state.selectedCompetitor}
      >
        <div>
          <p>
            <Trans i18nKey={'ReputationProject.CompetitorPerformance.RemoveCompetitorText'}>
              Are you sure you want to remove {{business}}? You will no longer see trends and comparisons for this business.
            </Trans>
          </p>
        </div>
      </Modal>
    );
    let competitorName;
    return (
      <div className='competitorsBar'>
        {
          competitors && competitors.map((competitor) => {
            competitorName = competitor.name;
            return (
              <div className='competitor' key={competitor.placeId}>
                {
                  !competitor.active ?
                    <div className='inactive'>
                      <div className='icon'>
                        {IconUtils.getIcon('ErrorOutlineIcon', '#EFEFF2', 40)}
                      </div>
                      <div className='information'>
                        <p>
                          {t('ReputationProject.CompetitorPerformance.NoLongerAvailable')}
                        </p>
                        <span className='remove' onClick={this.removeCompetitorDialogOpen.bind(this, competitor)}>
                          {IconUtils.getIcon('DeleteIcon', labelColor, 16)}
                          {t('ReputationProject.CompetitorPerformance.Remove')}
                        </span>
                      </div>
                    </div>
                  :
                    null
                }
                {
                  competitor.latitude && competitor.longitude &&
                    <div className='location'>
                      <MapDetail latitude={competitor.latitude} longitude={competitor.longitude} zoom={15} zoomControl={false} attributionControl={false}/>
                    </div>
                }

                <div className='info' title={competitorName}>
                  <span className='trimmedName'>{competitorName}</span>
                  {
                    competitor.firstLine && competitor.secondLine &&
                      <div className='address'>
                        <p>{competitor.firstLine}</p>
                        <p>{competitor.secondLine}</p>
                        {competitor.distance >= 0 && <p>{`${competitor.distance} ${t('ReputationProject.CompetitorPerformance.MilesAway')}`}</p>}
                      </div>
                  }

                </div>
                {
                  isSettingsList && competitor.active &&
                    <div className='removeContainer' onClick={this.removeCompetitorDialogOpen.bind(this, competitor)}>
                      <div className='icon'>{IconUtils.getIcon('DeleteIcon', '#231F20', 18)}</div>
                    </div>
                }
              </div>
            )
          })
        }
       <AddCompetitor {...this.props} />
       {RemoveCompetitorDialog}
      </div>
    );
  };
}

