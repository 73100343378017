/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';

export default function PaymentsHubLogo({style}) {
  return (
    <svg className='paymentsHubLogo' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 148.23 22.649' style={style}>
      <defs>
        <clipPath id='clipPath'>
          <rect id='Rectangle_3788' data-name='Rectangle 3788' width='116.883'
                height='15.01' fill='#23d6c3'/>
        </clipPath>
        <clipPath id='clipPath-2'>
          <rect id='Rectangle_6837' data-name='Rectangle 6837' width='22.649'
                height='22.649' fill='none'/>
        </clipPath>
      </defs>
      <g id='Group_25358' data-name='Group 25358'
         transform='translate(-691 -76)'>
        <g id='Group_24833' data-name='Group 24833'
           transform='translate(722.348 80.004)'>
          <g id='Group_24832' data-name='Group 24832' transform='translate(0 0)'
             clipPath='url(#clipPath)'>
            <path id='Path_14424' data-name='Path 14424'
                  d='M9.318,12.441a4.155,4.155,0,0,1-4.261,4.4A3.321,3.321,0,0,1,2.48,15.812V19.8H0V8.164H2.369V9.15A3.323,3.323,0,0,1,5.056,8.037a4.154,4.154,0,0,1,4.261,4.4m-2.512,0a2.159,2.159,0,0,0-2.178-2.369,2.159,2.159,0,0,0-2.178,2.369A2.159,2.159,0,0,0,4.627,14.81a2.159,2.159,0,0,0,2.178-2.369'
                  transform='translate(0 -4.92)' fill='#39434e'/>
            <path id='Path_14425' data-name='Path 14425'
                  d='M35.967,8.164v8.554H33.6v-.986a3.291,3.291,0,0,1-2.671,1.113,4.158,4.158,0,0,1-4.277-4.4,4.158,4.158,0,0,1,4.277-4.4,3.285,3.285,0,0,1,2.56,1.034V8.164Zm-2.433,4.277a2.193,2.193,0,1,0-4.373,0,2.193,2.193,0,1,0,4.373,0'
                  transform='translate(-16.314 -4.92)' fill='#39434e'/>
            <path id='Path_14426' data-name='Path 14426'
                  d='M65,8.363v7.123c0,3.18-1.67,4.643-4.659,4.643a6.763,6.763,0,0,1-4.086-1.177l.986-1.781a4.753,4.753,0,0,0,2.894.954c1.67,0,2.385-.779,2.385-2.322v-.286a3.5,3.5,0,0,1-2.528.986c-2.147,0-3.657-1.192-3.657-3.848V8.363h2.48v3.928c0,1.447.636,2.083,1.733,2.083,1.145,0,1.972-.731,1.972-2.305V8.363Z'
                  transform='translate(-34.436 -5.12)' fill='#39434e'/>
            <path id='Path_14427' data-name='Path 14427'
                  d='M99.2,11.82v4.9h-2.48V12.2c0-1.383-.62-2.019-1.622-2.019-1.113,0-1.892.716-1.892,2.242v4.293h-2.48V12.2c0-1.383-.588-2.019-1.622-2.019-1.1,0-1.876.716-1.876,2.242v4.293h-2.48V8.163h2.369v.986A3.466,3.466,0,0,1,89.8,8.036a3.193,3.193,0,0,1,2.814,1.4,3.846,3.846,0,0,1,3.085-1.4c2.035,0,3.5,1.176,3.5,3.784'
                  transform='translate(-51.88 -4.92)' fill='#39434e'/>
            <path id='Path_14428' data-name='Path 14428'
                  d='M133.452,14.03l1.32,1.431a4.481,4.481,0,0,1-3.53,1.384c-2.957,0-4.881-1.86-4.881-4.4a4.35,4.35,0,0,1,4.579-4.4,4.188,4.188,0,0,1,4.42,4.325l-6.328,1.224a2.255,2.255,0,0,0,2.274,1.272,2.9,2.9,0,0,0,2.147-.827m-4.659-1.845,4.166-.811a1.982,1.982,0,0,0-2.019-1.462,2.1,2.1,0,0,0-2.147,2.273'
                  transform='translate(-77.356 -4.92)' fill='#39434e'/>
            <path id='Path_14429' data-name='Path 14429'
                  d='M162.617,11.82v4.9h-2.48V12.2c0-1.383-.636-2.019-1.733-2.019-1.193,0-2.051.732-2.051,2.306v4.23h-2.48V8.163h2.369v1a3.746,3.746,0,0,1,2.83-1.129c2.019,0,3.546,1.176,3.546,3.784'
                  transform='translate(-94.198 -4.92)' fill='#39434e'/>
            <path id='Path_14430' data-name='Path 14430'
                  d='M187.278,13.518a3.527,3.527,0,0,1-2,.541c-2.035,0-3.2-1.034-3.2-3.069V3.485h2.48V5.568h2.115V7.476h-2.115v3.482a.987.987,0,0,0,1.049,1.129,1.756,1.756,0,0,0,1.018-.318Z'
                  transform='translate(-111.466 -2.134)' fill='#39434e'/>
            <path id='Path_14431' data-name='Path 14431'
                  d='M196.962,15.923l.827-1.781a5.853,5.853,0,0,0,2.973.827c1.145,0,1.574-.3,1.574-.78,0-1.4-5.168.032-5.168-3.386,0-1.622,1.463-2.767,3.959-2.767a7.029,7.029,0,0,1,3.291.747l-.827,1.765a4.913,4.913,0,0,0-2.465-.636c-1.113,0-1.59.349-1.59.8,0,1.462,5.168.048,5.168,3.419,0,1.59-1.479,2.719-4.039,2.719a7.344,7.344,0,0,1-3.7-.922'
                  transform='translate(-120.577 -4.92)' fill='#39434e'/>
            <path id='Path_14432' data-name='Path 14432'
                  d='M229.61,6.9v4.9h-2.48V7.282c0-1.383-.636-2.019-1.733-2.019-1.193,0-2.051.732-2.051,2.306V11.8h-2.481V0h2.481V4.134a3.758,3.758,0,0,1,2.719-1.018c2.019,0,3.546,1.176,3.546,3.784'
                  transform='translate(-135.21 0)' fill='#23d6c3'/>
            <path id='Path_14433' data-name='Path 14433'
                  d='M257.738,8.363v8.555h-2.353V15.9a3.462,3.462,0,0,1-2.655,1.145c-2.147,0-3.657-1.208-3.657-3.848V8.363h2.48v4.468c0,1.431.636,2.067,1.734,2.067,1.144,0,1.971-.731,1.971-2.306V8.363Z'
                  transform='translate(-152.478 -5.12)' fill='#23d6c3'/>
            <path id='Path_14434' data-name='Path 14434'
                  d='M286.68,7.521a4.155,4.155,0,0,1-4.261,4.4,3.324,3.324,0,0,1-2.687-1.113V11.8h-2.369V0h2.48V4.15a3.32,3.32,0,0,1,2.576-1.034,4.154,4.154,0,0,1,4.261,4.4m-2.512,0a2.186,2.186,0,1,0-4.357,0,2.186,2.186,0,1,0,4.357,0'
                  transform='translate(-169.797 0)' fill='#23d6c3'/>
          </g>
        </g>
        <g id='Group_23964' data-name='Group 23964'
           transform='translate(691 76)'>
          <g id='Group_23963' data-name='Group 23963' transform='translate(0 0)'
             clipPath='url(#clipPath-2)'>
            <path id='Path_16097' data-name='Path 16097'
                  d='M37.132,67.981A3.133,3.133,0,1,1,34,71.114a3.133,3.133,0,0,1,3.132-3.133'
                  transform='translate(-25.807 -51.598)' fill='#49e2a8'/>
            <path id='Path_16098' data-name='Path 16098'
                  d='M39.95,67.866A3.133,3.133,0,1,1,36.817,71a3.133,3.133,0,0,1,3.132-3.133'
                  transform='translate(-27.946 -51.511)' fill='#48e2a8'/>
            <path id='Path_16099' data-name='Path 16099'
                  d='M42.7,67.527A3.133,3.133,0,1,1,39.57,70.66,3.133,3.133,0,0,1,42.7,67.527'
                  transform='translate(-30.035 -51.254)' fill='#47e1a9'/>
            <path id='Path_16100' data-name='Path 16100'
                  d='M45.382,66.973a3.133,3.133,0,1,1-3.132,3.133,3.133,3.133,0,0,1,3.132-3.133'
                  transform='translate(-32.069 -50.833)' fill='#46e1a9'/>
            <path id='Path_16101' data-name='Path 16101'
                  d='M47.982,66.214a3.133,3.133,0,1,1-3.133,3.133,3.133,3.133,0,0,1,3.133-3.133'
                  transform='translate(-34.042 -50.257)' fill='#45e0a9'/>
            <path id='Path_16102' data-name='Path 16102'
                  d='M50.491,65.257a3.133,3.133,0,1,1-3.132,3.133,3.133,3.133,0,0,1,3.132-3.133'
                  transform='translate(-35.947 -49.531)' fill='#44e0aa'/>
            <path id='Path_16103' data-name='Path 16103'
                  d='M52.9,64.112a3.133,3.133,0,1,1-3.132,3.133A3.133,3.133,0,0,1,52.9,64.112'
                  transform='translate(-37.775 -48.662)' fill='#43e0aa'/>
            <path id='Path_16104' data-name='Path 16104'
                  d='M55.2,62.789a3.133,3.133,0,1,1-3.133,3.133A3.133,3.133,0,0,1,55.2,62.789'
                  transform='translate(-39.522 -47.657)' fill='#42dfaa'/>
            <path id='Path_16105' data-name='Path 16105'
                  d='M57.384,61.295a3.133,3.133,0,1,1-3.133,3.133,3.133,3.133,0,0,1,3.133-3.133'
                  transform='translate(-41.179 -46.523)' fill='#41dfab'/>
            <path id='Path_16106' data-name='Path 16106'
                  d='M59.439,59.641a3.133,3.133,0,1,1-3.132,3.133,3.133,3.133,0,0,1,3.132-3.133'
                  transform='translate(-42.739 -45.268)' fill='#40deab'/>
            <path id='Path_16107' data-name='Path 16107'
                  d='M61.361,57.835a3.133,3.133,0,1,1-3.133,3.133,3.133,3.133,0,0,1,3.133-3.133'
                  transform='translate(-44.197 -43.897)' fill='#3fdeab'/>
            <path id='Path_16108' data-name='Path 16108'
                  d='M63.138,55.886a3.133,3.133,0,1,1-3.132,3.133,3.133,3.133,0,0,1,3.132-3.133'
                  transform='translate(-45.546 -42.418)' fill='#3edeac'/>
            <path id='Path_16109' data-name='Path 16109'
                  d='M64.76,53.8a3.133,3.133,0,1,1-3.133,3.133A3.133,3.133,0,0,1,64.76,53.8'
                  transform='translate(-46.777 -40.837)' fill='#3dddac'/>
            <path id='Path_16110' data-name='Path 16110'
                  d='M66.22,51.6a3.133,3.133,0,1,1-3.133,3.133A3.133,3.133,0,0,1,66.22,51.6'
                  transform='translate(-47.885 -39.162)' fill='#3cddad'/>
            <path id='Path_16111' data-name='Path 16111'
                  d='M67.508,49.273a3.133,3.133,0,1,1-3.132,3.133,3.133,3.133,0,0,1,3.132-3.133'
                  transform='translate(-48.864 -37.399)' fill='#3bddad'/>
            <path id='Path_16112' data-name='Path 16112'
                  d='M68.616,46.843a3.133,3.133,0,1,1-3.132,3.133,3.133,3.133,0,0,1,3.132-3.133'
                  transform='translate(-49.704 -35.554)' fill='#3adcad'/>
            <path id='Path_16113' data-name='Path 16113'
                  d='M69.534,44.316A3.133,3.133,0,1,1,66.4,47.449a3.133,3.133,0,0,1,3.133-3.133'
                  transform='translate(-50.401 -33.636)' fill='#39dcae'/>
            <path id='Path_16114' data-name='Path 16114'
                  d='M70.253,41.7a3.133,3.133,0,1,1-3.133,3.133A3.133,3.133,0,0,1,70.253,41.7'
                  transform='translate(-50.947 -31.65)' fill='#38dbae'/>
            <path id='Path_16115' data-name='Path 16115'
                  d='M70.765,39a3.133,3.133,0,1,1-3.133,3.133A3.133,3.133,0,0,1,70.765,39'
                  transform='translate(-51.335 -29.604)' fill='#37dbae'/>
            <path id='Path_16116' data-name='Path 16116'
                  d='M71.059,36.237a3.133,3.133,0,1,1-3.132,3.133,3.133,3.133,0,0,1,3.132-3.133'
                  transform='translate(-51.558 -27.504)' fill='#36dbaf'/>
            <path id='Path_16117' data-name='Path 16117'
                  d='M71.128,33.411A3.133,3.133,0,1,1,68,36.544a3.133,3.133,0,0,1,3.132-3.133'
                  transform='translate(-51.611 -25.359)' fill='#35daaf'/>
            <path id='Path_16118' data-name='Path 16118'
                  d='M70.968,30.606a3.133,3.133,0,1,1-3.132,3.133,3.133,3.133,0,0,1,3.132-3.133'
                  transform='translate(-51.489 -23.23)' fill='#34daaf'/>
            <path id='Path_16119' data-name='Path 16119'
                  d='M70.585,27.867A3.133,3.133,0,1,1,67.452,31a3.133,3.133,0,0,1,3.132-3.133'
                  transform='translate(-51.199 -21.151)' fill='#33d9b0'/>
            <path id='Path_16120' data-name='Path 16120'
                  d='M69.989,25.2a3.133,3.133,0,1,1-3.132,3.133A3.133,3.133,0,0,1,69.989,25.2'
                  transform='translate(-50.746 -19.129)' fill='#32d9b0'/>
            <path id='Path_16121' data-name='Path 16121'
                  d='M69.189,22.62a3.133,3.133,0,1,1-3.133,3.133,3.133,3.133,0,0,1,3.133-3.133'
                  transform='translate(-50.139 -17.169)' fill='#31d9b0'/>
            <path id='Path_16122' data-name='Path 16122'
                  d='M68.193,20.131a3.133,3.133,0,1,1-3.133,3.133,3.133,3.133,0,0,1,3.133-3.133'
                  transform='translate(-49.383 -15.28)' fill='#30d8b1'/>
            <path id='Path_16123' data-name='Path 16123'
                  d='M67.012,17.743a3.133,3.133,0,1,1-3.133,3.133,3.133,3.133,0,0,1,3.133-3.133'
                  transform='translate(-48.487 -13.467)' fill='#2fd8b1'/>
            <path id='Path_16124' data-name='Path 16124'
                  d='M65.654,15.465A3.133,3.133,0,1,1,62.521,18.6a3.133,3.133,0,0,1,3.133-3.133'
                  transform='translate(-47.456 -11.738)' fill='#2ed7b1'/>
            <path id='Path_16125' data-name='Path 16125'
                  d='M64.127,13.307a3.133,3.133,0,1,1-3.132,3.133,3.133,3.133,0,0,1,3.132-3.133'
                  transform='translate(-46.297 -10.1)' fill='#2dd7b2'/>
            <path id='Path_16126' data-name='Path 16126'
                  d='M62.442,11.277a3.133,3.133,0,1,1-3.132,3.133,3.133,3.133,0,0,1,3.132-3.133'
                  transform='translate(-45.018 -8.559)' fill='#2cd7b2'/>
            <path id='Path_16127' data-name='Path 16127'
                  d='M60.606,9.384a3.133,3.133,0,1,1-3.132,3.133,3.133,3.133,0,0,1,3.132-3.133'
                  transform='translate(-43.624 -7.123)' fill='#2bd6b2'/>
            <path id='Path_16128' data-name='Path 16128'
                  d='M58.63,7.638A3.133,3.133,0,1,1,55.5,10.771,3.133,3.133,0,0,1,58.63,7.638'
                  transform='translate(-42.124 -5.797)' fill='#2ad6b3'/>
            <path id='Path_16129' data-name='Path 16129'
                  d='M56.521,6.048a3.133,3.133,0,1,1-3.132,3.133,3.133,3.133,0,0,1,3.132-3.133'
                  transform='translate(-40.524 -4.591)' fill='#29d6b3'/>
            <path id='Path_16130' data-name='Path 16130'
                  d='M54.29,4.621a3.133,3.133,0,1,1-3.133,3.133A3.133,3.133,0,0,1,54.29,4.621'
                  transform='translate(-38.83 -3.507)' fill='#28d5b3'/>
            <path id='Path_16131' data-name='Path 16131'
                  d='M51.945,3.369A3.133,3.133,0,1,1,48.812,6.5a3.133,3.133,0,0,1,3.132-3.133'
                  transform='translate(-37.05 -2.557)' fill='#27d5b4'/>
            <path id='Path_16132' data-name='Path 16132'
                  d='M49.493,2.3a3.133,3.133,0,1,1-3.132,3.133A3.133,3.133,0,0,1,49.493,2.3'
                  transform='translate(-35.19 -1.744)' fill='#26d4b4'/>
            <path id='Path_16133' data-name='Path 16133'
                  d='M46.948,1.42a3.133,3.133,0,1,1-3.133,3.133A3.133,3.133,0,0,1,46.948,1.42'
                  transform='translate(-33.257 -1.078)' fill='#26d4b4'/>
            <path id='Path_16134' data-name='Path 16134'
                  d='M44.315.741a3.133,3.133,0,1,1-3.133,3.133A3.133,3.133,0,0,1,44.315.741'
                  transform='translate(-31.259 -0.562)' fill='#25d4b5'/>
            <path id='Path_16135' data-name='Path 16135'
                  d='M41.6.273a3.133,3.133,0,1,1-3.133,3.133A3.133,3.133,0,0,1,41.6.273'
                  transform='translate(-29.202 -0.207)' fill='#24d3b5'/>
            <path id='Path_16136' data-name='Path 16136'
                  d='M38.825.023a3.133,3.133,0,1,1-3.132,3.133A3.133,3.133,0,0,1,38.825.023'
                  transform='translate(-27.092 -0.018)' fill='#23d3b6'/>
            <path id='Path_16137' data-name='Path 16137'
                  d='M35.994,0a3.133,3.133,0,1,1-3.133,3.133A3.133,3.133,0,0,1,35.994,0'
                  transform='translate(-24.943 0)' fill='#22d2b6'/>
            <path id='Path_16138' data-name='Path 16138'
                  d='M33.2.206a3.133,3.133,0,1,1-3.133,3.133A3.133,3.133,0,0,1,33.2.206'
                  transform='translate(-22.824 -0.156)' fill='#21d2b6'/>
            <path id='Path_16139' data-name='Path 16139'
                  d='M30.477.633a3.133,3.133,0,1,1-3.133,3.133A3.133,3.133,0,0,1,30.477.633'
                  transform='translate(-20.755 -0.481)' fill='#20d2b7'/>
            <path id='Path_16140' data-name='Path 16140'
                  d='M27.827,1.27A3.133,3.133,0,1,1,24.695,4.4,3.133,3.133,0,0,1,27.827,1.27'
                  transform='translate(-18.744 -0.964)' fill='#1fd1b7'/>
            <path id='Path_16141' data-name='Path 16141'
                  d='M25.264,2.11a3.133,3.133,0,1,1-3.133,3.133A3.133,3.133,0,0,1,25.264,2.11'
                  transform='translate(-16.798 -1.602)' fill='#1ed1b7'/>
            <path id='Path_16142' data-name='Path 16142'
                  d='M22.794,3.144a3.133,3.133,0,1,1-3.133,3.133,3.133,3.133,0,0,1,3.133-3.133'
                  transform='translate(-14.923 -2.386)' fill='#1dd1b8'/>
            <path id='Path_16143' data-name='Path 16143'
                  d='M20.428,4.361A3.133,3.133,0,1,1,17.3,7.494a3.133,3.133,0,0,1,3.133-3.133'
                  transform='translate(-13.128 -3.31)' fill='#1cd0b8'/>
            <path id='Path_16144' data-name='Path 16144'
                  d='M18.173,5.754A3.133,3.133,0,1,1,15.04,8.887a3.133,3.133,0,0,1,3.133-3.133'
                  transform='translate(-11.416 -4.367)' fill='#1bd0b8'/>
            <path id='Path_16145' data-name='Path 16145'
                  d='M16.04,7.313a3.133,3.133,0,1,1-3.133,3.133A3.133,3.133,0,0,1,16.04,7.313'
                  transform='translate(-9.797 -5.551)' fill='#1acfb9'/>
            <path id='Path_16146' data-name='Path 16146'
                  d='M14.037,9.03A3.133,3.133,0,1,1,10.9,12.163,3.133,3.133,0,0,1,14.037,9.03'
                  transform='translate(-8.277 -6.854)' fill='#19cfb9'/>
            <path id='Path_16147' data-name='Path 16147'
                  d='M12.173,10.894A3.133,3.133,0,1,1,9.04,14.027a3.133,3.133,0,0,1,3.133-3.133'
                  transform='translate(-6.862 -8.269)' fill='#18cfb9'/>
            <path id='Path_16148' data-name='Path 16148'
                  d='M10.458,12.9a3.133,3.133,0,1,1-3.133,3.133A3.133,3.133,0,0,1,10.458,12.9'
                  transform='translate(-5.56 -9.79)' fill='#17ceba'/>
            <path id='Path_16149' data-name='Path 16149'
                  d='M8.9,15.032a3.133,3.133,0,1,1-3.133,3.133A3.133,3.133,0,0,1,8.9,15.032'
                  transform='translate(-4.377 -11.409)' fill='#16ceba'/>
            <path id='Path_16150' data-name='Path 16150'
                  d='M7.507,17.287A3.133,3.133,0,1,1,4.374,20.42a3.133,3.133,0,0,1,3.133-3.133'
                  transform='translate(-3.32 -13.121)' fill='#15cdba'/>
            <path id='Path_16151' data-name='Path 16151'
                  d='M6.29,19.654a3.133,3.133,0,1,1-3.133,3.133A3.133,3.133,0,0,1,6.29,19.654'
                  transform='translate(-2.396 -14.918)' fill='#14cdbb'/>
            <path id='Path_16152' data-name='Path 16152'
                  d='M5.258,22.124a3.133,3.133,0,1,1-3.133,3.133,3.133,3.133,0,0,1,3.133-3.133'
                  transform='translate(-1.613 -16.792)' fill='#13cdbb'/>
            <path id='Path_16153' data-name='Path 16153'
                  d='M4.419,24.688a3.133,3.133,0,1,1-3.133,3.133,3.133,3.133,0,0,1,3.133-3.133'
                  transform='translate(-0.976 -18.738)' fill='#12ccbb'/>
            <path id='Path_16154' data-name='Path 16154'
                  d='M3.782,27.338A3.133,3.133,0,1,1,.649,30.471a3.133,3.133,0,0,1,3.133-3.133'
                  transform='translate(-0.493 -20.75)' fill='#11ccbc'/>
            <path id='Path_16155' data-name='Path 16155'
                  d='M3.356,30.063A3.133,3.133,0,1,1,.223,33.2a3.133,3.133,0,0,1,3.133-3.133'
                  transform='translate(-0.169 -22.818)' fill='#10cbbc'/>
            <path id='Path_16156' data-name='Path 16156'
                  d='M3.151,32.855A3.133,3.133,0,1,1,.018,35.988a3.133,3.133,0,0,1,3.133-3.133'
                  transform='translate(-0.014 -24.937)' fill='#0fcbbc'/>
            <path id='Path_16157' data-name='Path 16157'
                  d='M3.133,35.607A3.133,3.133,0,1,1,0,38.74a3.133,3.133,0,0,1,3.133-3.133'
                  transform='translate(0 -27.026)' fill='#0ecbbd'/>
            <path id='Path_16158' data-name='Path 16158'
                  d='M3.133,38.305A3.133,3.133,0,1,1,0,41.438a3.133,3.133,0,0,1,3.133-3.133'
                  transform='translate(0 -29.074)' fill='#0dcabd'/>
            <path id='Path_16159' data-name='Path 16159'
                  d='M3.133,41A3.133,3.133,0,1,1,0,44.136,3.133,3.133,0,0,1,3.133,41'
                  transform='translate(0 -31.122)' fill='#0ccabe'/>
            <path id='Path_16160' data-name='Path 16160'
                  d='M3.133,43.7A3.133,3.133,0,1,1,0,46.834,3.133,3.133,0,0,1,3.133,43.7'
                  transform='translate(0 -33.169)' fill='#0bcabe'/>
            <path id='Path_16161' data-name='Path 16161'
                  d='M3.133,46.4A3.133,3.133,0,1,1,0,49.532,3.133,3.133,0,0,1,3.133,46.4'
                  transform='translate(0 -35.217)' fill='#0ac9be'/>
            <path id='Path_16162' data-name='Path 16162'
                  d='M3.133,49.1A3.133,3.133,0,1,1,0,52.23,3.133,3.133,0,0,1,3.133,49.1'
                  transform='translate(0 -37.265)' fill='#09c9bf'/>
            <path id='Path_16163' data-name='Path 16163'
                  d='M3.133,51.794A3.133,3.133,0,1,1,0,54.927a3.133,3.133,0,0,1,3.133-3.133'
                  transform='translate(0 -39.312)' fill='#08c8bf'/>
            <path id='Path_16164' data-name='Path 16164'
                  d='M3.133,54.492A3.133,3.133,0,1,1,0,57.625a3.133,3.133,0,0,1,3.133-3.133'
                  transform='translate(0 -41.36)' fill='#07c8bf'/>
            <path id='Path_16165' data-name='Path 16165'
                  d='M3.133,57.19A3.133,3.133,0,1,1,0,60.323,3.133,3.133,0,0,1,3.133,57.19'
                  transform='translate(0 -43.408)' fill='#06c8c0'/>
            <path id='Path_16166' data-name='Path 16166'
                  d='M3.133,59.888A3.133,3.133,0,1,1,0,63.021a3.133,3.133,0,0,1,3.133-3.133'
                  transform='translate(0 -45.456)' fill='#05c7c0'/>
            <path id='Path_16167' data-name='Path 16167'
                  d='M3.133,62.586A3.133,3.133,0,1,1,0,65.719a3.133,3.133,0,0,1,3.133-3.133'
                  transform='translate(0 -47.503)' fill='#04c7c0'/>
            <path id='Path_16168' data-name='Path 16168'
                  d='M3.133,65.284A3.133,3.133,0,1,1,0,68.417a3.133,3.133,0,0,1,3.133-3.133'
                  transform='translate(0 -49.551)' fill='#03c6c1'/>
            <path id='Path_16169' data-name='Path 16169'
                  d='M3.133,67.981A3.133,3.133,0,1,1,0,71.114a3.133,3.133,0,0,1,3.133-3.133'
                  transform='translate(0 -51.598)' fill='#02c6c1'/>
            <path id='Path_16170' data-name='Path 16170'
                  d='M37.132,33.981A3.133,3.133,0,1,1,34,37.114a3.133,3.133,0,0,1,3.132-3.133'
                  transform='translate(-25.807 -25.792)' fill='#4a545f'/>
          </g>
        </g>
      </g>
    </svg>
  );
};
