/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { setSubmitButtonEnabled } from '../../actions/userExperienceActions';
import TextField from '../shared/TextField';
import MenuItem from '@mui/material/MenuItem';
import { connect } from 'react-redux';
import TextUtil from '../util/FormatTextUtil';
import CommonUtil from '../util/CommonUtil';
import Validator from '../util/Validator';
import { Field, reduxForm } from 'redux-form';
import FormUtil from './../util/FormUtil';
import IconUtil from './../util/IconUtil';
import LabelUtil from './../util/LabelUtil';
import _ from 'lodash';
import Select from '../shared/Select';
import Tooltip from '../shared/Tooltip';
import messages from '../../constants/messages';
import Checkbox from '../shared/Checkbox';


export const validate = values => Validator.validateBankingForm(values);

export class BankingFormContainer extends Component {

  componentDidUpdate(prevProps, prevState) {
    /* istanbul ignore else  */
    if (!_.isEqual(prevProps.validationHistory.giactAccountValid, this.props.validationHistory.giactAccountValid)) {

      const accountRoutingChangedSuccessfully = CommonUtil.allValuesTrue(this.props.validationHistory.giactAccountValid);
      const enableSubmit = FormUtil.shouldEnableSubmit(this.props) || !accountRoutingChangedSuccessfully;
      if (this.props?.userExperience?.enableFormSubmitButton !== enableSubmit) {
        this.props.dispatch(setSubmitButtonEnabled(enableSubmit));
      }
    }
  }

  render() {

    const { handleSubmit, canEdit, heading, t } = this.props;

    const label = LabelUtil.getLabel();

    const infoIcon = IconUtil.getIcon('InfoIcon', label.primaryColor);

    return (
      <form onSubmit={handleSubmit} className='profileBankingForm'>
        <div className='primaryBankingHolder'>
          <div className='secondaryTitle'>
            <span className='subHeading'>{heading}</span>
          </div>
          <Field
            label={t('Banking.AccountTypeLabel')}
            component={Select}
            disabled={canEdit}
            className='fullScreen alignBottom fullScreenRes'
            name='accountType'
            ref='accountType'>
            <MenuItem value='savings'>{t('Banking.Savings')}</MenuItem>
            <MenuItem value='checking'>{t('Banking.Checking')}</MenuItem>
          </Field>
          <Field
            label={t('Banking.RoutingNumberLabel')}
            component={TextField}
            disabled={canEdit}
            className='alignBottom halfScreenRes routingNumber'
            fullWidth={false}
            normalize={TextUtil.formatRoutingNumber}
            name='routingNumber'
            ref='routingNumber'
          />
          <Field
            label={t('Banking.BankAccountLabel')}
            component={TextField}
            disabled={canEdit}
            className='alignBottom halfScreenRes bankAccount'
            fullWidth={false}
            normalize={TextUtil.formatAccountNumber}
            name='account'
            ref='account'
          />
          <div className='tooltipHolder'>
            <Tooltip
                placement='left'
                title={t(this.props.tooltip)}
            >
              <p>{infoIcon}</p>
            </Tooltip>
          </div>
        </div>
      </form>
    );
  }
}

let BankingForm = reduxForm({
  form: 'bankingForm',
  validate,
  fields: [
    'account',
    'routingNumber',
    'accountType',
    'useForAll'
  ],
  destroyOnUnmount: false,
  enableReinitialize: true
})(BankingFormContainer);

function mapStateToProps(state, ownProps) {

  const banking = state?.verification?.fullInfo?.banking;
  const bankingType = banking?.[ownProps.type];

  const initialValues = {
    account: bankingType?.account ?? '',
    routingNumber: bankingType?.routing ?? '',
    accountType: bankingType?.type ?? '',
    bankAccountKind: ownProps.type
  }
  const tooltip = messages.tooltips.banking[ownProps.tooltip];

  return {
    initialValues,
    tooltip
  };

}

BankingForm = connect(mapStateToProps)(BankingForm);

export default BankingForm;
