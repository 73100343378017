/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, {Component} from 'react';

export default class VisaPaymentMethod extends Component {

  render() {
    return (
      <svg id='VisaPaymentMethod' xmlns='http://www.w3.org/2000/svg'  width='100' height='60' viewBox='0 0 100 60' className='visaPaymentMethod'>
        <defs>
          <clipPath id='clipPath'>
            <path id='Path_12648' data-name='Path 12648' d='M0-55.993A4.008,4.008,0,0,1,4-60H96a4,4,0,0,1,4,4.007V-4.007A4.008,4.008,0,0,1,96,0H4A4,4,0,0,1,0-4.007Z' fill='none' clipRule='evenodd'/>
          </clipPath>
          <clipPath id='clipPath-2'>
            <path id='Path_12647' data-name='Path 12647' d='M0,0H100V-60H0Z' fill='none'/>
          </clipPath>
          <clipPath id='clipPath-3'>
            <path id='Path_12651' data-name='Path 12651' d='M41.49-20.453l3.075-19.074H49.48L46.405-20.453H41.49' fill='none' clipRule='evenodd'/>
          </clipPath>
          <clipPath id='clipPath-5'>
            <path id='Path_12654' data-name='Path 12654' d='M64.233-39.059a12.15,12.15,0,0,0-4.4-.8c-4.859,0-8.28,2.588-8.308,6.295-.031,2.742,2.441,4.271,4.3,5.182,1.915.934,2.559,1.532,2.551,2.367-.013,1.277-1.529,1.863-2.942,1.863a9.844,9.844,0,0,1-4.63-1l-.632-.3-.687,4.27a14.834,14.834,0,0,0,5.476,1.017c5.164,0,8.52-2.557,8.559-6.515.018-2.174-1.292-3.822-4.128-5.183-1.718-.885-2.772-1.47-2.76-2.366,0-.793.891-1.641,2.816-1.641a8.621,8.621,0,0,1,3.678.733l.441.218.666-4.135' fill='none' clipRule='evenodd'/>
          </clipPath>
          <clipPath id='clipPath-7'>
            <path id='Path_12657' data-name='Path 12657' d='M70.777-27.216c.407-1.1,1.958-5.332,1.958-5.332-.029.05.405-1.1.653-1.82l.332,1.645s.943,4.553,1.137,5.506Zm6.063-12.292h-3.8a2.428,2.428,0,0,0-2.576,1.581l-7.3,17.48H68.33s.842-2.352,1.033-2.868c.563,0,5.579.01,6.3.01.147.666.6,2.858.6,2.858h4.562L76.841-39.508Z' fill='none' clipRule='evenodd'/>
          </clipPath>
          <clipPath id='clipPath-9'>
            <path id='Path_12660' data-name='Path 12660' d='M37.367-39.511l-4.811,13-.515-2.643a14.647,14.647,0,0,0-6.808-8l4.4,16.682,5.2,0,7.74-19.038H37.367' fill='none' clipRule='evenodd'/>
          </clipPath>
          <clipPath id='clipPath-11'>
            <path id='Path_12663' data-name='Path 12663' d='M28.091-39.524H20.165l-.065.4c6.167,1.58,10.248,5.394,11.941,9.978l-1.722-8.764a2.064,2.064,0,0,0-2.228-1.609' fill='none' clipRule='evenodd'/>
          </clipPath>
        </defs>
        <g id='Group_20265' data-name='Group 20265' transform='translate(-538 -738)'>
          <g id='Group_20172' data-name='Group 20172' transform='translate(538 798)'>
            <g id='Group_20161' data-name='Group 20161' clipPath='url(#clipPath)'>
              <g id='Group_20160' data-name='Group 20160' clipPath='url(#clipPath-2)'>
                <path id='Path_12646' data-name='Path 12646' d='M-5-65H105V5H-5Z' fill='#25337a'/>
              </g>
            </g>
            <g id='Group_20163' data-name='Group 20163' clipPath='url(#clipPath-3)'>
              <g id='Group_20162' data-name='Group 20162' clipPath='url(#clipPath-2)'>
                <path id='Path_12649' data-name='Path 12649' d='M36.49-44.527H54.48v29.074H36.49Z' fill='#fffefe'/>
              </g>
            </g>
            <g id='Group_20165' data-name='Group 20165' clipPath='url(#clipPath-5)'>
              <g id='Group_20164' data-name='Group 20164' clipPath='url(#clipPath-2)'>
                <path id='Path_12652' data-name='Path 12652' d='M44.485-44.859H69.233V-15.17H44.485Z' fill='#fffefe'/>
              </g>
            </g>
            <g id='Group_20167' data-name='Group 20167' clipPath='url(#clipPath-7)'>
              <g id='Group_20166' data-name='Group 20166' clipPath='url(#clipPath-2)'>
                <path id='Path_12655' data-name='Path 12655' d='M58.167-44.508H85.818v29.061H58.167Z' fill='#fffefe'/>
              </g>
            </g>
            <g id='Group_20169' data-name='Group 20169' clipPath='url(#clipPath-9)'>
              <g id='Group_20168' data-name='Group 20168' clipPath='url(#clipPath-2)'>
                <path id='Path_12658' data-name='Path 12658' d='M20.233-44.511H47.575V-15.47H20.233Z' fill='#fffefe'/>
              </g>
            </g>
            <g id='Group_20171' data-name='Group 20171' clipPath='url(#clipPath-11)'>
              <g id='Group_20170' data-name='Group 20170' clipPath='url(#clipPath-2)'>
                <path id='Path_12661' data-name='Path 12661' d='M15.1-44.524H37.041v20.374H15.1Z' fill='#ec982d'/>
              </g>
            </g>
          </g>
        </g>
      </svg>


    );
  }
};
