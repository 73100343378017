/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import IconButton from '../shared/IconButton';
import { NavLink } from 'react-router-dom';
import IconUtils from '../util/IconUtil';
import MainMenuItem from './MainMenuItem';
import routes from '../../constants/routes';
import UserUtil from '../util/UserUtil';
import {
  toggleMainMenu
} from '../../actions/userExperienceActions';
import { getCsSettings, getMerchantSettings } from '../../actions/merchantSettingsActions';
import Tooltip from '../shared/Tooltip';
import withAppData from '../../hoc/withAppData';
import { passwordStyle, mainMenuTopItemStyles } from '../../jss/inlineStyles';
import { mainMenuSpacerStyles, mainMenuStyles, } from '../../jss/mainMenuStyles';
import Box from '@mui/material/Box';
import LabelUtil from '../util/LabelUtil';
import classnames from 'classnames';

export class MainMenu extends Component {

  constructor(props) {
    super(props);
    this.handleMenuIconClicked = this.handleMenuIconClicked.bind(this);
    this.renderMenuHeader = this.renderMenuHeader.bind(this);
    this.renderMenuItems = this.renderMenuItems.bind(this);
    this.loadData = this.loadData.bind(this);

    this.state = {
      activeTooltip: null
    };
  }

  componentDidMount() {
    this.loadData(this.props);

    this.unlisten = this.props.history.listen(() => {
      this.setState({ activeTooltip: null });
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  loadData(props) {
    const { auth, user, merchantSettings } = props;

    if (auth?.isSpr && merchantSettings && !merchantSettings.csSettings) {
      props.dispatch(getCsSettings(user));
    }
    if (!merchantSettings?.merchantSettings && !merchantSettings?.isProcessing) {
      props.dispatch(getMerchantSettings(user));
    }
  }

  handleMenuIconClicked() {
    this.props.dispatch(toggleMainMenu());
  }

  /**
   * Compares item's path to current path
   * to determined if tab is active
   * @param {*} appRoutePrefix string
   * @param {*} menuPath string
   * @param {*} actualPath string
   */
  isActive(appRoutePrefix, menuPath, actualPath) {

    if (appRoutePrefix + menuPath !== actualPath) {

      if (actualPath === appRoutePrefix + routes.account.root
        && menuPath === routes.account.root + routes.account.personal) {
        return true;
      }

      if (actualPath === appRoutePrefix && menuPath === routes.activity.root + routes.activity.dashboard ||
        actualPath === appRoutePrefix + routes.activity.root
        && menuPath === routes.activity.root + routes.activity.dashboard) {
        return true;
      }

      if (actualPath === appRoutePrefix + routes.business.root
        && menuPath === routes.business.root + routes.business.employees) {
        return true;
      }

      if (actualPath === appRoutePrefix + routes.business.root
        && menuPath === routes.business.root + routes.business.reputation) {
        return true;
      }

      if (actualPath === appRoutePrefix + routes.inventory.root
        && menuPath === routes.inventory.root + routes.inventory.items) {
        return true;
      }

      //Business Settings pages
      const {account, account: {root: accountRoot} } = routes;
      if (menuPath === accountRoot + account.settings
        && ( actualPath === appRoutePrefix + accountRoot + account.salesSettings
          || actualPath === appRoutePrefix + accountRoot + account.business
          || actualPath === appRoutePrefix + accountRoot + account.store
          || actualPath === appRoutePrefix + accountRoot + account.fullTin
          || actualPath === appRoutePrefix + accountRoot + account.funding
          || actualPath === appRoutePrefix + accountRoot + account.alerts
          || actualPath === appRoutePrefix + accountRoot + account.integrations
          || actualPath === appRoutePrefix + accountRoot + account.devices
          || actualPath === appRoutePrefix + accountRoot + account.pci
          || actualPath === appRoutePrefix + accountRoot + account.communicationSettings
        )) {
        return true;
      }

      const {inventory, inventory: {root: inventoryRoot} } = routes;
      if (menuPath === inventoryRoot + inventory.items
        && ( actualPath === appRoutePrefix + inventoryRoot + inventory.categories
          || actualPath === appRoutePrefix + inventoryRoot + inventory.modifiers
          || actualPath === appRoutePrefix + inventoryRoot + inventory.discounts
          || actualPath === appRoutePrefix + inventoryRoot + inventory.archivedItems
        )) {
        return true;
      }

      return false;
    } else {
      return true;
    }

  }

  renderMenuHeader(handleMenuIconClicked, open) {

    const {auth, user, t} = this.props;

    const selectedMerchantAccount = user.data && user.data.merchantAccounts && user.data.merchantAccounts[0] &&
      _.find(user.data.merchantAccounts, {mea_id: Number(user.selectedMerchantAccount)});

    const closedAccountIcon = IconUtils.getIcon('ProfileIcon', '#FF0000');
    const headerItems = [];

    if (selectedMerchantAccount && selectedMerchantAccount.active === false) {
      headerItems.push(
        <div className='closedAccountIndicator'>
          <span className='closedAccountText'>{t('Validator.ClosedAccountTitle')}</span>
          <i>{closedAccountIcon}</i>
        </div>);
    }

    return headerItems && headerItems.length >= 1 ? (
      <List className='mainMenuHeader'>
        {headerItems.map((item, index) => {
            return (
              <ListItem
                className='headerItem'
                key={index}
                disableGutters
                sx={mainMenuTopItemStyles}
              >
                <ListItemIcon onClick={handleMenuIconClicked}>
                  <IconButton className='mainMenuMenuIcon header' style={{...passwordStyle, paddingLeft: 0}} size='large' color={'error'}>
                    {item.props.children[1]}
                  </IconButton>
                </ListItemIcon>
                <ListItemText className='headerItemText' primary={open ? item.props.children[0] : null}/>
              </ListItem>
            );
          })
        }
      </List>
    ): null;
  }

  /**
   * Maps through the items for the selected user
   * and returns a menu items for each.
   * @returns JSX or null
   */
  renderMenuItems() {
    const { history, user, auth, userExperience, merchantSettings, t } = this.props;
    const { mainMenuOpen, historicHardwareMode } = userExperience;
    const label = globalApplicationLabel;
    const appRoutePrefix = label && label.path;
    const userType = UserUtil.userType(user);
    const processorType = UserUtil.processorType(user);
    const displayDividersClass = (!historicHardwareMode && !auth.isReport) ? 'displayDividers' : '';
    const menuItems = UserUtil.userMenu(user, historicHardwareMode, auth, merchantSettings, displayDividersClass);

    const menuSections = menuItems.map((item, index) => {

      const itemPath = item && item.path;
      const pathname = history && history.location && history.location.pathname;
      const active = this.isActive(appRoutePrefix, itemPath, pathname);

      const itemShouldBeDisplayed =
        (
          (item.show
            && _.includes(item.show.byRole, auth.role)
            && _.includes(item.show.byUserType, userType)
            && (!item.show.byProcessorType || _.includes(item.show.byProcessorType, processorType))
            && ((historicHardwareMode && item.showWhenHistoricHardwareMode) || historicHardwareMode === false)
          )

          || (auth.isSpr
            && _.includes(item.show.byUserType, userType)
            && (!item.show.byProcessorType || _.includes(item.show.byProcessorType, processorType))
          )

          || (item.settingAllowed && auth[item.settingAllowed])

        ) && (!_.has(item, 'mandatorySetting') || item.mandatorySetting);

      const labelColor = LabelUtil.getLabelColor();
      const MainMenuItemComponent = (
        <MainMenuItem
          active={active}
          content={t(item.text)}
          iconColor={active ? labelColor : '#404143'}
          iconName={item.icon}
          label={label.abbreviation}
        />
      );

      const MainMenuItemComponentWithTooltip = (
        <Tooltip
          arrow
          placement='right'
          primaryColor
          title={t(item.text)}
          open={this.state.activeTooltip === item.text}
          onOpen={() => this.setState({ activeTooltip: item.text })}
          onClose={() => this.setState({ activeTooltip: null })}
          disableFocusListener={mainMenuOpen}
          disableHoverListener={mainMenuOpen}
        >
          {MainMenuItemComponent}
        </Tooltip>
      );

      return itemShouldBeDisplayed ? (
        <NavLink
          activeClassName='active'
          className={`${item.text} mainMenuItemLink ${item.divider ? item.divider : ''}`}
          key={index}
          name={item.text}
          to={appRoutePrefix + item.path}
          onClick={this.props.closeMainMenu}
        >
          {MainMenuItemComponentWithTooltip}
        </NavLink>
      ) : null;
    });

    return (
      <List className='mainMenuItems'>
       {menuSections}
      </List>
    )
  }

  render() {
    const {handleMenuIconClicked, renderMenuHeader, renderMenuItems, props} = this;
    const {userExperience} = props;

    const {mainMenuOpen} = userExperience;

    return (
      <>
        <Box className='mainMenuSpacer' sx={mainMenuSpacerStyles}/>
        <Drawer
          className={`mainMenu ${mainMenuOpen ? 'drawerOpen' : 'drawerClosed'} mobileLayout`}
          PaperProps={{ className: 'paperRoot', elevation: 0 }}
          variant='permanent'
          sx={mainMenuStyles(userExperience.headerHeight)}
        >
          <div className='WidthContainer'>
            {renderMenuHeader(handleMenuIconClicked, mainMenuOpen)}
            {renderMenuItems()}
          </div>
        </Drawer>
      </>
    );
  }
}

MainMenu.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.shape).isRequired,
  accountProfile: PropTypes.objectOf(PropTypes.shape).isRequired,
  auth: PropTypes.objectOf(PropTypes.shape).isRequired,
  history: PropTypes.objectOf(PropTypes.shape).isRequired,
  userExperience: PropTypes.objectOf(PropTypes.shape).isRequired,
};

export default withAppData(MainMenu);
