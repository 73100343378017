
/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import moment from 'moment';
import { change, getFormValues, reset } from 'redux-form';
import PaymentMethodSummary from '../shared/enhancedInvoices/PaymentMethodSummary';
import {
  getCustomerPaymentMethods,
  postPaymentMethodWithToken,
  updateCustomerDataToken
} from '../../actions/customerActions';
import {
  payInvoice,
  updateInvoiceToPay,
  setInvoice,
  setClpInvoiceOriginal,
  setClpInvoiceWithoutCd} from '../../actions/invoicesActions';
import Modal from '../shared/Modal';
import Select from '../shared/Select';
import UpdateSpinner from '../UpdateSpinner';
import {MenuItem} from '@mui/material';
import numeral from 'numeral';
import Button from '../shared/Button';
import EditAddPaymentMethodForm
  from './paymentMethods/EditAddPaymentMethodForm';
import DialogActions from '@mui/material/DialogActions';
import { isJSON } from '../util/CommonUtil';
import {
  setModalVisibility,
  togglePaymentMethodsAddDialog,
  togglePaymentMethodsDeleteDialog,
  togglePaymentMethodsEditDialog
} from '../../actions/userExperienceActions';
import actionTypes from '../../constants/actionTypes';
import {submit} from 'redux-form';
import {connect} from 'react-redux';
import { CircularProgress } from '@mui/material';
import Loading from '../Loading';
import { toastr } from 'react-redux-toastr';
import CustomerUtil from '../util/CustomerUtil';
import FormatTextUtils from '../util/FormatTextUtil';
import TipSelector from '../shared/enhancedInvoices/TipSelector';
import PayAndSaveMethodForm from './PayAndSaveForm';
import IconUtils from '../util/IconUtil';
import ApplePayButton from '../ApplePayButton';
import BrowserUtil from '../util/BrowserUtil';
import { roundToTwoDecimals } from '../../components/util/CommonUtil';
import ReskinMessageDialog from '../shared/MessageDialog';
import { processCreditCardVoidWithToken } from '../../actions/transactionsActions';
import messages from '../../constants/messages';
import PaymentUtil from '../util/PaymentUtil';
import DateUtil from '../util/DateUtil';
import { Trans } from 'react-i18next';
import TextUtil from '../util/FormatTextUtil';
import { t } from 'i18next';
import { validateRewardCodeToken } from '../../actions/loyaltyVpcActions';
import InvoiceUtil from '../util/InvoiceUtil';
import LoyaltyReward from './LoyaltyReward';
import LabelUtil from '../util/LabelUtil';
import UserUtil from '../util/UserUtil';
import CustomToggle from '../shared/Toggle';
import EnrollInLoyaltyForm from './EnrollInLoyaltyForm';
import applicationConstants from '../../constants/applicationConstants';
import paymentTypes from '../../constants/paymentTypes';
import HighAmountModal from '../business/HighAmountModal';
import FilterUtil from '../util/FilterUtil';
import { customerPaymentInvoiceDiscountBtn } from '../../jss/inlineStyles';
import InvoicePaidImg from './images/invoicePaidImg';
import TermsAndConditionToggle from '../shared/ach/TermsAndConditionToggle';
import InvoiceDetailPaid from './InvoiceDetailPaid';
import Typography from '@mui/material/Typography';
import {
  PaymentErrorModalText,
  paymentPageDetailSummaryMain,
  paymentPageDetailSummaryPrice,
  paymentPageDetailSummaryReward,
  paymentPageDetailSummarySub,
  paymentPageDetailSummarySubTitle,
  paymentPageDetailSummaryTitle,
  paymentPageDetailTitle, paymentPagePaidAchNotice, paymentPagePayDialogActions
} from '../../jss/invoicePaymentPageStyles';
import _ from 'lodash';

export class InvoiceDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      paymentError: null,
      errorModal: null,
      openEditPayment: false,
      openAddPayment: false,
      paymentMethods: [],
      defaultPaymentMethod: null,
      selectedPaymentMethodToShow: null,
      selectedPaymentMethod: 'saved',
      paid: false,
      paying: false,
      partialPayment: null,
      paymentMethodUsed: null,
      tip: {
        value: 0,
        type: ''
      },
      totalAmount: 0,
      totalTip: 0,
      rewardDiscount: null,
      rewardLoaded: null,
      itemizedCart: InvoiceUtil.initialItemizedCartObject(),
      dataInvoice : {},
      avsFailureCount: 0,
      rewardDiscountShow: 0,
      loyaltyModalOpen: false,
      enrollInLoyaltyValue: false,
      achFormValues: null
    };

    this.loadData = this.loadData.bind(this);
    this.handleEditPayment = this.handleEditPayment.bind(this);
    this.handleEditPaymentChange = this.handleEditPaymentChange.bind(this);
    this.handleAddNewPayment = this.handleAddNewPayment.bind(this);
    this.addEditMethod = this.addEditMethod.bind(this);
    this.handleCloseDialogs = this.handleCloseDialogs.bind(this);
    this.saveClick = this.saveClick.bind(this);
    this.handleAddDialogToggle = this.handleAddDialogToggle.bind(this);
    this.handlePayInvoice = this.handlePayInvoice.bind(this);
    this.handleApplePay = this.handleApplePay.bind(this);
    this.handleAfterPay = this.handleAfterPay.bind(this);
    this.setTip = this.setTip.bind(this);
    this.getTotal = this.getTotal.bind(this);
    this.payAndSaveClick = this.payAndSaveClick.bind(this);
    this.saveAndPay = this.saveAndPay.bind(this);
    this.confirmPartialPayment = this.confirmPartialPayment.bind(this);
    this.voidTransaction = this.voidTransaction.bind(this);
    this.submitLoyaltyRewardCodeForm = this.submitLoyaltyRewardCodeForm.bind(this);
    this.removeReward = this.removeReward.bind(this);
    this.updateInvoiceWithReward = this.updateInvoiceWithReward.bind(this);
    this.generateInvoiceRequestJson = this.generateInvoiceRequestJson.bind(this);
    this.calculateReward = this.calculateReward.bind(this);
    this.setInitialReward = this.setInitialReward.bind(this);
    this.mapItemizedItems = this.mapItemizedItems.bind(this);
    this.openLoyaltyModal = this.openLoyaltyModal.bind(this);
    this.closeLoyaltyModal = this.closeLoyaltyModal.bind(this);
    this.activatePrePopulatedReward = this.activatePrePopulatedReward.bind(this);
    this.removePrepopulatedReward = this.removePrepopulatedReward.bind(this);
    this.confirmEnrollInLoyalty = this.confirmEnrollInLoyalty.bind(this);
    this.submitEnrollInLoyaltyForm = this.submitEnrollInLoyaltyForm.bind(this);
    this.toggleEnrollInLoyaltyValue = this.toggleEnrollInLoyaltyValue.bind(this);
    this.getAvsFailureCount = this.getAvsFailureCount.bind(this);
    this.openThreeAvsErrorDialog = this.openThreeAvsErrorDialog.bind(this);
    this.closeHighTransactionAmountDialog = this.closeHighTransactionAmountDialog.bind(this);
    this.openHighTransactionAmountDialog = this.openHighTransactionAmountDialog.bind(this);
    this.IsHighTransactionAmount = this.IsHighTransactionAmount.bind(this);
    this.validateApplePay = this.validateApplePay.bind(this);
  }

  async componentDidMount() {

    this.mapItemizedItems();
    this.setInitialReward();
    this.getTotal();

    await this.loadData(this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedPaymentMethodToShow, dataInvoice, paid } = this.state
    const { invoices, merchantSettings, dispatch, cardOnFileFormValues, userExperience } = this.props;
    const { addPaymentMethodDialog } = userExperience
    const { selectedClpInvoiceOriginal } = invoices;

    const selectedPaymentMethodChanged = prevState?.selectedPaymentMethodToShow?.type !== selectedPaymentMethodToShow?.type;
    const selectedInputChanged = prevProps?.cardOnFileFormValues?.methodType !== cardOnFileFormValues?.methodType;

    const updateTriggerLogic = () => {

      const isSelectingDifferentAchPaymentMethod = !addPaymentMethodDialog && selectedPaymentMethodToShow && selectedPaymentMethodToShow.type === 'ach';
      const isAddingNewAchPaymentMethodWithSavedAch= addPaymentMethodDialog && cardOnFileFormValues?.methodType === 'bankingAccount'
      const isAddingNewAchPaymentMethodWithNoSavedAch = !selectedPaymentMethodToShow && cardOnFileFormValues?.methodType === 'bankingAccount';

      const isSelectingDifferentCreditCardPaymentMethod = !addPaymentMethodDialog && selectedPaymentMethodToShow && selectedPaymentMethodToShow.type !== 'ach';
      const isAddingNewCreditCardPaymentMethodWithSavedCard = addPaymentMethodDialog && cardOnFileFormValues?.methodType !== 'bankingAccount';
      const isAddingNewCreditCardPaymentMethodWithNoSavedCard = !selectedPaymentMethodToShow && cardOnFileFormValues?.methodType !== 'bankingAccount';

      const addingNewAch = isSelectingDifferentAchPaymentMethod || isAddingNewAchPaymentMethodWithSavedAch || isAddingNewAchPaymentMethodWithNoSavedAch
      const addingNewCard = isSelectingDifferentCreditCardPaymentMethod || isAddingNewCreditCardPaymentMethodWithSavedCard || isAddingNewCreditCardPaymentMethodWithNoSavedCard;

      const { cashDiscountApplied } = InvoiceUtil.getCashDiscountSettings(selectedClpInvoiceOriginal, merchantSettings);
      if (cashDiscountApplied) {
        const noCashDiscountInvoice = InvoiceUtil.createsInvoiceWithoutCd(selectedClpInvoiceOriginal);
        dispatch(setClpInvoiceWithoutCd(noCashDiscountInvoice));
        if (addingNewAch) {
          dispatch(setInvoice(noCashDiscountInvoice));
          this.setState({
            dataInvoice: noCashDiscountInvoice,
            avsFailureCount: this.getAvsFailureCount(noCashDiscountInvoice)
          });
        }
        if (addingNewCard) {
          dispatch(setInvoice(selectedClpInvoiceOriginal));
          this.setState({
            dataInvoice: selectedClpInvoiceOriginal,
            avsFailureCount: this.getAvsFailureCount(selectedClpInvoiceOriginal)
          });
        }
      }
    };

    if (!paid && (selectedPaymentMethodChanged || selectedInputChanged)) {
      updateTriggerLogic();
    }

    if (!paid && (prevProps.invoices.selectedClpInvoiceOriginal !== selectedClpInvoiceOriginal)) {
      updateTriggerLogic();
    }

    if (!paid && (prevState.dataInvoice !== dataInvoice)) {
      this.getTotal();
    }

  }

  mapItemizedItems() {
    const { invoice } = this.props;
    const  { itemizedCart } = this.state;

    const updateItemizedCart = {};
    for (let key in itemizedCart) {
      if( invoice.hasOwnProperty(key)) {
        updateItemizedCart[key] = invoice[key];
      }
    }

    this.setState(
      {
        itemizedCart: { ...itemizedCart, ...updateItemizedCart },
        dataInvoice: invoice,
        avsFailureCount: this.getAvsFailureCount(invoice)
      },
      this.getTotal
    );
  }

  setInitialReward() {
    const { invoice, merchantSettings } = this.props;
    const loyalty_vpc = merchantSettings?.merchantSettings?.loyalty_vpc || false;
    if(loyalty_vpc) {
      invoice?.receipt_discount_name?.map((disc, discIndex) =>{
        if(invoice?.receipt_discount_type[discIndex] === 'Loyalty Discount') {
          this.setState({rewardDiscount: { amount: Number(invoice?.receipt_discount_amt[discIndex]), type: 'dollar'}, rewardLoaded: Number(invoice?.receipt_discount_amt[discIndex])})
        }
      });
    }
  }

  openLoyaltyModal() {
    this.setState({loyaltyModalOpen: true});
  }

  closeLoyaltyModal() {
    this.setState({loyaltyModalOpen: false});
  }

  generateInvoiceRequestJson = (type) => {
    const { invoices, merchantSettings }  = this.props;
    const { rewardDiscount } = this.state;
    const selectedInvoice = invoices.selectedClpInvoiceOriginal;

    let itemizedCart = {};
    let rewardFormatted = {};
    let cart = InvoiceUtil.buildsCartFromInvoice(selectedInvoice);

    let baseInvoice = {
      type: 'customer_landing_page',
      invoice: {},
    }

    cart = InvoiceUtil.recalculateCart(cart, baseInvoice, {});

    const merchant = merchantSettings.merchantSettings;
    const hasCashDiscount = !!(numeral(selectedInvoice?.service_fee_amt).value());

    baseInvoice.cashDiscounting = hasCashDiscount;
    baseInvoice.cashDiscountingAmount = 0;

    if (hasCashDiscount) {
      const amountToCalculateServiceFee = cart.sub_total_pre_discounts || numeral(cart.sub_total_amt).value();
      const percentCD = numeral(merchant?.cash_discount_amount_percent).value();
      const fixedCD = numeral(merchant?.cash_discount_fixed_amount).value();
      baseInvoice.cashDiscountingAmount = FormatTextUtils.formatCashDiscount(fixedCD, percentCD, amountToCalculateServiceFee)
    }

    if (type === 'remove') {
      this.setState({ rewardDiscount: null }, () => {this.getTotal(selectedInvoice)});
      const loyaltyVpcDiscountIndex =
        cart.receipt_discount_name.indexOf('Loyalty Reward');
      cart = InvoiceUtil.deleteDiscount(
        loyaltyVpcDiscountIndex,
        cart
      );
      itemizedCart = InvoiceUtil.recalculateCart(cart, baseInvoice, {});
      this.setState({itemizedCart});
    } else {
      itemizedCart = InvoiceUtil.recalculateCart(
        cart,
        baseInvoice,
        rewardDiscount,
      );
      this.setState({itemizedCart});
      rewardFormatted = {
        type: 'loyalty',
        code: rewardDiscount?.code,
        amount: itemizedCart?.loyalty_discount_amt,
        name: 'Loyalty Reward'
      };
      baseInvoice.invoice.express_discounts = [rewardFormatted];
    }

    baseInvoice.invoice.amount = Number(itemizedCart.total_amt) > 0  ? itemizedCart.total_amt : 0;
    baseInvoice.invoice.sub_total_amount = itemizedCart.sub_total_amt;
    baseInvoice.invoice.tax_amount = Number(itemizedCart.tax_amt) > 0 ? itemizedCart.tax_amt : 0;
    baseInvoice.invoice.sub_total_amount = Number(baseInvoice.invoice.sub_total_amount) > 0 ? baseInvoice.invoice.sub_total_amount : 0;
    itemizedCart.sub_total_amt = Number(itemizedCart.sub_total_amt) > 0 ? itemizedCart.sub_total_amt : 0;

    if (itemizedCart?.item_ids) {
      const itemizedCartInvoice = { ...invoices.selectedInvoice, ...itemizedCart };
      this.setState(
        { dataInvoice: itemizedCartInvoice, avsFailureCount: this.getAvsFailureCount(itemizedCartInvoice) },
        () => {this.getTotal(invoices.selectedInvoice)}
      );
    }


    delete baseInvoice.cashDiscounting;
    delete baseInvoice.cashDiscountingAmount;

    return baseInvoice;
  }

  async updateInvoiceWithReward(type){
    const { dispatch, token, invoices}  = this.props;
    const { selectedClpInvoiceOriginal } = invoices;
    const invoiceId = selectedClpInvoiceOriginal?.id;
    const payload = this.generateInvoiceRequestJson(type);
    delete payload.cashDiscounting;
    delete payload.cashDiscountingAmount;
    const updatedInvoice = await dispatch(updateInvoiceToPay(token, invoiceId, payload));
    dispatch(setClpInvoiceOriginal(updatedInvoice.response))
  }

  removeReward() {
    this.updateInvoiceWithReward('remove');
  }

  activatePrePopulatedReward() {
    const loyaltyInfo = this.props.customers.customerPaymentData.loyalty_vpc_status;
    const rewardCode = loyaltyInfo.reward_code;
    const rewardDiscount = {amount: loyaltyInfo.reward_amount, type: loyaltyInfo.reward_amount_type, code: rewardCode};
    this.props.dispatch(reset('loyaltyVpcApplyBoxForm'));

    this.setState({rewardDiscount: {...rewardDiscount, code: rewardCode}}, () => {
      this.getTotal();
      this.updateInvoiceWithReward();
      this.props.dispatch(change('loyaltyVpcApplyBoxForm', 'rewardCode', rewardCode))
    });
  }

  removePrepopulatedReward() {
    this.removeReward();
    this.props.dispatch(reset('loyaltyVpcApplyBoxForm'));
  }

  async submitLoyaltyRewardCodeForm(values) {
    const { dispatch, token, invoices, customers } = this.props;
    const customerInfo = customers?.customerPaymentData;
    try {
      const result = await dispatch(validateRewardCodeToken(values.rewardCode, token, customerInfo?.id));
      const { response : rewardDiscount } = result;
      if(rewardDiscount !== ''){
        this.setState({rewardDiscount: {...rewardDiscount, code: values.rewardCode}}, this.getTotal);
        this.updateInvoiceWithReward();
      } else {
        dispatch(reset('loyaltyVpcApplyBoxForm'));
        toastr.error(t('LoyaltyPrograms.Clp.InvalidReward'));
      }
    } catch (error) {
      dispatch(reset('loyaltyVpcApplyBoxForm'));
      toastr.error(t('LoyaltyPrograms.Clp.Error'));
    }
  }

  async loadData(props) {
    const {token, decodedToken, dispatch, invoices, merchantSettings} = props;
    const {selectedInvoice} = invoices;
    const {isInvoiceFromSeries} = selectedInvoice?.statusList || {};
    const {auto_pay, payment_method_last4} = selectedInvoice?.series || {};
    const reward = selectedInvoice?.loyalty_info;
    const achEnabled = merchantSettings?.merchantSettings?.ach_enabled;
    let selectedPaymentMethodToShow;

    if (token && decodedToken) {
      const res = await dispatch(getCustomerPaymentMethods(token));

      const paymentMethods = FilterUtil.filterPaymentMethodsAch(res?.response?.payment_methods, achEnabled);

      if (paymentMethods?.length) {

        const defaultPaymentMethod = paymentMethods.find((p) => p.is_default);

        if (isInvoiceFromSeries && auto_pay) {

          const seriesPaymentMethod = paymentMethods.find((p) => p.last4 === payment_method_last4);

          if (seriesPaymentMethod) {
            selectedPaymentMethodToShow = seriesPaymentMethod;
          } else {
            if (defaultPaymentMethod) {
              selectedPaymentMethodToShow = defaultPaymentMethod;
            } else {
              selectedPaymentMethodToShow = paymentMethods[0];
            }
          }

        } else {

          if (defaultPaymentMethod) {
            selectedPaymentMethodToShow = defaultPaymentMethod;
          } else {
            selectedPaymentMethodToShow = paymentMethods[0];
          }

        }

        this.setState({
          paymentMethods,
          defaultPaymentMethod,
          selectedPaymentMethodToShow
        });
      }

      if (reward?.reward_code) {
        const loyaltyRewardIndex = selectedInvoice?.receipt_discount_name.findIndex((discount) => discount === 'Loyalty Reward');

        if (loyaltyRewardIndex >= 0) {
          const loyaltyRewardAmount = JSON.parse(selectedInvoice?.receipt_discount_info[loyaltyRewardIndex]).amount;
          this.setState({rewardDiscountShow: loyaltyRewardAmount});
        }
      }

    }
  }

  handleEditPayment() {
    this.setState({
      selectedPaymentMethod: 'saved',
      openEditPayment: !this.state.openEditPayment,
    })
  }

  async voidTransaction() {
    this.setState({ loading: true });
    const { userExperience: { modalVisibility: { payment: { response } } }, invoices: { selectedInvoice }, token, dispatch  } = this.props;
    const { uniq_id } = response;
    const { item_ids, item_quantity, sold_by } = selectedInvoice;
    const [, id] = uniq_id.split('_');

    const voidPayload = { id, item_ids, item_quantity, sold_by };

    try {
      await dispatch(processCreditCardVoidWithToken(voidPayload, token));
      dispatch(setModalVisibility('hidden'));
      this.setState({loading: false});
    } catch(e) {
      toastr.error('Error', e);
    }
  }

  handleAddNewPayment() {
    this.handleEditPayment();
    this.handleAddDialogToggle();
  }

  handleEditPaymentChange(paymentChangeEvent) {
    const paymentChangeType = paymentChangeEvent.target.value;
    switch (paymentChangeType) {
      case 'new':
        this.handleAddNewPayment();
        this.setState({
          selectedPaymentMethod: 'new'
        });
        break;
      default:
        const { paymentMethods } = this.state;
        const selectedPaymentMethod = paymentMethods.find((paymentMethod) => paymentMethod.id === paymentChangeType);
        this.setState({
          selectedPaymentMethodToShow: selectedPaymentMethod,
          selectedPaymentMethod: 'saved'
        });
    }
  }

  async addEditMethod() {
    const { dispatch, editAddFormValues, cardOnFileFormValues, token } = this.props;
    const { is_default } = editAddFormValues
    const paymentMethodToAdd = {
      ...cardOnFileFormValues,
      is_default
    };

    this.setState({ loading : true });

    await dispatch(postPaymentMethodWithToken(paymentMethodToAdd, token));
    const res = await dispatch(getCustomerPaymentMethods(token));

    this.handleCloseDialogs();

    if (res?.type === actionTypes.customerPaymentMethodsGetSuccess) {
      const paymentMethods = res?.response?.payment_methods;
      this.setState({
        paymentMethods,
        selectedPaymentMethodToShow: paymentMethods[0]
      });
    }

    this.setState({ selectedPaymentMethod: 'saved', loading: false });

  }

  handleCloseDialogs() {
    this.props.dispatch(togglePaymentMethodsDeleteDialog(false));
    this.props.dispatch(togglePaymentMethodsEditDialog(false));
    this.props.dispatch(togglePaymentMethodsAddDialog(false));

    this.setState(prevState => ({
      openEditPayment: !prevState.openEditPayment
    }));
  }

  closePaymentErrorModal = () => this.setState({ errorModal: null });

  saveClick() {
    this.props.dispatch(submit('editAddPaymentMethodForm'))
  };

  handleAddDialogToggle() {
    this.props.dispatch(togglePaymentMethodsAddDialog(true))
  }

  confirmPartialPayment() {
    this.props.dispatch(setModalVisibility('hidden'));
  }

  async handlePayInvoice() {
    const { token, invoices: { selectedInvoice, selectedClpInvoiceOriginal }, dispatch, merchantSettings } = this.props;
    const { defaultPaymentMethod, selectedPaymentMethodToShow, totalTip, avsFailureCount } = this.state;

    const isHighTransactionAmount = this.IsHighTransactionAmount();
    if (isHighTransactionAmount) return;

    const { isInvoiceFromSeries } = selectedInvoice?.statusList || {};
    const invoiceId = selectedInvoice?.id;
    const { ignore_avs_failure : ignoreAvsFailure } = selectedInvoice;

    const paymentMethod = selectedPaymentMethodToShow || defaultPaymentMethod;
    const paymentMethodId = paymentMethod.id;
    const paymentMethodType = paymentMethod.type;

    if (isInvoiceFromSeries) {

      const { id: seriesId, auto_pay, payment_method_last4, active } = selectedInvoice?.series;
      const seriesPaymentChanged = (auto_pay && (payment_method_last4 !== paymentMethod.last4));

      if (!!active && (!auto_pay || seriesPaymentChanged)) {
        const payload = {
          type: 'series',
          auto_pay: true,
          payment_method: paymentMethodId,
          payment_method_type: paymentMethodType
        };
        await dispatch(updateInvoiceToPay(token, seriesId, payload));
      }
    }

    const { cashDiscountApplied } = InvoiceUtil.getCashDiscountSettings(selectedClpInvoiceOriginal, merchantSettings);

    const payInvoicePayload = {
      paymentMethodId,
      paymentMethodType,
      paymentMethod: null,
      tip: totalTip,
      saveOrAutoPay: false
    };

   if (cashDiscountApplied && paymentMethodType === 'ach') {
     payInvoicePayload.receipt = InvoiceUtil.recreateReceiptFromInvoice(selectedInvoice, merchantSettings);
   }

    const res = await dispatch(payInvoice(token, invoiceId, payInvoicePayload));

    if (res?.response?.response_code === 'APR') {
      const { requested_amt, authorized_amt } = res?.response;
      const isPartialPayment = requested_amt !== authorized_amt;
      if(!isPartialPayment) {
        this.setState({ paid: true, paymentMethodUsed: res.response });
      } else {
        const partialPayment = res?.response;
        const authorizedAmt = parseFloat(authorized_amt);
        const requestedAmt = parseFloat(requested_amt);
        const remainingBalance = parseFloat((requestedAmt - authorizedAmt).toFixed(2));

        this.setState({
          paymentMethodUsed: res.response,
          loading: false,
          paid: false,
          partialPayment: {
            ...partialPayment,
            remainingBalance,
            authorized_amt: authorizedAmt,
            requested_amt: requestedAmt
          }
        });
      }

    } else {
      const { status_message, response_code } = res?.response ?? {};
      const modalData = { loading: false };
      if(response_code === 'AVS') {
        if(!ignoreAvsFailure){
          modalData.errorModal = {status_code: t('Errors.Avs.TryAnother') };
          this.setState({ avsFailureCount: avsFailureCount + 1 });
        } else {
          this.setState({ paid: true, paymentMethodUsed: res.response });
        }

      } else {
        modalData.errorModal = {status_code: status_message };
      }
      this.setState(modalData);
    }

  }

  /**
   *  Passes arguments received directly onto processInvoiceWithApplePay
   *  If there's any error it rejects the promise in order to inform Apple's Apple Pay UI window, so that it can display an appropriate status to the user
   */
  handleApplePay(user, applePaymentData, paymentData, tipAmount) {
    const { t, location } = this.props;

    const isHighTransactionAmount = this.IsHighTransactionAmount();
    if (isHighTransactionAmount) return;

    this.setState({loading: true, paid: false, paymentMethodUsed: null, paymentError: null});

    const merchantToken = {
      type: 'clp',
      token: this.props.token
    };

    let that = this;
    let errorMessage;

    return new Promise(function (resolve, reject) {
      PaymentUtil.processInvoiceWithApplePay(user, applePaymentData, paymentData, tipAmount, that.props, merchantToken).then(function (paymentResponse) {

        if (paymentResponse.payment && paymentResponse.payment.error) {
          errorMessage = paymentResponse.payment.error;
          that.setState({
            loading: false,
            paid: false,
            paymentError: errorMessage
          });
          reject(t('CustomerPayment.Error', { error: errorMessage }));
        } else if (paymentResponse.payment && paymentResponse.payment.response && paymentResponse.payment.response.response_code !== 'APR') {
          errorMessage = paymentResponse.payment.response.status_message;
          that.setState({
            loading: false,
            paid: false,
            paymentError: t('CustomerPayment.ApplePayError')
          });
          reject(t('CustomerPayment.Error', { error: errorMessage }));
        } else if (paymentResponse?.payment?.response?.response_code === 'APR') {

          if (paymentResponse.payment.response?.is_partial_auth) {
            errorMessage = t(messages.errors.partialPayment.message);
            that.setState({
              loading: false,
              paid: false,
              paymentError: errorMessage
            });
            reject(t('CustomerPayment.Error', { error: errorMessage }));
          } else {
            const paymentMethodUsed = paymentResponse?.payment?.response;
            that.setState({ loading: false, paid: true, paymentMethodUsed });
            resolve();
          }

        } else {
          errorMessage = t('CustomerPayment.SomethingWentWrongError');
          that.setState({
            loading: false,
            paid: false,
            paymentError: errorMessage
          });
          reject(t('CustomerPayment.Error', { error: errorMessage }));
        }
        resolve();
      }).catch(error => {
        that.setState({loading: false, paymentError: error});
        reject(t('CustomerPayment.Error', { error: error.message }));
      });

    });

  }

  handleAfterPay() {
    this.setState({ paid: false, achFormValues: null });
    this.props.goBackAndReset();
  }

  setTip({value, type}) {
    this.setState({
      tip: { value, type }
    }, this.getTotal);
  }

  calculateReward(subtotal, rewardAmount, rewardType ) {
    return rewardType === 'dollar' ? rewardAmount : (Number(subtotal)*(Number(rewardAmount)/100));
  }
  getTotal(invoice) {
    const {invoices} = this.props;
    const selectedInvoice = invoice || invoices.selectedInvoice;
    const { tip, rewardDiscount, rewardLoaded, dataInvoice, itemizedCart } = this.state;

    let partialPayment = null;
    let totalTip = selectedInvoice?.tip_amount;

    let totalAmount = Number(dataInvoice?.total_amt) > 0 ? dataInvoice?.total_amt : 0 ;

    if (selectedInvoice?.statusList?.isPartiallyPaid) {
      partialPayment = {
        authorized_amt: Number(totalAmount) - Number(selectedInvoice?.unpaid_amount),
        requested_amt:  Number(totalAmount),
      };

      const remainingBalance = Number((partialPayment.requested_amt - partialPayment.authorized_amt).toFixed(2));
      partialPayment.remainingBalance = remainingBalance;
    }

    if (Boolean(tip.value)) {
      if (tip.type === '$') {
        const commasAndDollarSignPattern = /[,$]/g;
        const tipAmount = Number(tip.value.replace(commasAndDollarSignPattern, ''));
        const amount = partialPayment ? partialPayment.remainingBalance : totalAmount;
        totalAmount = Number(amount) + tipAmount;
        totalTip = Number(totalTip) + tipAmount;

        if (partialPayment) {
          partialPayment.remainingBalance = partialPayment.remainingBalance + tipAmount;
        }
      }

      if (tip.type === '%') {
        const percentage = Number(tip.value.replace(/\%/g, '')) / 100;
        const amount = Number(totalAmount) * percentage;
        totalTip = Number(totalTip) + amount;
        totalAmount = Number(totalAmount) + amount;

        if (partialPayment) {
          partialPayment.remainingBalance = partialPayment.remainingBalance + amount;
        }
      }
    }

    if(rewardDiscount && !rewardLoaded) {
      const { amount, type} = rewardDiscount;
      const rewardAmount  = this.calculateReward(selectedInvoice?.sub_total_amt, amount, type);
      totalAmount = totalAmount >= 0 ? totalAmount : 0;
      this.setState({rewardDiscountShow: itemizedCart?.loyalty_discount_amt || rewardAmount});
    }

    if (rewardLoaded && !rewardDiscount) {
      this.setState({ rewardLoaded: null });
    }

    this.setState({totalAmount, totalTip, partialPayment});
  }

  payAndSaveClick() {
    this.props.dispatch(submit('payAndSaveMethodForm'));
  }

  getAvsFailureCount(invoice) {
    const avsFailures = invoice?.failure_array?.filter(failure => failure?.reason === applicationConstants.avsFailureReason);
    return avsFailures?.length > 0 ? avsFailures.length : 0;
  }

  openThreeAvsErrorDialog() {
    this.props.dispatch(setModalVisibility('threeAvsErrorDialog'));
  }

  IsHighTransactionAmount () {
    const { totalTip, totalAmount, partialPayment } = this.state;
    const paymentAmount = partialPayment?.remainingBalance ? (
      partialPayment.requested_amt + totalTip
    ) : totalAmount;

    if (parseFloat(paymentAmount) > applicationConstants.highTransactionLimitAmount) {
      this.openHighTransactionAmountDialog();
      return true;
    }

    return false;
  }

  validateApplePay() {
    const { dataInvoice, avsFailureCount } = this.state;

    const avsError = avsFailureCount >= applicationConstants.avsMaxAttempts && !dataInvoice?.ignore_avs_failure;

    if (avsError) {
      this.openThreeAvsErrorDialog();
      return false;
    }

    return !this.IsHighTransactionAmount();
  }

  async saveAndPay() {
    const { dispatch, token, invoices: { selectedInvoice, selectedClpInvoiceOriginal }, cardOnFileFormValues, payAndSaveFormValues, t, merchantSettings } = this.props;
    const { totalTip, avsFailureCount } = this.state;
    let paymentMethod;

    const isHighTransactionAmount = this.IsHighTransactionAmount();

    if (isHighTransactionAmount) return;

    const { ignore_avs_failure: ignoreAvsFailure } = selectedInvoice;

    const { is_default, is_autopay } = payAndSaveFormValues;

    paymentMethod = CustomerUtil.formatPaymentMethod(cardOnFileFormValues);
    paymentMethod.is_default = is_default || is_autopay;

    this.setState({paying : true, achFormValues: paymentMethod});

    const shouldSaveCard = is_default || is_autopay;

    const { cashDiscountApplied } = InvoiceUtil.getCashDiscountSettings(selectedClpInvoiceOriginal, merchantSettings);

    const payInvoicePayload = {
      paymentMethodId: null,
      paymentMethod,
      tip: totalTip,
      saveOrAutoPay: shouldSaveCard
    };

    if (cashDiscountApplied && paymentMethod.type === 'ach') {
      payInvoicePayload.receipt = InvoiceUtil.recreateReceiptFromInvoice(selectedInvoice, merchantSettings);
    }

    const fetchedTransactionResult = await dispatch(payInvoice(token, selectedInvoice.id, payInvoicePayload));
    if (fetchedTransactionResult?.response?.response_code === 'APR') {
      if (fetchedTransactionResult.response.authorized_amt !== fetchedTransactionResult.response.requested_amt) { // should we include status_message check? "10 - Partial Approval"
        this.props.dispatch(setModalVisibility('partialPayment', { payment: { response: fetchedTransactionResult.response } } ));

        const partialPayment = fetchedTransactionResult.response;
        const authorizedAmt = parseFloat(partialPayment?.authorized_amt);
        const requestedAmt = parseFloat(partialPayment?.requested_amt);
        const remainingBalance = parseFloat((requestedAmt - authorizedAmt).toFixed(2));

        this.setState({
          paymentMethodUsed: fetchedTransactionResult.response ,
          paying: false,
          paid: false,
          partialPayment: {
            ...partialPayment,
            remainingBalance,
            authorized_amt: authorizedAmt,
            requested_amt: requestedAmt
          }
        });

      } else {
        this.setState({paymentMethodUsed: fetchedTransactionResult.response , paying: false, paid: true });
      }
    } else {
      const { status_message, response_code } = fetchedTransactionResult?.response ?? {};
      const modalData = { paying: false };
      if(response_code === 'AVS') {
        if(!ignoreAvsFailure){
          modalData.errorModal = {status_code: t('Errors.Avs.TryAnother'), avs_style: true };
          this.setState({ avsFailureCount: avsFailureCount + 1 });
        } else {
          if(shouldSaveCard) {
            this.props.dispatch(setModalVisibility('unableToAddPaymentMethodDialog'));
          }
          this.setState({ paid: true, paymentMethodUsed: fetchedTransactionResult?.response });
        }

      } else {
        modalData.errorModal = {status_code: status_message };
      }
      this.setState(modalData);
    }
  }

  async confirmEnrollInLoyalty(values) {
    const {token, dispatch, customers} = this.props;

    const newCustomerObject = customers?.customerPaymentData;

    const customerAlreadyHasPhoneNumber = customers?.customerPaymentData?.phone_number;
    if(!customerAlreadyHasPhoneNumber) {
      newCustomerObject.phone_number = values.phone_number;
    }
    newCustomerObject.loyalty_vpc_status.opted_in_loyalty = true;

    await dispatch(updateCustomerDataToken(token, newCustomerObject));
    this.handleAfterPay();
  }

  submitEnrollInLoyaltyForm() {
    this.props.dispatch(submit('enrollInLoyaltyForm'));
  }

  toggleEnrollInLoyaltyValue() {
    this.setState(prevState => ({enrollInLoyaltyValue: !prevState.enrollInLoyaltyValue}));
  }

  closeHighTransactionAmountDialog() {
    const { dispatch } = this.props;
    dispatch(setModalVisibility('hidden'));
  }

  openHighTransactionAmountDialog() {
    const { dispatch } = this.props;
    dispatch(setModalVisibility('highTransactionAmountDialog'));
  }

  render() {

    const {
      openEditPayment, paymentMethods, defaultPaymentMethod, selectedPaymentMethod,
      selectedPaymentMethodToShow, paid, totalAmount, totalTip, paymentMethodUsed,
      partialPayment, errorModal, rewardDiscount, dataInvoice: selectedInvoice, rewardDiscountShow,
      paying, avsFailureCount, achFormValues, enrollInLoyaltyValue
    } = this.state;

    const {
      goBackAndReset, invoices, merchantSettings, userExperience,
      customers, t, brandColor, decodedToken, dispatch
    } = this.props;

    const {
      addPaymentMethodDialog,
      achTermsAccepted,
      modalVisibility: {
        partialPaymentDialogOpen, highTransactionAmountDialog,
        threeAvsErrorDialog, unableToAddPaymentMethodDialog
    }} = userExperience;

    const { selectedClpInvoiceOriginal } = invoices;

    const merchant = merchantSettings?.merchantSettings;
    const customerInfo = customers?.customerPaymentData;
    const loyalty_vpc = merchant?.loyalty_vpc && (UserUtil.isPremiumPlusWithLoyaltyAccount(merchant) || UserUtil.isPremiumPlusAccount(merchant));
    const loading = customers?.isFetching || this.state.loading;
    const isPaying = invoices?.isProcessing || paying;
    const error = customers?.error;
    const labelColor = LabelUtil.getLabelColor();
    const notificationTime = merchant?.ach_notification_revoke_days || 10;
    const notificationType = FormatTextUtils.getTrueKeys(merchant?.ach_notification_revoke_type) || 'mail';

    if (loading) { return <Loading/> };

    if (error || !decodedToken) {
      return (
        <div className='error'>
          <img src={`${serverDomainUrl}images/404.svg`} className='notFoundImage'></img>
          <h1>Not found</h1>
        </div>
      );
    }

    const loyaltyStatus = paymentMethodUsed?.loyalty_vpc;

    const isTipEnabled = selectedInvoice.inv_allow_tip;
    const tipAmounts = merchant?.tip_default_values ? merchant?.tip_default_values : '';

    const paymentToUse = selectedPaymentMethodToShow || defaultPaymentMethod;

    const paymentAmount = partialPayment?.remainingBalance ? partialPayment.remainingBalance : totalAmount;

    const { isInvoiceFromSeries, isPartiallyPaid: selectedInvoiceIsPartiallyPaid } = selectedInvoice?.statusList || {};
    const { auto_pay, end_date, frequency, next_run, start_date, end_amount_payments } = selectedInvoice?.series || {};

    const isPartiallyPaid = selectedInvoiceIsPartiallyPaid || partialPayment

    const invoiceTitle = isPartiallyPaid ? t('BalanceRemaining') : t('InvoiceAmount');

    const avsError = avsFailureCount >= applicationConstants.avsMaxAttempts && !selectedInvoice?.ignore_avs_failure;

    const payAndSaveSubmitButton = (show) => {
      return (
        <DialogActions sx={paymentPagePayDialogActions}>
          <Button
            label={t('PayAmount', { amount: numeral(paymentAmount).format('$0,0.00') })}
            onClick={avsError ? this.openThreeAvsErrorDialog : this.payAndSaveClick}
            disabled={!show}
          />
        </DialogActions>
      )
    };

    const submitButton = (show) => {
      return (
        <DialogActions className='editAddPaymentDialogActions'>
          <Button variant='text' label={t('Cancel')} onClick={this.handleCloseDialogs} />
          <Button
            label={t('Save')}
            onClick={this.saveClick}
            disabled={!show}
          />
        </DialogActions>
      )
    };

    const achEnabled = merchant?.ach_enabled;

    const addPaymentModal = (
      <Modal
        open={addPaymentMethodDialog}
        maxWidth='lg'
        onClose={this.handleCloseDialogs}
        hideActions={true}
        externalClassName='addPaymentModal'
        >
        <EditAddPaymentMethodForm
          achEnabled={achEnabled}
          onSubmit={this.addEditMethod}
          submitButton={submitButton}
          invoice={selectedInvoice}
          isClp
          isRecurringInvoice={isInvoiceFromSeries}
          isManagingPaymentMethods
          merchant={merchant?.business_name}
          notificationType={notificationType}
          notificationTime={notificationTime}
          {...this.props}
        />
      </Modal>
    );

    const loyaltyModal = (
      <Modal
        hideCancelButton
        open={this.state.loyaltyModalOpen}
        contentClassName='loyaltyModal'
        maxWidth='lg'
        onClose={this.closeLoyaltyModal}
        onConfirm={this.closeLoyaltyModal}
        confirmText={t('Ok')}>
          <LoyaltyReward
            {...this.props}
            submitLoyaltyRewardCodeForm={this.submitLoyaltyRewardCodeForm}
            activatePrePopulatedReward={this.activatePrePopulatedReward}
            removePrepopulatedReward={this.removePrepopulatedReward}
          />
      </Modal>
    );

    const paymentErrorModal = (
      <Modal
        contentClassName='paymentErrorModal'
        onClose={this.closePaymentErrorModal}
        cancelText={t('Okay')}
        hideConfirmButton={true}
        open={Boolean(errorModal)}
        title={t('UnableToPayTitleCustomerFacing')}
      >
        <>
          <Typography component='p' sx={PaymentErrorModalText}>
            {t('UnableToPayCustomerFacing')}
          </Typography>
          <Typography component='p' sx={PaymentErrorModalText}>
            {t('TryOtherPaymentMethodCustomerFacing')}
          </Typography>
        </>
      </Modal>
    );

    const threeAvsErrorModal = (
      <Modal
        contentClassName='paymentErrorModal'
        onClose={this.confirmPartialPayment}
        cancelText={t('Close')}
        hideConfirmButton={true}
        open={threeAvsErrorDialog}
        title={<>
          <span className='title-icon'>{IconUtils.getIcon('Error', brandColor)}</span>
          {`${t('PayInvoiceForm.TooManyAttempts')}.`}
        </>}
        >
        {t('PayInvoiceForm.AvsErrorText', {support: merchant?.business_name})}
      </Modal>
    );

    const unableToAddPaymentMethodModal = (
      <Modal
        onClose={this.confirmPartialPayment}
        cancelText={t('Close')}
        hideConfirmButton={true}
        open={unableToAddPaymentMethodDialog}
        title={<>
          <span className='title-icon'>{IconUtils.getIcon('Error', brandColor)}</span>
          <div>{t('CustomerDetailForm.AddPaymentMethodErrorTitle')}</div>
        </>}
        >
        <div>
          {t('CustomerDetailForm.AddPaymentMethodError')}
        </div>
      </Modal>
    );

    const authorizedAmt = partialPayment && parseFloat(partialPayment.authorized_amt);
    const requestedAmt = partialPayment && parseFloat(partialPayment.requested_amt);
    const remainingBalance = partialPayment && (requestedAmt - authorizedAmt);

    const formattedAuthorizedAmt = partialPayment && numeral(authorizedAmt.toFixed(2)).format('$0,0.00');
    let formattedRemainingBalance = partialPayment && numeral(remainingBalance.toFixed(2)).format('$0,0.00');

    if (partialPayment?.remainingBalance) {
      formattedRemainingBalance = numeral(partialPayment.remainingBalance.toFixed(2)).format('$0,0.00');
    }

    const cardInfo = partialPayment && partialPayment.network && `(${partialPayment.network} - ${partialPayment.last4})`;

    const partialPaymentDialog = (
      <ReskinMessageDialog
        titleText={t('PartialPaymentModal.Title', { authorizedAmt: formattedAuthorizedAmt })}
        bodyText={t('PartialPaymentModal.Description', { remainingBalance: formattedRemainingBalance })}
        confirmText={t('PartialPaymentModal.ConfirmText', { remainingBalance: formattedRemainingBalance })}
        cancelText={t('PartialPaymentModal.CancelText')}
        isChoiceRequired={true}
        open={partialPaymentDialogOpen}
        onConfirm={this.confirmPartialPayment}
        onRequestClose={this.voidTransaction}
        hideCloseIcon={true}
      />
    );

    const editPaymentModal = (
      <Modal
        title={t('EditPaymentMethod')}
        open={openEditPayment}
        contentClassName='paymentMethodSelectDialog'
        maxWidth='md'
        onConfirm={this.handleEditPayment}
        onClose={this.handleCloseDialogs}
        hideCancelButton={true}
        isClosableIconEnable
      >
        <div>
          <p className='contentTitle'>{isInvoiceFromSeries ? t('ReplaceExistingPaymentMethodSeries') : t('ReplaceExistingPaymentMethodInvoice')}</p>
          <div>
            <Select
              label='Payment Method'
              value={selectedPaymentMethod}
              onChange={this.handleEditPaymentChange}
            >
              <MenuItem value='saved'>
                {t('UseSavedPaymentMethod')}
              </MenuItem>
              <MenuItem value='new'>
                {t('AddPaymentMethod')}
              </MenuItem>
              {paymentMethods?.map((cc, index) =>
                <MenuItem value={cc.id} key={`paymentMethod${index}`} style={{display: 'block'}}>
                  <PaymentMethodSummary
                    paymentMethod={cc}
                    showDefault={true}
                    showEditButton={false}
                    t={t}
                    backgroundColor='#F2F2F2'
                  />
                </MenuItem>)
              }
            </Select>
          </div>
          { selectedPaymentMethodToShow ? <PaymentMethodSummary paymentMethod={selectedPaymentMethodToShow} showEditButton={false} t={t} /> : defaultPaymentMethod ? <PaymentMethodSummary paymentMethod={defaultPaymentMethod} showEditButton={false} t={t} /> : null}
        </div>
      </Modal>
    );

    const dueDate = DateUtil.getEndOfDay(selectedInvoice.due_date);
    const isPastDue = moment().isAfter(dueDate);

    const invoiceHeader = isInvoiceFromSeries && auto_pay && !isPastDue ? (
      <>
        {t('InvoiceHeaderAutoPay', { amount: numeral(selectedInvoice.total_amt).format('$0,0.00'), date: moment(next_run).format('LL') })}
      </>
    ) : `${invoiceTitle}: ${numeral(paymentAmount).format('$0,0.00')}`;

    const quantity = isInvoiceFromSeries &&
      FormatTextUtils.getQuantityInvoices(start_date, end_date, frequency, end_amount_payments);
    const currentInvoice = isInvoiceFromSeries &&
      FormatTextUtils.getCurrentInvoiceFromSeries(start_date, frequency, selectedInvoice.due_date);
    const repeatsInfo = isInvoiceFromSeries &&
      FormatTextUtils.getInvoiceRepeat(frequency, end_date, 'l');

    const detailsHeader = isInvoiceFromSeries ? (
      <>
        <span>{selectedInvoice.name}</span> <br/>
        <span>{t('InvoiceSeriesDetail', { currentInvoice, quantity: quantity ? quantity : t('NoEndDate')})}</span>
      </>
    ) : selectedInvoice.name;

    const subTotalAmount = roundToTwoDecimals(selectedInvoice?.sub_total_amt);
    const taxAmount = roundToTwoDecimals(selectedInvoice?.tax_amt);
    const tipAmount = roundToTwoDecimals(totalTip);

    const applePayPaymentRequest = {
      countryCode: 'US',
      currencyCode: 'USD',
      shippingMethods: [],
      lineItems: [
        {
          label: t('InvoiceDetail.AppleInvoice', { invoice: selectedInvoice.name }),
          amount: subTotalAmount.toString(),
          type: 'final'
        },
        {
          label: t('InvoiceDetail.AppleTax'),
          amount: taxAmount.toString(),
          type: 'final'
        }
      ],
      total: {
        label: t('InvoiceDetail.AppleTotal'),
        amount: roundToTwoDecimals(totalAmount).toString(),
        type: 'final'
      },
      supportedNetworks: ['amex', 'discover', 'masterCard', 'visa'],
      merchantCapabilities: ['supports3DS'],
      requiredBillingContactFields: ['postalAddress']
    };

    if (tipAmount > 0) {
      applePayPaymentRequest.lineItems.push({
        label: t('InvoiceDetail.AppleTip'),
        amount: tipAmount.toString(),
        type: 'final'
      });
    }


    const isSafari = BrowserUtil.isSafari();
    const applePayEnabled = Boolean(merchant?.apple_pay_enabled);

    const applePayButton = isSafari && applePayEnabled ? (
      <div className='applePayContainer'>
        <ApplePayButton
          {...this.props}
          tipAmount = {tipAmount}
          paymentRequest={applePayPaymentRequest}
          paymentData={selectedClpInvoiceOriginal}
          paymentType={paymentTypes.customerInvoice}
          validate={this.validateApplePay}
          handlePaymentProcessing={this.handleApplePay}
        />
      </div>
    ) : null;

    const payButton = paymentToUse && (
      <Button
        label={t('PayAmount', { amount: numeral(isPartiallyPaid ? partialPayment.remainingBalance : totalAmount).format('$0,0.00') })}
        fullWidth={true}
        onClick={avsError ? this.openThreeAvsErrorDialog : this.handlePayInvoice}
        disabled={invoices?.isProcessing || (!achTermsAccepted && paymentToUse?.type === 'ach')}
      />
    );

    const itemHasDiscounts = selectedInvoice?.item_discount_amt?.length || selectedInvoice?.item_discount_rate?.length;
    const hasItemizedDiscounts = !!selectedInvoice?.receipt_discount_name?.length;

    const isZeroTotalAmount = numeral(totalAmount).format('$0,0.00') === '$0.00';

    const sentReceiptTo = selectedInvoice?.email_addresses && selectedInvoice?.email_addresses.length && selectedInvoice.email_addresses[0] ?
      selectedInvoice.email_addresses[0] : TextUtil.formatPhoneNumber(selectedInvoice?.phone_number);

    const noBalanceDue = (
      <div className='paidInvoice zeroAmount'>
        <InvoicePaidImg brandColor={brandColor}/>
        <h2 className='centeredText'>{t('NoBalanceDue.Header')}</h2>
        {!!selectedInvoice?.paid_date ?
          (
            <p className='centeredText'>
              <Trans i18nKey={'NoBalanceDue.Description'}>
                We sent a receipt to <span className='bold'>{{ email: sentReceiptTo }}</span>
              </Trans>
            </p>
          ) : <br />
        }
        <div className='savedPayment'>
          <div className='card'>
            <div className='details'>
              <p className='bold'>{t('InvoiceDetail.InvoiceNumber', { invoiceNumber: selectedInvoice?.id })}</p>
              <p>{t('InvoiceDetail.PaidDate', { date: moment(selectedInvoice?.created_date).format('DD MM YYYY hh:mm') })}</p>
            </div>
          </div>
          <div className='extra'>
            <p>{numeral(totalAmount).format('$0,0.00')}</p>
          </div>
        </div>
      </div>
    );

    const shouldShowAutoPayText = isInvoiceFromSeries && !auto_pay;

    const autoPayText = shouldShowAutoPayText && <p className='autoPayText'>{t('InvoiceDetail.AutoPayCharge')}</p>

    const receiptDiscounts = InvoiceUtil.getReceiptDiscounts(selectedInvoice);

    const shouldShowEnrollInLoyaltyToggle = !customerInfo?.loyalty_vpc_status?.opted_in_loyalty;

    const dba = merchant?.business_name;
    const highTransactionAmountModal = (
      <HighAmountModal
        onClose={this.closeHighTransactionAmountDialog}
        open={highTransactionAmountDialog}
        bodyText={t('HighAmountModal.ExternalBody', {dba})}
        t={t}
      />
    );
    return (
      <section className='eInvoiceDetail'>
        <div className='leftContent'>
          {paid ?
            <InvoiceDetailPaid
              customers={customers}
              achFormValues={achFormValues}
              achRevokeDays={notificationTime}
              brandColor={brandColor}
              confirmEnrollInLoyalty={this.confirmEnrollInLoyalty}
              currentInvoice={currentInvoice}
              customerInfo={customerInfo}
              enrollInLoyaltyValue={enrollInLoyaltyValue}
              handleAfterPay={this.handleAfterPay}
              isInvoiceFromSeries={isInvoiceFromSeries}
              loyaltyStatus={loyaltyStatus}
              loyalty_vpc={loyalty_vpc}
              merchant={merchant}
              paid={paid}
              paymentMethodUsed={paymentMethodUsed}
              paymentMethods={paymentMethods}
              paymentToUse={paymentToUse}
              quantity={quantity}
              repeatsInfo={repeatsInfo}
              selectedInvoice={selectedInvoice}
              sentReceiptTo={sentReceiptTo}
              shouldShowEnrollInLoyaltyToggle={shouldShowEnrollInLoyaltyToggle}
              submitEnrollInLoyaltyForm={this.submitEnrollInLoyaltyForm}
              t={t}
              toggleEnrollInLoyaltyValue={this.toggleEnrollInLoyaltyValue}
              totalAmount={totalAmount}
            /> : <div>
            <div className='goBack'>
              <Button
                onClick={goBackAndReset}
                label={t('PaymentPortalPreview.BackToInvoices')}
                startIcon={IconUtils.getIcon('ChevronLeftIcon')}
                variant='text'
              />
            </div>
            {(isZeroTotalAmount && !rewardDiscount) ? noBalanceDue : (
              <>
                <Typography
                  component='h2'
                  sx={paymentPageDetailTitle}
                >
                  {invoiceHeader}
                </Typography>
                {selectedInvoice?.due_date && <h3 className='dueDateTop'>{t('PayBy', { date: moment.utc(selectedInvoice.due_date).format('LL') })}</h3>}
                {this.state.paymentError ? (
                  <div className='errorText invoicePaymentError'>
                    {this.state.paymentError}
                  </div>
                ) : ''}
                {(defaultPaymentMethod || selectedPaymentMethodToShow || paymentMethods.length) ? <div className='toPay'>
                    <h4>{t('InvoiceDetail.SavedPaymentDetails')}</h4>
                    <PaymentMethodSummary
                      backgroundColor='#F2F2F2'
                      paymentMethod={paymentToUse}
                      onClick={this.handleEditPayment}
                      showEditButton={!invoices?.isProcessing}
                      showDefault={true}
                      t={t}
                    />
                  {paymentToUse?.type === 'ach' &&
                    <TermsAndConditionToggle
                      t={t}
                      margin={true}
                      submitting={invoices?.isProcessing}
                      dispatch={dispatch}
                      useGlobalState={true}
                      isRecurringInvoice={isInvoiceFromSeries}
                      isClp={true}
                      merchant={merchant?.business_name}
                      notificationType={notificationType}
                      notificationTime={notificationTime}
                    />}
                    {autoPayText}
                    {isTipEnabled && <div style={{marginTop: '30px'}}>
                      <TipSelector invoice={selectedInvoice} tipAmounts={tipAmounts} setTip={this.setTip} t={t} />
                    </div>}
                  </div> :
                  <PayAndSaveMethodForm
                    onSubmit={this.saveAndPay}
                    submitButton={payAndSaveSubmitButton}
                    tipAmounts={tipAmounts}
                    title={false}
                    setTip={this.setTip}
                    invoice={selectedInvoice}
                    isTipEnabled={isTipEnabled}
                    t={t}
                    ignoreAvsFailure={merchantSettings?.merchantSettings?.ignore_avs_failure}
                    achEnabled={achEnabled}
                    isClp
                    isRecurringInvoice={isInvoiceFromSeries}
                    merchant={merchant?.business_name}
                    notificationType={notificationType}
                    notificationTime={notificationTime}
                  />}
              </>
            )}
          </div>}
        </div>
        <div className='rightContent'>
          <div className='topHolder'>
            <div className='heading'>
              <Typography
                component='h3'
                sx={paymentPageDetailSummaryTitle}
              >
                {merchant?.business_name}
              </Typography>
              <Typography
                component='p'
                sx={paymentPageDetailSummarySubTitle}
              >
                {detailsHeader}
              </Typography>
              {selectedInvoice?.first_name && selectedInvoice?.last_name &&
                <Typography
                  component='p'
                  sx={paymentPageDetailSummarySubTitle}
                >
                  {t('InvoiceDetail.BillTo', { billTo: `${selectedInvoice?.first_name} ${selectedInvoice?.last_name}` })}
                </Typography>}
              <Typography
                component='p'
                sx={paymentPageDetailSummarySubTitle}
              >
                {t('InvoiceDetail.InvoiceNumber', { invoiceNumber: selectedInvoice?.invoice })}
              </Typography>
              <Typography
                data-test-id='invoice-date'
                component='p'
                sx={paymentPageDetailSummarySubTitle}
              >
                {moment(selectedInvoice?.sent_date || selectedInvoice?.created_date).format('LL')}
              </Typography>
              {isInvoiceFromSeries &&
                <Typography
                  component='p'
                  sx={paymentPageDetailSummarySubTitle}
                >
                  {repeatsInfo}
                </Typography>}
              <Typography
                data-test-id='invoice-description'
                component='p'
                sx={paymentPageDetailSummarySubTitle}
              >
                {selectedInvoice.description}
              </Typography>
            </div>
            <ul className='detailList'>
              {selectedInvoice?.item_ids?.map((item, i) => {
                const itemRateDiscount = (itemHasDiscounts && selectedInvoice?.item_discount_rate[i]) ? selectedInvoice?.item_discount_rate[i] : 0;
                const mappedModifiers = selectedInvoice.item_modifiers?.map(InvoiceUtil.mapInvoiceModifiers);
                const itemModifiers = selectedInvoice.item_modifiers?.[i]?.length > 0
                  ? mappedModifiers[i].map(modifier => modifier?.total_amt > 0 ? modifier?.total_amt : Number(modifier?.price) * selectedInvoice.item_quantity[i])
                  : [];
                const totalModifiersAmt = itemModifiers.reduce((partialSum, amt) => partialSum + Number(amt || ''), 0);
                const itemTotalPrice = selectedInvoice.price_total_amount?.[i] > 0 ? selectedInvoice.price_total_amount[i] : selectedInvoice.item_unit_price[i] * selectedInvoice.item_quantity[i];
                const itemTotalAmt = (itemTotalPrice + totalModifiersAmt);
                const itemTotalWithDiscount = selectedInvoice.item_discount_total_amt?.[i] > 0 ? selectedInvoice.item_discount_total_amt[i] : (itemTotalAmt * (itemRateDiscount/100));
                const itemDiscountAmt = selectedInvoice.item_discount_total_amt?.[i] > 0 ? selectedInvoice.item_discount_total_amt[i] : selectedInvoice.item_discount_amt[i];
                const itemName = selectedInvoice?.item_names?.[i];
                const priceName = selectedInvoice?.item_price_names?.[i];
                const itemQuantity = selectedInvoice?.item_quantity?.[i];

                return (
                  <li className='detailItem' key={i}>
                    <div className='text'>
                      <Typography
                        component='p'
                        sx={paymentPageDetailSummaryMain}
                      >
                        {InvoiceUtil.formatNamePriceQuantity(itemName, priceName, itemQuantity)}
                      </Typography>
                      {mappedModifiers?.[i]?.map((name, nameIndex) =>
                        <Typography
                          key={nameIndex}
                          sx={paymentPageDetailSummarySub}
                        >
                          {name?.name}
                        </Typography>)}
                      {itemHasDiscounts && (selectedInvoice?.item_discount_amt[i] || selectedInvoice?.item_discount_rate[i]) ?
                        <Typography
                          sx={paymentPageDetailSummarySub}
                        >
                          {`${t('Discount')} ${selectedInvoice?.item_discount_rate[i] ? `(${selectedInvoice?.item_discount_rate[i]}%)` : ''}`}
                        </Typography> : null}
                    </div>
                    <div className='pullLeft'>
                      <Typography
                        component='p'
                        sx={paymentPageDetailSummaryPrice}
                      >
                        {numeral(itemTotalPrice).format('$0,0.00')}
                      </Typography>
                      {itemModifiers.map((price, priceIndex) =>
                        <Typography
                          key={priceIndex}
                          sx={paymentPageDetailSummarySub}
                        >
                          {numeral(price).format('$0,0.00')}
                        </Typography>)}
                      {itemHasDiscounts && (selectedInvoice?.item_discount_amt[i] || selectedInvoice?.item_discount_rate[i]) ?
                        <Typography
                          sx={paymentPageDetailSummarySub}
                        >
                          {selectedInvoice?.item_discount_rate[i] ? `-${numeral(`${itemTotalWithDiscount}`).format('$0,0.00')}` : `-${numeral(`${itemDiscountAmt}`).format('$0,0.00')}`}
                        </Typography> : null}
                    </div>
                  </li>
                )

              })}

              {hasItemizedDiscounts ? receiptDiscounts.map((discount) =>
                discount.type !== 'Loyalty Discount' && (<li className='detailItem' key={discount.index}>
                  <div className='text'>
                    <Typography
                      sx={paymentPageDetailSummaryMain}
                    >
                      {`${discount.name} ${discount.receiptDiscountInfo.includes('percentage') ? `(${JSON.parse(discount.receiptDiscountInfo).percentage}%)` : ''}`}
                    </Typography>
                  </div>
                  <div className='pullLeft'>
                    <Typography
                      sx={paymentPageDetailSummaryPrice}
                    >
                      {`-${numeral(`${discount.receiptDiscountAmt}`).format('$0,0.00')}`}
                    </Typography>
                  </div>
                </li>)) : null}

              <li className='detailItem sub'>
                <div className='text'>
                  {rewardDiscount ? <>
                    <Typography
                      sx={paymentPageDetailSummaryMain}
                    >
                      {t('LoyaltyPrograms.Clp.DiscountOrReward')}
                    </Typography>
                    {!paid ? <p>
                      <Button
                        className='removeReward'
                        label={t('LoyaltyPrograms.Clp.RemoveReward')}
                        onClick={this.removeReward}
                        variant='text'
                      />
                    </p> : null}
                  </> : null}
                  <Typography
                    sx={paymentPageDetailSummarySub}
                  >
                    {t('Subtotal')}
                  </Typography>
                  <Typography
                    sx={paymentPageDetailSummarySub}
                  >
                    {t('Tax')}
                  </Typography>
                  {isTipEnabled &&
                    <Typography
                      sx={paymentPageDetailSummarySub}
                    >
                      {t('Tip')}
                    </Typography>}
                </div>
                <div className='pullLeft' id='loyaltyReward'>
                  {rewardDiscount ? <>
                    <Typography
                      data-test-id='reward-amount'
                      sx={paymentPageDetailSummaryReward}
                    >
                      {`-${numeral(rewardDiscountShow).format('$0,0.00')}`}
                    </Typography>
                  </> : null}
                  <Typography
                    sx={paymentPageDetailSummarySub}
                  >
                    {numeral(selectedInvoice?.sub_total_amt).format('$0,0.00')}
                  </Typography>
                  <Typography
                    sx={paymentPageDetailSummarySub}
                  >
                    {numeral(selectedInvoice?.tax_amt).format('$0,0.00')}
                  </Typography>
                  {isTipEnabled &&
                    <Typography
                      data-test-id='tip-amount'
                      sx={paymentPageDetailSummarySub}
                    >
                      {numeral(totalTip).format('$0,0.00')}
                    </Typography>}
                </div>
              </li>
              <li className='detailItem'>
                <div className='text'>
                  <Typography
                    sx={paymentPageDetailSummaryMain}
                  >
                    {t('Total')}
                  </Typography>
                </div>
                <Typography
                  component='span'
                  sx={paymentPageDetailSummaryPrice}
                >
                  {numeral(totalAmount).format('$0,0.00')}
                </Typography>
              </li>
              {partialPayment &&
                (
                  <>
                    <li className='detailItem noBorder'>
                      <div className='text'>
                        <Typography
                          component='p'
                          sx={paymentPageDetailSummaryMain}
                        >
                          {t('PartialAuth', { cardInfo })}
                        </Typography>

                      </div>
                      <div className='pullLeft'>
                        <Typography
                          component='p'
                          sx={paymentPageDetailSummaryPrice}
                        >
                          {numeral(formattedAuthorizedAmt).format('$0,0.00')}
                        </Typography>
                      </div>
                    </li>
                    <li className='detailItem'>
                      <div className='text'>
                        <Typography
                          component='p'
                          sx={paymentPageDetailSummaryMain}
                        >
                          {t('BalanceRemaining', { cardInfo })}
                        </Typography>
                      </div>
                      <Typography
                        component='span'
                        sx={paymentPageDetailSummaryPrice}
                      >
                        {numeral(formattedRemainingBalance).format('$0,0.00')}
                      </Typography>
                    </li>
                  </>
                )
              }
              {
                (!rewardDiscount && loyalty_vpc && !paid) ?
                  <li className='detailItem loyaltyButtonContainer'>
                    <Button
                      className='button loyaltyButton'
                      endIcon={IconUtils.getIcon('LoyaltyVpcSmallTag', brandColor || labelColor)}
                      label={t('LoyaltyPrograms.Clp.ApplyReward')}
                      onClick={this.openLoyaltyModal}
                      sx={customerPaymentInvoiceDiscountBtn}
                      variant='text'
                    />
                  </li>
                :
                  null
              }
            </ul>
          </div>
          <div className='bottomHolder'>
            {((!paid && !isZeroTotalAmount) || (isZeroTotalAmount && rewardDiscount && !paid)) ?
              <div className='payHolder'>
                {invoices?.isProcessing ? <CircularProgress /> : (
                  <>
                    {payButton} <br/>
                    {applePayButton}
                  </>
                )}
              </div> : null}
          </div>
        </div>
        {addPaymentModal}
        {editPaymentModal}
        {loyaltyModal}
        {partialPaymentDialog}
        {paymentErrorModal}
        {threeAvsErrorModal}
        {unableToAddPaymentMethodModal}
        {isPaying && <UpdateSpinner />}
        {highTransactionAmountModal}
      </section>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const editAddFormValues = getFormValues('editAddPaymentMethodForm')(state) || {};
  const cardOnFileFormValues = getFormValues('cardOnFileForm')(state) || {};
  const payAndSaveFormValues = getFormValues('payAndSaveMethodForm')(state) || {};
  return { editAddFormValues, cardOnFileFormValues, payAndSaveFormValues };
}

export default connect(mapStateToProps)(InvoiceDetail);
