import { CALL_API } from '../middleware/api';
import actionTypes from '../constants/actionTypes';

export function getCategories(user) {

  const endpoint = `users/accounts/${user.selectedMerchantAccount}/categories`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.categoryGetRequest, actionTypes.categoryGetSuccess, actionTypes.categoryGetFailure]
    }
  };
};

export function setCategory(category) {
  return {
    type: actionTypes.categorySelected,
    selectedCategory: category
  };
}

export function saveCategories(user, selectedIndex, values) {

  const method = selectedIndex === 'new' ? 'POST' : 'PUT';
  const postEndpoint = `/users/accounts/${user.selectedMerchantAccount}/categories`;
  const putEndpoint = `/users/accounts/${user.selectedMerchantAccount}/categories/${values.id}`;
  const endpoint = method === 'PUT' ? putEndpoint : postEndpoint;

  const body = {
    cat_name: values.name,
    cat_color: values.color,
    cat_is_active: true
  };

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: method,
      authenticated: true,
      types: [actionTypes.categorySaveRequest, actionTypes.categorySaveSuccess, actionTypes.categorySaveFailure],
      body
    }
  };
}

export function deleteCategory(selectedCategory, user) {

  const endpoint = `users/accounts/${user.selectedMerchantAccount}/categories/${selectedCategory.id}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'DELETE',
      authenticated: true,
      types: [actionTypes.categoryDeleteRequest, actionTypes.categoryDeleteSuccess, actionTypes.categoryDeleteFailure]
    }
  };
}

export function mergeCategories(user, { baseCategory, selectedCategory }, newCategory) {
  const endpoint = `/users/accounts/${user.selectedMerchantAccount}/categories/merge`;

  const body = {
    baseCategory,
    selectedCategory,
    newCategory: {
      name: newCategory.newCategoryName,
      color: newCategory.color
    }
  };

  return {
    [CALL_API]: {
      body,
      endpoint,
      method: 'POST',
      authenticated: true,
      types: [actionTypes.mergeCategoryRequest, actionTypes.mergeCategorySuccess, actionTypes.mergeCategoryFailure]
    }
  };
};
