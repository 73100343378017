/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import {
  Field,
  formValueSelector,
  reduxForm,
  isValid,
  isPristine
} from 'redux-form';
import CustomTextField from '../shared/TextField';
import { connect } from 'react-redux';
import SettingsUtil from '../util/SettingsUtil';
import TextUtil from '../util/FormatTextUtil';
import Select from '../shared/Select';
import states from '../../constants/states';
import { MenuItem } from '@mui/material';
import CustomToggle from '../shared/Toggle';
import _ from 'lodash';
import Validator from '../util/Validator';
import AttachFilesForm, { AttachFilesFormType} from '../AttachFilesForm';
import FileUtil from '../util/FileUtil';
import IconUtil from '../util/IconUtil';
import { sendCustomImage } from '../../actions/formsActions'
import BrandColorPicker from '../../components/shared/BrandColorPicker';
import attachFileOptions from '../../constants/attachFileOptions';

const validate = (values, props) => {
  if (!_.isEmpty(values)) {
    return Validator.validateReceiptSettingsForm(values);
  } else {
    return {};
  }
};

export class ReceiptSettingsFormComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      img: null,
      ext: '',
      imgDialog: false,
      loading: false,
      receiptPreview: false
    };

    this.handleAddLogo = this.handleAddLogo.bind(this);
    this.handleResetImg = this.handleResetImg.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.customLogo !== this.props.customLogo) {
      this.setState({
        img: nextProps.customLogo.img,
        ext: nextProps.customLogo.ext
      });
    }
  }

  handleAddLogo(file) {
    return FileUtil.getBase64(file[0]).then((result) => {
      const ext = result.base64.split('/')[1].split(';')[0];
      const imgString = result.base64.replace(`data:image/${ext};base64,`, '');
      this.props.dispatch(sendCustomImage(imgString, ext));
      this.setState({
        img: imgString,
        ext: ext,
        imgDialog: false
      });
    });
  }

  handleResetImg() {
    this.setState({img: null});
  }

  render() {

    const { handleSubmit, initialValues, t } = this.props;

    const removeIcon = IconUtil.getIcon('Close', '#3E3935');

    const logoContainer = this.state.img ? <div className='inputRow inputRowImg'>
      <img
        src={`data:image/${this.state.ext};base64,${this.state.img}`}
        alt='Company Logo'/>
      <span className='removeLogo' onClick={this.handleResetImg}>{removeIcon}</span>
    </div> :
    <div className='inputRow'>
      <AttachFilesForm
        insideForm
        label={t('ReceiptSettingsForm.CustomerFaceLogo')}
        displayType={AttachFilesFormType.LOGO}
        logoSetState={this.handleAddLogo}
        filesCopy={t('UploadLogo')}
        dropzoneOptions={attachFileOptions.imageSinglePngJpgDropzoneOptions}
        onSubmit={null} />
    </div>;

    return (
      <form  onSubmit={handleSubmit} className='receiptSettingsForm'>

        {initialValues.has_cf_display &&
        <div className='inputRow'>
          <Field
            component={CustomToggle}
            label={t('ReceiptSettingsForm.ThisLogo')}
            name='cf_logo'/>
        </div>}

        {initialValues.has_cf_display && !this.props.cf_logo_value &&
        <div className='inputRow'>
          <Field
            component={CustomToggle}
            label={t('ReceiptSettingsForm.DifferentLogo')}
            name='cf_logo_custom'/>
        </div>}

        {!this.props.cf_logo_value && this.props.cf_logo_custom_value && logoContainer}

        <div className='flexRow'>
          <Field
            component={CustomTextField}
            label={t('ReceiptSettingsForm.BusinessName')}
            maxLength='50'
            name='merchant_name'
            hintText={t('ReceiptSettingsForm.BusinessNameHint')}
            className='threeQuarterScreen businessName'
          />
          <Field
            component={BrandColorPicker}
            label={t('ReceiptSettingsForm.BrandColor')}
            maxLength='50'
            name='brand_color'
            className='quarterScreen brandColor'
            color={this.props.color}
            handleClick={this.props.setShowColorPicker}
            showColorPicker={this.props.showColorPicker}
            handleChange={this.props.handleColorChange}
          />
        </div>
        <div className='inputRow'>
          <Field
            component={CustomTextField}
            label={t('ReceiptSettingsForm.StreetAddress')}
            name='address'
            hintText={t('ReceiptSettingsForm.StreetAddressHint')}
          />
        </div>
        <div className='flexRow'>
          <Field
            component={CustomTextField}
            className='halfScreen'
            label={t('City')}
            name='city'
            hintText={t('City')}
          />
          <Field
            className='quarterScreen'
            name='state'
            component={Select}
            label={t('State')}>
            {states.content.map((state, i) =>
              <MenuItem
                value={state}
                key={i}>
                {state}
              </MenuItem>)}
          </Field>
          <Field
            className='quarterScreen'
            label={t('ReceiptSettingsForm.ZipCode')}
            component={CustomTextField}
            normalize={TextUtil.formatZip}
            name='zip'
            hintText='00000'
          />
        </div>
        <div className='inputRow'>
          <Field
            component={CustomTextField}
            label={t('ReceiptSettingsForm.BusinessPhone')}
            normalize={TextUtil.formatPhoneNumber}
            name='phone_number'
            hintText='000-000-0000'
          />
        </div>
        <div className='flexRow'>
          <Field
            component={CustomTextField}
            label={t('ReceiptSettingsForm.Website')}
            className='halfScreen'
            name='website'
            hintText='www.website.com'
          />
          <Field
            component={CustomTextField}
            label={t('ReceiptSettingsForm.BusinessEmail')}
            className='halfScreen'
            name='email_address'
            hintText='customer@website.com'
          />
        </div>

        <div className='flexRow'>
          <Field
            component={CustomTextField}
            label={t('ReceiptSettingsForm.FacebookUsername')}
            className='halfScreen'
            name='facebook_link'
            disabled={!this.props.socialMediaEnabled}
            hintText={t('UsernameLabel')}
          />
          <Field
            component={CustomTextField}
            label={t('ReceiptSettingsForm.InstagramUsername')}
            className='halfScreen'
            name='instagram_link'
            disabled={!this.props.socialMediaEnabled}
            hintText={t('UsernameLabel')}
          />
        </div>
        <div className='flexRow'>
          <Field
            component={CustomTextField}
            label={t('ReceiptSettingsForm.XUsername')}
            className='halfScreen'
            name='twitter_link'
            disabled={!this.props.socialMediaEnabled}
            hintText={t('UsernameLabel')}
          />
          <Field
            component={CustomTextField}
            label={t('ReceiptSettingsForm.YelpUsername')}
            className='halfScreen'
            name='yelp_link'
            disabled={!this.props.socialMediaEnabled}
            hintText={t('UsernameLabel')}
          />
        </div>

        <div style={{paddingBottom: 35, borderBottom: '1px solid #DDDDDD'}}/>

        <h3 className='subheading'>{t('ReceiptSettingsForm.ReceiptSettings')}</h3>
        <p className='description'>{t('ReceiptSettingsForm.ReceiptSettingsDesc')}</p>


        <div className='inputSection'>
          <div className='inputRow'>
            <Field
              component={CustomToggle}
              label={t('ReceiptSettingsForm.BCCEmailReceipts')}
              name='should_bcc'
            />
          </div>
          <div className='inputRow'>
            <Field
              component={CustomTextField}
              label='BCC Email Address'
              label={t('ReceiptSettingsForm.BCCEmailReceipts')}
              name='bccEmailAddresses'
              disabled={!this.props.bccEmailEnabled}
              hintText={t('ReceiptSettingsForm.EmailHintText')}
              className='bccEmail'
            />
          </div>
        </div>
        <div className='inputSection'>

          <div className='flexRow'>
            <Field
              component={CustomToggle}
              label={t('ReceiptSettingsForm.CustomerRating')}
              name='customer_rating_enabled'
              customClass='halfScreen'
            />
            <Field
              component={CustomToggle}
              label={t('ReceiptSettingsForm.LogoPrintedReceipt')}
              name='display_company_logo'
              customClass='halfScreen'
            />
          </div>

          <div className='flexRow'>
            <Field
              component={CustomToggle}
              label={t('ReceiptSettingsForm.ESN')}
              name='esn_enabled'
              customClass='halfScreen'
            />
            <Field
              component={CustomToggle}
              label={t('ReceiptSettingsForm.SocialMediaButtons')}
              name='social_media_enabled'
              customClass='halfScreen'
            />
          </div>

          <div className='flexRow'>
            <Field
              component={CustomToggle}
              label={t('ReceiptSettingsForm.DisplaySoldBy')}
              name='display_sold_by'/>
          </div>
        </div>

        <div className='flexRow'>
          <Field
            component={CustomTextField}
            label={t('ReceiptSettingsForm.CustomFooterMessage')}
            hintText={t('ReceiptSettingsForm.MessageHint')}
            name='receipt_disclaimer'
          />
        </div>
        <div className='flexRow'>
          <Field
            component={Select}
            name='receipt_options'
            label={t('ReceiptSettingsForm.Options')}>
            <MenuItem disabled value={null}>{t('ReceiptSettingsForm.SelectOption')}</MenuItem>
            <MenuItem value='display'>{t('ReceiptSettingsForm.Display')}</MenuItem>
            <MenuItem value='skip_print'>{t('ReceiptSettingsForm.SkipPrint')}</MenuItem>
            <MenuItem value='skip'>{t('ReceiptSettingsForm.Skip')}</MenuItem>
          </Field>
        </div>

      </form>);

  }

}

let ReceiptSettingsForm = reduxForm({
  form: 'receiptSettingsForm',
  validate,
  fields: [
    'address',
    'city',
    'state',
    'zip',
    'website',
    'merchant_name',
    'should_bcc',
    'display_sold_by',
    'esn_enabled',
    'customer_rating_enabled',
    'social_media_enabled',
    'facebook_link',
    'instagram_link',
    'twitter_link',
    'yelp_link',
    'custom_search_enabled',
    'display_company_logo',
    'bccEmailAddresses',
    'email_address',
    'phone_number',
    'receipt_options',
    'brand_color'
  ],
  enableReinitialize: true
})(ReceiptSettingsFormComponent);

const selector = formValueSelector('receiptSettingsForm');

function mapStateToProps(state, ownProps) {

  const initialValues = state.merchantSettings.merchantSettings ? state.merchantSettings.merchantSettings : {};

  initialValues.bccEmailAddresses = initialValues?.bcc_email_addresses?.length && _.isArray(initialValues.bcc_email_addresses) && initialValues.bcc_email_addresses[0] !== '{}' ? SettingsUtil.splitEmails(initialValues.bcc_email_addresses) : '';

  initialValues.receipt_options = initialValues.receipt_options_enabled && !initialValues.receipt_always_print ? 'display' : !initialValues.receipt_options_enabled && initialValues.receipt_always_print ? 'skip_print' : 'skip';

  initialValues.phone_number = initialValues.phone_number && TextUtil.formatPhoneNumber(initialValues.phone_number);

  initialValues.address = initialValues.street_address && initialValues.street_address.address1;
  initialValues.city = initialValues.street_address && initialValues.street_address.city;
  initialValues.state = initialValues.street_address && initialValues.street_address.state;
  initialValues.zip = initialValues.street_address && initialValues.street_address.zip_code;

  //selectors
  const socialMediaEnabled = selector(state, 'social_media_enabled');
  const bccEmailEnabled = selector(state, 'should_bcc');
  const cf_logo_value = selector(state, 'cf_logo');
  const cf_logo_custom_value = selector(state, 'cf_logo_custom');
  const valid = isValid('receiptSettingsForm')(state);
  const pristine = isPristine('receiptSettingsForm')(state);

  const submissionOk = !valid && !pristine;

  return {
    initialValues,
    socialMediaEnabled,
    bccEmailEnabled,
    submissionOk,
    cf_logo_value,
    cf_logo_custom_value
  }

}

ReceiptSettingsForm = connect(mapStateToProps)(ReceiptSettingsForm);

export default ReceiptSettingsForm;
