/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Field, reduxForm, getFormValues, change, getFormMeta} from 'redux-form';
import { Box, MenuItem } from '@mui/material';

import countryList, { countryStateList } from '../../constants/countryStateList';
import businessTypes from '../../constants/businessTypes';

import Select from '../shared/Select';
import SearchableSelect from '../shared/SearchableSelect';
import TextField from '../shared/TextField';
import Tooltip from '../shared/Tooltip';

import FormatTextUtil from '../util/FormatTextUtil';
import IconUtil from '../util/IconUtil';
import LabelUtil from '../util/LabelUtil';
import Validator from '../util/Validator';
import BusinessPercentField from './BusinessPercentField'

import { getMerchantCategoryCodes } from '../../actions/merchantCategoryActions';
import { inputContainer, largeInput, smallInput, activationBusinessFormStyles } from '../../jss/activationStyles';

import {
  MOTO,
  INTERNET,
  RESTAURANT,
  RETAIL,
  KEYED,
  CARD_PRESENT,
  BUSINESS_MODEL,
  ACCOUNT_TYPES,
  DELIMITER,
  SOLE_PROPRIETOR
} from '../../constants/activationBusiness';
import activationStatuses from '../../constants/activationStatuses';


export const validate = (values) => Validator.validateActivateBusinessForm(values);

export const onSubmit = () => null;

const statesList = countryStateList('United States');

export const mapBusinessFormValuesToBoardingApplication = (formValues, merchantCategories) => {
  const values = {...formValues};

  const merchantCategory = merchantCategories?.find(merchant => `${merchant.mcc}` === `${values.mcc}`);

  if (Boolean(merchantCategory)) {
    const fieldsToAdd = [
      'averageMonthlyVolume',
      'averageTicketAmount',
      'highTicketAmount',
      'merchandiseServicesSold'
    ]

    fieldsToAdd.map(field => {
      let value = merchantCategory[field];

      if (field === 'merchandiseServicesSold') {
        value =  merchantCategory.description;
      }
      values[field] = value
    });
  }

  return  {
     type: activationStatuses.BUSINESS,
     business: {
       corporate_name: values.corporateName,
       dba_name: values.dbaName,
       business_type: values.businessType,
       federal_tax_id_number: values.federalTaxIdNumber,
       federal_tax_id_type: values.businessType === SOLE_PROPRIETOR ? 'SSN' : 'EIN',
       mcc: `${values.mcc}`,
       average_monthly_volume: parseFloat(values.averageMonthlyVolume),
       average_ticket_amount: parseFloat(values.averageTicketAmount),
       high_ticket_amount: parseFloat(values.highTicketAmount),
       merchandise_services_sold: values.merchandiseServicesSold,
       industry_type: values.industryType,
       email: values.email,
       phone: values.phone.replace(/\(|\)|-| /g, ''),
       business_address: {
         dba: {
           street: values.street,
           street2: values.street2,
           city: values.city,
           state: values.state || statesList.find((state) => values.state === state.label)?.key,
           zip_code: values.zipCode,
         },
       },
       websites: [
         {
           url: values.businessWebsite?.toLowerCase()
         }
       ],
       percent_of_business_transactions: {
         card_swiped: values.cardPresent,
         keyed_card_present_not_imprinted: values.keyed,
         mail_or_phone_order: values.moto,
         internet: values.internet
       }
     },
     bank_account: {
       aba_routing: values.routingNumber,
       account_type: values.accountType,
       demand_deposit_account: values.accountNumber
     }
   };
}

export class ActivationBusinessFormContainer extends Component {

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const { dispatch, user, merchantCategories: { merchantCategories, isFetching, error } } = this.props;

    if (!merchantCategories && !isFetching && !error) {
      dispatch(getMerchantCategoryCodes(user));
    }
  }

  getMccOptions = (merchantCategoryCodes) => {
    return merchantCategoryCodes
      .map((merchantCategory) => ({
        value: merchantCategory.mcc,
        label: `${merchantCategory.mcc} - ${merchantCategory.description}`,
      }));
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { currentValues, merchantCategories } = this.props;

    if (prevProps.currentValues.industryType !== currentValues.industryType) {
      if ([INTERNET.key, MOTO.key].includes(currentValues.industryType)) {
        this.changeFieldValue(currentValues.industryType, 100);
        [KEYED.key, CARD_PRESENT.key, MOTO.key, INTERNET.key]
            .filter(field => field !== currentValues.industryType)
            .map(field => this.changeFieldValue(field));
      }
    }
  }

  calculateBusinessPercent = (values) => {
    return values.reduce((acc, value) => {
      if (typeof value === 'number' && !isNaN(value)) {
        return acc + value;
      }
      return acc;
    }, 0);
  }

  changeFieldValue = (field, value = 0) => {
    const { dispatch } = this.props;
    dispatch(change('activationBusinessForm', field, value))
  }

  formatTaxField = (value) => {
    const { currentValues } = this.props;

    const isSoleProprietor = currentValues.businessType === SOLE_PROPRIETOR;

    if (isSoleProprietor) {
      return FormatTextUtil.formatFullSSNWithDelimiter(value, DELIMITER);
    }

    return FormatTextUtil.formatTaxId(value);
  }

  normalizeFormatTaxIdField = value => FormatTextUtil.formatWholeNumberWithDelimiter(value, 9)

  render() {

    const { handleSubmit, currentValues, meta, t, merchantCategories, visibilityFilter, initialValues } = this.props;

    const label = LabelUtil.getLabel();
    const infoIcon = IconUtil.getIcon('InfoIcon', label.primaryColor);
    const merchantCategoryCodes = merchantCategories?.filteredMerchantCategories || [];

    const isMotoOrInternetIndustryType = [MOTO.key, INTERNET.key].includes(currentValues.industryType);

    const disableMotoField = currentValues.industryType === INTERNET.key;
    const disableInternetField = currentValues.industryType === MOTO.key;

    const showBusinessWebsiteField = currentValues.industryType === INTERNET.key ||
      ([RESTAURANT.key, RETAIL.key].includes(currentValues.industryType) && currentValues.internet > 0);

    const currentTotalBusinessPercent =
      this.calculateBusinessPercent([currentValues.internet, currentValues.keyed, currentValues.cardPresent, currentValues.moto]);

    const anyBusinessPercentTouched = meta?.cardPresent?.touched || meta?.keyed?.touched ||
      meta?.moto?.touched || meta?.internet?.touched;

    const showBusinessPercentError = anyBusinessPercentTouched && currentTotalBusinessPercent !== 100;

    const isSoleProprietor = currentValues.businessType === SOLE_PROPRIETOR;

    const mccOptions = this.getMccOptions(merchantCategoryCodes);

    return (
      <Box component='form' id='activationBusinessForm' onSubmit={handleSubmit} sx={activationBusinessFormStyles}>

        <Box sx={inputContainer}>
          <Box sx={largeInput}>
            <Field
              label={t('Activation.Flow.Step2.LegalBusinessName')}
              className='large'
              hintText={t('Activation.Flow.Step2.Hint.LegalBusinessName')}
              component={TextField}
              fullWidth
              name='corporateName'
              id='corporateName'
            />
          </Box>
        </Box>

        <Box sx={inputContainer}>
          <Box sx={largeInput}>
            <Field
              label={t('DBAName')}
              className='large'
              hintText={t('Activation.Flow.Step2.Hint.DBAName')}
              component={TextField}
              fullWidth
              name='dbaName'
              id='dbaName'
            />
          </Box>
        </Box>

        <Box sx={inputContainer}>
          <Box sx={largeInput}>
            <Field
              label={t('Activation.Flow.Step2.BusinessType')}
              className='large'
              component={Select}
              fullWidth
              name='businessType'
            >
              <MenuItem value={-1} disabled={true}>{t('Select')}</MenuItem>
              {businessTypes.map((businessType, index) => (
                <MenuItem value={businessType.value} key={index}>{businessType.name}</MenuItem>
              ))}
            </Field>
          </Box>
          <Box sx={smallInput} className='taxIdContainer'>
            <Field
              label={isSoleProprietor ?
                t('Activation.Flow.Step2.FederalTaxIDSoleProprietor'):
                t('Activation.Flow.Step2.FederalTaxID')}
              hintText={isSoleProprietor ?
                t('Activation.Flow.Step2.Hint.FederalTaxIDSoleProprietor') :
                t('Activation.Flow.Step2.Hint.FederalTaxID') }
              component={TextField}
              className='large'
              fullWidth
              name='federalTaxIdNumber'
              id='federalTaxIdNumber'
              format={this.formatTaxField}
              normalize={this.normalizeFormatTaxIdField}
              adornment={
                <Tooltip
                  placement='top'
                  title={t('Activation.Flow.Step2.TaxIdToolTip')}
                  className='tooltip'
                >
                  {infoIcon}
                </Tooltip>
              }
            />
          </Box>
        </Box>


        <Box sx={inputContainer}>
          <Box sx={largeInput}>
            <Field
              label={t('Activation.Flow.Step2.MerchantCategoryCode')}
              className='large'
              hintText={t('Activation.Flow.Step2.Hint.MerchantCategoryCode')}
              component={SearchableSelect}
              fullWidth
              name='mcc'
              id='mcc'
              t={t}
              visibilityFilter={visibilityFilter}
              options={mccOptions}
            />
          </Box>
        </Box>

        <Box sx={inputContainer}>
          <Box sx={smallInput} className={'firstRowInput'}>
            <Field
              label={t('Activation.Flow.Step2.PrimaryBusinessModel')}
              className='large'
              component={Select}
              fullWidth
              name='industryType'
            >
              <MenuItem value={-1} disabled={true}>{t('Select')}</MenuItem>
              {
                BUSINESS_MODEL
                .map(model =>
                  <MenuItem value={model.key} key={model.key}>{model.label}</MenuItem>)
              }
            </Field>
          </Box>
        </Box>

        <Box sx={inputContainer}>
          <Box sx={smallInput} className={'firstRowInput'}>
            <BusinessPercentField
              label={t('Activation.Flow.Step2.CardPresent')}
              className='large'
              hintText={t('Activation.Flow.Step2.CardPresent')}
              name='cardPresent'
              disabled={isMotoOrInternetIndustryType}
            />

          </Box>
          <Box sx={smallInput}>
            <BusinessPercentField
              label={t('Activation.Flow.Step2.Keyed')}
              className='large'
              hintText={t('Activation.Flow.Step2.Keyed')}
              name='keyed'
              disabled={isMotoOrInternetIndustryType}
            />
          </Box>
          <Box sx={smallInput}>
            <BusinessPercentField
              label={t('Activation.Flow.Step2.Moto')}
              className='large'
              hintText={t('Activation.Flow.Step2.Moto')}
              name='moto'
              disabled={disableMotoField}
            />
          </Box>
          <Box sx={smallInput}>
            <BusinessPercentField
              label={t('Activation.Flow.Step2.Internet')}
              className='large'
              hintText={t('Activation.Flow.Step2.Internet')}
              name='internet'
              disabled={disableInternetField}
            />
          </Box>
        </Box>
        {
          showBusinessPercentError &&
          <Box sx={inputContainer}>
            <Box sx={largeInput}>
              <p className='information'>
                {`*${t('Errors.InvalidBusinessPercent')}. `}
                <b>{`${t('Current')} ${currentTotalBusinessPercent}%.`}</b>
              </p>
            </Box>
          </Box>
        }

        {
          showBusinessWebsiteField &&
          <Box sx={inputContainer}>
            <Box sx={largeInput}>
              <Field
                label={t('Activation.Flow.Step2.BusinessWebsite')}
                className='large'
                hintText={t('Activation.Flow.Step2.Hint.BusinessWebsite')}
                component={TextField}
                fullWidth
                name='businessWebsite'
              />
            </Box>
          </Box>
        }

        <Field name='averageMonthlyVolume' component='input' type='hidden' />
        <Field name='averageTicketAmount' component='input' type='hidden' />
        <Field name='highTicketAmount' component='input' type='hidden' />
        <Field name='merchandiseServicesSold' component='input' type='hidden' />

        <Box sx={inputContainer}>
          <Box sx={largeInput}>
            <Field
              label={t('Activation.Flow.Step2.BusinessEmail')}
              hintText={t('Activation.Flow.Step2.Hint.BusinessEmail')}
              component={TextField}
              fullWidth
              name='email'
              id='email'
            />
          </Box>
          <Box sx={smallInput}>
            <Field
              label={t('Activation.Flow.Step2.BusinessPhone')}
              hintText={t('EnterPhoneNumber')}
              component={TextField}
              fullWidth
              name='phone'
              id='phone'
              normalize={FormatTextUtil.formatPhoneNumber}
            />
          </Box>
        </Box>

        <Box sx={inputContainer}>
          <Box sx={largeInput}>
            <Field
              label={t('BusinessAddress')}
              className='large'
              hintText={t('Activation.Flow.Step1.Hint.Address')}
              component={TextField}
              fullWidth
              name='street'
              id='street'
            />
          </Box>
          <Box sx={smallInput}>
            <Field
              label={t('Activation.Flow.Step1.Apt')}
              className='large'
              hintText={t('Activation.Flow.Step1.Hint.Apt')}
              component={TextField}
              fullWidth
              name='street2'
              id='street2'
              normalize={FormatTextUtil.formatAlphaNumeric}
            />
          </Box>
        </Box>

        <Box sx={inputContainer}>
          <Box sx={largeInput}>
            <Field
              label={t('City')}
              className='large'
              hintText={t('EnterCity')}
              component={TextField}
              fullWidth
              name='city'
              id='city'
            />
          </Box>
          <Box sx={smallInput}>
            <Field
              className='large'
              component={Select}
              fullWidth
              label={t('State')}
              name='state'
            >
              <MenuItem value={-1} disabled={true}>
                {t('State')}
              </MenuItem>
              {countryList[0].stateCodes.map((state, index) => (<MenuItem value={state} key={index}>{state}</MenuItem>))}
            </Field>
          </Box>
          <Box sx={smallInput}>
            <Field
              label={t('Activation.Flow.Step1.Zip')}
              className='large'
              hintText={t('Activation.Flow.Step1.Hint.Zip')}
              component={TextField}
              fullWidth
              name='zipCode'
              id='zipCode'
              normalize={FormatTextUtil.formatZip}
            />
          </Box>
        </Box>

        <div className='stepContainer__title secondTitle'>
          <div className='title'>{t('Activation.Flow.Step2.SecondTitle')}</div>
          <div className='tooltipHolder'>
              <Tooltip
                placement='top'
                title={t('Activation.Flow.Step2.DepositToolTip')}
              >
                <p>{infoIcon}</p>
              </Tooltip>
            </div>
        </div>

        <Box sx={inputContainer}>
          <Box sx={smallInput} className={'accountType'}>
            <Field
              label={t('Activation.Flow.Step2.DepositAccountType')}
              className='large'
              component={Select}
              fullWidth
              name='accountType'
            >
              <MenuItem value={-1} disabled={true}>{t('Select')}</MenuItem>
              {
                ACCOUNT_TYPES.map(accountType => (
                  <MenuItem value={accountType.key} key={accountType.key}>{t(accountType.label)}</MenuItem>
                ))
              }
            </Field>
          </Box>
          <Box sx={smallInput}>
            <Field
              label={t('AccountNumber')}
              className='large'
              hintText={t('Activation.Flow.Step2.Hint.AccountNumber')}
              component={TextField}
              fullWidth
              name='accountNumber'
              id='accountNumber'
              maxLength={17}
              normalize={FormatTextUtil.formatWholeNumber}
            />
          </Box>
          <Box sx={smallInput}>
            <Field
              label={t('RoutingNumber')}
              className='large'
              hintText={t('Activation.Flow.Step2.Hint.RoutingNumber')}
              component={TextField}
              fullWidth
              name='routingNumber'
              id='routingNumber'
              maxLength={9}
              normalize={FormatTextUtil.formatWholeNumber}
            />
          </Box>
        </Box>
        <p className='inputDescription'>{t('Activation.Flow.Step2.DepositAccountDescription')}</p>
      </Box>
    )
  }
}

let ActivationBusinessForm = reduxForm({
  form: 'activationBusinessForm',
  validate,
  enableReinitialize: true,
  onSubmit
})(ActivationBusinessFormContainer);

export function mapStateToProps(state) {
  const { activation, form } = state;
  const currentValues = getFormValues('activationBusinessForm')(state) || {};
  const meta = getFormMeta('activationBusinessForm')(state) || {};

  const initialValues = {
    ...activation.businessForm,
    phone: FormatTextUtil.formatPhoneNumber(activation.businessForm?.phone),
    state: activation.businessForm?.state || -1,
  }

  return {
    initialValues,
    meta,
    currentValues
  }
}

ActivationBusinessForm = connect(mapStateToProps)(ActivationBusinessForm);

export default ActivationBusinessForm;
