/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react'
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm, getFormValues, change } from 'redux-form';
import { Trans } from 'react-i18next';
import numeral from 'numeral';
import Checkbox from './shared/Checkbox';
import FormatTextUtil from './util/FormatTextUtil';
import IconUtils from './util/IconUtil';
import LabelUtil from './util/LabelUtil';
import LineItemList from './LineItemList';
import Loading from './Loading';
import MessageDialog from './shared/MessageDialog';
import TextField from './shared/TextField';
import Toggle from './shared/Toggle';
import Validator from './util/Validator';
import messages from '../constants/messages';
import { roundToTwoDecimals, capitalizeFirstLetter } from './util/CommonUtil';
import _ from 'lodash';
import InvoiceUtil from './util/InvoiceUtil';
import { Box } from '@mui/material';
import { ItemizedOrCustomTabsStyles } from '../jss/inlineStyles'

const validate = (values) => {
  return Validator.validateRefunds(values);
};
const normalizeCurrency = (value) => FormatTextUtil.formatCurrencyWithMaxDigit(value, 20);

export class PaymentDialog extends React.Component {

  constructor(props) {
    super(props);
    this.calculateRefundTotals = this.calculateRefundTotals.bind(this);
    this.resetRefundQuantitiesAndTip = this.resetRefundQuantitiesAndTip.bind(this);
    this.getFormError = this.getFormError.bind(this);
    this.getSubtotal = this.getSubtotal.bind(this);
    this.cancelDialogAction = this.cancelDialogAction.bind(this);

  }

  componentDidUpdate(prevProps) {

    const { currentValues } = this.props;

    if (currentValues.refundAll && currentValues.refundAll !== prevProps.currentValues.refundAll) {
      this.resetRefundQuantitiesAndTip(currentValues);
    }
  }

  resetRefundQuantitiesAndTip(currentValues) {
    const {lineItems, originalTip, refundTip} = currentValues;

    currentValues && lineItems && lineItems.length && lineItems.map((item, itemIndex) => {
      const originalValue = currentValues && parseInt(lineItems[itemIndex].originalQuantity);
      const currentValue = currentValues && parseInt(lineItems[itemIndex].refundQuantity);

      if (currentValue !== originalValue) {
        this.props.dispatch(change('paymentDialogForm', `lineItems[${itemIndex}][refundQuantity]`, originalValue));
      }
    });

    currentValues && originalTip
      && parseFloat(numeral(refundTip).value()) !== parseFloat(originalTip)
      && this.props.dispatch(change('paymentDialogForm', 'refundTip', numeral(originalTip).format('$0,0.00')));
  }

  getSubtotal(item, index, mappedModifiers, currentValues) {

    const { selectedReceipt } = this.props.transactions;

    const totalOfItems = currentValues?.lineItems?.reduce((accumulator, item) => accumulator + item.originalQuantity, 0);
    const serviceFeeAmount = parseFloat(selectedReceipt.service_fee_amount || 0);

    const spreadValueForItems = serviceFeeAmount/totalOfItems;
    // Depending on trx source
    const txnSource = selectedReceipt?.txn_source?.toLowerCase() || '';
    const txnFromMobile = ['ios', 'android', 'phone', 'smart', 'mobile'].some(source => txnSource.includes(source));

    const itemQuantity = item.originalQuantity;
    const modifiersPrice = mappedModifiers[index]?.map(modifier => parseFloat(modifier?.price)) || [];

    let itemAmount = selectedReceipt.item_unit_price[index] - spreadValueForItems;

    const totalItemAmount =  itemAmount * itemQuantity;
    const totalModifiersAmount =  modifiersPrice.reduce((accumulator, price) => accumulator + price, 0) * itemQuantity;

    let totalDiscountsAmount = selectedReceipt.item_discount_amt[index] * itemQuantity;
    const hasDiscountAmt = !!selectedReceipt.item_discount_amt[index];
    let totalDiscountRate = selectedReceipt.item_discount_rate[index] / 100;
    const hasDiscountRate = !!selectedReceipt.item_discount_rate[index];

    if (txnFromMobile) {
      totalDiscountsAmount = selectedReceipt.item_discount_amt[index];
    }

    const itemSubtotalNoDiscount = totalItemAmount + totalModifiersAmount;
    let discount = 0;

    if (hasDiscountRate) {
      discount = itemSubtotalNoDiscount * totalDiscountRate;
    } else if (hasDiscountAmt) {
      discount = totalDiscountsAmount;
    }

    const itemSubtotal = roundToTwoDecimals(itemSubtotalNoDiscount - discount);
    return itemSubtotal;
  }

  calculateRefundTotals(currentValues, selectedReceipt, existingPartialRefundsTotal = 0, existingPartialRefundsTax = 0) {

    const refundTotals = {
      subtotal: 0,
      tax: 0,
      total: 0
    }

    if (currentValues && selectedReceipt) {

      //If refund all, use values from original transaction (which include any receipt discounts); else calculate totals based only on items selected
      if (currentValues.refundAll) {

        let refundableSubtotal, refundableTax, refundableTotal; //Before any existing partial refunds

        if (selectedReceipt.isSplitPayment) {

          const parsedTip = roundToTwoDecimals(selectedReceipt.tip_amount) || 0;
          refundableTotal = roundToTwoDecimals(selectedReceipt.amount) || 0;

          refundableSubtotal = (parseFloat(selectedReceipt.taxable_amt) > 0)
            ? roundToTwoDecimals(selectedReceipt.taxable_amt)
            : roundToTwoDecimals(refundableTotal - parsedTip);

          refundableTax = (parseFloat(selectedReceipt.taxable_amt) > 0)
            ? roundToTwoDecimals(refundableTotal - refundableSubtotal - parsedTip)
            : 0;

        } else {
          refundableSubtotal = roundToTwoDecimals(selectedReceipt.sub_total_amt);
          refundableTax = roundToTwoDecimals(selectedReceipt.tax_amt);
          refundableTotal = roundToTwoDecimals(selectedReceipt.total_amt);
        }

        refundTotals.subtotal = roundToTwoDecimals(refundableSubtotal + roundToTwoDecimals(existingPartialRefundsTotal) - roundToTwoDecimals(existingPartialRefundsTax)) || 0;
        refundTotals.tax = roundToTwoDecimals(refundableTax + roundToTwoDecimals(existingPartialRefundsTax)) || 0;
        refundTotals.total = roundToTwoDecimals(refundableTotal + roundToTwoDecimals(existingPartialRefundsTotal)) || 0;

      } else {

        const hasItems = !!(selectedReceipt.item_names && selectedReceipt.item_names.length);
        const hasModifiers = !!(selectedReceipt.modifier_sets && selectedReceipt.modifier_sets.length && !_.isEmpty(selectedReceipt.modifier_sets.filter(item => !!item)));
        let mappedModifiers = []; //Will be an array of arrays. One array of modifiers for each item

        const isModifiersSimpleArray = _.isEmpty(selectedReceipt.modifier_sets && selectedReceipt.modifier_sets.filter(modifier => Array.isArray(modifier))); //Simple array without nested arrays

        if (hasItems && hasModifiers) {

          mappedModifiers = !isModifiersSimpleArray
            ? selectedReceipt.modifier_sets
            : selectedReceipt.item_ids.map(itemId => selectedReceipt.modifier_sets.filter(modifier => modifier && modifier.includes(itemId)));

          mappedModifiers = mappedModifiers.map(InvoiceUtil.mapModifiers);
        }

        let itemsToBeRefunded = [];

        const cdVersion = selectedReceipt.cd_version;

        if (cdVersion === 4) {
          itemsToBeRefunded =  currentValues?.lineItems?.map((item, index) => {
            const totalItems = item.originalQuantity;

            let modifiersAmount = mappedModifiers[index]?.map(modifier => parseFloat(modifier?.price) + (modifier?.service_fee_total_amt / totalItems)) || []; // includes CD
            modifiersAmount =  modifiersAmount.reduce((acc, price) => acc + price, 0) * totalItems;

            const priceTotalAmount = selectedReceipt.price_total_amount[index];
            const discountTotalAmount = selectedReceipt.item_discount_total_amt[index] || 0;
            const quantityToBeRefunded = item.refundQuantity;
            const amountToBeRefunded = ((priceTotalAmount + modifiersAmount - discountTotalAmount) / totalItems ) * quantityToBeRefunded;
            const taxRate = selectedReceipt.item_tax_rate[index];
            const itemTax = taxRate ? selectedReceipt.item_tax_rate[index] / 100 : 0;
            const tax = amountToBeRefunded * itemTax;
            return {
              amount: amountToBeRefunded,
              tax,
            }
          }) || [];
        } else {

          const serviceFeeAmount = parseFloat(selectedReceipt.service_fee_amount || 0);

          const subTotal = currentValues?.lineItems?.map((item, index) => this.getSubtotal(item, index, mappedModifiers, currentValues))?.reduce((accumulator, itemSubtotal) => accumulator + itemSubtotal, 0) || 0;
          const cdRatio = roundToTwoDecimals(serviceFeeAmount / subTotal) || 0;

          itemsToBeRefunded = currentValues?.lineItems?.map((item, index) => {
            const itemSubtotal = this.getSubtotal(item, index,  mappedModifiers, currentValues);

            const cdAppliedTotal = roundToTwoDecimals(itemSubtotal + (itemSubtotal * cdRatio));

            const quantityToBeRefunded = item.refundQuantity;
            const itemQuantity = item.originalQuantity;

            const amountToBeRefunded = (cdAppliedTotal / itemQuantity) * quantityToBeRefunded;
            const taxRate = selectedReceipt.item_tax_rate[index];
            const itemTax = taxRate ? selectedReceipt.item_tax_rate[index] / 100 : 0;
            const tax = amountToBeRefunded * itemTax;

            return {
              amount: amountToBeRefunded,
              tax,
            }
          }) || [];
        }

        refundTotals.subtotal = itemsToBeRefunded.reduce((accumulator, item) => accumulator + item.amount, 0);
        refundTotals.subtotal = isNaN(refundTotals.subtotal) ? 0 :  refundTotals.subtotal;

        refundTotals.tax =  itemsToBeRefunded.reduce((accumulator, item) => accumulator + item.tax, 0);
        refundTotals.tax =  isNaN(refundTotals.tax) ? 0 : refundTotals.tax;
      }

      const tipAmount = parseFloat(selectedReceipt.tip_amount) || 0;

      refundTotals.total = _.round((refundTotals.subtotal) + refundTotals.tax +  tipAmount, 2);
      refundTotals.total  = isNaN(refundTotals.total) ? 0 : refundTotals.total;
    };

    return refundTotals;
  }

  getFormError(currentValues, refundMessages, totalExceedsLimit, t) {
    let formErrorMessage = null;
    if (totalExceedsLimit &&  currentValues.isItemized) {
      formErrorMessage =  <div className='formErrorMessage'>{t(refundMessages.refundExceedsLimit)}</div>
    } else if(totalExceedsLimit && !currentValues.isItemized) {
      formErrorMessage =  <div className='formErrorMessage customAmount'>{t(refundMessages.refundAmountExceedsLimit)}</div>
    }
    return formErrorMessage;
  }

  cancelDialogAction(...args ) {
    const { dialogActions, currentValues } = this.props;

    this.resetRefundQuantitiesAndTip(currentValues);
    return dialogActions.cancel.onClick(...args)
  }

  render() {
    const { dialogActions, currentValues, valid, closeAction, isSimplifiedDialog, merchantSettings, t } = this.props;

    const {
      operationType,
      showConfirmation,
      showError,
      refundAsVoid,
      errorMessage,
      paymentDialogOpen
    } = this.props.userExperience.modalVisibility;

    const {handleSubmit, selectedReceipt, isProcessing, isFetchingReceiptPdf} = this.props.transactions;

    if (selectedReceipt) {

      const isComplete = selectedReceipt.is_complete;
      const isVoidOperation = operationType && operationType.toUpperCase() === 'VOID';
      const isCompleteOperation = operationType && operationType.toUpperCase() === 'COMPLETE';
      const isCancelOperation = operationType && operationType.toUpperCase() === 'CANCEL';
      const isRefundOperation = operationType && operationType.toUpperCase() === 'REFUND';
      const activityIndicator = (isProcessing || isFetchingReceiptPdf) ? (<Loading/>) : null;
      const dialogTitle = showConfirmation || showError ? null : t(`${operationType && capitalizeFirstLetter(operationType)}Transaction`);
      const refundMessages = messages.refunds;
      const refundableTotal = isComplete ? selectedReceipt.isSplitPayment ? selectedReceipt.amount : selectedReceipt.total_amt : selectedReceipt.ccs_authorized_amt;

      const isTCCGiftCardRefund = isRefundOperation &&
        merchantSettings?.merchantSettings?.gc_type === 'TCC' &&
        selectedReceipt.network === 'Gift Card';

      const usingSimplifiedDialog = isVoidOperation ? true : isSimplifiedDialog;

      const hasPartialRefunds = !!(selectedReceipt && selectedReceipt.subTransactions && (selectedReceipt.subTransactions.length > 0));
      let existingPartialRefundsTip = 0;
      let existingPartialRefundsTotal = 0; //Any existing partial refund total is a negative amount!
      let existingPartialRefundsTax = 0;

      if (hasPartialRefunds) {
        selectedReceipt.subTransactions.map((subtransaction) => {
          existingPartialRefundsTotal += subtransaction.amount;
          existingPartialRefundsTax += subtransaction.partial_tax;
          //Subtransaction object doesnt currently include amount of partial tips refunded, if it did it could be used for existingPartialRefundsTip
        });
      }

      const refundTotals = this.calculateRefundTotals(currentValues, selectedReceipt, existingPartialRefundsTotal, existingPartialRefundsTax);

      const currentTipLimit = numeral(roundToTwoDecimals(roundToTwoDecimals(selectedReceipt.tip_amount) + roundToTwoDecimals(existingPartialRefundsTip))).format('$0,0.00');
      const currentTotalLimit = numeral(roundToTwoDecimals(roundToTwoDecimals(refundableTotal) + roundToTwoDecimals(existingPartialRefundsTotal))).format('$0,0.00');

      const totalInUse = currentValues.isItemized
        ? refundTotals.total
        : numeral(currentValues.customRefundAmount);

      const totalExceedsLimit = numeral(totalInUse).value() > numeral(currentTotalLimit).value();

      const isTotalInvalid = totalExceedsLimit || refundTotals.total <= 0;
      const isFormInvalid = !valid;

      const onConfirm = dialogActions && dialogActions.process
        ? (() => dialogActions.process.onClick(refundTotals))
        : (() => {});

      const cancelOnClick = dialogActions && dialogActions.cancel ? this.cancelDialogAction : (() => {});

      const confirmText = dialogActions && dialogActions.process
        ? dialogActions.process.label === 'Refund'
          ? `${t('Refund')} ${numeral(totalInUse).format('$0,0.00')}`
          : dialogActions.process.label
        : '';

      const cancelLabel = dialogActions && dialogActions.cancel ? dialogActions.cancel.label : '';

      const labelColor = LabelUtil.getLabelColor();

      const confirmationHeadingText = `Transaction${isVoidOperation ? 'Voided' : isCompleteOperation ? 'Completed' : isCancelOperation ? 'Cancelled' : 'Refunded'}`;

      const confirmationHeading = !refundAsVoid ? (
        <div className='headingHolder'>
          <h2 className='heading'>{t(confirmationHeadingText)}</h2>
        </div>
      ) : null;

      const refundAsVoidContent = refundAsVoid && (
        <div>
          <h2 className='heading'>{t('RefundAsVoidHeading')}</h2>
          <p>{t('RefundAsVoidInfo')}</p>
        </div>);

        const lineItems = selectedReceipt && !showError ? (
          <FieldArray
            {...this.props}
            name='lineItems'
            component={LineItemList}
            selectedReceipt={selectedReceipt}
            isTransactionDetails={true}
            currentFormValues={currentValues}
            hideInputFields={usingSimplifiedDialog}
            validate={() => undefined} //Prevents FieldArray being marked as invalid; Its fields will still be validated by validateRefunds
          />
        ) : null;


      const errorDisplayHeading = `Transaction${isVoidOperation ? 'Void' : isCompleteOperation ? 'Complete' : 'Refund'}Declined`;
      const errorDisplay = showError ? (
        <div className='iconHeading'>
          <div>{IconUtils.getIcon('Error', LabelUtil.getLabelColor())}</div>
          <div className='headingHolder'>
            <h2 className='heading'>{t(errorDisplayHeading)}</h2>
            <p>{`Error: ${t(errorMessage) ? t(errorMessage) : errorMessage}`}</p>
          </div>
        </div>) : null;

      const refundNote = (
        <Field
            className='refundTextField'
            component={TextField}
            hintText={t('RefundNoteHint')}
            label={t('Note')}
            name='note'
        />
      );

      const formErrorMessage = this.getFormError(currentValues, refundMessages, totalExceedsLimit, t);

      const existingPartialRefundsContent = hasPartialRefunds ?
        (<div className='previousPartialRefunds'>
          <p className='partialRefundsLabel'>{t('PartialRefundsLabel')}</p>
          {selectedReceipt.subTransactions.map((subtransaction, index) => {
            const partialRefundAmount = numeral(subtransaction.amount).format('$0,0.00');
            return (
              <div
                  className='partialRefundAmount'
                  key={index}
              >
                {`${t('PartialRefundAmount')} ${partialRefundAmount}`}
              </div>
            );
          })}
        </div>)
      : null;

      const cartDiscounts = currentValues.refundAll && selectedReceipt.receipt_discount_amt && selectedReceipt.receipt_discount_amt.length ? selectedReceipt.receipt_discount_amt.map((receiptDiscount, index) => {
        let percentageText;

        try {
          percentageText = selectedReceipt.receipt_discount_type[index] === 'Percentage'
            ? ` (${JSON.parse(selectedReceipt.receipt_discount_info[index]).percentage}% ${t('Off')})`
            : ''
        } catch (error) {
          percentageText = '';
        }

        return (
          <p className='details detailsCartDiscount' key={`cartDiscount${index}`}>
            <span className='text'>{`${t('Discount')}${percentageText}:`}</span>
            <span className='number'>{'-' + numeral(receiptDiscount).format('$0,0.00')}</span>
          </p>
        );
      }) : null;

      const itemizedRefundContent = (
        <div className='itemizedRefundContent'>

          {existingPartialRefundsContent}

          {!usingSimplifiedDialog &&
            <Field
                component={Toggle}
                description={t(refundMessages.refundAll)}
                disabled={isVoidOperation}
                label={t('RefundAll')}
                name='refundAll'
            />
          }

          <h2 className='refundAmountTitle'>{usingSimplifiedDialog ? '' : t('RefundAmount')}</h2>
          <div>{lineItems}</div>
          {selectedReceipt && !showError ?
          (<div className='detailTotals'>

            {selectedReceipt.tip_amount && !usingSimplifiedDialog ?
              <Field
                  className='refundTextField'
                  component={TextField}
                  disabled={currentValues && currentValues.refundAll}
                  hintText=''
                  label={<Trans i18nKey={'TipAmountLimit'}>Tip (Limit {{currentTipLimit}})</Trans>}
                  name='refundTip'
                  normalize={normalizeCurrency}
              /> : null}

            <div className='totalsContainer'>

              <div className='cartDiscounts'>
                {cartDiscounts}
              </div>

              <p className='details detailsSubtotal'>
                <span className='text'>{`${t('Subtotal')}:`}</span>
                <span className='number'>{numeral(refundTotals.subtotal).format('$0,0.00')}</span>
              </p>
              <p className='details detailsTax'>
                <span className='text'>{`${t('Tax')}`}</span>
                <span className='number'>{numeral(refundTotals.tax).format('$0,0.00')}</span>
              </p>
              <p className='details detailsTotal'>
                <span className='text'>
                  <span>{usingSimplifiedDialog ? t('Total') : t('TotalRefund')}</span>
                  {!usingSimplifiedDialog && <span className={`totalLimit ${refundTotals.total > numeral(currentTotalLimit).value() ? 'error' : ''}`}> <Trans i18nKey={'RefundLimit'}>(Limit {{currentTotalLimit}})</Trans></span>}
                </span>
                <span className='number'>{numeral(refundTotals.total).format('$0,0.00')}</span>
              </p>
            </div>

            {!usingSimplifiedDialog && refundNote}
          </div>) : null}
        </div>
      );

      const customRefundErrorClass = currentValues && (numeral(currentValues.customRefundAmount).value() > numeral(currentTotalLimit).value()) ? 'error' : '';

      const customRefundContent = !usingSimplifiedDialog && (
        <div className='customRefundContent'>

          {existingPartialRefundsContent}

          <h2 className='customRefundTitle'>
              <Trans i18nKey={'CustomRefundTitle'}>
                  Custom Refund Amount <span className={`customRefundLimit ${customRefundErrorClass}`}>(Limit {{currentTotalLimit}})</span>
              </Trans>
          </h2>

          <Field
              className='refundTextField'
              component={TextField}
              hintText='$0.00'
              label={t('Amount')}
              name='customRefundAmount'
              normalize={normalizeCurrency}
          />

          {refundNote}

          <p className='optionalRestock'>{t('OptionalRestock')}</p>

          {lineItems}

        </div>
      );

      const renderItemizedOrCustomTabs = ({input, ...props}) => (
        <Box sx={ItemizedOrCustomTabsStyles} className='itemizedOrCustomTabs'>
          <div
              className={`tab ${input.value === true ? 'selectedTab' : ''}`}
              onClick={!isVoidOperation && (() => {input.onChange(true)})}
          >
            {t('Itemized')}
          </div>
          <div
              className={`tab ${input.value === false ? 'selectedTab' : ''}`}
              onClick={!isVoidOperation && (() => {input.onChange(false)})}
          >
            {t('Custom')}
          </div>
          <Checkbox
              input={input}
              {...props}
              externalClassName={'itemizedOrCustomCheckbox hideMe'}
          />
        </Box>
      );

      const renderCustomTabs = ({input, ...props}) => (
        <div className='itemizedOrCustomTabs'>
          <div
            className={'tab customDisabled'}
            onClick={!isVoidOperation && (() => {input.onChange(false)})}
          >
            {t('Custom')}
          </div>
          <Checkbox
            input={input}
            {...props}
            externalClassName={'itemizedOrCustomCheckbox hideMe'}
          />
        </div>
      );

      const dialogBody = (
        <form onSubmit={handleSubmit}>
          <div className='paymentDialog'>
            {formErrorMessage}
            {activityIndicator}
            {showConfirmation ?
              <div className='confirmation'>
                <div className='iconHeading'>
                  <div>{IconUtils.getIcon('CheckCircle', labelColor)}</div>
                  {confirmationHeading}
                </div>
                {refundAsVoidContent}
              </div>

              : (selectedReceipt && !showError) ? (
              <div className='mainContainer'>

                <div className='mainContent'>

                  {!usingSimplifiedDialog &&
                    <Field
                        className='itemized'
                        component={isComplete ? renderItemizedOrCustomTabs : renderCustomTabs}
                        disabled={isVoidOperation}
                        label={t('ItemizedCustom')}
                        name='isItemized'
                    />
                  }
                  {currentValues.isItemized ? itemizedRefundContent : customRefundContent}

                </div>


              </div>
              ) : null
            }
            {errorDisplay}
          </div>
        </form>
      );

      const dialog = (
        <MessageDialog
          scrollable={false}
          bodyText={dialogBody}
          confirmText={confirmText}
          dialogBodyClassName={'refundDialogBody'}
          disabled={isTotalInvalid || isFormInvalid}
          onRequestClose={cancelOnClick}
          closeAction={closeAction}
          open={paymentDialogOpen}
          cancelText={cancelLabel}
          onConfirm={onConfirm}
          titleText={dialogTitle}
          isChoiceRequired={true}
        />
      );

      const giftCardRefundDialog = (
        <MessageDialog
          isChoiceRequired={true}
          externalClassName={'giftCardRefundDialog'}
          titleText={t('GiftCardRefundDialogTitle')}
          bodyText={t('GiftCardRefundDialogInfo')}
          confirmText={t('Close')}
          onConfirm={closeAction}
          onRequestClose={closeAction}
          open={paymentDialogOpen}
        />
      );

      return (isTCCGiftCardRefund ? giftCardRefundDialog : dialog);

    } else {
      return null;
    }

  }

}

export const PaymentDialogContainer = reduxForm({
  form: 'paymentDialogForm',
  validate,
  enableReinitialize: true,
})(PaymentDialog);

function mapStateToProps(state, ownProps) {

  const currentValues = getFormValues('paymentDialogForm')(state) || {};
  const { selectedReceipt } = ownProps.transactions;

  const itemDefaultQuantities = selectedReceipt && selectedReceipt.item_quantity ?
    selectedReceipt.item_quantity.map((quantity, index) => {
      const currentLineItemId = selectedReceipt.item_line_item_ids[index];

      const alreadyRestockedQuantity = selectedReceipt.subTransactions?.reduce((total, current) => {
        const restockLines = current.returned_stock ? JSON.parse(current.returned_stock) : [];
        if (currentLineItemId && restockLines?.length) {
          let filteredRestockLines = restockLines.reduce((prev, {line_item_id, quantity}) => {
              if (line_item_id === currentLineItemId) {
                  return prev + quantity;
              }
              return prev;
          }, 0);
          return total + filteredRestockLines;
        }
        return total;
      }, 0);

      const originalQuantity = selectedReceipt.item_quantity[index];

      const availableRestockQuantity = originalQuantity - alreadyRestockedQuantity;

      return {
        itemId: selectedReceipt.item_ids[index],
        originalQuantity,
        refundQuantity: quantity,
        restockQuantity: availableRestockQuantity,
        availableRestockQuantity
      }
    }) : [];

  return {
    initialValues: {
      isItemized: selectedReceipt?.is_complete,
      lineItems: itemDefaultQuantities,
      refundTip: numeral(parseFloat(selectedReceipt && selectedReceipt.tip_amount) || 0).format('$0,0.00'),
      originalTip: selectedReceipt && selectedReceipt.tip_amount,
      customRefundAmount: null,
      refundAll: true
    },
    currentValues
  }
}

export default connect(mapStateToProps)(PaymentDialogContainer);
