/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';

const NoPaymentHistory = (props) => {

  const color = props.color || '#00c4b3';

  return (

    <svg xmlns='http://www.w3.org/2000/svg' width='408.493' height='395.813'
         viewBox='0 0 408.493 395.813'
         style={props.style}
    >
      <defs>
        <style>{`.a{fill:#fff;}.b{fill:#1a1e22;}.c{fill:${color};}`}</style>
      </defs>
      <g transform='translate(-194.999 -219.999)'>
        <g transform='translate(238.216 397.569)'>
          <g transform='translate(23.596 0)'>
            <path className='a'
                  d='M693.677,946.209l-48.846-21.35-181.5,1.922,47.557,23.5Z'
                  transform='translate(-462.06 -923.589)'/>
            <path className='b'
                  d='M507.154,947.819a1.267,1.267,0,0,1-.562-.131l-47.557-23.5a1.269,1.269,0,0,1,.549-2.406l181.5-1.922a1.317,1.317,0,0,1,.522.106l48.846,21.351a1.269,1.269,0,0,1-.48,2.431l-182.792,4.073Zm-42.24-23.558,42.523,21.014,176.733-3.938L640.84,922.4Z'
                  transform='translate(-458.328 -919.858)'/>
          </g>
          <g transform='translate(69.772 21.35)'>
            <path className='c'
                  d='M646.794,1011.136c62.673,1.39,122.119-1.927,182.7-2.129,1.385,49.034-.03,91.444-.86,137.9-59.084.491-124.422,2.488-183.313.823Z'
                  transform='translate(-644.053 -1007.738)'/>
            <path className='b'
                  d='M693.107,1145.914c-17.513,0-34.795-.178-51.552-.651a1.269,1.269,0,0,1-1.233-1.281l1.472-136.589a1.269,1.269,0,0,1,1.3-1.255c37.8.839,74.941-.049,110.86-.907,23.49-.562,47.78-1.142,71.809-1.222h0a1.269,1.269,0,0,1,1.268,1.233c1.129,39.971.406,75.183-.359,112.461-.171,8.3-.347,16.88-.5,25.493a1.269,1.269,0,0,1-1.258,1.246c-16.756.139-33.629.394-51.493.663C746.841,1145.5,719.711,1145.914,693.107,1145.914Zm-50.234-3.153c41.809,1.144,86.893.463,130.51-.195,17.43-.263,33.916-.512,50.275-.654.149-8.2.316-16.361.478-24.264.757-36.86,1.472-71.7.394-111.1-23.6.1-47.446.665-70.519,1.217-35.541.85-72.279,1.728-109.694.936Z'
                  transform='translate(-640.322 -1004.007)'/>
          </g>
          <g transform='translate(22.89 2.212)'>
            <path className='c'
                  d='M461.85,1062.119c-.586-42.39-.719-86.153-1.305-128.543,15.6,7.324,32.749,13.944,48.354,21.268q-.736,68.294-1.472,136.589C492.429,1083.076,476.849,1070.476,461.85,1062.119Z'
                  transform='translate(-459.277 -932.307)'/>
            <path className='b'
                  d='M503.7,1088.97a1.265,1.265,0,0,1-.617-.161c-7.55-4.208-15.347-9.551-22.887-14.719-7.493-5.136-15.241-10.446-22.689-14.6a1.268,1.268,0,0,1-.651-1.09c-.293-21.2-.476-43.1-.653-64.278s-.359-43.073-.653-64.264a1.269,1.269,0,0,1,1.808-1.166c7.781,3.652,16.095,7.192,24.135,10.615,8.061,3.432,16.4,6.981,24.219,10.653a1.269,1.269,0,0,1,.73,1.162l-1.472,136.589a1.269,1.269,0,0,1-1.269,1.255Zm-44.318-31.332c7.35,4.15,14.92,9.338,22.247,14.36,6.87,4.708,13.955,9.564,20.826,13.537l1.44-133.62c-7.568-3.53-15.61-6.954-23.4-10.27-7.433-3.165-15.1-6.43-22.384-9.8.277,20.567.454,41.75.625,62.257C458.91,1015.038,459.091,1036.674,459.378,1057.638Z'
                  transform='translate(-455.546 -928.575)'/>
          </g>
          <g transform='translate(0 2.212)'>
            <path className='a'
                  d='M393.218,933.576c-2.862,13.425-11.618,25.04-22.89,32.874,15.665,9.19,31.352,19.346,47.017,28.535,10.462-11.23,19.963-25.4,24.226-40.141Z'
                  transform='translate(-369.06 -932.308)'/>
            <path className='b'
                  d='M413.614,992.524a1.265,1.265,0,0,1-.641-.174c-7.845-4.6-15.82-9.523-23.533-14.282s-15.664-9.666-23.484-14.253a1.268,1.268,0,0,1-.082-2.136c11.587-8.054,19.742-19.753,22.373-32.1a1.269,1.269,0,0,1,1.752-.9l48.353,21.268a1.269,1.269,0,0,1,.708,1.514c-3.877,13.407-12.584,27.845-24.517,40.654A1.267,1.267,0,0,1,413.614,992.524Zm-44.7-29.909c7.3,4.314,14.69,8.873,21.853,13.293,7.413,4.573,15.068,9.3,22.6,13.736,11-11.993,19.094-25.347,22.928-37.82l-45.925-20.2a51.428,51.428,0,0,1-8.15,17.491A60.346,60.346,0,0,1,368.919,962.614Z'
                  transform='translate(-365.328 -928.577)'/>
          </g>
        </g>
        <g transform='translate(310.434 363.237)'>
          <g transform='translate(139.448 188.112)'>
            <path className='a'
                  d='M1206.567,1544.63l-1.994-.08q23.316,11.873,47.362,22.233l7-10.992c.664-1.044,1.358-2.235,1.089-3.444-.307-1.379-1.716-2.171-2.984-2.792l-37.974-18.6C1216.773,1537.663,1212.246,1540.391,1206.567,1544.63Z'
                  transform='translate(-1203.304 -1529.688)'/>
            <path className='b'
                  d='M1248.2,1564.321a1.269,1.269,0,0,1-.5-.1c-15.977-6.883-31.936-14.375-47.435-22.268a1.268,1.268,0,0,1,.626-2.4l1.545.062c.479-.356.95-.7,1.411-1.04,4.855-3.564,8.363-6.139,10.28-11.758a1.269,1.269,0,0,1,1.759-.729l37.975,18.6c1.118.548,3.2,1.567,3.664,3.657.4,1.8-.638,3.426-1.257,4.4l-7,10.992A1.268,1.268,0,0,1,1248.2,1564.321Zm-43.707-23.073c14.16,7.134,28.678,13.924,43.218,20.211l6.417-10.08a3.9,3.9,0,0,0,.921-2.487c-.176-.789-1.211-1.393-2.3-1.928l-36.723-17.986c-2.28,5.48-6.193,8.353-10.673,11.642Z'
                  transform='translate(-1199.572 -1525.957)'/>
            <g transform='translate(33.677 17.373)'>
              <path className='a'
                    d='M1346.752,1617.55c1.257,3.56,2.173,8.707,1.454,11.961-.844,3.823-6.449,6.9-9.23,9.2-5.067,4.183,2.829,4.9,5.91,4.865a17.93,17.93,0,0,0,10.788-3.964c6.311-4.9,10.066-12.807,12.459-20.245.538-1.674,4.734-11.58,3.875-12.8-1.26-1.784-15.516-7.9-17.131-7.1,0,0,1.339,4.065-2.229,11.082-1.675,3.294-5.983,6.636-6.493,5.489C1346.359,1616.5,1346.559,1617.005,1346.752,1617.55Z'
                    transform='translate(-1336.048 -1598.136)'/>
              <path className='b'
                    d='M1340.932,1641.136a23.761,23.761,0,0,1-4.37-.4c-2.313-.461-3.655-1.279-4.1-2.5-.484-1.32.178-2.739,1.967-4.215.674-.556,1.487-1.143,2.349-1.765,2.631-1.9,5.9-4.26,6.45-6.727.648-2.935-.219-7.888-1.412-11.265h0c-.181-.514-.369-.99-.558-1.414l0,0a1.269,1.269,0,0,1,1.763-1.631,13.238,13.238,0,0,0,4.758-4.949,19.918,19.918,0,0,0,2.305-7.5,8,8,0,0,0-.154-2.626,1.255,1.255,0,0,1,.647-1.521c.466-.229,1.7-.839,9.753,2.551,1.828.77,7.88,3.4,8.976,4.955.833,1.179.2,3.727-2.928,11.851-.358.929-.667,1.73-.775,2.066-3.13,9.73-7.345,16.552-12.888,20.858a19.2,19.2,0,0,1-11.551,4.23Zm-6.083-3.757c.3.531,2.861,1.258,6.279,1.219a16.686,16.686,0,0,0,10.026-3.7c5.126-3.983,9.061-10.4,12.029-19.632.13-.4.421-1.158.823-2.2.782-2.029,3.105-8.055,3.113-9.585a39.251,39.251,0,0,0-7.472-3.842,49.854,49.854,0,0,0-7.027-2.514c.123,1.972-.159,5.522-2.583,10.29a15.837,15.837,0,0,1-3.422,4.31,11.163,11.163,0,0,1-2.4,1.719c1.31,3.721,2.276,9.063,1.488,12.632-.753,3.412-4.463,6.088-7.443,8.238-.827.6-1.608,1.16-2.218,1.663C1335,1636.843,1334.865,1637.3,1334.849,1637.379Zm8.722-25.564v0h0Zm23.539-8.482Z'
                    transform='translate(-1332.306 -1594.429)'/>
            </g>
            <g transform='translate(34.68 23.294)'>
              <path className='b'
                    d='M1341.258,1660.325c6.391,2.258,13.729.762,19.4-2.947s9.818-9.41,12.71-15.54a109.177,109.177,0,0,0,6.521-19.074c-1.345.367-4.128.407-5.473.775-2.079,8.991-4.9,18.53-10.409,25.934S1350.056,1663.111,1341.258,1660.325Z'
                    transform='translate(-1339.989 -1621.496)'/>
              <path className='b'
                    d='M1343.947,1658.933a20.522,20.522,0,0,1-6.844-1.143,1.269,1.269,0,0,1,.806-2.406c3.726,1.181,7.634.673,11.616-1.508a31.925,31.925,0,0,0,9.734-8.892c5.5-7.392,8.239-17.019,10.191-25.462a1.268,1.268,0,0,1,.9-.938,19.656,19.656,0,0,1,2.946-.427,18.769,18.769,0,0,0,2.527-.349,1.269,1.269,0,0,1,1.561,1.547,110.61,110.61,0,0,1-6.6,19.293c-3.374,7.153-7.8,12.557-13.163,16.061A25.2,25.2,0,0,1,1343.947,1658.933Zm27.789-38.043c-2,8.5-4.837,18.078-10.442,25.609a38.048,38.048,0,0,1-6.884,7.169q.926-.5,1.823-1.083c4.962-3.243,9.085-8.3,12.256-15.019a101.776,101.776,0,0,0,5.941-16.973c-.287.031-.583.06-.883.09C1372.914,1620.745,1372.267,1620.809,1371.736,1620.89Z'
                    transform='translate(-1336.256 -1617.764)'/>
            </g>
          </g>
          <g transform='translate(216.707 89.994)'>
            <g transform='translate(0 10.358)'>
              <path className='a'
                    d='M1510.526,1215.062l-1.452,1.247q24.133-6.914,47.788-15.369l-2.278-12.293a4.61,4.61,0,0,0-1.488-3.129c-1.117-.767-2.625-.4-3.921-.008l-38.822,11.78C1513.134,1203.5,1511.74,1208.372,1510.526,1215.062Z'
                    transform='translate(-1507.805 -1183.799)'/>
              <path className='b'
                    d='M1505.343,1213.843a1.269,1.269,0,0,1-.827-2.231l1.112-.955q.154-.835.307-1.635c1.075-5.668,1.853-9.764-.47-14.948a1.268,1.268,0,0,1,.789-1.733l38.822-11.781c1.151-.35,3.294-1,5.008.177,1.473,1.012,1.813,2.847,2.016,3.944l2.278,12.292a1.269,1.269,0,0,1-.82,1.426c-15.715,5.617-31.819,10.8-47.865,15.394A1.264,1.264,0,0,1,1505.343,1213.843Zm2.98-19.478c1.965,5.329,1.1,9.9.1,15.13q-.086.453-.173.917c14.566-4.243,29.156-8.96,43.432-14.04l-2.081-11.229a3.714,3.714,0,0,0-.958-2.315c-.622-.427-1.729-.176-2.834.16Z'
                    transform='translate(-1504.074 -1180.064)'/>
            </g>
            <g transform='translate(40.994 3.777)'>
              <path className='a'
                    d='M1675.565,1183.067q9.084,11.22,19,21.732c1.569,1.664,3.847,3.456,5.895,2.436,2.336-1.165,1.511-4.513.947-7.063-3.631-16.424-13.82-41.042-13.82-41.042-2.026-.528-16.255,3.806-16.946,5.389a18.6,18.6,0,0,1,5.218,9.5C1677.062,1179.135,1675.308,1182.75,1675.565,1183.067Z'
                    transform='translate(-1669.373 -1157.828)'/>
              <path className='b'
                    d='M1695.476,1205.1c-1.437,0-3.3-.733-5.563-3.13-6.6-6.995-13.013-14.33-19.063-21.8-.462-.571-.316-1.186-.114-2.037a15.477,15.477,0,0,0,.159-7.519,17.6,17.6,0,0,0-4.805-8.823,1.275,1.275,0,0,1-.341-1.475c.2-.462.737-1.688,8.757-4.19,1.819-.568,7.894-2.383,9.671-1.919a1.268,1.268,0,0,1,.852.743c.1.247,10.274,24.909,13.886,41.253l.052.235c.589,2.654,1.48,6.664-1.672,8.236A4.041,4.041,0,0,1,1695.476,1205.1Zm-22.331-26.131c5.916,7.286,12.176,14.437,18.614,21.261,1.047,1.109,3.01,2.868,4.406,2.171,1.274-.635.9-2.829.328-5.416l-.053-.238c-3.212-14.528-11.718-35.821-13.445-40.076a45.351,45.351,0,0,0-7.427,1.78,47.341,47.341,0,0,0-6.736,2.518,21.091,21.091,0,0,1,4.534,9.061,18.04,18.04,0,0,1-.161,8.686Zm-.323-.4h0Zm-4.852-17.07Z'
                    transform='translate(-1665.644 -1154.127)'/>
            </g>
            <g transform='translate(57.835)'>
              <path className='b'
                    d='M1750.254,1191.911c.456,2.3.762,4.411.37,4.723,1.88-1.494,2.276-4.161,2.355-6.561.251-7.539-1.819-14.009-3.823-21.092-2.414-8.531-4.761-16.657-8.6-24.74-.759,1.1-3.532,3.952-3.532,3.952a380.363,380.363,0,0,1,11.916,37.67C1749.147,1186.666,1749.756,1189.4,1750.254,1191.911Z'
                    transform='translate(-1735.752 -1142.972)'/>
              <path className='b'
                    d='M1746.893,1194.171a1.269,1.269,0,0,1-1.092-1.916,17.763,17.763,0,0,0-.523-3.829c-.508-2.556-1.109-5.245-1.3-5.972a382.68,382.68,0,0,0-11.871-37.531,1.268,1.268,0,0,1,.273-1.346,50.115,50.115,0,0,0,3.4-3.787,1.269,1.269,0,0,1,2.19.175c4,8.425,6.487,17.2,8.678,24.94q.252.89.5,1.769c1.853,6.483,3.6,12.606,3.366,19.71-.083,2.514-.481,5.64-2.832,7.51h0A1.262,1.262,0,0,1,1746.893,1194.171Zm-12.132-49.41c1.3,3.441,6.729,18.186,11.671,37.049.23.877.85,3.682,1.334,6.121h0l.058.3a17.858,17.858,0,0,0,.155-1.929c.223-6.706-1.474-12.643-3.27-18.928q-.252-.883-.506-1.776c-2.1-7.429-4.271-15.092-7.69-22.725C1735.9,1143.562,1735.226,1144.274,1734.762,1144.761Z'
                    transform='translate(-1732.021 -1139.241)'/>
            </g>
          </g>
          <g transform='translate(45.06 46.772)'>
            <path className='a'
                  d='M925.748,973.892q1.17,2.417,2.168,4.906c6.824,17.038,8.78,34.636,24.469,46.437,22.963,17.273,53.538,8.856,77.81-.677,3.84,11.331,7.911,22.577,10.066,34.345C1019.514,1071,995.8,1075,971.8,1075.612c-17.3.443-35.206-.014-50.92-7.255s-22.918-20.932-27.568-41.407c-1.453,21.343,7.2,46.309,22.9,60.843,14.93,13.825,37.05,24.884,53.086,32.626-5.413,8.427-15.6,19.222-21.013,27.649-5.1-.552-11.6-4.539-16.4-6.4-11-4.269-22.034-8.521-32.865-13.211-14.44-6.252-27.573-12.172-37.6-24.673-18.588-23.18-19.616-50.783-22.1-79.059-.318-3.615-2.922-26.533-6.755-35.388Z'
                  transform='translate(-831.291 -972.623)'/>
            <path className='b'
                  d='M944.55,1145.606a1.29,1.29,0,0,1-.137-.007c-3.675-.4-7.966-2.441-11.752-4.244-1.786-.851-3.472-1.654-4.973-2.236l-1.71-.663c-10.279-3.987-20.908-8.109-31.2-12.566-13.557-5.87-27.576-11.941-38.083-25.043-7.865-9.807-13.319-21.331-16.672-35.23-2.987-12.374-4.052-25.01-5.179-38.388q-.258-3.055-.523-6.124c-.355-4.048-2.942-26.417-6.655-35a1.269,1.269,0,0,1,.957-1.755l93.187-15.445a1.268,1.268,0,0,1,1.349.7c.794,1.639,1.535,3.317,2.2,4.987,1.681,4.2,3.077,8.452,4.427,12.566,4.159,12.676,8.088,24.65,19.627,33.33,22.631,17.024,52.815,8.491,76.584-.844a1.269,1.269,0,0,1,1.665.774q.636,1.876,1.277,3.75c3.393,9.937,6.9,20.213,8.835,30.774a1.269,1.269,0,0,1-.609,1.325,115.42,115.42,0,0,1-33.37,12.8,183.365,183.365,0,0,1-35.7,4.079c-16.265.417-35,.224-51.483-7.371a42.019,42.019,0,0,1-18.475-16.215,68.215,68.215,0,0,1-7.259-16.458,83.593,83.593,0,0,0,4.905,22.651c4.057,11.034,10.127,20.5,17.552,27.375,15.651,14.492,39.8,26.151,52.776,32.415a1.269,1.269,0,0,1,.516,1.828,171.1,171.1,0,0,1-10.587,13.936,169.618,169.618,0,0,0-10.426,13.714A1.268,1.268,0,0,1,944.55,1145.606ZM830.585,986.6c3.526,9.715,5.92,30.381,6.263,34.283q.27,3.073.524,6.133c2.212,26.249,4.3,51.043,21.3,72.244,10.114,12.612,23.839,18.555,37.112,24.3,10.247,4.437,20.853,8.551,31.11,12.529l1.71.663c1.589.617,3.317,1.439,5.146,2.311,3.328,1.585,7.066,3.365,10.175,3.9,2.72-4.11,6.491-8.756,10.145-13.258,3.452-4.253,7.009-8.637,9.632-12.511-13.356-6.455-36.675-17.934-52.09-32.207-7.718-7.146-14.015-16.953-18.21-28.362a83.084,83.084,0,0,1-5.093-33.5,1.269,1.269,0,0,1,2.5-.2c5.074,22.339,12.856,34.083,26.862,40.537,15.988,7.366,34.376,7.547,50.357,7.139,27.782-.711,49.132-5.828,67.084-16.09-1.932-10.073-5.308-19.962-8.576-29.534q-.427-1.249-.852-2.5c-9.9,3.841-24.209,8.79-38.515,9.99a66.283,66.283,0,0,1-20.372-1.22,49.832,49.832,0,0,1-18.911-8.744c-12.176-9.159-16.226-21.5-20.513-34.567-1.338-4.076-2.721-8.29-4.372-12.413-.531-1.326-1.109-2.658-1.725-3.97Z'
                  transform='translate(-827.56 -968.891)'/>
          </g>
          <g transform='translate(0 0)'>
            <path className='a'
                  d='M654.963,847.323c4.572-22.234,23.472-46.242,44.811-53.983,36.59-13.274,77.2,9.4,93.437,42.936a178.484,178.484,0,0,1-43.018,14.246c-36.919,7-50.17,1.2-50.17,1.2-2.256-7.383-8.515-7.214-9.636-4.235Z'
                  transform='translate(-653.695 -788.235)'/>
            <path className='b'
                  d='M714.229,851.557c-2.3,0-4.575-.073-6.788-.234-8.052-.585-11.514-2.067-11.657-2.13a1.268,1.268,0,0,1-.7-.791c-1.159-3.794-3.408-5.2-5.126-5.258a2.069,2.069,0,0,0-2.109,1.1,1.269,1.269,0,0,1-1.187.822h-.006l-35.424-.162a1.269,1.269,0,0,1-1.237-1.524c2.29-11.139,8.107-22.849,16.378-32.973,8.488-10.391,18.874-18.185,29.243-21.946,17.494-6.346,37.148-4.937,55.342,3.968a87.484,87.484,0,0,1,39.669,39.608,1.269,1.269,0,0,1-.565,1.683,184.074,184.074,0,0,1-75.828,17.84Zm-16.923-4.506c2.731.9,16.827,4.623,48.92-1.463a180.759,180.759,0,0,0,41.552-13.581,85.031,85.031,0,0,0-37.941-37.3c-17.57-8.6-36.521-9.971-53.362-3.862-9.945,3.608-19.94,11.125-28.143,21.167-7.64,9.353-13.114,20.075-15.524,30.362l33.089.152a4.713,4.713,0,0,1,4.136-1.916C692.522,840.687,695.682,842.434,697.306,847.051Z'
                  transform='translate(-649.964 -784.546)'/>
          </g>
        </g>
        <g transform='translate(194.999 219.999)'>
          <g transform='translate(282.043 113.988)'>
            <path className='b'
                  d='M1307.889,710.059a1.269,1.269,0,0,1-.941-2.119,165.441,165.441,0,0,1,52.31-38.558,1.269,1.269,0,1,1,1.076,2.3,162.886,162.886,0,0,0-51.5,37.96A1.265,1.265,0,0,1,1307.889,710.059Z'
                  transform='translate(-1306.621 -669.262)'/>
          </g>
          <g transform='translate(271.881 41.878)'>
            <path className='b'
                  d='M1267.838,445.649a1.269,1.269,0,0,1-1.1-1.9,171.834,171.834,0,0,1,55.833-58.492,1.269,1.269,0,1,1,1.369,2.136,169.282,169.282,0,0,0-55.005,57.625A1.268,1.268,0,0,1,1267.838,445.649Z'
                  transform='translate(-1266.57 -385.053)'/>
          </g>
          <g transform='translate(204.621 42.911)'>
            <path className='b'
                  d='M1002.746,464.927a1.27,1.27,0,0,1-1.212-1.644,245.27,245.27,0,0,1,37.3-73.644,1.269,1.269,0,1,1,2.039,1.51,242.742,242.742,0,0,0-36.917,72.884A1.269,1.269,0,0,1,1002.746,464.927Z'
                  transform='translate(-1001.476 -389.125)'/>
          </g>
          <g transform='translate(0 36.221)'>
            <path className='b'
                  d='M295.818,465.291a1.268,1.268,0,0,1-1.1-.635,274.909,274.909,0,0,0-99.089-99.534,1.269,1.269,0,0,1,1.278-2.192A277.454,277.454,0,0,1,296.915,463.388a1.269,1.269,0,0,1-1.1,1.9Z'
                  transform='translate(-194.999 -362.757)'/>
          </g>
          <g transform='translate(108.649 45.058)'>
            <path className='b'
                  d='M646.837,454.6a1.269,1.269,0,0,1-1.174-.788l-22.346-54.471a1.269,1.269,0,0,1,2.347-.963l22.346,54.471a1.27,1.27,0,0,1-1.173,1.75Z'
                  transform='translate(-623.222 -397.589)'/>
          </g>
          <g transform='translate(22.849 122.5)'>
            <path className='b'
                  d='M328.259,725.176a1.263,1.263,0,0,1-.754-.249,119.019,119.019,0,0,0-41.491-19.616,1.269,1.269,0,0,1,.617-2.461,121.577,121.577,0,0,1,42.384,20.038,1.268,1.268,0,0,1-.756,2.288Z'
                  transform='translate(-285.054 -702.812)'/>
          </g>
          <g transform='translate(171.589 0)'>
            <path className='b'
                  d='M872.556,312.252l-.083,0a1.268,1.268,0,0,1-1.184-1.348l5.782-89.716a1.269,1.269,0,1,1,2.532.163l-5.783,89.715A1.269,1.269,0,0,1,872.556,312.252Z'
                  transform='translate(-871.286 -219.999)'/>
          </g>
        </g>
      </g>
    </svg>

  )
};

export default NoPaymentHistory;
