import { CALL_API } from '../middleware/api';
import actionTypes from '../constants/actionTypes';
import _ from 'lodash';

export function getPCI(user) {

  /////////////////////////////////////////////////////////////////////
  // GET pci
  /////////////////////////////////////////////////////////////////////

  const endpoint = `users/accounts/${user.selectedMerchantAccount}/get-pci`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.pciRequest, actionTypes.pciSuccess, actionTypes.pciFailure]
    }
  };

}

export function getPciEnvelope(user) {

  /////////////////////////////////////////////////////////////////////
  // GET pci envelope
  /////////////////////////////////////////////////////////////////////

  const endpoint = `users/accounts/${user.selectedMerchantAccount}/pci-envelope`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.pciEnvelopeRequest, actionTypes.pciEnvelopeSuccess, actionTypes.pciEnvelopeFailure]
    }
  };

}

export function getPciDoc(user, pci) {

  /////////////////////////////////////////////////////////////////////
  // GET pci envelope
  /////////////////////////////////////////////////////////////////////

  const endpoint = `users/accounts/${user.selectedMerchantAccount}/pci-docs/${pci.envelopeId}/${pci.documentId}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.pciDocRequest, actionTypes.pciDocSuccess, actionTypes.pciDocFailure]
    }
  };

}

