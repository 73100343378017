/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';

export default function PowerUser({style}) {
  return (
    <svg className='powerUserIcon' xmlns='https://www.w3.org/2000/svg' viewBox='80 40 200 200' style={style}>
      <g>
        <g>
          <defs>
            <radialGradient id='radial-gradient' cx='0.5' cy='0.5' r='0.453' gradientUnits='objectBoundingBox'>
              <stop offset='0' stopColor='#222529' stopOpacity='0'/>
              <stop offset='1' stopColor='#222529'/>
            </radialGradient>
          </defs>
          <g id='Group_28624' data-name='Group 28624' transform='translate(-967 -1370)'>
            <g id='Group_27787' data-name='Group 27787' transform='translate(-4.074 1088.711)'>
              <g id='Rectangle_7953' data-name='Rectangle 7953' transform='translate(974.074 345.29)' fill='#222529' stroke='#fff' strokeWidth='1'>
                <rect width='108' height='106' rx='32' stroke='none'/>
                <rect x='0.5' y='0.5' width='107' height='105' rx='31.5' fill='none'/>
              </g>
              <g id='Rectangle_7950' data-name='Rectangle 7950' transform='translate(1219.074 345.29)' fill='#222529' stroke='#fff' strokeWidth='1'>
                <rect width='107' height='106' rx='32' stroke='none'/>
                <rect x='0.5' y='0.5' width='106' height='105' rx='31.5' fill='none'/>
              </g>
              <g id='Rectangle_7948' data-name='Rectangle 7948' transform='translate(1097.074 466.29)' fill='#222529' stroke='#fff' strokeWidth='1'>
                <rect width='107' height='92' rx='32' stroke='none'/>
                <rect x='0.5' y='0.5' width='106' height='91' rx='31.5' fill='none'/>
              </g>
              <g id='Rectangle_7955' data-name='Rectangle 7955' transform='translate(974.074 466.29)' fill='#222529' stroke='#fff' strokeWidth='1'>
                <rect width='108' height='92' rx='32' stroke='none'/>
                <rect x='0.5' y='0.5' width='107' height='91' rx='31.5' fill='none'/>
              </g>
              <g id='Rectangle_7952' data-name='Rectangle 7952' transform='translate(1219.074 466.29)' fill='#222529' stroke='#fff' strokeWidth='1'>
                <rect width='107' height='92' rx='32' stroke='none'/>
                <rect x='0.5' y='0.5' width='106' height='91' rx='31.5' fill='none'/>
              </g>
              <rect id='Rectangle_7956' data-name='Rectangle 7956' width='364' height='231' rx='15' transform='translate(971.074 281.289)' fill='url(#radial-gradient)'/>
              <path id='Rectangle_8568' data-name='Rectangle 8568' d='M0,0H362a0,0,0,0,1,0,0V49a18,18,0,0,1-18,18H17A17,17,0,0,1,0,50V0A0,0,0,0,1,0,0Z' transform='translate(971.074 499.289)' fill='#222529'/>
            </g>
            <g id='Rectangle_8567' data-name='Rectangle 8567' transform='translate(1093 1434)' fill='#222529' stroke={style?.fill ? style.fill : '#04c9ba'} strokeLinecap='round' strokeWidth='1.5'>
              <rect width='107' height='106' rx='32' stroke='none'/>
              <rect x='0.75' y='0.75' width='105.5' height='104.5' rx='31.25' fill='none'/>
            </g>
            <path id='play_arrow_FILL0_wght100_GRAD0_opsz24' d='M372-622.721v-16.4a1.748,1.748,0,0,1,.563-1.377,1.917,1.917,0,0,1,1.315-.5,2.057,2.057,0,0,1,.5.063,2.445,2.445,0,0,1,.5.188l12.9,8.263a2.265,2.265,0,0,1,.657.689,1.708,1.708,0,0,1,.219.876,1.708,1.708,0,0,1-.219.876,2.265,2.265,0,0,1-.657.689l-12.9,8.263a2.442,2.442,0,0,1-.5.188,2.057,2.057,0,0,1-.5.063,1.917,1.917,0,0,1-1.315-.5A1.748,1.748,0,0,1,372-622.721ZM373.753-630.922Zm0,8.388,13.271-8.388-13.271-8.388Z' transform='translate(769.812 2116.707)' fill={style?.fill ? style.fill : '#00c9ba'}/>
          </g>
        </g>
      </g>
    </svg>
  );
};
