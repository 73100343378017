/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { submit, change } from 'redux-form';
import { Box } from '@mui/material';

import {
  getMerchantSettings,
  partialUpdateSettings
} from '../../actions/merchantSettingsActions';

import AttachFilesForm, { AttachFilesFormType } from '../AttachFilesForm';
import InvoicePreview from '../invoice/InvoicePreview';
import ReceiptPreview from './ReceiptPreview';
import PaymentPortalPreview from './PaymentPortalPreview';
import ReceiptSettingsForm from './ReceiptSettingsForm';
import UpdateSpinner from '../UpdateSpinner';

import Button from '../shared/Button';
import FilterPanel from '../shared/FilterPanel';
import Modal from '../shared/Modal';

import FileUtil from '../util/FileUtil';
import IconUtil from '../util/IconUtil';
import SettingsUtil from '../util/SettingsUtil';

import attachFileOptions from '../../constants/attachFileOptions';
import Page from '../shared/Page';
import {settingsFormStyles} from '../../jss/settingsFormStyles';


export default class ReceiptSettings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      img: null,
      ext: '',
      imgDialog: false,
      loading: false,
      receiptPreview: false,
      invoicePreview: false,
      paymentPreview: false,
      customLogo: {},
      showColorPicker: false,
      color: null
    };

    this.loadData = this.loadData.bind(this);
    this.handleAddLogo = this.handleAddLogo.bind(this);
    this.handleResetImg = this.handleResetImg.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.closeReceiptPreviewDialog = this.closeReceiptPreviewDialog.bind(this);
    this.openReceiptPreviewDialog = this.openReceiptPreviewDialog.bind(this);
    this.closeInvoicePreviewDialog = this.closeInvoicePreviewDialog.bind(this);
    this.openInvoicePreviewDialog = this.openInvoicePreviewDialog.bind(this);
    this.closePaymentPreviewDialog = this.closePaymentPreviewDialog.bind(this);
    this.openPaymentPreviewDialog = this.openPaymentPreviewDialog.bind(this);
    this.setShowColorPicker = this.setShowColorPicker.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.getDropzoneOptions = this.getDropzoneOptions.bind(this);

  }

  UNSAFE_componentWillMount() {
    this.loadData(this.props);
  }

  loadData(props) {

    return props.dispatch(getMerchantSettings(props.user)).then((res) => {

      if (res && res.response && res.response.merchant_settings && res.response.merchant_settings.brand_color) {
        this.setState({
          color: res.response.merchant_settings.brand_color
        });
      }

      if (res && res.response && res.response.merchant_settings && res.response.merchant_settings.logo) {
        this.setState({
          img: res.response.merchant_settings.logo,
          ext: SettingsUtil.getImgExt(res.response.merchant_settings.logo)
        });
      }

      if (res && res.response && res.response.merchant_settings && res.response.merchant_settings.customLogo) {
        this.setState({
          customLogo: {
            img: res.response.merchant_settings.customLogo,
            ext: SettingsUtil.getImgExt(res.response.merchant_settings.customLogo),
          }
        });
      }
    });
  }

  handleAddLogo(file) {
    return FileUtil.getBase64(file[0]).then((result) => {
      const base64Sections = result.base64?.split('/')?.[1];
      const ext = base64Sections?.split(';')?.[0];
      const imgString = result.base64.replace(`data:image/${ext};base64,`, '');
      ext && this.setState({
        img: imgString,
        ext: ext,
        imgDialog: false
      });
    });
  }

  onSaveClick() {
    this.props.dispatch(submit('receiptSettingsForm'));
  }

  handleSave(values) {
    const that = this;
    this.setState({loading: true});
    return this.props.dispatch(partialUpdateSettings(
      SettingsUtil.createReceiptSettingsPayload(values, this.state.img,
        this.props.forms && this.props.forms.image && this.props.forms.image.img, this.state.color), this.props.user)).then(() => {
      that.setState({loading: false});
      that.loadData(that.props);
    });
  }

  closeReceiptPreviewDialog() {
    this.setState({receiptPreview: false});
  }

  openReceiptPreviewDialog() {
    this.setState({receiptPreview: true});
  }

  closeInvoicePreviewDialog() {
    this.setState({invoicePreview: false});
  }

  openInvoicePreviewDialog() {
    this.setState({invoicePreview: true});
  }

  closePaymentPreviewDialog() {
    this.setState({paymentPreview: false});
  }

  openPaymentPreviewDialog() {
    this.setState({paymentPreview: true});
  }

  handleResetImg() {
    this.setState({img: null});
  }

  setShowColorPicker() {
    this.setState({showColorPicker: !this.state.showColorPicker})
  }

  handleColorChange(color) {
    this.props.dispatch(change('receiptSettingsForm', 'brand_color', color.hex));
    this.setState({color: color.hex})
  };

  getDropzoneOptions() {
    return attachFileOptions.imageSinglePngJpgDropzoneOptions
  }

  render() {

    const { merchantSettings, formReducer, t } = this.props;

    const filterPanel = (
      <FilterPanel
        {...this.props}
        filterData={this.props.settingsMenuItems}
        selectFilterCallback={this.props.handleMenuSelection}
        buttonBar
      />
    );

    const isEmpty = merchantSettings.customReportStartTime === null;

    const pageLoading = isEmpty;

    if (pageLoading) {
      return (
        <Page
          loading={pageLoading}
          title={t('Business Settings')} />
      )
    }

    const removeIcon = IconUtil.getIcon('Close', '#3E3935');

    const logo = this.state.img;

    const receiptPreview = <Modal
      title={t('PreviewReceipt')}
      cancelText={t('Close')}
      maxWidth='sm'
      open={this.state.receiptPreview}
      onClose={this.closeReceiptPreviewDialog}
      hideConfirmButton
    >
      <ReceiptPreview
        {...this.props}
        logo={this.state.img}
        logoExt={this.state.ext}
        loyaltyProgram={false}
      />
    </Modal>;

    const invoicePreview = <Modal
      title={t('PreviewInvoice')}
      cancelText={t('Close')}
      maxWidth='md'
      open={this.state.invoicePreview}
      onClose={this.closeInvoicePreviewDialog}
      hideConfirmButton
    >
      <InvoicePreview useReceiptSettingsValues={true} />
    </Modal>;

    const paymentPreview = <Modal
      title={t('PreviewPayment')}
      cancelText={t('Close')}
      maxWidth='lg'
      open={this.state.paymentPreview}
      onClose={this.closePaymentPreviewDialog}
      hideConfirmButton
    >
      <PaymentPortalPreview
        {...this.props}
        logo={this.state.img}
        logoExt={this.state.ext}
      />
    </Modal>;

    return (
      <Box className='accountReceiptSettings' sx={settingsFormStyles}>
        <div className='pageWrap'>
          <Page
            loading={pageLoading}
            title={t('Business Settings')}
            initialSubpage={t('Receipt & invoice settings')}
          >
            <div className='flexContainer flexContainerResponsiveLayout'>
            {filterPanel}
            <div className='businessSettingsSubsection receiptSettings'>
              <h2 className='heading'>{t('Receipt & invoice settings')}.</h2>

              <div className='previewContainer'>
                <div className='previewButton' onClick={this.openReceiptPreviewDialog}>{t('PreviewReceipt')}</div>
                <div className='previewButton center' onClick={this.openInvoicePreviewDialog}>{t('PreviewInvoice')}</div>
                <div className='previewButton' onClick={this.openPaymentPreviewDialog}>{t('PreviewPayment')}</div>
              </div>

              <h3 className='subheading'>{t('BusinessInformation')}</h3>
              <p className='description'>{t('BusinessDescription')}</p>

              <div className='mainContent'>
                {logo ? <div className='inputRow inputRowImg'>
                    <img
                      src={`data:image/${this.state.ext};base64,${this.state.img}`}
                      alt='Company Logo'/>
                    <span className='removeLogo'
                          onClick={this.handleResetImg}>{removeIcon}</span>
                  </div> :
                  <div className='inputRow'>
                    <AttachFilesForm
                      label={t('Logo')}
                      displayType={AttachFilesFormType.LOGO}
                      logoSetState={this.handleAddLogo}
                      filesCopy={t('UploadLogo')}
                      dropzoneOptions={this.getDropzoneOptions()}
                      onSubmit={null}/>
                  </div>}

                <ReceiptSettingsForm
                  onSubmit={this.handleSave}
                  customLogo={this.state.customLogo}
                  showColorPicker={this.state.showColorPicker}
                  color={this.state.color}
                  setShowColorPicker={this.setShowColorPicker}
                  handleColorChange={this.handleColorChange}
                  {...this.props}/>
              </div>

              <div className='buttonPanel'>
                {formReducer && formReducer.receiptSettingsForm && formReducer.receiptSettingsForm.syncErrors &&
                  <span className='receiptErrorBottom'>{t('IncompleteSettingsForm')}</span>}
                <span>
                    <Button
                      label={t('SaveSettings')}
                      onClick={this.onSaveClick}
                    />
                  </span>
              </div>
            </div>
          </div>
          </Page>
        </div>
        {receiptPreview}
        {invoicePreview}
        {paymentPreview}
        {this.state.loading && <UpdateSpinner/>}
      </Box>
    );

  }

}
