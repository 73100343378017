import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import numeral from 'numeral';
import BarChart from '../../shared/charts/BarChart';
import CustomTableHeader from '../../shared/TableHeader';
import ItemsGraphSelector from '../ItemsGraphSelector';
import withStyles from '@mui/styles/withStyles';
import {primaryColorCheckBox} from '../../../jss/inlineStyles';

export const CustomCheckbox = withStyles(primaryColorCheckBox)
  ((props) => <Checkbox color='default' {...props} />);

const ItemsChart = (props) => {

  const {
    itemsTableCheckBox, itemsTableData, salesData, itemsData, handleRowSelection,
    userExperience: { charts, selectedRows = [] },
  } = props;

  const tableLength = itemsTableData.length;

  const tableHeadClick = (event) => {
    if (event.target.checked) {
      handleRowSelection('all');
      return;
    }
    handleRowSelection('none');
  };

  const tableRowClick = (i) => () => {
    const selected = [...selectedRows];
    const newSelected = selected?.includes(i) ?
      selected?.filter((rowIndex) => rowIndex !== i) : [...selected, i];

    handleRowSelection(newSelected);
  };

  const checkBoxState = {
    checked: tableLength > 0 && selectedRows.length === tableLength,
    indeterminate: selectedRows.length > 0 && selectedRows.length < tableLength,
  };


  return (
    <div id='ItemsChart'>
      <BarChart
        chartLabels={props.itemsChartLabels}
        currentData={props.itemsChartValues}
        width={600}
        height={200}
        yAxesType={charts.itemsyAxesType}
        name='itemsChart'
        {...props}
      />
      <ItemsGraphSelector {...props} />

      <Table>
        <TableHead>
          <TableRow className='tableRow'>

            {itemsTableCheckBox && (
              <TableCell
                component='th'
                padding='checkbox'
              >
                <CustomCheckbox
                  checked={checkBoxState.checked}
                  component={'div'}
                  indeterminate={checkBoxState.indeterminate}
                  inputProps={{'aria-label': 'select all'}}
                  onChange={tableHeadClick}
                />
              </TableCell>
            )}

            <CustomTableHeader
              type='table'
              clickable={true}
              headerId={'ItemsChartHeaderTwo'}
              attribute={'name'}
              name={props.t('Name')}
              {...props}
            />
            <CustomTableHeader
              type='table'
              clickable={true}
              headerId={'ItemsChartHeaderThree'}
              attribute={'sold'}
              name={props.t('Sold')}
              {...props}
            />
            <CustomTableHeader
              type='table'
              clickable={true}
              headerId={'ItemsChartHeaderFour'}
              attribute={'total'}
              name={props.t('Net Sales')}
              {...props}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {itemsTableData.map((item, i) => {
              return (
                <TableRow
                  className={`tableRow ${itemsData.itemsTableData.length === i + 1 ? 'doubleBottomBorder' : ''}`}
                  key={i}
                  onClick={itemsTableCheckBox ? tableRowClick(i) : undefined}
                >
                  {itemsTableCheckBox && (
                    <TableCell padding='checkbox'>
                      <CustomCheckbox
                        checked={_.includes(selectedRows, i)}
                        component={'div'}
                        inputProps={{'aria-labelledby': `enhanced-table-checkbox-${i}`}}
                      />
                    </TableCell>
                  )}
                  <TableCell>
                    {item.name}
                  </TableCell>
                  <TableCell> {item.sold} </TableCell>
                  <TableCell>{numeral(item.total).format('$0,0.00')}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
        <TableFooter>
          <TableRow className='tableRow noBottomBorder'>
            {itemsTableCheckBox ? <TableCell/> : null}
            <TableCell>
              {'Total to Account For'}
            </TableCell>
            <TableCell>
              {salesData.categoryTotalSold}
            </TableCell>
            <TableCell>{numeral(salesData.categoryTotalSales).format('$0,0.00')}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
};

export default ItemsChart;

/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */
