/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { setVisibilityFilter } from '../../actions/userExperienceActions';
import { getCustomerPaymentMethods } from '../../actions/customerActions';
import FormatTextUtil from '../util/FormatTextUtil';
import MerchantLogo from '../MerchantLogo';
import OpenInvoices from './OpenInvoices';
import PaymentHistory from './PaymentHistory';
import SessionExpired from './SessionExpired';
import PaymentMethods from './paymentMethods/PaymentMethods';
import FilterPanel from '../shared/FilterPanel';
import Facebook from '../icons/FacebookBlackIcon';
import Twitter from '../icons/TwitterBlackIcon';
import Instagram from '../icons/InstagramBlackIcon';
import YouTube from '../icons/YouTubeBlackIcon';
import Yelp from '../icons/YelpBlackIcon';
import {getCustomerInvoicesTransactions} from '../../actions/transactionsActions';
import {
  CustomerPaymentFilterOptions,
  CustomerPaymentFilterTypes
} from '../util/InvoiceUtil';
import Loading from '../Loading';
import {getCustomerInvoices, setInvoice} from '../../actions/invoicesActions';

export default class InvoiceLanding extends Component {

  state = {
    initialLoading: true,
    initialFilter: CustomerPaymentFilterTypes.SESSION_EXPIRED,
    secondsRemaining: null
  };

  async componentDidMount() {
    await this.loadData();
  }

  componentWillUnmount() {
    this.props.dispatch(setVisibilityFilter(null, 'filter'));
    clearInterval(this.interval);
  }

  showTokenSessionExpired = () => {
    const {token, decodedToken} = this.props;

    let timeUntilTokenExpires = 0;

    if (token && decodedToken) {
      const tokenExpiration = decodedToken?.iat ? decodedToken?.iat + clientLandingPageExpiration : 0;
      timeUntilTokenExpires = tokenExpiration - (Date.now().valueOf() / 1000);

      this.setState({secondsRemaining: Math.round(timeUntilTokenExpires)});
      this.interval = setInterval(this.tick, 1000);

      return timeUntilTokenExpires <= 0
    }
  }

  getInitialFilter = () => {
    const { overwriteInitialFilter } = this.props;

    if (this.showTokenSessionExpired()) {
      return CustomerPaymentFilterTypes.SESSION_EXPIRED;
    } else if (overwriteInitialFilter && overwriteInitialFilter === 'history') {
      return CustomerPaymentFilterTypes.PAYMENT_HISTORY;
    }

    return CustomerPaymentFilterTypes.OPEN_INVOICES;
  };

  tick = () => {
    const { visibilityFilter } = this.props
    const { secondsRemaining } = this.state;

    this.setState({ secondsRemaining: secondsRemaining - 1 });

    if (secondsRemaining <= 0 && visibilityFilter?.filter?.value !== CustomerPaymentFilterTypes.SESSION_EXPIRED) {
      this.handleFilterSelection({ name: CustomerPaymentFilterTypes.SESSION_EXPIRED });
    }
  };

  loadData = async () => {
    const { dispatch, token, decodedToken } = this.props;

    const initialFilter = this.getInitialFilter();
    const initialVisibilityFilter = {
      property: 'type',
      value: initialFilter
    };

    this.setState({ initialFilter });

    if (token && decodedToken) {
      const requests = [
        dispatch(getCustomerInvoices(token)),
        dispatch(getCustomerInvoicesTransactions(token)),
        dispatch(getCustomerPaymentMethods(token)),
        dispatch(setVisibilityFilter(initialVisibilityFilter, 'filter'))
      ];

      await Promise.all(requests);
    }

    if (this.state.initialLoading) {
      this.setState({ initialLoading: false });
    }
  };

  handleFilterSelection = async (filter) => {
    const { token, dispatch } = this.props;
    const visibilityFilter = { property: 'type', value: filter.name };
    const isOpenInvoices = filter.name === CustomerPaymentFilterTypes.OPEN_INVOICES;
    await dispatch(setVisibilityFilter(visibilityFilter, 'filter'));
    await dispatch(setInvoice(null));
    if (isOpenInvoices) {
      await dispatch(getCustomerInvoices(token));
    }
  };

  getFilterOptions = (merchant) => {
    CustomerPaymentFilterOptions[0].count = this.props.openInvoices?.length;

    const filterOptions = merchant?.type !== 'mbp' ? CustomerPaymentFilterOptions :
      CustomerPaymentFilterOptions.filter(CustomerPaymentFilterOption => CustomerPaymentFilterOption.name !== CustomerPaymentFilterTypes.PAYMENT_HISTORY)

    return filterOptions;
  }

  getMainContent = (type) => {
    const { initialFilter } = this.state;

    const content = {
      [CustomerPaymentFilterTypes.OPEN_INVOICES]:   <OpenInvoices   {...this.props}/>,
      [CustomerPaymentFilterTypes.PAYMENT_METHODS]: <PaymentMethods {...this.props}/>,
      [CustomerPaymentFilterTypes.PAYMENT_HISTORY]: <PaymentHistory {...this.props}/>,
      [CustomerPaymentFilterTypes.SESSION_EXPIRED]: <SessionExpired {...this.props}/>
    };

    return content?.[type] || content?.[initialFilter];
  };

  renderFooter() {
    const { merchantSettings, t } = this.props;
    const merchant = merchantSettings?.merchantSettings;
    return (
      <div className='customerPaymentFilterFooter'>

        {merchant?.address &&
        <div className='businessInfo'>

          <div className='name'>{merchant?.business_name}</div>
          <div>{merchant?.address}</div>
          {merchant?.address2 && <div>{merchant?.address2}</div>}
          <div>{`${merchant?.city}, ${merchant?.state} ${merchant?.zip_code}`}</div>
          <div>{FormatTextUtil.formatPhoneNumber(merchant?.phone_number)}</div>
          <div>
            <a href={`https://${merchant?.website}`} target='_blank'>{merchant?.website}</a>
          </div>
        </div>
        }

        <ul className='social'>
          {merchant?.facebook_link && <li>
            <a href={`https://www.facebook.com/${merchant?.facebook_link}`} target='_blank'>
              <Facebook />
            </a>
          </li>}
          {merchant?.twitter_link && <li>
            <a href={`https://twitter.com/${merchant?.twitter_link}`} target='_blank'>
              <Twitter />
            </a>
          </li>}
          {merchant?.instagram_link && <li>
            <a href={`https://www.instagram.com/${merchant?.instagram_link}`} target='_blank'>
              <Instagram />
            </a>
          </li>}
          {merchant?.yelp_link && <li>
            <a href={`https://www.yelp.com/biz/${merchant.yelp_link}`} target='_blank'>
              <Yelp />
            </a>
          </li>}
          {merchant?.youtube_link && <li>
            <a href={`https://www.youtube.com/c/${merchant.youtube_link}`} target='_blank'>
              <YouTube />
            </a>
          </li>}
        </ul>

        <div className='footer'>
          <span className='filterLogo'/>
          <span>{t('PoweredBy', { company: 'Payments Hub' })}</span>
        </div>
      </div>
    );
  };

  render() {
    const { initialLoading } = this.state;
    const { visibilityFilter, merchantSettings, t, decodedToken } = this.props;
    const merchant = merchantSettings?.merchantSettings;

    if (initialLoading) { return <Loading />; }

    const mainContent = this.getMainContent(visibilityFilter?.filter?.value);
    const filterFooter = this.renderFooter();
    let filterData = this.getFilterOptions(merchant);
    filterData = filterData.map(filter => ({
      name: t(filter.name),
      ...filter
    }));

    const filterHeader = (
      <div className='logoHolder'>
        {merchant?.logo && (
          <MerchantLogo
            altText={merchant?.merchant_name}
            logoBase64={merchant?.logo}
            size={90}
          />
        )}
      </div>
    );

    return (
      <section className='customerPayment'>
        <div className='customerPaymentWrapper flexContainerResponsiveLayout'>
          {visibilityFilter?.filter?.value !== CustomerPaymentFilterTypes.SESSION_EXPIRED &&
            decodedToken &&
            <FilterPanel
              {...this.props}
              pageTitle={filterHeader}
              className='filterPanel'
              defaultIcon=''
              filterData={filterData}
              selectFilterCallback={this.handleFilterSelection}
              hideFilterLogo={false}
              optionalFilterFooter={filterFooter}
              clp={true}
            />
          }
          <div className='customerPaymentContent'>
            {mainContent}
          </div>
        </div>
      </section>
    );
  }
}

InvoiceLanding.propTypes = {
  dispatch: PropTypes.func,
  visibilityFilter: PropTypes.object,
  token: PropTypes.string,
  decodedToken: PropTypes.object,
  loading: PropTypes.bool,
  openInvoices: PropTypes.array
};
