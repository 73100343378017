/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { Box } from '@mui/material'
import { Link } from 'react-router-dom';

import IconUtil from '../../util/IconUtil';
import CsvUtil from '../../util/CsvUtil';
import ReportUtil from '../../util/ReportUtil';
import UserUtil from '../../util/UserUtil';

import routes from '../../../constants/routes';

import Loading from '../../Loading';
import NoData from '../../NoData';
import Button from '../../shared/Button';
import { reportContainerStyles } from '../../../jss/table';

export default class TransactionsItemExport extends Component {

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handlePrintViewClick = this.handlePrintViewClick.bind(this);
  }

  handlePrintViewClick() {

    let reportWindow = window.open('about:blank');

    const transactionsWithItemsJson = CsvUtil.createTransactionWithItemsJson(this.props);
    const transactionsWithItemsHeaders = CsvUtil.constructTransactionsWithItemsHeaders(transactionsWithItemsJson);

    let html = ReportUtil.createHtmlFromCsvReport('Transactions by Item', this.props, transactionsWithItemsJson, transactionsWithItemsHeaders);
    if (!!reportWindow?.document) {
      reportWindow.document.write(html);
      reportWindow.document.title = 'Transactions by Item Report';
      ReportUtil.windowBindListeners(reportWindow);
    }
  }

  handleClick() {
    this.props.exportReport('TransactionsByItem', this.props);
  }

  render() {

    const { isFetching, transactions, user, t } = this.props;

    const isEmpty = transactions.receipts === null;
    const printIcon = IconUtil.getIcon('PrintIcon');
    const exportIcon = IconUtil.getIcon('LaunchIcon');

    if (isEmpty && isFetching) {
      return (
        <Loading/>
      )
    } else {
      if (transactions.receipts.length > 0) {
        const transactionsWithItemsJson = CsvUtil.createTransactionWithItemsJson(this.props);

        const transactionPath = globalApplicationLabel.path + routes.activity.root + routes.activity.transactions;
        const selectedMid = UserUtil.getActiveAccount(user)?.mid;

        return (
          <Box sx={reportContainerStyles}>
            <div className='exportContainer'>
              <div className='downloadLink'>
                <Button variant='link' className='printButton' onClick={this.handlePrintViewClick} startIcon={printIcon} label={t('Print')} />
                <Button variant='link' className='exportButton' onClick={this.handleClick} startIcon={exportIcon} label={t('Export')} />
              </div>
            </div>

            <div className='flexRowHeader hideMobile'>
              <div>{'DATE'}</div>
              <div>{'ACCOUNT NUMBER'}</div>
              <div>{'INVOICE'}</div>
              <div>{'CATEGORY'}</div>
              <div>{'ITEM NAME'}</div>
              <div>{'MODIFIERS APPLIED'}</div>
              <div>{'MODIFIERS PRICES'}</div>
              <div>{'ITEM QUANTITY'}</div>
              <div>{'UNIT PRICE'}</div>
              <div>{'DISCOUNT $'}</div>
              <div>{'DISCOUNT %'}</div>
              <div>{'TAX %'}</div>
              <div>{'TOTAL TRANSACTION AMOUNT'}</div>
              <div>{'TRANSACTION TYPE'}</div>
              <div>{'CUSTOM FIELD'}</div>
            </div>

            {transactionsWithItemsJson.map((transaction, i) => {

              const originalTx = this.props.transactions.receipts.find(tx => tx.invoice === transaction['Invoice']);

              const content = (
                <>
                  <div>{transaction['Date']}</div>
                  <div>{transaction['Account Number']}</div>
                  <div>{transaction['Invoice']}</div>
                  <div>{transaction['Category']}</div>
                  <div>{transaction['Item Name']}</div>
                  <div>{transaction['Modifiers Applied']}</div>
                  <div>{transaction['Modifiers Prices']}</div>
                  <div>{transaction['Item Quantity']}</div>
                  <div>{transaction['Unit Price']}</div>
                  <div>{transaction['Discount $']}</div>
                  <div>{transaction['Discount %']}</div>
                  <div>{transaction['Tax %']}</div>
                  <div>{transaction['Total Transaction Amount']}</div>
                  <div>{transaction['Transaction Type']}</div>
                  <div>{transaction['Custom Field']}</div>
                </>
              );

              if (selectedMid === originalTx.mid) {
                return (
                  <Link key={i} className='flexRowItem flexRowItemClickable hideMobile' to={transactionPath + '?receiptId=' + originalTx.receipt_id}>
                    {content}
                  </Link>
                );
              }

              return <div key={i} className='flexRowItem hideMobile'>{content}</div>;

            })}
          </Box>
        )
      } else {
        return (<NoData text={t('NoDataTransactionsItemExport')} />)
      }
    }

  }
}

TransactionsItemExport.defaultProps = {
  isFetching: true,
};
