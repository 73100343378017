/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TitlePanel from './../TitlePanel';
import moment from 'moment';
import DoughnutChart from '../shared/charts/DoughnutChart';
import NoData from './../NoData';
import Toggle from './../Toggle';
import SalesDataUtil from '../util/SalesDataUtil';
import MobileTable from '../MobileTable';
import userUtil from '../util/UserUtil';

export default class Method extends Component {

  constructor(props) {
    super(props);

    this.handlePaymentMethodToggle = this.handlePaymentMethodToggle.bind(this);
    this.handleMobileFooterToggle = this.handleMobileFooterToggle.bind(this);

    this.state = {
      formatToggle: '$',
      typeToggle: 'TYPE'
    };

  }

  handlePaymentMethodToggle(value) {
    this.setState({formatToggle: value});
  };

  handleMobileFooterToggle(value) {
    this.setState({typeToggle: value});
  };

  render() {

    const {activities, userExperience, user, t} = this.props;

    let methodVoidRefunds = SalesDataUtil.methodVoidRefunds(activities.data);

    const headers = ['NAME', 'CHANGE', 'TRANSACTIONS', 'GROSS SALES', 'REFUNDS', 'VOIDS', 'NET SALES'];

    const rows = [
      ['Chip-Read Credit', 0, activities.data.chipSalesCount, activities.data.chipSales, methodVoidRefunds.chipRefunds, methodVoidRefunds.chipVoids, methodVoidRefunds.netChip],
      ['Contactless Credit', 0, activities.data.contactlessSalesCount, activities.data.contactlessSales, methodVoidRefunds.contactlessRefunds, methodVoidRefunds.contactlessVoids, methodVoidRefunds.netContactless],
      ['Swiped Credit', 0, activities.data.swipedSalesCount, activities.data.swipedSales, methodVoidRefunds.swipedRefunds, methodVoidRefunds.swipedVoids, methodVoidRefunds.netSwiped],
      ['Keyed Credit', 0, activities.data.keyedSalesCount, activities.data.keyedSales, methodVoidRefunds.keyedRefunds, methodVoidRefunds.keyedVoids, methodVoidRefunds.netKeyed],
      ['Cash', 0, activities.data.cashSalesCount, activities.data.cashSales, methodVoidRefunds.cashRefunds, methodVoidRefunds.cashVoids, methodVoidRefunds.netCash]
    ];

    if (userUtil.userType(user) === 'PA') {
     rows.push([
       'Gift Card',
       0,
       activities.data.giftCardSalesCount,
       activities.data.giftCardSales,
       methodVoidRefunds.giftCardRefunds,
       methodVoidRefunds.giftCardVoids,
       methodVoidRefunds.netGiftCard
     ]);
    }

    let methodChartTableRes = activities.data.currentActivities.data.transactions.length ? <MobileTable headers={headers} rows={rows} compared={!!userExperience.compareToPeriod}/> : null;

    let paymentMethodsDonutCharts = activities.data.paymentMethods.length > 0 && activities.data.paymentMethods[0].count > 0 ||
      activities.data.paymentMethods.length > 1 && activities.data.paymentMethods[1].count > 0 ? (
        <DoughnutChart
          chartData={this.state.formatToggle === '%' ? this.props.chartData.paymentMethodPercentageDataSet : this.props.chartData.paymentMethodSalesDataSet}
          chartLabels={this.props.chartData.paymentMethodLabels}
          chartBackgroundColor={this.props.chartData.paymentMethodBackgroundColor}
          chartHoverBackgroundColor={this.props.chartData.paymentMethodHoverColor}
          t={t}
        />
      ) : (<NoData className='reportsNoDataMessage' text='No Payment Method Data'/>);

    let creditCardNetworkSalesDonutCharts = activities.data.creditCardNetworkSales.length > 0 ? (
        <DoughnutChart
          chartData={this.state.formatToggle === '%' ? this.props.chartData.creditPercentageDataSet : this.props.chartData.creditSalesDataSet}
          chartLabels={this.props.chartData.creditSalesLabels}
          chartBackgroundColor={this.props.chartData.creditSalesBackgroundColor}
          chartHoverBackgroundColor={this.props.chartData.creditSalesHoverColor}
          t={t}
        />
      ) : (<NoData className='reportsNoDataMessage' text='No Credit Sales Data'/>);

    return (
      <div className='mobilePaymentMethod'>
        <TitlePanel title='Payment method'
                    subText={moment(this.props.dateRange.startDate).format('MM/DD/YYYY h:mm:ss a') + ' - ' +
                    moment(this.props.dateRange.endDate).format('MM/DD/YYYY h:mm:ss a')}/>
        <Toggle rightToggle='%' leftToggle='$' toggleChanged={this.handlePaymentMethodToggle}/>
        {this.state.typeToggle === 'BRAND' ? creditCardNetworkSalesDonutCharts : paymentMethodsDonutCharts}
        <Toggle toggleClass='mobileFooterToggle' rightToggle='BRAND' leftToggle='TYPE' toggleChanged={this.handleMobileFooterToggle}/>
        {methodChartTableRes}
      </div>
    )
  }
}

Method.propTypes = {
  dateRange: PropTypes.object,
  chartData: PropTypes.object,
};
