/**
 NAB Holdings, LLC (“NAB”) CONFIDENTIAL MATERIAL

 Unpublished Copyright © 2000-2017 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import GridViewIcon from '@mui/icons-material/GridView';
import ListIcon from '@mui/icons-material/List';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import numeral from 'numeral';
import SettingsUtil from '../util/SettingsUtil';
import FormatTextUtil from '../util/FormatTextUtil';
import LabelUtil from '../util/LabelUtil';

const StorePreview = (props) => {

  const { items, merchantSettings, t } = props;
  const { salesItems, categories } = items;

  const merchant = merchantSettings?.merchantSettings;
  const slicedCategories = categories?.slice(0, 9);
  const slicedItems = salesItems?.slice(0, 8);

  const labelColor = LabelUtil.getLabelColor();
  const brandColor = merchant?.brand_color;
  const logo = merchant?.logo;
  const logoExt = logo ? SettingsUtil.getImgExt(logo) : '';
  const shouldShowLogo = merchant?.display_company_logo && logo && logoExt;

  const merchantName = merchant?.merchant_name;
  const address1 = merchant?.street_address?.address1;
  const address2 = merchant?.street_address?.address2;
  const city = merchant?.street_address?.city;
  const state = merchant?.street_address?.state;
  const zip = merchant?.street_address?.zip_code;
  const merchantPhone = merchant?.phone_number;
  const website = merchant?.website;

  const card = ({item}) => {
    const letters = item?.name?.match(/\b(\w)/g)?.slice(0, 2)?.join('');
    return (<Paper className='itemCard'>
      {item?.thumbnail ? <img className='itemImage' src={`data:image/png;base64,${item?.thumbnail}`}/> :
        <div className='itemImage' style={{backgroundColor: brandColor || labelColor}}>
          <span>{letters}</span>
        </div>}
      <div className='cardContent'>
        <h5>{item.name}</h5>
        <p>{item.description}</p>
      </div>
      <div className='price'>
        <span>
          {`${t('from')}: ${numeral(item.details.prices[0].price).format('$0,0.00')}`}
        </span>
      </div>
    </Paper>);
  }

  return (
    <section className='storePreview'>
      <div className='leftPanel'>
        {shouldShowLogo && <img src={`data:image/${logoExt};base64,${logo}`} className='logo'/>}
        <ul className='itemCategories'>
          <li className='category' style={{color: brandColor, fontWeight: 'bold'}}>All Items</li>
          {slicedCategories.map((category, index) => {
            return <li key={`cat-${index}`} className='category'>{category.name}</li>
          })}
        </ul>
        <div className='divider' />
        <div className='bottom'>
          <div className='merchantInfo'>
            <h5>{merchantName}</h5>
            <p>{address1}</p>
            <p>{address2}</p>
            <p>{`${city}, ${state} ${zip}`}</p>
            <p>{FormatTextUtil.formatPhoneNumber(merchantPhone)}</p>
            <p>{website}</p>
          </div>
          <div className='branding'>
            <span className='img' />
            <p>{t('PoweredBy', { company: LabelUtil.getLabel().text })}</p>
          </div>
        </div>
      </div>
      <div className='holder'>
        <div className='toolsContainer'>
          <div className='main'>
            <div className='search'>
              <span className='icon'>
                <SearchIcon />
              </span>
              <span className='text'>Search</span>
            </div>
            <div className='cart'>
              <ShoppingBasketIcon />
            </div>
          </div>
          <div className='controls'>
            <div className='sort'>
              <span className='text'>Sort by Name</span>
              <span className='icon'>
                <ArrowDropDownIcon />
              </span>
            </div>
            <div className='view'>
              <span className='grid'>
                <GridViewIcon />
              </span>
              <span className='list'>
                <ListIcon />
              </span>
            </div>
          </div>
          <ul className='paginationList'>
            <li>
              <ChevronLeftIcon />
            </li>
            <li className='active' style={{backgroundColor: brandColor || labelColor}}>1</li>
            <li>2</li>
            <li>3</li>
            <li>4</li>
            <li>5</li>
            <li>
              <ChevronRightIcon />
            </li>
          </ul>
        </div>
        <Grid
          container
          spacing={2}>
          {slicedItems.map((salesItem, index) =>
            <Grid key={`item-${index}`} item xs={3} md={3} lg={3}>
            {card({item: salesItem})}
          </Grid>)}
        </Grid>
      </div>
    </section>
  );

}

export default StorePreview;
