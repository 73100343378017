/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, {Component} from 'react';
import Checkbox from '../shared/Checkbox';
import UpdateSpinner from '../UpdateSpinner';
import messages from '../../constants/messages';
import {acceptTerms, receiveTermsAccount, toggleSavingAgreement, logoutUser} from '../../actions/authActions';
import LabelUtil from '../util/LabelUtil';
import UserUtil from '../util/UserUtil';
import { toggleTerms2FADialog } from '../../actions/userExperienceActions';
import Terms2FADialog from '../dialogs/legal/Terms2FADialog';
import { setVTAccessProcessValues } from '../../actions/userExperienceActions';
import IconUtils from '../util/IconUtil';
import Modal from '../shared/Modal';
import { Trans } from 'react-i18next';
import {Box} from '@mui/material';
import {TermsAndConditionsStyles} from '../../jss/inlineStyles';

export default class Terms extends Component {

  constructor(props) {
    super(props);

    this.state = {
      agreeUser: false,
      needsInfo: UserUtil.isNeedsInfo(this.props.user),
      isUserMbp: UserUtil.isMybizperks(this.props.user),
      merchantError: false
    };

    this.userChecked = this.userChecked.bind(this);
    this.handleAccept = this.handleAccept.bind(this);
    this.checkUser = this.checkUser.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.logOut = this.logOut.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(setVTAccessProcessValues())
  }

  handleAccept() {

    let that = this;
    return new Promise(function (resolve, reject) {
      that.props.dispatch(acceptTerms(that.props.user, that.props.auth.termsAndConditionsID)).then((payload) => {

        const newToken =  payload?.response?.token;
        const newUser = payload?.response?.user;
        const newMerchant = payload?.response?.merchant;

        if (newMerchant && typeof newMerchant === 'object') {
          that.props.dispatch(receiveTermsAccount(newToken, newUser, newMerchant));
          const appRoutePrefix = globalApplicationLabel.path;
          that.props.history.push(appRoutePrefix);
          resolve();
        } else {
          that.props.dispatch(toggleSavingAgreement());
          that.setState({merchantError: true});
          resolve();
        }

      }).catch(error => {
        const err = 'Error accepting terms: ' + error.message;
        reject(err);
      });

    });

  }

  userChecked(el) {
    this.setState({agreeUser: el.target.checked});
  }

  checkUser() {
    const { needsInfo, isUserMbp } = this.state;
    const openDialog = needsInfo && isUserMbp;
    if (openDialog) {
      this.openDialog();
    } else {
      this.handleAccept()
        .catch(() => {
          this.setState({merchantError: true, agreeUser: false});
        });
    }
  }

  closeDialog() {
    this.props.dispatch(toggleTerms2FADialog(false));
  }

  openDialog() {
    this.props.dispatch(toggleTerms2FADialog(true));
  }

  logOut() {
    this.props.dispatch(logoutUser());
  }

  render() {
    const label = LabelUtil.getLabel();
    const labelText = label.text;
    const isOwner = this.props.auth.isOwner;
    let allowAccept = this.state.agreeUser;
    const spinner = this.props.auth.savingAgreement ? (<UpdateSpinner />) : null;
    const { t } = this.props;

    const legalToShow = UserUtil.legalToShow(this.props.user);

    const terms2FADialog = (this.props.userExperience.terms2FADialog && <Terms2FADialog
      closeAction={this.closeDialog}
      acceptAction={this.handleAccept}
      twoFactorCodeStatus={this.props.auth.twoFactorCodeStatus}
      twoFactorPhoneStatus={this.props.auth.twoFactorPhoneStatus}
      dispatch={this.props.dispatch}
      user={this.props.user}
      {...this.props}
      uxStorage={this.props.userExperience.uxStorage}
      t={t}
    />)

    const notFD = (
      <Box id='userScroll' className='content' sx={TermsAndConditionsStyles.TermsAndConditionBox}>
        <div className='userAgree'>
          {legalToShow}
        </div>
      </Box>
    );
    const FD = (
      <div className='fdContainer'>
        <p className='fdText'>
          {t('Legal.FDText')}
          <a className='fdLink' href={messages.legal.hostedFD} target='_blank' style={{color: label.primaryColor}}>
            {t('Legal.FDLink')}
          </a>.</p>
      </div>
    );

    let termsHeader = (
      <div className='termsHeader'>
        {isOwner ? (
          <div>
            <div className='headingText'>{t(messages.legal.headerA)}</div>
              <div>
                <p>{t(messages.legal.paCopy1)}</p>
              </div>
          </div>
        ) : (
          <div>
            <div className='headingText requiredHeading'>{t(messages.legal.headerRequired)}</div>
            <p>
              <Trans i18nKey={messages.legal.copyRequired}>
                To continue using {{labelText}}, the Business Principal/owner of this account must login and agree to the updated terms. Please contact your administrator.
              </Trans></p>
          </div>
        )}
      </div>
    );

    const submitButton = (
      <div id='loginButton'
          className={ allowAccept ? 'loginButton' : 'loginButton disabledSubmitButton' }
          onClick={allowAccept ? () => this.checkUser() : null}>
        <div className='loginFront'>
              <span className='loginText'>
                {` ${t(messages.legal.linkText)} `}
              </span>
        </div>
      </div>
    );

    const errorRetrieveMerchantModal = (
      <Modal
        title={t('TermsMerchantError.ModalTitle')}
        open={this.state.merchantError}
        hideCancelButton={true}
        confirmText={t('Okay')}
        onConfirm={this.logOut}
      >
        <p>{t('TermsMerchantError.ModalText')}</p>
      </Modal>
    )

    return (

      <>
        {terms2FADialog}
        {errorRetrieveMerchantModal}
        { isOwner ? (

          <>
            <div className='termsContainer'>

              {termsHeader}

              {(legalToShow !== 'FD') ? notFD : FD}

              <Box sx={TermsAndConditionsStyles.TermsAndConditionsButtonWrapper}>
                <Checkbox
                  uncheckedIcon={IconUtils.getIcon('CheckBoxOutlineBlankIcon', LabelUtil.getLabelColor())}
                  checkedIcon={IconUtils.getIcon('CheckBoxIcon', LabelUtil.getLabelColor())}
                  input={{
                    value: this.state.agreeUser,
                    onChange: this.userChecked,
                  }}
                  className='userCheckbox'
                  label={t(messages.legal.userAgree)}
                />
                {submitButton}
              </Box>


            </div>
            {spinner}
          </>

        ) : (

          <div className='termsContainer'>
            {termsHeader}
          </div>

        )}
      </>
    )
  }
}
