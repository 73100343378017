/**
North American Bancard ("NAB") CONFIDENTIAL MATERIAL

Copyright 2000 NAB, All Rights Reserved.

NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
and Non-disclosure agreements explicitly covering such access.

The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
MAY DESCRIBE, IN WHOLE OR IN PART.

*/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Field,
  reduxForm,
  submit,
  isDirty,
  getFormSyncErrors,
  change, getFormValues,
} from 'redux-form';
import CustomToggle from '../../shared/Toggle';
import MessageDialog from '../../shared/MessageDialog';
import Button from '../../shared/Button';
import TextField from '../../shared/TextField';
import Checkbox from '../../shared/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Select from '../../shared/Select';
import Loading from '../../Loading';
import FormatTextUtil from '../../util/FormatTextUtil';
import numeral from 'numeral';
import Validator from '../../util/Validator';
import BetaDisclaimerWithHover from '../../BetaDisclaimerWithHover';
import _ from 'lodash';
import UserUtil from '../../util/UserUtil';
import { Trans } from 'react-i18next';
import routes from '../../../constants/routes';
import {Link} from 'react-router-dom';
import {InputAdornment} from '@mui/material';
import Modal from '../../shared/Modal';
import IconUtils from '../../util/IconUtil';
import DateUtils from '../../util/DateUtil';
import Grid from '@mui/material/Grid';

const validate = values => {
  return Validator.validateLoyaltyVpc(values);
};

const defaultSelectValues = {
  discountType: 'percent',
  rewardExpirationDate: '12 months'
};

export class LoyaltyFormComponent extends Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.onSaveLoyaltyVpcForm = this.onSaveLoyaltyVpcForm.bind(this);
    this.handleRewardType = this.handleRewardType.bind(this);
    this.toggleGeneralPointRuleDialog = this.toggleGeneralPointRuleDialog.bind(this);
    this.cancelGeneralPointRuleDialog = this.cancelGeneralPointRuleDialog.bind(this);
    this.toggleRewardDialog = this.toggleRewardDialog.bind(this);
    this.cancelRewardDialog = this.cancelRewardDialog.bind(this);
    this.handleExpirationDate = this.handleExpirationDate.bind(this);
    this.goToUserSettings = this.goToUserSettings.bind(this);

    this.state = {
      openDeleteDialog: false,
      openReEnableDialog: false,
      openUpdateDialog: false,
      openForm: false,
      isGeneralPointRuleDialogOpen: false,
      isRewardDialogOpen: false,
      tempMinimumSpendValue: '',
      tempDiscountType: '',
      tempDiscountAmount: '',
      tempPointsNeeded: '',
      tempRewardExpiration: ''
    };
  }

  closeDialog() {
    this.setState({
      openDeleteDialog: false, openUpdateDialog: false, openReEnableDialog: false
    });
  }


  handleRewardType(value) {
    this.props.dispatch(change('loyaltyFormVpc', 'rewardAmountType', value));
  }

  handleExpirationDate(value){
    this.props.dispatch(change('loyaltyFormVpc', 'rewardExpiration', value));
  }

  toggleGeneralPointRuleDialog() {
    const {isGeneralPointRuleDialogOpen} = this.state;

    const {
      currentValues: {
        minimumSpend
      }
    } = this.props;

    const newState = {
      tempMinimumSpendValue: isGeneralPointRuleDialogOpen ? '' : minimumSpend
    };

    this.setState(prevState => ({
      ...newState,
      isGeneralPointRuleDialogOpen: !prevState.isGeneralPointRuleDialogOpen
    }));
  }

  cancelGeneralPointRuleDialog() {
    this.props.dispatch(change('loyaltyFormVpc', 'minimumSpend', this.state.tempMinimumSpendValue));
    this.toggleGeneralPointRuleDialog();
  }

  goToUserSettings() {
    const appRoutePrefix = globalApplicationLabel.path
    const accountRoute = appRoutePrefix + routes.account.root;

    this.props.history.push(accountRoute);
  }

  toggleRewardDialog() {
    const { isRewardDialogOpen } = this.state;
    const {
      currentValues: {
        rewardAmountType,
        rewardAmount,
        pointsToEarnReward,
        rewardExpiration,
      }
    } = this.props;

    const newState = {
      tempDiscountType: isRewardDialogOpen ? '' : rewardAmountType,
      tempDiscountAmount: isRewardDialogOpen ? '' : rewardAmount,
      tempPointsNeeded: isRewardDialogOpen ? '' : pointsToEarnReward ,
      tempRewardExpiration: isRewardDialogOpen ? '' : rewardExpiration,
    };

    this.setState(prevState => ({
      ...newState,
      isRewardDialogOpen: !prevState.isRewardDialogOpen
    }));
  }

  cancelRewardDialog() {
    this.toggleRewardDialog();

    this.props.dispatch(change('loyaltyFormVpc', 'pointsToEarnReward', this.state.tempPointsNeeded));
    this.props.dispatch(change('loyaltyFormVpc', 'rewardAmountType', this.state.tempDiscountType));
    this.props.dispatch(change('loyaltyFormVpc', 'rewardAmount', this.state.tempDiscountAmount));
    this.props.dispatch(change('loyaltyFormVpc', 'rewardExpiration', this.state.tempRewardExpiration));
  }

  onSaveLoyaltyVpcForm = () => {
    const {isFormEnabled, merchantSettings, dirtyFields} = this.props;
    const isFirstTimeInLoyalty = !merchantSettings?.loyaltyVpc?.points_to_earn_reward;
    const isDisabling = !isFormEnabled;
    const isReEnabled = !isFirstTimeInLoyalty &&
      dirtyFields.includes('enabled') && isFormEnabled;

    if (isFirstTimeInLoyalty) {
      this.submitForm();
    } else if (isDisabling) {
      this.setState({ openDeleteDialog: true });
    } else if (isReEnabled) {
      this.setState({ openReEnableDialog: true });
    } else {
      this.setState({ openUpdateDialog: true });
    }
  };

  submitForm = ()=> {
    this.props.dispatch(submit('loyaltyFormVpc'));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    const { isFormEnabled, dispatch} = this.props;
    if((isFormEnabled !== prevProps.isFormEnabled) && !isFormEnabled){
      dispatch(change('loyaltyFormVpc', 'tcAccepted', isFormEnabled));
    };
  }

  render() {
    const { t, handleSubmit, auth, handleOpenPreviewModal, openPreviewDialog, loyaltyVpcFormSyncErrors, isFormEnabled, user, currentValues, marketingTiles, isLoyaltyAddOnActive } = this.props;
    const {minimumSpend, rewardAmountType, rewardAmount, rewardExpiration, pointsToEarnReward, enabled} = currentValues;
    const isGeneralPointRuleFilled = !!minimumSpend;
    const isRewardInformationFilled = !!rewardAmountType && !!rewardAmount && !!rewardExpiration && !!pointsToEarnReward;
    const formHasErrors = !_.isEmpty(loyaltyVpcFormSyncErrors) || !isGeneralPointRuleFilled || !isRewardInformationFilled;
    const { isProcessing } = this.props.merchantSettings;

    const isDisabled = auth.isManager || UserUtil.isUserCS(user, auth);
    const disableDialog = (
      <MessageDialog
        cancelText={t('LoyaltyPrograms.No')}
        confirmText={t('LoyaltyPrograms.Yes')}
        externalClassName='disableDialog'
        isChoiceRequired={false}
        onConfirm={this.submitForm}
        onRequestClose={this.closeDialog}
        open={this.state.openDeleteDialog}
        scrollable={true}
        titleText={t('LoyaltyPrograms.DeleteTitle')}
        bodyText={
          <>
            <p className='confirmationText'>
              {t('LoyaltyPrograms.DeleteContent')}
            </p>
          </>
        }
      />
    );


    const updateDialog = (
      <MessageDialog
        cancelText={t('No')}
        confirmText={t('Yes')}
        externalClassName='updateDialog'
        isChoiceRequired={false}
        onConfirm={this.submitForm}
        onRequestClose={this.closeDialog}
        open={this.state.openUpdateDialog}
        scrollable={true}
        titleText={t('LoyaltyPrograms.UpdateTitle')}
        bodyText={
          <>
            <p className='confirmationText'>
              {t('LoyaltyPrograms.UpdateContent')}
            </p>
          </>
        }
      />
    );

    const reEnableDialog = (
      <MessageDialog
        cancelText={t('No')}
        confirmText={t('Yes')}
        externalClassName='reEnableDialog'
        isChoiceRequired={false}
        onConfirm={this.submitForm}
        onRequestClose={this.closeDialog}
        open={this.state.openReEnableDialog}
        scrollable={true}
        titleText={t('LoyaltyPrograms.ReEnableTitle')}
        bodyText={
          <>
            <p className='confirmationText'>
              {t('LoyaltyPrograms.ReEnableContent')}
            </p>
          </>
        }
      />
    );

    const appRoutePrefix = globalApplicationLabel.path;
    const loyaltyTermsRoute = appRoutePrefix + routes.loyaltyTerms;

    const expirationDates = {
      '3 months': DateUtils.formatDate(DateUtils.addTimeToToday(3, 'M'), 'MM/DD/YY'),
      '6 months': DateUtils.formatDate(DateUtils.addTimeToToday(6, 'M'), 'MM/DD/YY'),
      '9 months': DateUtils.formatDate(DateUtils.addTimeToToday(9, 'M'), 'MM/DD/YY'),
      '12 months': DateUtils.formatDate(DateUtils.addTimeToToday(12, 'M'), 'MM/DD/YY'),
      'notexpire': false
    }

    const activeExpirationDate = expirationDates[rewardExpiration];

    const toggleBox = (
      <div className='loyaltyToggleContainer'>
        <div>
          <h2>
            {t('LoyaltyPrograms.EnableVirtualCard')}{' '}
            <BetaDisclaimerWithHover t={t} type={'loyalty'} featureLabel={'New'}/>
          </h2>
          <Trans>{t('LoyaltyPrograms.VirtualCardDescription')}</Trans>
          <p>{t('LoyaltyPrograms.LoyaltyTermsLinkText')} <Link to={loyaltyTermsRoute} target='_blank'>{t('LoyaltyPrograms.LoyaltyTermsLink')}</Link></p>
          <Field
            disabled={!enabled || !isLoyaltyAddOnActive}
            name={'tcAccepted'}
            component={Checkbox}
            externalClassName={'loyaltyTermsLabel'}
            label={t('LoyaltyPrograms.LoyaltyTermsCheckboxLabel')}
          />
        </div>
        {
          isLoyaltyAddOnActive ?
            <div className='toggleContainer'>
              <Field
                name='enabled'
                component={CustomToggle}
                disabled={isDisabled}
              />
            </div>
          :
            <Button
              className='activateAddOnButton'
              onClick={this.goToUserSettings}
              disabled={UserUtil.isNorthDemoAccount(this.props.user)}
              label={t('LoyaltyPrograms.ActivateLoyaltyAddOn')}
            />
        }
      </div>
    );

    const generalPointRuleContent = (
      <div>
        <div className='contentHeader'>
          <h1>{t('LoyaltyPrograms.GeneralPointRuleDialog.Title')}</h1>
          <p>{t('LoyaltyPrograms.GeneralPointRuleDialog.Text')}</p>
        </div>
        <div className='modalContent'>
          <div className='fieldText'>
            <p>{t('LoyaltyPrograms.MinimumSpend')}</p>
          </div>
          <div>
            <Field
              name='minimumSpend'
              component={TextField}
              format={(value) => value && numeral(value).format('$0,0.00')}
              normalize={(value) => FormatTextUtil.formatCurrencyWithMaxDigit(value, 10)}
              disabled={isDisabled}
              hintText='$0.01'
            />
          </div>
        </div>
      </div>
    );

    const generalPointRuleModal = (
      <Modal
        open={this.state.isGeneralPointRuleDialogOpen}
        externalClassName='generalPointRuleModal'
        confirmText={t('Save')}
        onClose={this.cancelGeneralPointRuleDialog}
        onConfirm={this.toggleGeneralPointRuleDialog}
        isConfirmDisabled={!isGeneralPointRuleFilled}
      >
        {generalPointRuleContent}
      </Modal>
    );

    const rewardContent = (
      <div>
        <div className='contentHeader'>
          <h1>{t('LoyaltyPrograms.RewardDialog.Title')}</h1>
          <p>{t('LoyaltyPrograms.RewardDialog.Text')}</p>
        </div>
        <div className='modalContent'>
          <div>
            <div className='fieldText twoOptionsTitle'>
              <p>{t('LoyaltyPrograms.RewardAmountType')}</p>
              <p>{t('LoyaltyPrograms.RewardAmount')}</p>
            </div>
            <div className='twoOptions'>
              <Field
                component={Select}
                className='halfScreen rewardAmountType'
                name='rewardAmountType'
                ref='rewardAmountType'
                input={{defaultValue: 'percent'}}
                disabled={isDisabled}
                props={{
                  input: { onChange: this.handleRewardType },
                  value: currentValues.rewardAmountType
                }}
              >
                <MenuItem value='percent'>%</MenuItem>
                <MenuItem value='dollar'>$</MenuItem>
              </Field>
              <Field
                className='halfScreen'
                name='rewardAmount'
                hintText={this.state.rewardType === 'dollar' ? '$0.00' : '0%'}
                component={TextField}
                normalize={(value) => this.state.rewardType === 'dollar' ? FormatTextUtil.formatNumberDoubleDecimals(value) : FormatTextUtil.formatWholeNumber(value)}
                disabled={isDisabled}
              />
            </div>
          </div>
          <div>
            <div className='fieldText'>
              <p>{t('LoyaltyPrograms.NumberPoints')}</p>
            </div>
            <div className='inputWidth'>
              <Field
                name='pointsToEarnReward'
                component={TextField}
                hintText='1'
                normalize={FormatTextUtil.formatPositiveWholeNumber}
                disabled={isDisabled}
              />
            </div>
          </div>
          <div>
            <div className='fieldText'>
              <p>{t('LoyaltyPrograms.EarnedExpiration')}</p>
            </div>
            <div className='shouldExpire'>
              <Field
                component={Select}
                className='fullScreen alignBottom fullScreenRes rewardExpiration'
                name='rewardExpiration'
                ref='rewardExpiration'
                disabled={isDisabled}
                input={{defaultValue: '12 months'}}
                props={{
                  input: { onChange: this.handleExpirationDate },
                  value: currentValues.rewardExpiration
                }}
              >
                <MenuItem value='notexpire'>
                  {t('LoyaltyPrograms.NotExpired')}
                </MenuItem>
                <MenuItem value='3 months'>
                  {t('LoyaltyPrograms.ExpireIn3Months')}
                </MenuItem>
                <MenuItem value='6 months'>
                  {t('LoyaltyPrograms.ExpireIn6Months')}
                </MenuItem>
                <MenuItem value='9 months'>
                  {t('LoyaltyPrograms.ExpireIn9Months')}
                </MenuItem>
                <MenuItem value='12 months'>
                  {t('LoyaltyPrograms.ExpireIn12Months')}
                </MenuItem>
              </Field>
            </div>
          </div>
        </div>
      </div>
    );

    const rewardModal = (
      <Modal
        open={this.state.isRewardDialogOpen}
        externalClassName='rewardModal'
        confirmText={t('Save')}
        onClose={this.cancelRewardDialog}
        onConfirm={this.toggleRewardDialog}
        isConfirmDisabled={!isRewardInformationFilled}
      >
        {rewardContent}
      </Modal>
    );

    const loyaltyIcon = IconUtils.getIcon('LoyaltyProgramIcon', '#2E2D2D', 23);
    const plusIcon = IconUtils.getIcon('AddIcon', '#1FC3B3', 18);

    const marketingTilesComponent = [];
    marketingTiles.forEach((tile, index) => {
      marketingTilesComponent.push(<Grid item xs={12} sm={12} md={12} lg={6} key={index} sx={tile?.sx}>
        <div className='tile'>
          <img src={tile?.img} className={tile?.class}/>
          <h3>{tile?.text}</h3>
        </div>
      </Grid>)
    });

    const shouldShowForm = isFormEnabled && isLoyaltyAddOnActive;

    return (
      <>
        <div className='loyaltyFormContainer'>
          <form className='loyaltySettings' onSubmit={handleSubmit}>
            {isProcessing ? <Loading /> : toggleBox}
            {disableDialog}
            {updateDialog}
            {reEnableDialog}
            {generalPointRuleModal}
            {rewardModal}
            {!isProcessing ? (
              <div style={{'display': shouldShowForm ? 'block' : 'none' }}>
                <div className='mainNames'>
                  <div>
                    <Field
                      label={t('LoyaltyPrograms.ProgramName')}
                      className='programName'
                      name='programName'
                      component={TextField}
                      hintText={t('LoyaltyPrograms.HintProgramName')}
                      disabled={isDisabled}
                      maxLength='50'
                    />
                    <span
                      className='fieldMessageFooter'>{(50 - this.props.programName?.length)}{t('LoyaltyPrograms.CharactersLeft')}</span>
                  </div>
                  <div>
                    <Field
                      label={t('LoyaltyPrograms.PointsName')}
                      className='pointsName'
                      name='pointsName'
                      component={TextField}
                      hintText={t('LoyaltyPrograms.HintPointsName')}
                      disabled={isDisabled}
                      maxLength='25'
                    />
                    <span
                      className='fieldMessageFooter'>{(25 - this.props.pointsName?.length)}{t('LoyaltyPrograms.CharactersLeft')}</span>
                  </div>
                </div>
                <div className='settingsOption columnStyle'>
                  <div className='customMessage'>
                    <p>{t('LoyaltyPrograms.CustomMessage')}</p>
                  </div>
                  <div className='fullScreen'>
                    <Field
                      name='rewardMessage'
                      className='fullScreen'
                      hintText={t('Optional')}
                      component={TextField}
                      disabled={isDisabled}
                    />
                  </div>
                  <span className='customMessageFooter'>{t('LoyaltyPrograms.CustomMessageFooter')}</span>
                </div>
                <div className='generalPointRule'>
                  <p>{t('LoyaltyPrograms.GeneralPointRuleDialog.GeneralPointRule')}</p>
                  <div className={`content ${!isGeneralPointRuleFilled ? 'contentZeroState' : ''}`}>
                    {
                      isGeneralPointRuleFilled ?
                        <div className='generalPointRuleInfo'>
                          <p>{t('LoyaltyPrograms.GeneralPointRuleDialog.EarnByVisit')}</p>
                          <p>{`${t('LoyaltyPrograms.GeneralPointRuleDialog.DescriptionFirstPart')} ${numeral(currentValues.minimumSpend).format('$0,0.00')} ${t('LoyaltyPrograms.GeneralPointRuleDialog.DescriptionSecondPart')} `}</p>
                        </div>
                      :
                        <p>{t('LoyaltyPrograms.GeneralPointRuleDialog.TapEdit')}</p>
                    }
                    <span onClick={this.toggleGeneralPointRuleDialog}>
                      {t('LoyaltyPrograms.GeneralPointRuleDialog.Edit')}
                    </span>
                  </div>
                </div>
                <div className='rewards'>
                  <p>{t('LoyaltyPrograms.RewardDialog.Rewards')}</p>
                  <div className={`content ${!isRewardInformationFilled ? 'contentZeroState' : ''}`}>
                    {
                      isRewardInformationFilled ?
                        <div className='rewardsInfo'>
                          {loyaltyIcon}
                          <div>
                            <p>{`${rewardAmountType === 'percent' ? `${rewardAmount}%` : `$${rewardAmount}`} ${t('LoyaltyPrograms.RewardDialog.OffEntirePurchase')}`}</p>
                            <p>{`${activeExpirationDate ? `${t('LoyaltyPrograms.RewardDialog.Expires')} ${activeExpirationDate}` : t('LoyaltyPrograms.RewardDialog.DoesNotExpire')}`}</p>
                          </div>
                        </div>
                      :
                        <p>{t('LoyaltyPrograms.RewardDialog.NoRewards')}</p>
                    }
                    {
                      isRewardInformationFilled &&
                        <span onClick={this.toggleRewardDialog}>
                          {t('LoyaltyPrograms.RewardDialog.Edit')}
                        </span>
                    }
                  </div>
                </div>
                {
                  !isRewardInformationFilled &&
                    <div className='createRewardButton' onClick={this.toggleRewardDialog}>
                      {plusIcon}
                      <p>{t('LoyaltyPrograms.RewardDialog.Create')}</p>
                    </div>
                }
              </div>
            ) : null}
          </form>
        </div>
        {
          !shouldShowForm &&
          <div className='loyaltyOptIn'>
            <Grid container>
              {marketingTilesComponent}
            </Grid>
          </div>
        }
        {
          !!!openPreviewDialog && (auth.isOwner || auth.isAdmin) && <div className='detailPanelHolderButtons saveContainer loyaltyFormBottomBar'>
            <div className='previewLoyaltyProgram' onClick={handleOpenPreviewModal}>{t('LoyaltyPrograms.PreviewProgram')}</div>
            <Button id='SaveLoyaltyButton' label={t('Save')} onClick={this.onSaveLoyaltyVpcForm} disabled={!this.props.hasChanged || formHasErrors} />
          </div>
        }
      </>
    );
  }
}

let LoyaltyForm = reduxForm({
  form: 'loyaltyFormVpc',
  validate,
  fields: [
    'enabled',
    'programName',
    'pointsName',
    'pointsToEarnReward',
    'minimumSpend',
    'rewardAmountType',
    'rewardAmount',
    'rewardMessage',
    'tcAccepted'
  ],
  enableReinitialize: true,
})(LoyaltyFormComponent);

function mapStateToProps(state, ownProps) {

  const { loyaltyVpc, merchantSettings  } = ownProps?.merchantSettings;
  const rewardAmount = loyaltyVpc?.reward_amount_type === 'percent' ? parseInt(loyaltyVpc?.reward_amount) : loyaltyVpc?.reward_amount;

  const loyaltyProgramDefaultName = merchantSettings?.merchant_name ? `${merchantSettings.merchant_name} Loyalty` : 'Loyalty Program';

  const loyaltyAddOn = ownProps?.merchantSettings?.addOns && ownProps?.merchantSettings.addOns?.length ? ownProps?.merchantSettings.addOns[0] : {};
  const isLoyaltyAddOnActive = loyaltyAddOn.enabled;

  const initialValues = {
    enabled: loyaltyVpc?.enabled && isLoyaltyAddOnActive || false,
    programName: loyaltyVpc?.program_name || loyaltyProgramDefaultName,
    pointsName: loyaltyVpc?.points_name || '',
    pointsToEarnReward: loyaltyVpc?.points_to_earn_reward || '',
    minimumSpend: loyaltyVpc?.minimum_spend || '',
    rewardAmountType: loyaltyVpc?.reward_amount_type || 'percent',
    rewardAmount: rewardAmount || '',
    rewardMessage: loyaltyVpc?.reward_message || '',
    rewardExpiration: loyaltyVpc?.reward_expiration || '12 months',
    tcAccepted: merchantSettings?.loyalty_tc_accepted && isLoyaltyAddOnActive || false
  };

  const currentValues = getFormValues('loyaltyFormVpc')(state) || {};

  const dirtyFields = _.reduce(initialValues,(result, value, key) => (
    _.isEqual(value, currentValues[key]) ? result : result.concat(key)
  ), []);

  return {
    initialValues,
    isFormEnabled: state.form?.loyaltyFormVpc?.values?.enabled || false,
    isLoyaltyAddOnActive,
    hasChanged: isDirty('loyaltyFormVpc')(state),
    loyaltyVpcFormSyncErrors: getFormSyncErrors('loyaltyFormVpc')(state),
    programName: currentValues.programName,
    pointsName: currentValues.pointsName,
    dirtyFields,
    currentValues
  };
}

LoyaltyForm = connect(mapStateToProps)(LoyaltyForm);

export default LoyaltyForm;
