/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

Copyright 2000 NAB, All Rights Reserved.

NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
and Non-disclosure agreements explicitly covering such access.

The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
MAY DESCRIBE, IN WHOLE OR IN PART.

*/

import React, {Component} from 'react';
import Page from '../shared/Page';

export default class SMSServiceTerms extends Component {

  render() {

    return (
      <Page hideAccessories>
        <div className='nonAuthTerms pageScrollableArea'>
        <h2>Payments Hub Terms and Conditions</h2>
        <p>
          By signing up for the Payments Hub Text Message Program (“Program”)
          you agree to these terms and conditions (“Program Terms”). If you do
          not agree to these Terms, including the privacy disclosures below,
          please do not use the program.
        </p>
        <h4>
          <em>
            NOTICE REGARDING DISPUTE RESOLUTION: THESE TERMS CONTAIN PROVISIONS
            THAT GOVERN HOW CLAIMS RELATED TO THE PROGRAM WILL BE ADJUCIATED,
            INCLUDING AN OBLIGATION TO ARBITRATE DISPUTES, WHICH WILL, SUBJECT
            TO LIMITED EXCEPTIONS, REQUIRE YOU TO SUBMIT CLAIMS TO BINDING
            ARBITRATION.
          </em>
        </h4>
        <h3>General Information</h3>
        <p>
          You may sign up for the Program by texting the word “START” to 38052.
          In response, you will receive an SMS text message to confirm your
          enrollment. Message frequency may vary, depending on your interactions
          with the Program.
        </p>
        <p>
          By enrolling in the Program, you certify that (1) you are 18 years old
          or older, (2) you are the account holder of the mobile number you are
          enrolling (or you have the account holder’s permission to enroll the
          number), and (3) you agree to these Terms.
        </p>
        <p>
          You agree to maintain accurate, complete, and up-to-date information
          with us, including by advising us immediately if you cease being the
          account holder of the mobile number you enrolled.
        </p>
        <p>
          Consent to receive SMS text messages is not required as a condition of
          purchasing any goods or services.
        </p>
        <p>
          We may use autodialer or non-autodialer technology to send the messages
          described above to the mobile phone number you enroll.
        </p>
        <p>
          Although alerts through this Program are complimentary, message and
          data rates may apply. Depending on your text and data plan, you may
          be charged by your carrier.
        </p>
        <p>
          To stop receiving Program texts, simply text “STOP”; you may receive
          a reply message to confirm that you have been unsubscribed.  If you
          want to re-join, just enroll as you did the first time and you will
          start receiving messages again. Text “HELP” for additional information.
        </p>
        <p>
          Payments Hub does not guarantee availability or performance of the
          Program and is not responsible for delays related to the transmission
          of text messages.
        </p>
        <h3>Program Availability</h3>
        <p>The Program may not be available in all areas or supported by all
          carriers or all devices. Check with your carrier for details.
          Participating carriers include: AT&amp;T, Spring/Boost/Virgin, T-Mobile,
          MetroPCS, and Verizon Wireless. Carriers are not liable for delayed or
          undelivered messages.
        </p>
        <h3>Privacy Statement</h3>
        <p>
          For purposes of this Program, we will collect certain personal
          information about you, including your mobile phone number, name,
          and transaction data.  Your information may be disclosed to a vendor
          that administers the Program. Reasonable security safeguards have been
          implemented to protect the privacy of your personal information. Text
          “HELP” for more information about our privacy practices.
        </p>
        <h3>Dispute Resolution</h3>
        <p>
          The parties shall use their best efforts to settle any dispute, claim,
          question, or disagreement directly through consultation and good faith
          negotiations which shall be a precondition to either party initiating
          formal legal proceedings.
        </p>
        <p>
          If the parties do not reach an agreed upon solution through the informal
          dispute resolution process, then either party may initiate binding
          arbitration. All claims arising out of or relating to these Terms,
          and/or your use of the Program shall be finally settled by binding
          arbitration. Any arbitration will be held before a single neutral
          arbitrator and will be governed by the Consumer Arbitration Rules and
          the Consumer Due Process Protocol (collectively, “AAA Rules”) of the
          American Arbitration Association (“AAA”) as currently in effect and
          will be administered by the AAA. The AAA Rules are available online
          at <a href='https://www.adr.org/consumer' target='_blank'>https://www.adr.org/consumer
          </a>, by calling the AAA at 1-800-778-7879. To the extent that there is
          a conflict between this clause and the AAA Rules, the arbitrator shall
          resolve such conflicts so as to preserve the parties’ mutual obligation
          to arbitrate claims on an individual basis.
        </p>
        <p>
          The parties understand that, absent this mandatory provision, they would
          have the right to sue in court and have a jury trial. They further
          understand that, in some instances, the costs of arbitration could exceed
          the costs of litigation and the right to discovery may be more limited in
          arbitration than in court.
        </p>
        <p>
          <em>Class Action Waiver.</em> The parties further agree that any
          arbitration shall be conducted in their individual capacities only and
          not as a class action or other representative action, and the parties
          expressly waive their right to file a class action or seek relief on a
          class basis. If any court or arbitrator determines that the class action
          waiver set forth in this paragraph is void or unenforceable for any
          reason, or that an arbitration can proceed on a class basis, then the
          arbitration provision set forth above shall be deemed null and void in
          its entirety and the parties shall be deemed to have not agreed to
          arbitrate disputes.
        </p>
        <p>
          <em>Exception - Small Claims Court Claims.</em> Notwithstanding the
          parties’ decision to resolve all disputes through arbitration, either
          party may also seek relief in a small claims court for disputes or
          claims within the scope of that court’s jurisdiction.
        </p>
        <h3>Questions</h3>
        <p>
          For questions about the Program or these Terms, you can send an email
          to <a href='mailto:support@paymentshub.com' target='_blank'>support@paymentshub.com</a> or
          call 877.464.4218.
        </p>
      </div>
      </Page>
    )
  }
}
