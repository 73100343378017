/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import {getSalesItemImage} from './../actions/itemsActions';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import Menu from '@mui/material/Menu';
import IconUtils from './util/IconUtil';
import ImageUtils from './util/ImageUtil';
import MenuItem from './shared/MenuItem';
import ReactCrop from 'react-image-crop';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Modal from './shared/Modal';
import classNames from 'classnames';
import attachFileOptions from '../constants/attachFileOptions';

export default class ItemDetailThumbnail extends Component {

  constructor(props) {
    super(props);

    this.initialCrop = Object.freeze({ aspect: 1, x: 10, y: 10, width: 80, height: 80 });

    this.state = {
      openMenu: false,
      openDialog: false,
      crop: this.initialCrop,
      preview: false,
      newItem: false
    };

    this.loadThumbnailData = this.loadThumbnailData.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
    this.handleImageCrop = this.handleImageCrop.bind(this);
    this.handleCrop = this.handleCrop.bind(this);
    this.handleRemoveImage = this.handleRemoveImage.bind(this);
    this.forceFileInputOpen = this.forceFileInputOpen.bind(this);
    this.setCropBasedOnDimensions = this.setCropBasedOnDimensions.bind(this);

    this.fileInputRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.item.image_id && (!this.props.salesItemImages || !this.props.salesItemImages?.data?.[this.props.item.image_id])) {
      this.loadThumbnailData(this.props);
    }
  }

  loadThumbnailData(props) {
    props.dispatch(getSalesItemImage(props.user, props.item.id, props.item.image_id));
  }

  handleClick(event) {
    event.preventDefault();
    this.setState({
      openMenu: true,
      anchorEl: event.currentTarget,
    });
  }

  handleMenuClose() {
    this.setState({
      openMenu: false,
    });
  }

  handleDialogClose() {
    this.setState({
      openDialog: false,
    });
  }

  forceFileInputOpen() {
    this.fileInputRef?.current?.click();
  }

  setCropBasedOnDimensions(imageWidth, imageHeight) {
    const crop = { ...this.initialCrop };

    const width = imageWidth > imageHeight ?
      ((imageHeight * crop.height / 100) / imageWidth * 100) : crop.width;

    const height = imageHeight > imageWidth ?
      ((imageWidth * crop.width / 100) / imageHeight * 100) : crop.height;

    return { ...crop, width, height };
  }

  /* istanbul ignore next: FileReader() too complex to work around*/
  handleImageUpload(e) {
    let reader = new FileReader();
    let file = e.target.files[0];

    if (file && file instanceof Blob) {
      reader.onloadend = () => {
        const imagePreview = new Image();
        imagePreview.src = reader.result;
        imagePreview.onload = () => {
          this.setState({
            file: file,
            imagePreviewUrl: reader.result,
            crop: this.setCropBasedOnDimensions(imagePreview.width, imagePreview.height),
            openMenu: false,
            openDialog: true
          });
        };
      };

      reader.readAsDataURL(file);
    };
  }

  handleCrop(crop, pixelCrop) {
    this.setState({
      crop: crop,
      pixelCrop: pixelCrop
    });
  }

  /* istanbul ignore next: Image() too complex to work around*/
  handleImageCrop() {
    let that = this;
    let image = new Image();
    image.onload = (e) => {
      let data = ImageUtils.crop(image, that.state.crop);
      that.setState({
        imagePreviewUrl: data,
        openDialog: false,
        preview: true
      });
      that.props.setCroppedImage(data.split(',')[1], data);
      image = null;
    };
    image.src = this.state.imagePreviewUrl;
  }

  handleRemoveImage() {
    this.setState({
      openMenu: false,
      preview: false,
    });
    if (this.fileInputRef?.current?.value) {
      this.fileInputRef.current.value = '';
    }
    this.props.setCroppedImage('deleteFlag', 'deleteFlag');
  }

  render() {

    const { item, salesItemImages, isEditModeEnable = true, disabled = false } = this.props;

    const itemData = salesItemImages && salesItemImages.data && salesItemImages.data[item.image_id];
    const isFetching = _.get(itemData, 'isFetching');
    const uploadImageIcon = IconUtils.getIcon('ImgUploadIcon');

    const cropImageDialog = (
      <Modal
        title='CROP IMAGE'
        open={this.state.openDialog}
        onClose={this.handleDialogClose}
        onConfirm={this.handleImageCrop}
        confirmText='CROP'
      >
        {this.state.imagePreviewUrl ? (
            <ReactCrop
              src={this.state.imagePreviewUrl}
              crop={this.state.crop}
              onChange={this.handleCrop} />
          ) : ''
        }
      </Modal>
    );

    if (isFetching && item.image_id) {
      return (
        <CircularProgress
          className='refreshIndicator'
          size={50}
          style={{
            display: 'inline-block',
            position: 'relative',
            zIndex: '0'
          }}
        />
      );
    } else {

      let imageSource = '';

      const isValidPreLoadedImage = (!_.isNull(this.props.loadedImage) && !this.state.newItem);
      const isImageDeleted = this.props.loadedImage === 'deleteFlag';

      if ((!_.isEmpty(itemData) || this.state.preview) || isValidPreLoadedImage) {
        if (isValidPreLoadedImage) {
          imageSource = this.props.loadedImage;
        } else {
          imageSource = (this.state.preview) ? this.state.imagePreviewUrl : 'data:image/png;base64,' + itemData.image;
        }
      }

      const isImageAvailable = itemData && itemData.image;
      const itemThumbnailClassname = classNames('itemThumbnail', { itemThumbnailDisabled: disabled });

      const acceptedFiles = Object.entries(attachFileOptions.imagesPngJpgFormats).reduce((acc, [,extensionArray]) => ([...acc, ...extensionArray]), []).join(', ');

      return (

        <div className={itemThumbnailClassname}>
          {(( isImageAvailable || this.state.preview || !_.isNull(this.props.loadedImage)) && !isImageDeleted) ? (
            <div>
              <img className='itemThumb' src={imageSource} style={{ width: '50px' }} />
              <span className={'existingImageCameraIcon'} onClick={!disabled ? this.handleClick : undefined}>
                {uploadImageIcon}
              </span>
            </div>
          ) : (
              <div className='uploadImageContainer'>
                <Avatar className='itemThumb itemsDetailThumbnail'
                  onClick={isEditModeEnable && !disabled ? this.handleClick : undefined}
                >
                  <span className={'camera active'}>
                    {uploadImageIcon}
                  </span>
                </Avatar>
              </div>
            )}
          {isEditModeEnable && !disabled ? <Menu
            open={this.state.openMenu}
            anchorEl={this.state.anchorEl}
            placement='bottom'
            onClose={this.handleMenuClose}
            className='uploadImageModal'
          >
            <MenuItem primaryText='Upload Image' onClick={this.forceFileInputOpen}/>
            {((!_.isEmpty(itemData) || this.state.preview || !_.isNull(this.props.loadedImage)) && !isImageDeleted) ? (
              <MenuItem primaryText='Remove Image' onClick={this.handleRemoveImage} />
            ) : ''}
          </Menu> : null}
          <input ref={this.fileInputRef} type='file' style={{ display: 'none' }} accept={acceptedFiles} onChange={this.handleImageUpload}/>
          {cropImageDialog}
        </div>
      )
    }
  }
}

ItemDetailThumbnail.propTypes = {
  item: PropTypes.object.isRequired
};
