/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { submit } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import query from 'query-string';
import numeral from 'numeral';
import _ from 'lodash';

import MasterLayout from '../MasterLayout';
import PaymentLinksDetailForm, { PAYMENT_LINK_DETAIL_FORM_ID } from './PaymentLinksDetailForm';
import PaymentLinksHeader from './PaymentLinksHeader';

import { getInternalPaymentLinks, updatePaymentLink } from '../../actions/paymentLinksActions';
import {
  setOpenMessageDialog,
  setVisibilityFilter
} from '../../actions/userExperienceActions';
import { setClosedAccount } from '../../actions/userActions';
import actionTypes from '../../constants/actionTypes';
import routes from '../../constants/routes';

import BottomBar from '../shared/BottomBar';
import DetailPanelOptions from '../shared/detailPanel/DetailPanelOptions';
import FilterPanel from '../shared/FilterPanel';
import PlanUpgradePaymentLinks from '../shared/paymentLinks/planUpgradePaymentLinks';
import Modal from '../shared/Modal';
import CreatePaymentLinkControl from '../shared/paymentLinks/createPaymentLinkControl';
import MessageDialog from '../shared/MessageDialog';

import DateUtils from '../util/DateUtil';
import IconUtil from '../util/IconUtil';
import PaymentLinksUtil, { PaymentLinksFilterTypes, PaymentLinksTableColumns } from '../util/PaymentLinksUtil';
import UserUtil from '../util/UserUtil';
import Page from '../shared/Page';
import { deactivatePaymentLinkDialogStyles, paymentLinksDetailRootStyles } from '../../jss/paymentLinksStyles';
import { CreatePaymentLink } from '../shared/brandedGraphics/paymentLinks/CreatePaymentLink';
import { CreateAnother } from '../shared/brandedGraphics/paymentLinks/CreateAnother';
import { SharePaymentLink } from '../shared/brandedGraphics/paymentLinks/SharePaymentLink';
import CreateButtonAccessory from '../appBar/accessories/CreateButtonAccessory';

export default class PaymentLinks extends Component {

  constructor(props) {
    super(props);
    this.showDetailForRow = this.showDetailForRow.bind(this);
    this.handleCloseDetail = this.handleCloseDetail.bind(this);
    this.onSaveChangesClick = this.onSaveChangesClick.bind(this);
    this.submitSaveChanges = this.submitSaveChanges.bind(this);
    this.onOpenQRCode = this.onOpenQRCode.bind(this);
    this.onCopyUrl = this.onCopyUrl.bind(this);
    this.onShowDeactivateModal = this.onShowDeactivateModal.bind(this);
    this.onCloseDeactivateModal = this.onCloseDeactivateModal.bind(this);
    this.onDeactivate = this.onDeactivate.bind(this);
    this.onReactivate = this.onReactivate.bind(this);
    this.checkOpenDetailFromQuery = this.checkOpenDetailFromQuery.bind(this);
    this.onViewTransactions = this.onViewTransactions.bind(this);
    this.loadData = this.loadData.bind(this);
    this.handleActionsFromHistory = this.handleActionsFromHistory.bind(this);

    this.state = {
      openCreateModal: false,
      openDetail: false,
      selectedIndex: -1,
      selectedDetail: null,
      openDeactivateModal: false
    };

  }

  componentDidMount() {
    this.handleFilterSelection({name: PaymentLinksFilterTypes.ACTIVE});
    this.loadData();
    this.handleActionsFromHistory();
  }

  componentWillUnmount() {
    this.props.dispatch(setVisibilityFilter('', 'filter'));
  }

  loadData() {

    const { dispatch, user, history, auth, userExperience } = this.props;
    const cs = history.location.pathname.includes('client-service');
    const selectedMerchantAccount = user.selectedMerchantAccount;
    const userAccounts = user.data ? user.data.merchantAccounts : [];
    const userAccount = userAccounts.find(account => account.mea_id === Number(selectedMerchantAccount));

    if (userAccount && !userAccount.active && !cs && !auth.isSpr && userExperience.openMessageDialog !== 'closedAccount') {
      dispatch(setClosedAccount());
    }

    dispatch(getInternalPaymentLinks(user)).then(result => {

      if (result?.type === actionTypes.getInternalPaymentLinksSuccess) {
        this.checkOpenDetailFromQuery(result);
      }

    });
  }

  handleActionsFromHistory() {

    const { history } = this.props;

    if (history?.location?.state?.openNewPaymentLink) {
      this.handleCreatePaymentLinkModal();
      history.replace({ state: {} });
    }

  }

  checkOpenDetailFromQuery(response) {

    const { location } = this.props;

    const queryString = query.parse(location?.search ?? '');
    const queryId = numeral(queryString?.paymentLinkId).value();
    const queryPaymentLink = response?.response?.filter(paymentLink => paymentLink?.id === queryId);

    if (queryPaymentLink?.length) {

      this.setState({
        selectedDetail: queryPaymentLink[0],
        openDetail: true
      });

    }
  }

  handleCreatePaymentLinkModal = () => {
    const { openCreateModal } = this.state;
    this.setState({openCreateModal: !openCreateModal});
  }

  handleFilterSelection = ({ name }) => {
    this.props.dispatch(setVisibilityFilter({ property: 'type', value: name }, 'filter'));
  };

  renderTitleColumn(title, isActive) {
    const linkIcon = isActive ? IconUtil.getIcon('ActivePaymentLinksIcon') : IconUtil.getIcon('InactivePaymentLinksIcon');

    return (
      <div className={`paymentLinkTableRow ${isActive ? 'activeIcon' : 'inactiveIcon'}`}>
        {linkIcon}
        {title}
      </div>
    )
  }

  showDetailForRow(selectedIndex) {

    const { paymentLinks, visibilityFilter } = this.props;

    if (selectedIndex >= 0 && paymentLinks?.filteredData) {

      const isActivePaymentLinks = visibilityFilter?.filter?.value === PaymentLinksFilterTypes.ACTIVE;
      const filteredPaymentLinks = {...paymentLinks};
      filteredPaymentLinks.filteredData = isActivePaymentLinks ? filteredPaymentLinks.filteredData?.filter(({active}) => active) : filteredPaymentLinks.filteredData?.filter(({active}) => !active);
      const selectedDetail = filteredPaymentLinks?.filteredData[selectedIndex];

      this.setState({
        selectedIndex,
        selectedDetail,
        openDetail: true
      });
    }

  }

  handleCloseDetail() {
    this.setState({ openDetail: false });
  }

  onSaveChangesClick() {
    this.props.dispatch(submit(PAYMENT_LINK_DETAIL_FORM_ID));
  }

  submitSaveChanges(newValues) {

    const { dispatch, user, paymentLinks: { selectedPaymentLink } } = this.props;

    if (!_.isEmpty(selectedPaymentLink) && !_.isEmpty(newValues)) {
      dispatch(updatePaymentLink(selectedPaymentLink, newValues, user)).then(result => {

        if (result.type === actionTypes.updatePaymentLinkSuccess) {
          location.reload();
        }

      });
    }

  }

  onOpenQRCode() {
    const { t } = this.props;
    const { selectedDetail } = this.state;

    if (selectedDetail?.settings?.qrCode) {
      window?.open(selectedDetail?.settings?.qrCode, '_blank');
    } else {
      toastr.error('Error', t('PaymentLinks.DownloadQRError'));
    }
  }

  onCopyUrl() {

    const { t } = this.props;
    const { selectedDetail } = this.state;

    const rawUrl = selectedDetail?.settings?.url;
    if (rawUrl) {
      navigator.clipboard.writeText(rawUrl);

      toastr.success('Success', t('PaymentLinks.CopyURLSuccess'));
    } else {
      toastr.error('Error', t('PaymentLinks.CopyURLError'));
    }
  }

  onShowDeactivateModal() {
    this.setState({openDeactivateModal: true});
  }

  onCloseDeactivateModal() {
    this.setState({openDeactivateModal: false});
  }

  onDeactivate() {
    this.setState({openDeactivateModal: false, openDetail: false});
    this.submitSaveChanges({active: false});
  }

  onReactivate() {
    this.setState({openDetail: false});
    this.submitSaveChanges({active: true});
  }

  onViewTransactions() {

    const { history } = this.props;
    const { selectedDetail } = this.state;

    const appRoutePrefix = globalApplicationLabel.path;
    const transactionHistoryLink = `${appRoutePrefix}${routes.activity.root}${routes.activity.transactions}?paymentLinkId=${selectedDetail.id}`;

    history.push(transactionHistoryLink);

  }

  render() {

    const { t, user, userExperience, paymentLinks, merchantSettings, auth } = this.props;
    const { isFetchingDetail, isProcessing } = paymentLinks;
    const { openCreateModal, openDeactivateModal, selectedDetail } = this.state;

    const isPremiumPlan = UserUtil.isPremiumPlusAccount(user);
    const isPremiumPlusWithLoyaltyAccount = UserUtil.isPremiumPlusWithLoyaltyAccount(user);
    const showPaymentLinks = isPremiumPlan || isPremiumPlusWithLoyaltyAccount;
    const isPaymentLinksLoading = paymentLinks.isFetching || paymentLinks.isProcessing;
    const hasPaymentLinks = paymentLinks?.internalPaymentLinks?.length > 0;
    const disablePaymentLinksDetail = UserUtil.isUserCS(user, auth) || UserUtil.isUserCSWrite(user);
    const isCsUser = user && auth && UserUtil.isUserCS(user, auth);

    const header = <PaymentLinksHeader {...this.props}/>;

    const isActivePaymentLinks = this.props.visibilityFilter?.filter?.value === PaymentLinksFilterTypes.ACTIVE;
    const filteredPaymentLinks = {...paymentLinks};
    filteredPaymentLinks.filteredData = isActivePaymentLinks ? filteredPaymentLinks.filteredData?.filter(({active}) => active) : filteredPaymentLinks.filteredData?.filter(({active}) => !active);

    const filterPanelButtonText = 'Create Payment Link';

    const rows = filteredPaymentLinks?.filteredData?.map((link) => {
      return [
        this.renderTitleColumn(link.settings?.title, link.active),
        t('PaymentLinkTypes.' + link.settings?.type),
        DateUtils.formatDate(link.created_date, 'MM/DD/YYYY'),
        PaymentLinksUtil.formatAmountColumn(link.settings?.amount),
        PaymentLinksUtil.formatTotalUsesColumn(link.number_sales),
        PaymentLinksUtil.formatTotalVolumeGenerated(link.total_sales_amt)
      ]
    });

    const transactionCount = selectedDetail?.number_sales;

    const detailOptions = [
      {
        onClick: this.onViewTransactions,
        text: `${t('PaymentLinks.Detail.ViewTransactions')} (${transactionCount})`,
        icon: IconUtil.getIcon('ListIcon'),
        disabled: transactionCount <= 0
      }
    ];

    if (selectedDetail?.active) {

      detailOptions.splice(0, 0,
        {
          onClick: this.onOpenQRCode,
          text: t('PaymentLinks.Detail.DownloadQR'),
          icon: IconUtil.getIcon('QRCodeIcon')
        },
        {
          onClick: this.onCopyUrl,
          text: t('PaymentLinks.Detail.CopyURL'),
          icon: IconUtil.getIcon('CopyIcon')
        }
      );

      detailOptions.push(
        {
          onClick: this.onShowDeactivateModal,
          text: t('PaymentLinks.Detail.Deactivate'),
          icon: IconUtil.getIcon('CancelXIcon')
        }
      );

    } else {

      detailOptions.splice(0, 0,
        {
          onClick: this.onReactivate,
          text: t('PaymentLinks.Detail.Reactivate'),
          icon: IconUtil.getIcon('PowerButtonIcon')
        }
      );
    }


    const paymentLinksDetailContent = (
      <div className='detailContent'>
        <DetailPanelOptions options={detailOptions} />
        <PaymentLinksDetailForm
          {...this.props}
          selectedDetail={selectedDetail}
          filteredPaymentLinks={filteredPaymentLinks}
          onSubmit={this.submitSaveChanges}
          onCopyUrl={this.onCopyUrl}
        />
        <BottomBar
          className='paymentLinksDetailBottomBar'
          leftActions={[]}
          rightActions={[
            {
              key: 'cancelDetailPaymentLink',
              label: t('PaymentLinks.Detail.Cancel'),
              onClick: this.handleCloseDetail,
              variant: 'text'
            },
            {
              key: 'saveChangesPaymentLink',
              label: t('PaymentLinks.Detail.SaveChanges'),
              disabled: isFetchingDetail || isProcessing,
              onClick: this.onSaveChangesClick,
              variant: 'contained'
            }
          ]}
        />
      </div>
    );

    const createDialogContent = <CreatePaymentLinkControl
      {...this.props}
      showCancelButton={true}
      onCancelButtonClick={this.handleCreatePaymentLinkModal}
      className='createPaymentLinkControl'
    />

    const createNewButton = <CreateButtonAccessory
      onClick={this.handleCreatePaymentLinkModal}
      disabled={isCsUser || user.closedAccount}
      isMobileOnly
    />

    const firstPaymentLinks = (
      <Page
        title={t('Payment Links')}
        accessories={[
          {
            name: 'createNew',
            accessory: createNewButton,
            showInMobile: true,
          },
        ]}
      >
        <div className='firstPaymentLinks'>
          <FilterPanel
            {...this.props}
            filterData={[{name: PaymentLinksFilterTypes.ACTIVE, disabled: true}, {name: PaymentLinksFilterTypes.INACTIVE, disabled: true}]}
            visibilityFilter={PaymentLinksFilterTypes.ACTIVE}
            buttonOnClick={this.handleCreatePaymentLinkModal}
            buttonText={filterPanelButtonText}
            buttonWithCreateIcon
            disabled={isCsUser || user.closedAccount}
          />
          <div className='contentWrapper pageScrollableArea'>
            <h2>{t('PaymentLinks.FirstPaymentLink.PageTitle')}</h2>
            <div className={'card'}>
              <div className='imageWrapper'>
                <CreatePaymentLink alt={t('PaymentLinkForm.Success.Title')}/>
              </div>
              <div className='textWrapper'>
                <h3>{t('PaymentLinks.FirstPaymentLink.Card1.Title')}</h3>
                <p>{t('PaymentLinks.FirstPaymentLink.Card1.Text')}</p>
              </div>
            </div>
            <div className={'card'}>
              <div className='imageWrapper'>
                <SharePaymentLink alt={t('PaymentLinkForm.Success.Title')}/>
              </div>
              <div className='textWrapper'>
                <h3>{t('PaymentLinks.FirstPaymentLink.Card2.Title')}</h3>
                <p>{t('PaymentLinks.FirstPaymentLink.Card2.Text')}</p>
              </div>
            </div>
            <div className={'card'}>
              <div className='imageWrapper'>
                <CreateAnother alt={t('PaymentLinkForm.Success.Title')}/>
              </div>
              <div className='textWrapper'>
                <h3>{t('PaymentLinks.FirstPaymentLink.Card3.Title')}</h3>
                <p>{t('PaymentLinks.FirstPaymentLink.Card3.Text')}</p>
              </div>
            </div>
          </div>
        </div>
      </Page>
    );

    const pageLoading = merchantSettings.isProcessing || userExperience.isFetching || isPaymentLinksLoading;

    return (
      <section className='paymentLinksContainer'>
        {
          showPaymentLinks ?
            hasPaymentLinks || isPaymentLinksLoading ?
              <MasterLayout
                {...this.props}
                displayLoadingBar={pageLoading}
                pageTitle={t('Payment Links')}
                entityText='paymentLinks'
                className='paymentLinks paymentLinksMasterLayout'
                header={header}
                table={{data: filteredPaymentLinks, rows, columns: PaymentLinksTableColumns, noDataText: t('NoPaymentLinks')}}
                filter={{
                  options: [{name: PaymentLinksFilterTypes.ACTIVE}, {name: PaymentLinksFilterTypes.INACTIVE}],
                  selectedFilter: this.props.visibilityFilter?.filter?.value,
                  buttonText: filterPanelButtonText
                }}
                detail={{
                  openDetail: this.state.openDetail && !disablePaymentLinksDetail,
                  detailContent: paymentLinksDetailContent,
                  detailProps: {
                    heading: t('PaymentLinks.Detail.Heading'),
                    customStyles: paymentLinksDetailRootStyles
                  }
                }}
                handleCloseDetail={this.handleCloseDetail}
                handleCreateNew={this.handleCreatePaymentLinkModal}
                handleFilterSelection={this.handleFilterSelection}
                handleRowSelection={this.showDetailForRow}
              />
              :
              <>
                {firstPaymentLinks}
              </>
            :
            <Page
              title={t('Payment Links')}
            >
              <div className='upgradePaymentLinksPage'>
                <FilterPanel {...this.props} />
                <div className='upgradePaymentLinksPageContainer'>
                  <PlanUpgradePaymentLinks {...this.props}/>
                </div>
              </div>
            </Page>
        }

        <Modal
          externalClassName={'createPaymentLinkDialog'}
          fullScreen
          hideActions
          title={''}
          bodyText={createDialogContent}
          confirmText={''}
          isChoiceRequired={true}
          open={openCreateModal}
          hideCloseIcon={true}
          paperProps={{
            sx: {
              maxWidth: '100%'
            }
          }}
        >
          {createDialogContent}
        </Modal>

        <MessageDialog
          externalClassName={'deactivatePaymentLinkDialog'}
          titleText={t('PaymentLinks.Detail.DeactivateTitle')}
          bodyText={t('PaymentLinks.Detail.DeactivateDescription')}
          cancelText={t('Cancel')}
          confirmText={t('PaymentLinks.Detail.DeactivateTitle')}
          isChoiceRequired={true}
          open={openDeactivateModal}
          onConfirm={this.onDeactivate}
          onRequestClose={this.onCloseDeactivateModal}
          hideCloseIcon={true}
          sx={deactivatePaymentLinkDialogStyles}
        />

      </section>
    );
  }
}

