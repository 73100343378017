/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

export const TODAY = 'Today';
export const YESTERDAY = 'Yesterday';
export const THIS_WEEK = 'This Week';
export const THIS_MONTH = 'This Month';
export const THIS_YEAR = 'This Year';
export const LAST_WEEK = 'Last Week';
export const LAST_ACTIVE_WEEK = 'Last Active Week';
export const LAST_MONTH = 'Last Month';
export const LAST_YEAR = 'Last Year';
export const ALL_TIME = 'All Time';
export const OPEN_BATCH = 'Open Batch';
export const PREVIOUS_MONTH = 'Previous Month';
export const PREVIOUS_THREE_MONTHS = 'Previous Three Months';
export const PREVIOUS_SIX_MONTHS = 'Previous Six Months';
export const CUSTOM_RANGE = 'Custom Range';

export const CUSTOM_TIME = 'Custom Time';

export const dateFilterTypes = {
  TODAY,
  YESTERDAY,
  THIS_WEEK,
  THIS_MONTH,
  THIS_YEAR,
  LAST_WEEK,
  LAST_ACTIVE_WEEK,
  LAST_MONTH,
  LAST_YEAR,
  ALL_TIME,
  OPEN_BATCH,
  PREVIOUS_MONTH,
  PREVIOUS_THREE_MONTHS,
  PREVIOUS_SIX_MONTHS,
  CUSTOM_RANGE,
  CUSTOM_TIME
};

export default dateFilterTypes;
