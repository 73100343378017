/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, {Component} from 'react';
import _ from 'lodash';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ListItemIcon from '@mui/material/ListItemIcon';
import PropTypes from 'prop-types';
import routes from '../../constants/routes';
import {changeSelectedMerchantAccount} from '../../actions/authActions';
import { setModalVisibility, setStopMIDConfirmation } from '../../actions/userExperienceActions';
import {midSelectedFlag} from '../../actions/userExperienceActions';
import CheckedIcon from '@mui/icons-material/RadioButtonChecked';
import UncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Modal from '../shared/Modal';
import Checkbox from '../shared/Checkbox';
import {
  midSelectWidgetStyles,
  midSelectMenuItemStyles,
  midMenuItemStyles
} from '../../jss/midSelectWidgetStyles';
import Typography from '@mui/material/Typography';

const appRoutePrefix = globalApplicationLabel.path;


/**
 * Mui select that changes the mid the user is currently using.
 */
export default class MidSelectWidget extends Component {

  constructor(props) {
    super(props);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.switchAccount = this.switchAccount.bind(this);
    this.getRelatedMids = this.getRelatedMids.bind(this);
    this.getMerchantAccounts = this.getMerchantAccounts.bind(this);
    this.selectedMerchantAccount = this.props.user && !_.isEmpty(this.props.user.data) ? Number(this.props.user.selectedMerchantAccount) : '';
    this.state = {
      notShowAgain: false,
    }
  }

  /**
   * On select change call switch account.
   * @param {value} number
   */
  handleValueChange(event) {
    if(!this.props.auth.isSpr) {
      if (this.props.isTopBar && !this.props.userExperience.stopMIDConfirmation) {
        this.selectedMerchantAccount = this.getMerchantAccounts(this.props.user)
          .find(({mea_id}) => mea_id === event.target.value);
        this.props.dispatch(setModalVisibility('switchMIDConfirm', {switchMIDConfirm: true}));
      } else {
        this.switchAccount(event.target.value);
      }
    }
  }

  handleClose = () => {
    this.props.dispatch(setModalVisibility('switchMIDConfirm', {switchMIDConfirm: false}));
  }

  handleConfirm = () => {
    if (this.state.notShowAgain) {
      this.props.dispatch(setStopMIDConfirmation());
    }
    this.handleClose();
    this.switchAccount(this.selectedMerchantAccount.mea_id);
  }

  handleNotShowAgain = () => {
    this.setState(({notShowAgain}) => ({notShowAgain: !notShowAgain}));
  }

  /**
   * Changes the current selected account (mid) for the user.
   * @param {number} merchantAccountId
   */
  switchAccount(merchantAccountId) {
    const {dispatch, history, isTopBar} = this.props;

    dispatch(changeSelectedMerchantAccount(merchantAccountId));
    dispatch(midSelectedFlag(true));
    if (isTopBar) {
      window.location.reload();
    } else {
      history.push(`${appRoutePrefix}${routes.activity.root}${routes.activity.dashboard}`);
    }
  }

  getRelatedMids(user){
    return !_.isEmpty(user.data) && user.data.relatedMids ? user.data.relatedMids : [];
  }

  getMerchantAccounts(user){
    return !_.isEmpty(user.data) && user.data.merchantAccounts? user.data.merchantAccounts : [];
  }

  filterCanAccessInvoicesOnlinePayments(mids){
    return mids.filter(({role, manager_can_access_invoices, manager_online_payments_access_enabled}) => (
      (role === 'admin' || role === 'owner') || (role === 'manager' && manager_can_access_invoices && manager_online_payments_access_enabled)
    ));
  }

  render() {
    const {handleValueChange, state, props} = this;
    const {visible, user, auth, isTopBar, t, userExperience} = props;
    const selectedMerchantAccount = user && !_.isEmpty(user.data) ? Number(user.selectedMerchantAccount) : '';

    let midsToShow  = auth.isSpr ? this.getRelatedMids(user) : this.getMerchantAccounts(user);

    const selectedMid = auth.isSpr ? 'relatedMids' : selectedMerchantAccount;

    const relatedMidsPlaceHolder = (
      <MenuItem
          className='midMenuItem'
          key={'relatedMids'}
          value='relatedMids'
          disabled={auth.isSpr}
          sx={midMenuItemStyles}
      >
        {t('Related Mids')}
      </MenuItem>
    );

    const midsMenuItems = (
        midsToShow.map((account, i) => {

          const {dba_name: name, mid, mea_id} = account;
          const selectedMid = mea_id === selectedMerchantAccount;
          const iconToShow = selectedMid ?
            <CheckedIcon/> : <UncheckedIcon/>;
          return (
            <MenuItem
                className={selectedMid ? 'active' : 'inactive'}
                key={i}
                sx={midSelectMenuItemStyles}
                value={mea_id}
                disabled={auth.isSpr}
            >
              {!auth.isSpr ? (
                <ListItemIcon>
                  {iconToShow}
                </ListItemIcon>
              ) : null}
              <Typography>
                <span>{name}</span>
                <span>{mid}</span>
              </Typography>
            </MenuItem>
          );
        })
    );

    if(auth.isSpr) {
      midsMenuItems.unshift(relatedMidsPlaceHolder);
    }

    return (

      visible && (!isTopBar || midsMenuItems.length > 1)  ?
        <Box
          className={'midSelectWidget'}
          sx={midSelectWidgetStyles}
        >
          <Select
              className={'midMenu'}
              IconComponent={KeyboardArrowDownIcon}
              onChange={handleValueChange}
              value={selectedMid}
          >
            {midsMenuItems}
          </Select>
          <Modal
              cancelText={t('No')}
              confirmText={t('Yes')}
              onClose={this.handleClose}
              onConfirm={this.handleConfirm}
              open={userExperience.modalVisibility.switchMIDConfirm}
              title={t('Confirm')}
          >
            <p>{`${t('SwitchTo')} ${this.selectedMerchantAccount.dba_name} ${this.selectedMerchantAccount.mid}`}</p>
            <Checkbox
                input={{
                  value: state.notShowAgain,
                  onChange: this.handleNotShowAgain,
                }}
                label={t('DontShowAgain')}
            />
          </Modal>
        </Box> :
        null
    )
  }
}

MidSelectWidget.propTypes = {
  dispatch: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  user: PropTypes.objectOf(PropTypes.shape).isRequired,
  isTopBar: PropTypes.bool
};
