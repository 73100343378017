/**
North American Bancard ("NAB") CONFIDENTIAL MATERIAL

Copyright 2000 NAB, All Rights Reserved.

NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
and Non-disclosure agreements explicitly covering such access.

The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
MAY DESCRIBE, IN WHOLE OR IN PART.

*/

import React, { Component } from 'react';
import LoyaltyActivityCard from './LoyaltyActivityCard';
import { AutoSizer, Column, Table } from 'react-virtualized';
import TableHeader from '../shared/TableHeader';
import MobileScrollNote from '../shared/MobileScrollNote';
import DateUtils from '../util/DateUtil';
import { setSelectedDateRange } from '../../actions/userExperienceActions';
import DateFilterAccessory from '../appBar/accessories/DateFilterAccessory';
import { getLoyaltyActivities } from '../../actions/loyaltyVpcActions';
import FormatTextUtils from '../util/FormatTextUtil';
import numeral from 'numeral';
import IconUtils from '../util/IconUtil';
import Box from '@mui/material/Box';

const defaultDateSelectionPeriod = 'Today';
export default class LoyaltyActivity extends Component {
  constructor(props) {
    super(props);

    this.headerRenderer = this.headerRenderer.bind(this);
    this.nameColumnRenderer = this.nameColumnRenderer.bind(this);
    this.volumeSpentColumnRenderer = this.volumeSpentColumnRenderer.bind(this);
    this.rewardNameColumnRenderer = this.rewardNameColumnRenderer.bind(this);
    this.expirationDateColumnRenderer = this.expirationDateColumnRenderer.bind(this);
    this.setActiveReport = this.setActiveReport.bind(this);
    this.handleDateSelection = this.handleDateSelection.bind(this);
    this.loadData = this.loadData.bind(this);
    this.topLoyaltyCustomersRowGetter = this.topLoyaltyCustomersRowGetter.bind(this);
    this.rewardPerformanceRowGetter = this.rewardPerformanceRowGetter.bind(this);
    this.getCurrentDateRange = this.getCurrentDateRange.bind(this);

    this.state = {
      activeReport: 'topCustomers'
    }
  }

  componentDidMount() {
    if(!this.props.loyaltyVpc.loyaltyActivity) {
      this.loadData();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.userExperience?.selectedDate?.dateRange?.text !== prevProps?.userExperience?.selectedDate?.dateRange?.text) {
      this.loadData();
    }
  }

  handleDateSelection(value, dateRange) {
    this.props.dispatch(setSelectedDateRange(value, dateRange));
  }

  headerRenderer({ dataKey, label }) {
    return (
      <TableHeader
        {...this.props}
        name={label}
        clickable={false}
        attribute={dataKey}
        styles={{ borderBottom: 0 }}
        headerId={`top_loyalty_customer_${dataKey}`}
      />
    )
  }

  nameColumnRenderer({rowData: {first_name, last_name}}) {
    return `${first_name} ${last_name}`;
  }

  volumeSpentColumnRenderer({cellData}) {
    return numeral(cellData).format('$0,0.00');
  }

  rewardNameColumnRenderer({cellData}) {
    const loyaltyIcon = IconUtils.getIcon('LoyaltyProgramIcon', '#2E2D2D', 23);
    return (
      <div className='rewardName'>
        {loyaltyIcon}
        {cellData}
      </div>
    );
  }

  expirationDateColumnRenderer({cellData}) {
    const {t} = this.props;
    return cellData ?? t('LoyaltyActivity.DoesNotExpire');
  }

  loadData() {
    const {dispatch, user} = this.props;
    dispatch(getLoyaltyActivities(user, this.getCurrentDateRange()));
  }

  setActiveReport(activeReport) {
    this.setState({activeReport});
  }

  topLoyaltyCustomersRowGetter({index}) {
    const topLoyaltyCustomers = this.props.loyaltyVpc?.loyaltyActivity?.topLoyaltyCustomers;
    if(_.isEmpty(topLoyaltyCustomers[index])) return false;

    return topLoyaltyCustomers[index];
  }

  rewardPerformanceRowGetter({index}) {
    const rewardPerformance = this.props.loyaltyVpc?.loyaltyActivity?.rewardPerformance;
    if(_.isEmpty(rewardPerformance[index])) return false;

    return rewardPerformance[index];
  }

  getCurrentDateRange() {
    const {userExperience} = this.props;

    return userExperience.selectedDate.dateRange ? userExperience.selectedDate.dateRange : DateUtils.getDateRange(defaultDateSelectionPeriod);
  }

  render() {
    const {loyaltyVpc, t} = this.props;
    const {loyaltyActivity} = loyaltyVpc;

    const dateRange = this.getCurrentDateRange();

    const totalLoyaltyCustomers = loyaltyActivity?.loyaltyActivities?.loyalty_customers_count;
    const accumulated = loyaltyActivity?.loyaltyActivities?.accumulated_loyalty_points;
    const redeemed = loyaltyActivity?.loyaltyActivities?.redeemed_loyalty_points;

    const totalRedemptionsCount = loyaltyActivity?.loyaltyActivities?.redemptions?.count;

    const totalRedemptionsValue = numeral(loyaltyActivity?.loyaltyActivities?.redemptions?.total_value).format('$0,0.00')

    const repeatCustomersPercentage = FormatTextUtils.formatPercentage(String(loyaltyActivity?.loyaltyActivities?.repeat_customers_percentage));
    const isUpwardTrending = loyaltyActivity?.loyaltyActivities?.repeat_customers_percentage > 0;
    const isDownwardTrending = loyaltyActivity?.loyaltyActivities?.repeat_customers_percentage < 0;
    const isValidRepeatCustomersPercentage = loyaltyActivity?.loyaltyActivities?.repeat_customers_percentage !== 0;

    const loyaltySpend = numeral(loyaltyActivity?.loyaltyActivities?.loyalty_spend).format('$0,0.00');
    const averageSpend = numeral(loyaltyActivity?.loyaltyActivities?.average_spend).format('$0,0.00');

    const topLoyaltyCustomers = loyaltyActivity?.topLoyaltyCustomers || [];

    const totalLoyaltyCustomersCard = (<LoyaltyActivityCard header={t('LoyaltyActivity.TotalCustomersTitle')} text={totalLoyaltyCustomers} footer={t('LoyaltyActivity.TotalCustomersFooter')}/>);
    const loyaltyPointsCard = (<LoyaltyActivityCard header={t('LoyaltyActivity.LoyaltyPointsTitle')} text={`${accumulated} | ${redeemed}`} footer={t('LoyaltyActivity.LoyaltyPointsFooter')}/>);
    const redemptionsCard = (<LoyaltyActivityCard header={t('LoyaltyActivity.RedemptionsTitle')} text={`${totalRedemptionsCount} ${t('LoyaltyActivity.RedemptionsBody')}`} footer={`${totalRedemptionsValue} ${t('LoyaltyActivity.RedemptionsFooter')}`}/>);

    const repeatCustomersCard = (
      <LoyaltyActivityCard
        header={t('LoyaltyActivity.RepeatCustomersTitle')}
        text={
          <div className='repeatCustomersBody'>
            <img src='/images/reputation/thunder.svg' className={`${isDownwardTrending ? 'downward' : ''}`}/>
            <p>{repeatCustomersPercentage}</p>
          </div>
        }
        footer={`${isValidRepeatCustomersPercentage ? isUpwardTrending ? t('LoyaltyActivity.RepeatCustomersUpward') : t('LoyaltyActivity.RepeatCustomersDownward') : t('LoyaltyActivity.RepeatCustomersNoData')}`}
      />
    );

    const loyaltySpendCard = (<LoyaltyActivityCard header={t('LoyaltyActivity.LoyaltySpendTitle')} text={loyaltySpend} footer={t('LoyaltyActivity.LoyaltySpendFooter')}/>);
    const averageSpendCard = (<LoyaltyActivityCard header={t('LoyaltyActivity.AverageSpendTitle')} text={averageSpend} footer={t('LoyaltyActivity.AverageSpendFooter')}/>);

    const listRowHeight = 71;
    const overscanRowCount = 10;
    const headerHeight = 58;

    const topCustomersAutoSizer = (
      <div className='tableContainer'>
        <AutoSizer className='infiniteListAutoSizer' disableHeight>
          {({ width }) => (
            <Table
              className='loyaltyTopCustomers'
              gridStyle={{ outline: 'none' }}
              width={width}
              headerHeight={headerHeight}
              height={(topLoyaltyCustomers.length + 1) * (listRowHeight + 1)}
              overscanRowCount={overscanRowCount}
              rowHeight={listRowHeight}
              rowCount={topLoyaltyCustomers.length}
              rowGetter={this.topLoyaltyCustomersRowGetter}
            >
              <Column
                label={t('LoyaltyActivity.Name')}
                dataKey='name'
                headerRenderer={this.headerRenderer}
                width={width * 1.5}
                cellRenderer={this.nameColumnRenderer}
              />
              <Column
                label={t('LoyaltyActivity.Points')}
                dataKey='points'
                headerRenderer={this.headerRenderer}
                width={width}
              />
              <Column
                label={t('LoyaltyActivity.Redemptions')}
                dataKey='redemptions'
                headerRenderer={this.headerRenderer}
                width={width}
              />
              <Column
                label={t('LoyaltyActivity.Transactions')}
                dataKey='transactions'
                headerRenderer={this.headerRenderer}
                width={width}
              />
              <Column
                label={t('LoyaltyActivity.VolumeSpent')}
                dataKey='volume_spent'
                headerRenderer={this.headerRenderer}
                width={width}
                cellRenderer={this.volumeSpentColumnRenderer}
              />
            </Table>
          )}
        </AutoSizer>
      </div>
    );

    const rewardPerformanceData = loyaltyActivity?.rewardPerformance || [];

    const rewardPerformanceAutoSizer = (
      <div className='tableContainer'>
        <AutoSizer className='infiniteListAutoSizer' disableHeight>
          {({ width }) => (
            <Table
              className='loyaltyRewardPerformance'
              gridStyle={{ outline: 'none' }}
              width={width}
              headerHeight={headerHeight}
              height={(rewardPerformanceData.length + 1) * (listRowHeight + 1)}
              overscanRowCount={overscanRowCount}
              rowHeight={listRowHeight}
              rowCount={rewardPerformanceData.length}
              rowGetter={this.rewardPerformanceRowGetter}
            >
              <Column
                label={t('LoyaltyActivity.RewardName')}
                dataKey='reward_name'
                headerRenderer={this.headerRenderer}
                width={width * 2}
                cellRenderer={this.rewardNameColumnRenderer}
              />
              <Column
                label={t('LoyaltyActivity.Expires')}
                dataKey='expires'
                headerRenderer={this.headerRenderer}
                width={width}
                cellRenderer={this.expirationDateColumnRenderer}
              />
              <Column
                label={t('LoyaltyActivity.Redemptions')}
                dataKey='redemptions'
                headerRenderer={this.headerRenderer}
                width={width}
              />
              <Column
                label={t('LoyaltyActivity.VolumeRedeemed')}
                dataKey='volume_redeemed'
                headerRenderer={this.headerRenderer}
                width={width}
                cellRenderer={this.volumeSpentColumnRenderer}
              />
            </Table>
          )}
        </AutoSizer>
      </div>
    );

    const dateFilter = (
      <DateFilterAccessory
        {...this.props}
        defaultValue={dateRange.text}
        hideCustomDate={true}
        handleSelection={this.handleDateSelection}
        showAllTime={false}
        showPreviousCustomSelection={false}
        disableCompactVersion={true}
      />
    );

    return (
      <section className='loyaltyActivity pageScrollableArea'>
        <Box marginTop={'50px'} className='pageHeader'>
          <h1>{t('LoyaltyActivity.Title')}</h1>
          {dateFilter}
        </Box>
        {
          !loyaltyActivity ?
            <div className='noDataMessage'>{t('LoyaltyActivity.DataError')}</div>
          :
            <>
              <div className='cardsContainer'>
                {totalLoyaltyCustomersCard}
                {loyaltyPointsCard}
                {repeatCustomersCard}
                {redemptionsCard}
                {loyaltySpendCard}
                {averageSpendCard}
              </div>
              <div className='tablesContainer'>
                <div className='reportToggleContainer'>
                  <div className='reportToggle'>
                    <h2
                      className={`topCustomers ${this.state.activeReport === 'topCustomers' ? 'activeChild' : ''}`}
                      onClick={this.setActiveReport.bind(this, 'topCustomers')}>
                      {t('LoyaltyActivity.TopLoyaltyCustomers')}
                    </h2>
                    <h2
                      className={`rewardPerformance ${this.state.activeReport === 'rewardPerformance' ? 'activeChild' : ''}`}
                      onClick={this.setActiveReport.bind(this, 'rewardPerformance')}>
                      {t('LoyaltyActivity.RewardPerformance')}
                    </h2>
                  </div>
                </div>
                <div>
                  <MobileScrollNote t={t} />
                  {
                    this.state.activeReport === 'topCustomers' ?
                      topCustomersAutoSizer
                    :
                      rewardPerformanceAutoSizer
                  }
                </div>
              </div>
            </>
        }
      </section>
    )
  }
}
