/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

Copyright 2000 NAB, All Rights Reserved.

NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
and Non-disclosure agreements explicitly covering such access.

The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
MAY DESCRIBE, IN WHOLE OR IN PART.

*/

import { Autocomplete, CircularProgress, IconButton } from '@mui/material';
import React, { Component } from 'react';
import { getCompetitors, getReputationSettings, resetCompetitorsSearch, saveCompetitor } from '../../../../actions/reputationActions';
import Button from '../../../shared/Button';
import Modal from '../../../shared/Modal';
import TextField from '../../../shared/TextField';
import IconUtils from '../../../util/IconUtil';
import LabelUtil from '../../../util/LabelUtil';

export default class AddCompetitor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addCompetitorDialogOpen: false,
      selectedCompetitor: undefined,
      searchTerm: '',
      selectedCompetitorToAdd: undefined
    }

    this.addCompetitorDialogClose = this.addCompetitorDialogClose.bind(this);
    this.onAddCompetitorSubmit = this.onAddCompetitorSubmit.bind(this);
    this.addCompetitorDialogOpen = this.addCompetitorDialogOpen.bind(this);
    this.handleSearchTermChange = this.handleSearchTermChange.bind(this);
    this.handleSearchKeyDown = this.handleSearchKeyDown.bind(this);

    this.searchCompetitors = this.searchCompetitors.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleSearchKeyDown);
  }
  addCompetitorDialogOpen() {
    this.setState({addCompetitorDialogOpen: true});
  }

  addCompetitorDialogClose() {
    this.setState({addCompetitorDialogOpen: false, selectedCompetitorToAdd: undefined});
    this.setState({searchTerm: ''});
    this.props.dispatch(resetCompetitorsSearch());
  }

  async onAddCompetitorSubmit() {
    this.addCompetitorDialogClose();
    this.setState({searchTerm: ''});
    await this.props.dispatch(saveCompetitor(this.props.user, this.state.selectedCompetitorToAdd));
    this.props.dispatch(getReputationSettings(this.props.user, this.props.userExperience.selectedDate.dateRange));
    this.props.dispatch(resetCompetitorsSearch());
  }



  searchCompetitors() {
    this.props.dispatch(getCompetitors(this.props.user, this.state.searchTerm));
  }

  handleSearchTermChange(event) {
    const searchTerm = event.target.value;
    this.setState({searchTerm, selectedCompetitorToAdd: undefined});
    this.props.dispatch(resetCompetitorsSearch());
  }

  setCompetitorToAdd(competitor) {
    this.setState({selectedCompetitorToAdd: competitor});
  }

  handleSearchKeyDown(e) {
    if ((e.key === 'Enter' || e.keyCode == 13) && e.shiftKey === false) {
      e.preventDefault();
      this.searchCompetitors();
    }
  }

  render() {
    const { competitors,isSmall, t, competitorsSearch, reputationManagement: {isFetchingCompetitors}} = this.props;

    const label = LabelUtil.getLabel();
    const plusIcon = IconUtils.getIcon('AddIcon',  label.primaryColor);

    const lastCompetitorOptionId = competitorsSearch && competitorsSearch.length ? competitorsSearch[competitorsSearch.length - 1].placeId : undefined;

    const AddCompetitorDialog = (
      <Modal
        title={t('ReputationProject.CompetitorPerformance.AddCompetitor')}
        confirmText={t('Add')}
        cancelText={t('Cancel')}
        externalClassName={'addCompetitorDialog'}
        onClose={this.addCompetitorDialogClose}
        onConfirm={this.onAddCompetitorSubmit}
        isConfirmDisabled={!this.state.selectedCompetitorToAdd}
        open={this.state.addCompetitorDialogOpen}
      >
        <div className='autocompleteContainer'>
          <Autocomplete
            options={!isFetchingCompetitors ? competitorsSearch || [] : []}
            className={'competitorsAutocomplete'}
            ListboxProps={{style: {maxHeight: '240px', paddingTop: '0px', paddingBottom: '0px', border: '1px solid #E0E1E1', borderRadius: '4px'}}}
            disableClearable
            forcePopupIcon={false}
            freeSolo
            open={competitorsSearch && !!competitorsSearch.length && this.state.addCompetitorDialogOpen}
            filterOptions={(options) => options}
            isOptionEqualToValue={(option) => option.placeId}
            getOptionLabel={(option) => option.name || this.state.searchTerm}
            renderOption={(props, option) => (
                <div 
                  onClick={this.setCompetitorToAdd.bind(this, option.placeId)} 
                  key={option.placeId} 
                  className={`competitorOption ${option.placeId === this.state.selectedCompetitorToAdd ? ' activeOption' : ''}`}
                >
                  <div className='name'>{option.name}</div>
                  <p>{option.firstLine}</p>
                  <p>{option.secondLine}</p>
                  {option.distance >= 0 && <p>{`${option.distance} ${t('ReputationProject.CompetitorPerformance.MilesAway')}`}</p>}
                  {option.placeId !== lastCompetitorOptionId ? <hr/> : <div className='lastOption'></div>}
              </div>
              )
            }
            renderInput={(params) => (
              <TextField 
                {...params} 
                onKeyDown={this.handleSearchKeyDown}
                onChange={this.handleSearchTermChange}
                hintText={t('ReputationProject.CompetitorPerformance.SearchHintText')}
                InputProps={{
                  ...params.InputProps,
                  startAdornment:
                  (
                    <img src='/images/reputation/google_logo.svg' alt='Google Places' className='googleLogo'/>
                  ),
                  endAdornment: (
                    <>
                      {isFetchingCompetitors ? < CircularProgress/> : null}
                    </>
                  )
                }}
              />
            )}
          />
          <Button
            disabled={isFetchingCompetitors || !this.state.searchTerm}
            onClick={this.searchCompetitors}
            label={t('Search')} 
          />
        </div>
      </Modal>
    );


    return (
      <div className='competitorCard'>
        {
          competitors && competitors?.length <= 2 ?
            <div className={`${isSmall ? '' : 'competitor'} addCompetitor`} onClick={this.addCompetitorDialogOpen}>
              <IconButton
                className='plusIcon'>
                {plusIcon}
              </IconButton>
              <p>{isSmall ? t('ReputationProject.Trends.CompetitorPerformance.Add') : t('ReputationProject.CompetitorPerformance.AddCompetitor')}</p>
            </div>
          :
          null
        }
        {AddCompetitorDialog}
      </div>
    );
  };
}

