import React from 'react';
import {Box, Typography} from '@mui/material';
import {
  BulkInvoiceCardStyles,
  BulkInvoicesStyles
} from '../../../jss/inlineStyles';
import IconUtils from '../../util/IconUtil';

const BulkReviewInformationCard = ({isFetching, t, details}) => {
  if (isFetching) return null;

  const hasErrors = details.totalErrors > 0;
  const color = hasErrors ? '#FF9D0A' : '#30C123';
  const icon = IconUtils.getIcon(hasErrors ? 'ErrorOutlineIcon' : 'CheckCircleOutlinedIcon', color);
  const headerText = hasErrors ? t('BulkInvoices.InvoicesError', {count: details.totalErrors}) : t('BulkInvoices.InvoicesReadyToSend', {count: details.totalImports});
  const subText = details.totalImports > 0 ? t('BulkInvoices.InvoicesErrorDescription', {count: details.totalImports}) : t('BulkInvoices.NoInvoicesReadyToSend')
  return (
    <Box data-testid='BulkReviewInformationCard'
         sx={BulkInvoiceCardStyles}
    >
      <Box sx={BulkInvoicesStyles.bulkReviewPage.cardContainer}>
        {icon}
        <Box display='flex'
             flexDirection='column'
        >
          <Typography sx={{
            color: color,
            fontFamily: 'interSemiBold'
          }}
          >
            {headerText}
          </Typography>
          {hasErrors && <Typography
            variant='primaryFontFamilyMedium'
          >
            {subText}
          </Typography>}
        </Box>
      </Box>
    </Box>
  )
}

export default BulkReviewInformationCard;
